import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten',
  pure: true,
})
export class ShortenPipe implements PipeTransform {
  constructor() { }

  transform(val: string, length: number): string {
    return (val.length > length) ? val.slice(0, length) + '...' : val;
  }
}
