import { Injectable } from '@angular/core';
import { TenantTokenSessionItem } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _setBrowserStorage: Function = (key: string, value: string) => sessionStorage.setItem(key, value);
  private _getBrowserStorage: Function = (key: string) => sessionStorage.getItem(key);
  private _removeBrowserStorage: Function = (key: string) => sessionStorage.removeItem(key);
  private _clearBrowserStorage: Function = () => sessionStorage.clear();

  constructor() {}

  public getBrowserTokenStorage(): [Object[], string, TenantTokenSessionItem?] {
    return [JSON.parse(this._getBrowserStorage('token')), this._getBrowserStorage('selectedTokenId'), JSON.parse(this._getBrowserStorage('tenant'))];
  }

  public setBrowserTokenStorage(token: any) {
    this._setBrowserStorage('token', JSON.stringify(token));
  }

  public setBrowserSelectedTokenStorage(selectedTokenId: string) {
    this._setBrowserStorage('selectedTokenId', selectedTokenId);
  }

  public setBrowserTokenInfo(token: any, selectedTokenId: string) {
    this._setBrowserStorage('tokenInfo', JSON.stringify(token));
    this._setBrowserStorage('selectedTokenInfoId', selectedTokenId);
  }

  public setBrowserTenantStorage(tenant: TenantTokenSessionItem) {
    this._setBrowserStorage('tenant', JSON.stringify(tenant));
  }

  public removeBrowserTokenStorage() {
    this._removeBrowserStorage('tenant');

    this._removeBrowserStorage('token');
    this._removeBrowserStorage('selectedTokenId');

    this._removeBrowserStorage('tokenInfo');
    this._removeBrowserStorage('selectedTokenInfoId');
  }

  public clearBrowserStorage() {
    this._clearBrowserStorage();
  }
}
