import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter } from "./state";
import * as PatientsStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  on(PatientsStoreActions.LoadMiniCardsRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.LoadMiniCardsSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addOne(action.patientMiniCards, { ...s, isLoading: false, error: null })
  }),
  on(PatientsStoreActions.LoadMiniCardsFailure, (state, action) => ({ ...state, isLoading: false, error: action.error }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
