import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_TREATMENT_STATUS_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientTreatmentStatusStoreEntity, State } from './state';

export const selectPatientTreatmentStatusesState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_TREATMENT_STATUS_FEATURE_KEY);

export const getSelectedPatientTreatmentStatusId = createSelector(selectPatientTreatmentStatusesState, (state) => state.selectedPatientTreatmentStatusId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientTreatmentStatusesState);
export const selectPatientTreatmentStatusIds = selectIds;
export const selectAllPatientTreatmentStatuses = selectAll;
export const selectAllPatientTreatmentStatusEntities = selectEntities;

export const getSelectedPatientTreatmentStatus = createSelector(
  selectPatientTreatmentStatusesState,
  selectAllPatientTreatmentStatuses,
  (state, allPatientTreatmentStatuses: PatientTreatmentStatusStoreEntity[]) =>
    allPatientTreatmentStatuses ? allPatientTreatmentStatuses.find((rp) => rp.id === state.selectedPatientTreatmentStatusId) : null
);

export const selectPatientTreatmentStatusById = (id: number) =>
  createSelector(selectAllPatientTreatmentStatuses, (allPatientTreatmentStatuses: PatientTreatmentStatusStoreEntity[]) =>
    allPatientTreatmentStatuses ? allPatientTreatmentStatuses.find((rp) => rp.id === id) : null
  );

export const selectPatientTreatmentStatusesError: MemoizedSelector<object, any> = createSelector(selectPatientTreatmentStatusesState, (state) => state.error);

export const selectPatientTreatmentStatusesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientTreatmentStatusesState, (state: State) => state.isLoading);
