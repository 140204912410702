import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { StorageItemBaseDto } from 'src/app/shared/services/api.service';
import { Download } from 'src/app/shared/services/download/download';
import { DownloadService } from 'src/app/shared/services/download/download.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

declare type VIEWER_TYPE = 'image' | 'model' | 'iframe' | 'other' | 'worddoc';

@UntilDestroy()
@Component({
  selector: 'app-patient-files-back',
  templateUrl: './patient-files-back.component.html',
  styleUrls: ['./patient-files-back.component.scss'],
})
export class PatientFilesBackComponent implements OnInit {
  currentStorageItem$ = this._cardData.incoming;
  private _imageMimeTypes: string[] = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', '	image/webp'];
  private _modelMimeTypes: string[] = ['application/octet-stream', 'model/stl', 'model/obj', 'model/mtl'];
  private _iframeTypes: string[] = ['application/pdf'];
  private _wordDocTypes: string[] = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  download$: Observable<Download>;

  // The files are likely uploading to Google/MSFT and security/privacy
  // should be a concern when utilizing these to view documents in an iframe
  private readonly _googleDocsViewer = 'https://docs.google.com/gview?url={url}&embedded=true';
  private readonly _msftOfficeViewer = 'https://view.officeapps.live.com/op/embed.aspx?src={url}';

  faExclamationTriangle = faExclamationTriangle;

  constructor(@Inject(CARD_DATA) private _cardData: ICardData<StorageItemBaseDto>, private _downloadService: DownloadService) {}

  ngOnInit() {}

  back() {
    this._cardData.flip.next({ payload: null, side: this._cardData.side });
  }

  getPreviewType(contentType: string): VIEWER_TYPE {
    if (this._imageMimeTypes.includes(contentType.toLowerCase())) return 'image';
    if (this._modelMimeTypes.includes(contentType.toLowerCase())) return 'model';
    if (this._iframeTypes.includes(contentType.toLowerCase())) return 'iframe';
    if (this._wordDocTypes.includes(contentType.toLowerCase())) return 'worddoc';
    return 'other';
  }

  download(file: StorageItemBaseDto) {
    this.download$ = this._downloadService.download(file.locationUrl, file.name);
  }

  getMSFTViewerUrl(fileLocation: string) {
    return this._msftOfficeViewer.replace('{url}', encodeURIComponent(fileLocation));
  }

  getGoogleViewerUrl(fileLocation:string) {
    return this._googleDocsViewer.replace('{url}', encodeURIComponent(fileLocation));
  }
}
