import { createSelector, MemoizedSelector, createFeatureSelector } from '@ngrx/store';
import { featureAdapter } from './state';
import { State } from '../state';
import { TREATMENT_FEATURE_KEY } from '../featurekey';

const selectFeatureState: MemoizedSelector<object, State> = createFeatureSelector<State>(TREATMENT_FEATURE_KEY)

export const selectGoalState = createSelector(
  selectFeatureState,
  (state) => state.goals
);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectGoalState);
export const selectGoalIds = selectIds;
export const selectGoalTotal = selectTotal;
export const selectAllGoals = selectAll;
export const selectAllGoalEntities = selectEntities;

export const selectGoalsActiveState = (isActive: boolean) => createSelector(
  selectAllGoals,
  goals => goals && goals.filter(g => g.isActive == isActive)
);

export const selectIsLoading = createSelector(
  selectGoalState,
  (state) => state.isLoading
);

export const selectError = createSelector(
  selectGoalState,
  (state) => state.error
);
