import { Action, createReducer, on } from "@ngrx/store";
import * as PatientStatusGroupStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PatientStatusGroupStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedPatientStatusGroupId: null
  })),
  on(PatientStatusGroupStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patientStatusGroups, { ...s, isLoading: false, error: null })
  }),
  on(PatientStatusGroupStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(PatientStatusGroupStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedPatientStatusGroupId: null
  })),
  on(PatientStatusGroupStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientStatusGroupId: action.patientStatusGroup && action.patientStatusGroup.id
  })),
  on(PatientStatusGroupStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(PatientStatusGroupStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientStatusGroupStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientStatusGroupId: null
  })),
  on(PatientStatusGroupStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(PatientStatusGroupStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
