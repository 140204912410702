import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PHOTOGROUP_FEATURE_KEY } from './featurekey';
import { featureAdapter, PhotoGroupStoreEntity, State } from './state';

export const selectPhotoGroupsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PHOTOGROUP_FEATURE_KEY);

export const getSelectedPhotoGroupId = createSelector(selectPhotoGroupsState, (state) => state.selectedPhotoGroupId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPhotoGroupsState);
export const selectPhotoGroupIds = selectIds;
export const selectAllPhotoGroups = selectAll;
export const selectAllPhotoGroupsEntities = selectEntities;

export const getSelectedPhotoGroup = createSelector(
  selectPhotoGroupsState,
  selectAllPhotoGroups,
  (state, allPhotoGroups: PhotoGroupStoreEntity[]) =>
    allPhotoGroups ? allPhotoGroups.find((rp) => rp.id === state.selectedPhotoGroupId) : null
);

export const selectPhotoGroupById = (id: number) =>
  createSelector(selectAllPhotoGroups, (allPhotoGroups: PhotoGroupStoreEntity[]) =>
    allPhotoGroups ? allPhotoGroups.find((rp) => rp.id === id) : null
  );

export const selectPhotoGroupsError: MemoizedSelector<object, any> = createSelector(selectPhotoGroupsState, (state) => state.error);

export const selectPhotoGroupsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPhotoGroupsState, (state: State) => state.isLoading);
