import { NgModule } from '@angular/core';
import { ngfModule } from "angular-file"
import { CommonModule } from '@angular/common';
import { CephalometricsComponent } from '../cephalometrics/cephalometrics.component';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { MatFormFieldModule, MatInputModule, MatDividerModule, MatListModule, MatIconModule, MatProgressBarModule, MatCardModule, MatButtonModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { EvolutionColors } from 'src/app/shared/enums';
import { ImageErrorHandlerModule } from '../../image-error-handler/image-error-handler.module';
import { AudaxModule } from './audax/audax.module';
import { AudaxComponent } from './audax/audax.component';
import { CephalometricsOrderDialogComponent } from './cephalometrics-order-dialog/cephalometrics-order-dialog.component';
import { ShortenModule } from '../../../pipe/shorten/shorten.module';

@CardModule({
  selector: 'cephalometrics',
  front: CephalometricsComponent,
  back: null,
  header: 'Cephalometrics',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.918 8.26953C13.3438 6.98438 12.5781 4.14062 11.9219 3.18359C10.9648 1.84375 8.88672 0.75 7.27344 0.75H5.25C2.35156 0.75 0 3.10156 0 6V6.02734C0 7.3125 0.765625 9.0625 1.75 9.91016V14.75H8.75V13H10.5C11.457 13 12.25 12.2344 12.25 11.25V9.5H13.125C13.5898 9.5 13.9727 9.11719 13.9727 8.625C13.9727 8.54297 13.9453 8.37891 13.918 8.26953ZM8.75 6.65625C8.75 6.79297 8.64062 6.875 8.53125 6.875H7V8.40625C7 8.54297 6.89062 8.625 6.78125 8.625H5.46875C5.33203 8.625 5.25 8.54297 5.25 8.40625V6.875H3.71875C3.58203 6.875 3.5 6.79297 3.5 6.65625V5.34375C3.5 5.23438 3.58203 5.125 3.71875 5.125H5.25V3.59375C5.25 3.48438 5.33203 3.375 5.46875 3.375H6.78125C6.89062 3.375 7 3.48438 7 3.59375V5.125H8.53125C8.64062 5.125 8.75 5.23438 8.75 5.34375V6.65625Z" fill="currentColor"/></svg>', actionName: ''}],
  order: 40,
  color: EvolutionColors.Orange,
})
@NgModule({
  declarations: [
    CephalometricsComponent,
    CephalometricsOrderDialogComponent
  ],
  entryComponents: [
    CephalometricsComponent,
    CephalometricsOrderDialogComponent,
    AudaxComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    ImageErrorHandlerModule,
    AudaxModule,
    ngfModule,
    ReactiveFormsModule,
    ShortenModule
  ]
})
export class CephalometricsModule { }
