import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTreeNestedDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PatientsStoreSelectors, RootStoreState } from '../../root-store';
import { FileDto, PatientClient, StorageContentTypeEnum, StorageTypeEnum } from '../../shared/services/api.service';
import { CustomNestedTreeControl, ITreeNode } from '../models/ITreeNode';

@UntilDestroy()
@Component({
  selector: 'folder-tree-view-select',
  templateUrl: './folder-tree-view-select.component.html',
  styleUrls: ['./folder-tree-view-select.component.css']
})
export class FolderTreeViewSelectComponent implements OnInit, AfterViewInit {
  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId).pipe(
    untilDestroyed(this),
    filter((id) => !!id),
    distinctUntilChanged()
  );

  private _loadFolder$: Subject<[number, ITreeNode]> = new Subject<[number, ITreeNode]>();
  private _rootFolders$ = this.getFolder(null);
  treeControl = new CustomNestedTreeControl<ITreeNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<ITreeNode>();
  folderTreeViewSelect: FolderTreeViewSelectModel = new FolderTreeViewSelectModel();
  isShowFileName: boolean;

  //ROOT TREE NODES
  treeData: ITreeNode[] = [
    {
      name: 'Files',
      children: this._rootFolders$,
      fileDtoId: null,
      type: StorageTypeEnum.Folder,
      parentId: null,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<FolderTreeViewSelectComponent>,
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    @Inject(MAT_DIALOG_DATA) public data: FolderTreeViewSelectData,
  ) {
    this.isShowFileName = data && data.isShowFileName;
    this.dataSource.data = this.treeData;
  }

  ngOnInit(): void {
    this.treeControl.expand(this.dataSource.data[0]);
  }

  ngAfterViewInit(): void {
    this.loadFolder(this.dataSource.data[0]);
  }

  hasChild = (_: number, node: ITreeNode) => !!node.children || node.type == StorageTypeEnum.Folder;

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public select() {
    this.dialogRef.close(this.folderTreeViewSelect);
  }

  /**
   * Returns an observable that when subscribed to returns the list of contents for a single folder
   */
  getFolder(fileDtoId: number): Observable<ITreeNode[]> {
    //Defers loading the folders until the folderId has its number called
    return this._loadFolder$.pipe(
      withLatestFrom(this._selectedPatientId$),
      //filters to only load when id matches the requested folder
      filter(([[folderId, node], patientId]) => folderId == fileDtoId),
      //set loading to false to show progress bar
      tap(([[folderId, node], patientId]) => (node.isLoaded = false)),
      switchMap(([[folderId, node], patientId]) =>
        combineLatest([
          of(node),
          //fetches root folders when folderId is null, otherwise fetches a specific folders contents
          folderId == null ? this._patientClient.patient_GetFolders(patientId) : this._patientClient.patient_GetFolder(patientId, folderId),
        ])
      ),
      map(([node, contents]) => {
        //set node to loaded and return a list of its contents mapped as TreeNodes
        contents = _.filter(contents, { 'type': StorageTypeEnum.Folder});
        node.isLoaded = true;
        node.childrenCount = contents.length;
        return contents.map((c) => this.mapContentToTreeNode(c));
      }),
      //shareReplay critical to prevent reload on every view change
      shareReplay()
    );
  }

  /**
   * Converts a FileDto item into a tree node representing either a folder or a file depending upon item type
   */
  mapContentToTreeNode(content: FileDto): ITreeNode {
    return <ITreeNode>{
      fileDtoId: content.id,
      name: content.name,
      children: this.getFolder(content.id),
      type: StorageTypeEnum.Folder,
      childrenCount: content.childrenCount,
      isLoaded: content.childrenCount > 0 ? false : true,
      parentId: content.parentId,
    };
  }

  /**
   * Get the text to display for the file name
   * @param file
   */
  getNodeName(file: FileDto): string {
    if (file.contentType == StorageContentTypeEnum.Other) {
      return file.name;
    } else {
      return `${file.id} - ${this.getFileExtension(file.name)} - ${file.createdWhen.toLocaleDateString()} - ${file.contentType}`;
    }
  }

  /**
   * Gets the file extension from file name
   */
  private getFileExtension(fileName: string) {
    return fileName.split('.').reverse()[0].toUpperCase();
  }

  /**
   * Maps a StorageContentType to the card where those items are managed
   */
  contentTypeToCardSelector(contentType: StorageContentTypeEnum): string {
    switch (contentType) {
      case StorageContentTypeEnum.Ceph:
        return 'cephalometrics';
      case StorageContentTypeEnum.Imaging:
      case StorageContentTypeEnum.IntraOral:
        return 'patient-imaging';
      case StorageContentTypeEnum.Model:
        return '3d-models';
      case StorageContentTypeEnum.Contract:
      case StorageContentTypeEnum.Attachment:
      case StorageContentTypeEnum.Other:
      default:
        return null;
    }
  }

  /**
   * Indicate a folder to be loaded from api if not already loaded
   */
  loadFolder(node: ITreeNode) {
    if (!node.isLoaded) {
      this._loadFolder$.next([node.fileDtoId, node]);
    }
  }
}

export class FolderTreeViewSelectModel {
  selectedFolderId?: number;
  fileName?: string;
}

export class FolderTreeViewSelectData {
  isShowFileName?: boolean;
}
