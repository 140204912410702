import { HubConnectionState } from '@microsoft/signalr';
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import { initialState, State } from './state';

const reducer = createReducer(
  initialState,
  on(Actions.HubCreated, (state, action) => ({ ...state, connectionStatus: HubConnectionState.Connecting })),
  on(Actions.HubStarted, (state, action) => ({ ...state, connectionStatus: HubConnectionState.Connected })),
  on(Actions.HubReconnecting, (state, action) => ({ ...state, connectionStatus: HubConnectionState.Reconnecting })),
  on(Actions.HubReconnected, (state, action) => ({ ...state, connectionStatus: HubConnectionState.Connected })),
  on(Actions.HubStopped, (state, action) => ({ ...state, connectionStatus: HubConnectionState.Disconnected }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
