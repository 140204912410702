import { Action, createReducer, on } from '@ngrx/store';
import * as InternalUserStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(InternalUserStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedUserId: null,
  })),
  on(InternalUserStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.users, { ...s, isLoading: false, error: null });
  }),
  on(InternalUserStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //FETCH USER
  on(InternalUserStoreActions.FetchRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(InternalUserStoreActions.FetchSuccess, (state, action) => featureAdapter.upsertOne(action.user, { ...state, isLoading: false, error: null })),
  on(InternalUserStoreActions.FetchFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //ADD PHOTO
  on(InternalUserStoreActions.AddPhotoRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(InternalUserStoreActions.AddPhotoSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(InternalUserStoreActions.AddPhotoFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //UPDATE PHOTO
  on(InternalUserStoreActions.UpdatePhotoRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(InternalUserStoreActions.UpdatePhotoSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(InternalUserStoreActions.UpdatePhotoFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //ADD
  on(InternalUserStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(InternalUserStoreActions.AddSuccess, (state, action) => featureAdapter.upsertOne(action.user, { ...state, isLoading: false, error: null })),
  on(InternalUserStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //SELECT
  on(InternalUserStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedUserId: null,
  })),
  on(InternalUserStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedUserId: action.user && action.user.id,
  })),
  on(InternalUserStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //DESELECT
  on(InternalUserStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(InternalUserStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedUserId: null,
  })),
  on(InternalUserStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //UTILITY
  on(InternalUserStoreActions.ResetError, (state) => ({
    ...state,
    error: null,
  })),
  on(InternalUserStoreActions.UpdateTxCardFavorite, (state, action) => ({ ...state, error: null, isLoading: true })),
  on(InternalUserStoreActions.UpdateTxCardFavoriteSuccess, (state, action) =>
    featureAdapter.updateOne({ id: action.userId, changes: { txCardFavorites: action.favorites } }, { ...state, isLoading: false })
  ),
  on(InternalUserStoreActions.UpdateTxCardFavoriteFailure, (state, action) => ({ ...state, error: action.error }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
