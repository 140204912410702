import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as DoctorTimeStoreActions from './actions';
import * as DoctorTimeStoreSelectors from './selectors';
import { SettingsClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class DoctorTimeStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _settingClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DoctorTimeStoreActions.LoadRequest),
    switchMap((action) =>
      this._settingClient
        .settings_GetDoctorTimes()
        .pipe(
          map((result) => DoctorTimeStoreActions.LoadSuccess({ doctorTimes: result })),
          catchError((err: HttpErrorResponse) => of(DoctorTimeStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DoctorTimeStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(DoctorTimeStoreSelectors.selectDoctorTimeById(action.id))
        .pipe(map((result) => DoctorTimeStoreActions.SelectSuccess({ doctorTime: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DoctorTimeStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(DoctorTimeStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DoctorTimeStoreActions.AddRequest),
    switchMap((action) =>
      this._settingClient.settings_PostDoctorTime(action.doctorTime)
        .pipe(
          map((result) => DoctorTimeStoreActions.AddSuccess({ doctorTime: result })),
          catchError((err: HttpErrorResponse) => of(DoctorTimeStoreActions.AddFailure({ error: err.message })))
        ))
  );
}
