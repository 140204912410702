import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, share, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, LocationClient, PatientClient, PatientReferralDto2, StorageContentTypeEnum } from 'src/app/shared/services/api.service';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import { LocationsStoreSelectors } from '../location-store';
import { PatientMiniCardsStoreActions } from '../patient-mini-cards-store';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as PatientsStoreActions from './actions';
import * as PatientsStoreSelectors from './selectors';
import { PatientSearchHistory, PatientStoreEntity } from './state';

@Injectable({ providedIn: 'root' })
export class PatientsStoreEffects {
  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  private _selectedLocationId$ = this._store$.select(LocationsStoreSelectors.getSelectedLocationId);

  constructor(
    private _store$: Store<State>,
    private _actions$: Actions,
    private _locationClient: LocationClient,
    private _patientClient: PatientClient
  ) {}

  @Effect()
  loginSuccessEffect$: Observable<Action> = this._actions$.pipe(
    ofType(AuthStoreActions.LoginSuccess, SignalRHubStoreActions.HubStarted),
    withLatestFrom(this._store$.select(SignalRHubStoreSelectors.selectHubConnectionStatus)),
    filter(([_, status]) => status == HubConnectionState.Connected),
    switchMapTo(this._store$.select(AuthStoreSelectors.selectIsPatient)),
    filter((isPatient) => !!sessionStorage.getItem('selectedPatient') && !isPatient),
    map((_) => {
      //auto select previously selected patient
      const storedPatient = sessionStorage.getItem('selectedPatient');
      const selectedPatient: { patientId: number; locationId: number } = JSON.parse(storedPatient);
      return PatientsStoreActions.SelectRequest({ id: selectedPatient.patientId, locationId: selectedPatient.locationId });
    })
  );

  @Effect()
  logoutSuccessEffect: Observable<Action> = this._actions$.pipe(
    ofType(AuthStoreActions.LogoutSuccess),
    mapTo(PatientsStoreActions.DeselectRequest())
  );

  @Effect()
  changeTenantSuccessEffect$: Observable<Action> = this._actions$.pipe(
    ofType(AuthStoreActions.ChangeTenantSuccess),
    switchMap((action) => of(
      PatientsStoreActions.DeselectRequest(),
      PatientsStoreActions.LoadSuccess({ patients: [] }),
      PatientsStoreActions.ClearHistory()
    )),
  );

  @Effect()
  loadRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.LoadRequest),
    withLatestFrom(this._store$.select((state) => state.Patients)),
    switchMap(([action, state]) =>
      this._locationClient
        .location_GetPatients(action.locationId, null, action.pageSize || state.pageSize, action.page || state.page, null)
        .pipe(map((result) => PatientsStoreActions.LoadSuccess({ patients: result })))
    )
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.LoadOneRequest),
    switchMap((action) =>
      this._locationClient.location_GetPatient(action.locationId, action.id, null).pipe(
        map((result) => PatientsStoreActions.LoadOneSuccess({ patient: result })),
        catchError((error) => of(PatientsStoreActions.LoadOneFailure({ error })))
      )
    )
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.SelectRequest),
    mergeMap((action) => zip(of(action), this._store$.select(PatientsStoreSelectors.selectPatientById(action.id)))),
    switchMap(([action, selectedPatient]) => {
      let obs: Observable<PatientStoreEntity>;
      //If patient not loaded, fetch from api
      if (!selectedPatient) {
        obs = this._locationClient.location_GetPatient(action.locationId, action.id);
      } else {
        obs = of(selectedPatient);
      }
      //map patient into select success action
      return obs.pipe(
        map((patient) => {
          sessionStorage.setItem('selectedPatient', JSON.stringify({ patientId: action.id, locationId: action.locationId })); //store ids of selected patient for auto select on refresh
          return PatientsStoreActions.SelectSuccess({ id: action.id, locationId: action.locationId, patient: patient });
        })
      );
    })
  );

  @Effect()
  patientViewedHistoryEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.PatientViewed),
    withLatestFrom(this._store$.select(PatientsStoreSelectors.selectHistory)),
    //if patient exists in history, do not modify
    filter(([action, history]) => !(history.some((x) => x.patientId == action.patientId && x.locationId == action.locationId))),
    map(([action, history]) => {
      //keep max length to 10
      if (history.length >= 10) {
        history.splice(9, history.length - 9);
      }
      history.unshift(new PatientSearchHistory({ patientId: action.patientId, locationId: action.locationId, name: action.name }));
      return PatientsStoreActions.UpdateSearchHistory({ history: history });
    })
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.DeselectRequest),
    switchMap((action) => {
      sessionStorage.removeItem('selectedPatient');
      return of(PatientsStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.UpdateRequest),
    switchMap((action) =>
      this._locationClient.location_PutPatient(action.patient.locationId, action.patient.id, action.patient, null).pipe(
        map((result) => PatientsStoreActions.UpdateSuccess({ patient: result })),
        catchError((err: HttpErrorResponse) => of(PatientsStoreActions.UpdateFailure({ error: err.message })))
      )
    ),
    share()
  );

  @Effect()
  updateReferredByRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.AssignPatientReferredByRequest),
    switchMap((action) =>
      this._patientClient.patient_PostPatientReferral(action.referredByPatientId, new PatientReferralDto2({ patientId: action.patientId })).pipe(
        // switchMap(result => )
        take(1),
        mergeMap((result) =>
          of(
            PatientsStoreActions.LoadOneRequest({ id: action.patientId, locationId: action.locationId }),
            PatientsStoreActions.AssignPatientReferredBySuccess({ patientId: action.referredByPatientId, referral: result })
          )
        ),
        catchError((err: HttpErrorResponse) => of(PatientsStoreActions.AssignPatientReferredByFailure({ error: err })))
      )
    ),
    share()
  );

  @Effect()
  addReferringContactRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.AddReferringContactRequest),
    switchMap((action) =>
      this._patientClient.patient_PostReferringContact(action.patientId, action.contactId).pipe(
        // switchMap(result => )
        take(1),
        mergeMap((result) =>
          of(
            PatientsStoreActions.LoadOneRequest({ id: action.patientId, locationId: action.locationId }),
            PatientsStoreActions.AddReferringContactSuccess({ patientId: action.patientId, referral: result })
          )
        ),
        catchError((err: HttpErrorResponse) => of(PatientsStoreActions.AddReferringContactFailure({ error: err })))
      )
    ),
    share()
  );

  @Effect()
  removeReferredByRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.RemoveReferringContactRequest),
    switchMap((action) =>
      this._patientClient.patient_DeleteReferringContact(action.patientId, action.contactId).pipe(
        take(1),
        mergeMap((_) =>
          of(
            PatientsStoreActions.LoadOneRequest({ id: action.patientId, locationId: action.locationId }),
            PatientsStoreActions.RemoveReferringContactSuccess({ patientId: action.patientId })
          )
        ),
        catchError((err: HttpErrorResponse) => of(PatientsStoreActions.RemoveReferringContactFailure({ error: err })))
      )
    ),
    share()
  );

  @Effect()
  updateProfileRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.UpdateProfileRequest),
    switchMap((action) =>
      this._patientClient.patient_PutProfile(action.patientId, null, action.file, StorageContentTypeEnum.Profile).pipe(
        map((result) => PatientsStoreActions.UpdateProfileSuccess({ patient: result })),
        catchError((err: HttpErrorResponse) => of(PatientsStoreActions.UpdateProfileFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteProfileRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.DeleteProfileRequest),
    switchMap((action) =>
      this._patientClient.patient_DeleteProfile(action.patientId).pipe(
        map((result) => PatientsStoreActions.DeleteProfileSuccess({ patientId: action.patientId, locationId: action.locationId })),
        catchError((err: HttpErrorResponse) => of(PatientsStoreActions.DeleteProfileFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteProfileSuccessEffect: Observable<Action> = this._actions$.pipe(
    ofType(PatientsStoreActions.DeleteProfileSuccess),
    switchMap(async (action) => PatientsStoreActions.LoadOneRequest({ id: action.patientId, locationId: action.locationId }))
  );

  @Effect()
  patientUpdateEffect$: Observable<Action> = this._actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedPatientId$, this._selectedLocationId$),
    filter(
      ([action, patientId, locationId]) =>
        action.event.eventArea == HubEventArea.PatientEvent &&
        action.event.entityType == LocationClient.CreatePatientDto &&
        action.event.entityId == patientId
    ),
    map(([action, patientId, locationId]) =>
      PatientsStoreActions.LoadOneRequest({ id: patientId, locationId: locationId })
    ),
  );
}
