import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule, MatIconModule, MatProgressBarModule, MatTooltipModule } from "@angular/material";
import { ImageCropperModule } from "ngx-image-cropper";
import { ClickConfirmModule } from "../click-confirm/click-confirm.module";
import { PhotoEditorComponent } from "./photo-editor.component";
import {MatSliderModule} from '@angular/material/slider';
import {MatMenuModule} from '@angular/material/menu'; 
import { FormsModule } from '@angular/forms';
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    PhotoEditorComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ClickConfirmModule,
    ImageCropperModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSliderModule,
    MatMenuModule,
    FormsModule,
    DragDropModule
  ],
  exports: [
    PhotoEditorComponent
  ],
})
export class PhotoEditorModule { }
