 import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatBottomSheetModule,
  MatButtonModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';
import { ngfModule } from 'angular-file';
import { StlModelViewerModule } from 'angular-stl-model-viewer';
import { EvolutionColors } from 'src/app/shared/enums';
import { CardModule } from 'src/app/shared/models';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { ClickConfirmModule } from '../../click-confirm/click-confirm.module';
import { ImageErrorHandlerModule } from '../../image-error-handler/image-error-handler.module';
import { SharedModule } from '../../shared.module';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { NewFolderDialogComponent } from './new-folder-dialog/new-folder-dialog.component';
import { PatientFilesBackComponent } from './patient-files-back/patient-files-back.component';
import { PatientFilesFrontComponent } from './patient-files-front/patient-files-front.component';
import { RenameFileDialogComponent } from './rename-file-dialog/rename-file-dialog.component';
import { TwainUploadDialogComponent } from './twain-upload-dialog/twain-upload-dialog.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { PatientFilesExplorerComponent } from './patient-files-explorer/patient-files-explorer.component';
import { RenameFileModule } from './rename-file-dialog/rename-file-dialog.module';

@CardModule({
  selector: 'patient-files',
  front: PatientFilesExplorerComponent,
  back: PatientFilesBackComponent,
  header: 'Files',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: '<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.25 8.375V3.5H1.125C0.492188 3.5 0 4.01562 0 4.625V11.375C0 12.0078 0.492188 12.5 1.125 12.5H10.875C11.4844 12.5 12 12.0078 12 11.375V10.25H4.125C3.07031 10.25 2.25 9.42969 2.25 8.375ZM13.875 2H9.375L7.875 0.5H4.125C3.49219 0.5 3 1.01562 3 1.625V8.375C3 9.00781 3.49219 9.5 4.125 9.5H13.875C14.4844 9.5 15 9.00781 15 8.375V3.125C15 2.51562 14.4844 2 13.875 2Z" fill="currentColor"/></svg>', actionName: ''}],
  order: 100,
  color: EvolutionColors.Blue,
})
@NgModule({
  declarations: [
    PatientFilesFrontComponent,
    PatientFilesBackComponent,
    PatientFilesExplorerComponent,
    NewFolderDialogComponent,
    FileUploadDialogComponent,
    TwainUploadDialogComponent
  ],
  entryComponents: [
    PatientFilesFrontComponent,
    PatientFilesBackComponent,
    PatientFilesExplorerComponent,
    NewFolderDialogComponent,
    FileUploadDialogComponent,
    RenameFileDialogComponent,
    TwainUploadDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    StlModelViewerModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatInputModule,
    ClickConfirmModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    ngfModule,
    MatDividerModule,
    ImageErrorHandlerModule,
    MomentDateModule,
    FontAwesomeModule,
    RenameFileModule
  ],
})
export class PatientFilesModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
