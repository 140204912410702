import { createSelector, MemoizedSelector, createFeatureSelector } from '@ngrx/store';
import { featureAdapter } from './state';
import { State } from '../state';
import { TREATMENT_FEATURE_KEY } from '../featurekey';

const selectFeatureState: MemoizedSelector<object, State> = createFeatureSelector<State>(TREATMENT_FEATURE_KEY)

export const selectStepGroupState = createSelector(
  selectFeatureState,
  (state) => state.stepGroups
);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectStepGroupState);
export const selectStepGroupIds = selectIds;
export const selectStepGroupTotal = selectTotal;
export const selectAllStepGroups = selectAll;
export const selectAllStepGroupEntities = selectEntities;

export const selectIsActiveStepGroups = (isActive: boolean) => createSelector(
  selectAllStepGroups,
  stepGroups => {
    stepGroups.forEach(sg => sg.steps = sg.steps && sg.steps.filter(s => s.isActive == isActive));
    return stepGroups.filter(sg => sg.isActive == isActive);
  }
)

export const selectIsLoading = createSelector(
  selectStepGroupState,
  (state) => state.isLoading
);

export const selectError = createSelector(
  selectStepGroupState,
  (state) => state.error
);
