import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThreeDModelsComponent } from './3d-models.component';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { MatButtonModule, MatCardModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatProgressBarModule } from '@angular/material';
import { ngfModule } from 'angular-file';
import { StlModelViewerModule } from "angular-stl-model-viewer"
import { ReactiveFormsModule } from '@angular/forms';
import { EvolutionColors } from 'src/app/shared/enums';


@CardModule({
  selector: '3d-models',
  front: ThreeDModelsComponent,
  back: null,
  header: '3D Viewer',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faCubes', actionName: '', isFaIcon: true}],
  order: 50,
  color: EvolutionColors.Orange,
})
@NgModule({
  declarations: [ThreeDModelsComponent],
  entryComponents: [ThreeDModelsComponent],
  imports: [
    CommonModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    ngfModule,
    ReactiveFormsModule,
    StlModelViewerModule
  ],
})
export class ThreeDModelsModule { }
