import { Action, createReducer, on } from '@ngrx/store';
import { DiagnosisStoreActions, DiagnosisStoreState } from './diagnosis';
import { GoalStoreActions, GoalStoreState } from './goal';
import { PlanGroupStoreActions, PlanGroupStoreState } from './plan-group';
import { initialState, State } from './state';
import { StepGroupStoreActions, StepGroupStoreState } from './step-group';

const reducer = createReducer(
  initialState,
  //Diagnosis
  on(DiagnosisStoreActions.LoadRequest, (state) => ({
    ...state,
    diagnoses: { ...state.diagnoses, hasLoaded: true },
    ...featureLoading(state, 'diagnoses', true),
  })),
  on(DiagnosisStoreActions.LoadOnceRequest, (state, action) => ({ ...state, ...featureLoading(state, 'diagnoses', state.diagnoses.isLoading) })),
  on(DiagnosisStoreActions.LoadSuccess, (state, action) => {
    const diagnosesState = Array.isArray(action.diagnoses) ?
      DiagnosisStoreState.featureAdapter.upsertMany(action.diagnoses, { ...state.diagnoses }) :
      DiagnosisStoreState.featureAdapter.upsertOne(action.diagnoses, { ...state.diagnoses });
    return { ...state, ...featureLoading({ ...state, diagnoses: diagnosesState }, 'diagnoses', false, null, true) };
  }),
  on(DiagnosisStoreActions.LoadFailure, (state, action) => ({ ...state, ...featureLoading(state, 'diagnoses', false, action.err) })),

  //Goal
  on(GoalStoreActions.LoadRequest, (state) => ({ ...state, goals: { ...state.goals, hasLoaded: true }, ...featureLoading(state, 'goals', true) })),
  on(GoalStoreActions.LoadOnceRequest, (state, action) => ({ ...state, ...featureLoading(state, 'goals', true) })),
  on(GoalStoreActions.LoadSuccess, (state, action) => {
    const goalsState = GoalStoreState.featureAdapter.upsertMany(action.goals, { ...state.goals });
    return { ...state, ...featureLoading({ ...state, goals: goalsState }, 'goals', false, null, true) };
  }),
  on(GoalStoreActions.LoadFailure, (state, action) => ({ ...state, ...featureLoading(state, 'goals', false, action.err) })),

  //PlanGroup
  on(PlanGroupStoreActions.LoadRequest, (state) => ({
    ...state,
    planGroups: { ...state.planGroups, hasLoaded: true },
    ...featureLoading(state, 'planGroups', true),
  })),
  on(PlanGroupStoreActions.LoadOnceRequest, (state, action) => ({ ...state, ...featureLoading(state, 'planGroups', true) })),
  on(PlanGroupStoreActions.LoadSuccess, (state, action) => {
    const planGroupsState = PlanGroupStoreState.featureAdapter.upsertMany(action.planGroups, { ...state.planGroups });
    return { ...state, ...featureLoading({ ...state, planGroups: planGroupsState }, 'planGroups', false, null, true) };
  }),
  on(PlanGroupStoreActions.LoadFailure, (state, action) => ({ ...state, ...featureLoading(state, 'planGroups', false, action.err) })),

  //StepGroup
  on(StepGroupStoreActions.LoadRequest, (state) => ({
    ...state,
    stepGroups: { ...state.stepGroups, hasLoaded: true },
    ...featureLoading(state, 'stepGroups', true),
  })),
  on(StepGroupStoreActions.LoadOnceRequest, (state, action) => ({ ...state, ...featureLoading(state, 'stepGroups', true) })),
  on(StepGroupStoreActions.LoadSuccess, (state, action) => {
    const stepGroupsState = StepGroupStoreState.featureAdapter.upsertMany(action.stepGroups, { ...state.stepGroups });
    return { ...state, ...featureLoading({ ...state, stepGroups: stepGroupsState }, 'stepGroups', false, null, true) };
  }),
  on(StepGroupStoreActions.LoadFailure, (state, action) => ({ ...state, ...featureLoading(state, 'stepGroups', false, action.err) }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

/**
 * Set a sub feature's isLoading value and returns changeset of state
 * @param state The current feature store state
 * @param feature The sub property feature to be updated
 * @param isLoading The value of isLoading to set the subFeature to
 * @param error The error value to set of the sub feature, defaults to null
 */
function featureLoading<T, K extends keyof T>(state: T, feature: K, isLoading?: boolean, error: any = null, hasLoaded?: boolean) {
  let value: Partial<State> = {
    [feature]: {
      ...state[feature],
      isLoading: isLoading != null ? isLoading : state[feature]['isLoading'],
      error: error,
      hasLoaded: hasLoaded != null ? hasLoaded : state[feature]['hasLoaded']
    },
    isLoading: null,
  };
  const storeIsLoading = [{ ...state, ...value }]
    .map((s) => s.diagnoses.isLoading || s.goals.isLoading || s.planGroups.isLoading || s.stepGroups.isLoading)
    .reduce((s) => s);
  value.isLoading = storeIsLoading;
  return value;
}
