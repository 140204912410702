
import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'message-dialog',
  templateUrl: './message.dialog.html',
  styleUrls: ['./message.dialog.scss']
})
export class MessageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<MessageDialog>) {
    console.log('MessageDialog data:', data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
