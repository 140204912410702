export declare type TITLE_KEY =
  'Dr.'|
  'Rev.'|
  'Judge'|
  'Miss.'|
  'Ms'|
  'Mrs.'|
  'Mr.'|
  'Rabbi';

export declare type TITLE = {
  name: string;
};

export const PATIENT_TITLES: { [key in TITLE_KEY]: TITLE } = {
  'Dr.': {
    name: "Dr."
  },
  'Rev.': {
    name: "Rev."
  },
  'Judge': {
    name: "Judge"
  },
  'Miss.': {
    name: "Miss."
  },
  'Ms': {
    name: "Ms"
  },
  'Mrs.': {
    name: "Mrs."
  },
  'Mr.': {
    name: "Mr."
  },
  'Rabbi': {
    name: "Rabbi"
  }
};
