import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { take } from 'rxjs/operators';
import { FileDto, FolderDto, PatientClient } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-rename-file-dialog',
  templateUrl: './rename-file-dialog.component.html',
  styleUrls: ['./rename-file-dialog.component.scss'],
})
export class RenameFileDialogComponent implements OnInit {
  fileName: string;
  isWorking: boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: RenameFileDialogData,
    private _bottomSheetRef: MatBottomSheetRef<RenameFileDialogComponent>,
    private _patientClient: PatientClient
  ) {
    this.fileName = this._data.currentFileName;
  }

  ngOnInit() {}

  renameFile() {
    this.isWorking = true;
    this._patientClient
      .patient_RenameFile(
        this._data.patientId,
        this._data.parentId,
        this._data.fileId,
        this.fileName)
      .pipe(take(1))
      .subscribe((result) => {
        this.isWorking = false;
        this.done(this._data.parentId, result);
      }, err => this.isWorking = false);
  }

  done(parentId: number, file: FileDto) {
    this._bottomSheetRef.dismiss([parentId, file]);
  }
}

export interface RenameFileDialogData {
  parentId: number;
  patientId: number;
  fileId: number;
  currentFileName: string
}
