import { NgModule } from '@angular/core';
import { MomentDateModule } from '../pipe/moment-date/moment-date.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { InputComponent } from './input/input.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { SelectComponent } from './select/select.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ImageButtonComponent } from './image-button/image-button.component';
import { ImageIconComponent } from './image-icon/image-icon.component';
import { TimeIntervalComponent } from './time-interval/time-interval.component';
import { IntervalPanelComponent } from './interval-panel/interval-panel.component';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { ButtonComponent } from './button/button.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TreeMenuComponent } from './tree-menu/tree-menu.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { DialogComponent } from './dialog/dialog.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { HighlightSearch } from '../pipe/highlight.pipe';
import { DigitalSignatureModule } from './digital-signature/digital-signature.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextMaskModule } from 'angular2-text-mask';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IconSelectorComponent } from './icon-selector/icon-selector.component';

const modules = [
  InputComponent,
  CheckBoxComponent,
  SelectComponent,
  RadioGroupComponent,
  ImageButtonComponent,
  ImageIconComponent,
  TimeIntervalComponent,
  IntervalPanelComponent,
  ColorSelectorComponent,
  IconSelectorComponent,
  ButtonComponent,
  AutocompleteComponent,
  TreeMenuComponent,
  ImageUploaderComponent,
  DialogComponent,
  TextFieldComponent,
  HighlightSearch
];

@NgModule({
  declarations: modules,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DigitalSignatureModule,
    MomentDateModule,
    ScrollingModule,
    TextMaskModule,
    FontAwesomeModule
  ],
  exports: [
    ...modules,
    DigitalSignatureModule
  ],
  entryComponents: [DialogComponent]
})
export class ElementsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
