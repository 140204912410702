import { Action, createReducer, on } from '@ngrx/store';
import { PatientLedgerDto } from 'src/app/shared/services/api.service';
import { PatientsStoreActions } from '../patient-store';
import * as PatientLedgerStoreActions from './actions';
import { initialState, State, featureAdapter } from './state';

const reducer = createReducer(
  initialState,

  on(PatientLedgerStoreActions.GetPatientLedgers, (state, action) =>
    featureAdapter.removeAll({
      ...state,
      isLoading: true,
      error: null,
      page: action.page || state.page,
      pageSize: action.pageSize || state.pageSize,
      ledgerTransactions: null,
    })
  ),
  on(PatientLedgerStoreActions.GetPatientLedgersSuccess, (state, action) =>
    featureAdapter.upsertMany(action.ledgers, {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(PatientLedgerStoreActions.AddLedger, (state, action) =>
    featureAdapter.upsertOne(action.ledger, {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(PatientLedgerStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientLedgerStoreActions.ResetError, (state) => ({ ...state, error: null })),
  on(PatientLedgerStoreActions.SetSelectedLedger, (state, action) => ({
    ...state,
    selectedLedger: action.selectedLedger,
    selectedLedgerId: action.selectedLedger != null ? action.selectedLedger.id : null,
  })),
  //Hide patient info and set selected to null when patient changes
  on(PatientsStoreActions.SelectSuccess, (state) => ({ ...state, selectedLedger: null, selectedLedgerId: null, showPatientInfo: false })),
  on(PatientLedgerStoreActions.GetLedgerTransactions, (state, action) => {
    return { ...state, isLoading: true, error: null, ledgerTransactions: null };
  }),
  on(PatientLedgerStoreActions.GetLedgerTransactionSuccess, (state, action) => {
    return { ...state, isLoading: false, error: null, ledgerTransactions: action.ledgerTransactions };
  }),

  on(PatientLedgerStoreActions.GetResponsibleParties, (state, action) => {
    return { ...state, isLoading: true, error: null, responsibleParties: [] };
  }),
  on(PatientLedgerStoreActions.GetResponsiblePartiesSuccess, (state, action) => {
    return { ...state, isLoading: false, error: null, responsibleParties: action.responsibleParties };
  }),

  on(PatientLedgerStoreActions.AddTransaction, (state, action) => {
    return { ...state, isLoading: false, error: null };
  }),
  on(PatientLedgerStoreActions.RemoveLedger, (state, action) => featureAdapter.removeOne(action.ledgerId, { ...state })),

  on(PatientLedgerStoreActions.TogglePatientInfo, (state, action) => {
    return { ...state, showPatientInfo: action.flag };
  }),

  on(PatientLedgerStoreActions.GetLedgerOptions, (state, action) => {
    return { ...state, isLoading: true, error: null, ledgerOptions: null };
  }),
  on(PatientLedgerStoreActions.GetLedgerOptionsSuccess, (state, action) => {
    return { ...state, isLoading: false, error: null, ledgerOptions: action.ledgerOptions };
  }),
  on(PatientLedgerStoreActions.EmptyLedgers, (state, action) => ({...state, ledgerTransactions: [] }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
