import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_CEPHALOMETRIC_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const selectPatientCephalometricsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_CEPHALOMETRIC_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientCephalometricsState);
export const selectPatientCephalometricIds = selectIds;
export const selectAllPatientCephalometrics = selectAll;
export const selectAllPatientCephalometricEntities = selectEntities;

export const selectPatientCephalometricsError: MemoizedSelector<object, any> = createSelector(selectPatientCephalometricsState, (state) => state.error);

export const selectPatientCephalometricsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientCephalometricsState, (state: State) => state.isLoading);
