import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take, withLatestFrom, distinctUntilChanged, tap, takeUntil } from 'rxjs/operators';
import { PatientsStoreActions, PatientsStoreSelectors, RootStoreState, ContactStoreSelectors, ContactStoreActions } from 'src/app/root-store';
import {
  ClinicDto2,
  ContactDto,
  DentistReferralDirectionEnum,
  DentistReferralDto,
  PatientClient,
  ReferringContactDto,
  SearchClient,
  SearchContactDto,
  SearchContactQueryDto,
  SearchDentistDto,
  SearchPatientDto,
  SearchPatientQueryDto,
  ContactSearch
} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-patient-referral',
  templateUrl: './patient-referral.component.html',
  styleUrls: ['./patient-referral.component.css'],
})
export class PatientReferralComponent {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('dentistsearchinput', { static: false }) dentistsearchinput: ElementRef;
  private _incomingData: PatientReferralPayload;
  @Input('data') set incomingData(value) {
    if (value) {
      this._incomingData = value;

      this._dentistReferrals$ = this._incomingData.dentistReferrals$;
      this.outDentistReferrals$ = this._dentistReferrals$.pipe(
        map((referrals) => referrals.filter((r) => r.direction == DentistReferralDirectionEnum.Outbound))
      );
      this.inDentistReferrals$ = this._dentistReferrals$.pipe(
        map((referrals) => referrals.filter((r) => r.direction == DentistReferralDirectionEnum.Inbound))
      );

      this.referringContacts$ = this._incomingData.referringContacts$;
    }
  }
  get incomingData() {
    return this._incomingData;
  }

  isWorking$ = this._store$
    .select(PatientsStoreSelectors.selectPatientsIsLoading);
  selectedPatient$ = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  private _dentistReferrals$: Subject<DentistReferralDto[]>;
  outDentistReferrals$: Observable<DentistReferralDto[]>;
  inDentistReferrals$: Observable<DentistReferralDto[]>;
  referringContacts$: Observable<ReferringContactDto[]>;

  //Dentist Search
  professionalSearchFormControl: FormControl = new FormControl();
  professionalSearch$: Subject<string | ProfessionalSearchResult> = new Subject<string | ProfessionalSearchResult>();
  professionalSearchResult$: Observable<SearchDentistDto[]> = this.professionalSearch$.pipe(
    startWith(''),
    filter((value) => (typeof value === 'string' ? value.length > 1 : false)),
    map((search) => search as string),
    debounceTime(500),
    switchMap((search) => this._searchClient.search_GetDentists(search)),
    shareReplay(1)
  );
  professionalDisplayWith: (value: ProfessionalSearchResult) => string = (value) => value && value[0].displayName;

  //Patient Search
  contactSearchFormControl: FormControl = new FormControl();
  contactSearch$: Subject<string> = new Subject<string>();
  isSearching$: Observable<boolean> = this._store$.select(ContactStoreSelectors.selectIsLoading);

  contactSearchResult$: Observable<ContactSearch[]> = this._store$.select(ContactStoreSelectors.selectAllContacts);
  contactSearchResult:ContactSearch[] = [];

  contactDisplayWith: (value: SearchContactDto) => string = (value) => value && this.getContactNameString(value);

  //toggles
  showInboundProfessionalSearch: boolean = false;
  showReferringContactSearch: boolean = false;
  showOutboundProfessionalSearch: boolean = false;

  DentistReferralDirectionEnum = DentistReferralDirectionEnum;

  showDeletePopup:boolean = false;
  dentistReferralId: number;
  initSearch:boolean = false;

  constructor(private _store$: Store<RootStoreState.State>, private _patientClient: PatientClient, private _searchClient: SearchClient) { }

  ngOnInit(){

    this.contactSearch$
      .pipe(
        filter((value) => !!value),
        distinctUntilChanged(),
        debounceTime(1000),
        tap((value) => this._store$.dispatch(ContactStoreActions.SearchRequest({ search: value, page: 0, pageSize: 4 }))),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => null);

    this.contactSearchResult$.pipe(filter(contacts => !!contacts)).subscribe(contacts => {
      let searchValue = this.contactSearchFormControl.value;
      this.contactSearchResult = contacts.filter(contact => contact.firstName.toLowerCase().includes(searchValue) || contact.lastName.toLowerCase().includes(searchValue));

    })
  }

  getContactAddress(contact: SearchContactDto): string {
    let address = '';
    if (contact.primaryAddress) {
      if (contact.primaryAddress.addressLine1)
        address += contact.primaryAddress.addressLine1;
      if (contact.primaryAddress.addressLine2 && contact.primaryAddress.addressLine2.length > 0)
        address += ', ' + contact.primaryAddress.addressLine2;
      if (contact.primaryAddress.city && contact.primaryAddress.city.length > 0) address += ', ' + contact.primaryAddress.city;
      if (contact.primaryAddress.state && contact.primaryAddress.state.length > 0) address += ', ' + contact.primaryAddress.state;
      if (contact.primaryAddress.zip && contact.primaryAddress.zip.length > 0) address += ' ' + contact.primaryAddress.zip;
    }
    return address;
  }

  getContactPhoneEmail(contact: SearchContactDto): string {
    let phoneEmail = '';
    if (contact.primaryPhone)
      phoneEmail += contact.primaryPhone.number + (contact.primaryPhone.extension ? ' ' + contact.primaryPhone.extension : '');
    if (contact.primaryEmail) phoneEmail += ' ' + contact.primaryEmail.emailAddress;
    return phoneEmail;
  }

  add() {}

  addDentistReferral(value, direction: DentistReferralDirectionEnum) {
    //reset form and hide boxes
    this.professionalSearchFormControl.setValue(null);
    switch (direction) {
      case DentistReferralDirectionEnum.Inbound:
        this.showInboundProfessionalSearch = false;
        break;
      case DentistReferralDirectionEnum.Outbound:
        this.showOutboundProfessionalSearch = false;
        break;
    }

    console.log("addDentistReferral ", value)

    //get current patient Id and create new referral
    const [dentist, clinic] = value;
    this._selectedPatientId$
      .pipe(
        take(1),
        switchMap((patientId) => {
          const referral = new DentistReferralDto({
            id: 0,
            clinicId: clinic.id,
            dentistId: dentist.id,
            direction: direction,
            patientId: patientId,
            comments: '',
            referralDate: new Date(),
          });
          return this._patientClient.patient_PostDentistReferral(patientId, referral);
        }),
        withLatestFrom(this._dentistReferrals$)
      )
      .subscribe(([result, referrals]) => {
        //add new referral and emit new list
        referrals.push(result);
        this._dentistReferrals$.next(referrals);
      });
  }

  removeDentistReferral(_dentistReferralId: number) {
    this.dentistReferralId = _dentistReferralId;
    this.showDeletePopup = true;
  }

  updateDentistReferral(dentistReferral: DentistReferralDto, model: NgModel) {
    this._selectedPatientId$
      .pipe(
        switchMap((patientId) => this._patientClient.patient_PutDentistReferral(patientId, dentistReferral.id, dentistReferral).pipe(take(1))),
        withLatestFrom(this._dentistReferrals$),
        take(1)
      )
      .subscribe(([_, referrals]) => {
        model.control.markAsPristine();
        const ind = referrals.findIndex((r) => r.id == dentistReferral.id);
        if (ind > -1) referrals.splice(ind, 1);
        referrals.push(dentistReferral);
        this._dentistReferrals$.next(referrals);
      });
  }

  addReferringContact(referringContact: ContactSearch) {
    this.contactSearchFormControl.setValue(null);
    this.showReferringContactSearch = false;
    this.selectedPatient$.pipe(take(1)).subscribe((patient) => {
      this._store$.dispatch(
        PatientsStoreActions.AddReferringContactRequest({
          patientId: patient.id,
          contactId: referringContact.id,
          locationId: patient.locationId
        })
      );
    });
  }

  removeReferringContact(contactId: number) {
    this.selectedPatient$.pipe(take(1)).subscribe((patient) => {
      this._store$.dispatch(
        PatientsStoreActions.RemoveReferringContactRequest({
          patientId: patient.id,
          contactId: contactId,
          locationId: patient.locationId
        })
      );
    });
  }

  valueIsStringOrNull(value: any) {
    return !value || typeof value === 'string';
  }

  getContactNameString(contact: SearchContactDto) {
    let value = [];
    if (!!contact.nickname) {
      value.push(contact.nickname);
      value.push(`(${contact.firstName})`);
    } else {
      value.push(contact.firstName);
    }
    value.push(contact.lastName);
    if (contact.relationshipType)
      value.push(`(${contact.relationshipType})`);
    return value.join(' ');
  }

  noDelete(){
		this.showDeletePopup = false;
  }

  confirmDelete(){
    //get current patient and remove referral with provided ID
    this._selectedPatientId$
      .pipe(
        switchMap((patientId) => this._patientClient.patient_DeleteDentistReferral(patientId, this.dentistReferralId).pipe(take(1))),
        withLatestFrom(this._dentistReferrals$),
        take(1)
      )
      .subscribe(([_, referrals]) => {
        //update with removed referral and emit new list
        const ind = referrals.findIndex((r) => r.id == this.dentistReferralId);
        if (ind > -1) referrals.splice(ind, 1);
        this._dentistReferrals$.next(referrals);
        this.showDeletePopup = false;
      });
  }


  getSerarchValue(searchobj, direction){
    this.dentistsearchinput.nativeElement.value = searchobj.dentist.displayName;
    this.addDentistReferral([searchobj.dentist, searchobj.clinic], direction)
  }

  
}

export interface PatientReferralPayload {
  editor?: string;
  header?: string;
  dentistReferrals$: ReplaySubject<DentistReferralDto[]>;
  referringContacts$: Observable<ReferringContactDto[]>;
}

type ProfessionalSearchResult = [SearchDentistDto, ClinicDto2];
