import { RelationshipTypeEnum } from '../services/api.service';

export enum RelationshipTypeLabel {
  Patient = 'Patient',
  Father = 'Father',
  Mother = 'Mother',
  StepFather = 'Step-Father',
  StepMother = 'Step-Mother',
  Spouse = 'Spouse',
  Partner = 'Partner',
  GrandFather = 'Grand Father',
  GrandMother = 'Grand Mother',
  Uncle = 'Uncle',
  Aunt = 'Aunt',
  FosterParent = 'Foster Parent',
  Other = 'Other'
}

export declare type RelationshipTypeCode = 'PA' | 'FA'| 'MO' | 'SF' | 'SM' | 'SP' | 'PR' | 'GF' | 'GM'  | 'UN' | 'AU' | 'FP' | 'OT';
export declare type RELATIONSHIP = { type : RelationshipTypeEnum, label: RelationshipTypeLabel; };
export const RELATIONSHIPS: { [key in RelationshipTypeCode] : RELATIONSHIP } = {
  'PA': {
    type: RelationshipTypeEnum.Patient,
    label: RelationshipTypeLabel.Patient
  },
  'FA': {
    type: RelationshipTypeEnum.Father,
    label: RelationshipTypeLabel.Father
  },
  'MO': {
    type: RelationshipTypeEnum.Mother,
    label: RelationshipTypeLabel.Mother
  },
  'SF': {
    type: RelationshipTypeEnum.StepFather,
    label: RelationshipTypeLabel.StepFather
  },
  'SM': {
    type: RelationshipTypeEnum.StepMother,
    label: RelationshipTypeLabel.StepMother
  },
  'SP': {
    type: RelationshipTypeEnum.Spouse,
    label: RelationshipTypeLabel.Spouse
  },
  'PR': {
    type: RelationshipTypeEnum.Partner,
    label: RelationshipTypeLabel.Partner
  },
  'GF': {
    type: RelationshipTypeEnum.GrandFather,
    label: RelationshipTypeLabel.GrandFather
  },
  'GM': {
    type: RelationshipTypeEnum.GrandMother,
    label: RelationshipTypeLabel.GrandMother
  },
  'UN': {
    type: RelationshipTypeEnum.Uncle,
    label: RelationshipTypeLabel.Uncle
  },
  'AU': {
    type: RelationshipTypeEnum.Aunt,
    label: RelationshipTypeLabel.Aunt
  },
  'FP': {
     type: RelationshipTypeEnum.FosterParent,
     label: RelationshipTypeLabel.FosterParent
   },
  'OT': {
    type: RelationshipTypeEnum.Other,
    label: RelationshipTypeLabel.Other
  },
}
