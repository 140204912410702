import { createAction, props } from "@ngrx/store";
import { HeaderSection } from "./HeaderSection";

export const SelectHeaderSection = createAction(
  '[Header] Select Header Section',
  props<{section: HeaderSection}>()
);

export const ClearHeaderSection = createAction(
  '[Header] Clear Header Section'
);

export const SetHeaderHeight = createAction(
  '[Header] Set Height',
  props<{height: number}>()
);
