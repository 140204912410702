import { ActivatedRouteSnapshot } from "@angular/router";
import { routerRequestAction, routerNavigationAction } from "@ngrx/router-store";
import { Action, createReducer, on } from "@ngrx/store";
import * as HeaderStoreActions from "./actions";
import { initialState, State } from "./state";

/**
 * Recursively search the activated route for the data value 'headerSection' with child values taking priority over parent
 * Note: Only searches the first child
 * @param s
 */
function getRouteData(s: ActivatedRouteSnapshot) {
  let section: string = null
  if(s && s.data && s.data['headerSection']){
    section = s.data['headerSection'];
  }
  if(s && s.children && s.children.length > 0){
    section = getRouteData(s.children[0]) || section;
  }
  return section;
}

const reducer = createReducer(
  initialState,
  on(HeaderStoreActions.SelectHeaderSection, (state, action) => ({...state, selectedSection: action.section})),
  on(HeaderStoreActions.ClearHeaderSection, (state, action) => ({...state, selectedSection: null})),
  on(HeaderStoreActions.SetHeaderHeight, (state, action) => ({...state, headerHeight: action.height})),
  on(routerNavigationAction, (state, action) => ({...state, selectedSection: getRouteData(action.payload.routerState.root)}))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
