import { createAction, props } from "@ngrx/store";
import { TemplateStoreEntity } from './state';
import { IDocumentTemplateDto, DocumentTemplateDto } from "../../shared/services/api.service";

//LOAD PAGE
export const LoadRequest = createAction(
  '[Template] Load Request',
  props<{ id?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Template] Load Success',
  props<{ templates: TemplateStoreEntity | TemplateStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Template] Load Failure',
  props<{ error: any; }>()
);

export const DeleteSuccess = createAction(
  '[Template] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Template] Delete Failure',
  props<{ error: string; }>()
);
