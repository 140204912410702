import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ContactSearch } from 'src/app/shared/services/api.service';

export const featureAdapter = createEntityAdapter<ContactSearch>({
  selectId: (contact) => contact.id,
  sortComparer: (a,b) => a.id - b.id
});

export interface State extends EntityState<ContactSearch> {
  isLoading?: boolean;
  error?: any;
  contact?: ContactSearch;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  contact: null
});
