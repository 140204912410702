import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, filter, mapTo } from 'rxjs/operators';
import { SettingsClient } from 'src/app/shared/services/api.service';
import { RootStoreState } from '../..';
import * as GoalStoreActions from './actions';
import * as GoalStoreSelectors from './selectors';
import { stat } from 'fs';

@Injectable({ providedIn: 'root' })
export class TreatmentGoalEffects {
  constructor(private store$: Store<RootStoreState.State>, private actions$: Actions, private settingsClient: SettingsClient) {}

  loadRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GoalStoreActions.LoadRequest),
      switchMap(() =>
        this.settingsClient.settings_GetGoals().pipe(
          map((result) => GoalStoreActions.LoadSuccess({ goals: result })),
          catchError((error) => of(GoalStoreActions.LoadFailure({ err: error })))
        )
      )
    );
  });

  loadOnceRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GoalStoreActions.LoadOnceRequest),
      withLatestFrom(this.store$.select(GoalStoreSelectors.selectGoalState)),
      filter(([_, state]) => !state.hasLoaded),
      mapTo(GoalStoreActions.LoadRequest())
    );
  });
}
