import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { POSTING_CODE_FEATURE_KEY } from './featurekey';
import { featureAdapter, PostingCodeStoreEntity, State } from './state';

export const selectPostingCodeState: MemoizedSelector<object, State> = createFeatureSelector<State>(POSTING_CODE_FEATURE_KEY);

export const getSelectedPostingCodeId = createSelector(selectPostingCodeState, (state) => state.selectedPostingCodeId);

const { selectAll, selectEntities } = featureAdapter.getSelectors(selectPostingCodeState);

export const selectedAllPostingCodes = selectAll;
export const selectAllPostingCodesEntities = selectEntities;

export const getSelectedPostingCode = createSelector(
  selectPostingCodeState,
  selectedAllPostingCodes,
  (state, postingCodeList: PostingCodeStoreEntity[]) =>
    postingCodeList ? postingCodeList.find((item) => item.id === state.selectedPostingCodeId) : null
);

export const selectPostingCodeById = (id: number) =>
  createSelector(selectedAllPostingCodes, (postingCodeList: PostingCodeStoreEntity[]) =>
    postingCodeList ? postingCodeList.find((item) => item.id === id) : null
  );

export const selectPostingCodeError: MemoizedSelector<object, any> = createSelector(selectPostingCodeState, (state) => state.error);

export const selectPostingCodeIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPostingCodeState, (state: State) => state.isLoading);
