import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, Observable, Observer, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as htmlToImage from 'html-to-image';
import {
  LocationsStoreSelectors,
  LocationStoreEntity,
  PatientsStoreSelectors,
  PatientStoreEntity,
  PhotoGroupStoreEntity,
  PhotoGroupStoreSelectors,
  PhotoTypeStoreActions,
  PhotoTypeStoreEntity,
  PhotoTypeStoreSelectors,
  RootStoreState
} from '../../../root-store';
import {
  FileParameter,
  FillTypeEnum,
  FromTypeEnum,
  IPatientTreatmentImageDto,
  MontageCellDto,
  MontageCellTypeEnum,
  MontageRowDto,
  MontageSettingDto,
  PatientClient,
  PatientTreatmentImageDto,
  PatientTreatmentImageGroupDto,
  SettingsClient,
  StorageContentTypeEnum
} from '../../../shared/services/api.service';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

interface MontageSize {
  name: string;
  width: number;
  height: number;
  isDefault: boolean;
}

interface IPatientTreatmentCroppedImage extends IPatientTreatmentImageDto {
  photoImageTransform: ImageTransform;
  croppedImage: string;
}

@Component({
  selector: 'montage-generator',
  templateUrl: './montage-generator.component.html',
  styleUrls: ['./montage-generator.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MontageGeneratorComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  fillTypeKeys = Object.keys(FillTypeEnum);
  fillTypeEnum = FillTypeEnum;
  selectedCroppedImage: IPatientTreatmentCroppedImage;
  imageChangedEvent: any = '';
  scale: number = 1;
  rotate: number = 0;
  photoImageTransform: ImageTransform = {};

  isWorking: boolean = false; 
  private fetchAll$: any;
  patient: PatientStoreEntity;
  photoGroups: PhotoGroupStoreEntity[];
  photoTypes: PhotoTypeStoreEntity[];
  location: LocationStoreEntity;
  montageSettings: MontageSettingDto[];
  patientTreatmentImageGroups: PatientTreatmentImageGroupDto[];
  selectedPhotoGroup: PatientTreatmentImageGroupDto;
  selectedMontageSetting: MontageSettingDto;
  selectedPhotoTypeId: number;
  selectedMontageSize: MontageSize;
  fieldMerges: any = {};
  montageSizes: MontageSize[] = [];

  constructor(
    private _dialogRef: MatDialogRef<MontageGeneratorComponent>,
    private _snackbar: MatSnackBar,
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    private _settingsClient: SettingsClient,
    private _domSanitizer: DomSanitizer,
    private _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.isWorking = true;
    this._store$.dispatch(PhotoTypeStoreActions.LoadRequest({}));
    this._store$.dispatch(PhotoTypeStoreActions.LoadRequest({}));
    this.initializeData();
    this.initializeMontageSizes();
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._cdr.detectChanges();
  }

  initializeMontageSizes(): void {
    this.montageSizes = [
      { name: 'Default', width: 0, height: 0, isDefault: true },
      { name: 'SD (852 x 480)', width: 852, height: 480, isDefault: false },
      { name: 'HD (1280 x 720)', width: 1280, height: 720, isDefault: false },
      { name: 'Full HD (1920 x 1080)', width: 1920, height: 1080, isDefault: false }
    ];
    this.selectedMontageSize = this.montageSizes[0];
  }

  initializeData(): void {
    this.fetchAll$ = combineLatest(
      this._store$.select(PatientsStoreSelectors.getSelectedPatient),
      this._store$.select(PhotoGroupStoreSelectors.selectAllPhotoGroups),
      this._store$.select(PhotoTypeStoreSelectors.selectAllPhotoTypes),
      this._store$.select(LocationsStoreSelectors.getSelectedLocation),
      this._settingsClient.settings_GetMontageSettings()
    )
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(([patient, photoGroups, photoTypes, location, montageSettings]) => {
        if (
          patient
          && photoGroups && photoGroups.length > 0
          && photoTypes && photoTypes.length > 0
          && location
          && montageSettings && montageSettings.length > 0
        ) {
          this.patient = patient;
          this.photoGroups = _.filter(photoGroups, 'isActive');
          this.photoTypes = _.chain(photoTypes)
            .filter('isActive')
            .sortBy(['name'])
            .value();
          this.location = location;
          this.montageSettings = montageSettings.filter(montageSetting => montageSetting.isActive);
          
          this.fieldMerges['{PatFirst}'] = this.patient.firstName;
          this.fieldMerges['{PatLast}'] = this.patient.lastName;
          this.fieldMerges['{PatId}'] = this.patient.id;
          this.fieldMerges['{PatDOB}'] = this.patient.dob ? moment.utc(this.patient.dob).format('MM/DD/YYYY') : '';
          this.fieldMerges['{PatAge}'] = this.patient.dob ? this.calculateAge(this.patient.dob) : '';

          this.loadGalleryImages();
        }
      });
  }

  processFiledMerge(infoBoxMessage: string) {
    if (!this.selectedPhotoGroup) return;

    this.fieldMerges['{TakenWhen}'] = this.selectedPhotoGroup.takenWhen ?
      moment(this.selectedPhotoGroup.takenWhen).tz(this.location.ianaTimeZone).format('MM/DD/YYYY') :
      '';
    this.fieldMerges['{AgeTaken}'] = this.selectedPhotoGroup.takenWhen && this.patient.dob ?
      this.calculateDateDiff(this.selectedPhotoGroup.takenWhen, this.patient.dob) :
      '';
    this.fieldMerges['{PhotoGroupName}'] = this.selectedPhotoGroup.photoGroupName;

    _.each(this.fieldMerges, (val, key) => {
      infoBoxMessage = infoBoxMessage.replace(key, val);
    });

    return this.sanitizeInfoBox(infoBoxMessage);
  }

  sanitizeInfoBox(infoBoxMessage: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(infoBoxMessage);
  }

  private loadGalleryImages(): void {
    this.isWorking = true;
    this._patientClient.patient_GetPatientTreatmentImageGroups(
      this.patient.id
    )
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((res: PatientTreatmentImageGroupDto[]) => {
        this.patientTreatmentImageGroups = res;
        _.each(this.patientTreatmentImageGroups, (patientTreatmentImageGroup: any) => {
          patientTreatmentImageGroup.patientTreatmentImages = _.chain(patientTreatmentImageGroup.patientTreatmentImages)
            .uniqBy('photoTypeId')
            .keyBy('photoTypeId')
            .value();

          patientTreatmentImageGroup.dobExamDiff = this.calculateDateDiff(patientTreatmentImageGroup.takenWhen, this.patient.dob);
        });
        this.isWorking = false;
        this._cdr.detectChanges();
      }, (err) => {
        console.log('montage error:', err);
        this.isWorking = false;
        this._cdr.detectChanges();
      });
  }

  private calculateDateDiff(examDate, dateOfBirth) {
    var duration = moment.duration(moment(examDate).tz(this.location.ianaTimeZone).diff(moment(dateOfBirth)));
    return `${duration.years()}y ${duration.months()}m`;
  }

  private calculateAge(dateOfBirth) {
    var duration = moment.duration(moment(new Date()).tz(this.location.ianaTimeZone).diff(moment(dateOfBirth)));
    return `${duration.years()}y ${duration.months()}m`;
  }

  close(): void {
    this._dialogRef.close();
    this._cdr.detectChanges();
  }

  saveMontage(): void {
    if (!this.selectedPhotoGroup || !this.selectedMontageSetting || !this.selectedPhotoTypeId) return;
    this.isWorking = true;

    htmlToImage.toBlob(document.getElementById('montage-setting-preview'))
      .then((blob) => {
        const data = new Blob([blob], { type: blob.type })
        const file: FileParameter = {
          data,
          fileName: `${this.selectedPhotoGroup.photoGroupName} - ${moment().format('MM/DD/YYYY')} - Montage.jpg`,
        };

        let patientTreatmentImage = _.find(this.selectedPhotoGroup.patientTreatmentImages, (p: PatientTreatmentImageDto) => {
          return p.photoTypeId == this.selectedPhotoTypeId;
        });

        let uploadMontageRequest: Observable<void>;

        if (patientTreatmentImage) {
          uploadMontageRequest = this._patientClient.patient_PutPatientTreatmentImageUploadImage(
            this.patient.id,
            this.selectedPhotoGroup.id,
            patientTreatmentImage.id,
            StorageContentTypeEnum.Imaging,
            file,
            this.selectedPhotoTypeId,
            this.selectedPhotoGroup.photoGroupId
          );
        }
        else {
          uploadMontageRequest = this._patientClient.patient_PostPatientTreatmentImageUploadImage(
            this.patient.id,
            this.selectedPhotoGroup.id,
            StorageContentTypeEnum.Imaging,
            file,
            this.selectedPhotoTypeId,
            this.selectedPhotoGroup.photoGroupId
          );
        }

        uploadMontageRequest
          .pipe(
            takeUntil(this._destroy$),
            take(1)
          )
          .subscribe(() => {
            this.isWorking = false;
            this.openSnackBar("Successfully saved", 'Ok')
            this._dialogRef.close(true);
          }, (err) => {
            console.log('upload error:', err);
            this.isWorking = false;
          });
      });

    this._cdr.detectChanges();
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
    this._cdr.detectChanges();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.selectedCroppedImage.croppedImage = event.base64;
    this.selectedCroppedImage.photoImageTransform = this.photoImageTransform;
    this._cdr.detectChanges();
  }

  imageLoaded() {
    if (!this.selectedCroppedImage.photoImageTransform) {
      this.photoImageTransform = {
        rotate: 0,
        scale: 1
      };
      this.scale = 1;
      this.rotate = 0;
    }
    else {
      this.photoImageTransform = this.selectedCroppedImage.photoImageTransform;
      this.scale = this.selectedCroppedImage.photoImageTransform.scale ? this.selectedCroppedImage.photoImageTransform.scale : 1;
      this.rotate = this.selectedCroppedImage.photoImageTransform.rotate ? this.selectedCroppedImage.photoImageTransform.rotate : 0;
    }

    this.openSnackBar("Image loaded", 'Ok');
    this.isWorking = false;
    this._cdr.detectChanges();
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed(): void {
    this.openSnackBar("Failed to load image", 'Ok');
    this.isWorking = false;
    this._cdr.detectChanges();
  }

  selectTreatmentImage(treatmentImage: IPatientTreatmentCroppedImage): void {
    this.selectedCroppedImage = treatmentImage;
    this.imageChangedEvent = null;
    this._cdr.detectChanges();
  }

  resetImage() {
    this.photoImageTransform = {
      rotate: 0,
      scale: 1
    };
    this.scale = 1;
    this.rotate = 0;
    this.openSnackBar("Image reset", 'Ok');
    this._cdr.detectChanges();
  }

  flipHorizontal(): void {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      flipH: !this.photoImageTransform.flipH
    };
    this._cdr.detectChanges();
  }

  flipVertical(): void {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      flipV: !this.photoImageTransform.flipV
    };
    this._cdr.detectChanges();
  }

  zoomOut() {
    this.scale -= .1;
    this.photoImageTransform = {
      ...this.photoImageTransform,
      scale: this.scale
    };
    this._cdr.detectChanges();
  }

  zoomIn() {
    this.scale += .1;
    this.photoImageTransform = {
      ...this.photoImageTransform,
      scale: this.scale
    };
    this._cdr.detectChanges();
  }

  imageRotation() {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      rotate: this.rotate
    };
    this._cdr.detectChanges();
  }

  initializeSelectedPhotoGroup(): void {
    this.selectedCroppedImage = null

    if (this.selectedPhotoGroup && this.selectedMontageSetting) {
      this.selectedMontageSetting.montageRows.forEach((row) => {
        row.montageCells.forEach((cell) => {
          if (cell.cellType == MontageCellTypeEnum.Photo &&
            this.selectedPhotoGroup.patientTreatmentImages[cell.photoTypeId]) {
            this.selectedPhotoGroup.patientTreatmentImages[cell.photoTypeId].fillType = cell.fillType;
          }
        });
      });
    }
  }
}
