import { createReducer, on } from '@ngrx/store';
import * as AuthStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  on(AuthStoreActions.LoginRequest, (state, action) => ({ ...state, isLoading: true, username: action.authModel.email })),
  on(AuthStoreActions.LoginSuccess, (state, action) =>
    featureAdapter.upsertOne(action.userCredentials, { ...state, selectedTokenId: action.userCredentials.id })
  ),
  on(AuthStoreActions.LoginFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),

  on(AuthStoreActions.LogoutRequest, (state, action) => ({ ...state, error: null, isLoading: true })),
  on(AuthStoreActions.LogoutSuccess, (state, action) => {
    if (action.ids && action.ids.length) {
      return featureAdapter.removeMany(action.ids, {
        ...state,
        isLoading: false,
        selectedTokenId: null,
        parsedToken: null,
      });
    }
    else {
      return featureAdapter.removeOne(action.id, {
        ...state,
        isLoading: false,
        selectedTokenId: state.selectedTokenId == action.id || !action.id ? null : state.selectedTokenId,
        parsedToken: state.selectedTokenId == action.id || !action.id ? null : state.parsedToken,
      });
    }
  }),
  on(AuthStoreActions.LogoutFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),

  on(AuthStoreActions.RefreshRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(AuthStoreActions.RefreshSuccess, (state, action) =>
    featureAdapter.updateOne({ id: action.id, changes: action.userCredentials }, { ...state, isLoading: false, error: null })
  ),
  on(AuthStoreActions.RefreshFailure, (state, action) => featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: action.error })),

  on(AuthStoreActions.LoginSelect, (state, action) => ({ ...state, selectedTokenId: action.id })),
  on(AuthStoreActions.AddTokens, (state, action) => {
    return featureAdapter.upsertMany(action.tokens, { ...state });
  }),
  on(AuthStoreActions.UpdateToken, (state, action) => featureAdapter.updateOne(action, { ...state })),
  on(AuthStoreActions.SetTenant, (state, action) => ({
    ...state,
    tenantName: action.tenantName,
    tenantKey: action.tenantKey,
    isPatient: action.isPatient,
    saveSession: action.saveSession == undefined ? state.saveSession : action.saveSession,
  })),

  on(AuthStoreActions.ChangeTenantRequest, (state, action) => ({
    ...state,
    tenantName: action.tenantName,
    tenantKey: action.tenantKey,
    saveSession: action.saveSession == undefined ? state.saveSession : action.saveSession,
  })),
  on(AuthStoreActions.ChangeTenantSuccess, (state, action) => {
    return featureAdapter.upsertOne(action.userCredentials, { ...state, selectedTokenId: action.userCredentials.id });
  }),
  on(AuthStoreActions.ChangeTenantFailure, (state, action) => featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: action.error })),

  on(AuthStoreActions.SetParsedToken, (state, action) => ({ ...state, parsedToken: action.parsedToken }))
);

export function featureReducer(state: State | undefined, action) {
  return reducer(state, action);
}
