import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MatMenuTrigger, MatSnackBar, MatTreeNestedDataSource } from '@angular/material';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { combineLatest, isObservable, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CardsStoreActions, CardsStoreSelectors, LocationsStoreSelectors, PatientsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { FileDto, FolderDto, PatientClient, StorageContentTypeEnum, StorageItemBaseDto, StorageTypeEnum } from 'src/app/shared/services/api.service';
import { Download } from 'src/app/shared/services/download/download';
import { DownloadService } from 'src/app/shared/services/download/download.service';
import { FileViewerComponent } from '../../../file-viewer/file-viewer.component';
import { FileUploadDialogComponent, FileUploadDialogData } from '../file-upload-dialog/file-upload-dialog.component';
import { NewFolderDialogComponent, NewFolderDialogData } from '../new-folder-dialog/new-folder-dialog.component';
import { RenameFileDialogComponent, RenameFileDialogData } from '../rename-file-dialog/rename-file-dialog.component';
import { TwainUploadDialogComponent, TwainUploadDialogData } from '../twain-upload-dialog/twain-upload-dialog.component';
import {faFolderPlus } from '@fortawesome/free-solid-svg-icons';

import {faFileZipper } from '@fortawesome/pro-solid-svg-icons';
import { saveAs } from 'file-saver';

interface TreeNode {
  fileDtoId?: number;
  name: string;
  cardSelector?: string;
  storageItem?: StorageItemBaseDto;
  children$?: Observable<TreeNode[]>;
  children?: TreeNode[];
  isLoaded?: boolean;
  isWorking?: boolean;
  type: StorageTypeEnum;
  childrenCount?: number;
  parentId?: number;
  contentType?: StorageContentTypeEnum;
  download$?: Observable<Download>;
  restrictedFolder?: boolean;
  createdWhen?: Date;
  isTop?: boolean;
  isImage?: boolean;
}

class CustomNestedTreeControl<T> extends NestedTreeControl<T> {
  canCollapse: (dataNode: T) => boolean = (_) => true;
  constructor(getChildren: (dataNode: T) => Observable<T[]> | T[] | undefined | null, canCollapse?: (dataNode: T) => boolean) {
    super(getChildren);
    if (canCollapse) this.canCollapse = canCollapse;
  }
}

@UntilDestroy()
@Component({
  selector: 'patient-files-explorer',
  templateUrl: './patient-files-explorer.component.html',
  styleUrls: ['./patient-files-explorer.component.scss'],
})
export class PatientFilesExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
  storageTypeEnum = StorageTypeEnum;
  breadcrumbs: TreeNode[];
  thumbnailRef: MatDialogRef<FileViewerComponent, any>;
  selectedLocation$ = this._rootStore$.select(LocationsStoreSelectors.getSelectedLocation);
  selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId)
    .pipe(
      untilDestroyed(this),
      filter((id) => !!id),
      distinctUntilChanged()
    )
    .pipe(map(patientId => {
      this.patientId = patientId;
      if (!!this.patientId) {
        this.initialize();
      }
      return patientId;
    }));
  patientId: number;
  private _loadFolder$: Subject<[number, TreeNode]> = new Subject<[number, TreeNode]>();
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  moveFiles: TreeNode[] = [];
  restrictedFolders: string[] = ['Forms', 'Attachments'];

  defaultFoldersWorking:boolean = false;
  faFolderPlus = faFolderPlus;

  //ROOT TREE NODES
  treeData: TreeNode[];
  topNode: TreeNode;
  selectedNode: TreeNode;

  contextMenuPosition = { x: 0, y: 0 };
  @ViewChild('matMenuTriggerFolder', { read: MatMenuTrigger, static: false }) contextFolderMenu: MatMenuTrigger;
  @ViewChild('matMenuTriggerFile', { read: MatMenuTrigger, static: false }) contextFileMenu: MatMenuTrigger;

  private _imageMimeTypes: string[] = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp'
  ];

  faFileZipper = faFileZipper;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    @Inject(CARD_DATA) private _data: ICardData<any, StorageItemBaseDto>,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _downloadService: DownloadService,
    private _rootStore$: Store<RootStoreState.State>,
  ) { }

  ngOnInit(): void { }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngAfterViewInit(): void {
  }

  initialize(): void {
    let folderObservable = this.getFolder(null);
    folderObservable
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => console.log("folder loaded"));

    this.topNode = {
      name: 'Files',
      children$: folderObservable,
      fileDtoId: null,
      type: StorageTypeEnum.Folder,
      parentId: null,
      isTop: true,
      isImage: false
    };
    this.treeData = [this.topNode];
    setTimeout(() => {
      this.loadFolder(this.topNode);
    });
  }

  hasChild = (_: number, node: TreeNode) => !!node.children || node.type == StorageTypeEnum.Folder;

  openCard(cardSelector: string) {
    this._store$
      .select(CardsStoreSelectors.getCardOrderBySelector('patient-files'))
      .pipe(take(1))
      .subscribe((order) => this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: cardSelector, order: order + 1 })));
  }

  loadFolder(node: TreeNode) {
    if (!node.isLoaded) {
      this._loadFolder$.next([node.fileDtoId, node]);
    }
    this.generateBreadcrumbs(node);

    console.log("load folder", node);
  }

  mapFoldersToTreeNode(contents: FileDto[]): TreeNode[] {
    if (contents && contents.length > 0) {
      return _.chain(contents)
        .orderBy(['type', 'createdWhen'], ['desc', 'asc'])
        .map((c: FileDto) => { return this.mapContentToTreeNode(c); })
        .value();
    }
    return [];
  }

  mapContentToTreeNode(content: FileDto): TreeNode {
    if (content.type == StorageTypeEnum.File) {
      return <TreeNode>{
        name: this.getNodeName(content),
        storageItem: content,
        cardSelector: this.contentTypeToCardSelector(content.contentType),
        type: StorageTypeEnum.File,
        parentId: content.parentId,
        contentType: content.contentType,
        createdWhen: content.createdWhen,
        isImage: content && this._imageMimeTypes.includes(content.locationContentType.toLowerCase())
      };
    } else if (content.type == StorageTypeEnum.Folder) {
      let folderObservable = this.getFolder(content.id);
      folderObservable
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => console.log("folder loaded"));

      return <TreeNode>{
        fileDtoId: content.id,
        name: content.name,
        children$: folderObservable,
        type: StorageTypeEnum.Folder,
        childrenCount: content.childrenCount,
        isLoaded: content.childrenCount > 0 ? false : true,
        parentId: content.parentId,
        restrictedFolder: _.indexOf(this.restrictedFolders, content.name) > -1,
        isImage: false
      };
    } else {
      return <TreeNode>{
        name: 'File Missing or Broken',
      };
    }
  }

  getNodeName(file: FileDto): string {
    if (file.contentType == StorageContentTypeEnum.Other) {
      return file.name;
    }
    else {
      return file.name.replace(/\.[^/.]+$/, "");
    }
  }

  getFolder(fileDtoId: number): Observable<TreeNode[]> {
    return this._loadFolder$.pipe(
      filter(([folderId, node]) => {
        return folderId == fileDtoId &&
               !!this.patientId;
      }),
      tap(([folderId, node]) => (node.isLoaded = false)),
      switchMap(([folderId, node]) =>
        combineLatest([
          of(node),
          folderId == null ? this._patientClient.patient_GetFolders(this.patientId) : this._patientClient.patient_GetFolder(this.patientId, folderId),
        ])
      ),
      map(([node, contents]) => {
        node.isLoaded = true;
        node.childrenCount = contents.length;
        node.children = this.mapFoldersToTreeNode(contents);
        return node.children;
      }),
      shareReplay()
    );
  }

  contentTypeToCardSelector(contentType: StorageContentTypeEnum): string {
    switch (contentType) {
      case StorageContentTypeEnum.Ceph:
        return 'cephalometrics';
      case StorageContentTypeEnum.Imaging:
      case StorageContentTypeEnum.IntraOral:
        return 'patient-imaging';
      case StorageContentTypeEnum.Model:
        return '3d-models';
      case StorageContentTypeEnum.Contract:
      case StorageContentTypeEnum.Attachment:
      case StorageContentTypeEnum.Other:
      default:
        return null;
    }
  }

  openAddFolderDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data = <NewFolderDialogData>{
      parentId: node.fileDtoId,
      patientId: this.patientId
    };

    this._bottomSheet
      .open<NewFolderDialogComponent, NewFolderDialogData, [number, FolderDto]>(NewFolderDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const [parentId, folder] = result;
          this._loadFolder$.next([parentId, node]);
        }
      });
  }

  openRenameFileDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data = <RenameFileDialogData>{
      parentId: node.parentId,
      patientId: this.patientId,
      fileId: node.storageItem.id,
      currentFileName: node.storageItem.name
    };

    this._bottomSheet
      .open<RenameFileDialogComponent, RenameFileDialogData, [number, FolderDto]>(RenameFileDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.refreshParentFolder(node);
        }
      });

    this.contextFileMenu.closeMenu();
  }

  refreshFolder(node: TreeNode) {
    this._loadFolder$.next([node.fileDtoId, node]);
  }

  private getParentNode(node: TreeNode, nodes: TreeNode[]): TreeNode {
    if (node.parentId) {
      let parentNode = nodes.find((n) => n.fileDtoId == node.parentId);
      if (parentNode)
        return parentNode;

      nodes.forEach((x: TreeNode) => {
        if (!parentNode && x.isLoaded && x.children) {
          parentNode = this.getParentNode(node, x.children);
        }
      });

      return parentNode;
    } else {
      return this.treeData[0];
    }
  }

  private refreshParentFolder(node: TreeNode) {
    this.refreshFolder(this.getParentNode(node, this.treeData));
  }

  deleteFolder(node: TreeNode) {
    if (!this.patientId) return;

    if (node.childrenCount == 0) {
      this._patientClient.patient_DeleteFolder(this.patientId, node.fileDtoId)
        .subscribe((_) => {
          this.refreshParentFolder(node);
          this._snackbar.open('Folder successfully deleted', 'OK', { duration: 2500 });
        });
    }
  }

  openFileUploadDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data: FileUploadDialogData = {
      folderId: node.fileDtoId,
      contentType: StorageContentTypeEnum.Other,
      patientId: this.patientId,
    };

    this._dialog
      .open<FileUploadDialogComponent, FileUploadDialogData, any>(FileUploadDialogComponent, { data, maxWidth: 800 })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this._snackbar.open('File successfully uploaded', 'OK', { duration: 2000 });
          //this.treeControl.expand(node);
          this.refreshFolder(node);
        }
      });
  }

  openTwainUploadDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data: TwainUploadDialogData = {
      folderId: node.fileDtoId,
      contentType: StorageContentTypeEnum.Other,
      patientId: this.patientId,
    };

    this._dialog
      .open<TwainUploadDialogComponent, TwainUploadDialogData, any>(TwainUploadDialogComponent, { data, width: '500px' })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this._snackbar.open('Twain successfully uploaded', 'OK', { duration: 2000 });
          //this.treeControl.expand(node);
          this.refreshFolder(node);
        }
      });
  }

  canDelete(node: TreeNode) {
    return node.type == StorageTypeEnum.File && node.contentType == StorageContentTypeEnum.Other;
  }

  canRename(node: TreeNode) {
    return node.type == StorageTypeEnum.File && node.contentType == StorageContentTypeEnum.Other;
  }

  deleteFile(node: TreeNode) {
    if (!this.patientId) return;

    node.isWorking = true;
    this._patientClient.patient_DeleteFile(this.patientId, node.storageItem.parentId, node.storageItem.id)
      .pipe(take(1))
      .subscribe((_) => {
        node.isWorking = false;
        this._snackbar.open('File successfully deleted', 'OK', { duration: 2000 });
        this.refreshParentFolder(node);
      });

    this.contextFileMenu.closeMenu();
  }

  downloadFile(node: TreeNode) {
    node.download$ = this._downloadService.download(
      node.storageItem.locationUrl,
      node.storageItem.name);

    this.contextFileMenu.closeMenu();
  }

  addMoveFile(node: TreeNode) {
    if (this.moveFiles.length == 0 && node.storageItem) {
      this.moveFiles.push(node);
      this._snackbar.open('Please select folder to move file', 'OK', { duration: 2000 });
    }

    this.contextFileMenu.closeMenu();
  }

  moveFile(node: TreeNode) {
    if (!this.patientId) return;

    if (this.moveFiles.length > 0) {
      node.isWorking = true;
      let moveFile = this.moveFiles[0];
      this.defaultFoldersWorking = true;
      let parentNode = this.getParentNode(moveFile, this.treeData);
      this._patientClient.patient_MoveFile(
        this.patientId,
        moveFile.storageItem.parentId,
        moveFile.storageItem.id,
        node.fileDtoId,
        null,
        moveFile.storageItem.eTag)
      .pipe(take(1))
      .subscribe((_) => {
        this.moveFiles = [];
        node.isWorking = false;
        this._snackbar.open('File successfully moved', 'OK', { duration: 2000 });
        this.refreshFolder(parentNode);
        this.loadFolder(node);
        this.refreshFolder(node);
        this.defaultFoldersWorking = false;
      });

      this.contextFolderMenu.closeMenu();
    }
  }

  cancelMoveFile(): void {
    this.moveFiles = [];
  }

  private getFileExtension(fileName: string) {
    return fileName.split('.').reverse()[0].toUpperCase();
  }

  openAddDefaultFolderDialog(node?: TreeNode) {
    if (!this.patientId) return;

    this.defaultFoldersWorking = true;
    this._patientClient.patient_PostDefaultFolders(this.patientId)
      .subscribe(resp => {
        this.defaultFoldersWorking = false;
        if(node){
          this.refreshFolder(node)
        } else {
          this.refreshFolder(this.treeData[0])
        }

      },
      err => {
        console.log(err);
        this.defaultFoldersWorking = false;
      });
  }

  showFile(file: StorageItemBaseDto) {
    this._dialog.open(
      FileViewerComponent,
      {
        data: {
          thumbnailUrl: file.locationThumbnailUrl,
          url: file.locationUrl,
          contentType: file.locationContentType,
          isThumbnail: false
        },
        hasBackdrop: true,
        height: '100%',
        width: '100%',
        restoreFocus: false
      }
    );
  }

  showFileThumbnail(file: StorageItemBaseDto, elementRef: ElementRef): void {
    this.closeFileThumbnail();
    this.thumbnailRef = this._dialog.open(
      FileViewerComponent,
      {
        data: {
          thumbnailUrl: file.locationThumbnailUrl,
          url: file.locationUrl,
          contentType: file.locationContentType,
          isThumbnail: true,
          trigger: elementRef
        },
        hasBackdrop: false,
        restoreFocus: false
      }
    );
  }

  closeFileThumbnail(): void {
    if (this.thumbnailRef)
      this.thumbnailRef.close();
  }

  generateBreadcrumbs(node: TreeNode): void {
    this.breadcrumbs = [this.topNode];
    let parentNode = this.getParentBreadcrumbs(node);

    if (parentNode) {
      this.breadcrumbs.push(parentNode);
    }

    if (!node.isTop) {
      this.breadcrumbs.push(node);
    }

    this.selectedNode = node;
  }

  getParentBreadcrumbs(node: TreeNode): TreeNode {
    if (!node || !node.parentId) {
      return null;
    }

    let parentNode = this.getParentNode(node, this.treeData);
    if (parentNode && parentNode.fileDtoId && parentNode.fileDtoId == node.parentId) {
      let parentBreadcrumbsNode = this.getParentBreadcrumbs(parentNode);
      if (parentBreadcrumbsNode) {
        this.breadcrumbs.push(parentBreadcrumbsNode);
      }
      return parentNode;
    }
    else {
      return null;
    }
  }

  onContextFolderMenu(event: MouseEvent, node: TreeNode, parent: HTMLDivElement) {
    event.preventDefault();
    let rect = parent.getBoundingClientRect();
    this.contextMenuPosition.x = event.clientX - rect.left + 10;
    this.contextMenuPosition.y = event.clientY - rect.top + 40;
    this.contextFolderMenu.menuData = { 'node': node };
    this.contextFolderMenu.menu.focusFirstItem('mouse');
    this.contextFolderMenu.openMenu();
  }

  onContextFileMenu(event: MouseEvent, node: TreeNode, parent: HTMLDivElement) {
    event.preventDefault();
    let rect = parent.getBoundingClientRect();
    this.contextMenuPosition.x = event.clientX - rect.left + 10;
    this.contextMenuPosition.y = event.clientY - rect.top + 40;
    this.contextFileMenu.menuData = { 'node': node };
    this.contextFileMenu.menu.focusFirstItem('mouse');
    this.contextFileMenu.openMenu();
  }

  downloadZipFile(node: TreeNode){
    this.selectedNode.isWorking = true;
    this._patientClient.patient_GetFolderContent(this.patientId, node.fileDtoId).subscribe(resp => {
      this.selectedNode.isWorking = false;
      if(resp.data){
        saveAs(resp.data, resp.fileName);
        this._snackbar.open('Zip file sucessfully downloaded', 'OK', { duration: 2500 });
      }
      
    },
    err => {
      this.selectedNode.isWorking = false;
      this._snackbar.open(err.message, 'OK', { duration: 2500 });
    })
  }
}
