import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, HubEventType, LocationClient, ScheduleGridOnDeckDto } from 'src/app/shared/services/api.service';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import { LocationsStoreActions, LocationsStoreSelectors } from '../location-store';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as OnDeckStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class OnDeckStoreEffects {
  private _selectedLocationId$ = this.store$.select(LocationsStoreSelectors.getSelectedLocationId);

  constructor(private store$: Store<State>, private actions$: Actions, private _locationClient: LocationClient) {}

  // Load OnDeck when user logs in
  loadOnLoginRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.SelectSuccess),
      withLatestFrom(this.store$.select(AuthStoreSelectors.selectIsPatient)),
      filter(([_,isPatient]) => !isPatient),
      map(([action]) => OnDeckStoreActions.LoadRequest({ locationId: action.location.id })),
    )
  );

  loadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnDeckStoreActions.LoadRequest),
      switchMap((action) => {
        if (action.appointmentId == null) {
          return this._locationClient.location_GetScheduleOnDeck(action.locationId).pipe(
            map((ondeck) => OnDeckStoreActions.LoadSuccess({ entries: ondeck })),
            catchError((err) => of(OnDeckStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        } else {
          return this._locationClient.location_GetScheduleOnDeckAppointment(action.locationId, action.appointmentId).pipe(
            map((ondeck) => OnDeckStoreActions.LoadSuccess({ entries: ondeck })),
            catchError((err) => of(OnDeckStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        }
      })
    )
  );

  // Listen for updates from hub
  deckUpdateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      withLatestFrom(this._selectedLocationId$),
      filter(
        ([action, locationId]) =>
          action.event.eventArea == HubEventArea.ScheduleEvent &&
          action.event.entityType == LocationClient.ScheduleGridOnDeckDto &&
          action.event.locationId == locationId
      ),
      map(([action, locationId]) =>
        action.event.eventType != HubEventType.Removed
          ? OnDeckStoreActions.LoadRequest({ locationId: locationId, appointmentId: action.event.entityId })
          : OnDeckStoreActions.Remove({ appointmentId: action.event.entityId })
      ),
    )
  );
}
