import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ContactStoreActions, ContactStoreSelectors, PatientsStoreSelectors, PatientStoreEntity, ResponsiblePartyStoreEntity as ResponsiblePartyStoreEntity, ResponsiblePartyStoreActions, ResponsiblePartyStoreSelectors, RootStoreState, PatientsStoreActions } from 'src/app/root-store';
import { InsuranceCompanyStoreSelectors } from 'src/app/root-store/insurancecompany-store';
import { LANGUAGES_LIST, LANGUAGE_KEY, RELATIONSHIPS, USSTATE_LIST } from 'src/app/shared/enums';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { ContactClient, ContactDto, GenderEnum, PhoneTypeEnum, RelationshipDto, PreferredContactMethodEnum, IContactPhoneDto2, IContactEmailDto2, ContactEmailDto2, ContactPhoneDto2, SearchContactQueryDto, SearchClient, SearchContactDto, ContactPhoneDto, EmailTypeEnum, ContactSearch, PatientClient } from 'src/app/shared/services/api.service';
import { isNullOrUndefined } from 'util';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PATIENT_TITLES } from 'src/app/shared/enums/PatientTitleType';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import * as moment from 'moment';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { MessageDialog } from '../../../../dialogs/message/message.dialog';
import { Subscription } from 'rxjs';
import { FutureMessagesDialogComponent } from '../../../../dialogs/future-messages-dialog/future-messages-dialog.component';

export enum PHONE_TYPE {
  mobilePhone = 'mobilePhone',
  alternatePhone1 = 'alternatePhone1',
  alternatePhone2 = 'alternatePhone2'
}

@UntilDestroy()
@Component({
  selector: 'app-patient-relationships-back',
  templateUrl: './patient-relationships-back.component.html',
  styleUrls: ['./patient-relationships-back.component.css'],
})
export class PatientRelationshipsBackComponent implements OnInit, OnDestroy {
  @ViewChild('formGroupDirective', { static: true }) formGroupDirective: FormGroupDirective;
  @ViewChild('relationshipSearch', { static: true }) relationshipSearch: ElementRef<HTMLInputElement>;

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private includeLanguages: string[] = ['en', 'es'];
  private _listenSuccess: Subscription;
  private _listenFailure: Subscription;
  phoneTypes = PHONE_TYPE;
  responsibleParty: ResponsiblePartyStoreEntity;
  responsiblePartyFormGroup: FormGroup;
  isSameAddressControl: FormControl = new FormControl();
  ignoreAddressChange: boolean = false;
  mode: 'edit' | 'add';
  selectedPatientLocationId: number;
  LANGUAGES_LIST = _.pickBy(LANGUAGES_LIST, (value, key) => {
    return this.includeLanguages.includes(key);
  });
  USSTATE_LIST = USSTATE_LIST;
  insuranceCompanies$ = this._store$.select(InsuranceCompanyStoreSelectors.selectAllInsuranceCompanies);
  PhoneType = PhoneTypeEnum;
  Gender = GenderEnum;
  relationshipList = Object.entries(RELATIONSHIPS)
    .filter(([key, relationship]) => relationship.label != 'Patient')
    .map(([key, relationship]) => ({ value: relationship.type, label: `${key} - ${relationship.label}` }))
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  isWorking: boolean;
  isWorking$: Observable<boolean> = this._store$.select(ResponsiblePartyStoreSelectors.selectResponsiblePartysIsLoading);
  error$: Observable<any> = this._store$.select(ResponsiblePartyStoreSelectors.selectResponsiblePartysError);
  searchFormControl: FormControl;
  searchInput$: Subject<string> = new Subject<string>();
  searchResults$: Observable<ContactSearch[]> = this._store$.select(ContactStoreSelectors.selectAllContacts);
  isSearching$: Observable<boolean> = this._store$.select(ContactStoreSelectors.selectIsLoading);
  phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  titleArr: { name: string;}[] = Object.entries(PATIENT_TITLES).map(([key, value]) => ({
		name: value.name,
	  }));

	filterTitleArr:any[];

  dialog: DialogComponent;
  warningDialogOpen:boolean = false;
  notificationList: any = {}
  saveBtnClicked: boolean = false;
  existingResponsibleParties: SearchContactDto[];
  isExistingResponsibleParty: boolean;
  searchResults: ContactSearch[] = [];
  selectedPatient: PatientStoreEntity;

  constructor(@Inject(CARD_DATA) public data: ICardData,
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private _actions$: Actions,
    private _contactClient: ContactClient,
    private _searchClient: SearchClient,
    private _patientClient: PatientClient,
    public matDialog: MatDialog
    ) {
    this.data.backgroundColor = '#eaf6fa';
    this.dialog = new DialogComponent(matDialog);
    this.setInitialFormGroup();
  }

  ngOnInit() {
    //Listen for incoming events from the other side
    this.data.incoming
      .pipe(
        map((data) => <ResponsiblePartyStoreEntity>data),
        withLatestFrom(this._store$.select(PatientsStoreSelectors.getSelectedPatient)),
        takeUntil(this._destroy$)
      )
      .subscribe(([responsibleParty, selectedPatient]) => {
        this.selectedPatient = selectedPatient;
        this.setInitialFormGroup();
        this.setFormValue(responsibleParty, selectedPatient);
        this.responsiblePartyFormGroup.markAllAsTouched();
      });

    //Listen for input on contact search field to dispatch search action
    this.searchInput$
      .pipe(
        filter((value) => !!value),
        distinctUntilChanged(),
        debounceTime(1000),
        tap((value) => this._store$.dispatch(ContactStoreActions.SearchRequest({ search: value, page: 0, pageSize: 4 }))),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => null);

    //Listen for events on the primary address field to uncheck same address
    this.responsiblePartyFormGroup
      .get('primaryAddress')
      .valueChanges.pipe(
        takeUntil(this._destroy$),
        filter(() => !this.ignoreAddressChange)
      )
      .subscribe(() => this.isSameAddressControl.setValue(false));

    this.searchResults$.pipe(filter(contacts => !!contacts)).subscribe(contacts => {
      let searchValue = this.searchFormControl.value;
      this.searchResults = contacts.filter(contact => contact.firstName.toLowerCase().includes(searchValue) || contact.lastName.toLowerCase().includes(searchValue));

    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
  }

  ngAfterViewInit() {
		setTimeout(() => {
      this.titleAutoComplete();
      this.checkElemmentEditOnNotification();
		}, 500);
	}

  /**
   * Build the primary FormGroup using the FormBuilder
   */
  setInitialFormGroup() {
    this.responsiblePartyFormGroup = this._fb.group({

      id: [],
      patientId: [null, Validators.required],
      contactId: [],
      relationshipType: [null, Validators.required],
      firstName: [null, Validators.required],
      middleName: [],
      title: [],
      lastName: [null, Validators.required],
      nickname: [],
      languageKey: ['en', Validators.required],
      gender: [GenderEnum.Unknown],
      dob: [],
      ssn: [],
      preferredContactMethod: [PreferredContactMethodEnum.Phone],
      primaryAddress: this._fb.group({
        id: [],
        addressType: ['Home', Validators.required],
        addressLine1: [],
        addressLine2: [],
        city: [],
        state: [null, Validators.maxLength(2)],
        zip: [null, Validators.maxLength(10)],
        eTag: [],
      }),
      mobilePhone: this._fb.group({
        id: [],
        isPrimary: false,
        phoneType: [],
        number: [],
        extension: [],
        eTag: [],
      }),
      alternatePhone1: this._fb.group({
        id: [],
        isPrimary: false,
        phoneType: [],
        number: [],
        extension: [],
        eTag: [],
      }),
      alternatePhone2: this._fb.group({
        id: [],
        isPrimary: false,
        phoneType: [],
        number: [],
        extension: [],
        eTag: [],
      }),
      primaryEmail: this._fb.group({
        id: [],
        emailType: ['Personal'],
        emailAddress: [null, Validators.email],
        eTag: [],
      }),
      contactETag: [],
      insuranceCompanyId: [],
      employerId: [],
      employerName: [],
      groupPolicyNumber: [],
      individualPolicyNumber: [],
      comments: [],
      eTag: [],
      employeeId: [],
      licenseId: [],
      isSecureAccess: []
    });

    this.searchFormControl = this._fb.control([]);
    this.filterTitleArr = this.titleArr;
  }

  /**
   * Populates the FormGroup with the information from the provided responsible party and patient, updates mode based upon Ids
   * @param responsibleParty Responsible party information
   * @param selectedPatient Patient relating to the responsible party
   */
  setFormValue(responsibleParty: ResponsiblePartyStoreEntity, selectedPatient: PatientStoreEntity) {
    const isSameContact = responsibleParty.contactId == selectedPatient.contactId;
    const isSameAddress = (responsibleParty.primaryAddress && responsibleParty.primaryAddress.id) == (selectedPatient.primaryAddress && selectedPatient.primaryAddress.id);
    this.isSameAddressControl.setValue(isSameAddress);
    if (responsibleParty && responsibleParty.id > 0) {
      this.mode = 'edit';
      this.responsibleParty = responsibleParty;

      const defaultPhone: IContactPhoneDto2 = {
        id: null,
        number: null,
        extension: null,
        phoneType: null,
        isPrimary: false
      };

      const defaultEmail: IContactEmailDto2 = {
        id: null,
        emailType: null,
        emailAddress: null
      };

      let patchValues = {
        ...responsibleParty,
        primaryEmail: responsibleParty.primaryEmail || new ContactEmailDto2(defaultEmail),
        mobilePhone: responsibleParty.mobilePhone || new ContactPhoneDto2(defaultPhone),
        alternatePhone1: responsibleParty.alternatePhone1 || new ContactPhoneDto2(defaultPhone),
        alternatePhone2: responsibleParty.alternatePhone2 || new ContactPhoneDto2(defaultPhone),
        employeeId: responsibleParty.employeeId,
        licenseId: responsibleParty.licenseId,
        dob: moment(responsibleParty.dob).isValid() == true ? moment(responsibleParty.dob).utc().format('MM/DD/YYYY') : responsibleParty.dob
      };

      Object.keys(patchValues).forEach((key) => isNullOrUndefined(patchValues[key]) && delete patchValues[key]);
      this.responsiblePartyFormGroup.patchValue(patchValues);
    } else if (responsibleParty && responsibleParty.id === 0) {
      this.mode = 'add';
      this.responsibleParty = undefined;
      this.responsibleParty = new RelationshipDto({ ...this.responsibleParty, ...responsibleParty });
      this.formGroupDirective.resetForm(this.responsibleParty);
      this.formGroupDirective.control.markAllAsTouched();
      this.responsiblePartyFormGroup.reset(this.responsibleParty);
      this.searchFormControl.reset();
      this.searchFormControl.enable();
    }
    this.setDisabled(isSameContact);
  }

  /**
   * If true, sets the address data to be the same as currently selected patient, otherwise it clears information
   * @param isSame If responsible parties address is the same as patients
   */
  setSameAddress(isSame: boolean) {
    if (isSame) {
      this._store$
        .select(PatientsStoreSelectors.getSelectedPatient)
        .pipe(
          take(1),
          takeUntil(this._destroy$),
          tap((_) => this.responsiblePartyFormGroup.get('primaryAddress').markAsTouched())
        )
        .subscribe((patient) => {
          this.ignoreAddressChange = true;
          let primaryAddress = this.responsiblePartyFormGroup.get('primaryAddress').value;
          this.responsiblePartyFormGroup.patchValue({
            primaryAddress: {
              ...patient.primaryAddress,
              id: primaryAddress ? primaryAddress.id : null,
              eTag: primaryAddress ? primaryAddress.eTag : null
            }
          });
          this.ignoreAddressChange = false;
        });
    } else this.responsiblePartyFormGroup.get('primaryAddress').reset();
  }

  /**
   * Sets most controls of the form as disabled, primarily for use when contact on responsible party is same as patient
   * Enables form on false
   * @param isDisabled
   */
  setDisabled(isDisabled: boolean) {
    if (!isDisabled) {
      this.responsiblePartyFormGroup.enable();
      return;
    }

    this.responsiblePartyFormGroup.get('title').disable();
    this.responsiblePartyFormGroup.get('firstName').disable();
    this.responsiblePartyFormGroup.get('lastName').disable();
    this.responsiblePartyFormGroup.get('middleName').disable();
    this.responsiblePartyFormGroup.get('nickname').disable();
    this.responsiblePartyFormGroup.get('languageKey').disable();
    this.responsiblePartyFormGroup.get('gender').disable();
    this.responsiblePartyFormGroup.get('relationshipType').disable();
    this.responsiblePartyFormGroup.get('gender').disable();
    this.responsiblePartyFormGroup.get('dob').disable();
    this.responsiblePartyFormGroup.get('ssn').disable();
    this.responsiblePartyFormGroup.get('preferredContactMethod').disable();
    this.responsiblePartyFormGroup.get('primaryAddress').disable();
    this.responsiblePartyFormGroup.get('mobilePhone').disable();
    this.responsiblePartyFormGroup.get('alternatePhone1').disable();
    this.responsiblePartyFormGroup.get('alternatePhone2').disable();
    this.responsiblePartyFormGroup.get('primaryEmail').disable();
    this.responsiblePartyFormGroup.get('employerName').disable();
    this.responsiblePartyFormGroup.get('comments').disable();
  }

  /**
   * Cancels the current edit by flipping card, reseting form to defaults, and resetting any errors on responsible party store
   */
  cancel() {
    this._store$.dispatch(ResponsiblePartyStoreActions.ResetError());
    this.clearLinkedContact();
    this.isSameAddressControl.setValue(false);
    this.data.flip.next(<IFlipEvent>{ side: this.data.side });
  }

  /**
   * Saves the current edit of the responsible party dispatching either an update or add request based upon mode, flips back upon success
   */

  saveOnMouseDn(evt){
    if (evt.which == 1) {
      this.saveBtnClicked = true;
      if(!this.warningDialogOpen){
        this.save();
      }

    }
  }

  clearPhones(phone: ContactPhoneDto2) {
    return phone && (phone.number || phone.id) ? phone : null;
  }

  save() {
    let patient = null;
    this.responsibleParty = { ...this.responsibleParty, ...this.responsiblePartyFormGroup.getRawValue() }; //recasting form values onto patient object, this will future proof if new properties are added to ResponsibleParty

    if(!moment(this.responsibleParty.dob).isValid()){
      this.responsibleParty.dob = null;
    }

    this.responsibleParty.mobilePhone.number = this.responsibleParty.mobilePhone.number != null ? this.responsibleParty.mobilePhone.number.replace(/\D/g,"") : null;
    this.responsibleParty.alternatePhone1.number = this.responsibleParty.alternatePhone1.number != null ? this.responsibleParty.alternatePhone1.number.replace(/\D/g,"") : null;
    this.responsibleParty.alternatePhone2.number = this.responsibleParty.alternatePhone2.number != null ? this.responsibleParty.alternatePhone2.number.replace(/\D/g, "") : null;

    this.responsibleParty.mobilePhone = this.clearPhones(this.responsibleParty.mobilePhone);
    this.responsibleParty.alternatePhone1 = this.clearPhones(this.responsibleParty.alternatePhone1);
    this.responsibleParty.alternatePhone2 = this.clearPhones(this.responsibleParty.alternatePhone2);

    //Get locationId from selected patient then send responsible party update/add request
    const dispatchAction =
      this.mode === 'edit'
        ? ResponsiblePartyStoreActions.UpdateRequest({ locationId: null, responsibleParty: this.responsibleParty })
        : ResponsiblePartyStoreActions.AddRequest({ locationId: null, responsibleParty: this.responsibleParty });
    this._store$
      .select(PatientsStoreSelectors.getSelectedPatient)
      .pipe(
        map((p) => {
          patient = p;
          return patient.locationId;
        }),
        take(1)
      )
      .subscribe((locationId) => {
        dispatchAction.locationId = locationId;
        this._store$.dispatch(dispatchAction);
      });

    //Listen for success prior to dispatching request
    const listenForAction = this.mode === 'edit' ? ResponsiblePartyStoreActions.UpdateSuccess : ResponsiblePartyStoreActions.AddSuccess;
    this._listenSuccess = this._actions$
      .pipe(ofType(listenForAction),
        take(1),
        takeUntil(this._destroy$))
      .subscribe((result) => {
        this._listenSuccess.unsubscribe();
        this._listenFailure.unsubscribe();
        if (patient) {
          this._store$.dispatch(ResponsiblePartyStoreActions.LoadRequest({
            patientId: patient.id,
            locationId: patient.locationId
          }));
        }
        this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
      });

    const listenForFailuer = this.mode === 'edit' ? ResponsiblePartyStoreActions.UpdateFailure : ResponsiblePartyStoreActions.AddFailure;
    this._listenFailure = this._actions$
      .pipe(ofType(listenForFailuer),
        take(1),
        takeUntil(this._destroy$))
      .subscribe((result) => {
        this._listenSuccess.unsubscribe();
        this._listenFailure.unsubscribe();
        if (patient) {
          this._store$.dispatch(ResponsiblePartyStoreActions.LoadRequest({
            patientId: patient.id,
            locationId: patient.locationId
          }));
        }

        let data = {
          message: result.error
        };

        if (result.error == 'Duplicate email.') {
          data.message = `You have selected to make this email secure. Another person already is using this
                email as a secure email. Please use another email.`;
        }

        this.dialog.open(MessageDialog, data);
        this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
      });
  }

  /**
   * Returns display text to be used upon selection of an auto-complete option
   * @param contact Contact retrieved via autocomplete
   */
  searchDisplayWith(contact: ContactDto) {
    if (contact && contact.id) return contact.firstName + ' ' + contact.lastName;
  }

  /**
   * Removes data linking responsible party to a specific contact thus creating a new contact upon save
   */
  clearLinkedContact() {
    this.responsiblePartyFormGroup.patchValue({
      contactId: 0,
      contactEtag: null,
      primaryAddress: { id: null, eTag: null },
      mobilePhone: { id: null, eTag: null },
      primaryEmail: { id: null, eTag: null },
      eTag: null
    });
    this.searchFormControl.enable();
    this.searchFormControl.setValue(null);
  }

  titleAutoComplete() {

    this.responsiblePartyFormGroup.controls['title'].valueChanges.pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {
        if (res != null) {
          let searchkeyword: any = res.toLowerCase();
          if (searchkeyword.length > 0) {
            this.filterTitleArr = this.titleArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
            if (this.filterTitleArr.length == 0) {
              this.filterTitleArr = this.titleArr;
            }
          } else {
            this.filterTitleArr = this.titleArr;
          }
        }

      })
  }

  dateMask() {
		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
		return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
  }

  dateValueChange(evt){
    this.responsiblePartyFormGroup.patchValue({
      dob:  moment(evt.value).format("MM/DD/YYYY")
    })
  }

  phoneIsPrimaryChanged(type: string, val: any) {
    if (!val || !val.checked) return;

    if (type != PHONE_TYPE.mobilePhone) {
      let mobilePhone = this.responsiblePartyFormGroup.get(PHONE_TYPE.mobilePhone).value;
      this.responsiblePartyFormGroup.patchValue({ mobilePhone: { ...mobilePhone, isPrimary: false } });
    }

    if (type != PHONE_TYPE.alternatePhone1) {
      let alternatePhone1 = this.responsiblePartyFormGroup.get(PHONE_TYPE.alternatePhone1).value;
      this.responsiblePartyFormGroup.patchValue({ alternatePhone1: { ...alternatePhone1, isPrimary: false } });
    }

    if (type != PHONE_TYPE.alternatePhone2) {
      let alternatePhone2 = this.responsiblePartyFormGroup.get(PHONE_TYPE.alternatePhone2).value;
      this.responsiblePartyFormGroup.patchValue({ alternatePhone2: { ...alternatePhone2, isPrimary: false } });
    }

    this.responsiblePartyFormGroup.markAsTouched();
  }

  checkElemmentEditOnNotification() {
    this.responsiblePartyFormGroup.get('primaryEmail').get('emailAddress').valueChanges.pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {
        if (this.responsibleParty && this.responsibleParty.primaryEmail && this.responsibleParty.primaryEmail.emailAddress != res) {
          this.warningDialogOpen = true;
        } else {
          this.warningDialogOpen = false;
        }
      });

    this.responsiblePartyFormGroup.get('mobilePhone').get('number').valueChanges.pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {
        if (!res) return;
        let mobileNumber: any = res.replace(/\D/g, "");
        if (this.responsibleParty && this.responsibleParty.mobilePhone && this.responsibleParty.mobilePhone.number != mobileNumber) {
          this.warningDialogOpen = true;
        } else {
          this.warningDialogOpen = false;
        }
      })
  }

  elementChangeOnBlur(section){
    let email: any = this.responsiblePartyFormGroup.get('primaryEmail').get('emailAddress').value;
    let mobile: any = this.responsiblePartyFormGroup.get('mobilePhone').get('number').value;

    if(this.warningDialogOpen == true){

      let type: any = {};

      if (section == 'email') {
        type = email == '' ? 'removed' : 'change';
      } else if(section == 'mobile'){
        type = mobile == '' ? 'removed' : 'change';
      }

      this.dialog.open(WarningDialogComponent, {type: type, element: section}).afterClosed().subscribe(resp => {
        this.warningDialogOpen = false;

        if (resp == 'cancel') {
          if (section == 'email') {
            this.responsiblePartyFormGroup.get('primaryEmail').patchValue({
              emailAddress: this.responsibleParty.primaryEmail.emailAddress
            });
          } else if(section == 'mobile'){
            this.responsiblePartyFormGroup.get('mobilePhone').patchValue({
              number: this.responsibleParty.mobilePhone.number
            })
          }

          this.saveBtnClicked = false;
        } else if (resp == 'continue') {
          this.checkFutureMessages();
          if (this.saveBtnClicked == true) {
            this.save();
          }
        } else {
          this.saveBtnClicked = false;
        }
      });
    }
  }

  checkFutureMessages() {
    this.isWorking = true;
    this._patientClient.patient_GetMessages(this.selectedPatient.id, undefined, -1)
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
        let _messages = resp.filter(message => message.messageType != 'Notification');
        if (_messages.length > 0) {
          const dialogRef = this.matDialog.open(FutureMessagesDialogComponent, { data: { patientId: this.selectedPatient.id } })
        }
        this.isWorking = false;
      }, err => {
        this.isWorking = false;
      });
  }

  closeSearchResponsibleParties() {
    this.responsiblePartyFormGroup.patchValue({
      contactId: null,
      contactETag: null
    });
    this.isExistingResponsibleParty = false;
    this.existingResponsibleParties = [];
  }

  searchExistingResponsibleParty() {
    if (this.mode == 'edit') return;
    let respPartyForm: any = this.responsiblePartyFormGroup.value;
    let firstName: any = respPartyForm.firstName;
    let lastName: any = respPartyForm.lastName;

    if (firstName && lastName) {
      this.getExistingResponsibleParties(firstName, lastName);
    }
  }

  getExistingResponsibleParties(firstName, lastName) {
    this.isWorking = true;

    const query = new SearchContactQueryDto({
      firstName: firstName,
      lastName: lastName
    });

    this._searchClient
      .search_PostContacts(query, 500, 0)
      .pipe(take(1))
      .subscribe((res) => {
        this.existingResponsibleParties = res;
        this.isExistingResponsibleParty = this.existingResponsibleParties.length > 0;

        this.isWorking = false;
      },
        (err) => {
          this.isWorking = false;
          console.log('search Contacts error:', err)
        }
      );
  }

  selectExistingResponsibleParty(contactId: number) {
    if (!contactId || contactId == 0) return;
    this._contactClient.contact_GetContact(contactId, null)
      .pipe(take(1))
      .subscribe((res) => {
        let contact: ContactDto = res;

        let primaryEmail = _.find(contact.emails, 'isPrimary');
        let primaryAddress = _.find(contact.addresses, 'isPrimary');
        let mobilePhone = _.find(contact.phones, { 'phoneType': PhoneTypeEnum.Mobile });
        let alternatePhones = _.filter(contact.phones, (phone: ContactPhoneDto) => {
          return mobilePhone && mobilePhone.id != phone.id;
        });

        this.responsiblePartyFormGroup.patchValue({
          contactId: contact.id,
          firstName: contact.firstName,
          middleName: contact.middleName,
          lastName: contact.lastName,
          nickName: contact.nickname,
          gender: contact.gender,
          dob: contact.dob ? moment(contact.dob).tz('UTC').format("MM/DD/YYYY") : null,
          ssn: contact.ssn,
          title: contact.title,
          preferredContactMethod: contact.preferredContactMethod,
          primaryAddress: {
            id: primaryAddress ? primaryAddress.id : 0,
            addressType: primaryAddress ? primaryAddress.addressType : 'Home',
            addressLine1: primaryAddress ? primaryAddress.addressLine1 : null,
            addressLine2: primaryAddress ? primaryAddress.addressLine2 : null,
            city: primaryAddress ? primaryAddress.city : null,
            state: primaryAddress ? primaryAddress.state : null,
            zip: primaryAddress ? primaryAddress.zip : null,
            eTag: primaryAddress ? primaryAddress.eTag : null
          },
          mobilePhone: {
            id: mobilePhone ? mobilePhone.id : 0,
            isPrimary: mobilePhone ? mobilePhone.isPrimary : false,
            phoneType: mobilePhone ? mobilePhone.phoneType : null,
            number: mobilePhone ? mobilePhone.number : null,
            extension: mobilePhone ? mobilePhone.extension : null,
            eTag: mobilePhone ? mobilePhone.eTag : null
          },
          alternatePhone1: {
            id: alternatePhones && alternatePhones[0] ? alternatePhones[0].id : 0,
            isPrimary: alternatePhones && alternatePhones[0] ? alternatePhones[0].isPrimary : false,
            phoneType: alternatePhones && alternatePhones[0] ? alternatePhones[0].phoneType : null,
            number: alternatePhones && alternatePhones[0] ? alternatePhones[0].number : null,
            extension: alternatePhones && alternatePhones[0] ? alternatePhones[0].extension : null,
            eTag: alternatePhones && alternatePhones[0] ? alternatePhones[0].eTag : null
          },
          alternatePhone2: {
            id: alternatePhones && alternatePhones[1] ? alternatePhones[1].id : 0,
            isPrimary: alternatePhones && alternatePhones[1] ? alternatePhones[1].isPrimary : false,
            phoneType: alternatePhones && alternatePhones[1] ? alternatePhones[1].phoneType : null,
            number: alternatePhones && alternatePhones[1] ? alternatePhones[1].number : null,
            extension: alternatePhones && alternatePhones[1] ? alternatePhones[1].extension : null,
            eTag: alternatePhones && alternatePhones[1] ? alternatePhones[1].eTag : null
          },
          primaryEmail: {
            id: primaryEmail ? primaryEmail.id : 0,
            emailType: primaryEmail ? primaryEmail.emailType : 'Personal',
            emailAddress: primaryEmail ? primaryEmail.emailAddress : null,
            eTag: primaryEmail ? primaryEmail.eTag : null
          },
          contactETag: contact.eTag,
          employerId: contact.employerId,
          employerName: contact.employerName,
          licenseId: contact.licenseId
        });

        this.responsiblePartyFormGroup.markAsTouched();
        this.searchFormControl.disable();
      },
      (err) => {
        console.log(err)
      }
    )

    this.closeSearchResponsibleParties();
  }

  noteDateChange(evt){
    let noteDate = moment(evt.value).format('MM/DD/YYYY');
    let note = this.responsiblePartyFormGroup.value.comments;

    this.responsiblePartyFormGroup.patchValue({
      comments: `${noteDate} ${note}`
    })
  }
}
