import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toothgrid',
  templateUrl: './toothgrid.component.html',
  styleUrls: ['./toothgrid.component.css']
})
export class ToothgridComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  saveAddEditEleastic(){

  }

}
