import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter } from "./state";
import * as InsuranceCompanyStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(
    InsuranceCompanyStoreActions.LoadRequest,
    (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize }),
  ),
  on(InsuranceCompanyStoreActions.LoadSuccess, (state, action) => {
    featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.insuranceCompanies, { ...state, isLoading: false, error: null })
  }),
  on(InsuranceCompanyStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //LOAD ONE
  on(InsuranceCompanyStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(InsuranceCompanyStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.insuranceCompany, { ...state, isLoading: false, error: null })
  )),
  on(InsuranceCompanyStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //ADD
  on(InsuranceCompanyStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(InsuranceCompanyStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.insuranceCompany, { ...state, isLoading: false, error: null })
  )),
  on(InsuranceCompanyStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //DELETE
  on(InsuranceCompanyStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(InsuranceCompanyStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  )),
  on(InsuranceCompanyStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
