import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickConfirmDirective } from './click-confirm.directive';
import { ClickConfirmTemplateComponent } from './click-confirm-template/click-confirm-template.component';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
import { DialogConfirmTemplateComponent } from './dialog-confirm-template/dialog-confirm-template.component';
import { DialogInfoTemplateComponent } from './dialog-info-template/dialog-info-template.component';



@NgModule({
  declarations: [
    ClickConfirmDirective,
    ClickConfirmTemplateComponent,
    DialogConfirmTemplateComponent,
    DialogInfoTemplateComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    OverlayModule,
    MatIconModule
  ],
  exports: [
    ClickConfirmDirective,
    ClickConfirmTemplateComponent,
    DialogConfirmTemplateComponent,
    DialogInfoTemplateComponent
  ],
  entryComponents: [
    ClickConfirmTemplateComponent,
    DialogConfirmTemplateComponent,
    DialogInfoTemplateComponent
  ]
})
export class ClickConfirmModule { }
