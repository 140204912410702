import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { RouterStoreEffectsService } from './effects';
import { FEATURE_KEY } from './featurekey';

export const ROUTER_STORE_MODE = RouterState.Minimal

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreRouterConnectingModule.forRoot({ routerState: ROUTER_STORE_MODE }),
    StoreModule.forFeature(FEATURE_KEY, routerReducer),
    EffectsModule.forFeature([RouterStoreEffectsService]),
  ]
})
export class RouterStoreModule { }
