import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { InsuranceCompanyDto, IInsuranceCompanyDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<InsuranceCompanyStoreEntity> = createEntityAdapter<InsuranceCompanyStoreEntity>({
  selectId: model => model.id,
  sortComparer: (a: InsuranceCompanyStoreEntity, b: InsuranceCompanyStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<InsuranceCompanyStoreEntity> {
  page?: number;
  pageSize?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class InsuranceCompanyStoreEntity extends InsuranceCompanyDto {
  constructor(data?: IInsuranceCompanyDto) {
    super(data);
  }
}
