import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { MESSAGE_DRAFT_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const selectMessageDraftState: MemoizedSelector<object, State> = createFeatureSelector<State>(MESSAGE_DRAFT_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectMessageDraftState);
export const selectMessageDraftIds = selectIds;
export const selectAllMessageDrafts = selectAll;
export const selectAllMessageDraftEntities = selectEntities;

export const selectMessageDraftsError: MemoizedSelector<object, any> = createSelector(selectMessageDraftState, (state) => state.error);

export const selectMessageDraftsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectMessageDraftState, (state) => state.isLoading);
