export enum EvolutionColors {
  None = 0,

  Yellow,
  LightRed,
  Blue,

  LightOrange,
  Teal,
  LightPurple,

  DarkGreen,
  Pink,
  Red,

  LightBlue,
  Green,
  Purple,

  Brown,
  DarkPink,
  Orange,
}

export enum EvolutionHexColors {
  White = "#FFFFFF",

  Yellow = "#FCFF7C",
  LightRed = "#FD7474",
  Blue = "#74B3FD",

  LightOrange = "#F6CC61",
  Teal = "#8CF1E4",
  LightPurple = "#B48CF6",

  DarkGreen = "#2EA8A8",
  Pink = "#FECACA",
  Red = "#DE473E",

  LightBlue = "#37DBFF",
  Green = "#39D56E",
  Purple = "#BF60A4",

  Brown = "#BDBE8B",
  DarkPink = "#FF9DEA",
  Orange = "#FF9549",

  ElectricBlue = "#3f91cd"
}
