import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { PatientsStoreSelectors, PatientStoreEntity, RootStoreState } from 'src/app/root-store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import {
  DentistClient,
  PatientClient,
  PatientDentistDto
} from 'src/app/shared/services/api.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-patient-dentist',
  templateUrl: './patient-dentist.component.html',
  styleUrls: ['./patient-dentist.component.css'],
})
export class PatientDentistComponent implements OnInit, OnDestroy {
  @ViewChild('dentistsearchinput', { static: false }) dentistsearchinput: ElementRef;
  patient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);

  private _incomingData: PatientDentistPayload;
  @Input('data') set incomingData(value) {
    if (value) {
      this._incomingData = value;
      this.patientDentists$ = this._incomingData.patientDentists$;
    }
  }

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  allDentistsList: any[] = [];
  patientDetails: any = {}
  addCurDentist:boolean = false;
  patientDentistForm:FormGroup;
  referralProfnl: any[]=[];
  submitCurrent:boolean = false;
  currDentist: any = {};
  showDeletePopup:boolean = false;
  patientDentists$: Subject<any>;
  initSearch:boolean = false;

  get incomingData() {
    return this._incomingData;
  }

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _dentistClient: DentistClient,
    private _patientClient: PatientClient,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initialize();
    this.getPatientDetails();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  initialize(){
    let today: any = new Date();
    this.patientDentistForm = this.formBuilder.group({
      date: [''],
      dentistId: ['', Validators.required],
      clinicId: ['']
    });

    this.patientDentistForm.patchValue({
      date: today.toISOString(),
    })
  }

  getPatientDetails(){
    this.patient$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(res =>  {
          if(res != null){
            this.patientDetails = res
            this.getPatientDentist();
          }
        });
  }

  getPatientDentist(){
    this._patientClient.patient_GetPatientDentists(this.patientDetails.id, null)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((res) => {
          this.allDentistsList = res;
          const _currDentist:any[] = [];
          if(res.length > 0){
            this.allDentistsList = _.orderBy(this.allDentistsList, ['createdWhen'], ['desc']);
            this.currDentist = this.allDentistsList[0];
            _currDentist.push(this.currDentist)
          }
          this.patientDentists$.next(_currDentist);
        },
        (err) => {
          console.log(err)
        });
  }

  addCurrent(){
    this.addCurDentist = true;
    this.submitCurrent = false;
    this.initSearch = false;
    this.initialize();
  }

  cancel(){
    this.addCurDentist = false;
    this.initSearch = true;
  }

  save(){
    this.submitCurrent = true;
    if(this.patientDentistForm.valid){

      let patientDentistFormValue = this.patientDentistForm.value;

      let dentistModel:any = {
        "patientId": this.patientDetails.id,
        "dentistId": patientDentistFormValue.dentistId,
        "clinicId": patientDentistFormValue.clinicId,
        "createdWhen": patientDentistFormValue.date
      }

      this._patientClient.patient_PostPatientDentist(this.patientDetails.id, dentistModel, null)
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe((res) => {
            this.addCurDentist = false;
            this.submitCurrent = false;
            this.initSearch = true;
            this.initialize();
            this.getPatientDentist();
          },
          (err) => {
            console.log(err)
          });
    }
  }

  removeDentist(){
    this.showDeletePopup = true;
  }

  noDelete(){
    this.showDeletePopup = false;
  }

  confirmDelete() {
    this._patientClient.patient_DeletePatientDentist(this.patientDetails.id, this.currDentist.id, null)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (res) => {
          this.addCurDentist = false;
          this.submitCurrent = false;
          this.showDeletePopup = false;
          this.initialize();
          this.getPatientDentist();
        },
        (err) => {
          console.log(err)
        });
  }

  getSerarchValue(searchobj){
    this.patientDentistForm.patchValue({
      dentistId: searchobj.dentist.id,
      clinicId:  searchobj.clinic.id
    });

    this.dentistsearchinput.nativeElement.value = searchobj.dentist.displayName;

  }

}

export interface PatientDentistPayload {
  editor?: string;
  header?: string;
  patientDentists$: ReplaySubject<PatientDentistDto[]>;
}
