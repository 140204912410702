import { featureAdapter, State } from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CHAIR_FEATURE_KEY } from './featurekey';

export const selectChairState = createFeatureSelector<State>(CHAIR_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = featureAdapter.getSelectors(selectChairState);

export const selectAllChairs = selectAll;
export const selectChairEntities = selectEntities;
export const selectChairIds = selectIds;
export const selectTotalChairs = selectTotal;

export const selectChair = (id: number) => createSelector(selectAllChairs, (chair) => chair.find((d) => d.rowId == id));
