import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, withLatestFrom, take, debounceTime, filter, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootStoreState, PatientsStoreSelectors } from 'src/app/root-store';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import {
  ErrorDetails,
  PatientClient,
  PatientContactReferralDto,
  PatientReferralDto,
  ReportPatientThinkAfterStatusLastMtdAppointmentDto,
  SearchClient,
  SearchPatientDto,
  SearchPatientQueryDto,
} from 'src/app/shared/services/api.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'patient-referred-practice',
  templateUrl: './patient-referred-practice.component.html',
  styleUrls: ['./patient-referred-practice.component.css'],
})
export class PatientReferredPracticeComponent implements OnInit {
  private _incomingData: IPatientReferredPracticePayload;
  @Input('data') set incomingData(value: IPatientReferredPracticePayload) {
    if (value) {
      this._incomingData = value;
      this.patientContactReferrals$ = this._incomingData.patientContactReferrals$;
      this.patientContactReferrals$.subscribe((patientContactReferrals) => {
        this.groupedPatientContactReferrals = _.chain(patientContactReferrals)
          .groupBy('contactId')
          .map((items) => {
            return items;
          })
          .value();
      });
    }
  }
  get() {
    return this._incomingData;
  }
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  // patientReferrals$: Observable<PatientReferral[]> = of(patientReferrals);
  patientContactReferrals$: ReplaySubject<PatientContactReferralDto[]>;
  groupedPatientContactReferrals: any;

  //Patient Search
  patientSearchFormControl: FormControl = new FormControl();
  patientSearch$: Subject<string | SearchPatientDto> = new Subject<string | SearchPatientDto>();
  patientSearchResult$: Observable<SearchPatientDto[]> = this.patientSearch$.pipe(
    startWith(''),
    filter((value) => (typeof value === 'string' ? value.length > 1 : false)),
    map((search) => search as string),
    debounceTime(500),
    switchMap((search) => {
      const query = new SearchPatientQueryDto();

      query.query = search;

      return this._searchClient.search_PostPatients(query);
    }),
    shareReplay(1)
  );
  patientDisplayWith: (value: SearchPatientDto) => string = (value) => value && this.getPatientNameString(value);

  parseInt = parseInt;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _searchClient: SearchClient,
    private _patientClient: PatientClient,
    private _snackbar: MatSnackBar
  ) {}

  ngOnInit() {}

  valueIsStringOrNull(value: any) {
    return !value || typeof value === 'string';
  }

  getPatientNameString(patient: SearchPatientDto) {
    let value = [];
    if (!!patient.nickname) {
      value.push(patient.nickname);
      value.push(`(${patient.firstName})`);
    } else {
      value.push(patient.firstName);
    }
    value.push(patient.lastName);
    return value.join(' ');
  }

  contactThankToggle(patientContactReferral:any){
    if (!patientContactReferral.isThanked) {
      this._patientClient.patient_PostReferringContactThanked(patientContactReferral.patientId, patientContactReferral.contactId, null)
        .pipe(take(1))
        .subscribe(resp => {
          patientContactReferral.isThanked = true;
        })
    } else {
      this._patientClient.patient_PostReferringContactUnthank(patientContactReferral.patientId, patientContactReferral.contactId, null)
        .pipe(take(1))
        .subscribe(resp => {
          patientContactReferral.isThanked = false;
        })
    }
  }
}

export interface IPatientReferredPracticePayload {
  editor: string;
  header: string;
  patientContactReferrals$: ReplaySubject<PatientContactReferralDto[]>;
}

interface Exam {
  id: number;
  name: string;
  date: Date;
}

interface PatientReferral {
  id: number;
  isThumbsUp: boolean;
  referralDate: Date;
  examDate?: Date;
  treatmentStartDate?: Date;
  patient: {
    id: number;
    firstName?: string;
    lastName?: string;
  };
  referredBy: {
    id: number;
    firstName?: string;
    lastName?: string;
  };
  exams: Exam[];
}

const patientReferrals: PatientReferral[] = [
  {
    id: 1,
    isThumbsUp: true,
    referralDate: new Date('1977-06-21T11:07:18Z'),
    examDate: new Date('2086-02-10T11:53:50Z'),
    treatmentStartDate: new Date('1927-05-04T11:18:24Z'),
    patient: {
      id: 4,
      firstName: 'Jackie',
      lastName: 'Mendoza',
    },
    referredBy: {
      id: 1,
    },
    exams: [
      {
        id: 1001,
        name: 'call in',
        date: new Date('2019-12-15T11:07:18Z'),
      },
      {
        id: 2010,
        name: 'child exam',
        date: new Date('2019-12-25T11:07:18Z'),
      },
      {
        id: 4010,
        name: 'invisalign-teen',
        date: new Date('2020-01-25T11:07:18Z'),
      },
    ],
  },
  {
    id: 2,
    isThumbsUp: true,
    referralDate: new Date('1904-06-15T12:36:58Z'),
    examDate: new Date('1996-11-22T03:40:18Z'),
    treatmentStartDate: new Date('1921-11-01T23:42:51Z'),
    patient: {
      id: 5,
      firstName: 'Booke',
      lastName: 'Young',
    },
    referredBy: {
      id: 1,
    },
    exams: [
      {
        id: 1005,
        name: 'online-website',
        date: new Date('2019-12-15T11:07:18Z'),
      },
      {
        id: 2005,
        name: 'adult exam',
        date: new Date('2019-12-25T11:07:18Z'),
      },
      {
        id: 4015,
        name: 'invisalign-adult-18',
        date: new Date('2020-01-25T11:07:18Z'),
      },
    ],
  },
];
