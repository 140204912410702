import { createAction, props } from "@ngrx/store";
import { ResponsiblePartyStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Responsible Party] Load Request',
  props<{page?: number; pageSize?: number; locationId: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[Responsible Party] Load Success',
  props<{ responsibleParties: ResponsiblePartyStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Responsible Party] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[Responsible Party] Load One Request',
  props<{ id: number; locationId: number; patientId: number; }>()
);

export const LoadOneSuccess = createAction(
  '[Responsible Party] Load One Success',
  props<{ responsibleParty: ResponsiblePartyStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[Responsible Party] Load One Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Responsible Party] Add Request',
  props<{ locationId: number; responsibleParty: ResponsiblePartyStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Responsible Party] Add Success',
  props<{ responsibleParty: ResponsiblePartyStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Responsible Party] Delete Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[Responsible Party] Delete Request',
  props<{locationId: number; id: number; patientId: number; }>()
);

export const DeleteSuccess = createAction(
  '[Responsible Party] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Responsible Party] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Responsible Party] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Responsible Party] Select Success',
  props<{ responsibleParty: ResponsiblePartyStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Responsible Party] Select Failure',
  props<{ error: any; }>()
);


//DESELECT
export const DeselectRequest = createAction(
  '[Responsible Party] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Responsible Party] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Responsible Party] Deselect Failure',
  props<{ error: any; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[Responsible Party] Update Request',
  props<{ locationId: number; responsibleParty: ResponsiblePartyStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[Responsible Party] Update Success',
  props<{ responsibleParty: ResponsiblePartyStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[Responsible Party] Update Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Responsible Party] Reset Error'
);

export const UpdateSortOrderRequest = createAction('[Responsible Party] UpdateSortOrderRequest', props<{ responsibleParties: ResponsiblePartyStoreEntity[], locationId: number}>());
export const UpdateSortOrderSuccess = createAction('[Responsible Party] UpdateSortOrderSuccess', props<{ responsibleParties: ResponsiblePartyStoreEntity[]}>());
export const UpdateSortOrderFailure = createAction('[Responsible Party] UpdateSortOrderFailure', props<{ error: any }>());
