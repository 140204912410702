import { Action, createReducer, on } from "@ngrx/store";
import * as WaitingAppointmentStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(WaitingAppointmentStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(WaitingAppointmentStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.waitingAppointments, { ...s, isLoading: false, error: null })
  }),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
