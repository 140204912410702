import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'src/app/shared/models';
import { ReferOutFrontComponent } from './refer-out-front/refer-out-front.component';
import { ReferOutBackComponent } from './refer-out-back/refer-out-back.component';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import {
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatProgressBarModule,
  MatListModule,
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatButtonToggleModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { DentistClinicSearchModule } from '@shared/dentist-clinic-search/dentist-clinic-search.module';
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ngfModule } from "angular-file";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@CardModule({
  back: ReferOutBackComponent,
  front: ReferOutFrontComponent,
  height: DEFAULT_CARD_HEIGHT,
  selector: 'referout',
  width: 835,
  header: 'Tx Communication',
  order: 140,
  actions: [{iconName: 'faFileWaveform', actionName: '', isFaIcon: true}]
})


@NgModule({
  declarations: [ReferOutFrontComponent, ReferOutBackComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatProgressBarModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DentistClinicSearchModule,
    AngularEditorModule,
    ngfModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatButtonToggleModule
  ],
  entryComponents: [ReferOutFrontComponent, ReferOutBackComponent]
})
export class ReferOutModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
