import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_REFERRAL_MESSAGE_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientReferralMessageStoreEntity, State } from './state';

export const selectPatientReferralMessagesState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_REFERRAL_MESSAGE_FEATURE_KEY);

export const getSelectedPatientReferralMessageId = createSelector(selectPatientReferralMessagesState, (state) => state.selectedPatientReferralMessageId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientReferralMessagesState);
export const selectPatientReferralMessageIds = selectIds;
export const selectAllPatientReferralMessages = selectAll;
export const selectAllPatientReferralMessageEntities = selectEntities;

export const getSelectedPatientReferralMessage = createSelector(selectPatientReferralMessagesState, selectAllPatientReferralMessages, (state, allPatientReferralMessages: PatientReferralMessageStoreEntity[]) =>
  allPatientReferralMessages ? allPatientReferralMessages.find((rp) => rp.id === state.selectedPatientReferralMessageId) : null
);

export const selectPatientReferralMessageById = (id: number) =>
  createSelector(selectAllPatientReferralMessages, (allPatientReferralMessages: PatientReferralMessageStoreEntity[]) => (allPatientReferralMessages ? allPatientReferralMessages.find((rp) => rp.id === id) : null));

export const selectPatientReferralMessagesError: MemoizedSelector<object, any> = createSelector(selectPatientReferralMessagesState, (state) => state.error);

export const selectPatientReferralMessageIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientReferralMessagesState, (state: State) => state.isLoading);
