import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../root-state';
import * as PatientFormStoreActions from './actions';
import * as PatientFormStoreSelectors from './selectors';
import { HubEventArea, HubEventType, PatientClient, PatientFormDto } from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material';
import { PatientsStoreSelectors } from '../patient-store';
import { SignalRHubStoreActions } from '../signalr-hub-store';

@Injectable({ providedIn: 'root' })
export class PatientFormStoreEffects {
  private _selectedPatientId$ = this.store$.select(PatientsStoreSelectors.getSelectedPatientId);
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _snackBar: MatSnackBar,
    private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.LoadRequest),
    withLatestFrom(this._selectedPatientId$),
    switchMap(([action, selectedPatientId]) => {
      return this._patientClient.patient_GetPatientForms(selectedPatientId).pipe(
        map((result) => PatientFormStoreActions.LoadSuccess({ patientForms: result })),
        catchError((err: HttpErrorResponse) => of(PatientFormStoreActions.LoadFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.LoadOneRequest),
    withLatestFrom(this._selectedPatientId$),
    switchMap(([action, selectedPatientId]) => {
      return this._patientClient.patient_GetPatientForm(selectedPatientId, action.id).pipe(
        map((result) => PatientFormStoreActions.LoadOneSuccess({ patientForm: result })),
        catchError((err: HttpErrorResponse) => of(PatientFormStoreActions.LoadOneFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(PatientFormStoreSelectors.selectPatientFormById(action.id))
        .pipe(map((result) => PatientFormStoreActions.SelectSuccess({ patientForm: result })))
    )
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.AddRequest),
    withLatestFrom(this._selectedPatientId$),
    switchMap(([action, selectedPatientId]) =>
      this._patientClient.patient_PostPatientForm(selectedPatientId, action.patientForm).pipe(
        map((result) => {
          this._snackBar.open("Successfully added!", "Ok", {
            duration: 3000,
          });
          return PatientFormStoreActions.AddSuccess({ patientForm: result });
        }),
        catchError((err: HttpErrorResponse) => of(PatientFormStoreActions.AddFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  addMultipleRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.AddMultipleRequest),
    withLatestFrom(this._selectedPatientId$),
    switchMap(([action, selectedPatientId]) =>
      this._patientClient.patient_PostPatientForms(selectedPatientId, action.patientForms).pipe(
        map((result) => {
          this._snackBar.open("Successfully added!", "Ok", {
            duration: 3000,
          });
          return PatientFormStoreActions.AddMultipleSuccess({ patientForms: result });
        }),
        catchError((err: HttpErrorResponse) => of(PatientFormStoreActions.AddMultipleFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.UpdateRequest),
    withLatestFrom(this._selectedPatientId$),
    switchMap(([action, selectedPatientId]) =>
      this._patientClient.patient_PutPatientForm(selectedPatientId, action.id, action.patientForm).pipe(
        switchMap(() => this._patientClient.patient_GetPatientForm(selectedPatientId, action.id)),
        map((result) => {
          this._snackBar.open("Successfully updated!", "Ok", {
            duration: 3000,
          });
          return PatientFormStoreActions.UpdateSuccess({ patientForm: result });
        }),
        catchError((err: HttpErrorResponse) => of(PatientFormStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientFormStoreActions.DeleteRequest),
    withLatestFrom(this._selectedPatientId$),
    switchMap(([action, selectedPatientId]) =>
      this._patientClient.patient_DeletePatientForm(selectedPatientId, action.id).pipe(
        map(() => {
          this._snackBar.open("Successfully deleted!", "Ok", {
            duration: 3000,
          });
          return PatientFormStoreActions.DeleteSuccess({ id: action.id });
        }),
        catchError((err: HttpErrorResponse) => of(PatientFormStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  patientFormEventEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedPatientId$),
    filter(
      ([action, selectedPatientId]) =>
        action.event.eventArea == HubEventArea.PatientFormEvent &&
        action.event.entityType == PatientFormDto.name &&
        action.event.patientId == selectedPatientId &&
        action.event.eventType == HubEventType.Modified
    ),
    map(([action, selectedPatientId]) => {
      return PatientFormStoreActions.LoadRequest();
    })
  );
}
