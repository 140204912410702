import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlanGroupDto, IPlanGroupDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PlanGroupDto> = createEntityAdapter<PlanGroupDto>({
  selectId: (planGroup) => planGroup.id,
  sortComparer: (a, b) => a.id - b.id,
});

export interface State extends EntityState<PlanGroupDto> {
  isLoading?: boolean;
  error?: any;
  hasLoaded?: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  hasLoaded: false,
});

export class PlanGroupStoreEntity extends PlanGroupDto {
  constructor(data?: IPlanGroupDto) {
    super(data);
  }
}
