import { NestedTreeControl } from "@angular/cdk/tree";
import { Observable } from "rxjs";
import { StorageContentTypeEnum, StorageItemBaseDto, StorageTypeEnum } from "../../shared/services/api.service";
import { Download } from "../../shared/services/download/download";

export interface ITreeNode {
  fileDtoId?: number;
  name: string;
  cardSelector?: string;
  storageItem?: StorageItemBaseDto;
  children?: ITreeNode[] | Observable<ITreeNode[]>;
  isLoaded?: boolean;
  isWorking?: boolean;
  type: StorageTypeEnum;
  childrenCount?: number;
  parentId?: number;
  contentType?: StorageContentTypeEnum;
  download$?: Observable<Download>;
  restrictedFolder?: boolean;
  isSelected?: boolean;
}

export class CustomNestedTreeControl<T> extends NestedTreeControl<T> {
  canCollapse: (dataNode: T) => boolean = (_) => true;
  constructor(getChildren: (dataNode: T) => Observable<T[]> | T[] | undefined | null, canCollapse?: (dataNode: T) => boolean) {
    super(getChildren);
    if (canCollapse) this.canCollapse = canCollapse;
  }
}
