import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { PROVIDERS_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProvidersStoreEffects } from './effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(PROVIDERS_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([ProvidersStoreEffects])
  ]
})
export class ProviderStoreModule { }
