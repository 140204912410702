import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(SettingsStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    settings: null,
  })),
  on(SettingsStoreActions.LoadSuccess, (state, action) => ({
    ...state,
    settings: action.settings,
    isLoading: false,
    error: null
  })),
  on(SettingsStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
