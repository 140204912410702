import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CardLoaderModule } from '@shared/card-loader/card-loader.module';
import { ErrorInterceptor } from '@shared/error-interceptor';
import { AuthInterceptorService, EventHubServiceModule } from '@shared/services';
import { API_BASE_URL } from '@shared/services/api.service';
import { getSaver, SAVER } from '@shared/services/download/saver.provider';
import { ETagInterceptorService } from '@shared/services/etag-interceptor.service';
import { PatientService } from '@shared/services/patient.service';
import { AppPropertyProvider, AppPropertyService } from '@shared/services/property-service/property-service.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { VersionCheckService } from '@shared/services/utility/version-check.service';
import { SharedModule } from '@shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ClickOutsideDirective } from './core/ClickElsewhereDirective';
import { MaterialModule } from './material.module';
import { AppPropertyPipe } from './pipe/property-service.pipe';
import { RootStoreModule } from '@root-store';
import { NgImageFullscreenViewService } from 'ng-image-fullscreen-view';
import { HttpCancelInterceptor } from '@shared/cancel-interceptor ';
import { HttpCancelService } from '@shared/services/cancel-service';

interface ApplicationState {
  currentlyLoading: boolean;
  customerList: any[];
}
export const initialState: ApplicationState = {
  currentlyLoading: false,
  customerList: [],
};

@NgModule({
  declarations: [AppComponent, ClickOutsideDirective, AppPropertyPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ClickOutsideModule,
    MaterialModule,
    RootStoreModule,
    CardLoaderModule,
    EventHubServiceModule,
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    SharedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ETagInterceptorService, multi: true },
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AppPropertyService,
    AppPropertyProvider,
    SettingsService,
    PatientService,
    VersionCheckService,
    HttpCancelService,
    { provide: SAVER, useFactory: getSaver },
    NgImageFullscreenViewService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
