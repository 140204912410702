import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { LocationsStoreSelectors, PatientsStoreSelectors, PatientStoreEntity, ProvidersStoreSelectors, RootStoreState, InternalUserStoreSelectors, CardsStoreActions } from 'src/app/root-store';
import { LANGUAGES_LIST } from 'src/app/shared/enums';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { PERMISSIONS } from '@shared/constants/permissions';
import { UserPermissionsService } from '@shared/user-permissions/user-permissions.service';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MessageDialog } from 'src/app/dialogs/message/message.dialog';
import { ACTIVE_PATIENT_LOCATION } from '../../../user-permissions/jwt.model';
import { AddPolicyDialogComponent } from 'src/app/dialogs/add-policy-dialog/add-policy-dialog.component';
import { faExclamation, faHeart, faExclamationTriangle, faBell, faHospitalUser } from '@fortawesome/free-solid-svg-icons';
import { faPencil, faTriangle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-basic-details-front',
  templateUrl: './basic-details-front.component.html',
  styleUrls: ['./basic-details-front.component.scss'],
})
export class BasicDetailsFrontComponent implements OnInit {
  patient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient).pipe(filter(patient => !!patient));
  isWorking$: Observable<boolean> = this._store$.select(PatientsStoreSelectors.selectPatientsIsLoading);
  error$: Observable<any> = this._store$.select(PatientsStoreSelectors.selectPatientsError);
  patientLanguageName$: Observable<string> = this.patient$.pipe(
    map((patient) => (patient.languageKey ? LANGUAGES_LIST[patient.languageKey.toLowerCase()].name : null))
  );
  patientLocation$: Observable<string> = this.patient$.pipe(
    switchMap((patient) => this._store$.select(LocationsStoreSelectors.selectLocationById(patient.preferredLocationId))),
    map((location) => (location ? location.shortName : null)));

  LANGUAGES_LIST = LANGUAGES_LIST;

  phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private userPermissionSubscription: Subscription;
  dialog: DialogComponent;

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  contactId:number;

  caseManager$: Observable<string> = this.patient$.pipe(
    switchMap((patient) => this._store$.select(InternalUserStoreSelectors.selectInternalUserById(patient.preferredCaseManagerId))),
    map((user) => (user ? `${user.firstName} ${user.lastName}` : null)));

  responsiblePartySsnBlind: boolean = true;

  faExclamationTriangle = faExclamationTriangle;
  faExclamation = faExclamation;
  faHeart = faHeart;
  faTriangle = faTriangle;
  faBell = faBell;
  faHospitalUser = faHospitalUser;
  faPencil = faPencil;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private _userPermissionsService: UserPermissionsService,
    private matDialog: MatDialog,
  ) {
    this.dialog = new DialogComponent(this.matDialog);
  }

  ngOnInit() {

    this.patient$.pipe(withLatestFrom(this._store$.select(PatientsStoreSelectors.getSelectedPatient)), takeUntil(this._destroy$))

    this.patient$.pipe(filter(patient => !!patient)).subscribe(resp => {
      this.contactId = resp.contactId;
    })
  }

  ngOnDestroy(): void {
    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }
  }

  /**
   * Peforms a flip event on the card sending the active patient as the payload
   */
  editDetails() {

    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }

    this.userPermissionSubscription = this._userPermissionsService.hasPermission(PERMISSIONS.Location.TreatmentCards.EditPatientCard, ACTIVE_PATIENT_LOCATION).subscribe(hasPermission => {
      if (hasPermission) {
        this.patient$.pipe(take(1)).subscribe(
          (patient) => this.data.flip.next(<IFlipEvent>{
            payload: patient,
            side: this.data.side
          }));
      } else {
        let data = {
          title: 'Permission Error',
          message: "Sorry, you don't have rights to edit patient card.  Please see the administrator."
        };

        this.dialog.open(MessageDialog, data);
      }
    })


  }

  public phoneNumberDisplay(pnumber: any) {
    if (pnumber) {
      return `(${pnumber.substring(0, 3)}) ${pnumber.substring(
        3,
        6
      )}-${pnumber.substring(6, 10)}`;
    }
  }

  openPolicyDialog(){
    this.dialog.open(AddPolicyDialogComponent, {contactId: this.contactId}, true);
  }

  openAlertCard(){
    this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: 'alerts-reminders' }));
  }
}
