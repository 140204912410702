import { createAction, props } from "@ngrx/store";
import { PatientCompletionDateStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientCompletionDate] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[PatientCompletionDate] Load Success',
  props<{ patientCompletionDates: PatientCompletionDateStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientCompletionDate] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[PatientCompletionDate] Add Request',
  props<{ patientCompletionDate: PatientCompletionDateStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[PatientCompletionDate] Add Success',
  props<{ patientCompletionDate: PatientCompletionDateStoreEntity; }>()
);

export const AddFailure = createAction(
  '[PatientCompletionDate] Add Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[PatientCompletionDate] Update Request',
  props<{ patientCompletionDate: PatientCompletionDateStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[PatientCompletionDate] Update Success',
  props<{ patientCompletionDate: PatientCompletionDateStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[PatientCompletionDate] Update Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[PatientCompletionDate] Delete Request',
  props<{ patientId: number, completionDateId: number }>()
);

export const DeleteSuccess = createAction(
  '[PatientCompletionDate] Delete Success',
  props<{ id: number }>()
);

export const DeleteFailure = createAction(
  '[PatientCompletionDate] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientCompletionDate] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientCompletionDate] Select Success',
  props<{ patientCompletionDate: PatientCompletionDateStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientCompletionDate] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientCompletionDate] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientCompletionDate] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientCompletionDate] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[PatientCompletionDate] Reset Error'
);
