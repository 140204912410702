import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientDentistVisitDto, IPatientDentistVisitDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientDentistVisitStoreEntity> = createEntityAdapter<PatientDentistVisitStoreEntity>({
  selectId: patientDentistVisit => patientDentistVisit.id,
  sortComparer: (a: PatientDentistVisitStoreEntity, b: PatientDentistVisitStoreEntity): number => b.dentistVisitDate.getTime() - a.dentistVisitDate.getTime()
});

export interface State extends EntityState<PatientDentistVisitStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientDentistVisitId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientDentistVisitId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientDentistVisitStoreEntity extends PatientDentistVisitDto {
  constructor(data?: IPatientDentistVisitDto) {
    super(data);
  }
}
