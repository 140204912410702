import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, EventEmitter, Injectable, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CardsStoreActions, CardsStoreSelectors, PatientsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { FileDto, PatientClient, StorageContentTypeEnum, StorageItemBaseDto, StorageTypeEnum } from 'src/app/shared/services/api.service';
import { ITreeNode } from '../../patient-details/models/ITreeNode';
//import { MatMenuTrigger } from '@angular/material/menu';

class CustomNestedTreeControl<T> extends NestedTreeControl<T> {
  canCollapse: (dataNode: T) => boolean = (_) => true;
  constructor(getChildren: (dataNode: T) => Observable<T[]> | T[] | undefined | null, canCollapse?: (dataNode: T) => boolean) {
    super(getChildren);
    if (canCollapse) this.canCollapse = canCollapse;
  }
}

@UntilDestroy()
@Component({
  selector: 'patient-files-selector',
  templateUrl: './patient-files-selector.component.html',
  styleUrls: ['./patient-files-selector.component.scss'],
})
export class PatientFilesSelectorComponent implements OnInit, AfterViewInit {
  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId).pipe(
    untilDestroyed(this),
    filter((id) => !!id),
    distinctUntilChanged()
  );
  treeControl = new CustomNestedTreeControl<ITreeNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<ITreeNode>();
  private _loadFolder$: Subject<[number, ITreeNode]> = new Subject<[number, ITreeNode]>();
  private _rootFolders$ = this.getFolder(null);

  @Output() fileSelected = new EventEmitter();
  @Output() fileHover = new EventEmitter();

  //ROOT TREE NODES
  treeData: ITreeNode[] = [
    {
      name: 'Files',
      children: this._rootFolders$,
      fileDtoId: null,
      type: StorageTypeEnum.Folder,
      parentId: null,
      isLoaded: false
    },
  ];

  //@ViewChild('imagepopoverTrigger', {static: false}) imagepopoverTrigger: MatMenuTrigger;
  popovermenuimg: any;
  _imageMimeTypes: string[] = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', '	image/webp'];

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient
  ) {
    this.dataSource.data = this.treeData;
  }

  ngOnInit(): void {
    this.treeControl.expand(this.dataSource.data[0]);
  }

  ngAfterViewInit(): void {
    this.loadFolder(this.dataSource.data[0]);
  }

  hasChild = (_: number, node: ITreeNode) => !!node.children || node.type == StorageTypeEnum.Folder;

  selectFile(node: ITreeNode): void {
    this.fileSelected.emit(node.storageItem);
  }

  okSelection() {
    let selectedItems: StorageItemBaseDto[] = this.getSelectedItem(this.dataSource.data[0]);

    //this.dialogRef.close(selectedItems);
  }

  getSelectedItem(node: ITreeNode): StorageItemBaseDto[] {
    if (!node.isLoaded || !node.children) return [];
    let children: ITreeNode[] = node.children as ITreeNode[];
    let selectedItems: StorageItemBaseDto[] = _.chain(children as ITreeNode[])
      .filter({ 'type': StorageTypeEnum.File, 'isSelected': true })
      .map((n: ITreeNode) => {
        return n.storageItem;
      })
      .value();

    let foldersTreeNode: ITreeNode[] = _.filter(children, { 'type': StorageTypeEnum.Folder, 'isLoaded': true });
    _.each(foldersTreeNode, (f: ITreeNode) => {
      selectedItems = _.concat(selectedItems, this.getSelectedItem(f));
    });

    return selectedItems;
  }

  /**
   * Opens a card adjacent to the current one
   */
  openCard(cardSelector: string) {
    this._store$
      .select(CardsStoreSelectors.getCardOrderBySelector('patient-files'))
      .pipe(take(1))
      .subscribe((order) => this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: cardSelector, order: order + 1 })));
  }

  /**
   * Indicate a folder to be loaded from api if not already loaded
   */
  loadFolder(node: ITreeNode) {
    if (!node.isLoaded) {
      this._loadFolder$.next([node.fileDtoId, node]);
    }
  }

  /**
   * Converts a list of FileDto into treenodes representing either a file or a folder
   */
  mapFoldersToTreeNode(contents: FileDto[]): ITreeNode[] {
    if (contents && contents.length > 0) {
      return _.chain(contents)
        .sortBy((c: FileDto) => { return c.type == StorageTypeEnum.File ? 0 : 1; })
        .map((c: FileDto) => { return this.mapContentToTreeNode(c); })
        .value();
    }
    return [];
  }

  /**
   * Converts a FileDto item into a tree node representing either a folder or a file depending upon item type
   */
  mapContentToTreeNode(content: FileDto): ITreeNode {
    if (content.type == StorageTypeEnum.File) {
      //Item is a file
      return <ITreeNode>{
        name: this.getNodeName(content),
        storageItem: content,
        cardSelector: this.contentTypeToCardSelector(content.contentType),
        type: StorageTypeEnum.File,
        parentId: content.parentId,
        contentType: content.contentType,
        isSelected: false
      };
    } else if (content.type == StorageTypeEnum.Folder) {
      //Item is a folder
      return <ITreeNode>{
        fileDtoId: content.id,
        name: content.name,
        children: this.getFolder(content.id),
        type: StorageTypeEnum.Folder,
        childrenCount: content.childrenCount,
        isLoaded: content.childrenCount > 0 ? false : true,
        parentId: content.parentId
      };
    } else {
      //Something wrong with item
      return <ITreeNode>{
        name: 'File Missing or Broken',
      };
    }
  }

  /**
   * Get the text to display for the file name
   * @param file
   */
  getNodeName(file: FileDto): string {
    if (file.contentType == StorageContentTypeEnum.Other) {
      return file.name;
    }
    else if (file.contentType == StorageContentTypeEnum.Imaging) {
      return file.name.replace(/\.[^/.]+$/, "");
    }
    else {
      return `${file.id} - ${this.getFileExtension(file.name)} - ${file.createdWhen.toLocaleDateString()} - ${file.contentType}`;
    }
  }

  /**
   * Returns an observable that when subscribed to returns the list of contents for a single folder
   */
  getFolder(fileDtoId: number): Observable<ITreeNode[]> {
    //Defers loading the folders until the folderId has its number called
    return this._loadFolder$.pipe(
      withLatestFrom(this._selectedPatientId$),
      //filters to only load when id matches the requested folder
      filter(([[folderId, node], patientId]) => folderId == fileDtoId),
      //set loading to false to show progress bar
      tap(([[folderId, node], patientId]) => (node.isLoaded = false)),
      switchMap(([[folderId, node], patientId]) =>
        combineLatest([
          of(node),
          //fetches root folders when folderId is null, otherwise fetches a specific folders contents
          folderId == null ? this._patientClient.patient_GetFolders(patientId) : this._patientClient.patient_GetFolder(patientId, folderId),
        ])
      ),
      map(([node, contents]) => {
        //set node to loaded and return a list of its contents mapped as TreeNodes
        node.isLoaded = true;
        node.childrenCount = contents.length;
        node.isSelected = false;
        node.children = this.mapFoldersToTreeNode(contents);
        return node.children;
      }),
      //shareReplay critical to prevent reload on every view change
      shareReplay()
    );
  }

  /**
   * Maps a StorageContentType to the card where those items are managed
   */
  contentTypeToCardSelector(contentType: StorageContentTypeEnum): string {
    switch (contentType) {
      case StorageContentTypeEnum.Ceph:
        return 'cephalometrics';
      case StorageContentTypeEnum.Imaging:
      case StorageContentTypeEnum.IntraOral:
        return 'patient-imaging';
      case StorageContentTypeEnum.Model:
        return '3d-models';
      case StorageContentTypeEnum.Contract:
      case StorageContentTypeEnum.Attachment:
      case StorageContentTypeEnum.Other:
      default:
        return null;
    }
  }

  /**
   * Reloads the subtree contents of a folder
   */
  refreshFolder(node: ITreeNode) {
    this._loadFolder$.next([node.fileDtoId, node]);
  }

  private getParentNode(node: ITreeNode) {
    if (node.parentId) {
      //folder is not root so find parent and refresh
      //note: unfortunately getDescendants does not return a true reference to the node that the display is using so changes to isLoaded and childrenCount are not updated in the template
      return this.treeControl.getDescendants(this.dataSource.data[0]).find((n) => n.fileDtoId == node.parentId);
    } else {
      //folder is a root folder so just refresh root
      return this.dataSource.data[0];
    }
  }

  /**
   * Gets the file extension from file name
   */
  private getFileExtension(fileName: string) {
    return fileName.split('.').reverse()[0].toUpperCase();
  }

  onMouseEnter(node){
    let isImage = this._imageMimeTypes.find(item => item == node.storageItem.locationContentType);
    if(isImage){
       this.fileHover.emit({url: node.storageItem.locationThumbnailUrl, isImage: true});
    } else {
      this.fileHover.emit({url: null, isImage: false, event: 'mouseenter'});
    }
  }

  onMouseLeave(){
    this.fileHover.emit({url: null, isImage: false, event: 'mouseleave'});
  }
}
