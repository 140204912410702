import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressBarModule } from "@angular/material";
import { StlModelViewerModule } from "angular-stl-model-viewer";
import { ImageErrorHandlerModule } from "../image-error-handler/image-error-handler.module";
import { SharedModule } from "../shared.module";
import { FileViewerContainerComponent } from "./file-viewer-container.component";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    FileViewerContainerComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    StlModelViewerModule,
    SharedModule,
    ImageErrorHandlerModule,
    FontAwesomeModule
  ],
  exports: [
    FileViewerContainerComponent
  ],
})
export class FileViewerContainerModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
