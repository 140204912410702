import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ITreatmentDto, TreatmentDto } from 'src/app/shared/services/api.service';

export const featureAdapter = createEntityAdapter<PatientTreatmentPlanStoreEntity>({
  selectId: (treatment) => treatment.id,
  sortComparer: (a, b) => (a.date.valueOf > b.date.valueOf ? 1 : -1),
});

export interface State extends EntityState<PatientTreatmentPlanStoreEntity> {
  isLoading?: boolean;
  error?: any;
  patientId?: number;
  locationId?: number;
  isLoaded?: boolean;
  selectedTreatmentPlanId?: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  patientId: null,
  locationId: null,
  isLoaded: false,
  selectedTreatmentPlanId: null,
});

export class PatientTreatmentPlanStoreEntity extends TreatmentDto {
  constructor(data?: ITreatmentDto) {
    super(data);
  }
}
