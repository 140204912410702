import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { PATIENT_MESSAGE_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { PatientMessageStoreEffectsService } from './effects';


@NgModule({
  imports: [
    StoreModule.forFeature(PATIENT_MESSAGE_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([PatientMessageStoreEffectsService]),
  ]
})
export class PatientMessageStoreModule { }
