import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PROVIDERS_FEATURE_KEY } from './featurekey';
import { featureAdapter, ProviderStoreEntity, State } from './state';

export const selectProvidersState: MemoizedSelector<object, State> = createFeatureSelector<State>(PROVIDERS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectProvidersState);
export const selectProviderIds = selectIds;
export const selectProviderTotal = selectTotal;
export const selectAllProviders = selectAll;
export const selectAllProvidersEntities = selectEntities;


export const selectProviderById = (id: number) =>
  createSelector(
    selectAllProviders,
    (allProviders: ProviderStoreEntity[]) => {
      if (allProviders) {
        return allProviders.find(c => c.id === id);
      } else {
        return null;
      }
    }
  );

export const selectProvidersError: MemoizedSelector<object, any> = createSelector(
  selectProvidersState,
  (state) => state.error
);

export const selectProvidersIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectProvidersState,
  (state) => state.isLoading
);

