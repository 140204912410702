import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommunicationDeskEffectsService } from './effects';
import { COMMUNICATION_DESK_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(COMMUNICATION_DESK_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([CommunicationDeskEffectsService])
  ],
})
export class CommunicationDeskStoreModule { }
