import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { LocationsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { ScheduleStoreSelectors } from 'src/app/root-store/schedule-store';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';
import { ComplianceScoreEnum, CreateEventDto, LocationClient, ScheduleGridDto, ScheduleNotesDto, UpdateEventDto } from '../api.service';
import { ProcedureDTO, ProcedureGroupDTO } from '../settings/settings.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScheduleService {
  selectedLocation: any;
  selectedLocationId: any;
  selectedDate$: Observable<Date> = this.store$.select(ScheduleStoreSelectors.selectViewDate);
  selectedLocation$ = this.store$.select(LocationsStoreSelectors.getSelectedLocation);
  selectedLocationId$ = this.store$.select(LocationsStoreSelectors.getSelectedLocationId);
  dateLocation$: Observable<[Date, number]> = combineLatest([
    this.store$.select(ScheduleStoreSelectors.selectViewDate),
    this.store$.select(LocationsStoreSelectors.getSelectedLocationId),
  ]).pipe(
    filter(([viewDate, locationId]) => viewDate != null && locationId != null),
    distinctUntilChanged(([pDate, pLocationId], [cDate, cLocationId]) => pLocationId === cLocationId && pDate === cDate),
    untilDestroyed(this)
  );

  constructor(private http: HttpClient, private store$: Store<RootStoreState.State>, private locationClient: LocationClient) {
    this.selectedLocation$
      .pipe(
        filter((location) => !!location),
        untilDestroyed(this)
      )
      .subscribe((location) => {
        this.selectedLocation = location;
        this.selectedLocationId = location.id;
      });
  }

  createAppointmentForPatient(appointment) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${appointment.patientId}/appointments`;
    return this.http.post(url, appointment);
  }

  getScheduleGrid(): Observable<ScheduleGridDto> {
    return this.selectedDate$.pipe(
      take(1),
      switchMap((viewDate) => this.locationClient.location_GetSchedule(this.selectedLocationId, viewDate))
    );
  }

  getScheduleChairs() {
    return this.selectedDate$.pipe(
      withLatestFrom(this.selectedLocation$),
      take(1),
      switchMap(([viewDate, location]) =>
        this.locationClient.location_GetScheduleChairs(location.id).pipe(
          map((result) => ({ data: result })),
          take(1)
        )
      )
    );
  }

  getCheckOutList() {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/checkout`;
    return this.http.get(url);
  }

  getSchedulesOndeck() {
    return this.selectedDate$.pipe(
      take(1),
      map((viewDate) => {
        const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/ondeck?date=${viewDate}`;
        return this.http.get(url);
      })
    );
  }

  getNotifications(patientId: number) {
    const url = `${environment.apiUrl}/patients/${patientId}/notifications`;
    return this.http.get(url);
  }

  getDoctors(): any {
    const url = `${environment.apiUrl}/providers`;
    return this.http.get(url);
  }

  updateNotifications(patientId: number, notifications: NotificationDTO[]) {
    const url = `${environment.apiUrl}/patients/${patientId}/notifications`;
    return this.http.post(url, notifications);
  }

  getPatientData(patientId, locId?: number) {
    let locationId = locId != null && locId > 0 ? locId : this.selectedLocationId;
    const url = `${environment.apiUrl}/locations/${locationId}/patients/${patientId}`;
    return this.http.get(url);
  }

  getAppointmentById(patientId: number, appointmentId: number) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${patientId}/appointments/${appointmentId}`;
    return this.http.get(url);
  }

  getProviders() {
    const url = `${environment.apiUrl}/providers`;
    return this.http.get(url);
  }

  deleteAppoinment(patientId: number, appointmentId: number) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${patientId}/appointments/${appointmentId}`;
    return this.http.delete(url);
  }

  postRescheduleAppointment(obj: AppointmentDTO) {
    let locationId = obj.locationId > 0 ? obj.locationId : this.selectedLocationId;
    const url = `${environment.apiUrl}/locations/${locationId}/patients/${obj.patientId}/appointments/${obj.id}/reschedule`;
    return this.http.post(url, obj);
  }

  searchPatient(searchString: string) {
    const url = `${environment.apiUrl}/patients?search=${searchString}&pageSize=20&page=0`;
    return this.http.get(url);
  }

  updateAppoinment(patientId: number, appointmentId: number, appoinment: any) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${patientId}/appoinments/${appointmentId}`;
    return this.http.put(url, appoinment);
  }

  getCalendarStats(date?: Date) {
    return this.selectedDate$.pipe(
      withLatestFrom(this.selectedLocation$),
      take(1),
      switchMap(([viewDate, location]) => {
        const queryDate = date ? moment.utc(date).toDate() : viewDate;
        return this.locationClient.location_GetCalendarStats(location.id, queryDate).pipe(take(1));
      })
    );
  }

  getDateCalender(date?: Date) {
    return this.selectedDate$.pipe(
      withLatestFrom(this.selectedLocation$),
      take(1),
      switchMap(([viewDate, location]) => {
        const queryDate = date ? moment.utc(date).toDate() : viewDate;
        return this.locationClient.location_GetCalendar(location.id, queryDate).pipe(take(1));
      })
    );
  }

  getSingleAppointment(patientId, appointmentId) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${patientId}/appointments/${appointmentId}`;
    return this.http.get(url);
  }

  getAvailableSchedules(obj: any) {
    let params = {
      id: obj.id,
      locationId: obj.locationId,
      procedureId: obj.procedureId,
      providerId: obj.providerId,
      doctorTimeId: obj.doctorTimeId,
      date: obj.date,
    };

    let url = `${environment.apiUrl}/locations/${this.selectedLocationId}/availableSchedules`;
    return this.http.get(url, { params: params });
  }

  getAppointment(obj: any) {
    let locationId = obj.locationId > 0 ? obj.locationId : this.selectedLocationId;
    let url = `${environment.apiUrl}/locations/${locationId}/patients/${obj.patientId}/appointments/${obj.id}`;
    return this.http.get(url);
  }

  putAppointment(obj: AppointmentDTO) {
    localStorage.setItem('etag', obj.etag);
    let locationId = obj.locationId > 0 ? obj.locationId : this.selectedLocationId;
    let url = `${environment.apiUrl}/locations/${locationId}/patients/${obj.patientId}/appointments/${obj.id}`;
    return this.http.put(url, obj);
  }

  postAppointmentNote(obj: AppointmentDTO) {
    let locationId = obj.locationId > 0 ? obj.locationId : this.selectedLocationId;
    return this.locationClient.location_PostAppointmentComments(locationId, obj.patientId, obj.id, obj.comments);
  }

  deleteAppointment(obj: AppointmentDTO) {
    localStorage.setItem('etag', obj.etag);
    let locationId = obj.locationId > 0 ? obj.locationId : this.selectedLocationId;
    let url = `${environment.apiUrl}/locations/${locationId}/patients/${obj.patientId}/appointments/${obj.id}`;
    return this.http.delete(url);
  }

  getAppointments(patientId: number) {
    let locationId = this.selectedLocationId != null && this.selectedLocationId > 0 ? this.selectedLocationId : 3;
    const url = `${environment.apiUrl}/locations/${locationId}/patients/${patientId}/appointments?pageSize=100`;
    return this.http.get(url);
  }

  getNotes(date: Date) {
    const _date = new Date(new Date(date).setHours(0,0,0,0));
    return this.selectedLocationId$.pipe(
      switchMap((locationId) => {
        return !!locationId ?
          this.locationClient.location_GetScheduleGridNotes(locationId, this.getISODate(_date)).pipe(take(1)) :
          of(null)
      }),
    );
  }

  postAppointments(obj: GridRowItemDTO) {
    let data = { params: { id: this.selectedLocationId, patientId: obj.patientId.toString() } };
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${obj.patientId}/appointments`;
    return this.http.post(url, obj, data);
  }

  putAppointments(obj: GridRowItemDTO) {
    let data = { params: { id: this.selectedLocationId, patientId: obj.patientId.toString(), appointmentId: obj.id.toString() } };
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${obj.patientId}/appointments/${obj.id}`;
    return this.http.put(url, obj, data);
  }

  postBlock(obj: any) {
    return this.selectedDate$.pipe(
      withLatestFrom(this.selectedLocation$),
      take(1),
      switchMap(([viewDate, location]) => this.locationClient.location_PostBlock(location.id, viewDate, obj).pipe(take(1)))
    );
  }

  putBlock(obj: any) {
    let data = { params: { id: this.selectedLocationId, scheduleGridItemId: obj.id.toString() } };
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/blocks/${obj.id}`;
    return this.http.put(url, obj, data);
  }

  deleteBlock(id: number) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/blocks/${id}`;
    return this.http.delete(url);
  }

  postOnDeck(obj: any) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/ondeck`;
    return this.http.post(url, obj);
  }

  postCheckOut(obj: any) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${obj.patientId}/appointments/${obj.appointmentId}/checkout`;
    return this.http.post(url, obj);
  }

  postFinished(obj: any) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${obj.patientId}/appointments/${obj.appointmentId}/finished`;
    return this.http.post(url, obj);
  }

  getPatientCompliance(obj: any) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${obj.patientId}/appointments/${obj.appointmentId}/compliance`;
    return this.http.get(url);
  }

  postPatientCompliance(obj: ComplianceDTO) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/patients/${obj.patientId}/appointments/${obj.appointmentId}/compliance`;
    return this.http.post(url, obj);
  }

  postDataToOnDeck(obj) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/ondeck`;
    return new Promise((resolve, reject) => {
      return this.http.post(url, obj).subscribe(
        (res) => {
          if (res) resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addUpdateNotes(scheduleNotes, date) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/notes?date=${date}`;
    return new Promise((resolve, reject) => {
      return this.http.post(url, scheduleNotes).subscribe(
        (res: any) => {
          res.date = date;
          if (res) resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  putScheduleChair(rowId, ondeckObject) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/chairs/${rowId}`;
    return this.http.put(url, ondeckObject);
  }

  deleteNotes(date) {
    const url = `${environment.apiUrl}/locations/${this.selectedLocationId}/schedules/notes?date=${date}`;
    return this.http.delete(url);
  }

  getEvent(scheduleGridItemId: number) {
    return this.locationClient.location_GetEvent(this.selectedLocationId, scheduleGridItemId);
  }

  postEvent(scheduleDate: Date, block: CreateEventDto) {
    return this.locationClient.location_PostEvent(this.selectedLocationId, scheduleDate, block);
  }

  putEvent(scheduleGridItemId: number, event: UpdateEventDto) {
    return this.locationClient.location_PutEvent(this.selectedLocationId, scheduleGridItemId, event);
  }

  deleteEvent(scheduleGridItemId) {
    return this.locationClient.location_DeleteEvent(this.selectedLocationId, scheduleGridItemId);
  }

  getISODate(date: any) {
    if (!date || !(date instanceof Date))
      return date;

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let utcDate = new Date(Date.UTC(year, month, day));

    return utcDate;
  }
}

export class DTO {
  editMode: boolean = false;
  viewMode: boolean = false;
  addMode: boolean = false;
  deleteMode: boolean = false;
  etag: string;
  tempId: number;

  constructor(data?: any) {
    if (data != null) {
      this.etag = data.etag != null ? data.etag : data.eTag != null ? data.eTag : null;
      this.tempId = data.tempId;
    }

    let self = this;

    if (this.tempId == null) {
      setTimeout(function () {
        self.tempId = uuid.v4();
      });
    }
  }

  viewModeOn() {
    this.viewMode = true;
    this.editMode = false;
    this.addMode = false;
    this.deleteMode = false;
  }

  editModeOn() {
    this.viewMode = false;
    this.editMode = true;
    this.addMode = false;
    this.deleteMode = false;
  }

  addModeOn() {
    this.viewMode = false;
    this.editMode = false;
    this.addMode = true;
    this.deleteMode = false;
  }

  deleteModeOn() {
    this.viewMode = false;
    this.editMode = false;
    this.addMode = false;
    this.deleteMode = true;
  }

  cancelMode() {
    this.viewMode = false;
    this.editMode = false;
    this.addMode = false;
    this.deleteMode = false;
  }

  formatTimeValue(time: string) {
    if (time == null) { return undefined; }

    if (time.indexOf('T') != -1) {
      return moment(time).format('hh:mm');
    } else {
      let date = moment().format('l');

      return moment(date + ' ' + time).format('hh:mm');
    }
  }

  formatTimeAMPMValue(time: string) {
    if (time == null) { return undefined; }

    if (time.indexOf('T') != -1) {
      return moment(time).format('h:mm a');
    } else {
      let date = moment().format('l');

      return moment(date + ' ' + time).format('h:mm a');
    }
  }

  setIncrement(value: string) {
    let increment = 0;

    if (value == 'FiveMinutes' || Number(value) == 5) {
      increment = 5;
    } else if (value == 'TenMinutes' || Number(value) == 10) {
      increment = 10;
    } else if (value == 'FifteenMinutes' || Number(value) == 15) {
      increment = 15;
    }

    return increment;
  }

  getHours(time: string) { // supply time only
    if (time.indexOf('T') != -1) {
      return Number(moment(time).utc().format('H'));
    }

    return Number(moment(time, 'H').format('H'));
  }

  getMinutes(time: string) { // supply time only
    if (time.indexOf('T') != -1) {
      return Number(moment(time).utc().format('m'));
    }

    return Number(moment(time, 'm').format('m'));
  }

  convertDate(date: string, format?: string, asUTC?: boolean) {
    if (date == null) { return ''; }

    let mDate = asUTC ? moment.utc(date) : moment(date);

    if (format == 'longDate') { // May 20, 2020
      return mDate.format('LL');
    } else if (format == 'shortDayName') { // Wed
      return mDate.format('llll').split(',')[0];
    } else if (format == 'fullDayName') { // Wednesday
      return mDate.format('dddd');
    } else if (format == '12hr') { // 12:00 PM
      return mDate.format('LT');
    } else if (format == '24hr') { // 14:00
      return mDate.format('HH:mm');
    } else if (format == 'default') {
      return mDate.format('L'); // 05/20/2020
    }

    return mDate.format(format); // custom moment formats
  }
}

export class ScheduleGridDTO extends DTO {
  timeZone: string;
  startTime: string;
  endTime: string;
  lunchStartTime: string;
  lunchEndTime: string;
  increment: number;
  colorCode: string;
  rows: RowDTO[];

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.timeZone = data.locationTimeZone;
      this.startTime = this.convertDate(data.startTime, 'HH:mm');
      this.endTime = this.convertDate(data.endTime, 'HH:mm');
      this.lunchStartTime = this.convertDate(data.lunchStartTime, 'HH:mm');
      this.lunchEndTime = this.convertDate(data.lunchEndTime, 'HH:mm');
      this.increment = this.setIncrement(data.increment);
      this.colorCode = data.colorCode;
      this.rows = [];

      if (data.rows != null && data.rows.length > 0) {
        data.rows.forEach(row => {
          this.rows.push(new RowDTO(row));
        });
      }
    }
  }
}

export class RowDTO extends DTO {
  rowId: number;
  title: string;
  displayOrder: number;
  items: GridRowItemDTO[];

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.rowId = data.rowId;
      this.title = data.title;
      this.displayOrder = data.displayOrder;
      this.items = [];

      if (data.items != null && data.items.length > 0) {
        data.items.forEach(item => {
          this.items.push(new GridRowItemDTO(item));
        });
      }
    }
  }
}

export class GridRowItemDTO extends DTO {
  id: number;
  patientId: number;
  originalStartTime: string;
  startTime: string;
  startTimeLabel: string;
  endTime: string;
  isPaymentRequired: boolean;
  hasAlergy: boolean;
  hasNotes: boolean;
  isCheckedIn: boolean;
  startTimeInHr: number;
  endTimeInHr: number;
  durationMin: number;
  location: string;
  colorCode: string;
  comments: string;
  rowId: number;
  providerId: number;
  procedureGroupId: number;
  procedureGroup: ProcedureGroupDTO;
  procedureGroupName: string;
  procedureId: number;
  procedure: ProcedureDTO;
  doctorTimeId: number;
  locationId: number;
  notes: string;
  date: string;
  doctorTimeName: string;
  firstName: string;
  lastName: string;
  procedureCode: string;
  appointmentId: number;
  appointmentStatus: string;
  eventName: string;
  isEvent: boolean;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.patientId = data.patientId;
      this.originalStartTime = data.startTime;
      this.startTime = this.formatTimeValue(data.startTime);
      this.startTimeLabel = this.formatTimeAMPMValue(this.startTime);
      this.endTime = this.formatTimeValue(data.endTime);
      this.isPaymentRequired = data.isPaymentRequired;
      this.hasAlergy = data.hasAlergy;
      this.hasNotes = data.hasNotes;
      this.isCheckedIn = data.isCheckedIn;
      this.startTimeInHr = this.getHour(this.startTime);
      this.endTimeInHr = this.getHour(this.endTime);
      this.durationMin = Math.round(Math.abs(this.startTimeInHr - this.endTimeInHr) * 60);
      this.location = data.location;
      this.colorCode = data.colorCode;
      this.comments = data.comments;
      this.rowId = data.rowId;
      this.date = data.date;
      this.locationId = data.locationId;
      this.providerId = data.providerId;
      this.procedureGroupId = data.procedureGroupId;
      this.procedureId = data.procedureId;
      this.procedure = new ProcedureDTO(this.procedure);
      this.doctorTimeId = data.doctorTimeId;
      this.notes = data.notes;
      this.doctorTimeName = data.doctorTimeName;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.procedureCode = data.procedureCode;
      this.appointmentId = data.appointmentId;
      this.appointmentStatus = data.appointmentStatus;
      this.eventName = data.eventName;
      this.isEvent = data.isEvent;

      if (typeof data.procedureGroup == 'string') {
        this.procedureGroupName = data.procedureGroup;
      } else {
        this.procedureGroup = new ProcedureGroupDTO(data.procedureGroup);
      }

      this.setDate();
    }
  }

  setDate() {
    if (this.date != null) { return this.date; }
    if (this.originalStartTime != null && this.originalStartTime.indexOf('T') != -1) {
      this.date = this.originalStartTime.split('T')[0];;
    }

    return null;
  }

  getHour(time: string) {
    if (time == null) { return null; }
    let timeSplit = time.split(':');
    let hr = Number(timeSplit[0]);
    let min = Number(timeSplit[1]);
    return hr + (min / 60);
  }

  calculateEndTime() {
    let date = moment().format('l');

    this.endTime = moment(date + ' ' + this.startTime)
      .add(this.durationMin, 'minutes')
      .format('HH:mm');
  }
}

export class PatientDTO extends DTO {
  id: number;
  contact: any;
  name: string;
  medicalAlert: string;
  accountNumber: string;
  cleaningRecall: string;
  consentSigned: string;
  contactETag: string;
  contactId: number;
  dob: string;
  firstName: string;
  gender: string;
  hipaaSigned: string;
  hobbiesInterests: string;
  languageKey: string;
  lastName: string;
  locationId: number;
  loyaltyCard: string;
  middleName: string;
  nickname: string;
  optOut: boolean;
  patientStatusId: number;
  paymentAmountDue: number;
  payoffDue: number;
  preferredCaseManagerId: string;
  preferredContactMethod: string;
  preferredLocationId: number;
  preferredProviderId: number;
  primaryAddress: any;
  primaryEmail: any;
  school: string;
  ssn: string;
  primaryAddressCity: string;
  primaryAddressLine1: string;
  primaryAddressState: string;
  primaryAddressZip: string;
  primaryEmailAddress: string;
  primaryPhoneNumber: string;
  mobilePhone: any;
  address: string;
  profileThumbnailUrl: string;
  profileUrl: string;
  sourceUrl: string;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.contact = data.contact;
      this.name = data.firstName + ' ' + data.lastName;
      this.medicalAlert = data.medicalAlert;
      this.accountNumber = data.accountNumber;
      this.cleaningRecall = data.cleaningRecall;
      this.consentSigned = data.consentSigned;
      this.contactETag = data.contactETag;
      this.contactId = data.contactId;
      this.dob = data.dob;
      this.firstName = data.firstName;
      this.gender = data.gender;
      this.hipaaSigned = data.hipaaSigned;
      this.hobbiesInterests = data.hobbiesInterests;
      this.languageKey = data.languageKey;
      this.lastName = data.lastName;
      this.locationId = data.locationId;
      this.loyaltyCard = data.loyaltyCard;
      this.middleName = data.middleName;
      this.nickname = data.nickname;
      this.optOut = data.optOut;
      this.patientStatusId = data.patientStatusId;
      this.paymentAmountDue = data.paymentAmountDue;
      this.payoffDue = data.payoffDue;
      this.preferredCaseManagerId = data.preferredCaseManagerId;
      this.preferredContactMethod = data.preferredContactMethod;
      this.preferredLocationId = data.preferredLocationId;
      this.preferredProviderId = data.preferredProviderId;
      this.primaryAddress = data.primaryAddress;
      this.primaryEmail = data.primaryEmail;
      this.school = data.school;
      this.ssn = data.ssn;
      this.primaryAddressCity = data.primaryAddressCity;
      this.primaryAddressLine1 = data.primaryAddressLine1;
      this.primaryAddressState = data.primaryAddressState;
      this.primaryAddressZip = data.primaryAddressZip;
      this.primaryEmailAddress = data.primaryEmailAddress;
      this.primaryPhoneNumber = data.primaryPhoneNumber;
      this.address = this.getAddress();
      this.mobilePhone = data.mobilePhone;
      this.profileThumbnailUrl = data.profileThumbnailUrl;
      this.profileUrl = data.profileUrl;
      this.sourceUrl = data.sourceUrl;
    }
  }

  getBirthDay() {
    return this.convertDate(this.dob, 'default', true);
  }

  getFullBirthday() {
    let duration = moment.duration(moment().diff(moment(this.dob)));
    return this.convertDate(this.dob, 'longDate', true) + " (" + Math.round(duration.years()) + "y " + Math.round(duration.months()) + "m)";
  }

  getAddress() {
    let address = '';
    let city = '';
    let state = '';
    let zip = '';

    if (this.primaryAddressLine1) {
      address = this.primaryAddressLine1;
    }

    if (this.primaryAddressCity) {
      if (this.primaryAddressLine1) {
        city = ", " + this.primaryAddressCity;
      } else {
        city = this.primaryAddressCity;
      }
    }

    if (this.primaryAddressState) {
      if (this.primaryAddressLine1 || this.primaryAddressCity) {
        state = ", " + this.primaryAddressState;
      } else {
        state = this.primaryAddressState;
      }
    }

    if (this.primaryAddressZip) {
      zip = this.primaryAddressZip;
    }

    return `${address} ${city} ${state} ${zip}`;
  }

  getLanguage() {
    if (this.languageKey != null) {
      let languageKey = this.languageKey.toLowerCase();

      if (languageKey == 'es') {
        return 'spanish';
      }
    }

    return '';
  }
}

export class NotificationDTO extends DTO {
  id: number;
  patientId: number;
  firstName: string;
  lastName: string;
  forAppointments: boolean = false;
  forFinancial: boolean = false;
  forMarketing: boolean = false;
  forBirthday: boolean = false;
  forTreatment: boolean = false;
  isSecureAccess: boolean = false;
  relationshipType: string;
  relationshipTypeLabel: string;
  relationshipTypeAbbreviation: string;
  contactSourceType: string;
  contactType: number;
  contactPhoneEmailId: number;
  contactPhoneEmail: string;
  sameType: boolean = false;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.patientId = data.patientId;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.forAppointments = data.forAppointments;
      this.forFinancial = data.forFinancial;
      this.forMarketing = data.forMarketing;
      this.forBirthday = data.forBirthday;
      this.forTreatment = data.forTreatment;
      this.isSecureAccess = data.isSecureAccess;
      this.relationshipType = data.relationshipType;
      this.relationshipTypeLabel = data.relationshipTypeLabel;
      this.relationshipTypeAbbreviation = data.relationshipTypeAbbreviation;
      this.contactSourceType = data.contactSourceType;
      this.contactType = data.contactType;
      this.contactPhoneEmailId = data.contactPhoneEmailId;
      this.contactPhoneEmail = data.contactPhoneEmail;
    }
  }
}

export class SearchPatientDTO extends DTO {
  id: number;
  procedureId: number;
  providerId: number;
  locationId: number;
  doctorTimeId: number;
  dayPeriod: string;
  timeFrameValue: number;
  timeFrame: string;
  notes: string;
  date: string;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.locationId;
      this.procedureId = data.procedureId;
      this.providerId = data.providerId;
      this.locationId = data.locationId;
      this.doctorTimeId = data.doctorTimeId;
      this.dayPeriod = data.dayPeriod;
      this.timeFrameValue = data.timeFrameValue;
      this.timeFrame = data.timeFrame;
      this.notes = data.notes;
      this.date = data.date;
    }
  }

  initSearchDate() {
    this.date = this.getFutureDate(this.timeFrame, this.timeFrameValue)
  }

  getFutureDate(timeFrame, value: number) {
    return moment().add(value, timeFrame).format('L');
  }

  nextWeek() {
    this.date = moment(this.date).add(1, 'weeks').format('L');
  }

  prevWeek() {
    this.date = moment(this.date).add(-1, 'weeks').format('L');
  }
}

export class AppointmentDTO extends DTO {
  id: number;
  endTime: string;
  isCurrentOrFuture: boolean;
  locationId: number;
  locationName: string;
  patientId: number;
  procedureName: string;
  procedureCode: string;
  procedureDescription: string;
  procedureId: number;
  providerId: number;
  startTime: string;
  comments: string;
  shortDayName: string;
  fullDayName: string;
  date: string;
  longDate: string;
  time: string;
  showComment: boolean;
  doctorTimeId: number;
  isChanged: boolean;
  toBlockId: number;
  kept: string;
  status: string;
  nextAppointments: any[];
  hasMultipleAppointments: boolean;
  hasNextAppointment: boolean;
  nextProcedure: string;
  nextProcedureId: number;
  nextSchedule: string;
  fromAppointmentId: number;
  nextNotes: string;
  hasTxCard: boolean;
  nextApppointmentId: number;
  locationTimeZone: string;
  isTriggered: boolean;
  feeAmount: number;
  feeNotes: string;
  isNoShow:boolean;
  isPastAppointmentEditable:boolean;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      if (!data.locationTimeZone) data.locationTimeZone = 'UTC';
      const startDate = moment.utc(data.startTime).tz(data.locationTimeZone);

      this.id = data.id;
      this.endTime = data.endTime;
      this.isCurrentOrFuture = data.isCurrentOrFuture;
      this.locationId = data.locationId;
      this.locationName = data.locationName;
      this.patientId = data.patientId;
      this.procedureCode = data.procedureCode;
      this.procedureDescription = data.procedureDescription;
      this.procedureId = data.procedureId;
      this.procedureName = data.procedureCode + "-" + data.procedureDescription;
      this.startTime = startDate.toISOString();
      this.comments = data.comments;
      this.showComment = data.showComment;
      this.providerId = data.providerId;
      this.doctorTimeId = data.doctorTimeId;
      this.isChanged = data.isChanged;
      this.toBlockId = data.toBlockId;
      this.status = data.status;
      this.fromAppointmentId = data.fromAppointmentId;
      this.nextNotes = data.nextNotes;
      this.nextAppointments = Array.isArray(data.nextAppointments) ? data.nextAppointments : [];
      this.hasMultipleAppointments = this.nextAppointments.length > 1;
      this.hasNextAppointment = this.nextAppointments.length > 0;
      this.hasTxCard = data.hasTxCard;
      this.nextApppointmentId = data.nextApppointmentId;
      this.isTriggered = data.isTriggered;

      this.locationTimeZone = data.locationTimeZone;
      this.time = startDate.format('h:mm a').toLowerCase();
      this.longDate = startDate.format('MMMM D YYYY');
      this.date = startDate.format('MM/DD/YYYY').toLowerCase()
      this.fullDayName = startDate.format('dddd');
      this.shortDayName = startDate.format('ddd');
      this.isNoShow = data.isNoShow;
      this.isPastAppointmentEditable = data.isPastAppointmentEditable;

      this.setKeptStatus(data.status);
      this.setNextProcedure();
      this.setNextSchedule();
    }
  }

  setKeptStatus(s: string) {
    if (s == null) { return ''; }

    let status = s.toLowerCase();

    if (status == 'cancelled') {
      this.kept = 'C';
    } else if (status == 'rescheduled') {
      this.kept = 'R';
    } else if (status == 'no show') {
      this.kept = 'X';
    } else if (status == 'kept') {
      this.kept = '*';
    }
  }

  setNextProcedure() {
    if (this.hasNextAppointment) {
      this.nextProcedure = this.nextAppointments[0].procedureCode + ' - ' + this.nextAppointments[0].procedureDescription;
      this.nextProcedureId = this.nextAppointments[0].procedureId;
    }
  }

  setNextSchedule() {
    let data = this.getNextSchedule();

    if (data != null) {
      if (data.timeFrame == 'Months') {
        this.nextSchedule = data.timeFrameValue + ' mths';
      } else if (data.timeFrame == 'Weeks') {
        this.nextSchedule = data.timeFrameValue + ' wks';
      } else {
        this.nextSchedule = data.timeFrameValue + ' days';
      }
    }
  }

  getNextSchedule(nextAppointment?: any) {
    let timeFrame = '';
    let timeFrameValue = 0;

    if (this.hasNextAppointment) {
      let next = nextAppointment != null ? nextAppointment : this.nextAppointments[0];
      if(next && next.nextPeriodName){
        let nextPeriodName = next.nextPeriodName.toLowerCase();
        let weeks = Math.floor(next.nextPeriodDays / 7);
        let months = Math.floor(next.nextPeriodDays / 30);
        let s1 = nextPeriodName.split(' ');

        if (nextPeriodName.indexOf('week') != -1) {
          timeFrameValue = Number(s1[0]) > 0 ? Number(s1[0]) : 0;
          timeFrame = 'Weeks';
        } else if (nextPeriodName.indexOf('month') != -1) {
          timeFrameValue = Number(s1[0]) > 0 ? Number(s1[0]) : 0;
          timeFrame = 'Months';
        } else if (nextPeriodName.indexOf('day') != -1) {
          timeFrameValue = Number(s1[0]) > 0 ? Number(s1[0]) : 0;
          timeFrame = 'Days';
        } else {
          if (months > 0) {
            timeFrameValue = months;
            timeFrame = 'Months';
          } else if (weeks > 0) {
            timeFrameValue = weeks;
            timeFrame = 'Weeks';
          } else {
            timeFrameValue = next.nextPeriodDays;
            timeFrame = 'Days';
          }
        }
      }


      return {
        timeFrame: timeFrame,
        timeFrameValue: timeFrameValue,
        doctorTimeId: next.doctorTimeId,
        procedureId: next.procedureId
      };
    }

    return null;
  }
}

export class AvailableScheduleDTO extends DTO {
  date: string;
  colorCode: string;
  items: ScheduleBlockDTO[];

  constructor(data?: any, searchModel?: SearchPatientDTO) {
    super(data);

    if (data != null) {
      this.date = data.date;
      this.colorCode = data.colorCode;
      this.items = [];

      if (searchModel != null) {
        if (Array.isArray(data.items)) {

          data.items.forEach(item => {
            let block = new ScheduleBlockDTO(item);
            let isMorning = this.isMorning(block.startTime);

            if ((searchModel.dayPeriod == 'All'
              || (searchModel.dayPeriod == 'Morning' && isMorning)
              || (searchModel.dayPeriod == 'Afternoon' && !isMorning))
              && (searchModel.doctorTimeId == block.doctorTimeId
                || searchModel.doctorTimeId <= 0)
              && (searchModel.locationId == block.locationId
                || searchModel.locationId <= 0)) {
              this.items.push(block);
            }
          });
        }
      } else {
        if (Array.isArray(data.items)) {
          data.items.forEach(item => this.items.push(new ScheduleBlockDTO(item)));
        }
      }
    }
  }


  unselectAllItems() {
    this.items.forEach(item => item.selected = false);
  }

  isMorning(date: string) {
    if (date.indexOf('T') != -1) {
      let dateVal = date.split('T')[0];
      let pmTime = dateVal + 'T12:00:00Z';
      return moment(date).isBefore(pmTime);
    }

    return false;
  }
}

export class ScheduleBlockDTO extends DTO {
  id: number;
  endTime: string;
  chairName: string;
  locationId: number;
  locationName: string;
  locationTimeZone: string;
  startTime: string;
  doctorTimeId: number;
  time: string;
  selected: boolean;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.endTime = data.endTime;
      this.chairName = data.chairName;
      this.locationId = data.locationId;
      this.locationName = data.locationName;
      this.locationTimeZone = data.locationTimeZone;
      this.startTime = data.startTime;
      this.selected = data.selected;
      this.doctorTimeId = data.doctorTimeId;
      this.time = this.convertDate(data.startTime, '12hr').toLowerCase();
    }
  }
}

export class OnDeckDTO extends DTO {
  appointmentId: number;
  arrivedWhen: string;
  displayScheduledFor: string;
  firstName: string;
  lastName: string;
  procedureCode: string;
  procedureGroup: string;
  procedureGroupColorCode: string;
  scheduledFor: string;
  timeGap: any;
  waitingTime: string;
  displayedArrivedWhen: string;
  displayedScheduledFor: string;
  interval: any;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.appointmentId = data.appointmentId;
      this.arrivedWhen = data.arrivedWhen;
      this.displayScheduledFor = data.displayScheduledFor;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.procedureCode = data.procedureCode;
      this.procedureGroup = data.procedureGroup;
      this.procedureGroupColorCode = data.procedureGroupColorCode;
      this.scheduledFor = data.scheduledFor;

      this.timeGap = this.calculateWatingTime(data);
      this.waitingTime = this.formatWaitingTime(this.timeGap);
      this.displayedArrivedWhen = moment.utc(data.arrivedWhen).tz(data.locationTimeZone).format('h:mm a');
      this.displayedScheduledFor = moment.utc(data.scheduledFor).tz(data.locationTimeZone).format('h:mm a');

      setInterval(() => {
        this.timeGap = this.calculateWatingTime(data);
        this.waitingTime = this.formatWaitingTime(this.timeGap);
      }, 60000);
    }
  }

  calculateWatingTime(data: any) {
    if (data == null) { return null; }

    let mArriveTime = moment.utc(data.arrivedWhen).tz(data.locationTimeZone);
    let mAppointmentTime = moment.utc(data.scheduledFor).tz(data.locationTimeZone);
    let mNow = moment.utc().tz(data.locationTimeZone);
    let mAppointmentTimePlus5 = mAppointmentTime.add(5, 'minutes');

    let hours = 0;
    let mins = 0;
    let late = false;

    if (mArriveTime.isAfter(mAppointmentTimePlus5)) {
      late = true;
      hours = mArriveTime.diff(mAppointmentTime, 'hours');
      mins = Number(moment(mArriveTime.diff(mAppointmentTime)).format("m"));
    } else {
      let minDiff = 0;
      if (mNow.isBefore(mAppointmentTime)) {
        minDiff = mNow.diff(mArriveTime, 'minutes');
      } else {
        minDiff = mAppointmentTime.diff(mArriveTime, 'minutes');
      }

      hours = Math.floor(minDiff / 60);
      mins = Math.floor(minDiff % 60);
    }

    return { hours: hours, mins: mins, late: late };
  }

  formatWaitingTime(time: any) {
    let displayTime = '';

    if (time == null) { return displayTime; }

    if (time.hours > 0) {
      displayTime += time.hours + 'h';
    }

    if (time.mins > 0) {
      displayTime += time.mins + 'm';
    }

    return displayTime;
  }
}

export class ScheduledChairDTO extends DTO {
  appointmentId: number;
  displayOrder: number;
  durationMinutes: number;
  firstName: string;
  lastName: string;
  progress: number;
  rowId: number;
  seatedWhen: string;
  title: string;
  duration: string;
  appointmentStartTime: string;
  appointmentEndTime: string;
  borderColor: string;
  strokeOffset = 0;
  startStrokeOffset = -250;
  endStrokeOffset = 0;
  interval: any;
  timeElapse: number;
  patientId: number;
  seated: boolean = false;
  hasTxCard: boolean = false;
  totalDuration: number;
  locationTimeZone: string;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.locationTimeZone = data.locationTimeZone;
      this.appointmentId = data.appointmentId;
      this.displayOrder = data.displayOrder;
      this.durationMinutes = data.durationMinutes;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.progress = data.progress;
      this.rowId = data.rowId;
      this.seatedWhen = data.seatedWhen;
      this.title = data.title;
      this.appointmentStartTime = data.appointmentStartTime;
      this.patientId = data.patientId;
      this.hasTxCard = data.hasTxCard;
      this.strokeOffset = this.startStrokeOffset;
      if(this.appointmentId > 0) {
        this.appointmentEndTime = moment(data.appointmentStartTime).utc().add(this.durationMinutes, 'minutes').format('HH:mm');
        this.duration = this.setDuration(data.durationMinutes);
        this.setBorderColor(data);

        this.calculateStrokeOffset();
        setInterval(() => {
          this.calculateStrokeOffset();
        }, 60000);
      }
    }
  }

  setBorderColor(data: any) {
    if (data == null) { return null; }
    let color = '#0ED251';
    let format = 'HH:mm';
    let fArriveTime = moment(data.seatedWhen).format(format);
    let fAppointmentTime = moment(data.appointmentStartTime).utc().format(format);
    let mArriveTime = moment(fArriveTime, format);
    let fAppointmentTimePlus5 = moment(fAppointmentTime, format);
    fAppointmentTimePlus5.add(5, 'minutes').format(format);
    let mAppointmentTimePlus5 = moment(fAppointmentTimePlus5, format);
    let fAppointmentTimeMinus5 = moment(fAppointmentTime, format);
    fAppointmentTimeMinus5.subtract(5, 'minutes').format(format);
    let mAppointmentTimeMinus5 = moment(fAppointmentTimeMinus5, format);

    if (mArriveTime.isAfter(mAppointmentTimePlus5)) {
      color = '#FFC5C5';
    } else if (mArriveTime.isBefore(mAppointmentTimeMinus5)) {
      color = '#C9F2FF';
    }

    this.borderColor = color;
  }

  setDuration(time: any) {
    if (time == null) { return null; }

    let hours = Math.floor(time / 60);
    let mins = Math.floor(time % 60);
    let displayTime = '';

    if (hours > 0) { displayTime += hours + 'hr'; }
    if (mins > 0) { displayTime += mins + 'm'; }

    return displayTime;
  }

  calculateStrokeOffset() {
    let current = moment.utc().tz(this.locationTimeZone);
    let appointmentTime = moment.utc(this.appointmentStartTime).tz(this.locationTimeZone);
    let arriveTime = moment.utc(this.seatedWhen).tz(this.locationTimeZone);
    this.timeElapse = current.diff(arriveTime, 'minutes');

    if (arriveTime.isBefore(appointmentTime)) {
      let diff = appointmentTime.diff(arriveTime, 'minutes');
      this.totalDuration = this.durationMinutes + diff;
    } else {
      this.totalDuration = this.durationMinutes;
    }

    let percentage = this.timeElapse / this.totalDuration;

    if (percentage >= 1) {
      this.strokeOffset = this.endStrokeOffset;
    } else {
      this.strokeOffset = this.startStrokeOffset - (this.startStrokeOffset * percentage);
      this.strokeOffset = this.strokeOffset > 0 ? this.endStrokeOffset : this.strokeOffset;
    }
  }

  getOvertimeElapse() {
    let displayTime = '';

    if (this.timeElapse > this.totalDuration) {
      let elapse = this.timeElapse - this.totalDuration;
      displayTime = '+ ' + this.setDuration(elapse);
    }

    return displayTime;
  }

  /* calculateStrokeOffset() {
    let format = 'HH:mm';
    let fAppointmentTime = moment(this.appointmentStartTime).utc().format(format);
    let mAppointmentTime = moment(fAppointmentTime, format);
    let fNow = moment().format(format);
    let mNow = moment(fNow, format);
    let mEndTime = moment(this.appointmentEndTime, format);
    this.timeElapse = mNow.diff(mAppointmentTime, 'minutes');

    if (mNow.isBefore(mAppointmentTime)) {
      this.strokeOffset = this.startStrokeOffset;
    } else if (mNow.isAfter(mEndTime)) {
      this.strokeOffset = this.endStrokeOffset;
    } else {
      let percentage = this.timeElapse / this.durationMinutes;
      this.strokeOffset = this.startStrokeOffset - (this.startStrokeOffset * percentage);
      this.strokeOffset = this.strokeOffset > 0 ? this.endStrokeOffset : this.strokeOffset;
    }
  } */

  /* fNow = '09:05';
  calculateStrokeOffsetTest() { // for testing
    let format = 'HH:mm';
    this.fNow = moment(this.fNow, format).add(1, 'minutes').format(format);
    let mNow = moment(this.fNow, format);
    let mAppointmentTime = moment('09:10', format);
    let mEndTime = moment('10:00', format);
    let mArriveTime = moment('09:00', format);
    this.timeElapse = mNow.diff(mArriveTime, 'minutes');

    console.log('timeElapse:', this.timeElapse);
    console.log('mNow:', mNow);
    console.log('mArriveTime:', mArriveTime);
    console.log('mAppointmentTime:', mAppointmentTime);
    console.log('mEndTime:', mEndTime);

    if (mArriveTime.isBefore(mAppointmentTime)) {
      let diff = mAppointmentTime.diff(mArriveTime, 'minutes');
      console.log('diff:', diff);
      this.totalDuration = this.durationMinutes + diff;
    } else if (mArriveTime.isAfter(mAppointmentTime)) {
      let diff = mArriveTime.diff(mAppointmentTime, 'minutes');
      this.totalDuration = this.durationMinutes - diff;
    } else {
      this.totalDuration = this.durationMinutes;
    }

    let percentage = this.timeElapse / this.totalDuration;

    if (percentage >= 1) {
      this.strokeOffset = this.endStrokeOffset;
    } else {
      this.strokeOffset = this.startStrokeOffset - (this.startStrokeOffset * percentage);
      this.strokeOffset = this.strokeOffset > 0 ? this.endStrokeOffset : this.strokeOffset;
      console.log('totalDuration:', this.totalDuration);
      console.log('percentage:', percentage);
      console.log('strokeOffset:', this.strokeOffset);
    }

    console.log('==================================');
  } */
}

export class ComplianceDTO extends DTO {
  id: number;
  treatmentId: number;
  appointmentId: number;
  applianceWearComplianceScore: ComplianceScoreEnum;
  dentistVisitComplianceScore: ComplianceScoreEnum;
  elasticWearComplianceScore: ComplianceScoreEnum;
  oralHygieneComplianceScore: ComplianceScoreEnum;
  overallComplianceScore: ComplianceScoreEnum;
  patientId: number;
  lastDentistVisitDate?: Date | null;
  previousEstimateCompletionDate?: Date | null;
  currentEstimateCompletionDate?: Date | null;
  previousCompletionDate?: Date | null;
  currentCompletionDate?: Date | null;
  isActiveOrRecallStatus!: boolean;
  isOnTrack: boolean = true;
  patientStatusId: number;
  patientStatus: any;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.treatmentId = data.treatmentId;
      this.appointmentId = data.appointmentId;
      this.applianceWearComplianceScore = data.applianceWearComplianceScore;
      this.dentistVisitComplianceScore = data.dentistVisitComplianceScore;
      this.elasticWearComplianceScore = data.elasticWearComplianceScore;
      this.oralHygieneComplianceScore = data.oralHygieneComplianceScore;
      this.overallComplianceScore = data.overallComplianceScore;
      this.patientId = data.patientId;
      this.lastDentistVisitDate = data.lastDentistVisitDate;
      this.previousEstimateCompletionDate = data.previousEstimateCompletionDate;
      this.currentEstimateCompletionDate = data.currentEstimateCompletionDate;
      this.isOnTrack = data.isOnTrack;
      this.patientStatusId = data.patientStatusId;
      this.patientStatus = data.patientStatus;
      this.isActiveOrRecallStatus = data.isActiveOrRecallStatus;
      this.setPreviousCompletionDate();
      this.setCurrentCompletionDate();
    }
  }

  setPreviousCompletionDate() {
    if (this.previousEstimateCompletionDate == null && this.currentEstimateCompletionDate != null) {
      this.previousCompletionDate = this.currentEstimateCompletionDate;
    } else if (this.previousEstimateCompletionDate != null) {
      this.previousCompletionDate = this.previousEstimateCompletionDate
    } else {
      this.previousCompletionDate = null;
    }
  }

  setCurrentCompletionDate() {
    if (this.previousEstimateCompletionDate == null && this.currentEstimateCompletionDate != null) {
      this.currentCompletionDate = null;
    } else {
      this.currentCompletionDate = this.currentEstimateCompletionDate;
    }
  }
}
