import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { DentistDto, IDentistDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<DentistStoreEntity> = createEntityAdapter<DentistStoreEntity>({
  selectId: dentist => dentist.id,
  sortComparer: (a: DentistStoreEntity, b: DentistStoreEntity): number => { return b.displayName > a.displayName ? -1 : 0 }
});

export interface State extends EntityState<DentistStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedDentistId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedDentistId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class DentistStoreEntity extends DentistDto {
  constructor(data?: IDentistDto) {
    super(data);
  }
}
