import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { RelationshipDto, IRelationshipDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<ResponsiblePartyStoreEntity> = createEntityAdapter<ResponsiblePartyStoreEntity>({
  selectId: patient => patient.id,
  sortComparer: (a: ResponsiblePartyStoreEntity, b: ResponsiblePartyStoreEntity): number => a.sortOrder - b.sortOrder
});

export interface State extends EntityState<ResponsiblePartyStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedResponsiblePartyId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedResponsiblePartyId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class ResponsiblePartyStoreEntity extends RelationshipDto {
  constructor(data?: IRelationshipDto) {
    super(data);
  }
}
