import { createAction, props } from '@ngrx/store';
import { ActiveTreatmentProgressStoreEntity } from './state';

export const LoadRequest = createAction(
  '[Treatment] Active Treatment Progress Load Request',
);

export const LoadSuccess = createAction(
  '[Treatment] Active Treatment Progress Load Success',
  props<{ activeTreatmentProgress: ActiveTreatmentProgressStoreEntity }>()
);

export const LoadFailure = createAction(
  '[Treatment] Active Treatment Progress Load Failure',
  props<{ err: any}>()
);
