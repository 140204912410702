import { Action, createReducer, on } from "@ngrx/store";
import * as TenantStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(TenantStoreActions.LoadSuccess, (state, action) =>
    Array.isArray(action.tenants)
      ? featureAdapter.upsertMany(action.tenants, featureAdapter.removeAll({ ...state, isLoading: false }))
      : featureAdapter.upsertOne(action.tenants, { ...state, isLoading: false })
  ),
  on(TenantStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
