import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-interval-panel',
  templateUrl: './interval-panel.component.html',
  styleUrls: ['./interval-panel.component.scss'],
})
export class IntervalPanelComponent {
  @Input() duration: number;
  @Output() selectionChange = new EventEmitter();

  getIntervalValue(i: number, col: number) {
		return i * 5 + col;
  }
  
  getIntervalPanelClass(c: number) {
		var v = c * 5 + 5;

		for (var i = 1; i <= 6; i++) {
			return (v * i) % 30 == 0 ? 'highlight' : ''
		}

		return '';
  }

  onSelectionChange(value: number) {
    this.duration = value;
    this.selectionChange.emit(this.duration);
  }
}