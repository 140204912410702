import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { FullScreenImage } from './full-screen-image';

@Component({
  selector: 'full-screen-viewer',
  templateUrl: './full-screen-viewer.component.html',
  styleUrls: ['./full-screen-viewer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FullScreenViewerComponent implements OnInit {
  showFullScreen: boolean = false;
  selectedImageIndex: number = -1;
  fullScreenImages: FullScreenImage[];

  constructor(
    private _dialogRef: MatDialogRef<FullScreenViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data) {
      this.fullScreenImages = data.images;
      this.selectedImageIndex = data.selectedImageIndex;
      this.showFullScreen = true;
    }
  }

  ngOnInit() {
  }

  close(): void {
    this._dialogRef.close();
  }
}
