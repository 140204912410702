import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { JwtBody } from 'src/app/shared-module/user-permissions/jwt.model';
import { TokenDto } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';

export const featureAdapter = createEntityAdapter<TokenDto>({
  selectId: (token) => token.id,
  sortComparer: (a,b) => a.id.localeCompare(b.id)
});

export interface State extends EntityState<TokenDto> {
  selectedTokenId?: string;
  isLoading?: boolean;
  error?: any;
  tenantKey?: string;
  tenantName?: string;
  isPatient?: boolean;
  saveSession?: boolean;
  parsedToken?: JwtBody;
  username?: string;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedTokenId: null,
  isLoading: false,
  error: null,
  tenantKey: null,
  tenantName: null,
  isPatient: null,
  saveSession: environment.production ? false : true,
  parsedToken: null,
  username: null,
});
