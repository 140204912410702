import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule, MatProgressBarModule, MatTabsModule } from "@angular/material";
import { MaterialModule } from "../../../../material.module";
import { FileViewerContainerModule } from "../../../file-viewer-container/file-viewer-container.module";
import { AudaxComponent } from "./audax.component";

@NgModule({
  declarations: [
    AudaxComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTabsModule,
    FileViewerContainerModule,
    MatIconModule,
    MaterialModule
  ],
  exports: [
    AudaxComponent
  ],
})
export class AudaxModule { }
