import { createAction, props } from "@ngrx/store";
import { PatientCephalometricStoreEntity } from './state';

export const LoadRequest = createAction(
  '[Patient Cephalometric] Load Request',
  props<{ patientCephalometricId?: number }>(),
);

export const LoadSuccess = createAction(
  '[Patient Cephalometric] Load Success',
  props<{ entries: PatientCephalometricStoreEntity[] | PatientCephalometricStoreEntity; }>()
);

export const LoadFailure = createAction(
  '[Patient Cephalometric] Load Failure',
  props<{ error: any; }>()
);

export const Remove = createAction(
  '[Patient Cephalometric] Remove Entry',
  props<{ patientCephalometricId: number }>()
);
