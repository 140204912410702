import { Action, createReducer, on } from "@ngrx/store";
import * as MarketingPackageStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(MarketingPackageStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedMarketingPackageId: null })),
  on(MarketingPackageStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.marketingPackages, { ...s, isLoading: false, error: null })
  }),
  on(MarketingPackageStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(MarketingPackageStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(MarketingPackageStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.marketingPackage, { ...state, isLoading: false, error: null })
  )),
  on(MarketingPackageStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(MarketingPackageStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedMarketingPackageId: null  })),
  on(MarketingPackageStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedMarketingPackageId: action.marketingPackage && action.marketingPackage.id })),
  on(MarketingPackageStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(MarketingPackageStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(MarketingPackageStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedMarketingPackageId: null })),
  on(MarketingPackageStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(MarketingPackageStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(MarketingPackageStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.marketingPackage, { ...state, isLoading: false, error: null })
  )),
  on(MarketingPackageStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(MarketingPackageStoreActions.DeleteRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedMarketingPackageId: state.selectedMarketingPackageId === action.id ? null : state.selectedMarketingPackageId })),
  on(MarketingPackageStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  ),
  on(MarketingPackageStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(MarketingPackageStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(MarketingPackageStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.marketingPackage, { ...state, isLoading: false, error: null })
  ),
  on(MarketingPackageStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
