import { createAction, props } from '@ngrx/store';
import { InternalUserStoreEntity } from './state';
import { FileParameter, PhotoDto } from '../../shared/services/api.service';

//LOAD PAGE
export const LoadRequest = createAction('[InternalUser] Load Request', props<{ page?: number; pageSize?: number }>());

export const LoadSuccess = createAction('[InternalUser] Load Success', props<{ users: InternalUserStoreEntity[] }>());

export const LoadFailure = createAction('[InternalUser] Load Failure', props<{ error: any }>());

//Fetch
export const FetchRequest = createAction('[InternalUser] Fetch Request', props<{ id: string }>());

export const FetchSuccess = createAction('[InternalUser] Fetch Success', props<{ user: InternalUserStoreEntity }>());

export const FetchFailure = createAction('[InternalUser] Fetch Failure', props<{ error: any }>());

//ADD PHOTO
export const AddPhotoRequest = createAction(
  '[InternalUser] Add Photo Request',
  props<{
    userId: string;
    file: FileParameter;
  }>()
);

export const AddPhotoSuccess = createAction('[InternalUser] Add Photo Success', props<{ photo: PhotoDto }>());

export const AddPhotoFailure = createAction('[InternalUser] Add Photo Failure', props<{ error: string }>());

//UPDATE PHOTO
export const UpdatePhotoRequest = createAction(
  '[InternalUser] Update Photo Request',
  props<{
    userId: string;
    photoId: number;
    file: FileParameter;
  }>()
);

export const UpdatePhotoSuccess = createAction('[InternalUser] Update Photo Success', props<{ photo: PhotoDto }>());

export const UpdatePhotoFailure = createAction('[InternalUser] Update Photo Failure', props<{ error: string }>());

//ADD
export const AddRequest = createAction('[InternalUser] Add Request', props<{ user: InternalUserStoreEntity }>());

export const AddSuccess = createAction('[InternalUser] Add Success', props<{ user: InternalUserStoreEntity }>());

export const AddFailure = createAction('[InternalUser] Add Failure', props<{ error: string }>());

//SELECT
export const SelectRequest = createAction('[InternalUser] Select Request', props<{ id: string }>());

export const SelectSuccess = createAction('[InternalUser] Select Success', props<{ user: InternalUserStoreEntity }>());

export const SelectFailure = createAction('[InternalUser] Select Failure', props<{ error: any }>());

//DESELECT
export const DeselectRequest = createAction('[InternalUser] Deselect Request');

export const DeselectSuccess = createAction('[InternalUser] Deselect Success');

export const DeselectFailure = createAction('[InternalUser] Deselect Failure', props<{ error: any }>());

//UTILITY
export const ResetError = createAction('[InternalUser] Reset Error');

//Tx Card Favorites
export const UpdateTxCardFavorite = createAction('[InternalUser] Update TxCardFavorite', props<{ cardSelector: string; isFavorite: boolean }>());
export const UpdateTxCardFavoriteSuccess = createAction('[InternalUser] Update TxCardFavorite Success', props<{ userId: string; favorites: string[]; }>());
export const UpdateTxCardFavoriteFailure = createAction('[InternalUser] Update TxCardFavorite Failure', props<{ error: any }>());
