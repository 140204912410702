import { createReducer, on, Action } from '@ngrx/store';
import { initialState, State, featureAdapter } from './state';
import * as CommuncationDeskActions from './actions';

const reducer = createReducer(
  initialState,
  on(CommuncationDeskActions.LoadDesksRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(CommuncationDeskActions.LoadDesksSuccess, (state, action) => featureAdapter.upsertMany(action.desks, { ...state, isLoading: false })),
  on(CommuncationDeskActions.LoadDesksFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
