import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import * as ProvidersStoreActions from './actions';
import { State } from '../root-state';
import { ProviderClient } from 'src/app/shared/services/api.service';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class ProvidersStoreEffects {
  constructor(private store$: Store<State>, private actions$: Actions, private _providerClient: ProviderClient) {}

  unloadOnLogoutSuccessEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AuthStoreActions.LogoutSuccess),
    mapTo(ProvidersStoreActions.Unload())
  )

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ProvidersStoreActions.LoadRequest),
    withLatestFrom(this.store$.select(state => state.Providers)),
    switchMap(([action, state]) =>
      this._providerClient
        .provider_GetProviders('', action.locationId, action.pageSize || state.pageSize, action.page || state.page)
        .pipe(map((result) => ProvidersStoreActions.LoadSuccess({ providers: result })))
    )
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ProvidersStoreActions.LoadOneRequest),
    switchMap((action) =>
      this._providerClient.provider_GetProvider(action.id, '').pipe(map((result) => ProvidersStoreActions.LoadOneSuccess({ provider: result })))
    )
  );
}
