import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, forkJoin, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap, startWith } from 'rxjs/operators';
import {
  SearchClient,
  DentistClient,
  ClinicClient,
  DentistDto2,
  ClinicDto

} from 'src/app/shared/services/api.service';

import * as _ from 'lodash';
import { DentistClientService } from '../../../shared/services/client/dentist-client.service';
import { FetchAllParameter } from '../../../shared/services/client/client.model';
import { ClinicClientService } from 'src/app/shared/services/client/clinic-client.service';
import { StationaryStoreSelectors } from '@root-store';


@Component({
  selector: 'app-dentist-clinic-search',
  templateUrl: './dentist-clinic-search.component.html',
  styleUrls: ['./dentist-clinic-search.component.css']
})
export class DentistClinicSearchComponent implements OnInit {

  @ViewChild('patientsearchinput', { static: false }) patientsearchinput: ElementRef;
  @ViewChild('patientListTrigger', { static: false }) patientListTrigger: MatMenuTrigger;

  @Input() otherView:boolean = false;
  @Input() initSearch: boolean;
  @Input() isVerifiedClinic: boolean;
  @Output() getSerarchValue = new EventEmitter();
  @Output() selectClinic = new EventEmitter();

  filters: any[] = [];

  dentistClinicForm: FormGroup;
  isDentist: boolean = false;
  noResult: boolean = false;

  dentistArr:any[] = [];
  clinicsArr:any[] = [];
  selectedSearch: any = "Search...";
  isWorking: boolean = false;
  favoritesOnly:boolean = true;
  selectedTabName:string = 'fav';
  noClinicResult:boolean = false;
  dentistLoaded:boolean = false;
  clinicLoaded:boolean = false;
  filterValue: any = "";
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  defaultDentists: any[] = [];
  defaultClinics: any[] = []
  pageNumber:number = 0
  professionalGroupId: any = null;

  showDentistShowMore:boolean = false;
  showClinicShowMore:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _searchClient: SearchClient,
    private _dentistClient: DentistClient,
    private _clinicClient: ClinicClient,
    private _dentistClientService: DentistClientService,
    private _clinicClientService: ClinicClientService,
  ) { }

  ngOnInit() {
    this.dentistClinicForm = this.formBuilder.group({
      filter: ['']
    });

    this.dentistClinicForm.patchValue({
      filter: 'All'
    });

    this.dentistClinicForm.controls['filter'].valueChanges.subscribe(filter => {
      if(this.filterValue.trim().length > 0){
        this.filterDentistOrClinic(this.filterValue, filter);
      } else {
        let _filter = this.filters.find(filterItem =>  filterItem.name == filter);
        this.pageNumber = 0;
        this.getDefulatDentistData(_filter.id)
      }
    })

    this.getDentistOptions();
    this.getClinicData();
  }

  ngOnChanges(){
    if(this.initSearch == true){
      this.dentistClinicForm.patchValue({
        filter: 'Dentist'
      });

      this.selectedSearch = "Search...";
      this.isDentist = true;
    }
  }

  selectSerchType(){
    this.isDentist = this.isDentist == true ? false : true;
    this.noResult = false;
    this.patientsearchinput.nativeElement.value = '';

    if(this.favoritesOnly){
      if(this.isDentist){
        this.getDentistsData();
      } else {
        this.getClinicData()
      }
    } else {
      if(this.isDentist){
        let grpFilter = this.dentistClinicForm.value.filter
        let _filter = this.filters.find(filterItem =>  filterItem.name == grpFilter);
        this.pageNumber = 0;
        this.getDefulatDentistData(_filter.id);
      } else {
        this.pageNumber = 0;
        this.getDefualtClinicData();
      }
    }
  }

  searchDentist() {
    if (this.isWorking) return;
    setTimeout(() => {
      if (!this.otherView && this.patientsearchinput) {
        fromEvent(this.patientsearchinput.nativeElement, "keyup").pipe(
          debounceTime(500),
          filter((e: KeyboardEvent) => e.keyCode !== 40),
          filter((e: KeyboardEvent) => e.keyCode !== 38),
          map((userInput: any) => userInput.target.value))
          .subscribe(res => {

            let searchkeyword: any = res;
            this.dentistArr = [];
            this.clinicsArr = [];
            this.noResult = false;
            this.noClinicResult = false;

            this.filterValue = searchkeyword;
            
            let grpFilter = this.dentistClinicForm.value.filter

            if(searchkeyword.trim().length > 0){
              this.filterDentistOrClinic(searchkeyword, grpFilter);
            } else {
              if(this.isDentist){
                let _filter = this.filters.find(filterItem =>  filterItem.name == grpFilter);
                this.pageNumber = 0;
                this.getDefulatDentistData(_filter.id)
              } else {
                this.pageNumber = 0;
                this.getDefualtClinicData();
              }
             
            }            
          });
      }
    });
  }

  filterDentistOrClinic(searchkeyword, filter){
    this.isWorking = true;
    if (this.isDentist == true) {
      this.dentistArr = [];
      this.showDentistShowMore = false;
      this.getAllDentist(searchkeyword).then(
        (resp) => {
          this.isWorking = false;
          resp.map(dentist => {
            if (filter == 'All') {
              this.dentistArr.push(dentist)
            } else {
              let _prGrp: any[] = dentist.professionGroups.filter(prGrp => prGrp.name === filter);

              if (_prGrp.length > 0) {
                this.dentistArr.push(dentist)
              }
            }
          });

          if (this.dentistArr.length > 0) {
            this.noResult = false;
            this.dentistArr = _.orderBy(this.dentistArr, (d => d.displayName.toLowerCase()));
          } else {
            this.noResult = true;
          }
        }
      )
    } else {
      this.showClinicShowMore = false;
      this.getAllClinics(searchkeyword).then(
        (resp) => {
          this.isWorking = false;
          this.clinicsArr = resp;
          if (this.clinicsArr.length > 0) {
            this.noClinicResult = false;
            this.clinicsArr = _.orderBy(this.clinicsArr, (c => c.name.toLowerCase()));
          } else {
            this.noClinicResult = true;
          }
        }
      )
    }
  }

  private getAllDentist(search: string): Promise<DentistDto2[]> {
    let params: FetchAllParameter[] = [
      { value: search },
      { value: true},
      { value: this.favoritesOnly },
      { value: 20 },
      { isPage: true },
    ];

    return new Promise(resolve => this._dentistClientService.fetchAll(
      this._dentistClient.dentist_GetDentists,
      params,
      DentistDto2
    ).subscribe(resp => {
      resolve(resp);
    }));
  }

  private getAllClinics(search?:string): Promise<ClinicDto[]> {
    let params: FetchAllParameter[] = [
      { value: search },
      { value: true },
      { value: this.favoritesOnly },
      { value: 20 },
      { isPage: true },
    ];

    return new Promise(resolve => this._clinicClientService.fetchAll(
      this._clinicClient.clinic_GetClinics,
      params,
      ClinicDto
    ).subscribe(resp => {
      resolve(resp);
    }));
  }

  onSelectionDentist(dentist, clinic) {
    this.selectedSearch = dentist.displayName;
    if(!this.favoritesOnly){
      this.patientsearchinput.nativeElement.value = '';
    }
    
    this.getSerarchValue.emit({"dentist": dentist, "clinic": clinic, "isDentist": this.isDentist});
    this.patientListTrigger.closeMenu();
  }

  getDentistOptions(){
    this._dentistClient.dentist_GetOptions(null).subscribe(
      (resp) => {
        this.filters = [{ id: '', name: 'All', shortName: 'All'}, ...resp.professionalGroups]
      },
    )
  }

  otherViewSearch(evt){
    this.getSerarchValue.emit({"dentist": evt.dentist, "clinic": evt.clinic});
    this.patientListTrigger.closeMenu();
  }

  otherViewSelectedClinic(clinic): void {
    this.selectClinic.emit(clinic);
    this.patientListTrigger.closeMenu();
  }


  togglefavoritesOnly(_favoritesOnly, tabname){
    this.favoritesOnly = _favoritesOnly;
    this.patientsearchinput.nativeElement.value = '';

    if(this.selectedTabName != tabname){
      if(this.favoritesOnly){
        if(this.isDentist){
          this.getDentistsData();
        } else {
          this.getClinicData()
        }
      } else {
        if(this.isDentist){
          this.pageNumber = 0;
          this.getDefulatDentistData(null);
        } else { 
          this.pageNumber = 0;
          this.getDefualtClinicData()
        }
      }
    }
    
    this.selectedTabName = tabname;
  }

  getDentistsData(){
    this.isWorking = true;
    this.dentistArr = [];
    
    forkJoin({dentists: this.getAllDentist(null)}).subscribe(({dentists}) => {
      this.isWorking = false;
      let filter:any;
      if(this.favoritesOnly){
        filter = 'All';
      } else {
        filter = this.dentistClinicForm.value.filter;
      }
      
      dentists.map(dentist => {
        if (filter == 'All') {
          this.dentistArr.push(dentist)
        } else {
          let _prGrp: any[] = dentist.professionGroups.filter(prGrp => prGrp.name === filter);

          if (_prGrp.length > 0) {
            this.dentistArr.push(dentist)
          }
        }
      });

      if (this.dentistArr.length > 0) {
        this.noResult = false;
        this.dentistArr = _.orderBy(this.dentistArr, (d => d.displayName.toLowerCase()));
      } else {
        this.noResult = true;
      }

      this.dentistLoaded = true;
    },
    err => {
      this.isWorking = false;
    })
  }

  getClinicData(){
    this.isWorking = true;
    this.clinicsArr = [];

    forkJoin({
      clinics: this.getAllClinics(null)
    }).subscribe(({ clinics }) => {

      this.isWorking = false;
      this.clinicsArr = clinics;

      if (this.clinicsArr.length > 0) {
        this.noClinicResult = false;
        this.clinicsArr = _.orderBy(this.clinicsArr, (c => c.name.toLowerCase()));
      } else {
        this.noClinicResult = true;
      }

      this.clinicLoaded = true;
    },
    (err) => {
      this.isWorking = false;
    })
  }

  getDefulatDentistData(professionalGroupId){
    this.isWorking = true;
    this.dentistArr = [];
    this.noResult = false;
    this.professionalGroupId = professionalGroupId;
    this._dentistClient.dentist_GetDentists(null, true, false, 20, this.pageNumber, professionalGroupId, 'displayName', true).subscribe(resp => {
      this.isWorking = false;
      this.dentistArr = resp;
      if(resp.length < 20){
        this.showDentistShowMore = false;
      } else {
        this.showDentistShowMore = true;
      }

      this.noResult = this.dentistArr.length > 0 ? false : true;      
    })
  }

  getDefualtClinicData() {
    this.isWorking = true;
    this.clinicsArr = [];
    this._clinicClient.clinic_GetClinics(null, true, false, 20, this.pageNumber).subscribe(resp => {
      this.isWorking = false;
      this.clinicsArr = _.orderBy(resp, (c => c.name.toLowerCase()));
      if(resp.length < 20){
        this.showClinicShowMore = false;
      } else {
        this.showClinicShowMore = true;
      }

      this.noClinicResult = this.clinicsArr.length > 0 ? false : true;
    });
  }

  showMore(){
    if(this.isDentist){
      if(this.showDentistShowMore){
        this.isWorking = true;
        this.pageNumber++;
        this._dentistClient.dentist_GetDentists(null, true, false, 20, this.pageNumber, this.professionalGroupId, 'displayName', true).subscribe(resp => {
          this.isWorking = false;
          if(resp.length < 20){
            this.showDentistShowMore = false;
          } else {
            this.showDentistShowMore = true;
          }
          this.dentistArr = _.concat(this.dentistArr, resp);
        })
      }
    } else {
      if(this.showClinicShowMore){
        this.isWorking = true;
        this.pageNumber++;
        this._clinicClient.clinic_GetClinics(null, true, false, 20, this.pageNumber).subscribe(resp => {
          this.isWorking = false;
          if(resp.length < 20){
            this.showClinicShowMore = false;
          } else {
            this.showClinicShowMore = true;
          }
          this.clinicsArr = _.concat(this.clinicsArr, _.orderBy(resp, (c => c.name.toLowerCase())));

        })
      }
    }
  }
}
