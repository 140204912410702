import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';
import { EvolutionColors } from 'src/app/shared/enums';
import { CardModule } from 'src/app/shared/models';
import { ClickConfirmModule } from '../../click-confirm/click-confirm.module';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { PatientRelationshipsBackComponent } from './patient-relationships-back/patient-relationships-back.component';
import { PatientRelationshipsFrontComponent } from './patient-relationships-front/patient-relationships-front.component';
import { WarningDialogComponent } from './patient-relationships-back/warning-dialog/warning-dialog.component';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { UserPermissionsModule } from '@shared/user-permissions/user-permissions.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

@CardModule({
  back: PatientRelationshipsBackComponent,
  front: PatientRelationshipsFrontComponent,
  height: DEFAULT_CARD_HEIGHT,
  selector: 'patient-relationships',
  width: 460,
  header: 'Relationships',
  order: 80,
  color: EvolutionColors.Blue,
  actions: [{iconName: 'faUsers', actionName: '', isFaIcon: true}]
})
@NgModule({
  declarations: [PatientRelationshipsFrontComponent, PatientRelationshipsBackComponent, WarningDialogComponent],
  imports: [
    ClickConfirmModule,
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatCardModule,
    MatDialogModule,
    MatAutocompleteModule,
    TextMaskModule,
    MatCheckboxModule,
    DragDropModule,
    MomentDateModule,
    UserPermissionsModule,
    PerfectScrollbarModule,
    FontAwesomeModule
  ],
  entryComponents: [
    PatientRelationshipsFrontComponent,
    PatientRelationshipsBackComponent,
    WarningDialogComponent
  ],
})
export class PatientRelationshipsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
