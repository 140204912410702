import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { IScheduleGridOnDeckDto, ScheduleGridOnDeckDto } from 'src/app/shared/services/api.service';
import * as moment from 'moment-timezone';
import { ExtensionMethod } from 'src/app/shared/extension-helper';

export const featureAdapter: EntityAdapter<OnDeckStoreEntity> = createEntityAdapter<OnDeckStoreEntity>({
  selectId: chair => chair.appointmentId,
  sortComparer: (a: OnDeckStoreEntity, b: OnDeckStoreEntity): number => a.arrivedWhen.valueOf() - b.arrivedWhen.valueOf()
});

export interface State extends EntityState<OnDeckStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});

export class OnDeckStoreEntity extends ScheduleGridOnDeckDto {
  constructor(data?: IScheduleGridOnDeckDto) {
    super(data);
  }
}
