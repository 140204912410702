import { Action, createReducer, on } from "@ngrx/store";
import * as FormTypeStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(FormTypeStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedFormTypeId: null })),
  on(FormTypeStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.formTypes, { ...s, isLoading: false, error: null })
  }),
  on(FormTypeStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(FormTypeStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FormTypeStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.formType, { ...state, isLoading: false, error: null })
  )),
  on(FormTypeStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(FormTypeStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedFormTypeId: null  })),
  on(FormTypeStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedFormTypeId: action.formType && action.formType.id })),
  on(FormTypeStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(FormTypeStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(FormTypeStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedFormTypeId: null })),
  on(FormTypeStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(FormTypeStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FormTypeStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.formType, { ...state, isLoading: false, error: null })
  )),
  on(FormTypeStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(FormTypeStoreActions.DeleteRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedFormTypeId: state.selectedFormTypeId === action.id ? null : state.selectedFormTypeId })),
  on(FormTypeStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  ),
  on(FormTypeStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(FormTypeStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FormTypeStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.formType, { ...state, isLoading: false, error: null })
  ),
  on(FormTypeStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
