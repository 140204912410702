import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_MINI_CARDS_FEATURE_KEY } from './featurekey';
import { featureAdapter, State, PatientMiniCardsStoreEntity } from './state';

export const selectPatientMiniCardsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_MINI_CARDS_FEATURE_KEY);

const { selectAll } = featureAdapter.getSelectors(selectPatientMiniCardsState);
export const selectAllPatientMiniCards = selectAll;

export const getSelectedPatientMiniCards =
  createSelector(selectAllPatientMiniCards, (all: PatientMiniCardsStoreEntity[]) =>
    all ? all[0] : null
  );

export const selectPatientMiniCardsError: MemoizedSelector<object, any> = createSelector(selectPatientMiniCardsState, (state) => state.error);

export const selectPatientMiniCardsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientMiniCardsState, (state: State) => state.isLoading);
