import { Action, createReducer, on } from "@ngrx/store";
import * as PostingCodeStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PostingCodeStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPostingCodeId: null })),
  on(PostingCodeStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.upsertMany(action.postingCodeList, { ...s, isLoading: false, error: null })
  }),
  on(PostingCodeStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UTILITY
  on(PostingCodeStoreActions.ResetError, (state) => ({ ...state, error: null })),
  //LOAD ONE
  on(PostingCodeStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PostingCodeStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.postingCode, { ...state, isLoading: false, error: null })
  )),
  on(PostingCodeStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //DELETE
  on(PostingCodeStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PostingCodeStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  )),
  on(PostingCodeStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
