import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { ProcedureGroupDto, IProcedureGroupDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<ProcedureGroupStoreEntity> = createEntityAdapter<ProcedureGroupStoreEntity>({
  selectId: procedureGroup => procedureGroup.id,
  sortComparer: (a: ProcedureGroupStoreEntity, b: ProcedureGroupStoreEntity): number => b.id - a.id
});

export interface State extends EntityState<ProcedureGroupStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedProcedureGroupId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedProcedureGroupId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class ProcedureGroupStoreEntity extends ProcedureGroupDto {
  constructor(data?: IProcedureGroupDto) {
    super(data);
  }
}
