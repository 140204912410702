import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TENANT_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const selectTenantsState: MemoizedSelector<object, State> = createFeatureSelector<State>(TENANT_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectTenantsState);
export const selectAllTenants = selectAll;
export const selectAllTenantsEntities = selectEntities;

export const selectTenantsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectTenantsState, (state: State) => state.isLoading);
