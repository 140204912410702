import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as ModelStoreActions from './actions';
import * as ModelStoreSelectors from './selectors';
import { PatientClient, StorageContentTypeEnum } from 'src/app/shared/services/api.service';
import { PatientsStoreActions } from '../patient-store';

@Injectable({ providedIn: 'root' })
export class ModelStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ModelStoreActions.LoadRequest),
    switchMap((action) =>
      this._patientClient
        .patient_GetImaging(
          action.patientId,
          StorageContentTypeEnum.Model)
        .pipe(
          map((result) => ModelStoreActions.LoadSuccess({ models: result })),
          catchError((err: HttpErrorResponse) => of(ModelStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ModelStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(
          ModelStoreSelectors
            .selectModelById(action.id))
        .pipe(map((result) => ModelStoreActions.SelectSuccess({ model: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ModelStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(ModelStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ModelStoreActions.AddRequest),
    switchMap((action) =>
      this._patientClient.patient_PostImage(
        action.model.patientId,
        action.model.file,
        StorageContentTypeEnum.Model,
        action.model.report)
        .pipe(
          map((result) => ModelStoreActions.AddSuccess({ model: result })),
          catchError((err: HttpErrorResponse) => of(ModelStoreActions.AddFailure({ error: err.message })))
        ))
  );
}
