import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from '../../../shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule  } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotepadCardComponent } from './notepad.component';
import { ElementsModule } from '../../../elements/elements.module';
import { MatProgressBarModule, MatButtonModule, MatIconModule, MatSelectModule, MatSnackBarModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@CardModule({
  selector: 'notepad',
  front: NotepadCardComponent,
  back: null,
  header: 'Notepad',
  width: 450,
  height: DEFAULT_CARD_HEIGHT,
  order: 130,
  actions: [{iconName: 'faStickyNote', actionName: '', isFaIcon: true}],
})
@NgModule({
  entryComponents: [NotepadCardComponent], //IMPORTANT
  declarations: [NotepadCardComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    ElementsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    FontAwesomeModule
  ],
})
export class NotepadModule {}
