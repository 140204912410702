import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { UserTypeEnum } from '../../shared/services/api.service';
import { USER_FEATURE_KEY } from './featurekey';
import { featureAdapter, UserStoreEntity, State } from './state';

export const selectUsersState: MemoizedSelector<object, State> = createFeatureSelector<State>(USER_FEATURE_KEY);

export const getSelectedUserId = createSelector(selectUsersState, (state) => state.selectedUserId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectUsersState);
export const selectUserIds = selectIds;
export const selectAllUsers = selectAll;
export const selectAllUserEntities = selectEntities;

export const getSelectedUser = createSelector(selectUsersState, selectAllUsers, (state, allUsers: UserStoreEntity[]) =>
  allUsers ? allUsers.find((rp) => rp.id === state.selectedUserId) : null
);

export const selectUserById = (id: string) =>
  createSelector(selectAllUsers, (allUsers: UserStoreEntity[]) => (allUsers ? allUsers.find((rp) => rp.id === id) : null));

export const selectUsersError: MemoizedSelector<object, any> = createSelector(selectUsersState, (state) => state.error);

export const selectUsersIsLoading: MemoizedSelector<object, boolean> = createSelector(selectUsersState, (state: State) => state.isLoading);

export const selectTxCardIsFavorite = (userId: string, cardSelector: string) =>
  createSelector(selectUserById(userId), (user: UserStoreEntity) => user && user.txCardFavorites && user.txCardFavorites.some((c) => c == cardSelector));

export const selectInternalUsers = createSelector(selectAllUsers, (allUsers: UserStoreEntity[]) => {
  return allUsers && allUsers.filter((c) => c.isActive === true && c.userType == UserTypeEnum.Internal);
});

export const selectTaskCount: MemoizedSelector<object, number> = createSelector(selectUsersState, (state) => state.taskCount);
