import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Subject, fromEvent } from 'rxjs';
import { take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { PatientCephalometricItemTypeEnum, PatientCephalometricOrderItemDto, PatientCephalometricOrderStatusEnum, PatientClient } from '../../../../shared/services/api.service';
import { PatientCephalometricOrderStoreEntity } from 'src/app/root-store/patient-cephalometric-order-store';
import { PatientStoreEntity } from '../../../../root-store';
import * as moment from 'moment';
import { DownloadService, IDownloadFile } from '../../../../shared/services/download/download.service';

@Component({
  selector: 'audax',
  templateUrl: './audax.component.html',
  styleUrls: ['./audax.component.css']
})
export class AudaxComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  input: AudaxComponentInput;

  cephalometricItems: PatientCephalometricOrderItemDto[];

  calibrationUrl: SafeResourceUrl;
  editAnalysisUrl: SafeResourceUrl;

  patientCephalometricStatusEnum = PatientCephalometricOrderStatusEnum;
  isWorking: boolean = true;
  isDownloading: boolean = false;
  isOrderable: boolean = false;
  isRecalibration: boolean = false;
  patientAge: string;
  today: Date = new Date;

  constructor(
    private _dialogRef: MatDialogRef<AudaxComponent>,
    private _snackbar: MatSnackBar,
    private _patientClient: PatientClient,
    @Inject(MAT_DIALOG_DATA) public data: AudaxComponentInput,
    protected _sanitizer: DomSanitizer,
    private _downloadService: DownloadService
  ) {
    this.input = data;

    fromEvent(window, 'message')
      .pipe(
          takeUntil(this._destroy$),  // don`t forget to cleanup!
          throttleTime(150),
          tap((e: MessageEvent)=> this.onAudaxEvent(e))
      ).subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this.initialize();
  }

  initialize(): void {
    switch (this.input.CephalometricOrder.status) {
      case PatientCephalometricOrderStatusEnum.Created: {
        break;
      }
      case PatientCephalometricOrderStatusEnum.Sent: {
        this.calibrationUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.input.CephalometricOrder.calibrationUrl);
        break;
      }
      case PatientCephalometricOrderStatusEnum.Ordered: {
        this.orderSent();
        break;
      }
      case PatientCephalometricOrderStatusEnum.Processing: {
        this.audaxProcessing();
        break;
      }
      case PatientCephalometricOrderStatusEnum.Error: {
        this.audaxError();
        break;
      }
      case PatientCephalometricOrderStatusEnum.Complete: {
        this.editAnalysisUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.input.CephalometricOrder.editAnalysisUrl);
        this.initializeComplete();
        break;
      }
      default: {
        break;
      }
    }

    this.isWorking = false;
  }

  initializeComplete(): void {
    this._patientClient.patient_GetPatientCephalometricOrderItems(
      this.input.Patient.id,
      this.input.CephalometricOrder.patientCephalometricId,
      this.input.CephalometricOrder.id
    )
      .pipe(
        take(1)
    )
      .subscribe((result) => {
        this.cephalometricItems = result.filter((x) => {
          return x.type != 'Unknown';
        });
        this.isWorking = false;
      });

    if (this.input.Patient && this.input.Patient.dob) {
      let duration = moment.duration(moment(this.today).diff(moment(this.input.Patient.dob)));
      this.patientAge = `${duration.years()}y ${duration.months()}m`;
    }
  }

  onAudaxEvent(message: MessageEvent) {
    console.log(message.data);

    if (message.data.id == 'calibrationMessageClose') {
      this.order();
    }

    if (message.data.id == 'editAnalysisMessageSaveOK') {
      this.recalibrateOrder();
    }

    if (message.data.id == 'editAnalysisMessageClose'
      || message.data.id == 'editAnalysisMessageCancel') {
      this.isRecalibration = false;
    }
  }

  close(): void {
    this._dialogRef.close();
  }

  orderSent(): void {
    setTimeout(() => {
      this.openSnackBar("An order has been sent, please try again later.", 'Ok');
      this.close();
    });
  }

  audaxProcessing(): void {
    setTimeout(() => {
      this.openSnackBar("Audax processing, please try again later.", 'Ok');
      this.close();
    });
  }

  audaxError(): void {
    setTimeout(() => {
      this.openSnackBar("Audax error.", 'Ok');
      this.close();
    });
  }

  order(): void {
    this.isWorking = true;

    this._patientClient.patient_PostSendPatientCephalometricOrder(
      this.input.Patient.id,
      this.input.CephalometricOrder.patientCephalometricId,
      this.input.CephalometricOrder.id
    )
      .pipe(
        take(1)
      )
      .subscribe(() => {
        this.isWorking = false;

        this.openSnackBar("Audax order sent.", 'Ok');
        this.close();
      });
  }

  recalibrateOrder(): void {
    this.isWorking = true;

    this._patientClient.patient_PostRecalibratePatientCephalometricOrder(
      this.input.Patient.id,
      this.input.CephalometricOrder.patientCephalometricId,
      this.input.CephalometricOrder.id
    )
      .pipe(
        take(1)
      )
      .subscribe(() => {
        this.isWorking = false;

        this.openSnackBar("Audax recalibration order sent.", 'Ok');
        this.close();
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  reCalibrate(): void {
    this.isRecalibration = true;
    this.initialize();
  }

  downloadAsZip(): void {
    let downloadFiles: IDownloadFile[] = this.cephalometricItems.map((x) => {
      return {
        url: x.locationUrl,
        filename: `${x.type}.${this.getFileExtension(x.type)}`
      };
    });

    if (downloadFiles && !downloadFiles.length) return;
    this.isDownloading = true;

    this._downloadService.downloadFilesAsZip(downloadFiles, "Cephalometrics-Audax").then((resp) => {
      if (resp) {
        this.openSnackBar('Cephalometrics has been successfully downloaded', 'Ok');
      }
      this.isDownloading = false;
    });
  }

  private getFileExtension(type: PatientCephalometricItemTypeEnum): string {
    switch (type) {
      case PatientCephalometricItemTypeEnum.TracePreviewImage:
      case PatientCephalometricItemTypeEnum.VectorTracingImage:
      case PatientCephalometricItemTypeEnum.MTable:
        return 'jpg';
      case PatientCephalometricItemTypeEnum.PDFReport:
        return 'pdf';
      case PatientCephalometricItemTypeEnum.PNGReport:
        return 'png';
      default:
        return 'png';
    }
  }
}

export class AudaxComponentInput {
  Patient: PatientStoreEntity;
  CephalometricOrder: PatientCephalometricOrderStoreEntity;
}
