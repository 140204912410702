import { Action, createReducer, on } from "@ngrx/store";
import * as TemplateStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(TemplateStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(TemplateStoreActions.LoadSuccess, (state, action) =>
    Array.isArray(action.templates)
      ? featureAdapter.upsertMany(action.templates, featureAdapter.removeAll({ ...state, isLoading: false }))
      : featureAdapter.upsertOne(action.templates, { ...state, isLoading: false })
  ),
  on(TemplateStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(TemplateStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  ),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
