import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter, PatientStoreEntity } from "./state";
import * as PatientsStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PatientsStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize })),
  on(PatientsStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patients, { ...s, isLoading: false, error: null })
  }),
  on(PatientsStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(PatientsStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.patient, { ...state, isLoading: false, error: null, txCardAlerts: true })
  )),
  on(PatientsStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(PatientsStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.patient, { ...state, isLoading: false, error: null })
  )),
  on(PatientsStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(PatientsStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  )),
  on(PatientsStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(PatientsStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPatient: null, selectedPatientId: null  })),
  on(PatientsStoreActions.SelectSuccess, (state, action) => {
    const s = featureAdapter.removeMany(x => x.id != action.id, state);
    return featureAdapter.upsertOne(action.patient, { ...s, isLoading: false, error: null, selectedPatient: null, selectedPatientId: action.patient.id, txCardAlerts: true });
  }),
  on(PatientsStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null  })),
  on(PatientsStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedPatient: null, selectedPatientId: null })),
  on(PatientsStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(PatientsStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.UpdateSuccess, (state, action) => UpdatePatient(state,action)),
  on(PatientsStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //REFERRAL ASSIGN
  on(PatientsStoreActions.AssignPatientReferredByRequest, (state) => ({ ...state, isLoading: true, error: null })),
  // on(PatientsStoreActions.AssignPatientReferredBySuccess, (state, action) => PatchSelectedPatient(state, { id: action.referral.patientId, referringPatientId: action.patientId })),
  on(PatientsStoreActions.AssignPatientReferredBySuccess, (state, action) => ({...state, isLoading: false })),
  on(PatientsStoreActions.AssignPatientReferredByFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD REFERRING CONTACT
  on(PatientsStoreActions.AddReferringContactRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.AddReferringContactSuccess, (state, action) => ({ ...state, isLoading: false })),
  on(PatientsStoreActions.AddReferringContactFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //REFERRAL REMOVE
  on(PatientsStoreActions.RemoveReferringContactRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientsStoreActions.RemoveReferringContactSuccess, (state, action) => PatchSelectedPatient(state, { id: action.patientId })),
  on(PatientsStoreActions.RemoveReferringContactFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE PROFILE
  on(PatientsStoreActions.UpdateProfileRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(PatientsStoreActions.UpdateProfileSuccess, (state, action) => UpdatePatientProfile(state, action)),
  on(PatientsStoreActions.UpdateProfileFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //DELETE PROFILE
  on(PatientsStoreActions.DeleteProfileRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(PatientsStoreActions.DeleteProfileSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(PatientsStoreActions.DeleteProfileFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(PatientsStoreActions.UpdatePatientStatusId, (state, action) =>
    featureAdapter.updateOne({id: action.patientId, changes: { patientStatusId: action.patientStatusId }}, {...state})
  ),
  //History
  on(PatientsStoreActions.ClearHistory, (state, action) => ClearSearchHistory(state)),
  on(PatientsStoreActions.SetTxCardAlerts, (state, action) => ({
    ...state,
    txCardAlerts: action.alert
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

function ClearSearchHistory(state: State): State {
  localStorage.removeItem('pinnedSearchHistory');
  return {
    ...state,
    isLoading: false,
    history: []
  }
}

function UpdatePatient(state: State, action): State {
  let newState: State = { ...state, isLoading: false, error: null };

  if(state.selectedPatient && state.selectedPatient.id == action.patient.id) //if patient is the currently selected one
    newState.selectedPatient = action.patient;

  if(state.entities[action.patient.id]) //if patient loaded in entity adapter
    return featureAdapter.updateOne({id: action.patient.id, changes: action.patient}, newState);

  return newState; //return updated state without updating entity adapter
}

function PatchSelectedPatient(state: State, patch: Partial<PatientStoreEntity>): State {
  if(state.selectedPatient && state.selectedPatient.id == patch.id)
    return {...state, selectedPatient: new PatientStoreEntity({...state.selectedPatient, ...patch})}
  else return state;
}

function UpdatePatientProfile(state: State, action): State {
  let newState: State = { ...state, isLoading: false, error: null };

  let patient = state.entities[action.patient.id];
  if (patient) {
    patient.sourceUrl = action.patient.sourceUrl;
    patient.profileUrl = action.patient.profileUrl;
    patient.profileThumbnailUrl = action.patient.profileThumbnailUrl;
    patient.profileContentType = action.patient.profileContentType;
    return featureAdapter.upsertOne(patient, newState);
  }
  else
    return featureAdapter.upsertOne(action.patient, newState);
}
