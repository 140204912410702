import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { RESPONSIBLEPARTY_FEATURE_KEY } from './featurekey';
import { featureAdapter, ResponsiblePartyStoreEntity, State } from './state';

export const selectResponsiblePartiesState: MemoizedSelector<object, State> = createFeatureSelector<State>(RESPONSIBLEPARTY_FEATURE_KEY);

export const getSelectedResponsiblePartyId = createSelector(selectResponsiblePartiesState, (state) => state.selectedResponsiblePartyId);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectResponsiblePartiesState);
export const selectResponsiblePartyIds = selectIds;
export const selectResponsiblePartyTotal = selectTotal;
export const selectAllResponsibleParties = selectAll;
export const selectAllResponsiblePartyEntities = selectEntities;

export const getSelectedResponsibleParty = createSelector(
  selectResponsiblePartiesState,
  selectAllResponsibleParties,
  (state, allResponsibleParties: ResponsiblePartyStoreEntity[]) =>
    allResponsibleParties ? allResponsibleParties.find((rp) => rp.id === state.selectedResponsiblePartyId) : null
);

export const selectResponsiblePartyById = (id: number) =>
  createSelector(selectAllResponsibleParties, (allResponsibleParties: ResponsiblePartyStoreEntity[]) =>
    allResponsibleParties ? allResponsibleParties.find((rp) => rp.id === id) : null
  );

export const selectResponsiblePartysError: MemoizedSelector<object, any> = createSelector(selectResponsiblePartiesState, (state) => state.error);

export const selectResponsiblePartysIsLoading: MemoizedSelector<object, boolean> = createSelector(selectResponsiblePartiesState, (state: State) => state.isLoading);
