export interface JwtHeader {
  alg: string;
  typ: string;
}

export class JwtBody {
  jti: string;
  sub: string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string[];
  Locations: number[];
  Permissions: EvoPermissions;
  exp: number;
  iss: string;
  aud: string;

  constructor(data?: Partial<JwtBody>) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) this[property] = data[property];
      }
    }
  }
}

export const GLOBAL_PERMISSION = 'Global';
export const INTERNAL_USER_ROLE = 'internal';
export const FULL_ACCESS = 'FULL_ACCESS';
export const ACTIVE_LOCATION = 'ACTIVE_LOCATION';
export const ACTIVE_PATIENT_LOCATION = 'ACTIVE_PATIENT_LOCATION';

export type EvoPermission = string;
export type EvoPermissions = Record<string | number, EvoPermission[]> & { Global: EvoPermission[]; locationIds: number[] };
export type GLOBAL_PERMISSION_TYPE = typeof GLOBAL_PERMISSION;
export type ACTIVE_LOCATION_TYPE = typeof ACTIVE_LOCATION;
export type ACTIVE_PATIENT_LOCATION_TYPE = typeof ACTIVE_PATIENT_LOCATION;
export type PERMISSION_AREA = number | GLOBAL_PERMISSION_TYPE | ACTIVE_LOCATION_TYPE | ACTIVE_PATIENT_LOCATION_TYPE
