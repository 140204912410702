import { createReducer, on } from '@ngrx/store';
import { featureAdapter, initialState, State } from './state';
import * as PatientTreatmentStoreActions from './actions';

const reducer = createReducer(
  initialState,
  on(PatientTreatmentStoreActions.LoadTreatmentsRequest, (state, action) => featureAdapter.removeAll({ ...state, isLoading: true, locationId: action.locationId, patientId: action.patientId, isLoaded: false })),
  on(PatientTreatmentStoreActions.LoadTreatmentsSuccess, (state, action) => featureAdapter.upsertMany(action.plans, { ...state, isLoading: false, isLoaded: true })),
  on(PatientTreatmentStoreActions.LoadTreatmentsFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientTreatmentStoreActions.SelectTreatmentPlan, (state, action) => ({ ...state, selectedTreatmentPlanId: action.id})),
  //Comment
  on(PatientTreatmentStoreActions.UpdateTreatmentCommentRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientTreatmentStoreActions.UpdateTreatmentCommentSuccess, (state, action) => featureAdapter.upsertOne(action.plan, { ...state, isLoading: false})),
  on(PatientTreatmentStoreActions.UpdateTreatmentCommentFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //Steps
  on(PatientTreatmentStoreActions.AddStepsRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientTreatmentStoreActions.AddStepsSuccess, (state, action) => featureAdapter.upsertOne(action.plan, { ...state, isLoading: false})),
  on(PatientTreatmentStoreActions.AddStepsFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientTreatmentStoreActions.LocalUpdatePlan, (state,action) => featureAdapter.upsertOne(action.plan, {...state})),
  on(PatientTreatmentStoreActions.UpdateStepsRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientTreatmentStoreActions.UpdateStepsSuccess, (state, action) => featureAdapter.upsertOne(action.plan, { ...state, isLoading: false})),
  on(PatientTreatmentStoreActions.UpdateStepsFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientTreatmentStoreActions.DeleteStepRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientTreatmentStoreActions.DeleteStepSuccess, (state, action) => featureAdapter.upsertOne(action.plan, { ...state, isLoading: false})),
  on(PatientTreatmentStoreActions.DeleteStepFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State, action) {
  return reducer(state, action);
}
