import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-image-button',
  templateUrl: './image-button.component.html',
  styleUrls: ['./image-button.component.scss'],
})
export class ImageButtonComponent {
  @Input() type: string;
  @Input() color: string;
  @Input() disabled: boolean = false;
  @Input() tooltip:string;
}
