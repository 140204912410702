import { createAction, props } from "@ngrx/store";
import { CephalometricTypeStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Cephalometric Type] Load Request'
);

export const LoadSuccess = createAction(
  '[Cephalometric Type] Load Success',
  props<{ cephalometricTypes: CephalometricTypeStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Cephalometric Type] Load Failure',
  props<{ error: any; }>()
);
