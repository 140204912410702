import { createReducer, on, Action } from '@ngrx/store';
import { initialState, State, featureAdapter } from './state';
import * as OnDeckStoreActions from './actions';

const reducer = createReducer(
  initialState,
  on(OnDeckStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true })),
  on(OnDeckStoreActions.LoadSuccess, (state, action) =>
    Array.isArray(action.entries)
      ? featureAdapter.upsertMany(action.entries, featureAdapter.removeAll({ ...state, isLoading: false }))
      : featureAdapter.upsertOne(action.entries, { ...state, isLoading: false })
  ),
  on(OnDeckStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(OnDeckStoreActions.Remove, (state, action) => featureAdapter.removeOne(action.appointmentId, { ...state }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
