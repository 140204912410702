import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocationService {
    constructor(private http: HttpClient) { }

    getLocations() {
        const url = `${environment.apiUrl}/locations`;
        return this.http.get(url);
    }

    getLocation(id: number) {
      const url = `${environment.apiUrl}/locaitons/${id}`;
      return this.http.get(url);
    }
}
