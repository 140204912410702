import { createAction, props } from "@ngrx/store";
import { DentistStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Dentist] Load Request',
  props<{page?: number; pageSize?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Dentist] Load Success',
  props<{ dentists: DentistStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Dentist] Load Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Dentist] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Dentist] Select Success',
  props<{ dentist: DentistStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Dentist] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[Dentist] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Dentist] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Dentist] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Dentist] Reset Error'
);
