import { createAction, props } from '@ngrx/store';
import { VoiceHubEvent } from '../../shared/services/api.service';
import { ActiveVoiceStoreEntity } from './state';

//SELECT
export const SelectRequest = createAction('[Active Voice] Select Request', props<{ toExtension: string }>());

export const SelectSuccess = createAction('[Active Voice] Select Success', props<{ activeVoice: ActiveVoiceStoreEntity }>());

export const SelectFailure = createAction('[Active Voice] Select Failure', props<{ error: any }>());

//DESELECT
export const DeselectRequest = createAction('[Active Voice] Deselect Request');

export const DeselectSuccess = createAction('[Active Voice] Deselect Success');

export const DeselectFailure = createAction('[Active Voice] Deselect Failure', props<{ error: any }>());

export const LoadSuccess = createAction('[Active Voice] Load Success', props<{ voiceHubEvent: VoiceHubEvent }>());

export const RemoveSuccess = createAction('[Active Voice] Remove Success', props<{ toExtension: string }>());
