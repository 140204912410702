import { createAction, props } from "@ngrx/store";
import { AppointmentStoreEntity } from './state';
import { AppointmentDto, CreateAppointmentDto, IAppointmentHistoryDto, AppointmentHistoryDto, NoShowDto } from "../../shared/services/api.service";

//LOAD PAGE
export const LoadRequest = createAction(
  '[Appointment] Load Request',
  props<{ locationId?: number; patientId: number; page?: number; pageSize?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Appointment] Load Success',
  props<{ appointments: AppointmentStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Appointment] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[Appointment] Load One Request',
  props<{ locationId: number; patientId: number; id: number; }>()
);

export const LoadOneSuccess = createAction(
  '[Appointment] Load One Success',
  props<{ appointment: AppointmentStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[Appointment] Load One Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Appointment] Add Request',
  props<{ locationId: number; patientId: number; appointment: CreateAppointmentDto; }>()
);

export const AddSuccess = createAction(
  '[Appointment] Add Success',
  props<{ appointment: AppointmentStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Appointment] Delete Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[Appointment] Delete Request',
  props<{ locationId: number; patientId: number; id: number; }>()
);

export const DeleteSuccess = createAction(
  '[Appointment] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Appointment] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Appointment] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Appointment] Select Success',
  props<{ appointment: AppointmentStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Appointment] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[Appointment] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Appointment] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Appointment] Deselect Failure',
  props<{ error: any; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[Appointment] Update Request',
  props<{ locationId: number; patientId: number; id: number; appointment: AppointmentDto; }>()
);

export const UpdateSuccess = createAction(
  '[Appointment] Update Success',
  props<{ appointment: AppointmentStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[Appointment] Update Failure',
  props<{ error: any; }>()
);

//UPDATE NO SHOW
export const UpdateNoShowRequest = createAction(
  '[Appointment] Update No Show Request',
  props<{ patientId: number; appointmentId: number; noShow: NoShowDto }>()
);

export const UpdateNoShowSuccess = createAction(
  '[Appointment] Update No Show Success',
  props<{ appointment: AppointmentStoreEntity; }>()
);

export const UpdateNoShowFailure = createAction(
  '[Appointment] Update No Show Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Appointment] Reset Error'
);
