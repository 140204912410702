import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_IMAGING_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientImagingStoreEntity, State } from './state';

export const selectPatientImagingsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_IMAGING_FEATURE_KEY);

export const getSelectedPatientImagingId = createSelector(selectPatientImagingsState, (state) => state.selectedPatientImagingId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientImagingsState);
export const selectPatientImagingIds = selectIds;
export const selectAllPatientImagings = selectAll;
export const selectAllPatientImagingEntities = selectEntities;

export const getSelectedPatientImaging = createSelector(
  selectPatientImagingsState,
  selectAllPatientImagings,
  (state, allPatientImagings: PatientImagingStoreEntity[]) =>
    allPatientImagings ? allPatientImagings.find((rp) => rp.id === state.selectedPatientImagingId) : null
);

export const selectPatientImagingById = (id: number) =>
  createSelector(selectAllPatientImagings, (allPatientImagings: PatientImagingStoreEntity[]) =>
    allPatientImagings ? allPatientImagings.find((rp) => rp.id === id) : null
  );

export const selectPatientImagingsError: MemoizedSelector<object, any> = createSelector(selectPatientImagingsState, (state) => state.error);

export const selectPatientImagingsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientImagingsState, (state: State) => state.isLoading);
