import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PatientClient } from 'src/app/shared/services/api.service';
import { CephalometricTypeStoreEntity, CephalometricTypeStoreSelectors, RootStoreState } from '../../../../root-store';

@Component({
  selector: 'cephalometrics-order-dialog',
  templateUrl: './cephalometrics-order-dialog.component.html',
  styleUrls: ['./cephalometrics-order-dialog.component.scss'],
})
export class CephalometricsOrderDialogComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  cephalometricOrderFormGroup: FormGroup;
  isWorking: boolean = false;

  cephalometricTypes$: Observable<CephalometricTypeStoreEntity[]> = this._store$
    .select(CephalometricTypeStoreSelectors.selectAllCephalometricTypes)

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: CephalometricsOrderDialogData,
    private _bottomSheetRef: MatBottomSheetRef<CephalometricsOrderDialogComponent>,
    private _patientClient: PatientClient,
    private _snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
  ) {
    this.setInitialFormGroup();
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  setInitialFormGroup() {
    this.cephalometricOrderFormGroup = this._fb.group({
      cephalometricTypeId: [null, Validators.required]
    });
  }

  save() {
    if (!this.cephalometricOrderFormGroup.valid) return;
    let cephalometricTypeId: number = this.cephalometricOrderFormGroup.get("cephalometricTypeId").value;
    
    this.isWorking = true;
    this._patientClient
      .patient_PostPatientCephalometricOrder(
        this._data.patientId,
        this._data.cephalometricId,
        cephalometricTypeId,
        null)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.isWorking = false;
        this.done();
      }, (err) => {
        this._snackBar.open("An error has occured.", "Ok", {
          duration: 3000,
        });
        this.isWorking = false
      });
  }

  done() {
    this._snackBar.open("Successfully added order.", "Ok", {
      duration: 3000,
    });
    this._bottomSheetRef.dismiss();
  }
}

export interface CephalometricsOrderDialogData {
  patientId: number;
  cephalometricId: number;
}
