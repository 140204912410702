import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as TenantStoreActions from './actions';
import { AuthStoreActions } from '../auth-store';

@Injectable({ providedIn: 'root' })
export class TenantStoreEffects {
  constructor(
    private actions$: Actions
  ) { }

  @Effect()
  loadOnLoginRequestEffect$ = this.actions$.pipe(
    ofType(AuthStoreActions.LoginSuccess),
    map((action) => TenantStoreActions.LoadSuccess({ tenants: action.userCredentials.tenants }))
  );

  @Effect()
  loadOnLoginSelectSucessEffect$ = this.actions$.pipe(
    ofType(AuthStoreActions.LoginSelectSuccess),
    map((action) => TenantStoreActions.LoadSuccess({ tenants: action.token.tenants }))
  );
}
