import { createAction, props } from "@ngrx/store";
import { TxCardTypeStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[TxCardType] Load Request',
  props<{page?: number; pageSize?: number; }>(),
);

export const LoadSuccess = createAction(
  '[TxCardType] Load Success',
  props<{ txCardTypes: TxCardTypeStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[TxCardType] Load Failure',
  props<{ error: any; }>()
);
