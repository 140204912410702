import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppPropertyService } from '../property-service/property-service.service';

@Injectable({ providedIn: 'root' })
export class TreatmentPlanService {
  public _enviApiUrl: string;
  public _txCardsEndpoint: string;
  public _wiresEndpoint: string;
  public _elasticsEndpoint: string;
  public _custom1Endpoint: string;
  public _custom2Endpoint: string;
  public _upperWireTitle: string;
  public _lowerWireTitle: string;
  public _elasticsTitle: string;
  public _appliancesTitle: string;
  public _staffTitle: string;
  public _doctorTitle: string;
  public _notesTitle: string;
  public _chargeTitle: string;
  public _bracketsTitle: string;
  public _elasticSeqTitle: string;

  public _wires: any;
  private _columnsArray = [];
  private _bracketsArray = [];
  private _elastSeqArray = [];
  public _upperWireArray = [];
  public _lowerWireArray = [];

  public _eTag: string;

  public _elasticsArray: any;

  public showLoading: boolean;

  constructor(
    private appPropSvc: AppPropertyService,
    private httpClient: HttpClient
  ) {
    this._enviApiUrl = environment.apiUrl;
    this._txCardsEndpoint = this.appPropSvc.get(
      'treatment_plan.txCardEndpoint'
    );

    // get titles
    this._upperWireTitle = this.appPropSvc.get('treatment_plan.upperWireTitle');
    this._lowerWireTitle = this.appPropSvc.get('treatment_plan.lowerWireTitle');
    this._elasticsTitle = this.appPropSvc.get('treatment_plan.elasticsTitle');
    this._appliancesTitle = this.appPropSvc.get(
      'treatment_plan.appliancesTitle'
    );
    this._staffTitle = this.appPropSvc.get('treatment_plan.staffTitle');
    this._doctorTitle = this.appPropSvc.get('treatment_plan.doctorTitle');
    this._notesTitle = this.appPropSvc.get('treatment_plan.notesTitle');
    this._chargeTitle = this.appPropSvc.get('treatment_plan.chargeTitle');
    this._bracketsTitle = this.appPropSvc.get('treatment_plan.bracketsTitle');
    this._elasticSeqTitle = this.appPropSvc.get(
      'treatment_plan.elasticSeqTitle'
    );
    this._custom1Endpoint = this.appPropSvc.get('treatment_plan.custom1');
    this._custom2Endpoint = this.appPropSvc.get('treatment_plan.custom2');
  }

  public get(endpoint: string, id?: number) {
    return this.httpClient.get(
      id
        ? `${this._enviApiUrl}${endpoint}/${id}`
        : `${this._enviApiUrl}${endpoint}`,
      {
        observe: 'response'
      }
    );
  }

  public post(endpoint: string, param: any) {
    return this.httpClient.post(`${this._enviApiUrl}${endpoint}`, param);
  }

  public put(endpoint: string, param: any, id?: string) {
    localStorage.setItem('etag', this._eTag);
    return this.httpClient.put(
      id
        ? `${this._enviApiUrl}${endpoint}/${id}`
        : `${this._enviApiUrl}${endpoint}`,
      param
    );
  }

  public delete(endpoint: string, id: string) {
    return this.httpClient.delete(`${this._enviApiUrl}${endpoint}/${id}`);
  }

  public removeClassOnElementWithClassnames(
    classname: string,
    classtoRemove: string
  ) {
    const allElementWithClassname = document.getElementsByClassName(classname);
    for (let i = 0; i < allElementWithClassname.length; i++) {
      allElementWithClassname[i].classList.remove(classtoRemove);
    }
  }

  public getCustoms() {
    return this.httpClient.options(
      `${this._enviApiUrl}${this._txCardsEndpoint}`
    );
  }
}
