import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, share, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, PatientClient } from 'src/app/shared/services/api.service';
import { PatientsStoreActions, PatientsStoreSelectors } from '../patient-store';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as PatientMiniCardsStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class PatientMiniCardsStoreEffects {
  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);

  constructor(
    private _store$: Store<State>,
    private _actions$: Actions,
    private _patientClient: PatientClient
  ) { }

  @Effect()
  loadPatientMiniCardsRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientMiniCardsStoreActions.LoadMiniCardsRequest),
    withLatestFrom(this._store$.select((state) => state.Patients)),
    switchMap(([action, state]) =>
      this._patientClient
        .patient_GetMiniCards(action.patientId)
        .pipe(map((result) => PatientMiniCardsStoreActions.LoadMiniCardsSuccess({ patientMiniCards: result })))
    )
  );

  @Effect()
  patientMiniCardsUpdateEffect$: Observable<Action> = this._actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedPatientId$),
    filter(
      ([action, patientId]) =>
        action.event.eventArea == HubEventArea.MiniCardsEvent &&
        action.event.entityType == PatientClient.PatientMiniCardsDto &&
        action.event.entityId == patientId
    ),
    map(([action, patientId]) =>
      PatientMiniCardsStoreActions.LoadMiniCardsRequest({ patientId: patientId })
    ),
  );

  @Effect()
  selectPatientSuccessEffect$: Observable<Action> =
    this._actions$.pipe(
      ofType(PatientsStoreActions.SelectSuccess),
      map((action) => PatientMiniCardsStoreActions.LoadMiniCardsRequest({ patientId: action.id })),
      share()
    );
}
