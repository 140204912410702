import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, ActionsSubject } from '@ngrx/store';
import { combineLatest, fromEvent, Observable, ReplaySubject, Subject, iif, EMPTY, of, forkJoin } from 'rxjs';
import { filter, map, skipWhile, take, takeUntil, withLatestFrom, switchMap, defaultIfEmpty, tap, share } from 'rxjs/operators';
import {
  DiagnosisStoreActions,
  DiagnosisStoreEntity,
  DiagnosisStoreSelectors,
  RootStoreState,
  PatientsStoreSelectors,
  PatientStoreEntity,
  GoalStoreSelectors,
  PatientTreatmentStoreActions
} from 'src/app/root-store';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { DiagnosisDto, LocationClient, TreatmentDto, GoalDto, DiagnosisDto2 } from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-diagnosis-editor',
  templateUrl: './diagnosis-editor.component.html',
  styleUrls: ['../patient-plan.module.css', './diagnosis-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagnosisEditorComponent implements OnInit, OnDestroy {
  @Input('treatment') patientTreatment: TreatmentDto;

  selectedDiagnosis: DiagnosisStoreEntity;
  selectedDiagnoses: DiagnosisDto[] = [];
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  isLoadingCount: number = 0;
  /** Primary observable retrieving all the active diagnoses in their nested state */
  diagnoses$: Observable<DiagnosisStoreEntity[]> = this._store$.select(DiagnosisStoreSelectors.selectAllActiveDiagnoses);
  diagnosesIsLoading$: Observable<boolean> = this._store$.select(DiagnosisStoreSelectors.selectIsLoading);
  /** Observable which takes the output of the nested diagnoses and flattens them out of their nested format */
  diagnosesFlat$: Observable<DiagnosisStoreEntity[]> = this.diagnoses$.pipe(map((diagnoses) => this.flatenDiagnoses(diagnoses)));
  /** Array of observables which each return a specific level of the diagnoses tree currently open in the template */
  openDiagnosesGroups: Observable<DiagnosisStoreEntity[]>[] = [];
  /** Replayable subject observable that holds the diagnosis ID of the parent for the currently visible diagnosis branch */
  currentParentId$: ReplaySubject<number> = new ReplaySubject<number>(1);
  /** Boolean indicating whether to act on any keyboard events for quick diagnosis selector navigation */
  enableKeyboard: boolean = false;
  /** Boolean indicating if goals associated to diagnosis should be automatically added along with added diagnosis */
  enableAutoGoal: boolean = true;
  /** Observable returning an array representing the current path to the selected diagnosis with parents first */
  currentPathArr$: Observable<DiagnosisStoreEntity[]> = combineLatest([this.currentParentId$, this.diagnosesFlat$]).pipe(
    map(
      ([currentParentId, diagnoses]) => <[DiagnosisStoreEntity, DiagnosisStoreEntity[]]>[diagnoses.find((d) => d.id == currentParentId), diagnoses]
    ),
    map(([diagnosis, diagnoses]) => this.getDiagnosisPathArr(diagnosis, diagnoses).reverse())
  );
  /** Observable returning a string representation of currently selected diagnosis tree path */
  currentPathStr$: Observable<string> = this.currentPathArr$.pipe(
    map((path) => path.reduce((prev, next) => (!prev ? next.name : prev + ' &gt; ' + next.name), <string>null)),
    map((value) => value || '')
  );
  /** Observable returning an array of the currently visible diagnoses */
  currentGroup$: Observable<DiagnosisStoreEntity[]> = combineLatest([this.diagnosesFlat$, this.currentParentId$]).pipe(
    map(([diagnoses, currentParentId]) => diagnoses.filter((d) => d.parentId === currentParentId).sort((a, b) => a.sortOrder - b.sortOrder))
  );

  diagnoses: DiagnosisStoreEntity[]=[];

  todayDate: any;
  @Input() isExpanded:boolean = false;

  constructor(
    @Inject(CARD_DATA) data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private _locationClient: LocationClient,
    private _snackbar: MatSnackBar,
    private _actions$: ActionsSubject,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.todayDate = new Date().toISOString();
    this.currentParentId$.next(null);
    this.openDiagnosesGroups.push(this.diagnoses$);
    this.diagnoses$.pipe(filter(diagnoses => !!diagnoses)).subscribe(resp => {
      this.diagnoses = _.orderBy(resp, "sortOrder", 'asc');
      this.diagnoses.map(diagnosis => {
        diagnosis.children = _.orderBy(diagnosis.children, "sortOrder", 'asc');
      })
      this._cdr.detectChanges();
    })

    //Build event listener for keyup events
    fromEvent(window, 'keyup')
      .pipe(
        takeUntil(this._destroy$),
        skipWhile(() => !this.enableKeyboard), //filter if hotkeys turned off
        filter((event: KeyboardEvent) => /^\d+$/.test(event.key)), //verify number was pressed
        withLatestFrom(this.currentGroup$)
      )
      .subscribe(([event, diagnoses]) => {
        //Select diagnosis at position of pressed key
        const index = parseInt(event.key) - 1;
        if (diagnoses.length > index) this.optionSelectionHandler(diagnoses[index]);
        this._cdr.detectChanges();
      });

    this.setSelectedDiagnoses();
  }
  ngOnDestroy() {
    this._destroy$.next(true);
  }

  /**
   * Accepts a diagnosis and array of flatten diagnoses and returns an array from decendants down to most recent child
   * @param diagnosis Diagnosis whose path you with to get
   * @param diagnoses Full, flattened array of diagnoses
   */
  getDiagnosisPathArr(diagnosis: DiagnosisStoreEntity, diagnoses: DiagnosisStoreEntity[]): DiagnosisStoreEntity[] {
    let path: DiagnosisStoreEntity[] = [];
    if (!diagnosis) return path;

    path.push(diagnosis);
    if (diagnosis.parentId) {
      const parent = diagnoses.find((d) => d.id == diagnosis.parentId);
      path = path.concat(this.getDiagnosisPathArr(parent, diagnoses));
    }
    return path;
  }

  /**
   * Get a string representation of the path from top of the diagnoses tree to provided diagnosis
   * @param diagnosis Diagnosis whose path you want to find
   */
  getDiagnosisPathStr(diagnosis: DiagnosisStoreEntity) {
    return this.diagnosesFlat$.pipe(
      map((diagnoses) => this.getDiagnosisPathArr(diagnosis, diagnoses)),
      map((path) => path.reverse().reduce((prev, next) => (!prev ? next.name : prev + ' &gt; ' + next.name), <string>null))
    );
  }

  /**
   * Flattens a nested array of diagnoses
   * @param arr Array of nested Diagnoses
   */
  flatenDiagnoses(arr: DiagnosisStoreEntity[]): DiagnosisStoreEntity[] {
    return arr.reduce((acc, val) => {
      acc.push(val);
      return acc.concat(Array.isArray(val.children) && val.children.length > 0 ? this.flatenDiagnoses(val.children) : val);
    }, <DiagnosisStoreEntity[]>[]);
  }

  /**
   * Event handler for diagnoses selectors that opens another selector with the children
   * @param diagnosis Selected Diagnosis
   */
  optionSelectionHandler(diagnosis: DiagnosisStoreEntity) {
    this.selectedDiagnosis = diagnosis;
    this.currentParentId$.next(diagnosis.id);
    this.openDiagnosesGroups.push(
      this.diagnosesFlat$.pipe(map((diagnoses) => diagnoses.find((d) => d.id == diagnosis.id).children.sort((a, b) => (a.sortOrder - b.sortOrder))))
    );
  }

  /**
   * Removes the most recently opened diagnosis selector stepping back up a level
   */
  back() {
    this.diagnosesFlat$
      .pipe(
        withLatestFrom(this.currentParentId$),
        take(1),
        map(([diagnoses, currentParentId]) => diagnoses.find((d2) => d2.id == currentParentId).parentId) //get ID for parent of parent
      )
      .subscribe((diagnosisId) => {
        this.currentParentId$.next(diagnosisId);
        this.openDiagnosesGroups.pop();
        this._cdr.detectChanges();
      });
  }

  /**
   * Add a new diagnosis to the patient
   * @param description Additional data associated with diagnosis
   */
  add(description, clinicalTerms, laymanTerms) {
    this.isLoadingCount++;
    this.currentPathArr$
      .pipe(
        tap((currentPath) => this.addAssociatedGoals(currentPath)),
        map((currentPath) => this.buildNestedPatientDiagnosis(currentPath, description, clinicalTerms, laymanTerms)),
        withLatestFrom(this._selectedPatient$),
        switchMap(([diagnosis, patient]) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(diagnosis), //just return unsaved diagnosis if treatment not yet created
            this._locationClient.location_PostDiagnosis(patient.locationId, patient.id, this.patientTreatment.id, diagnosis).pipe(share())
          )
        ),
        take(1),
        tap((_) => this.isLoadingCount--)
      )
      .subscribe(
        (result) => {
          this.patientTreatment.diagnoses.push(result);
          //reset open groups to top level
          this.openDiagnosesGroups.length = 1;
          this.currentParentId$.next(null);
          this.setSelectedDiagnoses();
        },
        (err) => this._snackbar.open('Adding diagnosis is not working right now. :(', 'Okay')
      );
  }

  /**
   * Add any goals linked to the diagnosis being added
   * @param diagnoses Array of diagnosis for which to search associated goals
   */
  addAssociatedGoals(diagnoses: DiagnosisStoreEntity[]) {
    if (this.enableAutoGoal)
      this._store$
        .select(GoalStoreSelectors.selectAllGoals)
        .pipe(
          take(1),
          filter((goals) => !!goals),
          withLatestFrom(this._selectedPatient$),
          switchMap(([goals, patient]) => {
            const linkedGoalIds: number[] = diagnoses.reduce((prev, next) => (next.goalId ? prev.concat([next.goalId]) : prev), []);
            const linkedGoals: GoalDto[] = linkedGoalIds
              .map((goalId) => {
                const goal = goals.find((g) => g.id == goalId);
                //Try to prevent duplicates by skipping adding any goals that already exist with the same text
                if (!this.patientTreatment.goals.some((g) => g.name == goal.name))
                  return new GoalDto({ ...goal, id: 0, treatmentId: this.patientTreatment.id });
              })
              .filter((g) => !!g);
            return iif(() => this.patientTreatment.id == 0, of(linkedGoals), this.postGoals(linkedGoals, patient));
          })
        )
        .subscribe((goals) => {
          this.patientTreatment.goals.push(...goals);
          this._cdr.detectChanges();
        });
  }

  /**
   * Combines a series of post Goal POST requests into a single observable
   * @param goals Goals to be added
   * @param patient Patient goal is being added to
   */
  postGoals(goals: GoalDto[], patient: PatientStoreEntity): Observable<GoalDto[]> {
    const obs = goals.map((goal) =>
      this._locationClient.location_PostGoal(patient.locationId, patient.id, this.patientTreatment.id, goal).pipe(share(), take(1))
    );
    return forkJoin<GoalDto>(...obs).pipe(take(1));
  }

  /**
   * Convert an array of diagnoses into a nested object of patient diagnosis
   * @param diagnosesArr Array of Diagnoses
   * @param description The final terminating node if provided
   */
  buildNestedPatientDiagnosis(
    diagnosesArr: DiagnosisStoreEntity[],
    description?: string,
    clinicalTerms?: string,
    laymanTerms?: string
  ): DiagnosisDto {
    return diagnosesArr
      .reverse()
      .map(
        (diagnosis, index) => {
          if (index == 0)
            return new DiagnosisDto({
              ...diagnosis,
              id: 0,
              treatmentId: this.patientTreatment.id,
              clinicalTerms: clinicalTerms,
              laymanTerms: laymanTerms,
              miscInfo: description,
              children: null,
              parentId: null,
              eTag: null
            });

          return new DiagnosisDto({ ...diagnosis, id: 0, treatmentId: this.patientTreatment.id, children: null, parentId: null, eTag: null });
        })
      .reduce((prev, next) => {
        delete next.parent;
        if (prev) next.children = [prev];
        return next;
      }, null);
  }

  /**
   * Removes a diagnosis of the patient from the current list
   * @param entry Patient diagnosis to remove
   */
  remove(entry: DiagnosisDto) {
    this.isLoadingCount++;
    let childDiagnosis = this.getChildDiagnosis(entry);
    this._selectedPatient$
      .pipe(
        switchMap((patient) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(null),
            this._locationClient.location_DeleteDiagnosis(patient.locationId, patient.id, this.patientTreatment.id, childDiagnosis.id)
          )
        ),
        tap((_) => this.isLoadingCount--)
      )
      .subscribe((_) => {
        const index = this.patientTreatment.diagnoses.indexOf(entry);
        this.patientTreatment.diagnoses.splice(index, 1);
        this.setSelectedDiagnoses();
      });
  }

  getChildDiagnosis(diagnosis: DiagnosisDto): DiagnosisDto {
    if (diagnosis.children && diagnosis.children.length > 0)
      return this.getChildDiagnosis(diagnosis.children[0]);

    return diagnosis;
  }

  /**
   * Gets a string css value for transform based upon the selectors current index in the group
   * @param index Index of the selector in the currently open groups
   */
  getTransform(index: number): string {
    const offset = this.openDiagnosesGroups.length - index - 1;
    return `translateX(-${100 * offset}%)`;
  }

  addDiagnosis(grp:DiagnosisDto2, children?:DiagnosisDto2, item?:DiagnosisDto2){

    let selectedItems:DiagnosisDto2[] = [];
    selectedItems.push(grp);
    if(children){
      selectedItems.push(children);
    }

    if(item){
      selectedItems.push(item);
    }

    let description = ''; 
    let clinicalTerms = item ? item.clinicalTerms : children ? children.clinicalTerms : grp.clinicalTerms; 
    let laymanTerms = item ? item.laymanTerms : children ? children.laymanTerms : grp.laymanTerms; 
    
    let _currentPath: Observable<DiagnosisDto2[]> = of(selectedItems);

    this.isLoadingCount++;
    _currentPath
      .pipe(
        tap((currentPath) => this.addAssociatedGoals(currentPath)),
        map((currentPath) => this.buildNestedPatientDiagnosis(currentPath, description, clinicalTerms, laymanTerms)),
        withLatestFrom(this._selectedPatient$),
        switchMap(([diagnosis, patient]) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(diagnosis), //just return unsaved diagnosis if treatment not yet created
            this._locationClient.location_PostDiagnosis(patient.locationId, patient.id, this.patientTreatment.id, diagnosis).pipe(share())
          )
        ),
        take(1),
        tap((_) => this.isLoadingCount--)
      )
      .subscribe(
        (result) => {
          this.patientTreatment.diagnoses.push(result);
          this.setSelectedDiagnoses();
          //reset open groups to top level
          //this.openDiagnosesGroups.length = 1;
          //this.currentParentId$.next(null);
        },
        (err) => this._snackbar.open('Adding diagnosis is not working right now. :(', 'Okay')
      );
  }

  editDiagnosis(entry){
    this.isLoadingCount++;
    this._selectedPatient$.pipe(take(1)).subscribe(patient => {

      this._locationClient.location_PutDiagnosis(
        patient.locationId, 
        patient.id, 
        this.patientTreatment.id, 
        entry.diagnosis.id,
        entry.diagnosis, 
        null, 
        entry.diagnosis.eTag
      ).subscribe(resp => {
        this._locationClient.location_GetDiagnoses(patient.locationId, patient.id, this.patientTreatment.id).subscribe(result => {
          this.patientTreatment.diagnoses = result;
          this.isLoadingCount--;
          this.setSelectedDiagnoses();
        }, err => {
          this.isLoadingCount--
          this._cdr.detectChanges();
        })
      }, err=> {
        this.isLoadingCount--;
        this._cdr.detectChanges();
      }) 
    })
    
  }

  setSelectedDiagnoses(): void {
    this.selectedDiagnoses = [...this.patientTreatment.diagnoses];
    this._cdr.detectChanges();
  }
}
