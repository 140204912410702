import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PROCEDURE_GROUP_FEATURE_KEY } from './featurekey';
import { featureAdapter, ProcedureGroupStoreEntity, State } from './state';

export const selectProcedureGroupsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PROCEDURE_GROUP_FEATURE_KEY);

export const getSelectedProcedureGroupId = createSelector(selectProcedureGroupsState, (state) => state.selectedProcedureGroupId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectProcedureGroupsState);
export const selectProcedureGroupIds = selectIds;
export const selectAllProcedureGroups = selectAll;
export const selectAllProcedureGroupEntities = selectEntities;

export const getSelectedProcedureGroup = createSelector(
  selectProcedureGroupsState,
  selectAllProcedureGroups,
  (state, allProcedureGroups: ProcedureGroupStoreEntity[]) =>
    allProcedureGroups ? allProcedureGroups.find((rp) => rp.id === state.selectedProcedureGroupId) : null
);

export const selectProcedureGroupById = (id: number) =>
  createSelector(selectAllProcedureGroups, (allProcedureGroups: ProcedureGroupStoreEntity[]) =>
    allProcedureGroups ? allProcedureGroups.find((rp) => rp.id === id) : null
  );

export const selectProcedureGroupsError: MemoizedSelector<object, any> = createSelector(selectProcedureGroupsState, (state) => state.error);

export const selectProcedureGroupsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectProcedureGroupsState, (state: State) => state.isLoading);
