import { createAction, props } from "@ngrx/store";
import { PatientMiniCardsStoreEntity } from "./state";

//LOAD ONE
export const LoadMiniCardsRequest = createAction(
  '[Patient Mini Cards] Load Request',
  props<{ patientId: number; }>()
);

export const LoadMiniCardsSuccess = createAction(
  '[Patient Mini Cards] Load Success',
  props<{ patientMiniCards: PatientMiniCardsStoreEntity; }>()
);

export const LoadMiniCardsFailure = createAction(
  '[Patient Mini Cards] Load Failure',
  props<{ error: any; }>()
);
