import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { MarketingPackageDto, IMarketingPackageDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<MarketingPackageStoreEntity> = createEntityAdapter<MarketingPackageStoreEntity>({
  sortComparer: (a: MarketingPackageStoreEntity, b: MarketingPackageStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<MarketingPackageStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedMarketingPackageId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedMarketingPackageId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class MarketingPackageStoreEntity extends MarketingPackageDto {
  constructor(data?: IMarketingPackageDto) {
    super(data);
  }
}
