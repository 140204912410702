import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: 'img[defaultImage]' })
export class ImageErrorHandlerDirective {
  @Input() defaultImage: string;
  @Input() maxTry?: number = 5;
  private tryCount: number = 0;
  private spinnerSrc: string = 'assets/loading.gif';

  constructor() {
  }

  @HostListener('error', ['$event'])
  retryImage(event: any): void {
    if (this.maxTry != 0 && this.tryCount >= this.maxTry) {
      if (event.target.src != this.defaultImage)
        event.target.src = this.defaultImage;
      return;
    }

    const originalSrc = event.target.src;
    event.target.src = this.spinnerSrc;
    this.tryCount += 1;

    setTimeout(function () {
      event.target.src = originalSrc;
    }, 5000);
  }
}
