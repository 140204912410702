import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICard } from '../../shared/models/cards';
import { IOpenCard } from './IOpenCard';

export const featureAdapter: EntityAdapter<ICard> = createEntityAdapter<ICard>({
  selectId: card => card.selector,
  sortComparer: (a: ICard, b: ICard): number => a.order - b.order
});

export interface State extends EntityState<ICard> {
  isLoading?: boolean;
  error?: any;
  openCards: IOpenCard[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  openCards: []
});
