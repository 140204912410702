import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as PatientModelBoxStoreActions from './actions';
import * as PatientModelBoxStoreSelectors from './selectors';
import { PatientClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class PatientModelBoxStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientModelBoxStoreActions.LoadRequest),
    switchMap((action) =>
      this._patientClient
        .patient_GetPatientModelBoxes(action.patientId)
        .pipe(
          map((result) => PatientModelBoxStoreActions.LoadSuccess({ patientModelBoxes: result })),
          catchError((err: HttpErrorResponse) => of(PatientModelBoxStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientModelBoxStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(
          PatientModelBoxStoreSelectors
            .selectPatientModelBoxById(action.id))
        .pipe(map((result) => PatientModelBoxStoreActions.SelectSuccess({ patientModelBox: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientModelBoxStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(PatientModelBoxStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientModelBoxStoreActions.AddRequest),
    switchMap((action) =>
      this._patientClient.patient_PostPatientModelBox(
        action.patientModelBox.patientId,
        action.patientModelBox)
        .pipe(
          map((result) => PatientModelBoxStoreActions.AddSuccess({ patientModelBox: result })),
          catchError((err: HttpErrorResponse) => of(PatientModelBoxStoreActions.AddFailure({ error: err.message })))
        ))
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientModelBoxStoreActions.UpdateRequest),
    switchMap((action) =>
      this._patientClient.patient_PutPatientModelBox(
        action.patientModelBox.patientId,
        action.patientModelBox.id,
        action.patientModelBox,
        null,
        action.patientModelBox.eTag)
        .pipe(
          switchMap(() => this._patientClient.patient_GetPatientModelBox(
            action.patientModelBox.patientId,
            action.patientModelBox.id
          )),
          map((result) => PatientModelBoxStoreActions.UpdateSuccess({ patientModelBox: result })),
          catchError((err: HttpErrorResponse) => of(PatientModelBoxStoreActions.UpdateFailure({ error: err.message })))
        ))
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientModelBoxStoreActions.DeleteRequest),
    switchMap((action) =>
      this._patientClient.patient_DeletePatientModelBox(
        action.patientId,
        action.modelBoxId)
        .pipe(
          map(() => PatientModelBoxStoreActions.DeleteSuccess({ id: action.modelBoxId })),
          catchError((err: HttpErrorResponse) => of(PatientModelBoxStoreActions.DeleteFailure({ error: err.message })))
        ))
  );
}
