import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_NOTEPAD_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientNotepadStoreEntity, State } from './state';

export const selectPatientNotepadsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_NOTEPAD_FEATURE_KEY);

export const getSelectedPatientNotepadId = createSelector(selectPatientNotepadsState, (state) => state.selectedPatientNotepadId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientNotepadsState);
export const selectPatientNotepadIds = selectIds;
export const selectAllPatientNotepads = selectAll;
export const selectAllPatientNotepadEntities = selectEntities;

export const getSelectedPatientNotepad = createSelector(selectPatientNotepadsState, selectAllPatientNotepads, (state, allPatientNotepads: PatientNotepadStoreEntity[]) =>
  allPatientNotepads ? allPatientNotepads.find((rp) => rp.id === state.selectedPatientNotepadId) : null
);

export const selectPatientNotepadById = (id: number) =>
  createSelector(selectAllPatientNotepads, (allPatientNotepads: PatientNotepadStoreEntity[]) => (allPatientNotepads ? allPatientNotepads.find((rp) => rp.id === id) : null));

export const selectPatientNotepadsError: MemoizedSelector<object, any> = createSelector(selectPatientNotepadsState, (state) => state.error);

export const selectPatientNotepadIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientNotepadsState, (state: State) => state.isLoading);
