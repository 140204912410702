import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule, MatIconModule, MatSlideToggleModule, MatTreeModule } from '@angular/material';
import { DisableByPermissionDirective } from './disableByPermission.directive';
import { HasPermissionDirective } from './hasPermission.directive';
import { HideByPermissionDirective } from './hidebyPermission.directive';
import { UserPermissionsEditorComponent } from './user-permissions-editor/user-permissions-editor.component';

@NgModule({
  imports: [
    CommonModule,
    MatTreeModule,
    MatCheckboxModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
  ],
  declarations: [
    HideByPermissionDirective,
    HasPermissionDirective,
    DisableByPermissionDirective,
    UserPermissionsEditorComponent,
  ],
  exports: [
    HideByPermissionDirective,
    HasPermissionDirective,
    DisableByPermissionDirective,
    UserPermissionsEditorComponent,
  ],
})
export class UserPermissionsModule {}
