import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { PERMISSIONS, PERMISSION_DESCRIPTION, PERMISSION, PermissionTypeEnum, LocationPermissionTypeEnum, PermissionReservedKeywords } from '@shared/constants/permissions';
import * as _ from 'lodash';

interface PermissionNode {
  name: string;
  children?: PermissionNode[];
  permission?: PERMISSION;
  selected: boolean;
}

@Component({
  selector: 'app-user-permissions-editor',
  templateUrl: './user-permissions-editor.component.html',
  styleUrls: ['./user-permissions-editor.component.scss'],
})
export class UserPermissionsEditorComponent implements OnInit {
  private _locationId?: number = null;
  @Input('isGlobal') isGlobal:boolean;
  @Input('locationId') set locationId(value: number) {

    this._locationId = value;

    this.dataSourceGlobal = new MatTreeNestedDataSource<PermissionNode>();
    this.dataSourceLocation = new MatTreeNestedDataSource<PermissionNode>();
    this.dataSourcePatientLocation = new MatTreeNestedDataSource<PermissionNode>();

    if(this.isGlobal) {
      this.dataSourceGlobal.data = _.chain(PERMISSIONS[PermissionTypeEnum.Global])
        .map((permission) => {
          let processedPermission = this.processPermission(permission);
          if (processedPermission)
            return processedPermission;
        })
        .compact()
        .value();
    } else {
      this.dataSourceLocation.data = _.chain(PERMISSIONS[PermissionTypeEnum.Location])
        .filter({ 'locationPermissionType': LocationPermissionTypeEnum.Location })
        .map((permission) => {
          let processedPermission = this.processPermission(permission);
          if (processedPermission)
            return processedPermission;
        })
        .compact()
        .value();

      this.dataSourcePatientLocation.data = _.chain(PERMISSIONS[PermissionTypeEnum.Location])
        .filter({ 'locationPermissionType': LocationPermissionTypeEnum.PatientLocation })
        .map((permission) => {
          let processedPermission = this.processPermission(permission);
          if (processedPermission)
            return processedPermission;
        })
        .compact()
        .value();
    }
  }
  get locationId(): number {
    return this._locationId;
  }

  @Input('permissions') set userPermissions(value: string[]) {
    this.permissionSelectionList.clear();
    if(value){
      this.permissionSelectionList.select(...value);
    }

  }
  get userPermissions(): string[] {
    return this.permissionSelectionList.selected;
  }
  @Output('permissionsChange') userPermissionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  treeControl = new NestedTreeControl<PermissionNode>((node) => node.children);
  dataSourceGlobal = new MatTreeNestedDataSource<PermissionNode>();
  dataSourceLocation = new MatTreeNestedDataSource<PermissionNode>();
  dataSourcePatientLocation = new MatTreeNestedDataSource<PermissionNode>();
  permissionDescription = PERMISSION_DESCRIPTION;
  permissionSelectionList: SelectionModel<string> = new SelectionModel<string>(true);


  constructor() { }

  ngOnInit() { }

  processPermission(permission: PERMISSION) {

    if (typeof permission == 'string') {
      if (PERMISSION_DESCRIPTION[permission])
        return <PermissionNode>{
          name: PERMISSION_DESCRIPTION[permission],
          permission: (this.isGlobal ? 'Global:' : `LocationId.${this.locationId}:`) + permission,
          selected: false,
        };
    } else {
      const children = _.chain(permission)
        .filter((p, key) => {
          return !_.includes(PermissionReservedKeywords, key);
        })
        .map((p) => {
          return this.processPermission(p);
        })
        .value();

      return <PermissionNode>{
        name: permission.description,
        children: children,
        permission: permission,
      };
    }
  }

  togglePermission(permission: PERMISSION, isChecked: boolean) {
    if (typeof permission == 'string') {
      // setTimeout enables seems to fix the UI getting out of sync and showing the last value
      setTimeout(() => {
        if (isChecked == true) this.permissionSelectionList.deselect(permission);
        else this.permissionSelectionList.select(permission);
        this.userPermissionChange.next(this.permissionSelectionList.selected);
      });
    } else {
      this.toggleDescendants(permission, isChecked);
    }
  }

  togglePermissionGroup(permissionGroup: PERMISSION, isChecked: boolean) {
    this.toggleDescendants(permissionGroup, isChecked);
  }

  toggleDescendants(permission: PERMISSION, isChecked: boolean) {
    Object.entries(permission)
      .filter(([key, permission]) => !_.includes(PermissionReservedKeywords, key))
      .forEach(([_, permission]) =>
        this.togglePermission(
          typeof permission == 'string' ?
            (this.isGlobal ? 'Global:' : `LocationId.${this.locationId}:`) + permission
            : permission,
          isChecked
        )
      );
  }

  hasChild = (_: number, node: PermissionNode) => !!node.children && node.children.length > 0;
  allChildrenSelected(node: PermissionNode) {
    return (
      node &&
      !!node.children &&
      node.children.every((childNode) =>
        childNode.children ? this.allChildrenSelected(childNode) : this.permissionSelectionList.isSelected(<string>childNode.permission)
      )
    );
  }
}
