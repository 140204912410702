import { createAction, props } from "@ngrx/store";
import { PatientImagingStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Patient Imaging] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[Patient Imaging] Load Success',
  props<{ patientImagings: PatientImagingStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Patient Imaging] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Patient Imaging] Add Request',
  props<{ patientImaging: PatientImagingStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Patient Imaging] Add Success',
  props<{ patientImaging: PatientImagingStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Patient Imaging] Add Failure',
  props<{ error: string; }>()
);

//ADD MULTIPLE
export const AddMultipleRequest = createAction(
  '[Patient Imaging] Add Multiple Request',
  props<{ patientImagings: PatientImagingStoreEntity[]; }>()
);

export const AddMultipleSuccess = createAction(
  '[Patient Imaging] Add Multiple Success',
  props<{ patientImagings: PatientImagingStoreEntity[]; }>()
);

export const AddMultipleFailure = createAction(
  '[Patient Imaging] Add Multiple Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Patient Imaging] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Patient Imaging] Select Success',
  props<{ patientImaging: PatientImagingStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Patient Imaging] Select Failure',
  props<{ error: any; }>()
);


//DESELECT
export const DeselectRequest = createAction(
  '[Patient Imaging] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Patient Imaging] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Patient Imaging] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Patient Imaging] Reset Error'
);
