import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap, exhaustMap } from 'rxjs/operators';
import { PatientsStoreActions } from '../patient-store';
import { State } from '../root-state';
import * as StoreActions from './actions';
import * as StoreSelectors from './selectors';
import { PatientClient, PatientLedgerDto, PatientLedgerOptionsDto } from 'src/app/shared/services/api.service';
import { state } from '@angular/animations';

@Injectable({ providedIn: 'root' })
export class PatientLedgerStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(StoreActions.GetPatientLedgers),
    filter((action) => !!action && !!action.patientId),
    exhaustMap((action) =>
      this._patientClient.patient_GetLedgers(action.patientId).pipe(
        map((result: PatientLedgerDto[]) => {
          return StoreActions.GetPatientLedgersSuccess({ ledgers: result });
        }),
        catchError((err: HttpErrorResponse) => of(StoreActions.LoadFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  getLedgerTransactionEffect$: Observable<Action> = this.actions$.pipe(
    ofType(StoreActions.GetLedgerTransactions),
    exhaustMap((action) => {
      return this._patientClient.patient_GetLedgerTransactions(action.patientId, action.ledgerId).pipe(
        map((result: any) => {
          return StoreActions.GetLedgerTransactionSuccess({ ledgerId: action.ledgerId, ledgerTransactions: result });
        }),
        catchError((err: HttpErrorResponse) => of(StoreActions.LoadFailure({ error: err.message })))
      )
    }
    )
  );

  @Effect()
  getResponsiblePartiesEffect$: Observable<Action> = this.actions$.pipe(
    ofType(StoreActions.GetResponsibleParties),
    switchMap((action) => {
      return this._patientClient.patient_GetRelationships(action.patientId, action.contactId).pipe(
        map((result: any) => {
          return StoreActions.GetResponsiblePartiesSuccess({ responsibleParties: result });
        }),
        catchError((err: HttpErrorResponse) => of(StoreActions.LoadFailure({ error: err.message })))
      )
    }
    )
  );

  @Effect()
  getLedgerOptionsEffect$: Observable<Action> = this.actions$.pipe(
    ofType(StoreActions.GetLedgerOptions),
    switchMap((action) => {
      if (action.patientId == null) { return new Observable<Action>(); }
      return this._patientClient.patient_GetLedgerOptions(action.patientId).pipe(
        map((result: any) => {
          return StoreActions.GetLedgerOptionsSuccess({ ledgerOptions: result });
        }),
        catchError((err: HttpErrorResponse) => of(StoreActions.LoadFailure({ error: err.message })))
      )
    }
    )
  );
}
