import { IScheduleGridChairDto, ScheduleGridChairDto } from 'src/app/shared/services/api.service';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter = createEntityAdapter<ChairStoreEntity>({
  selectId: (chair) => chair.rowId,
  sortComparer: (a, b) => a.displayOrder
});

export interface State extends EntityState<ChairStoreEntity> {
  isLoading?: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class ChairStoreEntity extends ScheduleGridChairDto {
  constructor(data?: IScheduleGridChairDto) {
    super(data);
  }
}
