import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { HEADER_FEATURE_KEY } from "./featurekey";
import { State } from "./state";

export const selectHeaderState: MemoizedSelector<object, State> = createFeatureSelector<State>(HEADER_FEATURE_KEY);

export const selectHeaderSection = createSelector(
  selectHeaderState,
  state => state.selectedSection
);

export const selectHeaderHeight = createSelector(
  selectHeaderState,
  state => state.headerHeight
)
