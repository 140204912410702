import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MatMenuModule} from '@angular/material/menu'; 
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';

import { DentistClinicSearchComponent } from './dentist-clinic-search/dentist-clinic-search.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DentistClinicOtherviewComponent } from './dentist-clinic-otherview/dentist-clinic-otherview.component';
import { MatProgressBarModule } from '@angular/material';
import { MatIconModule, MatRippleModule } from '@angular/material';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  declarations: [DentistClinicSearchComponent, DentistClinicOtherviewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule, 
    FormsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    PerfectScrollbarModule,
    MatProgressBarModule,
    MatIconModule,
    MatRippleModule
  ],
  exports: [
    FormsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    PerfectScrollbarModule,
    DentistClinicSearchComponent
  ],
})
export class DentistClinicSearchModule { }
