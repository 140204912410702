import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientLedgerDto, PatientLedgerOptionsDto, PatientLedgerTransactionDto, RelationshipDto } from "src/app/shared/services/api.service";

export const featureAdapter: EntityAdapter<PatientLedgerStoreEntity> = createEntityAdapter<PatientLedgerStoreEntity>({
  selectId: patient => patient.id,
  sortComparer: (a: PatientLedgerStoreEntity, b: PatientLedgerStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<PatientLedgerStoreEntity> {
  selectedLedgerId?: number;
  selectedLedger?: PatientLedgerDto;
  responsibleParties: RelationshipDto[];
  ledgerOptions: PatientLedgerOptionsDto;
  showPatientInfo: boolean;
  page?: number;
  pageSize?: number;
  isLoading?: boolean;
  error?: any;
  ledgerTransactions: PatientLedgerTransactionDto[];
}

export const initialState: State = featureAdapter.getInitialState({
  selectedLedgerId: null,
  selectedLedger: null,
  responsibleParties: [],
  ledgerOptions: null,
  showPatientInfo: false,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null,
  ledgerTransactions: null
});

export class PatientLedgerStoreEntity extends PatientLedgerDto {
  constructor(data?: PatientLedgerDto) {
    super(data);
  }
}
