import { createAction, props } from '@ngrx/store';
import { PatientReferralMessageStoreEntity } from './state';

export const LoadPatientReferralMessagesRequest = createAction('[Patient Referral Message] Load Patient Referral Messages Request');
export const LoadPatientReferralMessagesSuccess = createAction('[Patient Referral Message] Load Patient Referral Messages Success', props<{ patientReferralMessages: PatientReferralMessageStoreEntity[] }>());
export const LoadPatientReferralMessagesFailure = createAction('[Patient Referral Message] Load Patient Referral Messages Failure', props<{ error: any }>());

export const LoadPatientReferralMessageRequest = createAction('[Patient Referral Message] Load Patient Referral Message Request', props<{ messageId: number }>());
export const LoadPatientReferralMessageSuccess = createAction('[Patient Referral Message] Load Patient Referral Message Success', props<{ patientReferralMessage: PatientReferralMessageStoreEntity }>());
export const LoadPatientReferralMessageFailure = createAction('[Patient Referral Message] Load Patient Referral Message Failure', props<{ error: any }>());

export const ReferralMessagedRemoved = createAction('[Patient Referral Messages] RemoveReferralMessage', props<{ messageId: number; }>());



