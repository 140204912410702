import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IPatientNotepadDto, PatientNotepadDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientNotepadStoreEntity> = createEntityAdapter<PatientNotepadStoreEntity>({
  selectId: patientNotepad => patientNotepad.id
});

export interface State extends EntityState<PatientNotepadStoreEntity> {
  selectedPatientNotepadId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientNotepadId: null,
  isLoading: false,
  error: null
});

export class PatientNotepadStoreEntity extends PatientNotepadDto {
  constructor(data?: IPatientNotepadDto) {
    super(data);
  }
}
