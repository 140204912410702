import { createAction, props } from "@ngrx/store";
import { PatientDentistVisitStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientDentistVisit] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[PatientDentistVisit] Load Success',
  props<{ patientDentistVisits: PatientDentistVisitStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientDentistVisit] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[PatientDentistVisit] Add Request',
  props<{ patientDentistVisit: PatientDentistVisitStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[PatientDentistVisit] Add Success',
  props<{ patientDentistVisit: PatientDentistVisitStoreEntity; }>()
);

export const AddFailure = createAction(
  '[PatientDentistVisit] Add Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[PatientDentistVisit] Update Request',
  props<{ patientDentistVisit: PatientDentistVisitStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[PatientDentistVisit] Update Success',
  props<{ patientDentistVisit: PatientDentistVisitStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[PatientDentistVisit] Update Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[PatientDentistVisit] Delete Request',
  props<{ patientId: number, dentistVisitId: number }>()
);

export const DeleteSuccess = createAction(
  '[PatientDentistVisit] Delete Success',
  props<{ id: number }>()
);

export const DeleteFailure = createAction(
  '[PatientDentistVisit] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientDentistVisit] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientDentistVisit] Select Success',
  props<{ patientDentistVisit: PatientDentistVisitStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientDentistVisit] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientDentistVisit] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientDentistVisit] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientDentistVisit] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[PatientDentistVisit] Reset Error'
);
