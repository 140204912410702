import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DOCTOR_TIME_FEATURE_KEY } from './featurekey';
import { featureAdapter, DoctorTimeStoreEntity, State } from './state';

export const selectDoctorTimesState: MemoizedSelector<object, State> = createFeatureSelector<State>(DOCTOR_TIME_FEATURE_KEY);

export const getSelectedDoctorTimeId = createSelector(selectDoctorTimesState, (state) => state.selectedDoctorTimeId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectDoctorTimesState);
export const selectDoctorTimeIds = selectIds;
export const selectAllDoctorTimes = selectAll;
export const selectAllDoctorTimeEntities = selectEntities;

export const getSelectedDoctorTime = createSelector(
  selectDoctorTimesState,
  selectAllDoctorTimes,
  (state, allDoctorTimes: DoctorTimeStoreEntity[]) =>
    allDoctorTimes ? allDoctorTimes.find((rp) => rp.id === state.selectedDoctorTimeId) : null
);

export const selectDoctorTimeById = (id: number) =>
  createSelector(selectAllDoctorTimes, (allDoctorTimes: DoctorTimeStoreEntity[]) =>
    allDoctorTimes ? allDoctorTimes.find((rp) => rp.id === id) : null
  );

export const selectDoctorTimesError: MemoizedSelector<object, any> = createSelector(selectDoctorTimesState, (state) => state.error);

export const selectDoctorTimesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectDoctorTimesState, (state: State) => state.isLoading);
