import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_MODEL_BOX_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientModelBoxStoreEntity, State } from './state';

export const selectPatientModelBoxesState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_MODEL_BOX_FEATURE_KEY);

export const getSelectedPatientModelBoxId = createSelector(selectPatientModelBoxesState, (state) => state.selectedPatientModelBoxId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientModelBoxesState);
export const selectPatientModelBoxIds = selectIds;
export const selectAllPatientModelBoxes = selectAll;
export const selectAllPatientModelBoxEntities = selectEntities;

export const getSelectedPatientModelBox = createSelector(
  selectPatientModelBoxesState,
  selectAllPatientModelBoxes,
  (state, allPatientModelBoxes: PatientModelBoxStoreEntity[]) =>
    allPatientModelBoxes ? allPatientModelBoxes.find((rp) => rp.id === state.selectedPatientModelBoxId) : null
);

export const selectPatientModelBoxById = (id: number) =>
  createSelector(selectAllPatientModelBoxes, (allPatientModelBoxes: PatientModelBoxStoreEntity[]) =>
    allPatientModelBoxes ? allPatientModelBoxes.find((rp) => rp.id === id) : null
  );

export const selectPatientModelBoxesError: MemoizedSelector<object, any> = createSelector(selectPatientModelBoxesState, (state) => state.error);

export const selectPatientModelBoxesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientModelBoxesState, (state: State) => state.isLoading);
