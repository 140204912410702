import { DiagnosisStoreState } from './diagnosis';
import { GoalStoreState } from './goal';
import { PlanGroupStoreState } from './plan-group';
import { StepGroupStoreState } from './step-group';
import { ActiveTreatmentProgressStoreState } from './active-treatment-progress';

export interface State {
  diagnoses: DiagnosisStoreState.State;
  goals: GoalStoreState.State;
  planGroups: PlanGroupStoreState.State;
  stepGroups: StepGroupStoreState.State;
  activeTreatmentProgresses: ActiveTreatmentProgressStoreState.State
  isLoading?: boolean;
}

export const initialState: State = {
  diagnoses: DiagnosisStoreState.initialState,
  goals: GoalStoreState.initialState,
  planGroups: PlanGroupStoreState.initialState,
  stepGroups: StepGroupStoreState.initialState,
  activeTreatmentProgresses: ActiveTreatmentProgressStoreState.initialState,
  isLoading: false
};
