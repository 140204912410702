import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientRecallDto, IPatientRecallDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientRecallStoreEntity> = createEntityAdapter<PatientRecallStoreEntity>({
  selectId: patientRecall => patientRecall.id,
  sortComparer: (a: PatientRecallStoreEntity, b: PatientRecallStoreEntity): number => b.recallDate.getTime() - a.recallDate.getTime()
});

export interface State extends EntityState<PatientRecallStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientRecallId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientRecallId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientRecallStoreEntity extends PatientRecallDto {
  constructor(data?: IPatientRecallDto) {
    super(data);
  }
}
