import { Component, OnInit, Inject, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckbox, MatSelectChange, MatOption } from '@angular/material';
import { TxCardOptionsDto, AppointmentTxCardDto, NextPeriodDto } from 'src/app/shared/services/api.service';
import { ProcedureGroupDTO } from 'src/app/shared/services/settings/settings.service';
import * as _ from 'lodash';

@Component({
  templateUrl: './treatment-cards-appointment-modal.html',
  styleUrls: ['./treatment-cards-appointment-modal.css']
})
export class TreatmentCardMultipleAddModal implements OnInit {
  options: TxCardOptionsDtoVM;
  appointments: AppointmentTxCardDto[] = [];
  procedureGroupList: any[] = [];
  filteredProcedureGroupList: any[] = [];
  procedures = [];

  @ViewChildren('procedureInput') procedureInput: QueryList<ElementRef>;
  @ViewChildren('wksInput') wksInput: QueryList<ElementRef>;

  selectedProcedureInputIndx: any = 0;
  selectedWksInput: any = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TreatmentCardMultipleAddModal>
  ) {
    this.options = data.options;
    this.procedureGroupList = data.procedureGroupList;
    this.filteredProcedureGroupList = data.procedureGroupList;
    const clonedAppointments = [];
    data.appointments.forEach((val: AppointmentTxCardDto) => clonedAppointments.push(val));

    for (let index = clonedAppointments.length; index <= 2; index++) {
      clonedAppointments.push(new AppointmentTxCardDto());
    }

    this.filteredProcedureGroupList.map(procedureGrp => {
      procedureGrp.filteredProcedures = procedureGrp.procedures;
    })

    this.options.filteredNextPeriods = this.options.nextPeriods;

    this.appointments = clonedAppointments;
    this.procedures = _.chain(this.procedureGroupList).map('procedures').flatten().value();
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.appointments.map((appnt, indx) => {
        let _procedure = this.procedures.find(procedure => procedure.id == appnt.procedureId);
        if(_procedure){
          this.procedureInput.forEach((element, index) => {
            if(indx == index){
              element.nativeElement.value = `${_procedure.code}-${_procedure.description}`;
            }
          })


          if(appnt.nextPeriodId){
            this.wksInput.forEach((element, index) => {
              if(indx == index){
                let selectedWeeks = this.options.nextPeriods.find(nextPeriod => nextPeriod.id == appnt.nextPeriodId);
                element.nativeElement.value = selectedWeeks.name;
              }
            })
          }

        }
      })
    }, 500)

  }

  closeModal() {
    // clear any appointments with no data
    this.appointments = this.appointments.filter(x => (x.nextPeriodId != null && x.procedureId != null) || x.id > 0);
    // emit appointments to caller
    this.dialogRef.close(this.appointments);
  }

  onChangeProcedure(evnt){
    this.appointments.map((appnt, appntIndx) => {
      if(this.selectedProcedureInputIndx == appntIndx){
        let _procedure = this.procedures.find(procedure => procedure.id == appnt.procedureId);
        if(_procedure && _procedure.defaultNextPeriodId){
          appnt.nextPeriodId = _procedure.defaultNextPeriodId;

          this.selectedWksInput = this.selectedProcedureInputIndx;

          setTimeout(() => {
            this.wksInput.forEach((element, index) => {
              if(this.selectedWksInput == index){
                let selectedWeeks = this.options.nextPeriods.find(nextPeriod => nextPeriod.id == appnt.nextPeriodId);
                element.nativeElement.value = selectedWeeks.name;
              }
            } )
          })
        }
      }
    });

    this.procedureInput.forEach((element, index) => {
      if(this.selectedProcedureInputIndx == index){
        let selectedProcedure = this.procedures.find(procedure => procedure.id == evnt.option.value);
        element.nativeElement.value = `${selectedProcedure.code}-${selectedProcedure.description}`;
      }
    } )
  }

  enabledCreateRemider(appointment){
    let _appnts = this.appointments.filter(x => x.nextPeriodId != null && x.procedureId != null);
    if(_appnts.length > 1 && appointment.procedureId && appointment.nextPeriodId){
      return true
    }

    return false
  }

  onSearchProcedure(evt){
    let _searchkeyword: any = evt.target.value;
    if (_searchkeyword.length > 0) {
      let searchkeyword = _searchkeyword.toLowerCase();

      this.procedureGroupList.map(group => {
        group.filteredProcedures = group.procedures.filter(procedure => procedure.isActive && ((procedure.code.toLowerCase().includes(searchkeyword) || procedure.description.toLowerCase().includes(searchkeyword))))
      });

      this.filteredProcedureGroupList = this.procedureGroupList.filter(procedureGrp => procedureGrp.filteredProcedures.length > 0);

    } else {

      this.procedureGroupList.map(group => {
        group.filteredProcedures = group.procedures.filter(procedure => procedure.isActive)
      });

      this.filteredProcedureGroupList = this.procedureGroupList;
    }
  }

  onFocusAutoComplete(indx){
    this.selectedProcedureInputIndx = indx;
    this.procedureGroupList.map(group => {
      group.filteredProcedures = group.procedures.filter(procedure => procedure.isActive)
    });
    this.filteredProcedureGroupList = this.procedureGroupList;
  }


  onSearchWeeks(evt){
    let _searchkeyword: any = evt.target.value;
    if (_searchkeyword.length > 0) {
      let searchkeyword = _searchkeyword.toLowerCase();
      this.options.filteredNextPeriods = this.options.nextPeriods.filter(nextPeriod => nextPeriod.name.toLocaleLowerCase().includes(searchkeyword));
      if(this.options.filteredNextPeriods.length == 0){
        this.options.filteredNextPeriods = this.options.nextPeriods;
      }
    } else {
      this.options.filteredNextPeriods = this.options.nextPeriods;
    }

  }

  onFocusWeekAutoComplete(indx){
    this.selectedWksInput = indx;
    this.options.filteredNextPeriods = this.options.nextPeriods;
  }

  onChangeWeek(evnt){
    this.wksInput.forEach((element, index) => {
      if(this.selectedWksInput == index){
        let selectedWeeks = this.options.nextPeriods.find(nextPeriod => nextPeriod.id == evnt.option.value);
        element.nativeElement.value = selectedWeeks.name;
      }
    } )
  }

}

export class TxCardOptionsDtoVM extends TxCardOptionsDto {
  filteredNextPeriods?: NextPeriodDto[] | null;
}
