import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map, switchMap } from 'rxjs/operators';
import { LocationsStoreActions } from '../location-store';
import * as ScheduleStoreActions from './actions';

@Injectable()
export class ScheduleStoreEffects {
  constructor(private actions$: Actions) {}

  dateChangeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScheduleStoreActions.DateChange),
        tap((action) => sessionStorage.setItem('selectedScheduleDate', action.date.toISOString()))
      ),
    { dispatch: false }
  );
}
