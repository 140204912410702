import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export enum ViewerType {
  Image = 'Image',
  Model = 'Model',
  IFrame = 'IFrame',
  WordDoc = 'WordDoc',
  Other = 'Other',
}

@Component({
  selector: 'file-viewer-container',
  templateUrl: './file-viewer-container.component.html',
  styleUrls: ['./file-viewer-container.component.css']
})
export class FileViewerContainerComponent implements OnInit, OnDestroy {
  private readonly _msftOfficeViewer = 'https://view.officeapps.live.com/op/embed.aspx?src={url}';
  viewerType: ViewerType = ViewerType.Other;
  viewerTypeEnum = ViewerType;
  isThumbnail: boolean = false;
  private _imageMimeTypes: string[] = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp'
  ];
  private _modelMimeTypes: string[] = [
    'application/octet-stream',
    'model/stl',
    'model/obj',
    'model/mtl'
  ];
  private _iframeTypes: string[] = [
    'application/pdf'
  ];
  private _wordDocTypes: string[] = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() thumbnailUrl: string;
  @Input() url: string;
  @Input() contentType: string = '';

  faExclamationTriangle = faExclamationTriangle;

  constructor(
    private _snackbar: MatSnackBar,
  ) {

  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this.viewerType = this.getViewerType(this.contentType);
  }

  loadImageFailed(): void {
    this.openSnackBar("Failed to load image", 'Ok');
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  getViewerType(contentType: string): ViewerType {
    if (contentType) {
      if (this._imageMimeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.Image;
      if (this._modelMimeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.Model;
      if (this._iframeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.IFrame;
      if (this._wordDocTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.WordDoc;
    }

    return this.viewerTypeEnum.Other;
  }

  getMSFTViewerUrl(fileLocation: string) {
    return this._msftOfficeViewer.replace('{url}', encodeURIComponent(fileLocation));
  }
}
