import { Pipe, PipeTransform } from '@angular/core';
import { AppPropertyService } from '../shared/services/property-service/property-service.service';

@Pipe({
  name: 'appProperty'
})
export class AppPropertyPipe implements PipeTransform {
  constructor(private appPropertyService: AppPropertyService) {}

  transform(key: string) {
    return this.appPropertyService.get(key);
  }
}
