import { CommunicationDeskDto } from 'src/app/shared/services/api.service';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export type CommunicationDeskStoreEntity = CommunicationDeskDto;

export const featureAdapter = createEntityAdapter<CommunicationDeskStoreEntity>({
  selectId: (desk) => desk.id,
  sortComparer: (a,b) => a.name.localeCompare(b.name)
});

export interface State extends EntityState<CommunicationDeskStoreEntity> {
  isLoading?: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});
