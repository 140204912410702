import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import * as moment from 'moment';
import { NotificationDTO, ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements AfterViewInit, OnChanges {
  @Input() patientId: number;
  @Input() notificationList: NotificationDTO[];
  @Input() fetchNotification: boolean = false;
  @Output() cancel = new EventEmitter();
  @Output() afterSubmit = new EventEmitter();

  isDialog: boolean = false;
  submitclick:boolean = false;

  constructor(
    private scheduleService: ScheduleService,
    private _dialogRef: MatDialogRef<NotificationSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationSettingsData,
  ) {
    if (this.data) {
      this.patientId = data.patientId;
      this.notificationList = data.notificationList;
      this.fetchNotification = data.fetchNotification;
      this.isDialog = !!data.isDialog;
    }
  }

  ngAfterViewInit(): void { }

  createList(list: any[]) {
    this.notificationList = [];

    if(list){
      list.forEach((data, index) => { // list must be sorted by relationshipTypeAbbreviation
        let prevNotification = new NotificationDTO(list[index - 1]);
        let notification = new NotificationDTO(data);
  
        if (prevNotification.id > 0) {
          if (notification.relationshipTypeAbbreviation.toUpperCase() == prevNotification.relationshipTypeAbbreviation.toUpperCase()) {
            notification.sameType = true;
          }
        } 
  
        this.notificationList.push(notification);
      });
    }

    
  }

  onSubmitNotification() {
    if (this.patientId > 0) {
      this.scheduleService.updateNotifications(this.patientId,this.notificationList).subscribe((res: any[]) => {
        this.afterSubmit.emit({
          statusCode: 1,
          result: 'success',
          response: res
        });
      }, err => {
        this.afterSubmit.emit({
          statusCode: -1,
          result: 'error',
          response: err
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fetchNotification) {
      this.notificationList = [];

      if (this.patientId > 0) {
        this.scheduleService.getNotifications(this.patientId).subscribe((res: any[]) => {
          this.createList(res);
        });
      }
    } else {
      this.createList(this.notificationList);
    }
  }

  submitNotification(){
    this.submitclick = true;
  }

  cancelEdit(event: any): void {
    this.cancel.emit(event)
    if (this.isDialog) {
      this._dialogRef.close();
    }
  }

  aftersave(res){
    this.submitclick = false;
    this.afterSubmit.emit({
      statusCode: 1,
      result: 'success',
      response: res
    });

    if (this.isDialog) {
      this._dialogRef.close();
    }
  }
}

export interface NotificationSettingsData {
  patientId: number,
  notificationList: NotificationDTO[],
  fetchNotification: boolean,
  isDialog?: boolean
}
