import { Component, ChangeDetectorRef, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../core/message.service';

@Component({
	selector: 'app-patientmodaldd',
	templateUrl: './patientmodaldd.component.html',
	styleUrls: ['./patientmodaldd.component.css']
})
export class PatientmodalddComponent implements OnInit, OnChanges, OnDestroy {
	@Input() model: any;

	@Input() selectedValue: any;
	@Input() dropdownOptions: any[];
	@Input() multiple: boolean;
	@Input() defaultLocation: any;
	@Input() label: string = '';
	@Input() defaultSelectedValue: any;

	@Output() selectValue = new EventEmitter();
	@Output() location_filterArray = new EventEmitter();

	showdd: boolean = false;
	dd_id: any;
	subscription: Subscription;

	constructor(private dataService: MessageService) { }

	ngOnInit() {
		this.closeOtherDropdowns();
	}

	ngOnChanges() {
		this.selectDefaultValue();
	}

	/**
	 * Check if dropdown has label or not.
	 * If dropdown doesn't have label then checks default value, 
	 * Set default value, if no default value is passed then set 'All' as default selected value
	 */
	selectDefaultValue() {
		if (this.label.length) {
			if (this.model != null) {
				let option = this.dropdownOptions.find(option => option.id == this.model);

				if (option) {
					this.defaultSelectedValue = option.label;
				} else {
					this.defaultSelectedValue = 'All';
				}
			} else if (!this.defaultSelectedValue) {
				this.defaultSelectedValue = 'All';
			}
		}

		this.dropdownOptions.forEach(x => {
			if (x.label === this.defaultSelectedValue) {
				x.selected = true;

				this.selectedValue = this.defaultSelectedValue;
				// this.selectValue.emit(this.selectedValue);
				this.showdd = false;
			} else {
				x.selected = false;
			}
		});
	}

	/**
	 * Get current opened dropdown id and close all other dropdowns.
	 */
	closeOtherDropdowns() {
		this.dd_id = (+ new Date()) + (Math.floor(Math.random() * 1000) + 1);

    this.subscription = this.dataService.getMessage().subscribe(message => {
      if (!message) return;
			if (message.event == 'dropdownstate') {
				if (this.dd_id != message.data.dd_id) {
					this.showdd = false;
				}
			}
		});
	}

	/**
	 * Show dropdown options and emit to close already opened dropdowns.
	 */
	showddoptions() {
		this.showdd = this.showdd == true ? false : true;
		this.dataService.sendMessage('dropdownstate', { 'dd_id': this.dd_id, 'state': this.showdd });
	}

	/**
	 * Selects dropdown option.
	 * Checks either dropdown type is single or multiple selection.
	 * @param {number} currentIndex  Index of current selected dropdown option. 
	 */
	selectOption(currentIndex: number) {
		if (this.model != null) { // emits the model value if model is present
			this.dropdownOptions.map(item => item.selected = false);
			this.selectedValue = this.dropdownOptions[currentIndex].label;
			this.dropdownOptions[currentIndex].selected = true;
			this.showdd = false;
			this.selectValue.emit(this.dropdownOptions[currentIndex].id);
		} else {
			if (this.multiple) {
				let filterdata = [];
				this.dropdownOptions[currentIndex].selected = this.dropdownOptions[currentIndex].selected == true ? false : true;
				let _selectedMultiple = this.dropdownOptions.filter(item => { return item.selected == true });

				if (_selectedMultiple.length == 0) {
					this.selectedValue = this.selectedValue;
				} else if (_selectedMultiple.length == 1) {
					this.selectedValue = _selectedMultiple[0].label;
				} else {
					if (_selectedMultiple.length == this.dropdownOptions.length) {
						this.selectedValue = 'all';
					}
					else {
						this.selectedValue = `${_selectedMultiple.length}`;
					}
				}
				if (_selectedMultiple.length > 0) {
					for (var i = 0; i < _selectedMultiple.length; i++) {
						filterdata.push(_selectedMultiple[i].label);
					}
				}
				this.location_filterArray.emit(filterdata);
			} else {
				this.dropdownOptions.map(item => item.selected = false);
				this.selectedValue = this.dropdownOptions[currentIndex].label;
				this.dropdownOptions[currentIndex].selected = true;
				this.selectValue.emit(this.selectedValue);
				this.showdd = false;
			}
		}
	}

	/**
	 * Close dropdown when clicked outside the dropdown.
	 */
	onClickedOutside() {
		this.showdd = false;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
