import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  share,
  withLatestFrom
} from "rxjs/operators";
import { HubEventArea, LocationClient } from "src/app/shared/services/api.service";
import {
  LocationsStoreActions, LocationsStoreSelectors
} from "../location-store";
import { State } from "../root-state";
import { SignalRHubStoreActions } from "../signalr-hub-store";
import * as ChairActions from "./actions";

@Injectable({ providedIn: "root" })
export class ChairEffectsService {
  private _selectedLocationId$ = this._store$.select(LocationsStoreSelectors.getSelectedLocationId);

  constructor(
    private _actions$: Actions,
    private _locationClient: LocationClient,
    private _store$: Store<State>,
  ) {}

  locationSelectLoadRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationsStoreActions.SelectSuccess),
      map(action => ChairActions.LoadRequest({ locationId: action.location.id })),
      share()
    )
  );

  loadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChairActions.LoadRequest),
      exhaustMap((action) => this._locationClient.location_GetScheduleChairs(action.locationId).pipe(
        map((result) => ChairActions.LoadSuccess({ chairs: result })),
        catchError((error) => of(ChairActions.LoadFailure({ error }))),
      )),
      share()
    )
  );

  chairUpdateEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      withLatestFrom(this._selectedLocationId$),
      filter(
        ([action, selectedLocationId]) =>
          action.event.eventArea == HubEventArea.ScheduleEvent &&
          action.event.entityType == LocationClient.ScheduleGridChairDto &&
          action.event.locationId == selectedLocationId
      ),
      map(([action, selectedLocationId]) =>
        ChairActions.LoadRequest({ locationId: selectedLocationId })
      ),
    )
  );
}
