import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, HubEventType, MessageChannelTypeEnum, PatientClient } from 'src/app/shared/services/api.service';
import { PatientsStoreSelectors } from '../patient-store';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as MessageDraftStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class MessageDraftStoreEffects {
  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);

  constructor(
    private _store$: Store<State>,
    private _actions$: Actions,
    private _patientClient: PatientClient
  ) { }

  loadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessageDraftStoreActions.LoadRequest),
      withLatestFrom(this._selectedPatientId$),
      switchMap(([action, selectedPatientId]) => {
        if (!action.messageDraftId) {
          return this._patientClient.patient_GetMessageDrafts(
            selectedPatientId).pipe(
              map((messageDrafts) => MessageDraftStoreActions.LoadSuccess({ entries: messageDrafts })),
              catchError((err) => of(MessageDraftStoreActions.LoadFailure({ error: err }))),
              take(1)
            );
        } else {
          return this._patientClient.patient_GetMessageDraft(selectedPatientId, action.messageDraftId).pipe(
            map((messageDraft) => MessageDraftStoreActions.LoadSuccess({ entries: messageDraft })),
            catchError((err) => of(MessageDraftStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        }
      })
    )
  );

  addMessageDraftRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessageDraftStoreActions.AddMessageDraftRequest),
      withLatestFrom(this._selectedPatientId$),
      switchMap(([action, selectedPatientId]) =>
        this._patientClient.patient_PostMessageDraft(
          selectedPatientId,
          action.message
        ).pipe(
          take(1),
          map((result) => {
            if (action.files && action.files.length > 0) {
              return MessageDraftStoreActions.AddMessageDraftAttachmentRequest({
                message: result,
                files: action.files
              });
            }
            else {
              return MessageDraftStoreActions.AddMessageDraftSuccess({ message: result});
            }
          }),
          catchError((error) => of(MessageDraftStoreActions.AddMessageDraftFailure({ error: error })))
        )
      ),
      share()
    )
  );

  addMessageDraftAttachmentRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessageDraftStoreActions.AddMessageDraftAttachmentRequest),
      withLatestFrom(this._selectedPatientId$),
      switchMap(([action, selectedPatientId]) =>
        this._patientClient.patient_PostMessageDraftAttachment(
          selectedPatientId,
          action.message.id,
          action.files
        ).pipe(
          take(1),
          map((result) => {
            return MessageDraftStoreActions.AddMessageDraftAttachmentSuccess({ message: action.message });
          }),
          catchError((error) => of(MessageDraftStoreActions.AddMessageDraftFailure({ error: error })))
        )
      ),
      share()
    )
  );

  updateMessageDraftRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessageDraftStoreActions.UpdateMessageDraftRequest),
      withLatestFrom(this._selectedPatientId$),
      switchMap(([action, selectedPatientId]) =>
        this._patientClient.patient_PutMessageDraft(
          selectedPatientId,
          action.message.id,
          action.message
        ).pipe(
          take(1),
          map((result) => {
            if (action.files && action.files.length > 0) {
              return MessageDraftStoreActions.AddMessageDraftAttachmentRequest({
                message: action.message,
                files: action.files
              });
            }
            else {
              return MessageDraftStoreActions.UpdateMessageDraftSuccess({ message: action.message });
            }
          }),
          catchError((error) => of(MessageDraftStoreActions.UpdateMessageDraftFailure({ error: error })))
        )
      ),
      share()
    )
  );

  removeMessageDraftRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessageDraftStoreActions.DeleteMessageDraftRequest),
      switchMap((action) =>
        this._patientClient.patient_DeleteMessageDraft(action.patientId, action.messageDraftId).pipe(
          take(1),
          mapTo(MessageDraftStoreActions.DeleteMessageDraftSuccess({ patientId: action.patientId, messageDraftId: action.messageDraftId })),
          catchError((error) => of(MessageDraftStoreActions.DeleteMessageDraftFailure({ error: error })))
        )
      ),
      share()
    )
  );

  // Listen for updates from hub
  messageDraftUpdateEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      withLatestFrom(this._selectedPatientId$),
      filter(
        ([action, selectedPatientId]) =>
          action.event.eventArea == HubEventArea.MessageDraftEvent &&
          action.event.entityType == PatientClient.MessageDraftDto &&
          action.event.patientId == selectedPatientId
      ),
      map(([action, selectedPatientId]) =>
        action.event.eventType != HubEventType.Removed
          ? MessageDraftStoreActions.LoadRequest({})
          : MessageDraftStoreActions.Remove({ messageDraftId: action.event.entityId })
      ),
    )
  );
}
