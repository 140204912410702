import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, ActionsSubject } from '@ngrx/store';
import { forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { share, switchMap, take, tap, takeUntil, filter } from 'rxjs/operators';
import { PatientsStoreSelectors, PatientStoreEntity, PlanGroupStoreEntity, PlanGroupStoreSelectors, RootStoreState, PatientTreatmentStoreActions } from 'src/app/root-store';
import { LocationClient, PlanDto, PlanDto2, TreatmentDto, NextPlanDto, PlanGroupDto } from 'src/app/shared/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-treatment-plan-editor',
  templateUrl: './treatment-plan-editor.component.html',
  styleUrls: ['../patient-plan.module.css', './treatment-plan-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreatmentPlanEditorComponent implements OnInit, OnDestroy {
  @Input('treatment') patientTreatment: TreatmentDto;

  selectedPlans: any[];
  planGroups$: Observable<PlanGroupStoreEntity[]> = this._store$.select(PlanGroupStoreSelectors.selectIsActivePlanGroups(true));
  planGroups: PlanGroupDtoVM[]=[];
  private _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  isLoadingCount: number = 0;
  @Input() isNextStep:boolean = false;
  @Input() isExpanded:boolean = false;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _store$: Store<RootStoreState.State>, 
    private _locationClient: LocationClient,
    private _actions$: ActionsSubject,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this.planGroups$.subscribe(_planGroups => {
      _planGroups.map(plangroup => {
        this.planGroups.push(new PlanGroupDtoVM(plangroup));
      })

      this.planGroups = _.orderBy(this.planGroups, ['sortOrder', 'name'], ['asc', 'asc']);

      this.planGroups.map(plangrp => {
        plangrp.plans = _.orderBy(plangrp.plans, ['sortOrder', 'name'], ['asc', 'asc']);
      })

      this._cdr.detectChanges();
    })
    this.setSelectedPlans();
  }

  setSelectedPlans(): void {
    if (!this.patientTreatment) {
      this.selectedPlans = [];
    }
    else {
      this.selectedPlans = this.isNextStep ? _.orderBy(this.patientTreatment.nextPlans, 'id', 'asc') : _.orderBy(this.patientTreatment.plans, 'id', 'asc');
    }
    this._cdr.detectChanges();
  }

  addPlanGroup(evt:Event, planGroup: PlanGroupStoreEntity) {
    evt.stopPropagation();
    if (!planGroup.plans || (planGroup.plans && planGroup.plans.length == 0)) return;

    let patientPlans;
    if(this.isNextStep){
      patientPlans = planGroup.plans.map((p) => new NextPlanDto({ ...p, id: 0, treatmentId: this.patientTreatment.id }));
    } else {
      patientPlans = planGroup.plans.map((p) => new PlanDto({ ...p, id: 0, treatmentId: this.patientTreatment.id }));
    }

    this._selectedPatient$
      .pipe(
        tap((_) => this.isLoadingCount++),
        switchMap((patient) => {
          if (this.patientTreatment.id == 0)
            return of(patientPlans);
          else
            return forkJoin<PlanDto>(
              ...patientPlans.map((plan) => {
                if(this.isNextStep){
                  return this._locationClient.location_PostNextPlan(patient.locationId, patient.id, this.patientTreatment.id, plan).pipe(share())
                } else {
                  return this._locationClient.location_PostPlan(patient.locationId, patient.id, this.patientTreatment.id, plan).pipe(share())
                }
              })
            );
        }),
        tap((_) => this.isLoadingCount--),
        take(1)
      )
      .subscribe((results) => {
        if(this.isNextStep){
          this.patientTreatment.nextPlans.push(...results);
        } else {
          this.patientTreatment.plans.push(...results);
        }
        this.setSelectedPlans();
      });
  }

  addPlan(plan: PlanDto2) {
    let patientPlan;
    if(this.isNextStep){
      patientPlan = new NextPlanDto({ ...plan, id: 0, treatmentId: this.patientTreatment.id });
    } else {
      patientPlan = new PlanDto({ ...plan, id: 0, treatmentId: this.patientTreatment.id });
    }
    this._selectedPatient$
      .pipe(
        tap((_) => this.isLoadingCount++),
        switchMap((patient) => {
          if (this.patientTreatment.id == 0){
            return of(patientPlan);
          } else {
            if(this.isNextStep){
              return this._locationClient.location_PostNextPlan(patient.locationId, patient.id, this.patientTreatment.id, patientPlan).pipe(share());
            } else {
              return this._locationClient.location_PostPlan(patient.locationId, patient.id, this.patientTreatment.id, patientPlan).pipe(share());
            }
            
          }
            
        }),
        tap((_) => this.isLoadingCount--),
        take(1)
      )
      .subscribe((result) => {
        if(this.isNextStep){
          this.patientTreatment.nextPlans.push(result);
        } else {
          this.patientTreatment.plans.push(result);
        }
        this.setSelectedPlans();
      });
  }

  editPlan(editObject) {
    let patientPlan: PlanDto = editObject.plan;
    let idx = editObject.idx;

    this._selectedPatient$
      .pipe(
        tap((_) => this.isLoadingCount++),
        switchMap((patient) => {
          if (this.patientTreatment.id == 0){
            return of(patientPlan);
          } else {
            if(this.isNextStep){
              return this._locationClient.location_PutNextPlan(patient.locationId, patient.id, this.patientTreatment.id, patientPlan.id, patientPlan).pipe(share());
            } else {
              return this._locationClient.location_PutPlan(patient.locationId, patient.id, this.patientTreatment.id, patientPlan.id, patientPlan).pipe(share());
            }
            
          }
            
        }),
        tap((_) => this.isLoadingCount--),
        take(1)
      )
      .subscribe((result) => {
        this._selectedPatient$.pipe(filter(patient => !!patient)).subscribe(patient => {
          this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: patient.locationId, patientId: patient.id }))
          this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(result => {
            let _treatment = result.plans.find(treament => treament.id == this.patientTreatment.id);
            this.patientTreatment.nextPlans = _treatment.nextPlans;
            this.patientTreatment.plans = _treatment.plans;
            this.setSelectedPlans();
          })
        })
      });
  }

  removePlan(patientPlan: PlanDto) {
    this._selectedPatient$
      .pipe(
        tap((_) => this.isLoadingCount++),
        switchMap((patient) => {
          if (this.patientTreatment.id == 0){
            return of(null);
          } else {
            if(this.isNextStep){
              return this._locationClient.location_DeleteNextPlan(patient.locationId, patient.id, this.patientTreatment.id, patientPlan.id).pipe(share());
            } else {
              return this._locationClient.location_DeletePlan(patient.locationId, patient.id, this.patientTreatment.id, patientPlan.id).pipe(share());
            }
          }
        }),
        tap((_) => this.isLoadingCount--),
        take(1)
      )
      .subscribe(() => {
        if(this.isNextStep){
          const ind = this.patientTreatment.nextPlans.findIndex((plan) => plan.id == patientPlan.id);
          this.patientTreatment.nextPlans.splice(ind, 1);
        } else {
          const ind = this.patientTreatment.plans.findIndex((plan) => plan.id == patientPlan.id);
          this.patientTreatment.plans.splice(ind, 1);
        }
        this.setSelectedPlans();
      });
  }

  selectPlanGroup(_planGroup:PlanGroupDtoVM){
    this.planGroups.map(planGroup => {
      if(planGroup.id != _planGroup.id){
        planGroup.isSelected = false;
      } else {
        planGroup.isSelected = planGroup.isSelected ? false : true;
      }
    })
    this._cdr.detectChanges();
  }
}

export class PlanGroupDtoVM extends PlanGroupDto {
  isSelected:boolean = false;
}
