import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const getAuthState: MemoizedSelector<object, State> = createFeatureSelector<State>(AUTH_FEATURE_KEY);

export const selectIsLoading = createSelector(getAuthState, (state) => state.isLoading);
const { selectAll, selectEntities, selectIds, selectTotal } = featureAdapter.getSelectors(getAuthState);
export const selectAllTokens = selectAll;
export const selectTokenEntities = selectEntities;
export const selectTokenIds = selectIds;
export const selectTokenTotal = selectTotal;

export const selectedUserTokenId = createSelector(getAuthState, (state) => state.selectedTokenId);

export const selectCredentials = createSelector(selectedUserTokenId, selectAllTokens, (selectedUserTokenId, tokens) =>
  tokens.find((t) => t.id == selectedUserTokenId)
);

export const selectCredentialsById = (id: string) => createSelector(selectAllTokens, (tokens) => tokens.find((t) => t.id == id));

export const selectIsUserTokenExpired = (time: number, userTokenId?: string) => {
  const token = userTokenId ? selectCredentialsById(userTokenId) : selectCredentials
  return createSelector(
    token,
    (credentials) => credentials && time > credentials.expiresOn.valueOf()
  );
}

export const selectUserRealName = createSelector(
  selectCredentials,
  (userCredentials) =>
    userCredentials && { firstName: userCredentials.firstName, lastName: userCredentials.lastName, nickname: userCredentials.nickName }
);

export const selectUserId = createSelector(selectCredentials, (userCredentials) => userCredentials && userCredentials.id);

export const selectUserNickName = createSelector(selectCredentials, (userCredentials) => userCredentials && userCredentials.nickName);

export const selectUserDefaultLocationId = createSelector(selectCredentials, (userCredentials) => userCredentials && userCredentials.defaultLocationId);

export const selectTenantKey = createSelector(getAuthState, (state) => state.tenantKey);

export const selectTenantName = createSelector(getAuthState, (state) => state.tenantName);

export const selectIsPatient = createSelector(getAuthState, (state) => state.isPatient);

export const selectSaveSession = createSelector(getAuthState, (state) => state.saveSession);

//#region Permissions
export const selectUsername = createSelector(getAuthState, (state) => state.parsedToken && state.parsedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);

export const selectUserPermissions = createSelector(getAuthState, (state) => state.parsedToken && state.parsedToken.Permissions);

export const selectUserLocationIds = createSelector(getAuthState, (state) => state.parsedToken && state.parsedToken.Permissions.locationIds);

export const selectUserRoles = createSelector(getAuthState, state => state.parsedToken && state.parsedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])
//#endregion
