import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition('* <=> *', [
    query(
      '.fadeAnim:enter',
      [style({ opacity: 0, position: 'relative', left: '5%' }), stagger('50ms', animate('300ms ease-in', style({ opacity: 1, left: 0 })))],
      {
        optional: true,
      }
    ),
    query(
      '.fadeAnim:leave',
      [style({ opacity: 1, position: 'relative', left: 0 }), stagger('50ms', animate('300ms ease-out', style({ opacity: 0, left: '5%' })))],
      {
        optional: true,
      }
    ),
  ]),
]);
