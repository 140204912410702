import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IImageDto, ImageDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<CephalometricStoreEntity> = createEntityAdapter<CephalometricStoreEntity>({
  selectId: cephalometric => cephalometric.id,
  sortComparer: (a: CephalometricStoreEntity, b: CephalometricStoreEntity): number => b.createdWhen.getTime() - a.createdWhen.getTime()
});

export interface State extends EntityState<CephalometricStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedCephalometricId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedCephalometricId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class CephalometricStoreEntity extends ImageDto {
  constructor(data?: IImageDto) {
    super(data);
  }
}
