import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientImagingComponent } from './patient-imaging.component';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import {
  MatProgressBarModule,
  MatCardModule,
  MatListModule,
  MatDividerModule,
  MatIconModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonModule,
  MatMenuModule,
  MatDatepickerModule,
  MatInputModule
} from '@angular/material';
import { UploadImageComponent } from '../../../outreach/treatment/upload-image/upload-image.component';
import { ngfModule } from 'angular-file';
import { ClickConfirmModule } from '../../click-confirm/click-confirm.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { GalleryComponent } from '../../../outreach/treatment/gallery/gallery.component';
import { CropImageComponent } from '../../../outreach/treatment/crop-image/crop-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OutreachModule } from '../../../outreach/outreach.module';
import { CompareComponent } from '../../../outreach/treatment/gallery/compare/compare.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EvolutionColors } from 'src/app/shared/enums';
import { FullScreenViewerModule } from '../../full-screen-viewer/full-screen-viewer.module';
import { FullScreenViewerComponent } from '../../full-screen-viewer/full-screen-viewer.component';
import { MontageGeneratorComponent } from '../../../outreach/treatment/montage-generator/montage-generator.component';
import { MontageGeneratorModule } from '../../../outreach/treatment/montage-generator/montage-generator.module';
import { ImageErrorHandlerModule } from '../../image-error-handler/image-error-handler.module';
import { PatientFilesSelectorModule } from '../../patient-files-selector/patient-files-selector.module';
import { PatientFilesSelectorComponent } from '../../patient-files-selector/patient-files-selector.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TextMaskModule } from 'angular2-text-mask';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';

@CardModule({
  selector: 'patient-imaging',
  front: PatientImagingComponent,
  back: null,
  header: 'Imaging',
  width: 520,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faCamera', actionName: '', isFaIcon: true}],
  order: 30,
  color: EvolutionColors.Orange,
})
@NgModule({
  declarations: [
    PatientImagingComponent,
    UploadImageComponent
  ],
  entryComponents: [
    CompareComponent,
    CropImageComponent,
    GalleryComponent,
    PatientImagingComponent,
    UploadImageComponent,
    FullScreenViewerComponent,
    MontageGeneratorComponent
  ],
  imports: [
    ClickConfirmModule,
    CommonModule,
    ImageCropperModule,
    IvyCarouselModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTooltipModule,
    ngfModule,
    OutreachModule,
    ReactiveFormsModule,
    FullScreenViewerModule,
    MontageGeneratorModule,
    ImageErrorHandlerModule,
    PatientFilesSelectorModule,
    MatDialogModule,
    FormsModule,
    FontAwesomeModule,
    MatMenuModule,
    MatDatepickerModule,
    TextMaskModule,
    MatInputModule,
    MomentDateModule
  ]
})
export class PatientImagingModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
