import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClickConfirm } from '../click-confirm.interface';

@Component({
  templateUrl: './dialog-info-template.component.html',
  styleUrls: ['./dialog-info-template.component.css']
})
export class DialogInfoTemplateComponent implements ClickConfirm, OnInit {

  @Output('confirm') confirm: EventEmitter<any> = new EventEmitter<any>();
  @Input('confirmText') confirmText: string;
  @Input('positiveButtonText') positiveButtonText: string = "OK";
  @Input('negativeButtonText') negativeButtonText: string = "";

  constructor() { }

  ngOnInit() {
  }

  confirmClick() {
    this.confirm.next(false);
  }
}
