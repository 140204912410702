import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, IterableDiffers, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable, Subject, of, iif } from 'rxjs';
import {
  DiagnosisStoreEntity,
  DiagnosisStoreSelectors,
  GoalStoreEntity,
  GoalStoreSelectors,
  PatientsStoreSelectors,
  PatientStoreEntity,
  RootStoreState,
} from 'src/app/root-store';
import { DiagnosisDto, GoalDto, TreatmentDto, LocationClient } from 'src/app/shared/services/api.service';
import { take, switchMap, share, tap , filter} from 'rxjs/operators';
import { getPositionOfLineAndCharacter } from 'typescript';
import * as _ from 'lodash';

@Component({
  selector: 'app-goals-editor',
  templateUrl: './goals-editor.component.html',
  styleUrls: ['../patient-plan.module.css', './goals-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoalsEditorComponent implements OnInit, OnDestroy {
  @Input('treatment') patientTreatment: TreatmentDto;

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  goals$: Observable<GoalStoreEntity[]> = this._store$.select(GoalStoreSelectors.selectGoalsActiveState(true));
  private _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  isLoadingCount: number = 0;
  goals: GoalStoreEntity[] = [];
  selectedGoals: GoalDto[] = [];

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _locationClient: LocationClient,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.goals$.pipe(filter(goals => !!goals)).subscribe(goals => {
      this.goals = _.orderBy(goals, 'sortOrder', 'asc');
    })
    this.setSelectedGoals();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  setSelectedGoals(): void {
    this.selectedGoals = [...this.patientTreatment.goals];
    this._cdr.detectChanges();
  }

  addGoal(goal: GoalStoreEntity) {
    const patientGoal = new GoalDto({ ...goal, id: 0, treatmentId: this.patientTreatment.id });
    this._selectedPatient$
      .pipe(
        tap((_) => this.isLoadingCount++),
        switchMap((patient) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(patientGoal),
            this._locationClient.location_PostGoal(patient.locationId, patient.id, this.patientTreatment.id, patientGoal).pipe(share())
          )
        ),
        take(1),
        tap((_) => this.isLoadingCount--),
      )
      .subscribe((result) => {
        this.patientTreatment.goals.push(result);
        this.setSelectedGoals();
      });
  }

  removeGoal(goal: GoalDto) {
    this._selectedPatient$
      .pipe(
        tap((_) => this.isLoadingCount++),
        switchMap((patient) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(null),
            this._locationClient.location_DeleteGoal(patient.locationId, patient.id, this.patientTreatment.id, goal.id).pipe(share())
          )
        ),
        tap((_) => this.isLoadingCount--),
        take(1)
      )
      .subscribe((_) => {
        const index = this.patientTreatment.goals.findIndex(g => g.id == goal.id);
        this.patientTreatment.goals.splice(index, 1);
        this.setSelectedGoals();
      });
  }

  flattenPatientDiagnoses<T extends DiagnosisDto | DiagnosisStoreEntity>(diagnoses: T[]): T[] {
    if (diagnoses) return diagnoses.reduce((prev, next) => [...prev, next, ...this.flattenPatientDiagnoses(<T[]>next.children)], []);
    else return <T[]>[];
  }
}
