import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as ProcedureGroupStoreActions from './actions';
import * as ProcedureGroupStoreSelectors from './selectors';
import { SettingsClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class ProcedureGroupStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _settingClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ProcedureGroupStoreActions.LoadRequest),
    switchMap((action) =>
      this._settingClient
        .settings_GetProcedureGroups()
        .pipe(
          map((result) => ProcedureGroupStoreActions.LoadSuccess({ procedureGroups: result })),
          catchError((err: HttpErrorResponse) => of(ProcedureGroupStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ProcedureGroupStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(ProcedureGroupStoreSelectors.selectProcedureGroupById(action.id))
        .pipe(map((result) => ProcedureGroupStoreActions.SelectSuccess({ procedureGroup: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ProcedureGroupStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(ProcedureGroupStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ProcedureGroupStoreActions.AddRequest),
    switchMap((action) =>
      this._settingClient.settings_PostProcedureGroup(action.procedureGroup)
        .pipe(
          map((result) => ProcedureGroupStoreActions.AddSuccess({ procedureGroup: result })),
          catchError((err: HttpErrorResponse) => of(ProcedureGroupStoreActions.AddFailure({ error: err.message })))
        ))
  );
}
