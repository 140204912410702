import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { defer, from, Subject } from 'rxjs';
import { catchError, mergeAll, take, takeUntil, toArray } from 'rxjs/operators';
import { FileParameter, FromTypeEnum, PatientClient, StorageContentTypeEnum, TwainDto } from 'src/app/shared/services/api.service';
import { RootStoreState, TwainStoreActions, TwainStoreSelectors } from '../../../../root-store';
import { DownloadService } from '../../../../shared/services/download/download.service';

@Component({
  selector: 'twain-upload-dialog',
  templateUrl: './twain-upload-dialog.component.html',
  styleUrls: ['./twain-upload-dialog.component.scss'],
})
export class TwainUploadDialogComponent implements OnInit, OnDestroy {
  twains$ = this._store$.select(TwainStoreSelectors.selectAllTwain);
  files: File[] = [];
  isWorking: boolean = false;
  selectedTwain: TwainDto;

  private uploadAll$: any;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _downloadService: DownloadService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<TwainUploadDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: TwainUploadDialogData,
    private _patientClient: PatientClient
  ) { }

  generateObservableUploads(files: File[]): Promise<any[]> {
    return new Promise(resolve => {
      let fileUploads = [];
      _.each(files, (file: File) => {
        this.isWorking = true;
        const data = new Blob([file], { type: file.type });
        const fileParameter: FileParameter = {
          data,
          fileName: file.name,
        };
        const fileUplaod = this._patientClient
          .patient_PostFile(
            this.data.patientId,
            this.data.folderId,
            this.data.parentId,
            fileParameter,
            this.data.contentType,
            this.selectedTwain.id,
            FromTypeEnum.Twain);
        fileUploads.push(fileUplaod);
      });
      resolve(fileUploads);
    });
  }

  upload() {
    if (this.files && this.files.length > 0) {
      this.isWorking = true;
      this.generateObservableUploads(this.files).then((promises: any) => {
        const observables = promises.map(a => defer(() => a));
        this.uploadAll$ = from(observables)
          .pipe(
            mergeAll(1),
            toArray()
          )
          .subscribe((res) => {
            this.isWorking = false;
            this._store$.dispatch(TwainStoreActions.LoadRequest({}));
            this._dialogRef.close(res);
          });
      }, (err) => {
        this.isWorking = false;
      });
    }
  }

  fetchTwain(): void {
    this.isWorking = true;
    this._downloadService.blob(this.selectedTwain.locationUrl, this.selectedTwain.name)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        catchError((error) => {
          this._snackBar.open("Invalid Twain.", "Ok", {
            duration: 3000,
          });
          this.isWorking = false;
          return null;
        })
      )
      .subscribe((blob: Blob) => {
        let file = new File(
          [blob],
          this.selectedTwain.name,
          { type: blob.type });

        this.files.push(file);
        this.isWorking = false;
        this.upload();
      })
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.uploadAll$)
      this.uploadAll$.unsubscribe();

    this._destroy$.next(true);
  }
}

export interface TwainUploadDialogData {
  patientId: number;
  folderId: number;
  parentId?: number;
  contentType: StorageContentTypeEnum;
}
