import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { take } from 'rxjs/operators';
import { PatientClient, PatientTreatmentImageGroupDto } from 'src/app/shared/services/api.service';

@Component({
  selector: 'edit-taken-when-dialog',
  templateUrl: './edit-taken-when-dialog.component.html',
  styleUrls: ['./edit-taken-when-dialog.component.scss'],
})
export class EditTakenWhenDialogComponent implements OnInit {
  takenWhen: Date;
  formControlTakenWhen: FormControl;
  isWorking: boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: EditTakenWhenDialogData,
    private _bottomSheetRef: MatBottomSheetRef<EditTakenWhenDialogComponent>,
    private _patientClient: PatientClient,
    private _snackBar: MatSnackBar
  ) {
    this.takenWhen = this._data.currentTakenWhen;
    this.formControlTakenWhen = new FormControl(this.takenWhen);
  }

  ngOnInit() {}

  save() {
    this.isWorking = true;
    this._patientClient
      .patient_EditPatientTreatmentImageGroupTakenWhen(
        this._data.patientId,
        this._data.patientTreatmentImageGroupId,
        this.takenWhen)
      .pipe(take(1))
      .subscribe((result: PatientTreatmentImageGroupDto) => {
        this.isWorking = false;
        this.done(result.id, result.takenWhen);
      }, err => this.isWorking = false);
  }

  done(id: number, takenWhen: Date) {
    this._snackBar.open("Successfully updated taken when.", "Ok", {
      duration: 3000,
    });
    this._bottomSheetRef.dismiss([id, takenWhen]);
  }
}

export interface EditTakenWhenDialogData {
  patientId: number;
  patientTreatmentImageGroupId: number;
  currentTakenWhen: Date
}
