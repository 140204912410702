import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IImageDto, ImageDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientImagingStoreEntity> = createEntityAdapter<PatientImagingStoreEntity>({
  selectId: patientImaging => patientImaging.id,
  sortComparer: (a: PatientImagingStoreEntity, b: PatientImagingStoreEntity): number => b.createdWhen.getTime() - a.createdWhen.getTime()
});

export interface State extends EntityState<PatientImagingStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientImagingId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientImagingId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientImagingStoreEntity extends ImageDto {
  constructor(data?: IImageDto) {
    super(data);
  }
}
