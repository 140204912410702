import { HeaderSection } from "./HeaderSection";

export interface State {
  selectedSection: HeaderSection;
  error: string;
  headerHeight: number;
}

export const initialState: State = {
  selectedSection: null,
  error: null,
  headerHeight: 100
};
