import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import { MessageService } from '../../../../core/message.service';
import { DropdownSharedComponent } from '../dropdown-shared/dropdown-shared.component';
import { RootStoreState, LocationsStoreSelectors } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { fromEvent, pipe, Subject } from 'rxjs';
import { switchMap,debounceTime, map, take, filter, takeUntil} from 'rxjs/operators';

import {
	LocationClient,
	SettingsClient,
	ContactClient,
    ContactPhoneDto2,
	ContactEmailDto2,
	SearchClient,
	SearchContactQueryDto,
	PhoneTypeEnum,
	PolicyDto,
	GenderEnum,
  ContactDto,
  ContactPhoneDto,
  PreferredContactMethodEnum,
  ContactAddressDto2
} from 'src/app/shared/services/api.service';

import { PATIENT_TITLES } from 'src/app/shared/enums/PatientTitleType';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-responsible-party',
	templateUrl: './responsible-party.component.html',
	styleUrls: ['./responsible-party.component.css']
})
export class ResponsiblePartyComponent implements OnInit {
	@ViewChild("Gender", { static: false }) GenderComponent: DropdownSharedComponent;
	@ViewChild("Type", { static: false }) TypeComponent: DropdownSharedComponent;
	@ViewChild("relation_patient", { static: false }) RelationshipToPatientComponent: DropdownSharedComponent;
	@ViewChild("PreferredLang", { static: false }) PreferredLang: DropdownSharedComponent;
	@ViewChild("InsuranceCompany", { static: false }) InsuranceCompany: DropdownSharedComponent;
	@ViewChild("ResponsiblePartyTitle", { static: false }) ResponsiblePartyTitle: ElementRef;
	@ViewChild("RespInsuranceInput", { static: false }) RespInsuranceInput: ElementRef;
	@Input() patientinfo: any;
	@Input() newPratientData: any;
	@Input() responsibleBackup: any;
	@Input() patientId: any;
	@Input() patientContactId: any = '';
	contactId: any = "";
	@Input() selectedLocation: any;
	@Output() backtoparent = new EventEmitter();
	@Output() responsibleBackupval = new EventEmitter();

	emailMask = emailMask;
  storeBackupResposibleVal: any = {};
  savedResponsibleParty: any = {};

	phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	ssnmask: any = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	zipcodeMask: any = [/\d/, /\d/, /\d/, /\d/, /\d/];
	statemask:any = [/[a-zA-Z]/, /[a-zA-Z]/];

	@Output() showNotification_modal = new EventEmitter()

	relationshiparr: any[] = [
		{ "option": "Select Relation...", "value": "", "shortName": "", "colorCode": ""},
		{ "option": "Father", "value": "Father", "shortName": "FA", "colorCode": "#93e3fd" },
		{ "option": "Mother", "value": "Mother", "shortName": "MO", "colorCode": "#ffa7a7" },
		{ "option": "Stepfather", "value": "Stepfather", "shortName": "SF", "colorCode": "#a4ffce" },
		{ "option": "Stepmother", "value": "Stepmother", "shortName": "SM", "colorCode": "#23bf3c" },
		{ "option": "Spouse", "value": "Spouse", "shortName": "SP", "colorCode": "#d2b7ff" },
    { "option": "Partner", "value": "Partner", "shortName": "PR", "colorCode": "#e4d4ff" },
    { "option": "Grandparent", "value": "Grandparent", "shortName": "GP", "colorCode": "#aedcbe" },
		{ "option": "Grandfather", "value": "Grandfather", "shortName": "GF", "colorCode": "#aedcbe" },
		{ "option": "Grandmother", "value": "Grandmother", "shortName": "GM", "colorCode": "#aac2ae" },
		{ "option": "Uncle", "value": "Uncle", "shortName": "UN", "colorCode": "#fbf109" },
		{ "option": "Aunt", "value": "Aunt", "shortName": "AU", "colorCode": "#d3e408" },
		{ "option": "Foster Parent", "value": "Foster Parent", "shortName": "FP", "colorCode": "#f6cc61" },
		{ "option": "Other", "value": "Other", "shortName": "OT", "colorCode": "#a5a5a5" }
	];

	genderArr: any[] = [{ "option": "Gender", "value": "" }, { "option": "Female", "value": "Female" }, { "option": "Male", "value": "Male"  }];
	Gender = GenderEnum;
	typeArr: any[] = [{ "option": "Type" }, { "option": "Mobile" }, { "option": "Home" }, { "option": "Work" }, { "option": "Other" }];

	languageArr: any[] = [{"shortname": "", "option": "Preferred Language" }, {"shortname": "EN", "option": "English" }, {"shortname": "SP", "option": "Spanish" }];

	name_data: any = [
		{ "id": 1, "firstname": "Adam", "lastname": "Carter", "dob": "07/03/1968", "email": "adamcarter@gmail.com", "phone": "(801) 999-9999", "address": "234 W Main Street Salt Lake City, UT 84109", "state": "UT", "city": "Salt Lake City", "zip": "84109" },

		{ "id": 2, "firstname": "Derrick", "lastname": "Adams", "dob": "06/19/1986", "email": "adams.derrick@netsuite.com", "phone": "(801) 555-8888", "address": "1656 Grand ave Draper, UT 84070", "state": "UT", "city": "Draper", "zip": "84070" },

		{ "id": 3, "firstname": "Lydia", "lastname": "Adams", "dob": "06/19/1986", "email": "adams.derrick@netsuite.com", "phone": "(801) 555-8888", "address": "1656 Grand ave Draper, UT 84070", "state": "UT", "city": "Draper", "zip": "84070" },

		{ "id": 5, "firstname": "Greg", "lastname": "Adamson", "dob": "07/03/1972", "email": "gregadamson@yahoo.com", "phone": "(858) 337-8888", "address": "229 W 1060 S Orem, UT 84058", "state": "UT", "city": "Orem", "zip": "84058" },

		{ "id": 5, "firstname": "Dover", "lastname": "Benson", "dob": "08/20/1993", "email": "doverbenson@yahoo.com", "phone": "(858) 337-8888", "address": "229 W 1060 S Orem, UT 84058", "state": "UT", "city": "Orem", "zip": "84058" },

		{ "id": 5, "firstname": "Kate", "lastname": "Dover", "dob": "03/22/1978", "email": "tatedover@yahoo.com", "phone": "(858) 337-8888", "address": "229 W 1060 S Orem, UT 84058", "state": "UT", "city": "Orem", "zip": "84058" },

		{ "id": 5, "firstname": "Mary", "lastname": "Dover", "dob": "07/03/1972", "email": "marydover@yahoo.com", "phone": "(858) 337-8888", "address": "229 W 1060 S Orem, UT 84058", "state": "UT", "city": "Orem", "zip": "84058" },
	];

	searchactive: boolean = false;
	filterPatient: any[] = [];
	showFamilyResult: boolean = false;
	responsiblefname: any;
	responsiblelname: any;
	responsibleemail: any;
	responsiblephone: any;
	responsibledob: any;
	responsibleStreet: any;
	responsibleState: any;
	responsibleCity: any;
	responsibleZip: any;

	sameaddr: boolean = false;
	policyholder: boolean = false;
	searchParty: any;

	patientInfo: any = {
		"fname": "",
		"mname": "",
		"lname": "",
		"prefferedname": "",
		"birthday": "",
		"ssn": "",
		"email": "",
		"cellphone": "",
		"homephone": "",
		"street": "",
		"city": "",
		"state": "",
		"zip": "",
		"employer": ""
	}

	showConfirmation: boolean = false;
	errorMessage: any;
	responsiblePartyFormGroup: FormGroup;
	patientResponsible: boolean = false;
	patientInsuranceHolder: boolean = false;
	open: any = 'yes';

	patient:any = {};
	patientResp:any[] = [];
	@Input() _patientRespIndx: any = 0;
	patientRespIndx:any = 0;
	searchEnable: boolean = false;

	contactETag: any = '';

	insuranceCompArr: any[] = [];
	nonPatientData: any = {};
	isPatient: boolean = false;
	existingRespParty:boolean = false;
	formValueChanged:boolean = false;

	@Output() getRespPartyNumber = new EventEmitter();
	@Input() addNewResp:boolean = false;
	@Input() multipleResp:boolean = false;
	addnewRespClicked:boolean = false;

	showCloseBtn:boolean = true;
	searchExistingPatientArr: any[] = [];
	isExistingPatient:boolean = false;
	isSearchLoading:boolean = false;
	selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
	titleArr: { name: string;}[] = Object.entries(PATIENT_TITLES).map(([key, value]) => ({
		name: value.name,
	  }));

  filterTitleArr: any[];
  isLoading: boolean;
  filterInsuranceArr: any[]=[];
  insuranceSortType:any = 'asc';
  patientPolicies: PolicyDto[] = [];
  showInsuranceSection:boolean = true;
  showAddPolicyBtn:boolean = true;
  selectedPolicy:PolicyDto;
  maxEffeiveDate;
  searchInput$: Subject<string> = new Subject<string>();
  private _destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private formBuilder: FormBuilder,
		private locationClient: LocationClient,
		private settingsClient: SettingsClient,
		private _contactClient: ContactClient,
		private _searchClient: SearchClient,
		private _store$: Store<RootStoreState.State>
	) { }

	ngOnInit() {
		this.maxEffeiveDate = new Date();
		this.newPratientData.responsibleParty = [];
		this.initializeResponsiblePartyform();
		if (this.responsibleBackup && this.responsibleBackup.storeBackup) {
      this.responsiblePartyFormGroup.patchValue({
        firstName: this.responsibleBackup.storeBackup.responsiblefname,
        middleName: this.responsibleBackup.storeBackup.responsiblemiddlename,
        lastName: this.responsibleBackup.storeBackup.responsiblelastname,
        dob: this.responsibleBackup.storeBackup.responsibledob,
        employerName: this.responsibleBackup.storeBackup.responsibleEmp,
        ssn: this.responsibleBackup.storeBackup.responsibleSsn,
        primaryAddress: {
          addressType: 'Home',
          addressLine1: this.responsibleBackup.storeBackup.responsibleStreet,
          city: this.responsibleBackup.storeBackup.responsibleCity,
          state: this.responsibleBackup.storeBackup.responsibleState,
          zip: this.responsibleBackup.storeBackup.responsibleZip
        },
        mobilePhone: {
          isPrimary: false,
          phoneType: 'Mobile',
          number: this.responsibleBackup.storeBackup.responsiblephone
        },
        alternatePhone1: {
          isPrimary: false,
          phoneType: 'Home',
          number: this.responsibleBackup.storeBackup.responsibleHomeNu
        },
        primaryEmail: {
          emailType: 'Personal',
          emailAddress: this.responsibleBackup.storeBackup.responsibleemail
        },
        preferredContactMethod: this.responsibleBackup.storeBackup.responsiblePreferred
      });

			this.sameaddr = this.responsibleBackup.sameaddr;
			this.policyholder = this.responsibleBackup.policyholder;
			if (this.policyholder == true) {
				this.patientInsuranceHolder = true;
			}
			this.patientResponsible = this.responsibleBackup.patientResponsible;
		}

		this.getInsurance();
		this.getLanguage();

		this.patientRespIndx = JSON.parse(JSON.stringify(this._patientRespIndx))


		if(this.contactId != '' || this.contactId != null || this.contactId != undefined){
			if(this.addNewResp == false){
				this.getPatientData(this.contactId, false)
			}


		} else {

		}

    this.searchInput$.pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
    ).subscribe((search) => {
      this.searchRespInsurance(search)
    });
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.titleAutoComplete();
		}, 500);
	}

  ngOnDestroy(): void {
    this._destroy$.next(true);
  }

	initializeResponsiblePartyform() {
		this.responsiblePartyFormGroup = this.formBuilder.group({

      id: [],
      patientId: [],
      contactId: [],
      relationshipType: [null, Validators.required],
      title: [],
      firstName: [null, Validators.required],
      middleName: [],
      lastName: [null, Validators.required],
      nickname: [],
      languageKey: ['en', Validators.required],
      gender: [GenderEnum.Unknown],
      dob: [],
      ssn: [],
      preferredContactMethod: [PreferredContactMethodEnum.Phone],
      primaryAddress: this.formBuilder.group({
        id: [],
        addressType: ['Home', Validators.required],
        addressLine1: [],
        addressLine2: [],
        city: [],
        state: [null, Validators.maxLength(2)],
        zip: [null, Validators.maxLength(10)],
        eTag: [],
      }),
      mobilePhone: this.formBuilder.group({
        id: [],
        isPrimary: false,
        phoneType: [],
        number: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
        extension: [],
        eTag: [],
      }),
      alternatePhone1: this.formBuilder.group({
        id: [],
        isPrimary: false,
        phoneType: [],
        number: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
        extension: [],
        eTag: [],
      }),
      alternatePhone2: this.formBuilder.group({
        id: [],
        isPrimary: false,
        phoneType: [],
        number: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
        extension: [],
        eTag: [],
      }),
      primaryEmail: this.formBuilder.group({
        id: [],
        emailType: ['Personal'],
        emailAddress: [null, Validators.email],
        eTag: [],
      }),
      payorId: [],
      effectivedate: [null],
      insuranceCompany: [],
      insuranceCompanyId: [],
      employerId: [],
      employerName: [],
      groupPolicyNumber: [],
      individualPolicyNumber: [],
      comments: [],
      employeeId: [],
      licenseId: [],
      isSecureAccess: [],
      contactETag: [],
      eTag: [],
      insuranceNotes: []
    });

		this.responsiblePartyFormGroup.valueChanges.subscribe(data => {
			this.formValueChanged = true;
		});

		this.selectedLocation$.pipe().subscribe(res => {
			if (res != undefined) {
				//console.log("location", res);
				this.responsiblePartyFormGroup.patchValue({
          primaryAddress: {
            city: res.address.city,
            state: res.address.state
          }
				})
			}
		});

		this.filterTitleArr = this.titleArr;
	}

	onSubmitResponsiblePartyform(respPartyNew) {
		if (this.responsiblePartyFormGroup.invalid) {

			this.showConfirmation = true;

			if (this.responsiblePartyFormGroup.controls.firstName.value == '' || this.responsiblePartyFormGroup.controls.lastName.value == null) {
				this.errorMessage = 'Ooops! \n' +
					'add a first name.\n';

			} else if (this.responsiblePartyFormGroup.controls.lastName.value == '' || this.responsiblePartyFormGroup.controls.lastName.value == null) {
				this.errorMessage = 'Ooops! \n' +
					'add a last name.\n';

			} else if(this.responsiblePartyFormGroup.controls.primaryEmail.invalid){
					this.errorMessage = 'Ooops! \n' +
						'Please enter an valid email address\n';
			} else if(this.responsiblePartyFormGroup.controls.mobilePhone.invalid){
				this.errorMessage = 'Ooops! \n' +
						'Please enter a valid cell number\n';

			} else if(this.responsiblePartyFormGroup.controls.relationshipType.value == '' || this.responsiblePartyFormGroup.controls.relationshipType.value == null ){
				this.errorMessage = 'Ooops! \n' +
					'Please select relation with this patient\n';
			} else {
					this.showConfirmation = false;

					if(this.formValueChanged == true){
						this.submitRespParty(respPartyNew)
					} else {
						if(respPartyNew == true){
							this.addnewRespClicked = true
							this.getRespPartyNumber.emit({"type": "addnew", "numbr": 1});

						} else {
							this.showNotification();
						}

					}
			}


			return;
		} else {

			if(this.responsiblePartyFormGroup.controls.primaryEmail.invalid){
				this.showConfirmation = true;
				this.errorMessage = 'Ooops! \n' +
						'Please enter an valid email address\n';

			} else if(this.responsiblePartyFormGroup.controls.mobilePhone.invalid){
				this.showConfirmation = true;
				this.errorMessage = 'Ooops! \n' +
						'Please enter a valid cell number\n';
			} else {

				if(this.formValueChanged == true){
					this.submitRespParty(respPartyNew)
				} else {
					if(respPartyNew == true){
						this.addnewRespClicked = true
						this.getRespPartyNumber.emit({"type": "addnew", "numbr": 1});

					} else {
						this.showNotification();
					}
				}
			}
		}
	}

	changePolicyholder_chk(evt) {
		this.policyholder = evt.target.checked;

		if (this.policyholder) {
			this.patientInsuranceHolder = true;
			this.responsiblefname = this.patientinfo.fname;
			this.responsiblelname = this.patientinfo.lname;
			this.responsibleemail = this.patientinfo.email;
			this.responsiblephone = this.patientinfo.cellphone;
			this.responsibledob = this.patientinfo.birthday;
			this.responsibleStreet = this.patientinfo.street;
			this.responsibleState = this.patientinfo.state;
			this.responsibleCity = this.patientinfo.city;
			this.responsibleZip = this.patientinfo.zip;
		} else {
			this.patientInsuranceHolder = false;
			if (this.searchParty) {
				this.responsiblefname = this.searchParty.firstname;
				this.responsiblelname = this.searchParty.lastname;
				this.responsibleemail = this.searchParty.email;
				this.responsiblephone = this.searchParty.phone;
				this.responsibledob = this.searchParty.dob;
				this.responsibleStreet = this.searchParty.address;
				this.responsibleState = this.searchParty.state;
				this.responsibleCity = this.searchParty.city;
				this.responsibleZip = this.searchParty.zip;
			} else {
				this.responsiblefname = "";
				this.responsiblelname = "";
				this.responsibleemail = "";
				this.responsiblephone = "";
				this.responsibledob = "";
				this.responsibleStreet = "";
				this.responsibleState = "";
				this.responsibleCity = "";
				this.responsibleZip = "";
			}
		}


	}

	changeSameaddr_chk() {
		this.sameaddr = this.sameaddr == true ? false : true;

		if (this.sameaddr || this.searchParty) {
      this.responsiblePartyFormGroup.patchValue({
        primaryAddress: {
          addressLine1: this.newPratientData ? this.newPratientData.patientStreetAd : null,
          city: this.newPratientData ? this.newPratientData.patientCity : null,
          state: this.newPratientData ? this.newPratientData.patientState : null,
          zip: this.newPratientData ? this.newPratientData.patientZipCode : null
        }
      });
		} else {
			this.responsiblePartyFormGroup.patchValue({
        primaryAddress: {
          addressLine1: null,
          city: null,
          state: null,
          zip: null
        }
      });
		}
	}

	AddNewResponsible() {
		this.onSubmitResponsiblePartyform(true);
	}

	dateMask() {
		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
		return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
	}

	enableSearchOption() {
		this.searchEnable = this.searchEnable == false ? true : false;
	}


	activeSearchPanel() {
		this.searchactive = true;
	}

	searchResponsible(_searchStr) {
		let searchStr: any = _searchStr.toLowerCase();
		this.filterPatient = [];
		this.showFamilyResult = false;

		if (searchStr.length > 1) {
			this.filterPatient = this.name_data.filter(item => {
				return (item.firstname.toLowerCase().includes(searchStr) || item.lastname.toLowerCase().includes(searchStr));
			});

			if (this.filterPatient.length == 0) {
				this.filterPatient.push({ "firstname": "No patient found!" })
			}
			this.showFamilyResult = true;
		}
	}

	selectResponsible(item) {
		this.searchParty = item;

    this.responsiblePartyFormGroup.patchValue({
      firstName: this.searchParty ? this.searchParty.firstname : null,
      lastName: this.searchParty ? this.searchParty.lastname : null,
      dob: this.searchParty ? this.searchParty.dob : null,
      primaryAddress: {
        addressLine1: this.searchParty ? this.searchParty.address : null,
        city: this.searchParty ? this.searchParty.city : null,
        state: this.searchParty ? this.searchParty.state : null,
        zip: this.searchParty ? this.searchParty.zip : null
      },
      mobilePhone: {
        phoneType: 'Mobile',
        number: this.searchParty ? this.searchParty.phone : null
      },
      primaryEmail: {
        emailType: 'Personal',
        emailAddress: this.searchParty ? this.searchParty.email : null
      }
    });

		this.showFamilyResult = false;
		this.searchactive = false;
	}


	btnAction(action) {
		if (action == 'back') {
			this.showConfirmation = false;
		}
	}

	checkPatientResponsible() {
		this.patientResponsible = this.patientResponsible == false ? true : false;
		if (this.patientResponsible == true) {
			this.patientInsuranceHolder = true;

      this.responsiblePartyFormGroup.patchValue({
        firstName: this.newPratientData ? this.newPratientData.patientFirstName : null,
        middleName: this.newPratientData ? this.newPratientData.patientMiddleName : null,
        lastName: this.newPratientData ? this.newPratientData.patientLastName : null,
        dob: this.newPratientData ? this.newPratientData.patientdob : null,
        ssn: this.newPratientData ? this.newPratientData.patientSsn : null,
        preferredContactMethod: this.newPratientData ? this.newPratientData.patientPreferred : null,
        primaryAddress: {
          addressLine1: this.newPratientData ? this.newPratientData.patientStreetAd : null,
          city: this.newPratientData ? this.newPratientData.patientCity : null,
          state: this.newPratientData ? this.newPratientData.patientState : null,
          zip: this.newPratientData ? this.newPratientData.patientZipCode : null
        },
        mobilePhone: {
          phoneType: 'Mobile',
          number: this.newPratientData ? this.newPratientData.patientCellPhone : null
        },
        alternatePhone1: {
          phoneType: 'Home',
          number: this.newPratientData ? this.newPratientData.patientHomePh : null
        },
        primaryEmail: {
          emailType: 'Personal',
          emailAddress: this.newPratientData ? this.newPratientData.patientEmail : null
        },
        employerName: this.newPratientData ? this.newPratientData.patientEmp : null
      });

			this.sameaddr = true;
			this.policyholder = true;

		} else {

			this.responsiblePartyFormGroup.patchValue({
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        ssn: null,
        preferredContactMethod: null,
        primaryAddress: {
          addressLine1: null,
          city: null,
          state: null,
          zip: null
        },
        mobilePhone: {
          phoneType: 'Other',
          number: null
        },
        alternatePhone1: {
          phoneType: 'Other',
          number: null
        },
        primaryEmail: {
          emailType: 'Other',
          emailAddress: null
        },
        employerName: null
      });

			this.sameaddr = false;
			this.policyholder = false;
		}
	}

	getLanguage(){
		let defaultLang: any = "EN";

		this.responsiblePartyFormGroup.patchValue({
			  "languageKey": defaultLang
		  })
	}

	getSelectedLang(elem){

		if(elem.shortname != ''){

		  this.responsiblePartyFormGroup.patchValue({
			  "languageKey": elem.shortname
		  })
		} else {
			this.responsiblePartyFormGroup.patchValue({
			  "languageKey": 'EN'
		  })
		}
	  }

	RelationshiptoPatient(evt) {

		if (this.isPatient == false) {
			this.nonPatientData = this.responsiblePartyFormGroup.value;
		}

		if (evt.option != 'Select Relation...') {
			this.responsiblePartyFormGroup.patchValue({
				'relationshipType': evt.option
			});

			if(evt.option == 'Patient'){
				this.isPatient = true;
				this.updateRespPartyForm(this.newPratientData, true);
			} else {
				this.isPatient = false;
				this.updateRespPartyForm(this.nonPatientData, false);

			}
		} else {
			this.responsiblePartyFormGroup.patchValue({
        relationshipType: ''
      });
		}


	}

	ResponsibleGender(evt) {

		if(evt.option != 'Gender'){
			this.responsiblePartyFormGroup.patchValue({
        gender: evt.option
      });
		} else {
			this.responsiblePartyFormGroup.patchValue({
        gender: ''
      });
		}
	}

	PhoneType(evt) {
		if(evt.option != 'Type'){
			this.responsiblePartyFormGroup.patchValue({
        phoneType: evt.option
      });
		} else {
			this.responsiblePartyFormGroup.patchValue({
        alternatePhone1: {
          phoneType: 'Other'
        }
      });
		}

	}

	showNotification() {
		this.showNotification_modal.emit(true);
	}

	cancel() {

		if(this.patientRespIndx > 0){
			this.patientRespIndx--;
			this.getSingleRelationship();
		} else {
			this.backtoparent.emit(this.patientId);
		}
	}




	updateRespPartyForm(respData, _isPatient){

		if(_isPatient == true && this.existingRespParty == false){

			this._contactClient.contact_GetContact(this.patientContactId, null).subscribe(
				(res) => {
					let _resp: any = JSON.parse(JSON.stringify(res));
					this.contactETag = _resp.eTag;
					this.contactId = _resp.id;
				},

				(err) => {
					console.log(err)
				}
			)

      this.responsiblePartyFormGroup.patchValue({
        firstName: respData ? respData.patientFirstName : null,
        middleName: respData ? respData.patientMiddleName : null,
        lastName: respData ? respData.patientLastName : null,
        dob: respData ? respData.patientdob : null,
        ssn: respData ? respData.patientSsn : null,
        preferredContactMethod: respData ? respData.patientPreferred : null,
        primaryAddress: {
          addressLine1: respData ? respData.patientStreetAd : null,
          city: respData ? respData.patientCity : null,
          state: respData ? respData.patientState : null,
          zip: respData ? respData.patientZipCode : null
        },
        mobilePhone: {
          phoneType: 'Mobile',
          number: respData ? respData.patientCellPhone : null
        },
        alternatePhone1: {
          phoneType: respData.patientAltPhType,
          number: respData ? respData.patientHomePh : null
        },
        primaryEmail: {
          emailType: 'Personal',
          emailAddress: respData ? respData.patientEmail : null
        },
        employerName: respData ? respData.patientEmp : null
      });

		} else {

			if(this.existingRespParty == false){

				this.isPatient = false;
				this.contactETag = '';
				this.contactId = '';
			} else {
				this.existingRespParty = false;
			}
		}
	}

	getPatientData(contactId, search){


		this.locationClient.location_GetPatientRelationships(this.selectedLocation.id, this.patientId, undefined, undefined, null).subscribe(
      (res) => {
        res = _.sortBy(res, r => {
          return r.relationshipTypeCode == 'PA' ? 0 : 1;
        });

				this.patientResp = JSON.parse(JSON.stringify(res));

				if(this.patientResp.length > 0){

					if(this.patientResp[0].mobilePhone || this.patientResp[0].primaryEmail ||  this.patientResp[0].alternatePhone){
						if(!this.multipleResp) {
							this.getRespPartyNumber.emit({"type": "existing", "numbr": this.patientResp.length});

						}
						this.getSingleRelationship();

					} else {
						if(this.patientResp.length == 1){
							this.addnewRespClicked = true;
							this.getRespPartyNumber.emit({"type": "noresp", "numbr": 1});

						} else {
							if(!this.multipleResp) {
								this.getRespPartyNumber.emit({"type": "existing", "numbr": this.patientResp.length-1});
							}
							this.getSingleRelationship();
						}
					}



				} else {

					this.addnewRespClicked = true;
					this.getRespPartyNumber.emit({"type": "noresp", "numbr": 1});

				}


			},
			(err) => {
				console.log(err)
			}
		)
	}

	getSingleRelationship() {
		this.existingRespParty = true;
		this.showCloseBtn = false;

		if (this.patientRespIndx < this.patientResp.length) {
			let _patientResp: any = this.patientResp[this.patientRespIndx];
			this._contactClient.contact_GetPolicies(_patientResp.contactId).pipe(take(1)).subscribe(resp => {

				this.patientPolicies = resp;

				if(this.patientPolicies.length > 0){
					this.showInsuranceSection = false;
				} else {
					this.showInsuranceSection = true;
				}

			})

			let contactDOB: any;
			if (_patientResp.dob != undefined || _patientResp.dob != null) {
				contactDOB = moment(_patientResp.dob).tz('UTC').format('MM/DD/YYYY');
			} else {
				contactDOB = '';
			}


			let addr: any = "";
			let city: any = "";
			let state: any = "";
			let zip: any = "";

			if (_patientResp.primaryAddress) {
				addr = _patientResp.primaryAddress.addressLine1;
				city = _patientResp.primaryAddress.city;
				state = _patientResp.primaryAddress.state;
				zip = _patientResp.primaryAddress.zip;
			}

			let insuranceNotes: any = '';
			if (_patientResp.insuranceNotes) {
				insuranceNotes = _patientResp.insuranceNotes;
			}

			let email: any = '';
			if (_patientResp.primaryEmail && _patientResp.primaryEmail.emailAddress) {
				email = _patientResp.primaryEmail.emailAddress;
			}

			let mobilePhone: any = '';
			if (_patientResp.mobilePhone && _patientResp.mobilePhone) {
				mobilePhone = _patientResp.mobilePhone.number
			}

			let alternatePhone: any = '';
			if (_patientResp.alternatePhone && _patientResp.alternatePhone.number) {
				alternatePhone = _patientResp.alternatePhone.number;
			}

      this.responsiblePartyFormGroup.patchValue({
        relationshipType: _patientResp ? _patientResp.relationshipType : null,
        title: _patientResp ? _patientResp.title : null,
        firstName: _patientResp ? _patientResp.firstName : null,
        middleName: _patientResp ? _patientResp.middleName : null,
        lastName: _patientResp ? _patientResp.lastName : null,
        nickname: _patientResp ? _patientResp.nickname : null,
        dob: contactDOB,
        ssn: _patientResp ? _patientResp.ssn : null,
        gender: _patientResp ? _patientResp.gender : null,
        languageKey: _patientResp ? _patientResp.languageKey : null,
        primaryAddress: {
          addressLine1: addr,
          city: city,
          state: state,
          zip: zip
        },
        mobilePhone: {
          phoneType: 'Mobile',
          number: mobilePhone
        },
        alternatePhone1: {
          phoneType: _patientResp.phoneType,
          number: alternatePhone
        },
        primaryEmail: {
          emailType: 'Personal',
          emailAddress: email
        },
        insuranceCompanyId: _patientResp ? _patientResp.insuranceCompanyId : null,
        employerId: _patientResp ? _patientResp.employerId : null,
        employerName: _patientResp ? _patientResp.employerName : null,
        groupPolicyNumber: _patientResp ? _patientResp.groupPolicyNumber : null,
        individualPolicyNumber: _patientResp ? _patientResp.individualPolicyNumber : null,
        comments: _patientResp ? _patientResp.comments : "",
        employeeId: _patientResp ? _patientResp.employerId : null,
        insuranceNotes: insuranceNotes
      });

			setTimeout(() => {
				this.formValueChanged = false;
			}, 500)
		}
	}

	submitRespParty(respPartyNew) {
    this.onSubmitRespParty(respPartyNew);
	}

  onSubmitRespParty(respPartyNew){
    if (this.patientResp.length > 1 && this.addNewResp == false) {
      let primaryAddress: any = {};

      if (this.patientResp[this.patientRespIndx].primaryAddress) {
        primaryAddress = {
          "id": this.patientResp[this.patientRespIndx].primaryAddress.id,
          "eTag": this.patientResp[this.patientRespIndx].primaryAddress.eTag,
          "addressLine1": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.addressLine1'),
          "city": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.city'),
          "state": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.state'),
          "zip": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.zip')
        }
      } else {
        primaryAddress = {
          "addressLine1": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.addressLine1'),
          "city": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.city'),
          "state": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.state'),
          "zip": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.zip')
        }
      }


      let mobilePhone;
      if (this.patientResp[this.patientRespIndx].mobilePhone) {
        mobilePhone = {
          "id": this.patientResp[this.patientRespIndx].mobilePhone.id,
          "eTag": this.patientResp[this.patientRespIndx].mobilePhone.eTag,
          "number": this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.number')
        }
      }

      let phoneType: any = this.getFormValue(this.responsiblePartyFormGroup, 'phoneType');
      if (phoneType == 'Type' || phoneType == '') {
        phoneType = 'Other';
      }

      let alternatePhone;
      if (this.patientResp[this.patientRespIndx].alternatePhone) {
        alternatePhone = {
          "id": this.patientResp[this.patientRespIndx].alternatePhone.id,
          "eTag": this.patientResp[this.patientRespIndx].alternatePhone.eTag,
          "phoneType": phoneType,
          "number": this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.number')
        }
      }

      let primaryEmail: any = {};
      if (this.patientResp[this.patientRespIndx].primaryEmail) {
        primaryEmail = {
          "id": this.patientResp[this.patientRespIndx].primaryEmail.id,
          "eTag": this.patientResp[this.patientRespIndx].primaryEmail.eTag,
          "emailAddress": this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.emailAddress')
        }
      } else {
        primaryEmail = {
          "emailAddress": this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.emailAddress')
        }
      }

      let respGender: any = this.getFormValue(this.responsiblePartyFormGroup, 'gender');
      if (respGender == "") {
        respGender = null;
      }

      let respPartyModel: any = {
        "id": this.patientResp[this.patientRespIndx].id,
        "patientId": this.patientId,
        "contactId": this.patientResp[this.patientRespIndx].contactId,
        "relationshipType": this.getFormValue(this.responsiblePartyFormGroup, 'relationshipType'),
        "firstName": this.getFormValue(this.responsiblePartyFormGroup, 'firstName'),
        "middleName": this.getFormValue(this.responsiblePartyFormGroup, 'middleName'),
        "lastName": this.getFormValue(this.responsiblePartyFormGroup, 'lastName'),
        "nickname": this.getFormValue(this.responsiblePartyFormGroup, 'nickname'),
        "gender": respGender,
        "dob": this.getFormValue(this.responsiblePartyFormGroup, 'dob'),
        "ssn": this.getFormValue(this.responsiblePartyFormGroup, 'ssn'),
        "preferredContactMethod": "Email",

        "primaryAddress": primaryAddress,

        "mobilePhone": mobilePhone,

        "alternatePhone1": alternatePhone,

        "primaryEmail": primaryEmail,

        "insuranceCompanyId": this.getFormValue(this.responsiblePartyFormGroup, 'insuranceCompanyId'),
        "employerId": this.getFormValue(this.responsiblePartyFormGroup, 'employerId'),
        "employerName": this.getFormValue(this.responsiblePartyFormGroup, 'employerName'),
        "groupPolicyNumber": this.getFormValue(this.responsiblePartyFormGroup, 'groupPolicyNumber'),
        "individualPolicyNumber": this.getFormValue(this.responsiblePartyFormGroup, 'individualPolicyNumber'),
        "insuranceNotes": this.getFormValue(this.responsiblePartyFormGroup, 'insuranceNotes'),
        "comments": this.getFormValue(this.responsiblePartyFormGroup, 'comments'),
        "contactETag": this.patientResp[this.patientRespIndx].contactETag,
        "eTag": this.patientResp[this.patientRespIndx].eTag,
        "languageKey": this.getFormValue(this.responsiblePartyFormGroup, 'languageKey'),
        "title": this.getFormValue(this.responsiblePartyFormGroup, 'title')

      }

      this.isLoading = true;
      this.populateInsuranceDataToComment(respPartyModel, this.responsiblePartyFormGroup.value);
      this.locationClient.location_PutRelationship(this.selectedLocation.id, this.patientId, this.patientResp[this.patientRespIndx].id, respPartyModel, null, this.patientResp[this.patientRespIndx].eTag)
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe(
        (res) => {
          if(res.contactId){
            this.addNewPolicy(res.contactId);
          }
          if (respPartyNew == true) {
            this.addnewRespClicked = true
            this.getRespPartyNumber.emit({ "type": "addnew", "numbr": 1 });
          } else {
            this.showNotification();
          }
          this.isLoading = false;
        },
        (err) => {
          console.log(err)
        }
      )

    } else {

      if (this.patientResp.length == 1 || this.addNewResp == true) {

        let phoneType: any = this.getFormValue(this.responsiblePartyFormGroup, 'phoneType');
        if (phoneType && (phoneType.value == 'Type' || phoneType.value == '')) {
          phoneType = 'Other';
        }

        let respGender: any = this.getFormValue(this.responsiblePartyFormGroup, 'gender');
        if (respGender && respGender.value == "") {
          respGender = null;
        }

        if (this.savedResponsibleParty && this.savedResponsibleParty.id > 0) {
          this.savedResponsibleParty.relationshipType = this.getFormValue(this.responsiblePartyFormGroup, 'relationshipType');
          this.savedResponsibleParty.firstName = this.getFormValue(this.responsiblePartyFormGroup, 'firstName');
          this.savedResponsibleParty.middleName = this.getFormValue(this.responsiblePartyFormGroup, 'middleName');
          this.savedResponsibleParty.lastName = this.getFormValue(this.responsiblePartyFormGroup, 'lastName');
          this.savedResponsibleParty.nickname = this.getFormValue(this.responsiblePartyFormGroup, 'nickname');
          this.savedResponsibleParty.gender = respGender;
          this.savedResponsibleParty.dob = this.getFormValue(this.responsiblePartyFormGroup, 'dob');
          this.savedResponsibleParty.ssn = this.getFormValue(this.responsiblePartyFormGroup, 'ssn');

          if (this.savedResponsibleParty.primaryAddress == null) this.savedResponsibleParty.primaryAddress = new ContactAddressDto2();
          this.savedResponsibleParty.primaryAddress.id = this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.id');
          this.savedResponsibleParty.primaryAddress.eTag = this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.eTag');
          this.savedResponsibleParty.primaryAddress.addressLine1 = this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.addressLine1');
          this.savedResponsibleParty.primaryAddress.city = this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.city');
          this.savedResponsibleParty.primaryAddress.state = this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.state');
          this.savedResponsibleParty.primaryAddress.zip = this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.zip');

          if (this.savedResponsibleParty.mobilePhone == null) this.savedResponsibleParty.mobilePhone = new ContactPhoneDto2();
          this.savedResponsibleParty.mobilePhone.id = this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.id');
          this.savedResponsibleParty.mobilePhone.eTag = this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.eTag');
          this.savedResponsibleParty.mobilePhone.number = this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.number');

          if (this.savedResponsibleParty.alternatePhone1 == null) this.savedResponsibleParty.alternatePhone1 = new ContactPhoneDto2();
          this.savedResponsibleParty.alternatePhone1.phoneType = phoneType;
          this.savedResponsibleParty.alternatePhone1.id = this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.id');
          this.savedResponsibleParty.alternatePhone1.eTag = this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.eTag');
          this.savedResponsibleParty.alternatePhone1.number = this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.number');

          if (this.savedResponsibleParty.primaryEmail == null) this.savedResponsibleParty.primaryEmail = new ContactEmailDto2();
          this.savedResponsibleParty.primaryEmail.id = this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.id');
          this.savedResponsibleParty.primaryEmail.eTag = this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.eTag');
          this.savedResponsibleParty.primaryEmail.emailAddress = this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.emailAddress');

          this.savedResponsibleParty.insuranceCompany = this.getFormValue(this.responsiblePartyFormGroup, 'insuranceCompany');
          this.savedResponsibleParty.employerId = this.getFormValue(this.responsiblePartyFormGroup, 'employerId');
          this.savedResponsibleParty.employerName = this.getFormValue(this.responsiblePartyFormGroup, 'employerName');
          this.savedResponsibleParty.groupPolicyNumber = this.getFormValue(this.responsiblePartyFormGroup, 'groupPolicyNumber');
          this.savedResponsibleParty.comments = this.getFormValue(this.responsiblePartyFormGroup, 'comments');
          this.savedResponsibleParty.insuranceNotes = this.getFormValue(this.responsiblePartyFormGroup, 'insuranceNotes');
          this.savedResponsibleParty.languageKey = this.getFormValue(this.responsiblePartyFormGroup, 'languageKey');
          this.savedResponsibleParty.title = this.getFormValue(this.responsiblePartyFormGroup, 'title');
          this.savedResponsibleParty.individualPolicyNumber = this.getFormValue(this.responsiblePartyFormGroup, 'individualPolicyNumber');

          this.isLoading = true;
          this.locationClient
            .location_PutRelationship(
              this.selectedLocation.id,
              this.savedResponsibleParty.patientId,
              this.savedResponsibleParty.id,
              this.savedResponsibleParty,
              null,
              this.savedResponsibleParty.eTag)
            .pipe(
              take(1),
              takeUntil(this._destroy$)
            )
            .subscribe((res) => {
              this.savedResponsibleParty = res;
              if (respPartyNew == true) {
                this.addnewRespClicked = true
                this.getRespPartyNumber.emit({ "type": "addnew", "numbr": 1 });
              } else {
                this.showNotification();
              }
              this.isLoading = false;
            },
              (err) => {
                console.log(err);
              }
            )
        }
        else {
          let respPartyModel: any = {
            "patientId": this.patientId,
            "contactId": this.getFormValue(this.responsiblePartyFormGroup, 'contactId'),
            "relationshipType": this.getFormValue(this.responsiblePartyFormGroup, 'relationshipType'),
            "firstName": this.getFormValue(this.responsiblePartyFormGroup, 'firstName'),
            "middleName": this.getFormValue(this.responsiblePartyFormGroup, 'middleName'),
            "lastName": this.getFormValue(this.responsiblePartyFormGroup, 'lastName'),
            "nickname": this.getFormValue(this.responsiblePartyFormGroup, 'nickname'),
            "gender": respGender,
            "dob": this.getFormValue(this.responsiblePartyFormGroup, 'dob'),
            "ssn": this.getFormValue(this.responsiblePartyFormGroup, 'ssn'),
            "preferredContactMethod": "Email",
            "primaryAddress": {
              "id": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.id'),
              "eTag": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.eTag'),
              "addressLine1": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.addressLine1'),
              "city": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.city'),
              "state": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.state'),
              "zip": this.getFormValue(this.responsiblePartyFormGroup, 'primaryAddress.zip')
            },

            "mobilePhone": this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.number') ? {
              "phoneType": PhoneTypeEnum.Mobile,
              "id": this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.id'),
              "eTag": this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.eTag'),
              "number": this.getFormValue(this.responsiblePartyFormGroup, 'mobilePhone.number')
            } : null,

            "alternatePhone1": this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.number') ? {
              "phoneType": phoneType,
              "id": this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.id'),
              "eTag": this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.eTag'),
              "number": this.getFormValue(this.responsiblePartyFormGroup, 'alternatePhone1.number')
            } : null,
            "primaryEmail": {
              "id": this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.id'),
              "eTag": this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.eTag'),
              "emailAddress": this.getFormValue(this.responsiblePartyFormGroup, 'primaryEmail.emailAddress'),
            },
            "insuranceCompanyId": this.getFormValue(this.responsiblePartyFormGroup, 'insuranceCompanyId'),
            "employerId": this.getFormValue(this.responsiblePartyFormGroup, 'employerId'),
            "employerName": this.getFormValue(this.responsiblePartyFormGroup, 'employerName'),
            "groupPolicyNumber": this.getFormValue(this.responsiblePartyFormGroup, 'groupPolicyNumber'),
            "individualPolicyNumber": this.getFormValue(this.responsiblePartyFormGroup, 'individualPolicyNumber'),
            "insuranceNotes": this.getFormValue(this.responsiblePartyFormGroup, 'insuranceNotes'),
            "comments": this.getFormValue(this.responsiblePartyFormGroup, 'comments'),
            "contactETag": this.getFormValue(this.responsiblePartyFormGroup, 'contactETag'),
            "languageKey": this.getFormValue(this.responsiblePartyFormGroup, 'languageKey'),
            "title": this.getFormValue(this.responsiblePartyFormGroup, 'title')
          }

          //this.isLoading = true;
          this.populateInsuranceDataToComment(respPartyModel, this.responsiblePartyFormGroup.value);
          this.locationClient.location_PostRelationship(this.selectedLocation.id, this.patientId, respPartyModel, null)
            .pipe(
              take(1),
              takeUntil(this._destroy$)
          )
            .subscribe(
            (res) => {
              if(res.contactId){
                this.addNewPolicy(res.contactId);
              }
              this.savedResponsibleParty = res;
              if (respPartyNew == true) {
                this.addnewRespClicked = true
                this.getRespPartyNumber.emit({ "type": "addnew", "numbr": 1 });
              } else {
                this.showNotification();
              }
              this.isLoading = false;
            },
            (err) => {
              console.log(err);
            }
          )
        }
      }
    }
  }

  private getFormValue(formGroup: FormGroup, fieldName: string){
    if (!formGroup.get(fieldName)) {
      return null;
    }

    return formGroup.get(fieldName).value;
  }

  private populateInsuranceDataToComment(model: any, formValue: any): void {
    if (!formValue || !model) return;

    model.insuranceNotes = '';

    if (formValue.insuranceCompany) {
      model.insuranceNotes += `Insurance Company: ${formValue.insuranceCompany}\n`;
    }

    if (formValue.groupPolicyNumber) {
      model.insuranceNotes += `Group Number: ${formValue.groupPolicyNumber}\n`;
    }

    if (formValue.employeeId) {
      model.insuranceNotes += `Employee ID: ${formValue.employeeId}\n`;
    }

    if (formValue.insuranceNotes) {
      model.insuranceNotes += `Insurance Notes: ${formValue.insuranceNotes}\n`;
    }
  }

	backToRelationship(patientId){
		this.showNotification_modal.emit(false);
		this.patientId = patientId;
		this.getPatientData(this.patientId, false);
	}

	getInsurance(){

		this.settingsClient.settings_GetInsuranceCompanies().subscribe(
			(res) => {

				this.insuranceCompArr = [];
				let insuranceCompanies = _.filter(res, 'isActive');
				_.each(insuranceCompanies, insuranceCompany => {
					let shortName = !insuranceCompany.shortName || !insuranceCompany.shortName.trim() ? insuranceCompany.name : insuranceCompany.shortName;
					this.insuranceCompArr.push({
						"id": insuranceCompany.id,
						"shortName": shortName,
						"name": insuranceCompany.name,
						"addressLine1": insuranceCompany.addressLine1,
						"addressLine2": insuranceCompany.addressLine2,
						"city": insuranceCompany.city,
						"state": insuranceCompany.state,
						"zip": insuranceCompany.zip,
						"payorNumber": insuranceCompany.payorNumber
					})
			});
			this.filterInsuranceArr = this.insuranceCompArr;
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
			this.insuranceSortType = 'asc';
			},
			(err) => {
				console.log(err)
			}
		)
	}

	selectInsuranceCompany(elem){
		if(elem.id != ''){
			this.responsiblePartyFormGroup.patchValue({
				insuranceCompanyId: elem.id
			})
		} else {
			this.InsuranceCompany.ddplaceholder = 'Insurance Company...'
			this.responsiblePartyFormGroup.patchValue({
				insuranceCompanyId: ''
			})
		}
	}

	resetForm(){
		this.responsiblePartyFormGroup.reset()
	}

  cancelPanel() {
    if (this.savedResponsibleParty != null && this.savedResponsibleParty.id > 0) {
      this.locationClient
        .location_DeleteRelationship(
          this.selectedLocation.id,
          this.savedResponsibleParty.patientId,
          this.savedResponsibleParty.id,)
        .subscribe(() => {
          this.getRespPartyNumber.emit({ "type": "removeSavedRelation", "numbr": 1 });
        },
          (err) => {
            console.log(err);
          }
        )
    }
    else
		  this.getRespPartyNumber.emit({"type": "cancelpanel", "numbr": 1});
	}

  searchExistingResponsibleParty() {

    let respPartyForm: any = this.responsiblePartyFormGroup.value;
    let firstName: any = respPartyForm.firstName;
    let lastName: any = respPartyForm.lastName;

    if (firstName && lastName) {
      this.getExistingResponsibleParties(firstName, lastName);
    }
  }

	getExistingResponsibleParties(firstName, lastName){

    this.isSearchLoading = true;

    const query = new SearchContactQueryDto();

    query.firstName = firstName;
    query.lastName = lastName;

		this._searchClient
		  .search_PostContacts(query, 500, 0, true)
		  .pipe(take(1))
		  .subscribe((res) => {
          this.searchExistingPatientArr = res;

          if(this.searchExistingPatientArr.length > 0){
            this.isExistingPatient = true;
          } else {
            this.isExistingPatient = false;
          }

          this.isSearchLoading = false;
        },
        (err) => {
          this.isSearchLoading = false;
          console.log('search Contacts error:', err)
        }
      );
	}

  selectExistingResponsibleParty(contactId: number) {
    if (!contactId || contactId == 0) return;

    this._contactClient.contact_GetContact(contactId, null)
      .pipe(take(1))
      .subscribe((res) => {
        let contact: ContactDto = res;

        let primaryEmail = _.find(contact.emails, 'isPrimary');
        let primaryAddress = _.find(contact.addresses, 'isPrimary');
        let mobilePhone = _.find(contact.phones, { 'phoneType': PhoneTypeEnum.Mobile });
        let alternatePhones = _.filter(contact.phones, (phone: ContactPhoneDto) => {
          return mobilePhone && mobilePhone.id != phone.id;
        });

        this.responsiblePartyFormGroup.patchValue({
          contactId: contact.id,
          firstName: contact.firstName,
          middleName: contact.middleName,
          lastName: contact.lastName,
          nickName: contact.nickname,
          gender: contact.gender,
          dob: contact.dob ? moment(contact.dob).tz('UTC').format("MM/DD/YYYY") : null,
          ssn: contact.ssn,
          title: contact.title,
          preferredContactMethod: contact.preferredContactMethod,
          primaryAddress: {
            id: primaryAddress ? primaryAddress.id : 0,
            addressType: primaryAddress ? primaryAddress.addressType : 'Home',
            addressLine1: primaryAddress ? primaryAddress.addressLine1 : null,
            addressLine2: primaryAddress ? primaryAddress.addressLine2 : null,
            city: primaryAddress ? primaryAddress.city : null,
            state: primaryAddress ? primaryAddress.state : null,
            zip: primaryAddress ? primaryAddress.zip : null,
            eTag: primaryAddress ? primaryAddress.eTag : null
          },
          mobilePhone: {
            id: mobilePhone ? mobilePhone.id : 0,
            isPrimary: mobilePhone ? mobilePhone.isPrimary : false,
            phoneType: mobilePhone ? mobilePhone.phoneType : null,
            number: mobilePhone ? mobilePhone.number : null,
            extension: mobilePhone ? mobilePhone.extension : null,
            eTag: mobilePhone ? mobilePhone.eTag : null
          },
          alternatePhone1: {
            id: alternatePhones && alternatePhones[0] ? alternatePhones[0].id : 0,
            isPrimary: alternatePhones && alternatePhones[0] ? alternatePhones[0].isPrimary : false,
            phoneType: alternatePhones && alternatePhones[0] ? alternatePhones[0].phoneType : null,
            number: alternatePhones && alternatePhones[0] ? alternatePhones[0].number : null,
            extension: alternatePhones && alternatePhones[0] ? alternatePhones[0].extension : null,
            eTag: alternatePhones && alternatePhones[0] ? alternatePhones[0].eTag : null
          },
          alternatePhone2: {
            id: alternatePhones && alternatePhones[1] ? alternatePhones[1].id : 0,
            isPrimary: alternatePhones && alternatePhones[1] ? alternatePhones[1].isPrimary : false,
            phoneType: alternatePhones && alternatePhones[1] ? alternatePhones[1].phoneType : null,
            number: alternatePhones && alternatePhones[1] ? alternatePhones[1].number : null,
            extension: alternatePhones && alternatePhones[1] ? alternatePhones[1].extension : null,
            eTag: alternatePhones && alternatePhones[1] ? alternatePhones[1].eTag : null
          },
          primaryEmail: {
            id: primaryEmail ? primaryEmail.id : 0,
            emailType: primaryEmail ? primaryEmail.emailType : 'Personal',
            emailAddress: primaryEmail ? primaryEmail.emailAddress : null,
            eTag: primaryEmail ? primaryEmail.eTag : null
          },
          contactETag: contact.eTag,
          employerId: contact.employerId,
          employerName: contact.employerName,
          licenseId: contact.licenseId
        });

        this.responsiblePartyFormGroup.markAsTouched();
      },
      (err) => {
        console.log(err)
      }
    )

    this.isExistingPatient = false;
		this.searchExistingPatientArr = []
  }

	closeSearchResponsibleParties() {
    this.responsiblePartyFormGroup.patchValue({
      contactId: null,
      contactETag: null
    });

    this.isExistingPatient = false;
		this.searchExistingPatientArr = []
	}

	titleAutoComplete() {
		fromEvent(this.ResponsiblePartyTitle.nativeElement, "keyup").pipe(
			debounceTime(500),
			filter((e: KeyboardEvent) => e.keyCode !== 40),
			filter((e: KeyboardEvent) => e.keyCode !== 38),
			map((userInput: any) => userInput.target.value)).subscribe(res => {

				let searchkeyword: any = res.toLowerCase();
				if (searchkeyword.length > 0) {
					this.filterTitleArr = this.titleArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
					if (this.filterTitleArr.length == 0) {
						this.filterTitleArr = this.titleArr;
					}

				} else {
					this.filterTitleArr = this.titleArr;
				}
			})
	}

	insuranceCompanySelected(id: number) {
		let ins = this.insuranceCompArr.find((ic) => ic.id == id);

		this.responsiblePartyFormGroup.patchValue({
			insuranceCompany: ins.shortName,
			insuranceCompanyId: ins.id,
			payorId: ins.payorNumber
		})
	}

  searchRespInsurance(srchstring) {
    let searchkeyword: any = srchstring.toLowerCase();
    if (searchkeyword.length > 0) {
      this.filterInsuranceArr = this.insuranceCompArr.filter(option => (option.name.toLowerCase().includes(searchkeyword) || option.shortName.toLowerCase().includes(searchkeyword)));
      if (this.filterInsuranceArr.length == 0) {
        this.filterInsuranceArr = this.insuranceCompArr;
      }
    } else {
      this.filterInsuranceArr = this.insuranceCompArr;
    }
    this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
    this.insuranceSortType == 'asc';
  }

	sortInsurance(sortOn) {
		this.insuranceSortType = this.insuranceSortType == 'asc' ? 'desc' : 'asc';
		if (sortOn == 'shortname') {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], [this.insuranceSortType]);
		} else {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.name.toLowerCase()], [this.insuranceSortType]);
		}
	}

	getInsurnaceCompanyById(id){
		let insComp = this.insuranceCompArr.find((ic) => ic.id == id);
		return insComp;
	}

	addPolicy(){
		this.showAddPolicyBtn = false;
		this.showInsuranceSection = true;
	}

	selectPolicy(policy){
		this.selectedPolicy = policy;
	}

	addNewPolicy(contactId){
		let respFormValue = this.responsiblePartyFormGroup.value;

		if (respFormValue.effectivedate
      && respFormValue.insuranceCompanyId){
			let policyModel: PolicyDto = new PolicyDto();

      policyModel.contactId = contactId;
      policyModel.insuranceCompanyId = respFormValue.insuranceCompanyId;
			policyModel.activeFromDate = respFormValue.effectivedate;
			policyModel.employerId = respFormValue.employerId;
			policyModel.employerName = respFormValue.employerName;
			policyModel.groupPolicyNumber = respFormValue.groupPolicyNumber;
			policyModel.individualPolicyNumber = respFormValue.individualPolicyNumber;
			policyModel.notes = respFormValue.insuranceNotes;

			this._contactClient.contact_PostPolicy(contactId, policyModel).subscribe(resp => {})
		}
	}

  getDate() {
		if (this.selectedPolicy) {
			let dt = moment(this.selectedPolicy.activeFromDate).tz('UTC').format('MM/DD/YYYY');
			if (dt) {
				return dt;
			}
		}

		return null;
	}
}
