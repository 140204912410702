import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { SettingsClient } from 'src/app/shared/services/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {faCheckCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss'],
})
export class AddDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('searcBox', { static: false }) searcBox: ElementRef;
  vendorList: any[];
  filteredList: any[];
  filteredListDto: any[];
  panelOpenState = false;
  selectedItem: any;
  faTimes = faTimes;
  faCheckCircle = faCheckCircle;

  constructor(
    private _settingsClient: SettingsClient,
    public dialogRef: MatDialogRef<AddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('List:', data);
    this.filteredList = this.filteredListDto = data['dataList'];
    if (data['title'] === 'Locations') {
      this.filteredList = this.filteredList
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .filter((c) => c.isActive);
    } else {
      this.filteredList = this.filteredList
        .sort((a, b) =>
          a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
        )
        .filter((c) => c.isActive);
    }
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    const keyup = fromEvent(this.searcBox.nativeElement, 'keyup');
    keyup
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe((value) => {
        const filterValue = value.trim().toLowerCase();
        this.filteredList = this.filteredListDto.filter((option) => {
          if (
            option[this.data['title'] === 'Locations' ? 'name' : 'displayName']
              .toString()
              .toLowerCase()
              .indexOf(filterValue.toLowerCase()) !== -1
          ) {
            return true;
          }
          return false;
        });
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.selectedItem);
  }

  storeSelectedItem(idx: number) {
    this.selectedItem = this.filteredList[idx];
  }

  constructAddress(add1, add2, city) {
    let temp;
    if (add1 !== null) {
      temp = `${add1}`;
    }
    if (add2 !== null) {
      temp += `, ${add2}`;
    }
    if (city !== null) {
      temp += `, ${city}`;
    }
    return temp;
  }
}
