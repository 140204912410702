import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { SIGNALR_HUB_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(SIGNALR_HUB_FEATURE_KEY, featureReducer),
  ]
})
export class SignalRHubStoreModule { }
