import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ACTIVE_VOICE_FEATURE_KEY } from './featurekey';
import { featureAdapter, ActiveVoiceStoreEntity, State } from './state';

export const selectActiveVoicesState: MemoizedSelector<object, State> = createFeatureSelector<State>(ACTIVE_VOICE_FEATURE_KEY);

export const getSelectedActiveVoiceToExtension = createSelector(selectActiveVoicesState, (state) => state.selectedToExtension);

const { selectAll, selectEntities } = featureAdapter.getSelectors(selectActiveVoicesState);
export const selectAllActiveVoices = selectAll;
export const selectAllActiveVoiceEntities = selectEntities;

export const getSelectedActiveVoice = createSelector(selectActiveVoicesState, selectAllActiveVoices, (state, allActiveVoices: ActiveVoiceStoreEntity[]) =>
  allActiveVoices ? allActiveVoices.find((rp) => rp.toExtension === state.selectedToExtension) : null
);

export const selectActiveVoiceByToExtension = (toExtension: string) =>
  createSelector(selectAllActiveVoices, (allActiveVoices: ActiveVoiceStoreEntity[]) => (allActiveVoices ? allActiveVoices.find((rp) => rp.toExtension === toExtension) : null));

export const selectActiveVoicesError: MemoizedSelector<object, any> = createSelector(selectActiveVoicesState, (state) => state.error);

export const selectActiveVoicesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectActiveVoicesState, (state: State) => state.isLoading);
