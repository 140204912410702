import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { EvoPermission, PERMISSION_AREA } from './jwt.model';
import { UserPermissionsService } from './user-permissions.service';

/**
 * A structural directive that conditionally includes a template based on the current user's permission
 * @example
 * ``` html
 * <div *hasPermission="[Permissions.Dashboard.Analytics.Consults, 1]; else otherTemplateRef"></div>
 * ```
 */
@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective implements OnDestroy {
  //Directive base structure "borrowed" from Angular NgIf source code
  // https://github.com/angular/angular/blob/bbeac0727b8f267a47aba1ff1bcfc8cc5ca15b61/packages/common/src/directives/ng_if.ts

  private _subscription: Subscription;

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private _context: HasPermissionContext = new HasPermissionContext();
  private _thenTemplateRef: TemplateRef<HasPermissionContext> | null = null;
  private _elseTemplateRef: TemplateRef<HasPermissionContext> | null = null;
  private _thenViewRef: EmbeddedViewRef<HasPermissionContext> | null = null;
  private _elseViewRef: EmbeddedViewRef<HasPermissionContext> | null = null;

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<HasPermissionContext>,
    private _userPermissionsService: UserPermissionsService
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set hasPermission(value: [EvoPermission, PERMISSION_AREA]) {
    if (this._subscription) this._subscription.unsubscribe();

    this._subscription = this._userPermissionsService.hasPermission(value[0], value[1]).subscribe((permitted) => {
      this._context.$implicit = permitted;
      this._updateView();
    });
  }

  /**
   * A template to show if the condition expression evaluates to true.
   */
  @Input()
  set hasPermissionThen(templateRef: TemplateRef<HasPermissionContext> | null) {
    assertTemplate('hasPermissionThen', templateRef);
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }

  /**
   * A template to show if the condition expression evaluates to false.
   */
  @Input()
  set hasPermissionElse(templateRef: TemplateRef<HasPermissionContext> | null) {
    assertTemplate('hasPermissionElse', templateRef);
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }
}

export class HasPermissionContext {
  public $implicit: any = null;
  public hasPermission: any = null;
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${JSON.stringify(templateRef)}'.`);
  }
}
