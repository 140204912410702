import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MatBottomSheet, MatSnackBar } from '@angular/material';
import { Subject, combineLatest } from 'rxjs';
import { PatientStoreEntity, PatientsStoreSelectors } from '../../../root-store/patient-store';
import { Store } from '@ngrx/store';
import {
  RootStoreState,
  PhotoGroupStoreActions,
  PhotoGroupStoreSelectors,
  PhotoGroupStoreEntity,
  PhotoTypeStoreSelectors,
  PhotoTypeStoreEntity,
  PhotoTypeStoreActions,
  PatientImagingStoreEntity
} from '../../../root-store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CompareComponent } from './compare/compare.component';
import { Dictionary } from '@ngrx/entity';
import { FolderDto, IPatientTreatmentImageDto, PatientClient, PatientTreatmentImageDto, PatientTreatmentImageGroupDto, StorageContentTypeEnum } from '../../../shared/services/api.service';
import { take, takeUntil } from 'rxjs/operators';
import { PhotoEditorComponent } from '../../../shared-module/photo-editor/photo-editor.component';
import { PhotoEditorActions, PhotoEditorData } from '../../../shared-module/photo-editor/PhotoEditorModels';
import { EditTakenWhenDialogComponent, EditTakenWhenDialogData } from './edit-taken-when-dialog/edit-taken-when-dialog.component';
import { MovePhotoGroupDialogComponent, MovePhotoGroupDialogData } from './move-photo-group-dialog/move-photo-group-dialog.component';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { MovePhotoDialogComponent, MovePhotoDialogData } from './move-photo-dialog/move-photo-dialog.component';
import { DownloadService, IDownloadFile } from 'src/app/shared/services/download/download.service';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { RenameFileDialogComponent, RenameFileDialogData } from '../../../shared-module/card-loader/patient-files/rename-file-dialog/rename-file-dialog.component';
import { AdvancedEditorComponent } from '../../../shared-module/advanced-editor/advanced-editor.component';
import { AdvancedEditorActions, AdvancedEditorData } from '../../../shared-module/advanced-editor/AdvancedEditorModels';
import { base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, OnDestroy {
  isDepenciesLoaded: boolean = false;
  patient: PatientStoreEntity;
  photoGroups: PhotoGroupStoreEntity[];
  photoTypes: PhotoTypeStoreEntity[];
  isCompareMode: boolean = false;
  selectedImages: any[] = [];
  isImageEdited: boolean = false;

  @Output() imageName = new EventEmitter();

  selectMultiple: boolean = false;
  showComparePanel: boolean = false;

  compareArrPanelArr: any[] = [];
  selectRowArr: any[] = [];
  selectRowCount: any = 0;
  exams: { [key: number]: ExamsArr } = {};

  patientTreatmentImageGroups: { [key: number]: PatientTreatmentImageGroupDto[] } = {};
  patientImageGroups: PatientTreatmentImageGroupDto[];
  isWorking: number = 0;

  private fetchAll$: any;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  faFileArchive = faFileArchive;

  constructor(
    private _dialogRef: MatDialogRef<GalleryComponent>,
    private _store$: Store<RootStoreState.State>,
    private _dialog: MatDialog,
    private _patientClient: PatientClient,
    private _bottomSheet: MatBottomSheet,
    private _snackbar: MatSnackBar,
    private _downloadService: DownloadService
  ) {
  }

  ngOnInit() {
    this._store$.dispatch(PhotoGroupStoreActions.LoadRequest({}));
    this._store$.dispatch(PhotoTypeStoreActions.LoadRequest({}));
    this.initializeData();
  }

  ngOnDestroy() {
    if (this.fetchAll$)
      this.fetchAll$.unsubscribe();
    this._destroy$.next(true);
  }

  initializeData(): void {
    this.isDepenciesLoaded = false;
    this.fetchAll$ = combineLatest(
      this._store$.select(PatientsStoreSelectors.getSelectedPatient),
      this._store$.select(PhotoGroupStoreSelectors.selectAllPhotoGroups),
      this._store$.select(PhotoTypeStoreSelectors.selectAllPhotoTypes)
    )
      .pipe(take(1))
      .subscribe(([patient, photoGroups, photoTypes]) => {
        if (patient && photoGroups && photoGroups.length > 0 && photoTypes && photoTypes.length > 0) {
          this.patient = patient;

          this.photoGroups = _.chain(photoGroups)
            .sortBy(['sortOrder'])
            .value();
          this.photoTypes = _.chain(photoTypes)
            .filter('isActive')
            .sortBy(['sortOrder'])
            .value();
          this.isDepenciesLoaded = true;
          this.loadGalleryImages();
        }
      });
  }

  private loadGalleryImages(): void {
    this.isWorking++;
    this._patientClient.patient_GetPatientTreatmentImageGroups(
      this.patient.id
    ).subscribe((res: PatientTreatmentImageGroupDto[]) => {
      this.patientImageGroups = res;
      this.patientTreatmentImageGroups = _.groupBy(res, 'photoGroupId');
      _.each(this.patientTreatmentImageGroups, (patientTreatmentImageGroup: any) => {
        _.each(patientTreatmentImageGroup, (group: any) => {
          group.patientTreatmentImages = _.chain(group.patientTreatmentImages)
            .uniqBy('photoTypeId')
            .keyBy('photoTypeId')
            .value();

          group.dobExamDiff = this.calculateDateDiff(group.takenWhen, this.patient.dob);
        })
      });
      this.isWorking--;
    }, (err) => {
      console.log('gallery error:', err);
      this.isWorking--;
    });
  }

  openEditTakenWhenDialog(patientTreatmentImageGroup: PatientTreatmentImageGroupDto, photoGroupId: number): void {
    let data: EditTakenWhenDialogData = {
      patientId: this.patient.id,
      patientTreatmentImageGroupId: patientTreatmentImageGroup.id,
      currentTakenWhen: patientTreatmentImageGroup.takenWhen
    };

    this._bottomSheet
      .open<EditTakenWhenDialogComponent, EditTakenWhenDialogData>(EditTakenWhenDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          let editedData: any = _.find(this.patientTreatmentImageGroups[photoGroupId], { 'id': result[0] });
          if (editedData) {
            editedData.takenWhen = result[1];
            editedData.dobExamDiff = this.calculateDateDiff(patientTreatmentImageGroup.takenWhen, this.patient.dob);
          }
        }
      })
  }

  moveToAnotherGroup(patientTreatmentImageGroup: PatientTreatmentImageGroupDto): void {
    let data: MovePhotoGroupDialogData = {
      patientId: this.patient.id,
      patientTreatmentImageGroupIdFrom: patientTreatmentImageGroup.id,
      fromPhotoGroupId: patientTreatmentImageGroup.photoGroupId,
      photoGroups: this.patientImageGroups
    };

    this._bottomSheet
      .open<MovePhotoGroupDialogComponent, MovePhotoGroupDialogData>(MovePhotoGroupDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.loadGalleryImages();
        }
      })
  }

  moveImage(imageId: number): void {
    let data: MovePhotoDialogData = {
      patientId: this.patient.id,
      imageId: imageId,
      photoGroups: this.photoGroups,
      photoTypes: this.photoTypes
    };

    this._bottomSheet
      .open<MovePhotoDialogComponent, MovePhotoDialogData>(MovePhotoDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.loadGalleryImages();
        }
      })
  }

  reclassifyPictures(patientTreatmentImageGroup: PatientTreatmentImageGroupDto): void {
    const dialogRef = this._dialog.open(
      UploadImageComponent,
      {
        data: {
          isReclassifyMode: true,
          photoGroupId: patientTreatmentImageGroup.photoGroupId,
          patientTreatmentImageGroupId: patientTreatmentImageGroup.id
        },
        disableClose: true
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadGalleryImages();
      }
    });
  }

  private calculateDateDiff(examDate, dateOfBirth) {
    var duration = moment.duration(moment(examDate).diff(moment(dateOfBirth)));
    return `${duration.years()}y ${duration.months()}m`;
  }

  closeGallery(): void {
    this._dialogRef.close(this.isImageEdited);
  }

  editImage(image: any, patientTreatmentImageGroups, photoGroupId: number): void {
    let photoEditorData: AdvancedEditorData = {
      locationUrl: image.locationUrl,
      originalLocationUrl: image.locationOriginalUrl,
      enableReloadOriginal: true,
      image: image,
    }

    const dialogRef = this._dialog.open(
      AdvancedEditorComponent,
      {
        data: photoEditorData,
        disableClose: true,
        position: {right: '0px'}
      }
    );

    dialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        delete image.editedImage;
        if (result && result.action) {
          switch (result.action) {
            case AdvancedEditorActions.Save: {
              this.uploadEditedImage(
                base64ToFile(result.base64),
                image,
                "Image has been successfully saved.",
                photoGroupId);
              break;
            }
          }
        }
    });

    dialogRef.componentInstance.editedImage
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((result: string) => {
        image.editedImage = result;
    });
  }

  uploadEditedImage(file: any, image: any, successMessage: string, photoGroupId: number): void {
    if (!file || !image) return;
    this.isWorking++;

    file.data = new Blob([file], { type: file.type });
    file.fileName = file.name;

    this._patientClient.patient_PutPatientTreatmentImageEditImage(
      this.patient.id,
      image.patientTreatmentImageGroupId,
      image.id,
      StorageContentTypeEnum.Imaging,
      file,
      image.photoTypeId,
      null
    )
      .pipe(
        take(1),
        takeUntil(this._destroy$)
    )
      .subscribe((ret: PatientTreatmentImageDto) => {
        this.openSnackBar(successMessage, 'Ok');
        let patientTreatmentImageGroup: any = _.find(this.patientTreatmentImageGroups[photoGroupId], (p: PatientTreatmentImageGroupDto) => {
          return p.id == ret.patientTreatmentImageGroupId;
        });
        if (patientTreatmentImageGroup) {
          let patientTreatmentImage = _.find(patientTreatmentImageGroup.patientTreatmentImages, (p: PatientTreatmentImageDto) => {
            return p.id == ret.id;
          });
          if (patientTreatmentImage) {
            patientTreatmentImage.locationContentType = ret.locationContentType;
            patientTreatmentImage.locationThumbnailUrl = ret.locationThumbnailUrl;
            patientTreatmentImage.locationUrl = ret.locationUrl;
            patientTreatmentImage.sourceUrl = ret.sourceUrl;
          }
        }
        this.isImageEdited = true;
        this.isWorking--;
    }, (err) => {
      console.log('gallery error:', err);
      this.isWorking--;
    });
  }

  resetSelection(): void {
    this.isCompareMode = false;
    this.selectedImages = [];
  }

  openCompare(): void {
    const dialogCompareRef = this._dialog.open(
      CompareComponent,
      {
        disableClose: true,
        data: { compares: this.selectedImages }
      }
    );

    dialogCompareRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadGalleryImages();
        this.isImageEdited = true
      }
      else {
        _.each(this.patientTreatmentImageGroups, (patientTreatmentImageGroup: any) => {
          _.each(patientTreatmentImageGroup, (g: any) => {
            _.each(g.patientTreatmentImages, (i: any) => {
              i.isSelected = false;
            });
          });
        });
      }

      this.resetSelection();
    });
  }

  toggleSelectImage(image: any, examId: number, photoGroupId: number): void {
    if (!this.patientTreatmentImageGroups[photoGroupId])
      return;

    if (!image.isSelected) {
      let valid = true;
      _.each(this.patientTreatmentImageGroups, (patientTreatmentImageGroup: PatientTreatmentImageGroupDto[]) => {
        _.each(patientTreatmentImageGroup, (g: PatientTreatmentImageGroupDto) => {
          if (!valid) return false;
          let selectedImages = _.filter(g.patientTreatmentImages, 'isSelected');
          if (g.id === examId && selectedImages && selectedImages.length >= 3)
            valid = false;

          if (selectedImages.length > 0)
            return g.id;
        });
      });

      if (!valid) return;
    }

    image.isSelected = !image.isSelected;

    this.selectedImages = _
      .chain(this.patientTreatmentImageGroups)
      .map(patientTreatmentImageGroup => {
        let images = [];
        _.each(patientTreatmentImageGroup, (g: PatientTreatmentImageGroupDto) => {
          let selectedImages = _.filter(g.patientTreatmentImages, 'isSelected');

          if (selectedImages.length > 0) {
            images.push({
              name: g.photoGroupName,
              id: g.id,
              date: g.takenWhen,
              images: selectedImages
            });
          }
        });
        if (images.length > 0)
          return images;
      })
      .flatten()
      .compact()
      .value();
  }

  async restoreOriginal(image: any, photoGroupId: number): Promise<void> {
    if (!image.locationOriginalUrl) {
      this.openSnackBar("Original Url is not available", 'Ok');
      return;
    }

    let response = await fetch(image.locationOriginalUrl);
    let data = await response.blob();
    this.uploadEditedImage(
      data,
      image,
      "Image has been successfully restored.",
      photoGroupId);
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  downloadImage(image){
    this._downloadService.download(image.locationUrl, image.name).subscribe(resp => {
      console.log("download", resp);
      if(resp.state == 'DONE'){
        this.openSnackBar('Image has been successfully downloaded', '');
      }
    })
  }

  zipAndDownloadImages(patientTreatmentImageGroup: PatientTreatmentImageGroupDto): void {
    let downloadFiles: IDownloadFile[] = [];
    _.each(patientTreatmentImageGroup.patientTreatmentImages, (image: PatientTreatmentImageDto) => {
      let downloadFile: IDownloadFile = {
        url: image.locationUrl,
        filename: image.name
      };
      downloadFiles.push(downloadFile);
    });

    this.isWorking++;
    this._downloadService.downloadFilesAsZip(downloadFiles, patientTreatmentImageGroup.photoGroupName).then((resp) => {
      if (resp) {
        this.openSnackBar('Images has been successfully downloaded', '');
      }
      this.isWorking--;
    });
  }

  removeVisitInfo(patientTreatmentImageGroup: PatientTreatmentImageGroupDto):void {
    this.isWorking++;
    this._patientClient.patient_DeletePatientTreatmentImageGroup(this.patient.id, patientTreatmentImageGroup.id).subscribe(resp => {
      this.isWorking--;
      this.initializeData();
      this.openSnackBar('Visit info has been removed successfully', '');
    }, err => {
        this.isWorking--;
        this.openSnackBar('Some error occured. Try again later', '');
    })
  }

  convertObjectToArr(patientTreatmentImageGroup: PatientTreatmentImageGroupDto){
    let _patientTreatmentImages = [];
    if (Array.isArray(patientTreatmentImageGroup.patientTreatmentImages)) {
      _patientTreatmentImages = patientTreatmentImageGroup.patientTreatmentImages;
    } else {
      _patientTreatmentImages = Object.values(patientTreatmentImageGroup.patientTreatmentImages);
    }
    return _patientTreatmentImages;
  }

  checkIfEmpty(obj){
    return _.isEmpty(obj);
  }

  openRenameFileDialog(image: IPatientTreatmentImageDto) {
    if (!this.patient || !this.patient.id) return;

    let data = <RenameFileDialogData>{
      parentId: image.storageParentId,
      patientId: this.patient.id,
      fileId: image.storageItemId,
      currentFileName: image.name
    };

    this._bottomSheet
      .open<RenameFileDialogComponent, RenameFileDialogData, [number, FolderDto]>(RenameFileDialogComponent, { data: data })
      .afterDismissed()
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((result) => {
        if (result) {
          image.name = result[1].name;
          this.openSnackBar('Image has been successfully renamed.', '');
        }
      });

  }
}

class ExamsArr {
  id: number;
  photoGroupId: number;
  name?: string;
  date: Date;
  images: Dictionary<PatientImagingStoreEntity>;
  dobExamDiff: string;
}
