import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AngularEditorConfig, AngularEditorComponent, AngularEditorService } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { PatientsStoreSelectors, RootStoreState, CardsStoreActions, CardsStoreSelectors } from 'src/app/root-store';
import {
  PatientClient,
  FileParameter,
  StorageContentTypeEnum,
} from 'src/app/shared/services/api.service';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { MessageService } from 'src/app/core/message.service';

export interface RxRequestEmail {
  file?: FileParameter,
  toDentistId?: number
}

@Component({
  selector: 'app-rx-request-back',
  templateUrl: './rx-request-back.component.html',
  styleUrls: ['./rx-request-back.component.css']
})
export class RxRequestBackComponent implements OnInit {

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private dentistId: number;
  @ViewChild('rxrequesteditor', {static: false}) rxRequestEditor:AngularEditorComponent;
  @ViewChild('footercontent', { static: false }) footercontent: ElementRef;
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '420px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'header',
    defaultParagraphSeparator: '',
    sanitize: false,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',

        'underline',
        'strikeThrough',
        'subscript',
        'superscript',

        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'customClasses',
        'textColor',
        'backgroundColor',
        'link',
        'unlink',

        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };

  rxrequestpdfForm: FormGroup;
  stationaryFooter: any = "";
  genrtPDF:boolean = false;

  emailData: any;

  constructor(
    @Inject(CARD_DATA) public data: ICardData, 
    private formBuilder: FormBuilder,
    private patientClient: PatientClient,
    private _store$: Store<RootStoreState.State>,
    private _router: Router,
    private _actions$: Actions,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.rxrequestpdfForm = this.formBuilder.group({
      content: ['']
    })

    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe(resp => {
      this.emailData = resp;
      this.rxrequestpdfForm.patchValue({
        content: resp.content.innerHTML
      })

      this.stationaryFooter = resp.footer;
      this.dentistId = resp.dentistId;
    })
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  cancel() {
    this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
  }

  generatePdfBlob(): Promise<Blob> {
    return new Promise(resolve => {
      setTimeout(() => {
        this.rxRequestEditor.textArea.nativeElement.setAttribute('style', 'height: auto');
        let content = this.rxRequestEditor.textArea.nativeElement;
        let footer = content.querySelectorAll('.stationary-footer');
        footer[0].setAttribute('style', 'display: none');

        let blankSpace: any[] = content.querySelectorAll('.editable-div');

        for (let i = 0; i < blankSpace.length; i++) {
          blankSpace[i].setAttribute('style', 'display: none');
        }

        var opt = {
          margin: [0.5, 0.5, 0.5, 0.5],
          filename: 'RXREQUEST.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: 'avoid-all' },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        const footeroptions = {
          background: 'white',
          scale: 1,
          quality: 1
        };

        html2canvas(this.footercontent.nativeElement, footeroptions).then((canvas) => {
          let img = canvas.toDataURL("image/jpeg");

          const today = moment();

          let pdfWorker = html2pdf().from(content).set(opt).toPdf().get('pdf').then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var pgHt = pdf.internal.pageSize.getHeight();

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(150);

              let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
              let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`

              if (canvas.height) {
                pdf.addImage(img, 'JPEG', 0.50, (pgHt - canvas.height / 72) - 0.30)
              } else {
                pdf.text(footerText, 0.50, pgHt - 0.30);
              }
              this.rxRequestEditor.textArea.nativeElement.setAttribute('style', 'height: 420px');
            }

          }).save();

          pdfWorker.outputPdf('blob').then((blob: Blob) => {
            resolve(blob);
          });
        })
      }, 1000)
    });
  }

  generatePDF(){
    this.genrtPDF = true; 4
    this.generatePdfBlob().then((blob: Blob) => {
      this.selectedPatientId$
        .pipe(take(1))
        .subscribe((patientId) => {
          const today = moment();
          let formattedDate = today.format('MM/DD/YYYY');
          const data = new Blob([blob], { type: blob.type })

          const file: FileParameter = {
            data,
            fileName: `${formattedDate}-Rx-Request.pdf`,
          };
          this.patientClient
            .patient_PostRxRequest(patientId, null, file, StorageContentTypeEnum.RxRequest)
            .pipe(take(1))
            .subscribe((result) => {
              this.genrtPDF = false;
              //this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
            });
        });
    });
  }

  email(): void {
    this.genrtPDF = true; 4
    this.generatePdfBlob().then((blob: Blob) => {
      this.selectedPatientId$
        .pipe(take(1))
        .subscribe((patientId) => {
          const today = moment();
          let formattedDate = today.format('MM/DD/YYYY');
          const data = new Blob([blob], { type: blob.type })

          const file: FileParameter = {
            data,
            fileName: `${formattedDate}-Rx-Request.pdf`,
          };

          let rxRequestEmail: RxRequestEmail = {
            file: file,
            toDentistId: this.dentistId
          }

          this.patientClient
            .patient_PostRxRequest(patientId, null, file, StorageContentTypeEnum.RxRequest)
            .pipe(take(1))
            .subscribe((result) => {
              this.genrtPDF = false;
              this._router.navigate(['/dashboard', 'patient', 'communication'], {
                state: {
                  rxRequestEmail: rxRequestEmail
                }
              });
            });
        });
    });
  }

  sendToReferOut(){
    
    this._actions$
    .pipe(
      ofType(CardsStoreActions.OpenSuccess),
      take(1),
      takeUntil(this._destroy$)).subscribe(result => {
        console.log("result", result);

        setTimeout(() => {
          this.emailData.editorContent = this.rxRequestEditor.textArea.nativeElement;
          this.emailData.isRxRequest = true;
          this.messageService.sendMessage('flipcard', { event: 'flipback', emailData: this.emailData });
          this.cancel();
        }, 0)
      })
    
    this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: 'referout' }));
    
  }
}
