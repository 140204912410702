import { createAction, props } from '@ngrx/store';
import { PatientLedgerDto, PatientLedgerOptionsDto, PatientLedgerTransactionDto, RelationshipDto } from 'src/app/shared/services/api.service';
import { PatientLedgerStoreEntity } from './state';

export const LoadFailure = createAction('[Patient Ledger] Load Failure', props<{ error: any }>());

export const GetResponsibleParties = createAction(
  '[Patient ResponsibleParties] GetResponsibleParties',
  props<{ patientId: number; contactId: number; page?: number; pageSize?: number }>()
);

export const GetResponsiblePartiesSuccess = createAction(
  '[Patient ResponsibleParties] GetResponsiblePartiesSuccess',
  props<{ responsibleParties: RelationshipDto[] }>()
);

export const GetPatientLedgers = createAction(
  '[Patient Ledgers] GetPatientLedgerse',
  props<{ patientId: number; page?: number; pageSize?: number }>()
);

export const GetPatientLedgersSuccess = createAction('[Patient Ledgers] GetPatientLedgersSuccess', props<{ ledgers: PatientLedgerDto[] }>());

export const SetSelectedLedger = createAction('[Patient Ledger] SelectedLedger', props<{ selectedLedger: PatientLedgerDto }>());

export const AddLedger = createAction('[Ledger Add] AddLedger', props<{ ledger: PatientLedgerDto }>());

export const RemoveLedger = createAction('[Ledger Remove] RemoveLedger', props<{ ledgerId: number }>());

export const GetLedgerTransactions = createAction('[Ledger Transactions] GetLedgerTransactions', props<{ patientId: number; ledgerId: number }>());

export const GetLedgerTransactionSuccess = createAction(
  '[Ledger Transactions] GetLedgerTransactionSuccess',
  props<{ ledgerId: number; ledgerTransactions: PatientLedgerTransactionDto[] }>()
);

export const AddTransaction = createAction(
  '[Ledger Transactions] AddTransaction',
  props<{ ledgerTransaction: PatientLedgerTransactionDto; ledgerId: number }>()
);

export const GetLedgerOptions = createAction('[Ledger Options] GetLedgerOptions', props<{ patientId: number }>());

export const GetLedgerOptionsSuccess = createAction('[Ledger Options] GetLedgerOptions', props<{ ledgerOptions: PatientLedgerOptionsDto }>());

export const TogglePatientInfo = createAction('[Ledger PatientInfo] TogglePatientInfo', props<{ flag: boolean }>());

export const ResetError = createAction('[Patient Ledger] Reset Error');

export const SelectRequest = createAction('[Patient Ledger] Select Request', props<{ id: number }>());

export const SelectSuccess = createAction('[Patient Ledger] Select Success', props<{ selectedLedger: PatientLedgerStoreEntity }>());

export const EmptyLedgers = createAction('[Patient Ledger] Empty Ledgers');
