import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DoctorTimeSegmentDTO } from 'src/app/shared/services/settings/settings.service';

@Component({
  selector: 'app-time-interval',
  templateUrl: './time-interval.component.html',
  styleUrls: ['./time-interval.component.scss'],
})
export class TimeIntervalComponent {
  @Input() model: DoctorTimeSegmentDTO[];
  @Input() label: string = 'Doctor Time';
  @Input() required: boolean = false;
  @Input() disabled: false;
  @Input() duration: number;
  @Output() valueChange = new EventEmitter();
  hasError: boolean = false;

  onKeydown(e) {
    if (e.metaKey || // cmd/ctrl
      e.which <= 0 || // arrow keys
      e.which == 8 || // delete key
      /[0-9]/.test(e.key)) { } else {
      e.preventDefault();
    }
  }

  onKeyup() {
    this.checkErrors();
  }

  expandDoctorTime() {
    if (this.model == null) return;
    let len = this.model.length;
    let nextLen = this.duration / 5;

    if (this.duration >= 5 && this.duration <= 180 && this.duration % 5 == 0) {
      if (len > nextLen) {
        let diff = len - nextLen;

        while (diff-- > 0) {
          this.model.pop();
        }
      } else if (len < nextLen) {
        let start = len == 0 ? 5 : this.model[len - 1].index;

        for (var i = start + 1; i < nextLen; i++) {
          this.model.push(new DoctorTimeSegmentDTO({
            index: i,
            error: false,
            value: ''
          }));
        }
      }
    } else {
      this.model = [];
    }

    this.checkErrors();
  }

  checkErrors() {
    let self = this;
    this.hasError = false;

    this.model.forEach(function (item) {
      item.error = item.timeAmount > 5 ? true : false;
      self.hasError = self.hasError || item.error;
    });
  }

  validate() {
    this.checkErrors();
    return !this.hasError;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.expandDoctorTime();
  }
}