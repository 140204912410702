import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, delay, filter, mapTo } from 'rxjs/operators';
import { HubEventArea, LocationClient } from 'src/app/shared/services/api.service';
import * as RootStoreState from '../../root-state';
import * as ActiveTreatmentProgressStoreActions from './actions';
import { PatientsStoreSelectors } from '../../patient-store';
import { LocationsStoreSelectors } from '../../location-store';
import { SignalRHubStoreActions } from '../../signalr-hub-store';

@Injectable({ providedIn: 'root' })
export class ActiveTreatmentProgressEffects {
  private _selectedPatientId = this.store$.select(PatientsStoreSelectors.getSelectedPatientId);
  private _selectedLocationId = this.store$.select(LocationsStoreSelectors.getSelectedLocationId);

  constructor(
    private store$: Store<RootStoreState.State>,
    private actions$: Actions,
    private locationClient: LocationClient
  ) { }

  loadRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActiveTreatmentProgressStoreActions.LoadRequest),
      withLatestFrom(this._selectedPatientId, this._selectedLocationId),
      switchMap(([action, patientId, locationId]) =>
        this.locationClient.location_GetActiveTreatmentProgress(locationId, patientId)
          .pipe(
            map((result) => ActiveTreatmentProgressStoreActions.LoadSuccess({ activeTreatmentProgress: result })),
            catchError((error) => of(ActiveTreatmentProgressStoreActions.LoadFailure({ err: error })))
          )
      )
    );
  });

  @Effect()
  activeTreatmentProgressSignalRActionsEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedPatientId),
    filter(
      ([action, patientId]) =>
        patientId &&
        action.event.eventArea == HubEventArea.TreatmentEvent &&
        action.event.entityId == patientId
    ),
    map(([action, userId]) =>
      ActiveTreatmentProgressStoreActions.LoadRequest()
    ),
  );
}
