import { Component, ElementRef, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  DentistStoreActions,
  DentistStoreEntity,
  DentistStoreSelectors,
  LocationsStoreSelectors,
  PatientCompletionDateStoreActions,
  PatientCompletionDateStoreEntity,
  PatientCompletionDateStoreSelectors,
  PatientDentistVisitStoreActions,
  PatientDentistVisitStoreEntity,
  PatientDentistVisitStoreSelectors,
  PatientModelBoxStoreActions,
  PatientModelBoxStoreEntity,
  PatientModelBoxStoreSelectors,
  PatientRecallStoreActions,
  PatientRecallStoreEntity,
  PatientRecallStoreSelectors,
  PatientsStoreSelectors,
  PatientStatusGroupStoreActions,
  PatientStatusGroupStoreSelectors,
  PatientTreatmentStatusStoreActions,
  PatientTreatmentStatusStoreEntity,
  PatientTreatmentStatusStoreSelectors,
  RootStoreState,
} from "@root-store";
import { DentistClient, PatientClient, PatientStatusDto, PatientXrayDto, PatientXrayTypeEnum } from '@shared/services/api.service';
import * as _ from 'lodash';
import { combineLatest, fromEvent, Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { PERMISSIONS } from '@shared/constants/permissions';
import { UserPermissionsService } from '@shared/user-permissions/user-permissions.service';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MessageDialog } from 'src/app/dialogs/message/message.dialog';
import * as moment from 'moment';
import { ACTIVE_PATIENT_LOCATION } from '../../user-permissions/jwt.model';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-key-dates',
  templateUrl: './key-dates.component.html',
  styleUrls: ['./key-dates.component.css'],
})
export class KeyDatesComponent implements OnInit, OnDestroy {
  isDepenciesLoaded: boolean = false;
  keyDatesTypes = {
    treatmentStatus: 'treatmentStatus',
    completionDates: 'completionDates',
    dentistVisit: 'dentistVisit',
    modelBox: 'modelBox',
    patientRecall: 'patientRecall',
    patientXrays: 'patientXrays'
  };
  editMode: any = {};
  lastProphy: any[] = [];
  submitlastProphy: boolean = false;
  referralProfnl: any[] = [];

  headPatientTreatmentStatus: PatientTreatmentStatusStoreEntityVm;
  patientTreatmentStatuses$: Observable<PatientTreatmentStatusStoreEntityVm[]> = this._store$
    .select(PatientTreatmentStatusStoreSelectors.selectAllPatientTreatmentStatuses)
    .pipe(
      map(pts => pts.map(x => <PatientTreatmentStatusStoreEntityVm>x)),
      map((patientTreatmentStatuses) => {
        if (this.isDepenciesLoaded) {
          this.headPatientTreatmentStatus = _.head(patientTreatmentStatuses);
        }
        return patientTreatmentStatuses;
      })
    );
  isPatientTreatmentStatusWorking$ = this._store$.select(PatientTreatmentStatusStoreSelectors.selectPatientTreatmentStatusesIsLoading);
  patientTreatmentStatusFormGroup: FormGroup;

  headPatientRecall: PatientRecallStoreEntityVm;
  patientRecalls$: Observable<PatientRecallStoreEntityVm[]> = this._store$.select(PatientRecallStoreSelectors.selectAllPatientRecalls).pipe(
    map(prs => prs.map(x => <PatientRecallStoreEntityVm>x)),
    map((patientRecalls) => {
      if (this.isDepenciesLoaded) {
        patientRecalls = _.map(patientRecalls, (patientRecall, i) => {

          let patientStatus = _.find(this.patientStatuses, ['id', patientRecall.patientStatusId]);
          if (patientStatus) {
            patientRecall.patientStatusCode = patientStatus.code;
            patientRecall.patientStatusDescription = patientStatus.description;
          }
          return patientRecall;
        });

        patientRecalls = [...patientRecalls.filter(patientRecal => patientRecal.isActive), ...patientRecalls.filter(patientRecal => !patientRecal.isActive)];
        this.headPatientRecall = _.head(patientRecalls);

      }
      return patientRecalls;
    })
  );
  isPatientRecallWorking$ = this._store$.select(PatientRecallStoreSelectors.selectPatientRecallsIsLoading);
  patientRecallFormGroup: FormGroup;

  headPatientCompletionDate: PatientCompletionDateStoreEntity;
  tailPatientCompletionDate: PatientCompletionDateStoreEntity;
  patientCompletionDates$: Observable<PatientCompletionDateStoreEntity[]> = this._store$
    .select(PatientCompletionDateStoreSelectors.selectAllPatientCompletionDates)
    .pipe(
      map((patientCompletionDates) => {
        if (this.isDepenciesLoaded) {
          this.headPatientCompletionDate = _.head(patientCompletionDates);
          this.tailPatientCompletionDate = _.last(patientCompletionDates);
        }
        return patientCompletionDates;
      })
    );
  isPatientCompletionDateWorking$ = this._store$.select(PatientCompletionDateStoreSelectors.selectPatientCompletionDatesIsLoading);
  patientCompletionDateFormGroup: FormGroup;

  headDentistVisit: PatientDentistVisitStoreEntity;

  patientDentistVisits$: Observable<PatientDentistVisitStoreEntity[]> = this._store$
    .select(PatientDentistVisitStoreSelectors.selectAllPatientDentistVisits)
    .pipe(
      map((patientDentistVisits) => {
        if (this.isDepenciesLoaded) this.headDentistVisit = _.head(patientDentistVisits);
        return patientDentistVisits;
      })
    );

  isPatientDentistVisitWorking$ = this._store$.select(PatientDentistVisitStoreSelectors.selectPatientDentistVisitsIsLoading);
  patientDentistVisitFormGroup: FormGroup;

  headPatientModelBox: PatientModelBoxStoreEntity;
  patientModelBoxes$: Observable<PatientModelBoxStoreEntity[]> = this._store$.select(PatientModelBoxStoreSelectors.selectAllPatientModelBoxes).pipe(
    map((patientModelBoxes) => {
      if (this.isDepenciesLoaded) this.headPatientModelBox = _.head(patientModelBoxes);
      return patientModelBoxes;
    })
  );
  isPatientModelBoxWorking$ = this._store$.select(PatientModelBoxStoreSelectors.selectPatientModelBoxesIsLoading);
  patientModelBoxFormGroup: FormGroup;

  patientStatuses: PatientStatusDto[];
  dentists: DentistStoreEntity[];

  private selectedPatientId: number;
  private fetchAll$: any;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  private userPermissionSubscription: Subscription;
  dialog: DialogComponent;

  @ViewChild('dentist', { static: false }) dentistInput: ElementRef;
  @ViewChild('adddentist', { static: false }) adddentist: ElementRef;
  @ViewChild('puttoRecallDialog', { static: false }) puttoRecallDialog: TemplateRef<any>;
  @ViewChild('recallDialog', { static: false }) recallDialog: TemplateRef<any>;

  showDeletePopup: boolean = false;
  showConfirmDeactivateRecall: boolean = false;
  seelctedDeleteType: any;
  selectedDeleteId: any;
  putToRecalldialogRef: MatDialogRef<any>;
  recallDialogRef: MatDialogRef<any>;
  recallForm: FormGroup;
  monthNumbersArr: any[] = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "12" },
    { id: 11, name: "18" },
    { id: 12, name: "24" }
  ];

  recallSubmitted: boolean = false;
  minRecallDate: any;

  isPatientHistoryWorking: boolean = false;
  patientXraysArr: PatientXrayDto[] = [];
  patientXrayForm: FormGroup;
  patientXrayTypes: any[] = [...Object.entries(PatientXrayTypeEnum).map(([key, value]) => ({ id: key, name: value }))];
  maxPatientXrayDt: any;

  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  selectedLocation;

  constructor(
    private _actions$: Actions,
    private _formBuilder: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private _dentistClient: DentistClient,
    private _patientClient: PatientClient,
    private _userPermissionsService: UserPermissionsService,
    private matDialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    this.dialog = new DialogComponent(this.matDialog);
  }

  ngOnInit() {
    this._store$.dispatch(PatientStatusGroupStoreActions.LoadRequest({}));
    this._store$.dispatch(DentistStoreActions.LoadRequest({}));
    this.initializeEditMode();
    this.fetchAll$ = combineLatest(
      this._store$.select(PatientsStoreSelectors.getSelectedPatientId),
      this._store$.select(PatientStatusGroupStoreSelectors.selectAllPatientStatusGroups),
      this._store$.select(DentistStoreSelectors.selectAllDentists)
    )
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(([patientId, patientStatusGroups, dentists]) => {
        this.isDepenciesLoaded = this.selectedPatientId == patientId;

        if (patientId && !this.isDepenciesLoaded && patientStatusGroups && patientStatusGroups.length > 0) {
          this.selectedPatientId = patientId;
          this.patientStatuses = _.chain(patientStatusGroups).map('patientStatuses').flatten().value().filter(status => status.isActive);
          this.patientStatuses = _.orderBy(this.patientStatuses, 'code');
          this.dentists = dentists;

          if (patientId) {
            this.getPatientHistory(patientId);
          }

          setTimeout(() => {
            this.isDepenciesLoaded = true;
            this._store$.dispatch(PatientModelBoxStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
            this._store$.dispatch(PatientTreatmentStatusStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
            this._store$.dispatch(PatientDentistVisitStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
            this._store$.dispatch(PatientCompletionDateStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
            this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
          });
        }
      });

    this.initializeModelBoxEdit();
    this.initializeTreatmentStatusEdit();
    this.initializeDentistVisitEdit();
    this.initializeCompletionDateEdit();
    this.initializePatientRecallEdit();

    this.recallForm = this._formBuilder.group({
      patientStatus: ['', Validators.required],
      month: [''],
      recalldate: ['', Validators.required],
      isActive: ['']
    });

    this.minRecallDate = moment().toDate();

    this.initializePatientXrays();
    this.maxPatientXrayDt = moment().toDate();

    this.selectedLocation$.pipe(
      filter(location => !!location),
      takeUntil(this._destroy$)
    ).subscribe(location => {
      this.selectedLocation = location;
    })

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchReffaral();
    }, 500);
  }

  ngOnDestroy() {
    this.fetchAll$.unsubscribe();
    this._destroy$.next(true);
    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }
  }

  initializeTreatmentStatusEdit(patientTreatmentStatus?: PatientTreatmentStatusStoreEntity) {
    if (patientTreatmentStatus) {

      let patientTime = moment(patientTreatmentStatus.fromDate).tz('UTC').format('hh:mm a');

      this.patientTreatmentStatusFormGroup = this._formBuilder.group({
        patientStatusId: [patientTreatmentStatus.patientStatusId, Validators.required],
        fromDate: [patientTreatmentStatus.fromDate, Validators.required],
        patientId: patientTreatmentStatus.patientId,
        id: patientTreatmentStatus.id,
        eTag: patientTreatmentStatus.eTag,
        fromTime: [patientTime]
      });
    } else {

      let timeNow = moment().format('hh:mm a')

      this.patientTreatmentStatusFormGroup = this._formBuilder.group({
        patientStatusId: ['', Validators.required],
        fromDate: [new Date(), Validators.required],
        patientId: this.selectedPatientId,
        fromTime: [timeNow]
      });
    }
  }

  initializePatientRecallEdit(patientRecall?: PatientRecallStoreEntity) {
    if (patientRecall) {

      let recallTime = moment(patientRecall.recallDate).format('hh:mm a');

      this.patientRecallFormGroup = this._formBuilder.group({
        patientStatusId: [patientRecall.patientStatusId, Validators.required],
        recallDate: [patientRecall.recallDate, Validators.required],
        patientId: patientRecall.patientId,
        id: patientRecall.id,
        eTag: patientRecall.eTag,
        isActive: patientRecall.isActive,
        recallTime: recallTime
      });
    } else {
      this.patientRecallFormGroup = this._formBuilder.group({
        patientStatusId: ['', Validators.required],
        recallDate: [new Date(), Validators.required],
        patientId: this.selectedPatientId,
        isActive: true,
        recallTime: ['']
      });
    }
  }

  initializeCompletionDateEdit() {
    this.patientCompletionDateFormGroup = this._formBuilder.group({
      estimateCompletionDate: [new Date(), Validators.required],
      patientId: this.selectedPatientId,
      estCompletionMnth: ['']
    });
  }

  initializeDentistVisitEdit(patientDentistVisit?: PatientDentistVisitStoreEntity) {
    if (patientDentistVisit) {
      this.patientDentistVisitFormGroup = this._formBuilder.group({
        dentistVisitDate: [patientDentistVisit.dentistVisitDate, Validators.required],
        patientId: patientDentistVisit.patientId,
        id: patientDentistVisit.id,
        eTag: patientDentistVisit.eTag,
        dentistId: [patientDentistVisit.dentistId, Validators.required],
        clinicId: [patientDentistVisit.clinicId, Validators.required],
      });

      this.dentistInput.nativeElement.value = this.dentists.find((doc) => doc.id == patientDentistVisit.dentistId).displayName;
    } else {
      this.patientDentistVisitFormGroup = this._formBuilder.group({
        dentistVisitDate: [new Date(), Validators.required],
        patientId: this.selectedPatientId,
        dentistId: ['', Validators.required],
        clinicId: ['', Validators.required],
      });

      this.patientDentistVisitFormGroup.patchValue({
        dentistId: '',
        clinicId: '',
      });

      if (this.adddentist) {
        this.adddentist.nativeElement.value = '';
      }
    }
  }

  initializeModelBoxEdit(patientModelBox?: PatientModelBoxStoreEntity) {
    if (patientModelBox) {
      this.patientModelBoxFormGroup = this._formBuilder.group({
        label: [patientModelBox.label, Validators.required],
        date: [patientModelBox.date, Validators.required],
        patientId: patientModelBox.patientId,
        id: patientModelBox.id,
        eTag: patientModelBox.eTag,
        modelTime: moment(patientModelBox.date).format('hh:mm a')
      });
    } else {
      this.patientModelBoxFormGroup = this._formBuilder.group({
        label: ['', Validators.required],
        date: [new Date(), Validators.required],
        patientId: this.selectedPatientId,
      });
    }
  }

  add(type: string): void {

    switch (type) {
      case this.keyDatesTypes.treatmentStatus: {
        this.initializeTreatmentStatusEdit();
        break;
      }
      case this.keyDatesTypes.completionDates: {
        this.initializeCompletionDateEdit();
        break;
      }
      case this.keyDatesTypes.dentistVisit: {
        this.initializeDentistVisitEdit();
        break;
      }
      case this.keyDatesTypes.modelBox: {
        this.initializeModelBoxEdit();
        break;
      }
      case this.keyDatesTypes.patientRecall: {
        this.initializePatientRecallEdit();
        break;
      }
      case this.keyDatesTypes.patientXrays: {
        this.initializePatientXrays();
        break
      }
    }

    this.initializeEditMode();

    if (type == 'treatmentStatus') {
      this.userPermissionSubscription = this._userPermissionsService.hasPermission(PERMISSIONS.Location.TreatmentCards.ChangeTxStatus, ACTIVE_PATIENT_LOCATION).subscribe(hasPermission => {
        if (hasPermission) {
          this.editMode['treatmentStatus'] = true;
        } else {
          let data = {
            title: 'Permission Error',
            message: "Sorry, you don't have rights to add treatment status. Please see the administrator."
          };

          this.dialog.open(MessageDialog, data);
        }
      })
    } else {
      this.editMode[type] = true;
    }

  }

  cancel(): void {
    this.initializeEditMode();
  }

  save(type: string): void {
    switch (type) {
      case this.keyDatesTypes.treatmentStatus: {
        this.savePatientTreatmentStatus();
        break;
      }
      case this.keyDatesTypes.completionDates: {
        this.savePatientCompletionDate();
        break;
      }
      case this.keyDatesTypes.dentistVisit: {
        this.savePatientDentistVisit();
        break;
      }
      case this.keyDatesTypes.modelBox: {
        this.savePatientModelBox();
        break;
      }
      case this.keyDatesTypes.patientRecall: {
        this.savePatientRecall();
        break;
      }
      case this.keyDatesTypes.patientXrays: {
        this.savePatientXray();
        break;
      }
    }
  }

  savePatientTreatmentStatus(): void {
    if (!this.patientTreatmentStatusFormGroup.valid) return;
    let formValue = this.patientTreatmentStatusFormGroup.value;
    

    if (formValue.id) {

      formValue.fromDate = this.getISOString(formValue.fromDate, formValue.fromTime);


      this._actions$.pipe(ofType(PatientTreatmentStatusStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
        this._store$.dispatch(PatientTreatmentStatusStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
      });

      this._store$.dispatch(PatientTreatmentStatusStoreActions.UpdateRequest({ patientTreatmentStatus: formValue }));
      this.checkCanDeactivateRecall(formValue.id);
    } else {

      formValue.fromDate = this.getISOString(formValue.fromDate);

      this._actions$.pipe(ofType(PatientTreatmentStatusStoreActions.AddSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
        this._store$.dispatch(PatientTreatmentStatusStoreActions.LoadRequest({ patientId: this.selectedPatientId }));

        this.putToReacall(formValue);
      });

      this._store$.dispatch(PatientTreatmentStatusStoreActions.AddRequest({ patientTreatmentStatus: formValue }));
      this.checkCanDeactivateRecall();

    }
  }

  checkCanDeactivateRecall(patientTreatmentStatusId?: number): void {
    this.showConfirmDeactivateRecall = (!patientTreatmentStatusId || this.headPatientTreatmentStatus.id == patientTreatmentStatusId)
      && !!this.headPatientRecall
      && this.headPatientRecall.isActive;
  }

  deactivateRecall(): void {
    this.showConfirmDeactivateRecall = false;
    if (this.headPatientRecall) {
      this._patientClient.patient_PutPatientRecallInactive(this.selectedPatientId, this.headPatientRecall.id, null, this.headPatientRecall.eTag)
        .pipe(
          takeUntil(this._destroy$),
          take(1)
        )
        .subscribe(res => {
          this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
        })
    }
  }

  savePatientRecall(): void {
    if (!this.patientRecallFormGroup.valid) return;
    let formValue = this.patientRecallFormGroup.value;

    if (formValue.id) {
      let _recalldate = moment(formValue.recalldate);
      let _recallTime = moment(formValue.recallTime, 'hh:mm a');
      _recalldate = _recalldate.set('hours', _recallTime.hours()).set('minutes', _recallTime.minutes());
      formValue.recalldate = _recalldate.toDate();

      this._actions$.pipe(ofType(PatientRecallStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
      });

      this._store$.dispatch(PatientRecallStoreActions.UpdateRequest({ patientRecall: formValue }));
    } else {

      if (this.headPatientRecall) {
        this._patientClient.patient_PutPatientRecallInactive(this.selectedPatientId, this.headPatientRecall.id, null, this.headPatientRecall.eTag)
          .pipe(
            takeUntil(this._destroy$),
            take(1)
          )
          .subscribe(res => {
            this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
            this._actions$.pipe(ofType(PatientRecallStoreActions.AddSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
              this.initializeEditMode();
            });

            this._store$.dispatch(PatientRecallStoreActions.AddRequest({ patientRecall: formValue }));
          })
      }
      else {
        this._actions$.pipe(ofType(PatientRecallStoreActions.AddSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
          this.initializeEditMode();
        });

        this._store$.dispatch(PatientRecallStoreActions.AddRequest({ patientRecall: formValue }));
      }
    }
  }

  savePatientCompletionDate(): void {
    if (!this.patientCompletionDateFormGroup.valid) return;
    let formValue = this.patientCompletionDateFormGroup.value;
    formValue.estimateCompletionDate = this.getISOString(formValue.estimateCompletionDate);

    let completionDtModel: any = {
      estimateCompletionDate: formValue.estimateCompletionDate,
      patientId: formValue.patientId,
    }

    this._actions$.pipe(ofType(PatientCompletionDateStoreActions.AddSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
      this._store$.dispatch(PatientDentistVisitStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
      this.initializeEditMode();
    });

    this._store$.dispatch(PatientCompletionDateStoreActions.AddRequest({ patientCompletionDate: completionDtModel }));
  }

  savePatientDentistVisit(): void {
    this.submitlastProphy = true;
    if (!this.patientDentistVisitFormGroup.valid) return;
    let formValue = this.patientDentistVisitFormGroup.value;
    formValue.dentistVisitDate = this.getISOString(formValue.dentistVisitDate);
    if (formValue.id) {
      this._actions$.pipe(ofType(PatientDentistVisitStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
        this._store$.dispatch(PatientCompletionDateStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
        //this.getLastProphy();
      });

      this._store$.dispatch(PatientDentistVisitStoreActions.UpdateRequest({ patientDentistVisit: formValue }));
    } else {
      this._actions$.pipe(ofType(PatientDentistVisitStoreActions.AddSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
        this._store$.dispatch(PatientCompletionDateStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
        //this.getLastProphy();
      });

      this._store$.dispatch(PatientDentistVisitStoreActions.AddRequest({ patientDentistVisit: formValue }));
    }
  }

  savePatientModelBox(): void {
    if (!this.patientModelBoxFormGroup.valid) return;
    let formValue = this.patientModelBoxFormGroup.value;
    if (formValue.id) {

      let _modelDate = moment(formValue.date);
      let _modelTime = moment(formValue.modelTime, 'hh:mm a');

      _modelDate = _modelDate.set('hours', _modelTime.hours()).set('minutes', _modelTime.minutes());

      formValue.date = _modelDate.toDate();

      this._actions$.pipe(ofType(PatientModelBoxStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
      });

      this._store$.dispatch(PatientModelBoxStoreActions.UpdateRequest({ patientModelBox: formValue }));
    } else {
      this._actions$.pipe(ofType(PatientModelBoxStoreActions.AddSuccess), take(1), takeUntil(this._destroy$)).subscribe((result) => {
        this.initializeEditMode();
      });

      this._store$.dispatch(PatientModelBoxStoreActions.AddRequest({ patientModelBox: formValue }));
    }
  }

  remove(type: string, id: number): void {
    this.seelctedDeleteType = type;
    this.selectedDeleteId = id;
    this.showDeletePopup = true;
  }

  removeTreatmentStatus(id: number): void {
    this._actions$.pipe(ofType(PatientTreatmentStatusStoreActions.DeleteSuccess), take(1), takeUntil(this._destroy$)).subscribe(() => {
      this.initializeEditMode();
      this._store$.dispatch(PatientTreatmentStatusStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
      this.showDeletePopup = false;
    });

    this._store$.dispatch(
      PatientTreatmentStatusStoreActions.DeleteRequest({
        patientId: this.selectedPatientId,
        treatmentStatusId: id,
      })
    );

    this.checkCanDeactivateRecall(id);
  }

  removePatientRecall(id: number): void {
    this._actions$.pipe(ofType(PatientRecallStoreActions.DeleteSuccess), take(1), takeUntil(this._destroy$)).subscribe(() => {
      this.initializeEditMode();
      this.showDeletePopup = false;
    });

    this._store$.dispatch(
      PatientRecallStoreActions.DeleteRequest({
        patientId: this.selectedPatientId,
        patientRecallId: id,
      })
    );
  }

  removeCompletionDates(id: number): void {
    this._actions$.pipe(ofType(PatientCompletionDateStoreActions.DeleteSuccess), take(1), takeUntil(this._destroy$)).subscribe(() => {
      this.initializeEditMode();
      this.showDeletePopup = false;
    });

    this._store$.dispatch(
      PatientCompletionDateStoreActions.DeleteRequest({
        patientId: this.selectedPatientId,
        completionDateId: id
      })
    );
  }

  removeDentistVisit(id: number): void {
    this._actions$.pipe(ofType(PatientDentistVisitStoreActions.DeleteSuccess), take(1), takeUntil(this._destroy$)).subscribe(() => {
      this.initializeEditMode();
      this.showDeletePopup = false;
    });

    this._store$.dispatch(
      PatientDentistVisitStoreActions.DeleteRequest({
        patientId: this.selectedPatientId,
        dentistVisitId: id,
      })
    );
  }

  removeModelBox(id: number): void {
    this._actions$.pipe(ofType(PatientModelBoxStoreActions.DeleteSuccess), take(1), takeUntil(this._destroy$)).subscribe(() => {
      this.initializeEditMode();
      this.showDeletePopup = false;
    });

    this._store$.dispatch(
      PatientModelBoxStoreActions.DeleteRequest({
        patientId: this.selectedPatientId,
        modelBoxId: id,
      })
    );
  }

  editPatientTreatmentStatus(patientTreatmentStatus: PatientTreatmentStatusStoreEntity): void {
    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }

    this.userPermissionSubscription = this._userPermissionsService.hasPermission(PERMISSIONS.Location.TreatmentCards.EditPatientCard, ACTIVE_PATIENT_LOCATION)
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe(hasPermission => {
        if (hasPermission) {
          this.initializeTreatmentStatusEdit(patientTreatmentStatus);
          this.enterEditMode(this.keyDatesTypes.treatmentStatus);
        } else {
          let data = {
            title: 'Permission Error',
            message: "Sorry, you don't have rights to edit treatment status. Please see the administrator."
          };

          this.dialog.open(MessageDialog, data);
        }
      })


  }

  editPatientRecall(patientRecall: PatientRecallStoreEntity): void {
    this.initializePatientRecallEdit(patientRecall);
    this.enterEditMode(this.keyDatesTypes.patientRecall);
  }

  editPatientDentistVisit(patientDentistVisit: PatientDentistVisitStoreEntity): void {
    this.initializeDentistVisitEdit(patientDentistVisit);
    this.enterEditMode(this.keyDatesTypes.dentistVisit);
  }

  editPatientModelBox(patientModelBox: PatientModelBoxStoreEntity): void {
    this.initializeModelBoxEdit(patientModelBox);
    this.enterEditMode(this.keyDatesTypes.modelBox);
  }

  enterEditMode(type: string): void {
    this.initializeEditMode(type);
  }

  initializeEditMode(exceptType?: string): void {
    _.each(this.keyDatesTypes, (keyDatesType) => {
      this.editMode[keyDatesType] = exceptType && exceptType === keyDatesType;
    });
  }

  selectDentistInput(elem, editmode) {
    let selectedProfsnl: any = elem.option.value;

    if (editmode == true) {
      this.dentistInput.nativeElement.value = `${selectedProfsnl[0].displayName}`;
    } else {
      this.adddentist.nativeElement.value = `${selectedProfsnl[0].displayName}`;
    }

    this.patientDentistVisitFormGroup.patchValue({
      dentistId: selectedProfsnl[0].id,
      clinicId: selectedProfsnl[1].id,
    });
  }

  searchReffaral() {
    if (this.dentistInput) {
      fromEvent(this.dentistInput.nativeElement, 'keyup')
        .pipe(
          debounceTime(500),
          filter((e: KeyboardEvent) => e.keyCode !== 40),
          filter((e: KeyboardEvent) => e.keyCode !== 38),
          map((userInput: any) => userInput.target.value)
        )
        .subscribe((res) => {
          let searchkeyword: any = res;

          if (searchkeyword.length > 0) {
            this._dentistClient.dentist_GetDentists(searchkeyword)
              .pipe(
                takeUntil(this._destroy$),
                take(1)
              )
              .subscribe(
                (resp) => {

                  if (resp.length > 0) {
                    this.referralProfnl = resp;
                  } else {
                    this.referralProfnl = this.dentists;
                  }

                },
                (err) => {
                  console.log(err);
                }
              );
          } else {

            this.referralProfnl = [];
          }
        });
    }

    if (this.adddentist) {
      fromEvent(this.adddentist.nativeElement, 'keyup')
        .pipe(
          debounceTime(500),
          filter((e: KeyboardEvent) => e.keyCode !== 40),
          filter((e: KeyboardEvent) => e.keyCode !== 38),
          map((userInput: any) => userInput.target.value)
        )
        .subscribe((res) => {
          let searchkeyword: any = res;
          this.referralProfnl = [];

          if (searchkeyword.length > 0) {
            this._dentistClient.dentist_GetDentists(searchkeyword)
              .pipe(
                takeUntil(this._destroy$),
                take(1)
              )
              .subscribe(
                (resp) => {
                  console.log("dentists on resp: ", this.dentists)

                  if (resp.length > 0) {
                    this.referralProfnl = resp;
                  } else {

                    this.referralProfnl = this.dentists;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.referralProfnl = [];
          }
        });
    }
  }

  getVisitingDentist(dentistId, clinicId) {
    let dentist: any = {
      displayName: '',
      clinicName: '',
    };

    if (dentistId) {
      let visitingDentist: any = this.dentists.find((doc) => doc.id == dentistId);
      if (visitingDentist != undefined) {
        dentist.displayName = visitingDentist.displayName;
        if (clinicId && clinicId != null && clinicId != 'null') {
          let _clinicName = visitingDentist.clinics.find((clinic) => clinic.id == clinicId);
          if (_clinicName) {
            dentist.clinicName = _clinicName.name;
          }
        }
      }
    }

    return dentist;
  }

  getISOString(date: any, time?:any) {
    if (!date || !(date instanceof Date))
      return date;

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let utcDate;

    if(time){
      let _time = moment(time, 'hh:mm a');
      utcDate = new Date(Date.UTC(year, month, day, _time.hours(), _time.minutes()));
    } else {
      utcDate = new Date(Date.UTC(year, month, day));
    }
    return utcDate.toISOString();
  }

  noDelete() {
    this.showDeletePopup = false;
  }

  confirmDelete() {

    switch (this.seelctedDeleteType) {
      case this.keyDatesTypes.treatmentStatus: {
        this.removeTreatmentStatus(this.selectedDeleteId);
        break;
      }
      case this.keyDatesTypes.dentistVisit: {
        this.removeDentistVisit(this.selectedDeleteId);
        break;
      }
      case this.keyDatesTypes.modelBox: {
        this.removeModelBox(this.selectedDeleteId);
        break;
      }
      case this.keyDatesTypes.patientRecall: {
        this.removePatientRecall(this.selectedDeleteId);
        break;
      }

      case this.keyDatesTypes.completionDates: {
        this.removeCompletionDates(this.selectedDeleteId);
        break;
      }

      case this.keyDatesTypes.patientXrays: {
        this.removePatientXray(this.selectedDeleteId);
        break;
      }
    }
  }

  calcuateDateFromMonth() {
    let formValue: any = this.patientCompletionDateFormGroup.value;
    if (formValue.estCompletionMnth) {
      this.patientCompletionDateFormGroup.patchValue({
        estimateCompletionDate: moment().add(formValue.estCompletionMnth, 'month').toDate()
      })
    } else {
      this.initializeCompletionDateEdit();
    }
  }

  removeRecall(type: string) {
    let patientRecallValue = this.patientRecallFormGroup.value;
    this.seelctedDeleteType = type;
    this.selectedDeleteId = patientRecallValue.id;
    this.showDeletePopup = true;
  }

  toggleActiveInactive(patientRecall) {

    if (patientRecall.id === this.headPatientRecall.id) {
      if (this.headPatientRecall.isActive) {
        this._patientClient.patient_PutPatientRecallInactive(this.selectedPatientId, this.headPatientRecall.id, null, this.headPatientRecall.eTag)
          .pipe(
            takeUntil(this._destroy$),
            take(1)
          )
          .subscribe(res => {
            this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
          })
      } else {
        this._patientClient.patient_PutPatientRecallActive(this.selectedPatientId, this.headPatientRecall.id, null, this.headPatientRecall.eTag)
          .pipe(
            takeUntil(this._destroy$),
            take(1)
          )
          .subscribe(res => {
            this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
          })
      }
    } else {

      forkJoin(
        this._patientClient.patient_PutPatientRecallInactive(this.selectedPatientId, this.headPatientRecall.id, null, this.headPatientRecall.eTag),
        this._patientClient.patient_PutPatientRecallActive(this.selectedPatientId, patientRecall.id, null, patientRecall.eTag)
      )
        .pipe(
          takeUntil(this._destroy$),
          take(1)
        )
        .subscribe(res => {
          this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
        })
    }
  }

  putToReacall(formValue) {
    let patientStatus = _.find(this.patientStatuses, ['id', formValue.patientStatusId]);
    if (patientStatus) {
      if (Number(patientStatus.code) > 3000 && Number(patientStatus.code) < 4000) {
        this.putToRecalldialogRef = this.matDialog.open(this.puttoRecallDialog);
        this.putToRecalldialogRef.afterClosed()
          .pipe(
            takeUntil(this._destroy$),
            take(1)
          )
          .subscribe(dialogResponse => {
            if (dialogResponse) {
              this.recallForm.patchValue({
                patientStatus: this.patientTreatmentStatusFormGroup.value.patientStatusId,
                recalldate: null
              })
              this.recallDialogRef = this.matDialog.open(this.recallDialog);
            }
          })
      }
    }
  }

  saveRecallDate() {
    this.recallSubmitted = true;
    if (this.recallForm.valid) {
      let patientRecallModel: any = {
        patientId: this.selectedPatientId,
        patientStatusId: this.recallForm.value.patientStatus,
        recallDate: this.recallForm.value.recalldate,
        isActive: true
      }

      this._patientClient.patient_PostPatientRecall(this.selectedPatientId, patientRecallModel, null)
        .pipe(
          takeUntil(this._destroy$),
          take(1)
        )
        .subscribe((res) => {
          this._store$.dispatch(PatientRecallStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
          this.recallSubmitted = false;
          this.recallDialogRef.close(null);
          this.openSnackBar("Successfully added to recall", "");
        },
          (err) => console.log('recall post error:', err));
    }
  }

  calculateRecallDt(evt) {
    let datenow: any = moment().toDate();
    let recallDt: any = moment(datenow).add(evt.value, 'M')
    this.recallForm.patchValue({
      recalldate: recallDt.toDate(),
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getPatientHistory(patientId) {
    this.isPatientHistoryWorking = true;
    this.selectedPatientId = patientId
    this._patientClient.patient_GetPatientXrays(this.selectedPatientId)
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe(resp => {
        this.patientXraysArr = _.orderBy(resp, 'takenWhen', 'desc');
        this.isPatientHistoryWorking = false;
      },
        err => {
          this.isPatientHistoryWorking = false;
          this.openSnackBar(err.message, 'OK');
        })
  }

  initializePatientXrays(patientXray?: PatientXrayDto) {
    if (patientXray) {

      let patientXrayTime = moment(patientXray.takenWhen).format('hh:mm a');

      this.patientXrayForm.patchValue({
        id: patientXray.id,
        type: patientXray.type,
        takenwhen: moment(patientXray.takenWhen).toDate(),
        comment: patientXray.comments,
        eTag: patientXray.eTag,
        patientxraytime: patientXrayTime
      })

    } else {
      this.patientXrayForm = this._formBuilder.group({
        id: [''],
        type: ['', Validators.required],
        takenwhen: [new Date(), Validators.required],
        comment: [''],
        eTag: [''],
        patientxraytime: ['']
      });
    }
  }

  savePatientXray() {
    if (!this.patientXrayForm.valid) return;
    this.isPatientHistoryWorking = true;
    let formValue = this.patientXrayForm.value;
    let patientXrayDto = new PatientXrayDto();
    patientXrayDto.patientId = this.selectedPatientId;
    patientXrayDto.comments = formValue.comment;

    let _takenwhen = moment(formValue.takenwhen);
    let takenTime = moment(formValue.patientxraytime, 'hh:mm a');

    _takenwhen = _takenwhen.set('hours', takenTime.hours()).set('minutes', takenTime.minutes());

    patientXrayDto.takenWhen = moment(_takenwhen).toDate();
    patientXrayDto.type = formValue.type
    if (formValue.id) {
      patientXrayDto.eTag = formValue.eTag,
        patientXrayDto.id = formValue.id;

      this._patientClient.patient_PutPatientXray(this.selectedPatientId, formValue.id, patientXrayDto, null, formValue.eTag)
        .pipe(
          takeUntil(this._destroy$),
          take(1)
        )
        .subscribe(resp => {
          this.initializeEditMode();
          this.getPatientHistory(this.selectedPatientId)
          this.isPatientHistoryWorking = false;
        },
          err => {
            this.isPatientHistoryWorking = false;
            this.openSnackBar(err.message, 'OK');
          })

    } else {
      this._patientClient.patient_PostPatientXray(this.selectedPatientId, patientXrayDto)
        .pipe(
          takeUntil(this._destroy$),
          take(1)
        )
        .subscribe(resp => {
          this.initializeEditMode();
          this.getPatientHistory(this.selectedPatientId)
          this.isPatientHistoryWorking = false;
        },
          err => {
            this.isPatientHistoryWorking = false;
            this.openSnackBar(err.message, 'OK');
          })
    }
  }

  editPatientXray(patientXray?: PatientXrayDto) {
    this.initializePatientXrays(patientXray);
    this.enterEditMode(this.keyDatesTypes.patientXrays)
  }

  removePatientXray(id) {
    this.isPatientHistoryWorking = true;
    this._patientClient.patient_DeletePatientXray(this.selectedPatientId, id)
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe(resp => {
        this.initializeEditMode();
        this.showDeletePopup = false;
        this.getPatientHistory(this.selectedPatientId);
        this.isPatientHistoryWorking = false;
      },
        err => {
          this.isPatientHistoryWorking = false;
          this.openSnackBar(err.message, 'OK');
        })
  }
}

class PatientTreatmentStatusStoreEntityVm extends PatientTreatmentStatusStoreEntity {
  patientStatusCode: string;
  patientStatusDescription: string;
}

class PatientRecallStoreEntityVm extends PatientRecallStoreEntity {
  patientStatusCode: string;
  patientStatusDescription: string;
  isActive: boolean;
}
