import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthStoreSelectors, LocationsStoreSelectors, LocationStoreEntity, PatientsStoreSelectors, RootStoreState } from '@root-store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import {
  ACTIVE_LOCATION,
  ACTIVE_PATIENT_LOCATION,
  EvoPermission,
  EvoPermissions,
  FULL_ACCESS,
  GLOBAL_PERMISSION,
  GLOBAL_PERMISSION_TYPE,
  INTERNAL_USER_ROLE,
  PERMISSION_AREA,
} from './jwt.model';
import { parsePermissions } from './reviver';

@Injectable({ providedIn: 'root' })
export class UserPermissionsService {
  private _permissions$ = this._store$.select(AuthStoreSelectors.selectUserPermissions);
  private _roles$ = this._store$.select(AuthStoreSelectors.selectUserRoles);
  private _userLocationIds$ = this._store$.select(AuthStoreSelectors.selectUserLocationIds);
  private _selectedLocationId$ = this._store$.select(LocationsStoreSelectors.getSelectedLocationId).pipe(filter((id) => !!id));
  private _selectedPatientLocationId$ = this._store$
    .select(PatientsStoreSelectors.getSelectedPatient)
    .pipe(map((patient) => patient && patient.locationId));

  constructor(private _store$: Store<RootStoreState.State>) {}

  /**
   * Check if the currently authenticated user is authorized with a specific permission
   * @param permission - String indicating specific permission
   * @param id - Either a number indicating a locationId, 'Global' for universal level permissions, or `ACTIVE_LOCATION` to use the curently active location. Defaults to `GLOBAL_PERMISSION`.
   * @returns Observable emitting a boolean indicating whether access is permitted
   */
  hasPermission(permission: EvoPermission, area: PERMISSION_AREA = GLOBAL_PERMISSION): Observable<boolean> {
    return combineLatest([this._permissions$, this._selectedLocationId$, this._selectedPatientLocationId$]).pipe(
      take(1),
      map(([perms, selectedLocationId, selectedPatientLocationId]) => {
        let id: PERMISSION_AREA = area;
        if (area == ACTIVE_LOCATION) {
          //if 'ACTIVE_LOCATION' area specified use selectedLocationId from store
          id = selectedLocationId;
        } else if (area == ACTIVE_PATIENT_LOCATION) {
          //if 'ACTIVE_PATIENT_LOCATION' area specified, use LocationId from active patient
          id = selectedPatientLocationId;

          //if no patient active in store then short circuit and return no permission
          if (id == null) return false;
        }

        if (permission == null || permission == undefined) throw new Error('Permission not specified');
        if (id == null || id == undefined) throw new Error('Permission Area not specified');

        if (perms == null || perms[id] == null) return false;

        //first check for FULL_ACCESS to entire area
        if (perms[id].includes(FULL_ACCESS)) return true;

        //second check for exact permission
        if (perms[id].includes(permission)) return true;

        //if no exact then walk up tree looking for `FULL_ACCESS`
        const tree = permission.split('.');
        for (let x = 1; x < tree.length; x++) {
          let fullPerms = tree.slice(0, -x).join('.') + `.${FULL_ACCESS}`;
          if (perms[id].includes(fullPerms)) {
            return true;
          }
        }

        //not authorized
        return false;
      }),
      catchError((err) => of(false)),
      distinctUntilChanged()
    );
  }

  /**
   * Get a list of the locationIds currently available to user via their permissions
   * @returns Observable emitting an array of locationIds available to the currently active user
   */
  userAvailableLocationIds(): Observable<number[]> {
    return this._userLocationIds$;
  }

  /**
   * Get a list of locations based upon the permissions of the current user
   * @returns Observable emitting an array of locations based upon the currently active users permissions
   */
  userAvailableLocations(): Observable<LocationStoreEntity[]> {
    return this.userAvailableLocationIds().pipe(switchMap((ids) => this._store$.select(LocationsStoreSelectors.selectLocationsByIds(ids))));
  }

  /**
   * Get a list of permissions for the current using in a specific location or Global permission
   * @param id - LocationId or `Global` area from which to retrieve permissions
   * @returns Observable emitting an array of `EvoPermission`
   */
  userPermissionsForId(id: number | GLOBAL_PERMISSION_TYPE = GLOBAL_PERMISSION): Observable<EvoPermission[]> {
    return this._permissions$.pipe(map((perms) => perms[id]));
  }

  /**
   * Get whether the current user is an internal user as dictated by roles
   * @returns Observable emitting a boolean that is true when interal user or false when other user or not logged in
   */
  userIsInternal(): Observable<boolean> {
    return this._roles$.pipe(map((roles) => (roles ? roles.includes(INTERNAL_USER_ROLE) : false), distinctUntilChanged()));
  }

  /**
   * Check if a user has applied a current role based upon their permissions.
   * @param roleName - Name of role
   * @returns Observable emitting a boolean that is true when current user has indicated role name
   */
  userHasRole(roleName: string): Observable<boolean> {
    return this._roles$.pipe(map((roles) => (roles ? roles.includes(roleName) : false), distinctUntilChanged()));
  }

  /**
   * Parse an array of user claims related to permissions
   * Expects Location permissions to be in the format of `LocationId.1:Dashboard.Analytics`
   * Expects Global permission to be in the format of `Global:Dashboard.Analytics`
   * @param permissions Array of unparsed user permissions
   * @returns `EvoPermissions` type object
   */
  claimsToUserPermissions(permissions: string[]): EvoPermissions {
    return parsePermissions(permissions);
  }

  /**
   * Parse an `EvoPermissions` object back into the user claims format
   * @param permissions Permissions object
   * @returns Array of strings representing a user's claims
   */
  userPermissionsToClaims(permissions: EvoPermissions): string[] {
    const locationClaims: string[] = [].concat(...permissions.locationIds.map((id) => permissions[id].map((perm) => `LocationId.${id}:${perm}`)));
    const globalClaims: string[] = permissions.Global.map((perm) => `Global:${perm}`);
    return [...locationClaims, ...globalClaims];
  }
}
