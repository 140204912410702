import { Type } from "@angular/core";

import { ICardComponent } from "./ICardComponent";
import { ICardAction } from "./ICardAction";
import { EvolutionColors } from '../../enums';

export const CARD_DESCRIMINATOR = 'IS-CARD-MODULE';

export class ICardModule {
  descriminator: typeof CARD_DESCRIMINATOR;
  cardFront: Type<ICardComponent>;
  cardBack: Type<ICardComponent>;
  cardSelector: string;
  cardHeader?: string;
  cardWidth: number;
  cardHeight: number;
  cardActions?: ICardAction[];
  cardOrder?: number;
  cardColor: EvolutionColors;
}

export function CardModule(params: {
  /**
   * Selector functioning as an ID for this card, must be unique
   * */
  selector: string;
  /**
   * Component to be rendered on the front (default) side of the card
   * */
  front: Type<any>;
  /**
   * Component to be rendered ont he back side of the card
   * */
  back: Type<any>;
  /**
   * Optional text to be inserted into the header bar of the card
   * */
  header?: string;
  /**
   * Width of the card
   * */
  width: number;
  /**
   * Height of the card
   * */
  height: number;
  /**
   * Optional set of action buttons to be inserted into the header
   * */
  actions?: ICardAction[];
  /**
   * Optional value which the cards will be sorted by.
   */
  order?: number;
  /**
   * Color associated with card to be used primarily in the menu
   */
  color?: EvolutionColors;
})
{
  return function (constructor) {
    let proto = (constructor.prototype as ICardModule);

    proto.descriminator = CARD_DESCRIMINATOR;
    proto.cardFront = params.front;
    proto.cardBack = params.back;
    proto.cardSelector = params.selector;
    proto.cardWidth = params.width;
    proto.cardHeight = params.height;
    proto.cardHeader = params.header;
    proto.cardActions = params.actions;
    proto.cardOrder = params.order;
    proto.cardColor = params.color;
  }
}
