import { Router, ActivatedRoute } from '@angular/router';
import { Component, Output, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';
import { VersionCheckService } from './shared/services/utility/version-check.service';
import { EventHubService } from './shared/services/event-hub/event-hub.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Evolution';
  constructor(versionCheckService: VersionCheckService, eventHub: EventHubService, viewContainerRef: ViewContainerRef) {
    this.loadScript(environment.emergePay.assetsUrl);
    versionCheckService.initVersionCheck('./version.json');
    eventHub.Start(viewContainerRef);
  }

  public loadScript(url: string) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
