import { Action, createReducer, on } from "@ngrx/store";
import * as DentistStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(DentistStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedDentistId: null
  })),
  on(DentistStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.dentists, { ...s, isLoading: false, error: null })
  }),
  on(DentistStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(DentistStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedDentistId: null
  })),
  on(DentistStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedDentistId: action.dentist && action.dentist.id
  })),
  on(DentistStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(DentistStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(DentistStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedDentistId: null
  })),
  on(DentistStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(DentistStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
