import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../root-state';
import * as WaitingAppointmentStoreActions from './actions';
import * as WaitingAppointmentStoreSelectors from './selectors';
import { HubEventArea, HubEventType, LocationClient, PatientClient } from 'src/app/shared/services/api.service';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import { LocationsStoreSelectors } from '../location-store';

@Injectable({ providedIn: 'root' })
export class WaitingAppointmentStoreEffects {
  private _selectedLocationId = this.store$.select(LocationsStoreSelectors.getSelectedLocationId);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _locationClient: LocationClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(WaitingAppointmentStoreActions.LoadRequest),
    withLatestFrom(this._selectedLocationId),
    switchMap(([action, locationId]) => {
      return this._locationClient.location_GetScheduleWaitList(locationId).pipe(
        map((result) => WaitingAppointmentStoreActions.LoadSuccess({ waitingAppointments: result })),
        catchError((err: HttpErrorResponse) => of(WaitingAppointmentStoreActions.LoadFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  waitingAppointmentSignalRActionsEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedLocationId),
    filter(
      ([action, locationId]) =>
        locationId &&
        action.event.eventArea == HubEventArea.WaitingAppointmentEvent &&
        action.event.entityId == locationId &&
        action.event.eventType == HubEventType.Modified
    ),
    map(([action]) =>
      WaitingAppointmentStoreActions.LoadRequest()
    ),
  );
}
