import { createAction, props } from "@ngrx/store";
import { ContactReferralDto, FileParameter, PatientDto, PatientReferralDto2 } from 'src/app/shared/services/api.service';
import { IPatientSearchHistory, PatientSearchHistory, PatientStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Patients] Load Request',
  props<{page?: number; pageSize?: number; locationId: number; }>(),
);

export const LoadSuccess = createAction(
  '[Patients] Load Success',
  props<{ patients: PatientStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Patients] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[Patients] Load One Request',
  props<{ id: number; locationId: number }>()
);

export const LoadOneSuccess = createAction(
  '[Patients] Load One Success',
  props<{ patient: PatientStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[Patients] Load One Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Patients] Add Request',
  props<{ patient: PatientStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Patients] Add Success',
  props<{ patient: PatientStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Patients] Delete Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[Patients] Delete Request',
  props<{ id: number; }>()
);

export const DeleteSuccess = createAction(
  '[Patients] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Patients] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Patients] Select Request',
  props<{ id: number; locationId: number; }>()
);

export const SelectSuccess = createAction(
  '[Patients] Select Success',
  props<{ id: number; locationId: number; patient: PatientStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Patients] Select Failure',
  props<{ error: any; }>()
);


//DESELECT
export const DeselectRequest = createAction(
  '[Patients] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Patients] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Patients] Deselect Failure',
  props<{ error: any; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[Patients] Update Request',
  props<{ patient: PatientStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[Patients] Update Success',
  props<{ patient: PatientStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[Patients] Update Failure',
  props<{ error: any; }>()
);

//HISTORY
export const PatientViewed = createAction(
  '[Patients] Patient Viewed',
  props<IPatientSearchHistory>()
)
export const UpdateSearchHistory = createAction(
  '[Patients] Update History',
  props<{ history: PatientSearchHistory[]}>()
)
export const ClearHistory = createAction(
  '[Patients] Clear History',
)

//REFERRAL
export const AssignPatientReferredByRequest = createAction(
  '[Patients] Assign ReferredBy Request',
  props<{ patientId: number; referredByPatientId: number; locationId: number; }>()
);

export const AssignPatientReferredBySuccess = createAction(
  '[Patients] Assign ReferredBy Success',
  props<{ patientId: number; referral: PatientReferralDto2; }>()
);

export const AssignPatientReferredByFailure= createAction(
  '[Patients] Assign ReferredBy Failure',
  props<{ error: any; }>()
);

export const AddReferringContactRequest = createAction(
  '[Patients] Add Referring Contact Request',
  props<{ patientId: number; contactId: number; locationId: number; }>()
);

export const AddReferringContactSuccess = createAction(
  '[Patients] Add Referring Contact Success',
  props<{ patientId: number; referral: ContactReferralDto; }>()
);

export const AddReferringContactFailure = createAction(
  '[Patients] Add Referring Contact Failure',
  props<{ error: any; }>()
);

export const RemoveReferringContactRequest = createAction(
  '[Patients] Remove ReferredBy Request',
  props<{ patientId: number; contactId: number; locationId: number; }>()
);

export const RemoveReferringContactSuccess = createAction(
  '[Patients] Remove ReferredBy Success',
  props<{ patientId: number; }>()
);

export const RemoveReferringContactFailure= createAction(
  '[Patients] Remove ReferredBy Failure',
  props<{ error: any; }>()
);

//UPDATE PROFILE
export const UpdateProfileRequest = createAction(
  '[Patients] Update Profile Request',
  props<{
    patientId: number;
    file: FileParameter;
  }>()
);

export const UpdateProfileSuccess = createAction('[Patients] Update Profile Success', props<{ patient: PatientStoreEntity }>());

export const UpdateProfileFailure = createAction('[Patients] Update Profile Failure', props<{ error: string }>());

//DELETE PROFILE
export const DeleteProfileRequest = createAction(
  '[Patients] Delete Profile Request',
  props<{
    patientId: number;
    locationId: number;
  }>()
);

export const DeleteProfileSuccess = createAction('[Patients] Delete Profile Success', props<{ patientId: number, locationId: number }>());

export const DeleteProfileFailure = createAction('[Patients] Delete Profile Failure', props<{ error: string }>());

export const UpdatePatientStatusId = createAction('[Paitents] Update Patient TreatmentStatusId', props<{ patientId: number, patientStatusId: number }>());

export const SetTxCardAlerts = createAction('[Paitents] Update Patient TxCard Alerts', props<{ alert: boolean }>());
