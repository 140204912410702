import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
    pure: true,
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private _santizer: DomSanitizer) { }
    transform(value: string): SafeResourceUrl {
        return this._santizer.bypassSecurityTrustHtml(value);
    }
}
