import { createAction, props } from "@ngrx/store";
import { TenantStoreEntity } from './state';

export const LoadSuccess = createAction(
  '[Tenant] Load Success',
  props<{ tenants: TenantStoreEntity | TenantStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Tenant] Load Failure',
  props<{ error: any; }>()
);
