export declare type PHOTO_GALLERY_SETTING_KEY =
  'MontageSettings' |
  'CompareSettings' |
  'PhotoGroups' |
  'PhotoTypes';

export declare type PHOTO_GALLERY_SETTING = {
  id: number;
  name: string;
};

export const PHOTO_GALLERY_SETTINGS: { [key in PHOTO_GALLERY_SETTING_KEY]: PHOTO_GALLERY_SETTING } = {
  'MontageSettings': {
    id: 1,
    name: "Montage Settings"
  },
  'CompareSettings': {
    id: 2,
    name: "Compare Settings"
  },
  'PhotoGroups': {
    id: 4,
    name: "Photo Groups"
  },
  'PhotoTypes': {
    id: 5,
    name: "Photo Types"
  },
};
