import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMapTo, filter, map, mapTo, share, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { LocationClient, StorageContentTypeEnum } from 'src/app/shared/services/api.service';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as LocationsStoreActions from './actions';
import * as LocationsStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class LocationsStoreEffects {
  constructor(private store$: Store<State>, private actions$: Actions, private _locationClient: LocationClient) {}

  loadOnLoginRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStoreActions.LoginSuccess, SignalRHubStoreActions.HubStarted),
      withLatestFrom(this.store$.select(LocationsStoreSelectors.selectLocationTotal), this.store$.select(SignalRHubStoreSelectors.selectHubConnectionStatus)),
      filter(([_, total, status]) => status == HubConnectionState.Connected),
      mapTo(LocationsStoreActions.LoadRequest({}))
    )
  );

  unloadOnLogoutRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthStoreActions.LogoutSuccess),
    mapTo(LocationsStoreActions.Unload())
  ));

  changeTenantSuccessEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthStoreActions.ChangeTenantSuccess),
    withLatestFrom(this.store$.select(LocationsStoreSelectors.selectLocationTotal)),
    switchMap(([action, state]) => {
      sessionStorage.setItem(
        'selectedLocationId',
        action.userCredentials && action.userCredentials.defaultLocationId ?
          action.userCredentials.defaultLocationId.toString() :
          null);
      return of(
        LocationsStoreActions.Unload(),
        LocationsStoreActions.LoadRequest({})
      );
    })
  ));

  selectSuccessEffect$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationsStoreActions.SelectSuccess),
        tap((action) => {
          document.title = `Evolution - ${action.location.shortName}`;
          sessionStorage.setItem('selectedLocationId', action.location.id.toString());
        })
      ),
    { dispatch: false }
  );

  loadSuccessEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.LoadSuccess),
      withLatestFrom(this.store$.select(AuthStoreSelectors.selectCredentials)),
      switchMap(([action, credentials]) => {
        const selectedLocationId = parseInt(sessionStorage.getItem('selectedLocationId'));

        if (!!selectedLocationId) {
          return of(LocationsStoreActions.SelectRequest({ id: selectedLocationId }));
        }

        return of(LocationsStoreActions.SelectRequest({ id: credentials.defaultLocationId }));
      })
    )
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.LoadRequest),
      withLatestFrom(this.store$.select((state) => state.Locations)),
      switchMap(([action, state]) =>
        this._locationClient.location_GetLocations(action.pageSize || state.pageSize, action.page || state.page).pipe(
          map((result) => LocationsStoreActions.LoadSuccess({ locations: result })),
          catchError((error) => of(LocationsStoreActions.LoadFailure({ error })))
        )
      )
    )
  );

  loadOneRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.LoadOneRequest),
      switchMap((action) =>
        this._locationClient.location_GetLocation(action.id).pipe(
          map((result) => LocationsStoreActions.LoadOneSuccess({ location: result })),
          catchError((error) => of(LocationsStoreActions.LoadOneFailure({ error })))
        )
      )
    )
  );

  selectRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.SelectRequest),
      withLatestFrom(this.store$.select(LocationsStoreSelectors.selectLocationsState)),
      switchMap(([action, state]) => {
        if (state.ids.length > 0) {
          if (action.id && state.entities[action.id]) {
            return of(LocationsStoreActions.SelectSuccess({ location: state.entities[action.id] }));
          }
          else {
            sessionStorage.setItem('selectedLocationId', state.ids[0].toString());
            return of(LocationsStoreActions.SelectSuccess({ location: state.entities[state.ids[0]] }));
          }
        }
        else {
          return of(LocationsStoreActions.SelectFailure({ error: 'No Locations loaded' }));
        }
      })
    )
  );

  addRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.AddRequest),
      switchMap((action) =>
        this._locationClient.location_PostLocation(action.location).pipe(
          take(1),
          map((result) => LocationsStoreActions.AddSuccess({ location: result })),
          catchError((error) => of(LocationsStoreActions.AddFailure({ error: error })))
        )
      ),
      share()
    )
  );

  updateRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.UpdateRequest),
      switchMap((action) =>
        this._locationClient.location_PutLocation(action.location.id, action.location).pipe(
          take(1),
          //fetch updated location
          switchMap(() => this._locationClient.location_GetLocation(action.location.id)),
          map((location) => LocationsStoreActions.UpdateSuccess({ location: location })),
          catchError((err) => of(LocationsStoreActions.UpdateFailure({ error: err })))
        )
      ),
      share()
    )
  );

  @Effect()
  updateProfileRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(LocationsStoreActions.UpdateProfileRequest),
    switchMap((action) =>
      this._locationClient.location_PutProfile(action.locationId, null, action.file, StorageContentTypeEnum.Profile).pipe(
        map((result) => LocationsStoreActions.UpdateProfileSuccess({ location: result })),
        catchError((err: HttpErrorResponse) => of(LocationsStoreActions.UpdateProfileFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteProfileRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(LocationsStoreActions.DeleteProfileRequest),
    switchMap((action) =>
      this._locationClient.location_DeleteProfile(action.locationId).pipe(
        map((result) => LocationsStoreActions.DeleteProfileSuccess({ locationId: action.locationId })),
        catchError((err: HttpErrorResponse) => of(LocationsStoreActions.DeleteProfileFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteProfileSuccessEffect: Observable<Action> = this.actions$.pipe(
    ofType(LocationsStoreActions.DeleteProfileSuccess),
    switchMap(async (action) => LocationsStoreActions.LoadOneRequest({ id: action.locationId }))
  );
}
