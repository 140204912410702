import { Component, OnInit, Input, Output, EventEmitter,  forwardRef} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import * as moment from 'moment';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class InputComponent implements OnInit {

  @Input() type = 'text';
  @Input() columns = [];
  @Input() model: any;
  @Input() errmsg: string = 'Input required.';
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() value: string;
  @Input() disabled: false;
  @Input() maxLength: number = 255;
  @Input() placeholder: string = '';
  @Input() backgroundColor: string;
  @Input() closeButton: boolean = false;
  @Input() hasError: boolean = false;
  @Input() classList: string = 'native';
  @Input() minDate: Date = null;
  @Output() valueChange = new EventEmitter();
  @Output() click = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Input() mask = false;
  @Input() hasDateMask:boolean = false;

  dateModel: any = "";
  minutesGap:number = 5;

  constructor(){}

  ngOnInit(){

    if(this.type == 'date' && this.hasDateMask){
      if(this.model){
        this.dateModel = moment(this.model).format('MM/DD/YYYY');
      }
    }
  }

  ngOnChanges(){
    if(this.type == 'date' && this.hasDateMask){
      if(this.model){
        this.dateModel = moment(this.model).format('MM/DD/YYYY');
      }
    }
  }
  

  validate() {
    this.hasError = false;

    if (this.required) {
      if(this.type == 'date'){
        this.hasError = this.model == null;
      } else {
        this.hasError = this.model == null || this.model.trim().length <= 0;
      }
      
      return !this.hasError;
    }

    return !this.hasError;
  }

  setErrorMessage(msg: string) {
    this.errmsg = msg;
    return this;
  }

  showErrorMessage() {
    this.hasError = true;
  }

  hideErrorMessage() {
    this.hasError = false;
  }

  onKeydown(e) {
    let isAccepted = this.isKeyAccepted(e);
    if (this.type == 'phone') {
      if (!isAccepted) { e.preventDefault() }
    } else if (this.type == 'decimal') {
      if (!isAccepted) { e.preventDefault(); } else {
        if (this.model != null) {
          let s1 = this.model.toString().split('.');

          if (s1.length > 1) {
            if (e.which == 190) {
              e.preventDefault();
            } else if (s1[1].length >= 2) {
              if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 112)) {
                e.preventDefault();
              }
            }
          }
        }
      }
    }
  }

  isKeyAccepted(e) {
    if (this.type == 'phone') {
      if (e.metaKey || // cmd/ctrl
        e.which <= 0 || // arrow keys
        e.which == 8 || // delete key
        e.which == 9 || // tab key
        (e.keyCode > 95 && e.keyCode < 112) || // num pad
        ((e.ctrlKey || e.metaKey) && e.keyCode == 65) ||// all
        ((e.ctrlKey || e.metaKey) && e.keyCode == 67) ||// copy
        ((e.ctrlKey || e.metaKey) && e.keyCode == 86) || // pase
        /[0-9]/.test(String.fromCharCode(e.which))) {
        return true;
      }
    } else if (this.type == 'decimal') {
      if (e.which == 46 || // delete key
        e.which == 8 || // backspace
        e.which == 9 || // tab key
        e.which == 35 || // end key
        e.which == 36 || // home key
        e.which == 190 || // period key
        e.which == 37 || // arrow keys
        e.which == 38 || // arrow keys
        e.which == 39 || // arrow keys
        e.which == 40 || // arrow keys
        e.which == 110 || // num period
        e.which == 189 || // num period
        e.which == 109 || // num period
        e.which == 187 || // num period
        e.which == 107 || // num period
        (e.keyCode > 95 && e.keyCode < 106) || // num pad
        (e.ctrlKey && e.keyCode == 65) ||// all
        (e.ctrlKey && e.keyCode == 67) ||// copy
        (e.ctrlKey && e.keyCode == 86) || // pase
        /[0-9]/.test(String.fromCharCode(e.which))) {
        return true;
      }
      /* else if (this.type == 'decimal') {
      if (e.which == 46 || // delete key
        e.which == 8 || // backspace
        e.which == 9 || // tab key
        e.which == 35 || // end key
        e.which == 36 || // home key
        e.which == 190 || // period key
        e.which == 37 || // arrow keys
        e.which == 38 || // arrow keys
        e.which == 39 || // arrow keys
        e.which == 40 || // arrow keys
        e.which == 110 || // num period
        (e.keyCode > 95 && e.keyCode < 106) || // num pad
        (e.ctrlKey && e.keyCode == 65) ||// all
        (e.ctrlKey && e.keyCode == 67) ||// copy
        (e.ctrlKey && e.keyCode == 86) || // pase
        /[0-9]/.test(String.fromCharCode(e.which))) {
        return true;
      } else if ((e.keyCode == 189 || e.keyCode == 109 || e.keyCode == 187 || e.keyCode == 107) && e.target.value.length <= 0) {
        return true;
      }
    } */
    }

    return false;
  }

  onChange(newValue) {
    if (this.required) {
      this.hasError = newValue.toString().trim().length <= 0;
    }
    if(this.type == 'date' && this.hasDateMask){
      this.model = moment(this.dateModel).toDate();
    }

    this.valueChange.emit(this.model);
  }

  onDateSelected(newValue) {
    if (this.required) {
      this.hasError = newValue.trim().length <= 0;
    }

    if (!this.hasError) {
      //moment.locale('UTC');
      this.dateModel = moment(newValue).format('L');
    } else {
      this.dateModel = newValue;
    }
  }

  onClick(event) {
    let self = this;

    setTimeout(function () {
      self.click.emit(event);
    });
  }

  clear() {
    this.hideErrorMessage();
    this.model = null;
    this.valueChange.emit(this.model);
  }

  onBlurEvent(newValue) {
    this.onBlur.emit(this.model);
  }

  dateMask() {
		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
		return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
	}

  onDateChange(evt){
    this.dateModel = moment(evt.value).format('MM/DD/YYYY');
    this.model = evt.value;
    this.valueChange.emit(this.model);
  }
}
