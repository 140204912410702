import { Action, createReducer, on } from '@ngrx/store';
import * as UserStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(UserStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedUserId: null,
  })),
  on(UserStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.users, { ...s, isLoading: false, error: null });
  }),
  on(UserStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //LOAD task count
  on(UserStoreActions.LoadTaskCountRequest, (state, action) => ({
    ...state,
    error: null,
  })),
  on(UserStoreActions.LoadTaskCountSuccess, (state, action) => ({
    ...state,
    error: null,
    taskCount: action.taskCount
  })),
  on(UserStoreActions.LoadFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  //FETCH USER
  on(UserStoreActions.FetchRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(UserStoreActions.FetchSuccess, (state, action) => featureAdapter.upsertOne(action.user, { ...state, isLoading: false, error: null })),
  on(UserStoreActions.FetchFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //ADD PHOTO
  on(UserStoreActions.AddPhotoRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(UserStoreActions.AddPhotoSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(UserStoreActions.AddPhotoFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //UPDATE PHOTO
  on(UserStoreActions.UpdatePhotoRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(UserStoreActions.UpdatePhotoSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(UserStoreActions.UpdatePhotoFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //ADD
  on(UserStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(UserStoreActions.AddSuccess, (state, action) => featureAdapter.upsertOne(action.user, { ...state, isLoading: false, error: null })),
  on(UserStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //SELECT
  on(UserStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedUserId: null,
  })),
  on(UserStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedUserId: action.user && action.user.id,
  })),
  on(UserStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //DESELECT
  on(UserStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(UserStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedUserId: null,
  })),
  on(UserStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //UTILITY
  on(UserStoreActions.ResetError, (state) => ({
    ...state,
    error: null,
  })),
  on(UserStoreActions.UpdateTxCardFavorite, (state, action) => ({ ...state, error: null, isLoading: true })),
  on(UserStoreActions.UpdateTxCardFavoriteSuccess, (state, action) =>
    featureAdapter.updateOne({ id: action.userId, changes: { txCardFavorites: action.favorites } }, { ...state, isLoading: false })
  ),
  on(UserStoreActions.UpdateTxCardFavoriteFailure, (state, action) => ({ ...state, error: action.error }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
