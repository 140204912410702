import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlignersComponent } from '../aligners/aligners.component';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { CardModule } from 'src/app/shared/models';
import { MatIconModule, MatButtonModule, MatTooltipModule, MatMenuModule, MatInputModule, MatDatepickerModule, MatProgressBarModule, MatSelectModule, MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

@CardModule({
  selector: 'aligners',
  front: AlignersComponent,
  back: null,
  header: 'Aligners',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faTeeth', actionName: '', isFaIcon: true}],
  order: 120,
})
@NgModule({
  declarations: [AlignersComponent],
  entryComponents: [AlignersComponent],
  imports: [
    CommonModule,
    MatIconModule, 
    MatButtonModule, 
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatMenuModule,
    MatDialogModule,
    TextMaskModule
  ]
})
export class AlignersModule { }
