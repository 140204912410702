import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleMeridian',
})
export class SimpleMeridianPipe implements PipeTransform {
  transform(str: string) {
    return str;
  }
}
