import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IUserDto2, UserDto2 } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<UserStoreEntity> = createEntityAdapter<UserStoreEntity>({
  selectId: user => user.id
});

export interface State extends EntityState<UserStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedUserId?: string;
  taskCount?: number
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedUserId: null,
  page: 0,
  pageSize: 20,
  taskCount: 0,
  isLoading: false,
  error: null
});

export class UserStoreEntity extends UserDto2 {
  constructor(data?: IUserDto2) {
    super(data);
  }
}
