import { SafeUrl } from "@angular/platform-browser";
import { ImageTransform } from "ngx-image-cropper";
import { FillTypeEnum } from "../../shared/services/api.service";

export enum AdvancedEditorActions {
  Cancel = 'Cancel',
  Save = 'Save',
  Remove = 'Remove'
}

export interface AdvancedEditorTransform extends ImageTransform {
  canvasRotation?: number;
}

export interface AdvancedEditorData {
  locationUrl?: string,
  originalLocationUrl?: string,
  image?: any,
  imageBase64?: string,
  originalImage?: any,
  enableRemovePhoto?: boolean,
  enableReloadOriginal?: boolean,
  imageTransform?: AdvancedEditorTransform,
  originalImageTransform?: AdvancedEditorTransform,
  width?: number,
  height?: number,
  enableResize?: boolean,
  fillType?: FillTypeEnum
}
