import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IVoiceHubEvent, VoiceHubEvent } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<ActiveVoiceStoreEntity> = createEntityAdapter<ActiveVoiceStoreEntity>({
  selectId: activeVoice => activeVoice.toExtension
});

export interface State extends EntityState<ActiveVoiceStoreEntity> {
  isLoading?: boolean;
  error?: any;
  selectedToExtension?: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  selectedToExtension: null
});

export class ActiveVoiceStoreEntity extends VoiceHubEvent {
  constructor(data?: IVoiceHubEvent) {
    super(data);
  }
}
