import { createAction, props } from '@ngrx/store';
import { ContactSearch } from 'src/app/shared/services/api.service';

export const SearchRequest = createAction(
  '[Contacts] Search Request',
  props<{ search: string; page: number; pageSize: number }>()
);
export const SearchSuccess = createAction(
  '[Contacts] Search Success',
  props<{ contacts: ContactSearch[]; }>()
);
export const SearchFailure = createAction(
  '[Contacts] Search Failure',
  props<{ err?: any; }>()
);

export const LoadRequest = createAction(
  '[Contacts] Load One Request',
  props<{ contactId: number}>()
);
export const LoadSuccess = createAction(
  '[Contacts] Load One Success',
  props<{ contact: ContactSearch}>()
);
export const LoadFailure = createAction(
  '[Contacts] Load One Failure',
  props<{ err?: any}>()
);
