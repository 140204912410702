import { createAction, props } from "@ngrx/store";
import { PhotoGroupStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Photo Group] Load Request',
  props<{page?: number; pageSize?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Photo Group] Load Success',
  props<{ photoGroups: PhotoGroupStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Photo Group] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[Photo Group] Load One Request',
  props<{ id: number; }>()
);

export const LoadOneSuccess = createAction(
  '[Photo Group] Load One Success',
  props<{ photoGroup: PhotoGroupStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[Photo Group] Load One Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Photo Group] Add Request',
  props<{ photoGroup: PhotoGroupStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Photo Group] Add Success',
  props<{ photoGroup: PhotoGroupStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Photo Group] Delete Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[Photo Group] Delete Request',
  props<{ id: number; }>()
);

export const DeleteSuccess = createAction(
  '[Photo Group] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Photo Group] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Photo Group] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Photo Group] Select Success',
  props<{ photoGroup: PhotoGroupStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Photo Group] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[Photo Group] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Photo Group] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Photo Group] Deselect Failure',
  props<{ error: any; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[Photo Group] Update Request',
  props<{ id: number; photoGroup: PhotoGroupStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[Photo Group] Update Success',
  props<{ photoGroup: PhotoGroupStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[Photo Group] Update Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Photo Group] Reset Error'
);
