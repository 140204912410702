import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IPatientMiniCardsDto, PatientMiniCardsDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientMiniCardsStoreEntity> = createEntityAdapter<PatientMiniCardsStoreEntity>({
});

export interface State extends EntityState<PatientMiniCardsStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});

export class PatientMiniCardsStoreEntity extends PatientMiniCardsDto {
  constructor(data?: IPatientMiniCardsDto) {
    super(data);
  }
}
