import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthenticationStoreEffectsService, AuthStoreActions, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _store$: Store<RootStoreState.State>,
    private _router: Router,
    private _authStoreEffectsService: AuthenticationStoreEffectsService
  ) {}
  // check if user is logged in before routing to protected page, if not, route to login screen
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const time = Date.now();
    return combineLatest([
      this._store$.select(AuthStoreSelectors.selectCredentials),
      this._store$.select(AuthStoreSelectors.selectIsUserTokenExpired(time)),
    ]).pipe(
      take(1),
      //check if authenticated or not authenticated but credentials present (may need refresh) and either return value or send back to the login
      switchMap(([credentials, isCredentialsExpired]) => {
        if (credentials && isCredentialsExpired) {
          //credentials present but expired, try refresh
          setTimeout(() => this._store$.dispatch(AuthStoreActions.RefreshRequest({ id: credentials.id, accessToken: credentials.accessToken, refreshToken: credentials.refreshToken })), 0);
          //wait for refresh results
          return this._authStoreEffectsService.refreshRequestEffect$.pipe(
            take(1),
            map((result) => {
              //if refresh successful continue, otherwise direct back to root (or generic patient error when in patient portal)
              if (result.type == AuthStoreActions.RefreshSuccess.type) return true;
              else if (state.url.startsWith('/patient')) return this._router.parseUrl('/patient/error');
              else return this._router.parseUrl('/');
            })
          );
        }
        if (isCredentialsExpired === undefined || isCredentialsExpired === null) {
          //No credentials, send back to root or patient error when at patient-portal
          if (state.url.startsWith('/patient')) return of(this._router.parseUrl('/patient/error'));
          else return of(this._router.parseUrl('/'));
        } else return of(true);
      })
    );
  }
}
