import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, switchMapTo, take, withLatestFrom } from 'rxjs/operators';
import { UserClient, UserTxCardFavoritesDto, UserTypeFilterEnum } from 'src/app/shared/services/api.service';
import * as AuthStoreActions  from '../auth-store/actions';
import * as AuthStoreSelectors from '../auth-store/selectors';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as InternalUserStoreActions from './actions';
import * as InternalUserStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class InternalUserStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _userClient: UserClient) {}

  @Effect()
  loginLoadUsersEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AuthStoreActions.LoginSuccess, SignalRHubStoreActions.HubStarted),
    withLatestFrom(this.store$.select(SignalRHubStoreSelectors.selectHubConnectionStatus)),
    filter(([_, status]) => status == HubConnectionState.Connected),
    switchMapTo(this.store$.select(AuthStoreSelectors.selectIsPatient)),
    filter(isPatient => !isPatient),
    mapTo(InternalUserStoreActions.LoadRequest({}))
  );

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InternalUserStoreActions.LoadRequest),
    switchMap((action) =>
      this._userClient.user_GetInternalAdminUsers().pipe(
        map((result) => InternalUserStoreActions.LoadSuccess({ users: result })),
        catchError((err: HttpErrorResponse) => of(InternalUserStoreActions.LoadFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  fetchRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InternalUserStoreActions.FetchRequest),
    switchMap((action) =>
      this._userClient.user_GetUser(action.id).pipe(
        map((result) => InternalUserStoreActions.FetchSuccess({ user: result })),
        catchError((err: HttpErrorResponse) => of(InternalUserStoreActions.FetchFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InternalUserStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(InternalUserStoreSelectors.selectInternalUserById(action.id)).pipe(map((result) => InternalUserStoreActions.SelectSuccess({ user: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InternalUserStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(InternalUserStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addPhotoRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InternalUserStoreActions.AddPhotoRequest),
    switchMap((action) =>
      this._userClient.user_PostPhoto(action.userId, action.file).pipe(
        map((result) => InternalUserStoreActions.AddPhotoSuccess({ photo: result })),
        catchError((err: HttpErrorResponse) => of(InternalUserStoreActions.AddPhotoFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updatePhotoRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InternalUserStoreActions.UpdatePhotoRequest),
    switchMap((action) =>
      this._userClient.user_PutPhoto(action.userId, action.photoId, action.file).pipe(
        map((result) => InternalUserStoreActions.UpdatePhotoSuccess({ photo: result })),
        catchError((err: HttpErrorResponse) => of(InternalUserStoreActions.UpdatePhotoFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateTxCardFavoriteReqestEffect = this.actions$.pipe(
    ofType(InternalUserStoreActions.UpdateTxCardFavorite),
    withLatestFrom(
      this.store$
        .select(AuthStoreSelectors.selectedUserTokenId)
        .pipe(switchMap((userId) => this.store$.select(InternalUserStoreSelectors.selectInternalUserById(userId))))
    ),
    filter(([_, user]) => !!user),
    map(([action, user]) => {
      let newFavorites = [...(user.txCardFavorites || [])];
      if (action.isFavorite && !newFavorites.some((c) => c == action.cardSelector)) {
        newFavorites.push(action.cardSelector);
      } else {
        newFavorites = newFavorites.filter((c) => c != action.cardSelector);
      }
      return <[string, string[]]>[user.id, newFavorites];
    }),
    switchMap(([userId, favorites]) =>
      this._userClient.user_PostUser2(userId, new UserTxCardFavoritesDto({ txCardFavorites: favorites })).pipe(
        take(1),
        switchMap((_) =>
          of(InternalUserStoreActions.UpdateTxCardFavoriteSuccess({ userId: userId, favorites: favorites }), InternalUserStoreActions.FetchRequest({ id: userId }))
        ),
        catchError((err) => of(InternalUserStoreActions.UpdateTxCardFavoriteFailure({ error: err })))
      )
    )
  );
}
