import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { withLatestFrom, take, map, takeUntil, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  CephalometricTypeStoreActions,
  CephalometricTypeStoreEntity,
  CephalometricTypeStoreSelectors,
  PatientCephalometricStoreActions,
  PatientCephalometricStoreEntity,
  PatientCephalometricStoreSelectors,
  PatientsStoreSelectors,
  PatientStoreEntity,
  RootStoreState
} from 'src/app/root-store';
import { PatientCephalometricDto, PatientCephalometricOrderDto, PatientCephalometricOrderStatusEnum, PatientClient, StorageContentTypeEnum, StorageTypeEnum } from '../../../shared/services/api.service';
import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { AudaxComponent, AudaxComponentInput } from './audax/audax.component';
import { PatientCephalometricOrderStoreEntity } from 'src/app/root-store/patient-cephalometric-order-store';
import { CephalometricsOrderDialogComponent, CephalometricsOrderDialogData } from './cephalometrics-order-dialog/cephalometrics-order-dialog.component';

@Component({
  selector: 'app-cephalometrics',
  templateUrl: './cephalometrics.component.html',
  styleUrls: ['./cephalometrics.component.css']
})
export class CephalometricsComponent implements OnInit, OnDestroy {
  audaxRef: MatDialogRef<AudaxComponent, any>;
  @ViewChild('formGroupDirective', { static: true }) formGroupDirective: FormGroupDirective;

  selectedPatientCephalometric: PatientCephalometricStoreEntity;
  selectedPatientCephalometricOrder: PatientCephalometricOrderStoreEntity;

  cephalometricFormGroup: FormGroup;
  cephalometricTypes$: Observable<CephalometricTypeStoreEntity[]> = this._store$
    .select(CephalometricTypeStoreSelectors.selectAllCephalometricTypes)
  patientCephalometrics$: Observable<PatientCephalometricStoreEntity[]> = this._store$
    .select(PatientCephalometricStoreSelectors.selectAllPatientCephalometrics)
    .pipe(map(c => {
      this.selectedPatientCephalometric = null;
      if (c.length > 0) this.selectPatientCephalometric(c[0]); //Set default Cephalometrics
      return c;
    }));
  isWorking$ = this._store$
    .select(PatientCephalometricStoreSelectors.selectPatientCephalometricsIsLoading);
  error$: Observable<any> = this._store$.select(PatientCephalometricStoreSelectors.selectPatientCephalometricsError);
  newPatientCephalometric: any;
  isWorking = false;
  patientCephalometricOrderStatusEnum = PatientCephalometricOrderStatusEnum;

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private selectedPatient: PatientStoreEntity;
  private fetchAll$: any;

  constructor(
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private _cdr: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _rootStore$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    private _bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
  ) {
    this.setInitialFormGroup();
  }

  ngOnInit() {
    this.fetchAll$ = this._store$
      .select(PatientsStoreSelectors.getSelectedPatient)
      .subscribe((patient: PatientStoreEntity) => {
        this.selectedPatient = patient;
        if (this.selectedPatient) {
          this._store$.dispatch(PatientCephalometricStoreActions.LoadRequest({ }));
          this._cdr.detectChanges();
        }
      });
    this._rootStore$.dispatch(CephalometricTypeStoreActions.LoadRequest());
  }

  ngOnDestroy() {
    this.fetchAll$.unsubscribe();
    this._destroy$.next(true);
  }

  selectPatientCephalometric(patientCephalometric?: PatientCephalometricStoreEntity) {
    this.selectedPatientCephalometric = patientCephalometric;
    this.selectedPatientCephalometricOrder = patientCephalometric.orders[0];
  }

  add() {
    this.newPatientCephalometric = {
      patientId: this.selectedPatient.id,
      cephalometricTypeId: null,
      file: null,
      contentType: StorageContentTypeEnum.Ceph
    };
    this.formGroupDirective.resetForm(this.newPatientCephalometric);
    this.selectedPatientCephalometric = null;
  }

  setInitialFormGroup() {
    this.cephalometricFormGroup = this._fb.group({
      file: [null, Validators.required],
      patientId: [null, Validators.required],
      contentType: [null, Validators.required],
      cephalometricTypeId: [null, Validators.required]
    });
  }

  save() {
    this.isWorking = true;
    this.newPatientCephalometric = { ...this.newPatientCephalometric, ...this.cephalometricFormGroup.getRawValue() };

    this._patientClient.patient_PostPatientCephalometric(
      this.newPatientCephalometric.patientId,
      this.newPatientCephalometric.cephalometricTypeId,
      this.newPatientCephalometric.file,
      this.newPatientCephalometric.contentType
    )
      .pipe(
        filter(image => !!image),
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((patientCephalometric: PatientCephalometricDto) => {
        this.newPatientCephalometric = null;
        this.selectedPatientCephalometricOrder = patientCephalometric.orders[0];
        this.openAudax(this.selectedPatientCephalometricOrder);
        this.isWorking = false;
        this._cdr.detectChanges();
      });

    //this._store$.dispatch(CephalometricStoreActions.AddRequest({ cephalometric: this.cephalometric }));
  }

  cancel() {
    this._store$.dispatch(PatientCephalometricStoreActions.LoadRequest({}));
    this.newPatientCephalometric = null;
    this._cdr.detectChanges();
  }

  fileChanged(file) {
    if (file) {
      file.data = new Blob([file], { type: file.type });
      file.fileName = file.name;
    }

    this.cephalometricFormGroup.patchValue({
      file: file
    });
  }

  openAudax(patientCephalometricOrder: PatientCephalometricOrderDto): void {
    const input = new AudaxComponentInput();

    this._patientClient.patient_GetPatientCephalometricOrder(this.selectedPatient.id, patientCephalometricOrder.patientCephalometricId, patientCephalometricOrder.id)
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe(result =>
        {
          input.Patient = this.selectedPatient;
          input.CephalometricOrder = result;

          this.audaxRef = this._dialog.open(
            AudaxComponent,
            {
              data: input,
              hasBackdrop: false,
              restoreFocus: false,
              height: '100%',
              width: '100%',
            }
          );
        }, err =>
        {
          this._snackBar.open(err.message, "Ok", {
            duration: 3000,
          });
        });
  }

  addOrder(): void {
    let cephalometricsOrderDialogData: CephalometricsOrderDialogData = {
      patientId: this.selectedPatient.id,
      cephalometricId: this.selectedPatientCephalometric.id
    }

    this._bottomSheet
      .open<CephalometricsOrderDialogComponent, CephalometricsOrderDialogData>(CephalometricsOrderDialogComponent, { data: cephalometricsOrderDialogData })
      .afterDismissed()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        if (result) {
        }
      })
  }

  copyToClipboard(val: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(val).then(() => {
        this._snackBar.open("Copied to Clipboard", "Ok", {
          duration: 3000,
        });
      }, (error) => {
        console.log(error)
      });
    } else {
      this._snackBar.open("Browser do not support Clipboard API", "Ok", {
        duration: 3000,
      });
    }
  }
}
