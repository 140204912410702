import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PERMISSION_AREA } from '@shared/user-permissions';
import { UserPermissionsService } from '@shared/user-permissions/user-permissions.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(private _userPermissionsService: UserPermissionsService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data) {
      const key: string = route.data['PermissionKey'];
      const area: PERMISSION_AREA = route.data['PermissionArea'];
      return this._userPermissionsService.hasPermission(key, area).pipe(map((result) => (result ? true : this._router.parseUrl('/error/403'))));
    }
    return false;
  }
}
