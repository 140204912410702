import { createAction, props } from "@ngrx/store";
import { MarketingPackageStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[MarketingPackage] Load Request'
);

export const LoadSuccess = createAction(
  '[MarketingPackage] Load Success',
  props<{ marketingPackages: MarketingPackageStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[MarketingPackage] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[MarketingPackage] Load One Request',
  props<{ id: number; }>()
);

export const LoadOneSuccess = createAction(
  '[MarketingPackage] Load One Success',
  props<{ marketingPackage: MarketingPackageStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[MarketingPackage] Load One Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[MarketingPackage] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[MarketingPackage] Select Success',
  props<{ marketingPackage: MarketingPackageStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[MarketingPackage] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[MarketingPackage] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[MarketingPackage] Deselect Success',
);

export const DeselectFailure = createAction(
  '[MarketingPackage] Deselect Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[MarketingPackage] Add Request',
  props<{ marketingPackage: MarketingPackageStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[MarketingPackage] Add Success',
  props<{ marketingPackage: MarketingPackageStoreEntity; }>()
);

export const AddFailure = createAction(
  '[MarketingPackage] Delete Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[MarketingPackage] Update Request',
  props<{ id: number; marketingPackage: MarketingPackageStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[MarketingPackage] Update Success',
  props<{ marketingPackage: MarketingPackageStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[MarketingPackage] Update Failure',
  props<{ error: any; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[MarketingPackage] Delete Request',
  props<{ id: number; }>()
);

export const DeleteSuccess = createAction(
  '[MarketingPackage] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[MarketingPackage] Delete Failure',
  props<{ error: string; }>()
);
