import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientTreatmentStatusDto, IPatientTreatmentStatusDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientTreatmentStatusStoreEntity> = createEntityAdapter<PatientTreatmentStatusStoreEntity>({
  selectId: patientTreatmentStatus => patientTreatmentStatus.id,
  sortComparer: (a: PatientTreatmentStatusStoreEntity, b: PatientTreatmentStatusStoreEntity): number => b.fromDate.getTime() - a.fromDate.getTime()
});

export interface State extends EntityState<PatientTreatmentStatusStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientTreatmentStatusId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientTreatmentStatusId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientTreatmentStatusStoreEntity extends PatientTreatmentStatusDto {
  constructor(data?: IPatientTreatmentStatusDto) {
    super(data);
  }
}
