import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap, share, take } from 'rxjs/operators';
import { PatientsStoreActions } from '../patient-store';
import { State } from '../root-state';
import * as ResponsiblePartyStoreActions from './actions';
import * as ResponsiblePartyStoreSelectors from './selectors';
import { LocationClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class ResponsiblePartyStoreEffects {
  constructor(private _actions$: Actions, private _store$: Store<State>, private _locationClient: LocationClient) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.LoadRequest),
      switchMap((action) =>
        this._locationClient.location_GetPatientRelationships(action.locationId, action.patientId, action.pageSize, action.page).pipe(
          map((result) => ResponsiblePartyStoreActions.LoadSuccess({ responsibleParties: result })),
          catchError((err: HttpErrorResponse) => of(ResponsiblePartyStoreActions.LoadFailure({ error: err.message })))
        )
      )
    )
  );

  patientSelectedLoadRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientsStoreActions.SelectSuccess),
      map((action) =>
        ResponsiblePartyStoreActions.LoadRequest({
          patientId: action.id,
          locationId: action.locationId,
        })
      )
    )
  );

  selectRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.SelectRequest),
      switchMap((action) =>
        this._store$
          .select(ResponsiblePartyStoreSelectors.selectResponsiblePartyById(action.id))
          .pipe(map((result) => ResponsiblePartyStoreActions.SelectSuccess({ responsibleParty: result })))
      )
    )
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.UpdateRequest),
      switchMap((action) =>
        this._locationClient
          .location_PutRelationship(action.locationId, action.responsibleParty.patientId, action.responsibleParty.id, action.responsibleParty)
          .pipe(
            switchMap(() =>
              this._locationClient.location_GetRelationship(action.locationId, action.responsibleParty.patientId, action.responsibleParty.id, '')
            ),
            map((result) => ResponsiblePartyStoreActions.UpdateSuccess({ responsibleParty: result })),
            catchError((err: HttpErrorResponse) => of(ResponsiblePartyStoreActions.UpdateFailure({ error: err.message })))
          )
      )
    )
  );

  addRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.AddRequest),
      switchMap((action) =>
        this._locationClient.location_PostRelationship(action.locationId, action.responsibleParty.patientId, action.responsibleParty).pipe(
          map((result) => ResponsiblePartyStoreActions.AddSuccess({ responsibleParty: result })),
          catchError((err: HttpErrorResponse) => of(ResponsiblePartyStoreActions.AddFailure({ error: err.message })))
        )
      )
    )
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.DeleteRequest),
      switchMap((action) =>
        this._locationClient.location_DeleteRelationship(action.locationId, action.patientId, action.id).pipe(
          map(() => ResponsiblePartyStoreActions.DeleteSuccess({ id: action.id })),
          catchError((err: HttpErrorResponse) => of(ResponsiblePartyStoreActions.DeleteFailure({ error: err.message })))
        )
      )
    )
  );

  loadOneRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.LoadOneRequest),
      switchMap((action) =>
        this._locationClient.location_GetRelationship(action.locationId, action.patientId, action.id).pipe(
          map((responsibleParty) => ResponsiblePartyStoreActions.LoadOneSuccess({ responsibleParty: responsibleParty })),
          catchError((err: HttpErrorResponse) => of(ResponsiblePartyStoreActions.LoadOneFailure({ error: err.message })))
        )
      ),
      share()
    )
  );

  patientUpdateSuccessEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientsStoreActions.UpdateSuccess),
      withLatestFrom(this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties)),
      filter(([action, responsibleParties]) => responsibleParties.some((rp) => rp.contactId == action.patient.contactId)),
      map(([action, responsibleParties]) => ({
        responsibleParty: responsibleParties.find((rp) => rp.contactId == action.patient.contactId),
        patient: action.patient,
      })),
      map((data) =>
        ResponsiblePartyStoreActions.LoadOneRequest({
          id: data.responsibleParty.id,
          locationId: data.patient.locationId,
          patientId: data.patient.id,
        })
      ),
      share()
    )
  );

  updateSortOrderRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ResponsiblePartyStoreActions.UpdateSortOrderRequest),
      switchMap((action) =>
        forkJoin(
          action.responsibleParties.map((rp) =>
            this._locationClient.location_PutRelationship(action.locationId, rp.patientId, rp.id, rp).pipe(
              take(1),
              switchMap((_) => this._locationClient.location_GetRelationship(action.locationId, rp.patientId, rp.id).pipe(take(1)))
            )
          )
        ).pipe(
          map((result) => ResponsiblePartyStoreActions.UpdateSortOrderSuccess({ responsibleParties: result })),
          catchError((error) => of(ResponsiblePartyStoreActions.UpdateSortOrderFailure({ error: error })))
        )
      )
    )
  );
}
