import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { USER_TASK_FEATURE_KEY } from './featurekey';
import { featureAdapter, UserTaskStoreEntity, State } from './state';

export const selectUserTasksState: MemoizedSelector<object, State> = createFeatureSelector<State>(USER_TASK_FEATURE_KEY);

export const getSelectedUserTaskId = createSelector(selectUserTasksState, (state) => state.selectedUserTaskId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectUserTasksState);
export const selectUserTaskIds = selectIds;
export const selectAllUserTasks = selectAll;
export const selectAllUserTaskEntities = selectEntities;

export const getSelectedUser = createSelector(selectUserTasksState, selectAllUserTasks, (state, allUserTasks: UserTaskStoreEntity[]) =>
  allUserTasks ? allUserTasks.find((rp) => rp.id === state.selectedUserTaskId) : null
);

export const selectUserTaskById = (id: number) =>
  createSelector(selectAllUserTasks, (allUsers: UserTaskStoreEntity[]) => (allUsers ? allUsers.find((rp) => rp.id === id) : null));

export const selectUserTasksError: MemoizedSelector<object, any> = createSelector(selectUserTasksState, (state) => state.error);

export const selectUserTasksIsLoading: MemoizedSelector<object, boolean> = createSelector(selectUserTasksState, (state: State) => state.isLoading);
