import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, Input} from '@angular/core';
import {
  DentistClient,
  ClinicClient,
  ProfessionalGroupDto,
  DentistDto2,
  ClinicDto
} from 'src/app/shared/services/api.service';

import {MatAccordion} from '@angular/material/expansion';
import { debounceTime, map, filter, take, takeUntil } from 'rxjs/operators';
import { fromEvent, forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { ClinicClientService } from '../../../shared/services/client/clinic-client.service';
import { FetchAllParameter } from '../../../shared/services/client/client.model';
import {DentistClientService} from 'src/app/shared/services/client/dentist-client.service';

@Component({
  selector: 'app-dentist-clinic-otherview',
  templateUrl: './dentist-clinic-otherview.component.html',
  styleUrls: ['./dentist-clinic-otherview.component.scss']
})
export class DentistClinicOtherviewComponent implements OnInit, AfterViewInit {
  @Input() isVerifiedClinic: boolean;
  @Output() getSerarchValue = new EventEmitter();
  @Output() selectClinic = new EventEmitter();
  @ViewChild('searcBox', { static: false }) searcBox: ElementRef;
  @ViewChild('accordion', {static:false}) accordion: MatAccordion;
  @ViewChild('switch', { static: false }) switch: ElementRef;
  public searchVal = '';
  public temp = 'Professionals';
  public filteredList;
  public clinics: any[] = [];
  
  public selection = 'location';
  public selectedGrp:any = {label: 'All', selected: true, shortName: 'All'};
  public professionalGroupDto: Array<ProfessionalGroupDto> = new Array();
  public drGroupArr: any[] = [{label: 'All', selected: false, shortName: 'All'}];
  public showgrpdrpdn = false;
  public prevSelectedFilterGroupIndex: number;

  filterValue: string = '';
  favoritesOnly:boolean = true;
  selectedTabName:string = 'fav';
  isLoading:boolean = false;
  dentists:any[] = [];
  noResult:boolean = false;
  noClinicResult:boolean = false;

  constructor(
    private _dentistClient: DentistClient,
    private _clinicClient: ClinicClient,
    private _clinicClientService: ClinicClientService,
    private _dentistClientService: DentistClientService
  ) {
    this._dentistClient.dentist_GetOptions().subscribe((x) => {
      this.professionalGroupDto = x['professionalGroups'];
      this.professionalGroupDto.forEach((p) => {

        this.drGroupArr.push({
          label: p.name,
          selected: false,
          shortName: p.shortName,
        });
      });
    });
  }

  ngOnInit() {
    this.getClinics();
  }

  getDentists(){
    this.isLoading = true;
    this.filteredList = [];
    forkJoin({dentists: this.getAllDentists()}).subscribe(({ dentists }) => {
      this.dentists = dentists;
      this.filteredList = _.orderBy(this.dentists, (d => d.displayName.toLowerCase()));
      this.isLoading = false;

      if(this.filteredList.length > 0){
        this.noResult = false;
      } else {
        this.noResult = true;
      }
    });
  }

  getClinics(){
    this.isLoading = true;
    this.filteredList = [];
    forkJoin({clinics: this.getAllClinics()}).subscribe(({ clinics }) => {
      this.clinics = clinics
      this.filteredList = _.orderBy(this.clinics, (c => c.name.toLowerCase()));
      this.isLoading = false;

      if(this.filteredList.length > 0){
        this.noClinicResult = false;
      } else {
        this.noClinicResult = true;
      }
    })

  }

  private getAllDentists(): Promise<DentistDto2[]> {
    let params: FetchAllParameter[] = [
      { value: null },
      { value: true },
      { value: this.favoritesOnly },
      { value: 20 },
      { isPage: true },
    ];

    return new Promise(resolve => this._dentistClientService.fetchAll(
      this._dentistClient.dentist_GetDentists,
      params,
      DentistDto2
    ).subscribe(resp => {
      resolve(resp);
    }));
  }

  private getAllClinics(): Promise<ClinicDto[]> {
    let params: FetchAllParameter[] = [
      {value: null},
      { value: true },
      { value: this.favoritesOnly },
      { value: 20 },
      { isPage: true },
    ];

    return new Promise(resolve => this._clinicClientService.fetchAll(
      this._clinicClient.clinic_GetClinics,
      params,
      ClinicDto
    ).subscribe(resp => {
      resolve(resp);
    }));
  }

  public toggleSelection() {
    this.searchVal = '';
    this.searcBox.nativeElement.value = "";
    this.selection = this.selection === 'doctor' ? 'location' : 'doctor';
    this.noResult = false;
    this.noClinicResult = false;

    if(this.favoritesOnly){
      if(this.selection === 'doctor'){
        this.getDentists();
      } else {
        this.getClinics()
      }
    } else {
      this.filteredList = [];
    }
  }

  public constructAddress(add1, add2, city, state) {
    let temp;
    if (add1 !== null) {
      temp = `${add1}`;
    }
    if (add2 !== null) {
      temp += `, ${add2}`;
    }
    if (city !== null) {
      temp += `, ${city}`;
    }
    if (state !== null) {
      temp += `, ${state}`;
    }
    return temp;
  }

  ngAfterViewInit(): void {
    const keyup = fromEvent(this.searcBox.nativeElement, 'keyup');
    keyup
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(1000)
      )
      .subscribe((filter) => {
        this.filterValue = filter;
        this.filteredList = [];
        if(filter.trim().length > 0){
          this.filterDentistOrClinic(filter);
        }
        
      });

      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.searcBox.nativeElement.focus();
      });  
  }

  filterDentistOrClinic(filter: string): void {
    this.isLoading = true;
    if (this.selection === 'doctor') {
      this.noResult = false;
      this._dentistClient.dentist_GetDentists(filter, true, this.favoritesOnly).subscribe((dentists) => {
        this.filteredList = _.chain(dentists)
          .filter((d) => {
            if (this.selectedGrp.shortName != 'All'){
              return d.isActive && d.professionGroupsDisplay.toString().toLowerCase().indexOf(this.selectedGrp.shortName.toLowerCase()) !== -1;
            }
            return d.isActive;
          })
          .orderBy((d => d.displayName.toLowerCase()))
          .value();

          this.isLoading = false;

          if(this.filteredList.length > 0){
            this.noResult = false;
          } else {
            this.noResult = true;
          }
      },
      err => {
        this.isLoading = false;
      });
    } else {
      this.noClinicResult = false;
      this._clinicClient.clinic_GetClinics(filter, true, this.favoritesOnly).subscribe(clinics => {
        this.isLoading = false;
        this.filteredList = _.chain(clinics).filter(c => c.isActive).orderBy((c => c.name.toLowerCase())).value();
        if(this.filteredList.length > 0){
          this.noClinicResult = false;
        } else {
          this.noClinicResult = true;
        }
      },
      err => {
        this.isLoading = false;
      })
    }
  }

  public phoneNumberDisplay(pnumber: any) {
    if (pnumber) {
      return `(${pnumber.substring(0, 3)}) ${pnumber.substring(
        3,
        6
      )}-${pnumber.substring(6, 10)}`;
    }
  }

  selectGrpOptn(indx) {
    this.drGroupArr.map((item) => {
      item.selected = false;
    });
    this.drGroupArr[indx].selected = true;
    this.selectedGrp = this.drGroupArr[indx];

    if (this.prevSelectedFilterGroupIndex !== indx) {
      this.prevSelectedFilterGroupIndex = indx;
    } else {
      this.prevSelectedFilterGroupIndex = null;
      this.selectedGrp = {label: 'All', selected: false, shortName: 'All'};
      this.drGroupArr[indx].selected = false;
    }

    if(this.filterValue.length > 0){
      this.filterDentistOrClinic(this.filterValue);
    }

    

    this.showgrpdrpdn = false;
  }

  onSelectionDentist(dentist, clinic){

    this.accordion.closeAll();
    this.selection = 'doctor';
    this.switch.nativeElement.checked = false;

    this.selectedGrp = {label: 'All', selected: true, shortName: 'All'};

    if(dentist.email == ''){
      dentist.email = null;
    }

    if(clinic.email == ''){
      clinic.email = null;
    }

    this.searcBox.nativeElement.value = "";
    this.getSerarchValue.emit({"dentist": dentist, "clinic": clinic});

    this.filterDentistOrClinic(this.filterValue);
  }

  clinicSelect(clinic: any): void {
    if (!clinic || !clinic.email) return;

    this.selectClinic.emit(clinic);
    this.accordion.closeAll();
  }

  dentistSelect(dentist: any): void {
    if (!dentist || !dentist.email) return;

    this.getSerarchValue.emit({ "dentist": dentist });
    this.accordion.closeAll();
  }

  togglefavoritesOnly(_favoritesOnly, tabname){
    this.favoritesOnly = _favoritesOnly;
    this.searcBox.nativeElement.value = "";
    this.noResult = false;
    this.noClinicResult = false;

    if(this.selectedTabName != tabname){
      if(this.favoritesOnly){
        if(this.selection === 'doctor'){
          this.getDentists();
        } else {
          this.getClinics()
        }
      } else {
        this.filteredList = [];
      }
    }

    this.selectedTabName = tabname;
  }
}
