import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit, AfterViewInit {


  @Input() columns = [];
  @Input() model: NgModel;
  @Input() errmsg: string = 'Input required.';
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  hasError: boolean = false;

  ngOnInit(): void {
  
  }

  ngAfterViewInit(): void {

  }

  validate() {
    return !this.hasError
  }

  keyup(e) {

  }

  onChange(newValue) {
    if (this.required) {
      this.hasError = newValue.trim().length <= 0;
      this.valueChange.emit(this.model);
    }
  }
}