import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DiagnosisStoreEntity } from 'src/app/root-store/treatment-store/diagnosis/state';
import * as _ from 'lodash';

@Component({
  selector: 'app-diagnosis-selector',
  templateUrl: './diagnosis-selector.component.html',
  styleUrls: ['./diagnosis-selector.component.css'],
})
export class DiagnosisSelectorComponent implements OnInit {
  @Input('diagnoses') diagnoses$: Observable<DiagnosisStoreEntity[]>;
  diagnoses:any[] = [];

  @Output('selectionEvent') selectionEvent: EventEmitter<DiagnosisStoreEntity> = new EventEmitter<DiagnosisStoreEntity>();
  @Input() selectedDiagnosis: any;
  
  _selectedDiagnosis: any;

  constructor() {}

  ngOnInit() {
    
    this.diagnoses$.subscribe(resp => {
      this.diagnoses = _(resp).filter('isActive').value();
    })    
  }

  ngOnChanges(){
    if(this.selectedDiagnosis && this.selectedDiagnosis.id && !this.selectedDiagnosis.parentId){
      this._selectedDiagnosis = this.selectedDiagnosis;
    }    
  }

  select(diagnosis: DiagnosisStoreEntity, event: Event) {
    this.selectionEvent.next(diagnosis);
  }

  trackByFn(index, item: DiagnosisStoreEntity) {
    return item.id;
  }
}
