import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { CephalometricTypeDto, ICephalometricTypeDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<CephalometricTypeStoreEntity> = createEntityAdapter<CephalometricTypeStoreEntity>({
  selectId: cephalometricType => cephalometricType.id,
  sortComparer: (a: CephalometricTypeStoreEntity, b: CephalometricTypeStoreEntity): number => b.id - a.id
});

export interface State extends EntityState<CephalometricTypeStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class CephalometricTypeStoreEntity extends CephalometricTypeDto {
  constructor(data?: ICephalometricTypeDto) {
    super(data);
  }
}
