import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatBadgeModule,
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSnackBarModule,
  MatTooltipModule
} from '@angular/material';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ngfModule } from 'angular-file';
import { EvolutionColors } from 'src/app/shared/enums';
import { CardModule } from 'src/app/shared/models';
import { ElementsModule } from '../../../elements/elements.module';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { SafeHtmlModule } from '../../../pipe/safe-html/safe-html.module';
import { NotificationSettingsModule } from '../../../schedule/notification-settings/notification-settings.module';
import { SharedModule } from '../../shared.module';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { PatientFormsViewerComponent } from './patient-form-viewer/patient-forms-viewer.component';
import { PatientFormsBackComponent } from './patient-forms-back/patient-forms-back.component';
import { PatientFormsFrontComponent } from './patient-forms-front/patient-forms-front.component';


@CardModule({
  selector: 'patient-forms',
  front: PatientFormsFrontComponent,
  back: PatientFormsBackComponent,
  header: 'Forms',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{ iconName: 'faFileMedicalAlt', actionName: '', isFaIcon: true }],
  order: 100,
  color: EvolutionColors.Blue,
})
@NgModule({
  declarations: [
    PatientFormsBackComponent,
    PatientFormsFrontComponent,
    PatientFormsViewerComponent
  ],
  entryComponents: [
    PatientFormsBackComponent,
    PatientFormsFrontComponent,
    PatientFormsViewerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MomentDateModule,
    ngfModule,
    ElementsModule,
    MatBadgeModule,
    NotificationSettingsModule,
    MatSnackBarModule,
    SafeHtmlModule,
    MatRadioModule,
    SharedModule
  ],
})
export class PatientFormsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
