import {
  Directive,
  HostListener,
  Optional,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material';

@Directive({ selector: '[appTabDirective]' })
export class TabDirective {
  observable: any;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger) {}

  @HostListener('keydown.tab', ['$event.target']) onBlur() {
    // console.log(this.autoTrigger);
    if (this.autoTrigger.activeOption) {
      this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
      this.ngModelChange.emit(this.autoTrigger.activeOption.value);
    }
  }
}
