import { Action, createReducer, on } from "@ngrx/store";
import * as StationaryStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(StationaryStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(StationaryStoreActions.LoadSuccess, (state, action) =>
    Array.isArray(action.stationaries)
      ? featureAdapter.upsertMany(action.stationaries, featureAdapter.removeAll({ ...state, isLoading: false }))
      : featureAdapter.upsertOne(action.stationaries, { ...state, isLoading: false })
  ),
  on(StationaryStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(StationaryStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  ),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
