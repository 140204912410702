import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ɵConsole, Input, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/core/message.service';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LocationsStoreSelectors, PatientsStoreSelectors, PatientStoreEntity, ProvidersStoreSelectors, RootStoreState, PatientsStoreActions } from 'src/app/root-store';

import {
  SettingsClient,
  PatientClient,
  PatientTeethElasticDefaultDto
} from 'src/app/shared/services/api.service';

import html2pdf from 'html2pdf.js';

declare var Raphael: any;

@Component({
  selector: 'app-elastic-permanent-primary',
  templateUrl: './elastic-permanent-primary.component.html',
  styleUrls: ['./elastic-permanent-primary.component.css']
})
export class ElasticPermanentPrimaryComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  patient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);

  initialX: any;
  initialY: any;
  frstPtClk = false;
  strtlineDr = false;
  midX: any;
  midY: any;
  paper: any;
  line: any;
  lines: any[] = [];
  lnCnt = 0;
  noSelected = false;
  saveCnt = 0;
  selClr_drp = false;
  selElastic: any;
  clrSelctd = "#3f91cd";
  clrSelFlag = false;
  selIndx: any;
  avlbl_clrs: any[] = [
    { clrCode: "#FCFF7C", selctd: false },
    { clrCode: "#FD7474", selctd: false },
    { clrCode: "#74B3FD", selctd: false },
    { clrCode: "#F6CC61", selctd: false },
    { clrCode: "#8CF1E4", selctd: false },
    { clrCode: "#B48CF6", selctd: false },
    { clrCode: "#2EA8A8", selctd: false },
    { clrCode: "#FECACA", selctd: false },
    { clrCode: "#DE473E", selctd: false },
    { clrCode: "#37DBFF", selctd: false },
    { clrCode: "#39D56E", selctd: false },
    { clrCode: "#BF60A4", selctd: false },
    { clrCode: "#BDBE8B", selctd: false },
    { clrCode: "#FF9DEA", selctd: false },
    { clrCode: "#FF9549", selctd: false }
  ];
  lng_desc = '';
  elstc_frm = ''

  toothArr: any[] = [
    {
      section: 'upper', toothArrnagment: [
        { id: 'UR8' }, { id: 'UR7' }, { id: 'UR6' }, { id: 'UR5' }, { id: 'UR4' }, { id: 'UR3' }, { id: 'UR2' }, { id: 'UR1' },
        { id: 'UL1' }, { id: 'UL2' }, { id: 'UL3' }, { id: 'UL4' }, { id: 'UL5' }, { id: 'UL6' }, { id: 'UL7' }, { id: 'UL8' }]
    },
    {
      section: 'lower', toothArrnagment: [
        { id: 'LR8' }, { id: 'LR7' }, { id: 'LR6' }, { id: 'LR5' }, { id: 'LR4' }, { id: 'LR3' }, { id: 'LR2' }, { id: 'LR1' },
        { id: 'LL1' }, { id: 'LL2' }, { id: 'LL3' }, { id: 'LL4' }, { id: 'LL5' }, { id: 'LL6' }, { id: 'LL7' }, { id: 'LL8' }]
    }
  ];


  toothSelectorArr_names: any = [
    { name: 'UR8', selected: false },
    { name: 'UR7', selected: false },
    { name: 'UR6', selected: false },
    { name: 'UR5', selected: false },
    { name: 'UR4', selected: false },
    { name: 'UR3', selected: false },
    { name: 'UR2', selected: false },
    { name: 'UR1', selected: false },
    { name: 'UL1', selected: false },
    { name: 'UL2', selected: false },
    { name: 'UL3', selected: false },
    { name: 'UL4', selected: false },
    { name: 'UL5', selected: false },
    { name: 'UL6', selected: false },
    { name: 'UL7', selected: false },
    { name: 'UL8', selected: false },
    { name: 'LR8', selected: false },
    { name: 'LR7', selected: false },
    { name: 'LR6', selected: false },
    { name: 'LR5', selected: false },
    { name: 'LR4', selected: false },
    { name: 'LR3', selected: false },
    { name: 'LR2', selected: false },
    { name: 'LR1', selected: false },
    { name: 'LL1', selected: false },
    { name: 'LL2', selected: false },
    { name: 'LL3', selected: false },
    { name: 'LL4', selected: false },
    { name: 'LL5', selected: false },
    { name: 'LL6', selected: false },
    { name: 'LL7', selected: false },
    { name: 'LL8', selected: false }
  ];

  toothSelectorArr: any = [];

  toothSelectorOptn: boolean = false;
  selectedTooth: any[] = [];
  showDeletePopup: boolean = false;
  sequenceIndx: any = 0;

  permaPrimaryToothArr: any = [
    { "permanent": "8", "primary": "8" },
    { "permanent": "7", "primary": "7" },
    { "permanent": "6", "primary": "6" },
    { "permanent": "5", "primary": "e" },
    { "permanent": "4", "primary": "d" },
    { "permanent": "3", "primary": "c" },
    { "permanent": "2", "primary": "b" },
    { "permanent": "1", "primary": "a" },
    { "permanent": "1", "primary": "a" },
    { "permanent": "2", "primary": "b" },
    { "permanent": "3", "primary": "c" },
    { "permanent": "4", "primary": "d" },
    { "permanent": "5", "primary": "e" },
    { "permanent": "6", "primary": "6" },
    { "permanent": "7", "primary": "7" },
    { "permanent": "8", "primary": "8" }
  ];


  permanentPrimaryArr_upper: any[] = [
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    }
  ];

  permanentPrimaryArr_lower: any[] = [
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": true, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    },
    { 
      "permanent": true, 
      "primaryavailable": false, 
      "primary": false, 
      "parmaLabel": true, 
      "perio": false, 
      "roots": false, 
      "extracted": false, 
      "peg": false, 
      "ectopic": false, 
      "decal": false, 
      "supernum": false, 
      "impacted": false, 
      "unerupted": false,
      "missing": false,
      "crown": false,
      "implant": false,
      "tad": false,
      "bracket": false,
      "bracketcolor": '#ffffff'
    }
  ];

  optionSequences:any = [
    {"shortName": "X", "name":"Extracted", "selected": false},
    {"shortName": "M", "name":"Missing", "selected": false},
    {"shortName": "I", "name":"Impacted", "selected": false}, 
    {"shortName": "U", "name":"Unerupted", "selected": false},
    {"shortName": "P", "name":"Peg", "selected": false},
    {"shortName": "C", "name":"Crown", "selected": false},
    {"shortName": "S", "name":"Supernum", "selected": false},
    {"shortName": "R", "name":"Short Roots", "selected" : false}, 
    {"shortName": "decal", "name":"Decalcification", "selected": false},
    {"shortName": "perio", "name": "Perio", "selected": false}, 
    {"shortName": "E", "name":"Ectopic", "selected": false}, 
    {"shortName": "implant", "name":"Implant", "selected": false},
    {"shortName": "tad", "name":"Tad", "selected": false}
  ]
  
  @ViewChild('raphaelContainer', { static: false }) raphaelContainer: ElementRef;
  @ViewChild('teethcont', { static: false }) teethcont: ElementRef;
  @ViewChild('teethimg', { static: false }) teethimg: ElementRef;

  @Output() chosenClr = new EventEmitter<any>();
  @Output() elasticOp = new EventEmitter<any>();
  @Output() saveNewElstc = new EventEmitter<any>();

  @Output() saveAddEditEleastic = new EventEmitter<any>();

  elasticSequenceData: any = {};
  editMode: boolean = false;

  startDrawing: boolean = false;
  elasticDrawingForm: FormGroup;
  submitted: boolean = false;

  seqeuenceDrpdnOptn:boolean = false;
  elasticSeqArray: any[] = [];

  drpdnLabel: any = "sequence";
  sequenceInstrc: any = "instructions";
  elasticSection:boolean = true;
  selectedOptionBtn: any = "permanent";
  selectFromDrpdn: boolean = false;

  optionBtnSelected: any[] = [
    {"name": "primary", "selected": false},
    {"name": "permanent", "selected" : true},
    {"name": "extracted", "selected": false},
    {"name": "missing", "selected": false},
    {"name": "impacted", "selected": false},
    {"name": "unerupted", "selected": false},
    {"name": "peg", "selected": false},
    {"name": "crown", "selected": false},
    {"name": "supernum", "selected": false},
    {"name": "roots", "selected": false},
    {"name": "decal", "selected": false},
    {"name": "perio", "selected": false},
    {"name": "ectopic", "selected": false},
    {"name":  "implant", "selected": false},
    {"name":  "tad", "selected": false}
  ];

  patientDetails:any = {};
  editElastic: boolean = false;
  addElastic:boolean = false;
  lasteditedDt: any = new Date();
  selectedEleastic: any = {};

  elasticSequenceArr:any[] = [];
  sequenceElemArr:any[] = [
    {"name": "Add a new seqquence", "id": 1},
    {"name": "Edit older sequence", "id": 2},
  ];
  editsequenceMode: any = 2;

  bracketsList:any[] = [];

  selectedBrackets: any[] = [];
  selectedToothBrackets: any[] = [];
  selectedBracket: any;
  genrtPDF:boolean = false;
  gridDate_PDF: any = "";
  gridInstruction_PDF: any = "";

  @ViewChild('content', { static: false }) content: ElementRef;
  
  drawUWire:boolean = false;
  drawLWire:boolean = false;
  drawUpperWires:any[] = [];
  drawLowerWires:any[] = [];
  upperWireSequence: any = "";
  lowerWireSequence: any = "";

  @ViewChild('upperwirecont', { static: false }) upperwirecont: ElementRef;
  @ViewChild('lowerwirecont', { static: false }) lowerwirecont: ElementRef;

  drawUChain:boolean = false;
  drawLChain:boolean = false;
  drawUpperChains:any[] = [];
  drawLowerChains:any[] = [];

  upperChainSequence: any = "";
  lowerChainSequence: any = "";

  @ViewChild('upperchaincont', { static: false }) upperchaincont: ElementRef;
  @ViewChild('lowerchaincont', { static: false }) lowerchaincont: ElementRef;

  selectedTab:any = 'brackets';

  constructor(
    private msgSer: MessageService, 
    private formBuilder: FormBuilder,
    private settingsClient: SettingsClient,
    private patientClient:PatientClient,
    private _store$: Store<RootStoreState.State>,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.initialzeForm();
    if (this.toothSelectorArr[this.sequenceIndx] == undefined) {
      this.toothSelectorArr[this.sequenceIndx] = [];
      this.toothSelectorArr[this.sequenceIndx] = JSON.parse(JSON.stringify(this.toothSelectorArr_names));
    }

    this.getElasticSequecnes();
    this.getPatientDetails();
    
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialize();
    })
  }

  initialzeForm() {
    this.elasticDrawingForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      color: ['', Validators.required],
      formation: ['', Validators.required],
      date: [''],
      instruction: ['']
    });
  }


  initialize() {
    this.paper = Raphael(this.raphaelContainer.nativeElement, 0, 0, 570, 500);
    this.paper.setSize('100%', '100%');
    this.drawFormation(false);
  }

  drawFormation(drawdata){

    let indx;
    let flag = 0;

    if (this.elasticSequenceData.colorCode) {

      if(this.elasticSequenceData.formationSequence){
        let oldSequenceArr: any[] = this.elasticSequenceData.formationSequence.split(":").map(item => {
          if(item.indexOf(',') != -1){
            return item.split(",");
          } else if(item.indexOf('-') != -1){
            return item.split("-");
          }
          
        });
  
        if(oldSequenceArr.length > 0){ 
  
          oldSequenceArr.map(item => {
            if(Array.isArray(item) == true){
              if(item.length == 3){
                item.splice(1, 0, item[1])
              } else if(item.length % 3 == 0){
  
              }
            }
          })
          
          oldSequenceArr = _.flatten(oldSequenceArr)
  
          if(oldSequenceArr.length % 2 != 0){
            let oddSequenceArr: any = [];
            oldSequenceArr.map((item, indx) => {
              oddSequenceArr.push(item);
              if(indx > 0 && indx != oldSequenceArr.length-1){
                oddSequenceArr.push(item);
              }
            });
            oldSequenceArr = oddSequenceArr;
          }
  
          oldSequenceArr = _.chunk(oldSequenceArr,2);
  
          this.elstc_frm = oldSequenceArr.map(e => e.join('-')).join(',');
        } else {
          this.elstc_frm = this.elasticSequenceData.formationSequence;
        }
      }

      let teethimgWd: any = this.teethimg.nativeElement.offsetWidth;

      let formation_seqn: any[] = this.elstc_frm.split(",").map(item => {
        return item.split("-");
      });


      let lines: any[] = [];

      let lineFormationIndx: any = 0;

      for (let i = 0; i < formation_seqn.length; i++) {

        for (let j = 0; j < formation_seqn[i].length; j++) {

          let _toothItem: any = formation_seqn[i][lineFormationIndx];

          let whichSection: any = _toothItem.substring(0, 1);

          let tothItemIdex = this.toothSelectorArr_names.findIndex(item => _toothItem === item.name);
          let toothIndx: any = 0;

          if (tothItemIdex != -1) {
            if (whichSection == 'U') {
              if (tothItemIdex > 15) {
                toothIndx = (tothItemIdex - 15) + 1;
              } else {
                toothIndx = tothItemIdex + 1;
              }

            } else {
              if (tothItemIdex > 15) {
                toothIndx = (tothItemIdex - 15);
              } else {
                toothIndx = tothItemIdex;
              }
            }

            let strtX: any = toothIndx * teethimgWd + (teethimgWd - 10) / 2;
            let strtY: any = 0;
            if (whichSection == 'U') {
              strtY = 110;
            } else {
              strtY = 167;
            }

            lineFormationIndx += 1;

            let _toothItem_end: any = formation_seqn[i][lineFormationIndx];


            let whichSection_end: any = _toothItem_end.substring(0, 1);
            let tothItemIdex_end: any = this.toothSelectorArr_names.findIndex(item => _toothItem_end === item.name);

            if (whichSection_end == 'U') {
              if (tothItemIdex_end > 15) {
                tothItemIdex_end = (tothItemIdex_end - 15) + 1;
              } else {
                tothItemIdex_end = tothItemIdex_end + 1;
              }
            } else {
              if (tothItemIdex_end > 15) {
                tothItemIdex_end = (tothItemIdex_end - 15);
              } else {
                tothItemIdex_end = tothItemIdex_end;
              }
            }

            let endX: any = tothItemIdex_end * teethimgWd + (teethimgWd - 10) / 2;
            let endY: any = 0;
            if (whichSection_end == 'U') {
              endY = 110;
            } else {
              endY = 167;
            }

            lines.push({ "strtX": strtX, "strtY": strtY, "endX": endX, "endY": endY, "lnClr": this.elasticSequenceData.colorCode });

            if (formation_seqn[i].length % 2 == 0) {
              lineFormationIndx += 1
              if (lineFormationIndx == formation_seqn[i].length) {
                lineFormationIndx = 0
                break;
              }

            } else {
              if (lineFormationIndx == formation_seqn[i].length - 1) {
                lineFormationIndx = 0;
                break;
              }
            }

          }

        }
      }

      this.selClr_drp = false;
      //this.elstc_frm = this.elasticSequenceData.formationSequence;
      this.lines = lines;
      this.clrSelFlag = false;
      this.sequenceIndx = formation_seqn.length-1;

      if (drawdata == true) {
        this.selElastic = this.elasticSequenceData.name;
        this.lng_desc = this.elasticSequenceData.description;
        this.clrSelctd = this.elasticSequenceData.colorCode;

        let elasticFormation: any = this.elstc_frm.replace(/,/g, ':');

        this.elasticDrawingForm.patchValue({
          name: this.selElastic,
          formation: elasticFormation,
          description: this.lng_desc,
          color: this.clrSelctd

        })

        this.avlbl_clrs.forEach((clr, ind) => {
          if (clr.clrCode == this.elasticSequenceData.colorCode) {
            indx = ind;
            flag = 1;
          }
        });
      }

      
      this.selIndx = 0;
     
    }
    this.avlbl_clrs.forEach(ele => {
      ele.selctd = false;
    });
    if (flag == 1) {
      this.avlbl_clrs[indx].selctd = true;
    }
    this.updateSvg();

    if (this.clrSelctd == null) {
      this.clrSelctd = 'black';
      this.clrSelFlag = false;
    }
    else {

      if(this.editMode == true){
        this.clrSelFlag = true;
      } else {
        this.clrSelFlag = false;
      }
      
    }


    this.selectedTooth = [];
    if(this.elstc_frm.length > 0){
      this.selectedTooth = this.elstc_frm.split(",").map(item => {
        return item.split("-");
      });
    }
    for(let m = 0; m<this.sequenceIndx+1; m++){
      this.toothSelectorArr[m] = []
      this.toothSelectorArr[m] = JSON.parse(JSON.stringify(this.toothSelectorArr_names));

      if (this.selectedTooth.length > 0) {
        for (let i = 0; i < this.toothSelectorArr[m].length; i++) {
          for (let j = 0; j < this.selectedTooth[m].length; j++) {

            if (this.toothSelectorArr[m][i].name == this.selectedTooth[m][j]) {
              this.toothSelectorArr[m][i].selected = true;
              break;
            }
          }
        }
      }
    }
  }

  pointClk(event, indx, secId) {
    if (this.startDrawing == true) {
      this.newSelectDraw(event, indx, secId)
    }
  }

  movingMouse(event) {
    if (this.frstPtClk) {
      let svgItm = document.querySelector('.mouse-draw-div > svg');
      if (this.strtlineDr != true) {
        this.strtlineDr = true;
      }
      else {
        svgItm.children[svgItm.children.length - 1].remove();
      }

      let targetPt: any = event.target.getBoundingClientRect();
      let teethcontPt: any = this.teethcont.nativeElement.getBoundingClientRect();
      this.midX = (targetPt.x - teethcontPt.x) + event.offsetX;
      this.midY = (targetPt.y - teethcontPt.y) + event.offsetY;

      let drwPth = `M${this.initialX},${this.initialY} L${this.midX},${this.midY}`
      this.line = this.paper.path(drwPth);
      this.line.attr("stroke", this.clrSelctd);
      this.line.attr("stroke-width", 3);

    }
  }

  updateSvg() {
    let svgItm = document.querySelector('.mouse-draw-div > svg');

    let drwLn;
    while (svgItm && svgItm.children.length != 2) {
      svgItm.children[svgItm.children.length - 1].remove();
    }
    if (this.lines.length) {
      this.lines.forEach(ln => {
        let drwPth = `M${ln.strtX},${ln.strtY} L${ln.endX},${ln.endY}`
        drwLn = this.paper.path(drwPth);
        drwLn.attr("stroke", ln.lnClr);
        drwLn.attr("stroke-width", 3);
        drwLn.attr({ 'arrow-end':   'oval-medium-medium', 
        'arrow-start': 'oval-medium-medium' });
      })
    }
  }

  deletechanges() {

      this.frstPtClk = false;
      this.strtlineDr = false;
      let svgItm = document.querySelector('.mouse-draw-div > svg');
      if (svgItm && svgItm.children.length > 2) {
        svgItm.children[svgItm.children.length - 1].remove();
        if (this.saveCnt && this.lines.length) {
          this.saveCnt--;
        }

        if (this.selectedTooth[this.sequenceIndx].length == 0) {
          this.selectedTooth.pop()
        }

        if (this.selectedTooth[this.sequenceIndx].length % 2 == 0) {
          this.selectedTooth[this.sequenceIndx].splice(this.selectedTooth[this.sequenceIndx].length - 2, 2);
        } else {
          this.selectedTooth[this.sequenceIndx].pop();

        }
        this.lines.pop();

        if (this.selectedTooth[this.sequenceIndx].length == 0) {
          this.selectedTooth.pop()
          this.sequenceIndx--;
          if (this.sequenceIndx < 0) {
            this.sequenceIndx = 0;
          }
        }

        this.elstc_frm = this.selectedTooth.map(e => e.join('-')).join(':');

        let elasticFormation: any = this.elstc_frm.replace(/,/g, ':');

        this.elasticDrawingForm.patchValue({
          formation: elasticFormation
        });
      }
    //}

  }

  deleteAll() {
    this.frstPtClk = false;
    this.strtlineDr = false;

    let svgItm = document.querySelector('.mouse-draw-div > svg');
    while (svgItm && svgItm.children && svgItm.children.length != 2) {
      svgItm.children[svgItm.children.length - 1].remove();
    }

    this.lines = [];
    this.elstc_frm = '';
    this.noSelected = true;
    this.saveCnt = 0;
    this.sequenceIndx = 0;
    this.drpdnLabel = "sequence";
    this.sequenceInstrc = "instructions";
    this.elasticSequenceData = {};
    this.selectedTooth = [];

    this.elasticDrawingForm.reset();
  }

  colorSel(clr) {
    let indx;
    this.avlbl_clrs.forEach((ele, i) => {
      if (ele.clrCode === clr.clrCode) {
        indx = i;
      }
    });

    this.avlbl_clrs.forEach(clr => {
      clr.selctd = false;
    })

    this.avlbl_clrs[indx].selctd = !this.avlbl_clrs[indx].selctd;

    if (this.avlbl_clrs[indx].selctd) {
      this.clrSelctd = this.avlbl_clrs[indx].clrCode;
      this.clrSelFlag = true;
    }
    let flg = 0;
    this.avlbl_clrs.forEach(clr => {
      if (clr.selctd) {
        flg = 1;
      }
    });
    if (flg == 1) {
      this.clrSelFlag = true
    }
    else {
      this.clrSelFlag = false;
      this.clrSelctd = '#3f91cd';
    }

    if (this.lines.length) {
      this.lines.forEach(line => {
        line.lnClr = this.clrSelctd;
      });
      this.updateSvg();
    }

    this.selClr_drp = false;

    this.elasticDrawingForm.patchValue({
      color: this.clrSelctd
    })


  }

  onClickoutside(evnt) {
    this.toothSelectorOptn = false;
  }

  selectToothOptn(indx) {

    if (this.selectedTooth[this.sequenceIndx] == undefined) {
      this.selectedTooth[this.sequenceIndx] = [];
    }

    if (this.toothSelectorArr[this.sequenceIndx] == undefined) {
      this.toothSelectorArr[this.sequenceIndx] = [];
      this.toothSelectorArr[this.sequenceIndx] = JSON.parse(JSON.stringify(this.toothSelectorArr_names));
    }

    if (this.toothSelectorArr[this.sequenceIndx][indx].selected == false) {
      this.toothSelectorArr[this.sequenceIndx][indx].selected = true;

      this.selectedTooth[this.sequenceIndx].push(this.toothSelectorArr[this.sequenceIndx][indx].name);

    } else {
      this.toothSelectorArr[this.sequenceIndx][indx].selected = false;
      let selectedOptnName: any = this.toothSelectorArr[this.sequenceIndx][indx].name;

      let selectedThIndx: any = this.selectedTooth.findIndex(item => item === selectedOptnName);
      this.selectedTooth[this.sequenceIndx].splice(selectedThIndx, 1);

    }

    this.elstc_frm = this.selectedTooth.map(e => e.join('-')).join(':');
    let elasticFormation: any = this.elstc_frm.replace(/,/g, ':');
    this.elasticDrawingForm.patchValue({
      formation: elasticFormation
    })

  }

  newaddtion() {
    this.frstPtClk = false;
    this.noSelected = false;
    this.strtlineDr = false;
    let svgItm = document.querySelector('.mouse-draw-div > svg');
    while (svgItm.children.length != 2) {
      svgItm.children[svgItm.children.length - 1].remove();
    }
    this.lines = [];
    this.saveCnt = 0;
    this.selectedTooth = [];
    this.lng_desc = null;
    this.elstc_frm = '';
    this.selElastic = null;
    this.clrSelctd = '#3f91cd';
    this.clrSelFlag = false;
    this.toothSelectorArr.map(item => {
      item.selected = false;
    })
  }

  newSelectDraw(event, indx, secId) {
    this.selClr_drp = false;
    let toothId: any = this.toothArr[secId].toothArrnagment[indx].id;
    if (!this.selectedTooth[this.sequenceIndx].includes(toothId)) {
      this.selectedTooth[this.sequenceIndx].push(toothId);
    }

    if (this.frstPtClk != true) {
      this.frstPtClk = true;

      let targetPt: any = event.target.getBoundingClientRect();
      let teethcontPt: any = this.teethcont.nativeElement.getBoundingClientRect();
      this.initialX = (targetPt.x - teethcontPt.x) + event.offsetX;
      this.initialY = (targetPt.y - teethcontPt.y) + event.offsetY;

    }
    else {
      this.line.attr({ 'arrow-end':   'oval-medium-medium', 
      'arrow-start': 'oval-medium-medium' });
      this.lnCnt++;
      this.saveCnt++;
      let tmpLn = {
        lnId: this.lnCnt,
        strtX: this.initialX,
        strtY: this.initialY,
        endX: this.midX,
        endY: this.midY,
        lnClr: this.clrSelctd
      };
      this.lines.push(tmpLn);
      this.frstPtClk = false;
      this.strtlineDr = false;
      this.startDrawing = false;
    }

    this.elstc_frm = this.selectedTooth.map(e => e.join('-')).join(':');

    let elasticFormation: any = this.elstc_frm.replace(/,/g, ':');
    this.elasticDrawingForm.patchValue({
      formation: elasticFormation
    })
  }


  stratStopDrawing(mode) {

      if (this.selectFromDrpdn == true) {
        this.deleteAll();
        this.selectFromDrpdn = false;
      }

      if (mode == 'start') {
        this.startDrawing = true;

        if (this.elstc_frm.length > 0) {
          this.sequenceIndx = this.elstc_frm.split(':').length;
        } else {
          this.sequenceIndx = 0;
        }

        if (this.selectedTooth[this.sequenceIndx] == undefined) {
          this.selectedTooth[this.sequenceIndx] = [];
        }

        if (this.toothSelectorArr[this.sequenceIndx] == undefined) {
          this.toothSelectorArr[this.sequenceIndx] = [];
          this.toothSelectorArr[this.sequenceIndx] = JSON.parse(JSON.stringify(this.toothSelectorArr_names));
        }

      } else {
        this.startDrawing = false;
      }


  }


  deleteFormation() {
    this.showDeletePopup = true;

  }

  confirmDelete() {
    this.settingsClient.settings_DeleteElasticSequence(this.elasticSequenceData.id, null)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      (res) => {
        this.elasticDrawingForm.reset();
        this.submitted = false;
        this.saveAddEditEleastic.emit();
      },
      (err) => {
        console.log(err)
      }
    )
  }

  noDelete() {
    this.showDeletePopup = false;
  }

  drpdnboxOutdide(evt){
    this.seqeuenceDrpdnOptn = false;
  }

  openSequenceDrpdn(){
      if(this.addElastic == true || this.editElastic == true){
        this.seqeuenceDrpdnOptn = this.seqeuenceDrpdnOptn == true ? false : true;
      }
    
  }

  getElasticSequecnes(){
    this.settingsClient.settings_GetElasticSequences(null)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      (res) => {
        this.elasticSeqArray = res;
        this.elasticSeqArray.map(elasticSequenceItem => {
          elasticSequenceItem.elasticFormationSequence = elasticSequenceItem.formationSequence.replace(/,/g, '-');
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  editItem(es){
    this.elasticSequenceData = es;
    this.seqeuenceDrpdnOptn = false;
    this.sequenceInstrc = es.description;
    this.selectFromDrpdn = true;
    this.startDrawing = false;

    
    setTimeout(() => {
      this.drawFormation(true);
      this.drpdnLabel = `${es.name} - ${this.elstc_frm.replace(/,/g, ':')}`;
    }, 500)
  }

  togglePrimaryTooth(section, indx) {

    /* if (this.elasticSection == false) { */

      if (this.selectedOptionBtn != null) {
        this.drawUChain = false;
        this.drawLChain = false;
        this.drawUWire = false;
        this.drawLWire = false;
        this.drawUpperChains = [];
        this.drawLowerChains = [];
        this.drawUpperWires = [];
        this.drawLowerWires = [];
      }
      

      if (section == 'upper') {

        if(this.selectedOptionBtn == 'primary'){
          this.permanentPrimaryArr_upper[indx].primary = true;
          this.permanentPrimaryArr_upper[indx].permanent = false;
          this.permanentPrimaryArr_upper[indx].parmaLabel = false;
          
        } else if(this.selectedOptionBtn == 'permanent'){
          this.permanentPrimaryArr_upper[indx].primary = false;
          this.permanentPrimaryArr_upper[indx].permanent = true;
          this.permanentPrimaryArr_upper[indx].parmaLabel = true;

        } else if(this.selectedOptionBtn == 'extracted'){
          this.permanentPrimaryArr_upper[indx].extracted = this.permanentPrimaryArr_upper[indx].extracted == true ? false : true;

        } else if(this.selectedOptionBtn == 'missing'){

          this.permanentPrimaryArr_upper[indx].missing = this.permanentPrimaryArr_upper[indx].missing == true ? false : true;
          if(this.optionBtnSelected[0].selected == true){
            this.permanentPrimaryArr_upper[indx].primary = !this.permanentPrimaryArr_upper[indx].missing;
            this.permanentPrimaryArr_upper[indx].permanent = false;
            this.permanentPrimaryArr_upper[indx].parmaLabel = false;
          } else if(this.optionBtnSelected[1].selected == true){
            this.permanentPrimaryArr_upper[indx].permanent = !this.permanentPrimaryArr_upper[indx].missing;
            this.permanentPrimaryArr_upper[indx].primary = false;
            this.permanentPrimaryArr_upper[indx].parmaLabel = true;
          }

        } else if(this.selectedOptionBtn == 'impacted'){

          this.permanentPrimaryArr_upper[indx].impacted = this.permanentPrimaryArr_upper[indx].impacted == true ? false : true;
          if(this.optionBtnSelected[0].selected == true){
            this.permanentPrimaryArr_upper[indx].primary = !this.permanentPrimaryArr_upper[indx].impacted;
            this.permanentPrimaryArr_upper[indx].permanent = false;
            this.permanentPrimaryArr_upper[indx].parmaLabel = false;
          } else if(this.optionBtnSelected[1].selected == true){
            this.permanentPrimaryArr_upper[indx].permanent = !this.permanentPrimaryArr_upper[indx].impacted;
            this.permanentPrimaryArr_upper[indx].primary = false;
            this.permanentPrimaryArr_upper[indx].parmaLabel = true;
          }

        } else if(this.selectedOptionBtn == 'unerupted'){

          this.permanentPrimaryArr_upper[indx].unerupted = this.permanentPrimaryArr_upper[indx].unerupted == true ? false : true;
          if(this.optionBtnSelected[0].selected == true){
            this.permanentPrimaryArr_upper[indx].primary = !this.permanentPrimaryArr_upper[indx].unerupted;
            this.permanentPrimaryArr_upper[indx].permanent = false;
            this.permanentPrimaryArr_upper[indx].parmaLabel = false;
          } else if(this.optionBtnSelected[1].selected == true){
            this.permanentPrimaryArr_upper[indx].permanent = !this.permanentPrimaryArr_upper[indx].unerupted;
            this.permanentPrimaryArr_upper[indx].primary = false;
            this.permanentPrimaryArr_upper[indx].parmaLabel = true;
          }

        } else if(this.selectedOptionBtn == 'peg'){
          this.permanentPrimaryArr_upper[indx].peg = this.permanentPrimaryArr_upper[indx].peg == true ? false : true;
        } else if(this.selectedOptionBtn == 'crown'){
          this.permanentPrimaryArr_upper[indx].crown = this.permanentPrimaryArr_upper[indx].crown == true ? false : true;
        } else if(this.selectedOptionBtn == 'supernum'){
          this.permanentPrimaryArr_upper[indx].supernum = this.permanentPrimaryArr_upper[indx].supernum == true ? false : true;
        } else if(this.selectedOptionBtn == 'roots'){
          this.permanentPrimaryArr_upper[indx].roots = this.permanentPrimaryArr_upper[indx].roots == true ? false : true;
        } else if(this.selectedOptionBtn == 'decal'){
          this.permanentPrimaryArr_upper[indx].decal = this.permanentPrimaryArr_upper[indx].decal == true ? false : true;
        } else if(this.selectedOptionBtn == 'perio'){
          this.permanentPrimaryArr_upper[indx].perio = this.permanentPrimaryArr_upper[indx].perio == true ? false : true;
        } else if(this.selectedOptionBtn == 'ectopic'){
          this.permanentPrimaryArr_upper[indx].ectopic = this.permanentPrimaryArr_upper[indx].ectopic == true ? false : true;
        } else if(this.selectedOptionBtn == 'implant'){
          this.permanentPrimaryArr_upper[indx].implant = this.permanentPrimaryArr_upper[indx].implant == true ? false : true;
        } else if(this.selectedOptionBtn == 'tad'){
          this.permanentPrimaryArr_upper[indx].tad = this.permanentPrimaryArr_upper[indx].tad == true ? false : true;
        } else if(this.selectedOptionBtn == "bracket"){
          this.permanentPrimaryArr_upper[indx].bracket = this.permanentPrimaryArr_upper[indx].bracket == true ? false : true;

          let toothId: any = this.toothArr[0].toothArrnagment[indx].id;
          if (this.selectedToothBrackets.length > 0) {
            let existingBrcketIndx: any = this.selectedToothBrackets.findIndex(item => item.toothid === toothId);

            if (existingBrcketIndx != -1) {

              if (this.permanentPrimaryArr_upper[indx].bracket == true) {
                this.selectedToothBrackets[existingBrcketIndx].bracket = this.selectedBracket.name;
              } else {
                this.selectedToothBrackets.splice(existingBrcketIndx, 1)
              }

            } else {
              if (this.permanentPrimaryArr_upper[indx].bracket == true) {
                this.selectedToothBrackets.push(
                  {
                    "toothid": this.toothArr[0].toothArrnagment[indx].id,
                    "bracket": this.selectedBracket.name,
                    "bracketId": this.selectedBracket.id,
                    "selected": true
                  }
                )
              }

            }
          } else {
            this.selectedToothBrackets.push({
                "toothid": this.toothArr[0].toothArrnagment[indx].id,
                "bracket": this.selectedBracket.name,
                "bracketId": this.selectedBracket.id,
                "selected": true
              }
            )
          }

          this.permanentPrimaryArr_upper[indx].bracketcolor = this.selectedBracket.colorCode;
        } 

        if(this.drawUWire){
          this.drawUpperWires.push(this.toothArr[0].toothArrnagment[indx].id);
          this.drawWires('upper', 'wire');
        }

        if(this.drawUChain){
          this.drawUpperChains.push(this.toothArr[0].toothArrnagment[indx].id);
          this.drawChains('upper', 'chain');
        }


      } else if (section == 'lower') {

        if(this.selectedOptionBtn == 'primary'){
          this.permanentPrimaryArr_lower[indx].primary = true;
          this.permanentPrimaryArr_lower[indx].permanent = false;
          this.permanentPrimaryArr_lower[indx].parmaLabel = false;
          
        } else if(this.selectedOptionBtn == 'permanent'){
          this.permanentPrimaryArr_lower[indx].primary = false;
          this.permanentPrimaryArr_lower[indx].permanent = true;
          this.permanentPrimaryArr_lower[indx].parmaLabel = true;

        } else if(this.selectedOptionBtn == 'extracted'){
          this.permanentPrimaryArr_lower[indx].extracted = this.permanentPrimaryArr_lower[indx].extracted == true ? false : true;

        } else if(this.selectedOptionBtn == 'missing'){

          this.permanentPrimaryArr_lower[indx].missing = this.permanentPrimaryArr_lower[indx].missing == true ? false : true;
          if(this.optionBtnSelected[0].selected == true){
            this.permanentPrimaryArr_lower[indx].primary = !this.permanentPrimaryArr_lower[indx].missing;
            this.permanentPrimaryArr_lower[indx].permanent = false;
            this.permanentPrimaryArr_lower[indx].parmaLabel = false;
          } else if(this.optionBtnSelected[1].selected == true){
            this.permanentPrimaryArr_lower[indx].permanent = !this.permanentPrimaryArr_lower[indx].missing;
            this.permanentPrimaryArr_lower[indx].primary = false;
            this.permanentPrimaryArr_lower[indx].parmaLabel = true;
          }

        } else if(this.selectedOptionBtn == 'impacted'){

          this.permanentPrimaryArr_lower[indx].impacted = this.permanentPrimaryArr_lower[indx].impacted == true ? false : true;
          if(this.optionBtnSelected[0].selected == true){
            this.permanentPrimaryArr_lower[indx].primary = !this.permanentPrimaryArr_lower[indx].impacted;
            this.permanentPrimaryArr_lower[indx].permanent = false;
            this.permanentPrimaryArr_lower[indx].parmaLabel = false;
          } else if(this.optionBtnSelected[1].selected == true){
            this.permanentPrimaryArr_lower[indx].permanent = !this.permanentPrimaryArr_lower[indx].impacted;
            this.permanentPrimaryArr_lower[indx].primary = false;
            this.permanentPrimaryArr_lower[indx].parmaLabel = true;
          }

        } else if(this.selectedOptionBtn == 'unerupted'){

          this.permanentPrimaryArr_lower[indx].unerupted = this.permanentPrimaryArr_lower[indx].unerupted == true ? false : true;
          if(this.optionBtnSelected[0].selected == true){
            this.permanentPrimaryArr_lower[indx].primary = !this.permanentPrimaryArr_lower[indx].unerupted;
            this.permanentPrimaryArr_lower[indx].permanent = false;
            this.permanentPrimaryArr_lower[indx].parmaLabel = false;
          } else if(this.optionBtnSelected[1].selected == true){
            this.permanentPrimaryArr_lower[indx].permanent = !this.permanentPrimaryArr_lower[indx].unerupted;
            this.permanentPrimaryArr_lower[indx].primary = false;
            this.permanentPrimaryArr_lower[indx].parmaLabel = true;
          }

        } else if(this.selectedOptionBtn == 'peg'){
          this.permanentPrimaryArr_lower[indx].peg = this.permanentPrimaryArr_lower[indx].peg == true ? false : true;
        } else if(this.selectedOptionBtn == 'crown'){
          this.permanentPrimaryArr_lower[indx].crown = this.permanentPrimaryArr_lower[indx].crown == true ? false : true;
        } else if(this.selectedOptionBtn == 'supernum'){
          this.permanentPrimaryArr_lower[indx].supernum = this.permanentPrimaryArr_lower[indx].supernum == true ? false : true;
        } else if(this.selectedOptionBtn == 'roots'){
          this.permanentPrimaryArr_lower[indx].roots = this.permanentPrimaryArr_lower[indx].roots == true ? false : true;
        } else if(this.selectedOptionBtn == 'decal'){
          this.permanentPrimaryArr_lower[indx].decal = this.permanentPrimaryArr_lower[indx].decal == true ? false : true;
        } else if(this.selectedOptionBtn == 'perio'){
          this.permanentPrimaryArr_lower[indx].perio = this.permanentPrimaryArr_lower[indx].perio == true ? false : true;
        } else if(this.selectedOptionBtn == 'ectopic'){
          this.permanentPrimaryArr_lower[indx].ectopic = this.permanentPrimaryArr_lower[indx].ectopic == true ? false : true;
        } else if(this.selectedOptionBtn == 'implant'){
          this.permanentPrimaryArr_lower[indx].implant = this.permanentPrimaryArr_lower[indx].implant == true ? false : true;
        } else if(this.selectedOptionBtn == 'tad'){
          this.permanentPrimaryArr_lower[indx].tad = this.permanentPrimaryArr_lower[indx].tad == true ? false : true;
        } else if (this.selectedOptionBtn == "bracket") {
          this.permanentPrimaryArr_lower[indx].bracket = this.permanentPrimaryArr_lower[indx].bracket == true ? false : true;

          
          if (this.selectedToothBrackets.length > 0) {
            let toothId: any = this.toothArr[1].toothArrnagment[indx].id;
            let existingBrcketIndx: any = this.selectedToothBrackets.findIndex(item => item.toothid === toothId);
            if (existingBrcketIndx != -1) {

              if (this.permanentPrimaryArr_lower[indx].bracket == true) {
                this.selectedToothBrackets[existingBrcketIndx].bracket = this.selectedBracket.name;
              } else {
                this.selectedToothBrackets.splice(existingBrcketIndx, 1)
              }
            } else {
              if (this.permanentPrimaryArr_lower[indx].bracket == true) {
                this.selectedToothBrackets.push(
                  {
                    "toothid": this.toothArr[1].toothArrnagment[indx].id,
                    "bracket": this.selectedBracket.name,
                    "bracketId": this.selectedBracket.id,
                    "selected": true
                  }
                )
              }
            }
          } else {
            this.selectedToothBrackets.push(
                  {
                    "toothid": this.toothArr[1].toothArrnagment[indx].id,
                    "bracket": this.selectedBracket.name,
                    "bracketId": this.selectedBracket.id,
                    "selected": true
                  }
                )
          }
          this.permanentPrimaryArr_lower[indx].bracketcolor = this.selectedBracket.colorCode;
        }

        if(this.drawLWire){
          this.drawLowerWires.push(this.toothArr[1].toothArrnagment[indx].id);
          this.drawWires('lower', 'wire');
        }

        if(this.drawLChain){
          this.drawLowerChains.push(this.toothArr[1].toothArrnagment[indx].id);
          this.drawChains('lower', 'chain')
        }

      }
  }

  selectMode(_section){
    if(_section == 'elastic'){
      this.elasticSection = true;
    } else {
      this.elasticSection = false;
    }
  }

  allPrimaryPermanent(toothtype){
    if(toothtype == 'primary'){
      this.permanentPrimaryArr_upper.map(item => {
        item.primary = true;
        item.permanent = false;
        item.parmaLabel = false;
      });

      this.permanentPrimaryArr_lower.map(item => {
        item.primary = true;
        item.permanent = false;
        item.parmaLabel = false;
      });

    } else {
      this.permanentPrimaryArr_upper.map(item => {
        item.primary = false;
        item.permanent = true;
        item.parmaLabel = true;
      });

      this.permanentPrimaryArr_lower.map(item => {
        item.primary = false;
        item.permanent = true;
        item.parmaLabel = true;
      });
    }
  }

  getOption(type){
    this.selectedOptionBtn = type;

    if(type == 'primary'){
      this.optionBtnSelected[0].selected = true;
      this.optionBtnSelected[1].selected = false;
    } else if(type == 'permanent') {
      this.optionBtnSelected[0].selected = false;
      this.optionBtnSelected[1].selected = true;
    } else {
      let btnIndx: any = this.optionBtnSelected.findIndex(item => item.name === type);

      if(btnIndx != -1){
        this.optionBtnSelected.map((item, index) => {
          if(index > 1){
            item.selected = false;
          }
        })

        this.optionBtnSelected[btnIndx].selected = true;
      }
    }
  }

  getPatientDetails(){
    this.patient$
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => {
      if(res != null){
        this.patientDetails = res;
        console.log("patientDetails ", res);
        this.getBracketOptions();        
      }
      
    })

  }

  getBracketOptions(){
    this.patientClient.patient_OptionsTeethElastics(this.patientDetails.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
      this.bracketsList = [{id: 0, name: 'None'}, ...resp.brackets];

      if(this.patientDetails.defaultBracketId){
        this.selectedBracket = this.bracketsList.find(bracket => bracket.id == this.patientDetails.defaultBracketId);
      } else {
        this.selectedBracket = this.bracketsList[0];
      }
      this.getTeethElastic();
    })
  }

  getTeethElastic() {
    this.patientClient.patient_GetTeethElastics(this.patientDetails.id, 100, undefined, null)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      (res) => {
        //res.map(item => {

        this.resetAll()

        this.elasticSequenceArr = res;

        if (res.length > 0) {
          let lasteditedItem: any = res[res.length - 1];
          this.selectedEleastic = lasteditedItem;
          this.lasteditedDt = lasteditedItem.date;

          this.elasticDrawingForm.patchValue({
            date: lasteditedItem
          })

          if(lasteditedItem.teethSequence != ''){
             let teethSequenceArr: any[] = lasteditedItem.teethSequence.split(':');
             teethSequenceArr.map(_teethelem => {
               let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
               let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);

               if (upperindx != -1) {
                 this.permanentPrimaryArr_upper[upperindx].primary = true;
                 this.permanentPrimaryArr_upper[upperindx].permanent = false;
                 this.permanentPrimaryArr_upper[upperindx].parmaLabel = false;

               }

               if (lowerindx != -1) {
                 this.permanentPrimaryArr_lower[lowerindx].primary = true;
                 this.permanentPrimaryArr_lower[lowerindx].permanent = false;
                 this.permanentPrimaryArr_lower[lowerindx].parmaLabel = false;
               }
               
             })
          }

          
          let respArr: any[] = lasteditedItem.optionSequence.split(':');
          respArr.map(teethelem => {

            let _teethelem: any = teethelem.split(',')[0];

            let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
            let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);

            if (upperindx != -1) {

              let toothOptionsArr: any[] = teethelem.substring(4, teethelem.length).split(',');

              toothOptionsArr.map(optionItem => {

                if (optionItem == 'X') {
                  this.permanentPrimaryArr_upper[upperindx].extracted = true;
                }
                if (optionItem == 'M') {
                  this.permanentPrimaryArr_upper[upperindx].missing = true;
                }
                if (optionItem == 'I') {
                  this.permanentPrimaryArr_upper[upperindx].impacted = true;
                }
                if (optionItem == 'U') {
                  this.permanentPrimaryArr_upper[upperindx].unerupted = true;
                }
                if (optionItem == 'P') {
                  this.permanentPrimaryArr_upper[upperindx].peg = true;
                }
                if (optionItem == 'C') {
                  this.permanentPrimaryArr_upper[upperindx].crown = true;
                }
                if (optionItem == 'S') {
                  this.permanentPrimaryArr_upper[upperindx].supernum = true;
                }
                if (optionItem == 'R') {
                  this.permanentPrimaryArr_upper[upperindx].roots = true;
                }
                if (optionItem == 'D') {
                  this.permanentPrimaryArr_upper[upperindx].decal = true;
                }
                if (optionItem == 'perio') {
                  this.permanentPrimaryArr_upper[upperindx].perio = true;
                }
                if (optionItem == 'E') {
                  this.permanentPrimaryArr_upper[upperindx].ectopic = true;
                }
                if (optionItem == 'implant') {
                  this.permanentPrimaryArr_upper[upperindx].implant = true;
                }
                if (optionItem == 'tad') {
                  this.permanentPrimaryArr_upper[upperindx].tad = true;
                }

              })
            }

            if (lowerindx != -1) {

              let toothOptionsArr: any[] = teethelem.substring(4, teethelem.length).split(',');

              toothOptionsArr.map(optionItem => {

                if (optionItem == 'X') {
                  this.permanentPrimaryArr_lower[lowerindx].extracted = true;
                }
                if (optionItem == 'M') {
                  this.permanentPrimaryArr_lower[lowerindx].missing = true;
                }
                if (optionItem == 'I') {
                  this.permanentPrimaryArr_lower[lowerindx].impacted = true;
                }
                if (optionItem == 'U') {
                  this.permanentPrimaryArr_lower[lowerindx].unerupted = true;
                }
                if (optionItem == 'P') {
                  this.permanentPrimaryArr_lower[lowerindx].peg = true;
                }
                if (optionItem == 'C') {
                  this.permanentPrimaryArr_lower[lowerindx].crown = true;
                }
                if (optionItem == 'S') {
                  this.permanentPrimaryArr_lower[lowerindx].supernum = true;
                }
                if (optionItem == 'R') {
                  this.permanentPrimaryArr_lower[lowerindx].roots = true;
                }
                if (optionItem == 'D') {
                  this.permanentPrimaryArr_lower[lowerindx].decal = true;
                }
                if (optionItem == 'perio') {
                  this.permanentPrimaryArr_lower[lowerindx].perio = true;
                }
                if (optionItem == 'E') {
                  this.permanentPrimaryArr_lower[lowerindx].ectopic = true;
                }
                if (optionItem == 'implant') {
                  this.permanentPrimaryArr_lower[lowerindx].implant = true;
                }
                if (optionItem == 'tad') {
                  this.permanentPrimaryArr_lower[lowerindx].tad = true;
                }

              })
            }

          })

          if(lasteditedItem.elasticSequence != ''){

            this.elasticSequenceData = {};
            this.elasticSequenceData.colorCode = "#3f91cd";
            this.elasticSequenceData.formationSequence = lasteditedItem.elasticSequence;
            this.startDrawing = false;

            setTimeout(() => {
              this.drawFormation(true);
            }, 500)

          }

          if(lasteditedItem.bracketSequence && lasteditedItem.bracketSequence != ''){
            let bracketSequence: any[] = lasteditedItem.bracketSequence.split(':');
            let _selectedBrackets:any[] = [];

            bracketSequence.map(item => {
              let _teethelem: any = item.split(',')[0];
              let bracketElem: any = item.split(',')[1];
              let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
              let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);


              if (upperindx != -1) {
                this.permanentPrimaryArr_upper[upperindx].bracket = true;

                let colorIndx: any = this.bracketsList.findIndex(item => item.id == bracketElem);
                if(colorIndx != -1){
                  this.permanentPrimaryArr_upper[upperindx].bracketcolor = this.bracketsList[colorIndx].colorCode;
                }

                this.selectedToothBrackets.push(
                  {
                    "toothid": this.toothArr[0].toothArrnagment[upperindx].id,
                    "bracket": this.bracketsList[colorIndx].name,
                    "bracketId": this.bracketsList[colorIndx].id,
                    "selected": true
                  }
                )
                
              }
    
              if (lowerindx != -1) {
                this.permanentPrimaryArr_lower[lowerindx].bracket = true;

                let colorIndx: any = this.bracketsList.findIndex(item => item.id == bracketElem);
                if(colorIndx != -1){
                  this.permanentPrimaryArr_lower[lowerindx].bracketcolor = this.bracketsList[colorIndx].colorCode;
                }

                this.selectedToothBrackets.push(
                  {
                    "toothid": this.toothArr[1].toothArrnagment[lowerindx].id,
                    "bracket": this.bracketsList[colorIndx].name,
                    "bracketId": this.bracketsList[colorIndx].id,
                    "selected": true
                  }
                )
              }

              let bracketElemIndx: any = this.bracketsList.findIndex(item => item.id == bracketElem);
              
              if(bracketElemIndx != -1){
                _selectedBrackets.push(this.bracketsList[bracketElemIndx]);
              }
            });

            this.selectedBrackets = _selectedBrackets.filter((v, i, a) => a.indexOf(v) === i); 
          }


          if(lasteditedItem.wireChainSequence){
            
            let sequence1 = "";
            let sequence2 = "";

            let allWirChainSequence = lasteditedItem.wireChainSequence.split(':');

            allWirChainSequence.map(item => {
              if(item.indexOf('W') != -1){
                sequence1 += item + ':'
              } else {
                 sequence2 += item + ':'
              }
            })

            if(sequence1 != ''){
              let _upperWireSequence = sequence1.split(':')[0];
              let _lowerWireSequence = sequence1.split(':')[1];

              if(_upperWireSequence){
                this.drawUpperWires = _upperWireSequence.split(',')[0].split('-');
                this.drawWires('upper', 'wire')
              }

              if(_lowerWireSequence){
                this.drawLowerWires = _lowerWireSequence.split(',')[0].split('-');
                this.drawWires('lower', 'wire')
              }
            }

            if (sequence2 != '') {
              let _upperChainSequence = sequence2.split(':')[0];
              let _lowerChainSequence = sequence2.split(':')[1];

              if (_upperChainSequence) {
                this.drawUpperChains = _upperChainSequence.split(',')[0].split('-');
                this.drawChains('upper', 'chain')
              }

              if (_lowerChainSequence) {
                this.drawLowerChains = _lowerChainSequence.split(',')[0].split('-');
                this.drawChains('lower', 'chain')
              }
            }
            
          }
        } else {

          this.permanentPrimaryArr_upper.map(item => { 
            item.permanent = true; 
            item.primaryavailable = false;
            item.primary = false;
            item.parmaLabel = true;
            item.perio = false;
            item.roots = false; 
            item.extracted = false;
            item.peg = false;
            item.ectopic = false;
            item.decal = false;
            item.supernum = false;
            item.impacted = false;
            item.unerupted = false;
            item.missing = false;
            item.crown = false;
            item.implant = false;
            item.tad = false;
            item.bracket = false;
          });
    
          this.permanentPrimaryArr_lower.map(item => { 
            item.permanent = true; 
            item.primaryavailable = false;
            item.primary = false;
            item.parmaLabel = true;
            item.perio = false;
            item.roots = false; 
            item.extracted = false;
            item.peg = false;
            item.ectopic = false;
            item.decal = false;
            item.supernum = false;
            item.impacted = false;
            item.unerupted = false;
            item.missing = false;
            item.crown = false;
            item.implant = false;
            item.tad = false;
            item.bracket = false;
          })

          this.deleteAll();
          
        }
        //})

      },
      (err) => {
        console.log(err)
      }
    )
  }

  addElasticToothgrid(){

    if (this.elasticSequenceArr.length > 0) {
      let today = moment();
      let lastConfigDt = moment(this.elasticSequenceArr[this.elasticSequenceArr.length - 1].date);

      if (lastConfigDt.startOf('date').isSame(today.startOf('date'))) {
        this.addElastic = false;
        this.editElastic = true;
      } else {
        this.addElastic = true;
        this.editElastic = false;
      }
    } else {
      this.addElastic = true;
      this.editElastic = false;
    }
    
    this.lasteditedDt = new Date().toISOString();
  }

  editElasticToothgrid(){
    if(this.selectedEleastic && this.selectedEleastic.id){
      this.editElastic = true;
      this.addElastic = false;
    };
    
  }

  saveElastics(){

    if (this.editElastic == true) {

      let optionSequence: any = "";
      let teethSequence: any = "";

      this.permanentPrimaryArr_upper.map((item, index) => {

        if (item.extracted == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.missing == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.impacted == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.unerupted == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.peg == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.crown == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.supernum == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.roots == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.decal == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.perio == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.ectopic == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.implant == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.tad == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if(item.primary == true){
          teethSequence += this.toothArr[0].toothArrnagment[index].id + ":";
        }


        if (item.extracted == true) {
          optionSequence += 'X,';
        }
        if (item.missing == true) {
          optionSequence += 'M,';
        }
        if (item.impacted == true) {
          optionSequence += 'I,';
        }
        if (item.unerupted == true) {
          optionSequence += 'U,';
        }
        if (item.peg == true) {
          optionSequence += 'P,';
        }
        if (item.crown == true) {
          optionSequence += 'C,';
        }
        if (item.supernum == true) {
          optionSequence += 'S,';
        }
        if (item.roots == true) {
          optionSequence += 'R,';
        }
        if (item.decal == true) {
          optionSequence += 'D,';
        }
        if (item.perio == true) {
          optionSequence += 'perio,';
        }
        if (item.ectopic == true) {
          optionSequence += 'E,';
        }
        if (item.implant == true) {
          optionSequence += 'implant,';
        }
        if (item.tad == true) {
          optionSequence += 'tad,';
        }

        if (item.extracted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.missing == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.impacted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.unerupted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.peg == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.crown == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.supernum == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.roots == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.decal == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.perio == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.ectopic == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.implant == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.tad == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        }
      });

      this.permanentPrimaryArr_lower.map((item, index) => {

        if (item.extracted == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.missing == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.impacted == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.unerupted == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.peg == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.crown == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.supernum == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.roots == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.decal == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.perio == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.ectopic == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.implant == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.tad == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if(item.primary == true){
          teethSequence += this.toothArr[1].toothArrnagment[index].id + ":";
        }


        if (item.extracted == true) {
          optionSequence += 'X,';
        }
        if (item.missing == true) {
          optionSequence += 'M,';
        }
        if (item.impacted == true) {
          optionSequence += 'I,';
        }
        if (item.unerupted == true) {
          optionSequence += 'U,';
        }
        if (item.peg == true) {
          optionSequence += 'P,';
        }
        if (item.crown == true) {
          optionSequence += 'C,';
        }
        if (item.supernum == true) {
          optionSequence += 'S,';
        }
        if (item.roots == true) {
          optionSequence += 'R,';
        }
        if (item.decal == true) {
          optionSequence += 'D,';
        }
        if (item.perio == true) {
          optionSequence += 'perio,';
        }
        if (item.ectopic == true) {
          optionSequence += 'E,';
        }
        if (item.implant == true) {
          optionSequence += 'implant,';
        }
        if (item.tad == true) {
          optionSequence += 'tad,';
        }

        if (item.extracted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.missing == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.impacted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.unerupted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.peg == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.crown == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.supernum == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.roots == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.decal == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.perio == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.ectopic == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.implant == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.tad == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        }
      });

      optionSequence = optionSequence.substring(0, optionSequence.length - 1);
      teethSequence = teethSequence.substring(0, teethSequence.length - 1);

      let bracketSequenceArr: any[] = this.selectedToothBrackets.filter(item => item.selected == true);

      let bracketSequence: any = "";
      bracketSequenceArr.map((item, indx) => {
        if(indx == bracketSequenceArr.length-1){
          bracketSequence += item.toothid + "," + item.bracketId;
        } else {
          bracketSequence += item.toothid + "," + item.bracketId + ":"
        }
      })

      let formationSequence: any = this.elasticDrawingForm.value.formation.replace(/,/g, ':');

      let  wireChainSequence: any = "";

      if(this.upperWireSequence != '' && this.lowerWireSequence != ''){
        wireChainSequence = `${this.upperWireSequence},W:${this.lowerWireSequence},W`;
      } else if(this.upperWireSequence != '' && this.lowerWireSequence == ''){
        wireChainSequence = `${this.upperWireSequence},W`;
      } else if(this.upperWireSequence == '' && this.lowerWireSequence != ''){
        wireChainSequence = `${this.lowerWireSequence},W`;
      }

      if(wireChainSequence != '' && (this.upperChainSequence != '' || this.lowerChainSequence != '')){
        wireChainSequence += ":";
      }

      if(this.upperChainSequence != '' && this.lowerChainSequence != ''){
        wireChainSequence += `${this.upperChainSequence},C:${this.lowerChainSequence},C`;
      } else if(this.upperChainSequence != '' && this.lowerChainSequence == ''){
        wireChainSequence += `${this.upperChainSequence},C`;
      } else if(this.upperChainSequence == '' && this.lowerChainSequence != ''){
        wireChainSequence += `${this.lowerChainSequence},C`;
      }


      let teethElasticModel: any = {
        "eTag": this.selectedEleastic.eTag,
        "id": this.selectedEleastic.id,
        "patientId": this.patientDetails.id,
        "date": this.selectedEleastic.date,
        "teethSequence": teethSequence,
        "elasticSequence": formationSequence,
        "optionSequence": optionSequence,
        "bracketSequence": bracketSequence,
        "wireChainSequence": wireChainSequence
      }

      this.patientClient.patient_PutTeethElastic(this.patientDetails.id, this.selectedEleastic.id, teethElasticModel, null, this.selectedEleastic.eTag)
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe(
        (res) => {
          this.addElastic = false;
          this.editElastic = false;
          this.getTeethElastic();
        },
        (err) => {
          console.log(err)
        }
      )


    } else if (this.addElastic == true) {
      let today: any = new Date();
      let todayISO: any = today.toISOString();

      let optionSequence: any = "";
      let teethSequence: any = "";

      this.permanentPrimaryArr_upper.map((item, index) => {

        if (item.extracted == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.missing == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.impacted == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.unerupted == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.peg == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.crown == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.supernum == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.roots == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.decal == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.perio == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.ectopic == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.implant == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if (item.tad == true) {
          optionSequence += this.toothArr[0].toothArrnagment[index].id + ",";
        } else if(item.primary == true){
          teethSequence += this.toothArr[0].toothArrnagment[index].id + ":";
        }

        if (item.extracted == true) {
          optionSequence += 'X,';
        }
        if (item.missing == true) {
          optionSequence += 'M,';
        }
        if (item.impacted == true) {
          optionSequence += 'I,';
        }
        if (item.unerupted == true) {
          optionSequence += 'U,';
        }
        if (item.peg == true) {
          optionSequence += 'P,';
        }
        if (item.crown == true) {
          optionSequence += 'C,';
        }
        if (item.supernum == true) {
          optionSequence += 'S,';
        }
        if (item.roots == true) {
          optionSequence += 'R,';
        }
        if (item.decal == true) {
          optionSequence += 'D,';
        }
        if (item.perio == true) {
          optionSequence += 'perio,';
        }
        if (item.ectopic == true) {
          optionSequence += 'E,';
        }
        if (item.implant == true) {
          optionSequence += 'implant,';
        }
        if (item.tad == true) {
          optionSequence += 'tad,';
        }

        if (item.extracted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.missing == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.impacted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.unerupted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.peg == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.crown == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.supernum == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.roots == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.decal == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.perio == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.ectopic == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.implant == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.tad == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        }
      });

      this.permanentPrimaryArr_lower.map((item, index) => {

        if (item.extracted == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.missing == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.impacted == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.unerupted == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.peg == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.crown == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.supernum == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.roots == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.decal == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.perio == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.ectopic == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.implant == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
        } else if (item.tad == true) {
          optionSequence += this.toothArr[1].toothArrnagment[index].id + ",";
         }else if(item.primary == true){
          teethSequence += this.toothArr[1].toothArrnagment[index].id + ":";
        }


        if (item.extracted == true) {
          optionSequence += 'X,';
        }
        if (item.missing == true) {
          optionSequence += 'M,';
        }
        if (item.impacted == true) {
          optionSequence += 'I,';
        }
        if (item.unerupted == true) {
          optionSequence += 'U,';
        }
        if (item.peg == true) {
          optionSequence += 'P,';
        }
        if (item.crown == true) {
          optionSequence += 'C,';
        }
        if (item.supernum == true) {
          optionSequence += 'S,';
        }
        if (item.roots == true) {
          optionSequence += 'R,';
        }
        if (item.decal == true) {
          optionSequence += 'D,';
        }
        if (item.perio == true) {
          optionSequence += 'perio,';
        }
        if (item.ectopic == true) {
          optionSequence += 'E,';
        }
        if (item.implant == true) {
          optionSequence += 'implant,';
        }
        if (item.tad == true) {
          optionSequence += 'tad,';
        }

        if (item.extracted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.missing == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.impacted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.unerupted == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.peg == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.crown == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.supernum == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.roots == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.decal == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.perio == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.ectopic == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.implant == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        } else if (item.tad == true) {
          optionSequence = optionSequence.substring(0, optionSequence.length - 1);
          optionSequence += ":";
        }
      });

      optionSequence = optionSequence.substring(0, optionSequence.length - 1);
      teethSequence = teethSequence.substring(0, teethSequence.length - 1);

      let bracketSequenceArr: any[] = this.selectedToothBrackets.filter(item => item.selected == true);

      let bracketSequence: any = "";
      bracketSequenceArr.map((item, indx) => {
        if(indx == bracketSequenceArr.length-1){
          bracketSequence += item.toothid + "," + item.bracketId;
        } else {
          bracketSequence += item.toothid + "," + item.bracketId + ":"
        }
      })

      let formationValue = this.elasticDrawingForm.value.formation;

      let formationSequence: any = formationValue ? formationValue.replace(/,/g, ':') : formationValue;

      let  wireChainSequence: any = "";

      if(this.upperWireSequence != '' && this.lowerWireSequence != ''){
        wireChainSequence = `${this.upperWireSequence},W:${this.lowerWireSequence},W`;
      } else if(this.upperWireSequence != '' && this.lowerWireSequence == ''){
        wireChainSequence = `${this.upperWireSequence},W`;
      } else if(this.upperWireSequence == '' && this.lowerWireSequence != ''){
        wireChainSequence = `${this.lowerWireSequence},W`;
      }

      if(wireChainSequence != '' && (this.upperChainSequence != '' || this.lowerChainSequence != '')){
        wireChainSequence += ":";
      }

      if(this.upperChainSequence != '' && this.lowerChainSequence != ''){
        wireChainSequence += `${this.upperChainSequence},C:${this.lowerChainSequence},C`;
      } else if(this.upperChainSequence != '' && this.lowerChainSequence == ''){
        wireChainSequence += `${this.upperChainSequence},C`;
      } else if(this.upperChainSequence == '' && this.lowerChainSequence != ''){
        wireChainSequence += `${this.lowerChainSequence},C`;
      }

      let teethElasticModel: any = {
        "patientId": this.patientDetails.id,
        "date": todayISO,
        "teethSequence": teethSequence,
        "elasticSequence": formationSequence,
        "optionSequence": optionSequence,
        "bracketSequence": bracketSequence,
        "wireChainSequence": wireChainSequence
      }

      this.patientClient.patient_PostTeethElastic(this.patientDetails.id, teethElasticModel, null)
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe(
        (res) => {
          this.addElastic = false;
          this.editElastic = false;
          this.getTeethElastic();
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }

  showSelectedSequence(evt){    
    this.selectedEleastic = evt.value;

    //if (this.selectedEleastic.length > 0) {
      let lasteditedItem: any = this.selectedEleastic
      this.lasteditedDt = lasteditedItem.date;

      this.permanentPrimaryArr_upper.map(item => { 
        item.permanent = true; 
        item.primaryavailable = false;
        item.primary = false;
        item.parmaLabel = true;
        item.perio = false;
        item.roots = false; 
        item.extracted = false;
        item.peg = false;
        item.ectopic = false;
        item.decal = false;
        item.supernum = false;
        item.impacted = false;
        item.unerupted = false;
        item.missing = false;
        item.crown = false;
        item.implant = false;
        item.tad = false;
      });

      this.permanentPrimaryArr_lower.map(item => { 
        item.permanent = true; 
        item.primaryavailable = false;
        item.primary = false;
        item.parmaLabel = true;
        item.perio = false;
        item.roots = false; 
        item.extracted = false;
        item.peg = false;
        item.ectopic = false;
        item.decal = false;
        item.supernum = false;
        item.impacted = false;
        item.unerupted = false;
        item.missing = false;
        item.crown = false;
        item.implant = false;
        item.tad = false;
      })

      if(lasteditedItem.teethSequence != ''){
        let teethSequenceArr: any[] = lasteditedItem.teethSequence.split(':');

        teethSequenceArr.map(_teethelem => {
          let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
          let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);

          if (upperindx != -1) {
            this.permanentPrimaryArr_upper[upperindx].primary = true;
            this.permanentPrimaryArr_upper[upperindx].permanent = false;
            this.permanentPrimaryArr_upper[upperindx].parmaLabel = false;

          }

          if (lowerindx != -1) {
            this.permanentPrimaryArr_lower[lowerindx].primary = true;
            this.permanentPrimaryArr_lower[lowerindx].permanent = false;
            this.permanentPrimaryArr_lower[lowerindx].parmaLabel = false;
          }
          
        })
     }


      let respArr: any[] = lasteditedItem.optionSequence.split(':');
      respArr.map(teethelem => {
        let _teethelem: any = teethelem.split(',')[0];

        let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
        let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);


        if (upperindx != -1) {

          let toothOptionsArr: any[] = teethelem.substring(4, teethelem.length).split(',');;

          toothOptionsArr.map(optionItem => {

            if (optionItem == 'X') {
              this.permanentPrimaryArr_upper[upperindx].extracted = true;
            }
            if (optionItem == 'M') {
              this.permanentPrimaryArr_upper[upperindx].missing = true;
            }
            if (optionItem == 'I') {
              this.permanentPrimaryArr_upper[upperindx].impacted = true;
            }
            if (optionItem == 'U') {
              this.permanentPrimaryArr_upper[upperindx].unerupted = true;
            }
            if (optionItem == 'P') {
              this.permanentPrimaryArr_upper[upperindx].peg = true;
            }
            if (optionItem == 'C') {
              this.permanentPrimaryArr_upper[upperindx].crown = true;
            }
            if (optionItem == 'S') {
              this.permanentPrimaryArr_upper[upperindx].supernum = true;
            }
            if (optionItem == 'R') {
              this.permanentPrimaryArr_upper[upperindx].roots = true;
            }
            if (optionItem == 'D') {
              this.permanentPrimaryArr_upper[upperindx].decal = true;
            }
            if (optionItem == 'perio') {
              this.permanentPrimaryArr_upper[upperindx].perio = true;
            }
            if (optionItem == 'E') {
              this.permanentPrimaryArr_upper[upperindx].ectopic = true;
            }
            if (optionItem == 'implant') {
              this.permanentPrimaryArr_upper[upperindx].implant = true;
            }
            if (optionItem == 'tad') {
              this.permanentPrimaryArr_upper[upperindx].tad = true;
            }

          })
        }

        if (lowerindx != -1) {

          let toothOptionsArr: any[] = teethelem.substring(4, teethelem.length).split(',');

          toothOptionsArr.map(optionItem => {

            if (optionItem == 'X') {
              this.permanentPrimaryArr_lower[lowerindx].extracted = true;
            }
            if (optionItem == 'M') {
              this.permanentPrimaryArr_lower[lowerindx].missing = true;
            }
            if (optionItem == 'I') {
              this.permanentPrimaryArr_lower[lowerindx].impacted = true;
            }
            if (optionItem == 'U') {
              this.permanentPrimaryArr_lower[lowerindx].unerupted = true;
            }
            if (optionItem == 'P') {
              this.permanentPrimaryArr_lower[lowerindx].peg = true;
            }
            if (optionItem == 'C') {
              this.permanentPrimaryArr_lower[lowerindx].crown = true;
            }
            if (optionItem == 'S') {
              this.permanentPrimaryArr_lower[lowerindx].supernum = true;
            }
            if (optionItem == 'R') {
              this.permanentPrimaryArr_lower[lowerindx].roots = true;
            }
            if (optionItem == 'D') {
              this.permanentPrimaryArr_lower[lowerindx].decal = true;
            }
            if (optionItem == 'perio') {
              this.permanentPrimaryArr_lower[lowerindx].perio = true;
            }
            if (optionItem == 'E') {
              this.permanentPrimaryArr_lower[lowerindx].ectopic = true;
            }
            if (optionItem == 'implant') {
              this.permanentPrimaryArr_lower[lowerindx].implant = true;
            }
            if (optionItem == 'tad') {
              this.permanentPrimaryArr_lower[lowerindx].tad = true;
            }

          })
        }

      })

      if(lasteditedItem.elasticSequence != ''){

        this.elasticSequenceData = {};
        this.elasticSequenceData.colorCode = "#3f91cd";
        this.elasticSequenceData.formationSequence = lasteditedItem.elasticSequence
        this.startDrawing = false;

        setTimeout(() => {
          this.drawFormation(true);
        }, 500)

      } else {
        this.deleteAll();
      }

      this.selectedBrackets = [];
      this.selectedToothBrackets = [];
      this.permanentPrimaryArr_upper.map(item => {
        item.bracket = false;
        item.bracketcolor = '#FFFFFF';
      });

      this.permanentPrimaryArr_lower.map(item => {
        item.bracket = false;
        item.bracketcolor = '#FFFFFF';
      })
      

      if(lasteditedItem.bracketSequence && lasteditedItem.bracketSequence != ''){
        let bracketSequence: any[] = lasteditedItem.bracketSequence.split(':');
        let _selectedBrackets:any[] = [];

        bracketSequence.map(item => {
          let _teethelem: any = item.split(',')[0];
          let bracketElem: any = item.split(',')[1];
          let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
          let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);


          if (upperindx != -1) {
            this.permanentPrimaryArr_upper[upperindx].bracket = true;

            let colorIndx: any = this.bracketsList.findIndex(item => item.id == bracketElem);
            if(colorIndx != -1){
              this.permanentPrimaryArr_upper[upperindx].bracketcolor = this.bracketsList[colorIndx].colorCode;
            }

            this.selectedToothBrackets.push(
              {
                "toothid": this.toothArr[0].toothArrnagment[upperindx].id,
                "bracket": colorIndx != -1 ? this.bracketsList[colorIndx].name : '',
                "bracketId": colorIndx != -1 ? this.bracketsList[colorIndx].id : 0,
                "selected": true
              }
            )
            
          }

          if (lowerindx != -1) {
            this.permanentPrimaryArr_lower[lowerindx].bracket = true;

            let colorIndx: any = this.bracketsList.findIndex(item => item.id == bracketElem);
            if(colorIndx != -1){
              this.permanentPrimaryArr_lower[lowerindx].bracketcolor = this.bracketsList[colorIndx].colorCode;
            }

            this.selectedToothBrackets.push(
              {
                "toothid": this.toothArr[1].toothArrnagment[lowerindx].id,
                "bracket": colorIndx != -1 ? this.bracketsList[colorIndx].name : '',
                "bracketId": colorIndx != -1 ? this.bracketsList[colorIndx].id : 0,
                "selected": true
              }
            )
          }

          let bracketElemIndx: any = this.bracketsList.findIndex(item => item.id == bracketElem);
          
          if(bracketElemIndx != -1){
            _selectedBrackets.push(this.bracketsList[bracketElemIndx]);
          }
        });

        this.selectedBrackets = _selectedBrackets.filter((v, i, a) => a.indexOf(v) === i); 
      }

    //}
  }


  selectEditSequence(evt){
    this.editsequenceMode = evt.value;

    if(this.editsequenceMode == 1){
      this.addElasticToothgrid();
    } else {
      this.editElasticToothgrid();
    }
  }

  resetAll(){
    this.permanentPrimaryArr_upper.map(item => { 
      item.permanent = true; 
      item.primaryavailable = false;
      item.primary = false;
      item.parmaLabel = true;
      item.perio = false;
      item.roots = false; 
      item.extracted = false;
      item.peg = false;
      item.ectopic = false;
      item.decal = false;
      item.supernum = false;
      item.impacted = false;
      item.unerupted = false;
      item.missing = false;
      item.crown = false;
      item.implant = false;
      item.tad = false;
      item.bracket = false;
    });

    this.permanentPrimaryArr_lower.map(item => { 
      item.permanent = true; 
      item.primaryavailable = false;
      item.primary = false;
      item.parmaLabel = true;
      item.perio = false;
      item.roots = false; 
      item.extracted = false;
      item.peg = false;
      item.ectopic = false;
      item.decal = false;
      item.supernum = false;
      item.impacted = false;
      item.unerupted = false;
      item.missing = false;
      item.crown = false;
      item.implant = false;
      item.tad = false;
      item.bracket = false;
    })

    this.deleteAll();
  }


  selectBracket(value){

    let patientTeethElasticDefaultDto = new PatientTeethElasticDefaultDto();
    patientTeethElasticDefaultDto.patientId = this.patientDetails.id;

    if(value.id > 0){
      this.selectedOptionBtn = "bracket";
      this.selectedBrackets.push(value);
      patientTeethElasticDefaultDto.bracketId = value.id;
    } else {
      patientTeethElasticDefaultDto.bracketId = null;
    }

    this.patientClient.patient_PutTeethElasticDefaults(this.patientDetails.id, patientTeethElasticDefaultDto)
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
      this._store$.dispatch(PatientsStoreActions.LoadOneRequest({
        id: this.patientDetails.id,
        locationId: this.patientDetails.preferredLocationId
      }))
    })
    

  }

  removeBracket(indx){

    if (this.selectedToothBrackets.length > 0) {

      let bracketName: any = this.selectedBrackets[indx].name;

      this.selectedToothBrackets.map((item, indx) => {

        if(item.bracket === bracketName){
          let toothId: any = item.toothid;
          item.selected = false;

          let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === toothId);
          let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === toothId);

          if (upperindx != -1) {
            this.permanentPrimaryArr_upper[upperindx].bracket = false
          }

          if (lowerindx != -1) {
            this.permanentPrimaryArr_lower[lowerindx].bracket = false
          }

        }

      });

      this.selectedBrackets.splice(indx, 1);
      this.selectedBracket = '';
      
    } else {
      this.selectedBrackets.splice(indx, 1);
    }

  }

  selectBracketBtn(indx){
    this.selectedOptionBtn = "bracket";
    this.selectedBracket = this.selectedBrackets[indx];
  }

  generatePDF(){

    console.log("value", this.elasticDrawingForm.value)

    this.genrtPDF = true;
    this.gridDate_PDF = moment(this.elasticDrawingForm.value.date.date).format('MM/DD/YYYY');
    this.gridInstruction_PDF = this.elasticDrawingForm.value.instruction;


    this.optionSequences.map(item => {
      item.selected = false;
    });

    this.permanentPrimaryArr_upper.map(item => {

      if (item.extracted == true) {
        this.optionSequences[0].selected = true;
      } else if (item.missing == true) {
        this.optionSequences[1].selected = true;
      } else if (item.impacted == true) {
        this.optionSequences[2].selected = true;
      } else if (item.unerupted == true) {
        this.optionSequences[3].selected = true;
      } else if (item.peg == true) {
        this.optionSequences[4].selected = true;
      } else if (item.crown == true) {
        this.optionSequences[5].selected = true;
      } else if (item.supernum == true) {
        this.optionSequences[6].selected = true;
      } else if (item.roots == true) {
        this.optionSequences[7].selected = true;
      } else if (item.decal == true) {
        this.optionSequences[8].selected = true;
      } else if (item.perio == true) {
        this.optionSequences[9].selected = true;
      } else if (item.ectopic == true) {
        this.optionSequences[10].selected = true;
      } else if (item.implant == true) {
        this.optionSequences[11].selected = true;
      } else if (item.tad == true) {
        this.optionSequences[12].selected = true;
      }

    });

    this.permanentPrimaryArr_lower.map(item => {
      if (item.extracted == true) {
        this.optionSequences[0].selected = true;
      } else if (item.missing == true) {
        this.optionSequences[1].selected = true;
      } else if (item.impacted == true) {
        this.optionSequences[2].selected = true;
      } else if (item.unerupted == true) {
        this.optionSequences[3].selected = true;
      } else if (item.peg == true) {
        this.optionSequences[4].selected = true;
      } else if (item.crown == true) {
        this.optionSequences[5].selected = true;
      } else if (item.supernum == true) {
        this.optionSequences[6].selected = true;
      } else if (item.roots == true) {
        this.optionSequences[7].selected = true;
      } else if (item.decal == true) {
        this.optionSequences[8].selected = true;
      } else if (item.perio == true) {
        this.optionSequences[9].selected = true;
      } else if (item.ectopic == true) {
        this.optionSequences[10].selected = true;
      } else if (item.implant == true) {
        this.optionSequences[11].selected = true;
      } else if (item.tad == true) {
        this.optionSequences[12].selected = true;
      }

    })
    
    setTimeout(() => {

      let content = this.content.nativeElement;
      var opt = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'TOOTHGRID.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all'},
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      html2pdf().from(content).set(opt).toPdf().get('pdf').then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();
        var pgHt = pdf.internal.pageSize.getHeight();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);

          const today = moment();
          let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
          let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`
          pdf.text(footerText, 0.25, pgHt - 0.25);
        }

        this.genrtPDF = false;
      }).save();
    }, 1000)
  }

  showAddBtn(){

    let visibleAddBtn: boolean = false;

    if (this.elasticSequenceArr.length > 0) {
      let selectedElasticDt = moment(this.lasteditedDt);

      let lastConfigDt = moment(this.elasticSequenceArr[this.elasticSequenceArr.length - 1].date);

      if (selectedElasticDt.isBefore(lastConfigDt)) {
        visibleAddBtn = false
      } else {
        visibleAddBtn = true
      }
    } else {
      visibleAddBtn = true
    }

    return visibleAddBtn;

  }

  enableDrawingWire(quadrant){

    this.selectedOptionBtn = null;

    if(quadrant == 'upper'){
      this.drawUWire = this.drawUWire ? false : true;
      this.drawLWire = false;

      if(this.drawUWire){
        Array.from(this.upperwirecont.nativeElement.children).forEach(child => {
          this.renderer.removeChild(this.upperwirecont.nativeElement.children, child);
        });
        this.upperWireSequence = "";
      }
      
    } else {
      this.drawLWire = this.drawLWire ? false : true;
      this.drawUWire = false;

      if(this.drawLWire){
        Array.from(this.lowerwirecont.nativeElement.children).forEach(child => {
          this.renderer.removeChild(this.lowerwirecont.nativeElement.children, child);
        });
        this.lowerWireSequence = "";
      }
    }
  }

  enableDrawingChain(quadrant){

    this.selectedOptionBtn = null

    if(quadrant == 'upper'){
      this.drawUChain = this.drawUChain ? false : true;
      this.drawLChain = false;

      if(this.drawUChain){
        Array.from(this.upperchaincont.nativeElement.children).forEach(child => {
          this.renderer.removeChild(this.upperchaincont.nativeElement.children, child);
        });

        this.upperChainSequence = "";
      }
      
    } else {
      this.drawLChain = this.drawLChain ? false : true;
      this.drawUChain = false;

      if(this.drawLChain){
        Array.from(this.lowerchaincont.nativeElement.children).forEach(child => {
          this.renderer.removeChild(this.lowerchaincont.nativeElement.children, child);
        });

        this.lowerChainSequence = "";
      }
    }
  }

  drawWires(quadrant, type){
    if(quadrant == 'upper'){
      let lines: any[] = [];

      if (this.drawUpperWires.length == 2) {
        this.drawUWire = false;

        let startTeethItem: any = this.drawUpperWires[0];
        let endTeehItem: any = this.drawUpperWires[1];

        let tothItemIndex = this.toothSelectorArr_names.findIndex(item => startTeethItem === item.name);
        let toothIndx: any = 0;
        if (tothItemIndex != -1) {
          if (tothItemIndex > 15) {
            toothIndx = (tothItemIndex - 15) + 1;
          } else {
            toothIndx = tothItemIndex + 1;
          }
        }
        let teethimgWd: any = this.teethimg.nativeElement.offsetWidth;
        let strtX: any = toothIndx * teethimgWd + (teethimgWd - 10) / 2;
        let strtY: any = 100;

        let tothItemIdex_end: any = this.toothSelectorArr_names.findIndex(item => endTeehItem === item.name);
        if (tothItemIdex_end > 15) {
          tothItemIdex_end = (tothItemIdex_end - 15) + 1;
        } else {
          tothItemIdex_end = tothItemIdex_end + 1;
        }

        let endX: any = tothItemIdex_end * teethimgWd + (teethimgWd - 10) / 2;
        let endY: any = 100;

        lines.push({ "strtX": strtX, "strtY": strtY, "endX": endX, "endY": endY, "lnClr": '#646464' });
        this.drawSVGWire(lines, this.upperwirecont, type);

        this.upperWireSequence = this.drawUpperWires.join('-');

        this.drawUpperWires = [];
      }

    } else {
      let lines: any[] = [];

      if (this.drawLowerWires.length == 2) {
        this.drawLWire = false;

        let startTeethItem: any = this.drawLowerWires[0];
        let endTeehItem: any = this.drawLowerWires[1];

        let tothItemIndex = this.toothSelectorArr_names.findIndex(item => startTeethItem === item.name);
        let toothIndx: any = 0;
        if (tothItemIndex != -1) {
          if (tothItemIndex > 15) {
            toothIndx = (tothItemIndex - 15);
          } else {
            toothIndx = tothItemIndex;
          }
        }
        let teethimgWd: any = this.teethimg.nativeElement.offsetWidth;
        let strtX: any = toothIndx * teethimgWd + (teethimgWd - 10) / 2;
        let strtY: any = 100;

        let tothItemIdex_end: any = this.toothSelectorArr_names.findIndex(item => endTeehItem === item.name);
        if (tothItemIdex_end > 15) {
          tothItemIdex_end = (tothItemIdex_end - 15);
        } else {
          tothItemIdex_end = tothItemIdex_end;
        }

        let endX: any = tothItemIdex_end * teethimgWd + (teethimgWd - 10) / 2;
        let endY: any = 100;

        lines.push({ "strtX": strtX, "strtY": strtY, "endX": endX, "endY": endY, "lnClr": '#646464' });
        this.drawSVGWire(lines, this.lowerwirecont, type);

        this.lowerWireSequence = this.drawLowerWires.join('-');
        this.drawLowerWires = [];
      }
    }
  }

  drawChains(quadrant, type){
    if(quadrant == 'upper'){
      let lines: any[] = [];

      if (this.drawUpperChains.length == 2) {
        this.drawUChain = false;

        let startTeethItem: any = this.drawUpperChains[0];
        let endTeehItem: any = this.drawUpperChains[1];

        let tothItemIndex = this.toothSelectorArr_names.findIndex(item => startTeethItem === item.name);
        let toothIndx: any = 0;
        if (tothItemIndex != -1) {
          if (tothItemIndex > 15) {
            toothIndx = (tothItemIndex - 15) + 1;
          } else {
            toothIndx = tothItemIndex + 1;
          }
        }
        let teethimgWd: any = this.teethimg.nativeElement.offsetWidth;
        let strtX: any = toothIndx * teethimgWd + (teethimgWd - 10) / 2;
        let strtY: any = 100;

        let tothItemIdex_end: any = this.toothSelectorArr_names.findIndex(item => endTeehItem === item.name);
        if (tothItemIdex_end > 15) {
          tothItemIdex_end = (tothItemIdex_end - 15) + 1;
        } else {
          tothItemIdex_end = tothItemIdex_end + 1;
        }

        let endX: any = tothItemIdex_end * teethimgWd + (teethimgWd - 10) / 2;
        let endY: any = 100;

        lines.push({ "strtX": strtX, "strtY": strtY, "endX": endX, "endY": endY, "lnClr": '#646464' });
        this.drawSVGWire(lines, this.upperchaincont, type);

        this.upperChainSequence = this.drawUpperChains.join('-');

        this.drawUpperChains = [];
      }

    } else {
      let lines: any[] = [];

      if (this.drawLowerChains.length == 2) {
        this.drawLChain = false;

        let startTeethItem: any = this.drawLowerChains[0];
        let endTeehItem: any = this.drawLowerChains[1];

        let tothItemIndex = this.toothSelectorArr_names.findIndex(item => startTeethItem === item.name);
        let toothIndx: any = 0;
        if (tothItemIndex != -1) {
          if (tothItemIndex > 15) {
            toothIndx = (tothItemIndex - 15);
          } else {
            toothIndx = tothItemIndex;
          }
        }
        let teethimgWd: any = this.teethimg.nativeElement.offsetWidth;
        let strtX: any = toothIndx * teethimgWd + (teethimgWd - 10) / 2;
        let strtY: any = 100;

        let tothItemIdex_end: any = this.toothSelectorArr_names.findIndex(item => endTeehItem === item.name);
        if (tothItemIdex_end > 15) {
          tothItemIdex_end = (tothItemIdex_end - 15);
        } else {
          tothItemIdex_end = tothItemIdex_end;
        }

        let endX: any = tothItemIdex_end * teethimgWd + (teethimgWd - 10) / 2;
        let endY: any = 100;

        lines.push({ "strtX": strtX, "strtY": strtY, "endX": endX, "endY": endY, "lnClr": '#646464' });
        this.drawSVGWire(lines, this.lowerchaincont, type);

        this.lowerChainSequence = this.drawLowerChains.join('-');

        this.drawLowerChains = [];
      }
    }
  }


  drawSVGWire(lines, el, type){
    let wireCont = el.nativeElement;    
    Array.from(wireCont.children).forEach(child => {
      this.renderer.removeChild(wireCont, child);
    });

    const div = this.renderer.createElement('div');
    this.renderer.addClass(div, 'wireline');

    let strtX = lines[0].strtX;
    let endX = lines[0].endX;
    if(strtX < endX){
      this.renderer.setStyle(div, 'left', lines[0].strtX + 'px');
      this.renderer.setStyle(div, 'width', (lines[0].endX - lines[0].strtX) + 'px');
    } else {
      this.renderer.setStyle(div, 'left', lines[0].endX + 'px');
      this.renderer.setStyle(div, 'width', (lines[0].strtX - lines[0].endX) + 'px');
    }

    if(type == 'wire'){
      this.renderer.setStyle(div, 'background', lines[0].lnClr);
    } else {
      this.renderer.addClass(div, 'chainrepeat');
      let svgrepeat = 'assets/infinity.png';
      this.renderer.setStyle(div, 'background-image', 'url('+svgrepeat+')');
    }

    this.renderer.appendChild(wireCont, div);    

  }

  toggleTab(_selectedTab){

    console.log("_selectedTab ", _selectedTab);

    if(this.selectedTab == _selectedTab){
      this.selectedTab = null;
    } else {
      this.selectedTab = _selectedTab;
    }
  }

  removeDefaultBracket(){
    let patientTeethElasticDefaultDto = new PatientTeethElasticDefaultDto();
    patientTeethElasticDefaultDto.patientId = this.patientDetails.id;
    patientTeethElasticDefaultDto.bracketId = null;

    this.patientClient.patient_PutTeethElasticDefaults(this.patientDetails.id, patientTeethElasticDefaultDto)
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
      this._store$.dispatch(PatientsStoreActions.LoadOneRequest({
        id: this.patientDetails.id,
        locationId: this.patientDetails.preferredLocationId
      }))
    })
  }
}
