import { createSelector, MemoizedSelector, createFeatureSelector } from '@ngrx/store';
import { featureAdapter } from './state';
import { State } from '../state';
import { TREATMENT_FEATURE_KEY } from '../featurekey';

const selectFeatureState: MemoizedSelector<object, State> = createFeatureSelector<State>(TREATMENT_FEATURE_KEY)

export const selectDiagnosisState = createSelector(
  selectFeatureState,
  (state) => state.diagnoses
);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectDiagnosisState);
export const selectDiagnosisIds = selectIds;
export const selectDiagnosisTotal = selectTotal;
export const selectAllDiagnoses = selectAll;
export const selectAllDiagnosisEntities = selectEntities;

export const selectAllActiveDiagnoses = createSelector(
  selectAllDiagnoses,
  (diagnoses) => diagnoses.filter(d => d.isActive)
);

export const selectIsLoading = createSelector(
  selectDiagnosisState,
  (state) => state.isLoading
);

export const selectError = createSelector(
  selectDiagnosisState,
  (state) => state.error
);
