import { createAction, props } from "@ngrx/store";
import { PostingCodeStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Posting Code] Load Request', 
  props<{ activeOnly?: boolean; }>()
);

export const LoadSuccess = createAction(
  '[Posting Code] Load Success',
  props<{ postingCodeList: PostingCodeStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Posting Code] Load Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Posting Code] Reset Error'
);

//LOAD ONE
export const LoadOneRequest = createAction('[PostingCode] Load One Request', props<{ id: number }>());

export const LoadOneSuccess = createAction('[PostingCode] Load One Success', props<{ postingCode: PostingCodeStoreEntity }>());

export const LoadOneFailure = createAction('[PostingCode] Load One Failure', props<{ error: string }>());

//DELETE
export const DeleteRequest = createAction('[PostingCode] Delete Request', props<{ id: number }>());

export const DeleteSuccess = createAction('[PostingCode] Delete Success', props<{ id: number }>());

export const DeleteFailure = createAction('[PostingCode] Delete Failure', props<{ error: string }>());
