import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TemplateTypeEnumDto } from '../../shared/services/api.service';
import { TEMPLATE_FEATURE_KEY } from './featurekey';
import { featureAdapter, TemplateStoreEntity, State } from './state';

export const selectTemplatesState: MemoizedSelector<object, State> = createFeatureSelector<State>(TEMPLATE_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectTemplatesState);
export const selectTemplateIds = selectIds;
export const selectAllTemplates = selectAll;
export const selectAllTemplatesEntities = selectEntities;

export const getSelectedTemplate = createSelector(
  selectTemplatesState,
  selectAllTemplates,
);

export const selectTemplateById = (id: number) =>
  createSelector(selectAllTemplates, (allTemplates: TemplateStoreEntity[]) =>
    allTemplates ? allTemplates.find((rp) => rp.id === id) : null
  );

export const selectTemplatesByType = (type: TemplateTypeEnumDto) =>
  createSelector(selectAllTemplates, (allTemplates: TemplateStoreEntity[]) =>
    allTemplates ? allTemplates.filter((x) => x.templateType === type) : []
  );

export const selectTemplatesError: MemoizedSelector<object, any> = createSelector(selectTemplatesState, (state) => state.error);

export const selectTemplatesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectTemplatesState, (state: State) => state.isLoading);
