import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENTS_FEATURE_KEY } from './featurekey';
import { featureAdapter, State, PatientStoreEntity } from './state';

export const selectPatientsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENTS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectPatientsState);
export const selectPatientIds = selectIds;
export const selectPatientTotal = selectTotal;
export const selectAllPatients = selectAll;
export const selectAllPatientsEntities = selectEntities;

export const selectPatientById = (id: number) =>
  createSelector(selectAllPatients, (allPatients: PatientStoreEntity[]) =>
    allPatients ? allPatients.find((c) => c.id === id) : null
  );

export const getSelectedPatientId = createSelector(selectPatientsState, (state) => state.selectedPatientId);
export const getSelectedPatient = createSelector(selectPatientsState, (state) => state.selectedPatientId ? state.entities[state.selectedPatientId] : null);
export const selectPatientsError: MemoizedSelector<object, any> = createSelector(selectPatientsState, (state) => state.error);
export const selectPatientsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientsState, (state: State) => state.isLoading);
export const selectHistory = createSelector(selectPatientsState, (state) => state.history);
export const getTxCardAlerts = createSelector(selectPatientsState, (state) => state.txCardAlerts);
