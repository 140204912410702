import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClickConfirm } from '../click-confirm.interface';

@Component({
  templateUrl: './click-confirm-template.component.html',
  styleUrls: ['./click-confirm-template.component.css']
})
export class ClickConfirmTemplateComponent implements ClickConfirm, OnInit {

  @Output('confirm') confirm: EventEmitter<any> = new EventEmitter<any>();
  @Input('confirmText') confirmText: string;

  constructor() { }

  ngOnInit() {
  }

  confirmClick() {
    this.confirm.next(true);
  }

  cancelClick() {
    this.confirm.next(false);
  }

}
