import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PatientsStoreSelectors, PatientStoreEntity, RootStoreState, PatientTreatmentStoreActions, LocationsStoreSelectors, PatientTreatmentStoreSelectors } from 'src/app/root-store';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { LocationClient, TreatmentDto } from 'src/app/shared/services/api.service';
import html2pdf from 'html2pdf.js';
import * as moment from 'moment-timezone';
import { faArrowUp, faPencilAlt, faPlusSquare, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AngularEditorConfig, AngularEditorComponent, AngularEditorService } from '@kolkov/angular-editor';
import * as _ from 'lodash';

@Component({
  selector: 'app-patient-plan-front',
  templateUrl: './patient-plan-front.component.html',
  styleUrls: ['../patient-plan.module.css', './patient-plan-front.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientPlanFrontComponent implements OnInit, OnDestroy {
  @HostBinding('style.height') parentHeight = '100%';

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  private _refreshTreatments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  patientTreatments$: Observable<TreatmentDto[]> = combineLatest([this._selectedPatient$, this._refreshTreatments$]).pipe(
    takeUntil(this._destroy$),
    filter(([patient]) => !!patient),
    switchMap(([patient]) => this._locationClient.location_GetTreatments(patient.locationId, patient.id)),
    map((treatments) => treatments.sort((a, b) => (a.date.valueOf() > b.date.valueOf() ? -1 : 1))),
    tap((result) => {
      if (this.selectedTreatment) {
        let treatment: TreatmentDto = result.find((treatment) => treatment.id == this.selectedTreatment.id);
        treatment = treatment || (result.length > 0 && result[0]); //fallback for when switching patients
        this.selectTreatment(treatment, true);
      } else if (!this.selectedTreatment && result.length > 0) {
        this.selectTreatment(result[0]);
      }
    }),
    shareReplay()
  );
  selectedTreatment: TreatmentDto;
  isLoadingCount: number = 0;
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  genrtPdf:boolean = false;
  @ViewChild('content', { static: false }) content: ElementRef;
  isLoading$ = this._store$.select(PatientTreatmentStoreSelectors.selectIsLoading);
  bottomExpanded:boolean = false;

  faArrowUp = faArrowUp;
  faPencilAlt = faPencilAlt;
  faPlusSquare = faPlusSquare;
  faFilePdf = faFilePdf;
  faArrowDown = faArrowDown;

  @ViewChild('pdfDialog', {static: false}) pdfDialog:TemplateRef<any>;
  @ViewChild('pdfeditor', {static: false}) pdfEditor:AngularEditorComponent;
  pdfEditorContent: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '420px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'header',
    defaultParagraphSeparator: '',
    sanitize: false,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',

        'underline',
        'strikeThrough',
        'subscript',
        'superscript',

        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'customClasses',
        'textColor',
        'backgroundColor',
        'link',
        'unlink',

        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };

  isPdfProgres:boolean = false;
  dialogRef:MatDialogRef<any>;


  constructor(
    @Inject(CARD_DATA) private data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private _locationClient: LocationClient,
    private _snackbar: MatSnackBar,
    private _cdr: ChangeDetectorRef,
    private matdialog: MatDialog
  ) {}

  ngOnInit() {
    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe((payload: TreatmentDto) => {
      if (payload) this._refreshTreatments$.next(true);
      this.detectChanges();
    });

    this.detectChanges();
  }

  detectChanges() {
    if (!this._cdr['destroyed']) {
      this._cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
  }

  newTreatment() {
    this._selectedPatient$.pipe(take(1)).subscribe((patient) => {
      const payload = {
        treatment: new TreatmentDto({ id: 0, date: new Date(Date.now()), diagnoses: [], steps: [], goals: [], plans: [], nextPlans: [] }),
        locationId: patient.locationId,
        patientId: patient.id,
      };
      this.data.flip.next({ payload: payload, side: this.data.side });
      this.detectChanges();
    });
  }

  editTreatment(treatment: TreatmentDto) {
    this._selectedPatient$.pipe(take(1)).subscribe((patient) => {
      const payload = {
        treatment: treatment,
        locationId: patient.locationId,
        patientId: patient.id,
      };
      this.data.flip.next({ payload: payload, side: this.data.side });
      this.detectChanges();
    });
  }

  removeTreatment(treatment: TreatmentDto) {
    this.selectedTreatment = null;
    this._selectedPatient$
      .pipe(
        switchMap((patient) => this._locationClient.location_DeleteTreatment(patient.locationId, patient.id, treatment.id)),
        take(1)
      )
      .subscribe(
        (_) => {
          this._selectedPatient$.pipe(take(1)).subscribe((patient) => {
            this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: patient.locationId, patientId: patient.id }))
            this.refresh();
            this._snackbar.open('Succesfully removed patient diagnosis entry', 'OK', { duration: 2500 });
          })


        },
        (err) => this._snackbar.open('There was a problem while attempting to remove the diagnosis', 'OK', { duration: 3000 })
      );
  }

  refresh() {
    this._refreshTreatments$.next(true);
    this.detectChanges();
  }

  selectTreatment(treatment: TreatmentDto, force: boolean = false) {
    if (this.selectedTreatment && treatment && treatment.id == this.selectedTreatment.id && !force) return;
    if(treatment && treatment.id){
      this.isLoadingCount++;
      this._selectedPatient$
        .pipe(
          switchMap((patient) => this._locationClient.location_GetTreatment(patient.locationId, patient.id, treatment.id).pipe(take(1))),
          tap((_) => this.isLoadingCount--),
          shareReplay(),
          take(1)
        )
        .subscribe((result) => {
          this.selectedTreatment = result;
          this.selectedTreatment.plans = _.orderBy(this.selectedTreatment.plans, 'id', 'asc');
          this.selectedTreatment.nextPlans = _.orderBy(this.selectedTreatment.nextPlans, 'id', 'asc');
          this.selectedTreatment.steps = _.orderBy(this.selectedTreatment.steps, 'sortOrder', 'asc');
          this.detectChanges();
        });
    } else {
      this.selectedTreatment = null;
      this.detectChanges();
    }

  }

  generatePDF(){
    this.isPdfProgres = true;
    setTimeout(() => {
      let content = this.pdfEditorContent;
      var opt = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'Diagnosis_Plan.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all'},
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      html2pdf().from(content).set(opt).toPdf().get('pdf').then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();
        var pgHt = pdf.internal.pageSize.getHeight();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);

          const today = moment();
          let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
          let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`
          pdf.text(footerText, 0.25, pgHt - 0.25);
        }

        this.isPdfProgres = false;
        this.dialogRef.close()

      }).save();
    }, 1000)
  }

  openPDFDialod(){
    this.genrtPdf = true;
    setTimeout(() => {
      let content = this.content.nativeElement;
      this.pdfEditorContent = content.innerHTML;
      this.dialogRef = this.matdialog.open(this.pdfDialog, {width: '700px'});
      this.genrtPdf = false;
    }, 1000)

  }

  inProgress(value){
    if(value == true){
      this.isLoadingCount = 1;
    } else {
      this.isLoadingCount = 0;
      //this.refresh();
    }
  }
}
