import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent {
  @Input() model: boolean;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() type: string;
  @Input() isHtmlLabel:boolean;
  @Output() checkChange = new EventEmitter();
}