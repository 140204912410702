import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { FillTypeEnum } from '../../shared/services/api.service';
import { AdvancedEditorActions, AdvancedEditorData } from './AdvancedEditorModels';

@Component({
  selector: 'advanced-editor',
  templateUrl: './advanced-editor.component.html',
  styleUrls: ['./advanced-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedEditorComponent implements OnInit, OnDestroy {
  base64: string;
  imageData: AdvancedEditorData;
  isWorking: boolean = false;
  rotationDegrees: number = 0;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  fillTypeKeys = Object.keys(FillTypeEnum);

  @Output() editedImage = new EventEmitter<string>();

  constructor(
    private _dialogRef: MatDialogRef<AdvancedEditorComponent>,
    private _snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: AdvancedEditorData,
    private _cdr: ChangeDetectorRef
  ) {
    this.imageData = data;
  }

  ngOnInit() {
    this.isWorking = true;
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  close(): void {
    this._dialogRef.close({ action: AdvancedEditorActions.Cancel });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.base64 = event.base64;
    this.editedImage.emit(event.base64);
    if (!this.imageData.image) this.imageData.image = {};
    this.imageData.image.data = base64ToFile(event.base64);
    this.imageData.image.fileName = this.imageData.image.name;

    this._cdr.detectChanges();
  }

  imageLoaded() {
    if (!this.imageData.imageTransform) {
      this.imageData.imageTransform = {
        flipV: false,
        flipH: false,
        rotate: 0,
        canvasRotation: 0
      }
    }
    this.isWorking = false;
    this._cdr.detectChanges();
  }

  cropperReady() {
    // cropper ready
  }

  setImageRotation(degrees: number): void {
    if (!this.imageData || !this.imageData.imageTransform) return;

    let canvasRotation: number = Math.trunc(degrees / 90);
    let rotate: number = canvasRotation ? degrees % (canvasRotation * 90) : degrees;

    this.imageData.imageTransform = {
      ...this.imageData.imageTransform,
      rotate: rotate,
      canvasRotation: canvasRotation
    };

    this.rotationDegrees = this.getDegrees();
    this._cdr.detectChanges();
  }

  getDegrees(): number {
    let degrees: number = 0;
    if (this.imageData.imageTransform.canvasRotation) {
      degrees = this.imageData.imageTransform.canvasRotation * 90;
    }

    if (this.imageData.imageTransform.rotate)
      degrees = degrees + this.imageData.imageTransform.rotate;

    return degrees;
  }

  imageRotation(): void {
    if (!this.imageData || !this.imageData.imageTransform) return;
    this.setImageRotation(this.rotationDegrees);
  }

  rotateRight(): void {
    if (!this.imageData || !this.imageData.imageTransform) return;
    let degrees: number = this.getDegrees();

    if (degrees < -90)
      this.setImageRotation(-90);
    else if (degrees < 0)
      this.setImageRotation(0);
    else if (degrees < 90)
      this.setImageRotation(90);
    else if (degrees < 180)
      this.setImageRotation(180);
  }

  rotateLeft(): void {
    if (!this.imageData || !this.imageData.imageTransform) return;
    let degrees: number = this.getDegrees();

    if (degrees > 90)
      this.setImageRotation(90);
    else if (degrees > 0)
      this.setImageRotation(0);
    else if (degrees > -90)
      this.setImageRotation(-90);
    else if (degrees > -180)
      this.setImageRotation(-180);
  }

  flipHorizontal(): void {
    if (!this.imageData || !this.imageData.imageTransform) return;

    this.imageData.imageTransform = {
      ...this.imageData.imageTransform,
      flipH: !this.imageData.imageTransform.flipH
    };
    this._cdr.detectChanges();
  }

  flipVertical(): void {
    if (!this.imageData || !this.imageData.imageTransform) return;

    this.imageData.imageTransform = {
      ...this.imageData.imageTransform,
      flipV: !this.imageData.imageTransform.flipV
    };
    this._cdr.detectChanges();
  }

  zoomOut() {
    if (!this.imageData || !this.imageData.imageTransform) return;
    if (!this.imageData.imageTransform.scale) this.imageData.imageTransform.scale = 1;
    if (this.imageData.imageTransform.scale < .2) return;

    this.imageData.imageTransform = {
      ...this.imageData.imageTransform,
      scale: this.imageData.imageTransform.scale -= .1
    };
    this._cdr.detectChanges();
  }

  zoomIn() {
    if (!this.imageData || !this.imageData.imageTransform) return;
    if (!this.imageData.imageTransform.scale) this.imageData.imageTransform.scale = 1;

    this.imageData.imageTransform = {
      ...this.imageData.imageTransform,
      scale: this.imageData.imageTransform.scale += .1
    };
    this._cdr.detectChanges();
  }

  loadImageFailed(): void {
    this.openSnackBar("Failed to load image", 'Ok');
    this.isWorking = false;
    this._cdr.detectChanges();
  }

  removePhoto(): void {
    this._dialogRef.close({ action: AdvancedEditorActions.Remove })
  }

  savePhoto(): void {
    this._dialogRef.close({
      action: AdvancedEditorActions.Save,
      base64: this.base64,
      image: this.imageData.image,
      imageTransform: this.imageData.imageTransform,
      height: this.imageData.height,
      width: this.imageData.width,
      fillType: this.imageData.fillType
    })
  }

  reloadOriginal(): void {
    this.isWorking = true;
    this.imageData.locationUrl = this.imageData.originalLocationUrl;
    this.imageData.image = this.imageData.originalImage;
    this._cdr.detectChanges();
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
    this._cdr.detectChanges();
  }
}
