import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FileDto, FormDataTypeEnum, PatientClient, PatientFormDto } from '../../../../shared/services/api.service';
import { FileViewerComponent } from '../../../file-viewer/file-viewer.component';

interface FormAnswer {
  formName: string;
  description: string;
  sections: FormAnswerSection[];
}

interface FormAnswerSection {
  label: string;
  formItems: FormAnswerItem[]
}

interface FormAnswerItem {
  question: string;
  formDataType: FormDataTypeEnum;
  answer: any;
  children: FormAnswerItem[];
}

@Component({
  selector: 'patient-forms-viewer',
  templateUrl: './patient-forms-viewer.component.html',
  styleUrls: ['./patient-forms-viewer.component.css']
})
export class PatientFormsViewerComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  selectedFormAnswer: FormAnswer;
  selectedPatientForm: PatientFormDto;
  page: number = 1;
  formDataTypeEnum: typeof FormDataTypeEnum = FormDataTypeEnum;
  isWorking: boolean;

  constructor(
    private _snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private _data: PatientFormDto,
    private _sanitizer: DomSanitizer,
    private _dialogRef: MatDialogRef<PatientFormsViewerComponent>,
    private _dialog: MatDialog,
    private _patientClient: PatientClient
  ) {
    this.selectedFormAnswer = null;
    this.selectedPatientForm = null;
    this.page = 1;
    if (_data) {
      this.selectedPatientForm = _data;
      this.selectedFormAnswer = JSON.parse(_data.jsonData);
      if (this.selectedFormAnswer) {
        this.selectedFormAnswer.sections.forEach((section: FormAnswerSection) => {
          this.checkSignatureAnswer(section.formItems);
        });
      }
    }
  }

  checkSignatureAnswer(formItems: FormAnswerItem[]): void {
    if (!formItems) return;
    formItems.forEach((item: FormAnswerItem) => {
      if (item.formDataType == FormDataTypeEnum.Signature) {
        item.answer = this._sanitizer.bypassSecurityTrustResourceUrl(item.answer);
        this.checkSignatureAnswer(item.children);
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  close(): void {
    this._dialogRef.close();
  }

  showFile(fileId: number) {
    this.isWorking = true;
    this._patientClient.patient_GetPatientFile(this.selectedPatientForm.patientId, fileId)
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((file: FileDto) => {
        this.isWorking = false;
        this._dialog.open(
          FileViewerComponent,
          {
            data: {
              thumbnailUrl: null,
              url: file.locationUrl,
              contentType: file.locationContentType,
              isThumbnail: false
            },
            hasBackdrop: true,
            height: '100%',
            width: '100%',
            restoreFocus: false
          }
        );
      });
  }
}
