import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as AppointmentComplianceStoreActions from './actions';
import * as AppointmentComplianceStoreSelectors from './selectors';
import { LocationClient, PatientClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class AppointmentComplianceStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _locationClient: LocationClient,
    private _patientClient: PatientClient) { }

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentComplianceStoreActions.LoadOneRequest),
    switchMap((action) =>
      this._patientClient
        .patient_GetAppointmentCompliance(action.patientId, action.appointmentId)
        .pipe(
          map((result) => AppointmentComplianceStoreActions.LoadOneSuccess({ appointmentCompliance: result })),
          catchError((err: HttpErrorResponse) => of(AppointmentComplianceStoreActions.LoadOneFailure({ error: err.message })))
        )
    )
  );

  @Effect()
  loadOneSuccessEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentComplianceStoreActions.LoadOneSuccess),
    switchMap((action) =>
      this.store$.select(AppointmentComplianceStoreSelectors.selectAppointmentComplianceById(action.appointmentCompliance.id))
        .pipe(map((result) => AppointmentComplianceStoreActions.SelectSuccess({ appointmentCompliance: result })))
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentComplianceStoreActions.UpdateRequest),
    switchMap((action) =>
      this._patientClient.patient_PostAppointmentCompliance(
        action.patientId,
        action.appoointmentId,
        action.appointmentCompliance
      ).pipe(
        switchMap(() => this._patientClient.patient_GetAppointmentCompliance(action.patientId, action.appoointmentId)),
        map((result) => AppointmentComplianceStoreActions.UpdateSuccess({ appointmentCompliance: result })),
        catchError((err: HttpErrorResponse) => of(AppointmentComplianceStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentComplianceStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(AppointmentComplianceStoreSelectors.selectAppointmentComplianceById(action.id))
        .pipe(map((result) => AppointmentComplianceStoreActions.SelectSuccess({ appointmentCompliance: result })))
    )
  );
}
