import { createAction, props } from "@ngrx/store";
import { PatientModelBoxStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientModelBox] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[PatientModelBox] Load Success',
  props<{ patientModelBoxes: PatientModelBoxStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientModelBox] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[PatientModelBox] Add Request',
  props<{ patientModelBox: PatientModelBoxStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[PatientModelBox] Add Success',
  props<{ patientModelBox: PatientModelBoxStoreEntity; }>()
);

export const AddFailure = createAction(
  '[PatientModelBox] Add Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[PatientModelBox] Update Request',
  props<{ patientModelBox: PatientModelBoxStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[PatientModelBox] Update Success',
  props<{ patientModelBox: PatientModelBoxStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[PatientModelBox] Update Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[PatientModelBox] Delete Request',
  props<{ patientId: number, modelBoxId: number }>()
);

export const DeleteSuccess = createAction(
  '[PatientModelBox] Delete Success',
  props<{ id: number }>()
);

export const DeleteFailure = createAction(
  '[PatientModelBox] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientModelBox] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientModelBox] Select Success',
  props<{ patientModelBox: PatientModelBoxStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientModelBox] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientModelBox] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientModelBox] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientModelBox] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[PatientModelBox] Reset Error'
);
