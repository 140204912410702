import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, switchMapTo, take, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, TaskFilterEnum, UserClient, UserTxCardFavoritesDto } from 'src/app/shared/services/api.service';
import * as AuthStoreActions  from '../auth-store/actions';
import * as AuthStoreSelectors from '../auth-store/selectors';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as UserStoreActions from './actions';
import * as UserStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class UserStoreEffects {
  private _selectedUserId = this.store$.select(AuthStoreSelectors.selectUserId);
  constructor(private actions$: Actions, private store$: Store<State>, private _userClient: UserClient) {}

  @Effect()
  loginLoadUsersEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AuthStoreActions.LoginSuccess, AuthStoreActions.ChangeTenantSuccess, SignalRHubStoreActions.HubStarted),
    withLatestFrom(this.store$.select(SignalRHubStoreSelectors.selectHubConnectionStatus)),
    filter(([_, status]) => status == HubConnectionState.Connected),
    switchMapTo(this.store$.select(AuthStoreSelectors.selectIsPatient)),
    filter(isPatient => !isPatient),
    mapTo(UserStoreActions.LoadRequest({}))
  );

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.LoadRequest),
    switchMap((action) =>
      this._userClient.user_GetUsers().pipe(
        map((result) => {
          this.store$.dispatch(UserStoreActions.LoadTaskCountRequest());
          return UserStoreActions.LoadSuccess({ users: result });
        }),
        catchError((err: HttpErrorResponse) => of(UserStoreActions.LoadFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  loadTaskCountRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.LoadTaskCountRequest),
    withLatestFrom(this._selectedUserId),
    switchMap(([action, userId]) =>
      this._userClient.user_GetTasksCount(userId, TaskFilterEnum.Assigned, true, false).pipe(
        map((result) => UserStoreActions.LoadTaskCountSuccess({ taskCount: result })),
        catchError((err: HttpErrorResponse) => of(UserStoreActions.LoadTaskCountFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  fetchRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.FetchRequest),
    switchMap((action) =>
      this._userClient.user_GetUser(action.id).pipe(
        map((result) => UserStoreActions.FetchSuccess({ user: result })),
        catchError((err: HttpErrorResponse) => of(UserStoreActions.FetchFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(UserStoreSelectors.selectUserById(action.id)).pipe(map((result) => UserStoreActions.SelectSuccess({ user: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(UserStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addPhotoRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.AddPhotoRequest),
    switchMap((action) =>
      this._userClient.user_PostPhoto(action.userId, action.file).pipe(
        map((result) => UserStoreActions.AddPhotoSuccess({ photo: result })),
        catchError((err: HttpErrorResponse) => of(UserStoreActions.AddPhotoFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updatePhotoRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserStoreActions.UpdatePhotoRequest),
    switchMap((action) =>
      this._userClient.user_PutPhoto(action.userId, action.photoId, action.file).pipe(
        map((result) => UserStoreActions.UpdatePhotoSuccess({ photo: result })),
        catchError((err: HttpErrorResponse) => of(UserStoreActions.UpdatePhotoFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateTxCardFavoriteReqestEffect = this.actions$.pipe(
    ofType(UserStoreActions.UpdateTxCardFavorite),
    withLatestFrom(this.store$.select(AuthStoreSelectors.selectCredentials)),
    withLatestFrom(
      this.store$
        .select(AuthStoreSelectors.selectedUserTokenId)
        .pipe(switchMap((userId) => this.store$.select(UserStoreSelectors.selectUserById(userId))))
    ),
    filter(([_, user]) => !!user),
    map(([[action, state], user]) => {
      let newFavorites = [...(user.txCardFavorites || [])];
      if (action.isFavorite && !newFavorites.some((c) => c == action.cardSelector)) {
        newFavorites.push(action.cardSelector);
      } else {
        newFavorites = newFavorites.filter((c) => c != action.cardSelector);
      }
      if (state) {
        state.txCardFavorites = newFavorites;
      }
      return <[string, string[]]>[user.id, newFavorites];
    }),
    switchMap(([userId, favorites]) =>
      this._userClient.user_PostUser2(userId, new UserTxCardFavoritesDto({ txCardFavorites: favorites })).pipe(
        take(1),
        switchMap((_) => {
          return of(UserStoreActions.UpdateTxCardFavoriteSuccess({ userId: userId, favorites: favorites }), UserStoreActions.FetchRequest({ id: userId }));
        }),
        catchError((err) => of(UserStoreActions.UpdateTxCardFavoriteFailure({ error: err })))
      )
    )
  );

  @Effect()
  userTaskSignalRActionsEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedUserId),
    filter(
      ([action, userId]) =>
        userId &&
        action.event.eventArea == HubEventArea.UserTaskEvent &&
        action.event.userId.toLowerCase() == userId.toLowerCase()
    ),
    map(([action, userId]) =>
      UserStoreActions.LoadTaskCountRequest()
    ),
  );
}
