import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DiagnosisDto2, IDiagnosisDto2 } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<DiagnosisStoreEntity> = createEntityAdapter<DiagnosisStoreEntity>({
  selectId: (diagnosis) => diagnosis.id,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export interface State extends EntityState<DiagnosisStoreEntity> {
  isLoading?: boolean;
  error?: any;
  hasLoaded?: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  hasLoaded: false,
})

export class DiagnosisStoreEntity extends DiagnosisDto2 {
  constructor(data?: IDiagnosisDto2) {
    super(data);
  }
}
