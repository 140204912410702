import { Component, OnInit, Input } from '@angular/core';
import { IMenuItem } from './models/MenuItem';
import { Router } from '@angular/router';
import { EvolutionColors } from '../../shared/enums/EvolutionColors';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss']
})
export class TreeMenuComponent implements OnInit {

  @Input() menuItems: IMenuItem[];
  @Input() indentDistance: string = '2em'; //how far to push childen menu elements right from the parent baseline
  @Input() nestLevel: number = 0; //Indicates how many levels this menu is nested

  EvolutionColors = EvolutionColors;

  constructor(private _router: Router, private _domSanitizer: DomSanitizer) { }

  ngOnInit() {}

  /**
   * Handles click events on menu items. Passes menu item to any supplied on click function
   *
   * @param item The menu item clicked
   * @param event Browser mouse event
   * @internal
   */
  menuClickHandler(item: IMenuItem, event: MouseEvent) {
    //send click event if registered
    if (item.onClick && typeof(item.onClick) === 'function')
      item.onClick(item);

    //Check for toggle
    if (item.collapsable) {
      item.isCollapsed = item.isCollapsed === false ? true : false;
    } else {
      //check for navigation
      if (typeof item.url === 'string') {
        this._router.navigateByUrl(item.url);
      } else if (Array.isArray(item.url)) {
        this._router.navigate(item.url, item.navigationExtras);
      }
    }
  }

  iconClickHandler(item: IMenuItem, event: MouseEvent) {
    if(item.onIconClick && typeof(item.onIconClick) === 'function'){
      event.stopImmediatePropagation();
      item.onIconClick(item);
    }
  }

  isIconClickable(item: IMenuItem) {
    if(item.icon && item.onIconClick && typeof(item.onIconClick) === 'function')
      return true;
    return false;
  }

  renderLabel(label: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(label);
  }
}

// Example menuItems
//menuItems: IMenuItem[] = [
//  {
//    label: 'Appointments',
//    collapsable: true,
//    isCollapsed: false,
//    children: [
//      { label: 'Virtual Consult', icon: 'gamepad', url: '/dashboard' }, //navigation by url string
//      { label: 'Before NP Exam',
//        url: ['/dashboard', 'practice-management', 'operations', { mnid: 0 }], navigationExtras: { queryParams: { test: 'test' } } }, //navigation by route fragments
//      {
//        label: 'Exam Cancelled / No Future Appt', active: true, noninteractive: true, children: [
//          { label: 'Sub 1' },
//          { label: 'Sub 2' },
//        ]
//      },
//      { label: 'No Show For Exam', color: EvolutionColors.LightPurple, icon: 'mood_bad' }, //using color themes and icons
//      { label: 'Scheduled To Start Treatment', color: EvolutionColors.LightBlue },
//      { label: 'Deband Congratulations', icon: 'emoji_emotions' },
//    ]
//  },
//  {
//    label: 'Treatment',
//    noninteractive: true,
//    children: [
//      { label: 'Patient Is Thinking / Short Recall' },
//      { label: 'Placed into TX OBS Recall' },
//      { label: 'Retainer Recall' },
//    ]
//  }];

