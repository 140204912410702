import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatProgressBarModule,
  MatTreeModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { PatientFilesSelectorComponent } from './patient-files-selector.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatTreeModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  exports: [PatientFilesSelectorComponent],
  declarations: [PatientFilesSelectorComponent],
  providers: [],
  entryComponents: [PatientFilesSelectorComponent]
})
export class PatientFilesSelectorModule { }
