import { createReducer, on, Action } from '@ngrx/store';
import { initialState, State, featureAdapter } from './state';
import * as ChairActions from './actions';

const reducer = createReducer(
  initialState,
  on(ChairActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(ChairActions.LoadSuccess, (state, action) => featureAdapter.addAll(action.chairs, { ...state, isLoading: false })),
  on(ChairActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
