import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  share
} from "rxjs/operators";
import { CommunicationDesksClient } from "src/app/shared/services/api.service";
import {
  LocationsStoreActions
} from "../location-store";
import * as CommunicationDeskActions from "./actions";

@Injectable({ providedIn: "root" })
export class CommunicationDeskEffectsService {
  constructor(
    private _actions$: Actions,
    private _communicationDesksClient: CommunicationDesksClient
  ) {}

  locationSelectLoadRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationsStoreActions.SelectSuccess),
      map(action => CommunicationDeskActions.LoadDesksRequest({ locationId: action.location.id })),
      share()
    )
  );

  loadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CommunicationDeskActions.LoadDesksRequest),
      exhaustMap((action) => this._communicationDesksClient.communicationDesks_GetCommunicationDesks(action.locationId).pipe(
        map((result) => CommunicationDeskActions.LoadDesksSuccess({ desks: result })),
        catchError((error) => of(CommunicationDeskActions.LoadDesksFailure({ error }))),
      )),
      share()
    )
  );

}
