import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';
import { PatientClient } from 'src/app/shared/services/api.service';
import { PatientsStoreActions } from '../patient-store';
import { State } from '../root-state';
import * as PatientTreatmentStatusStoreActions from './actions';
import * as PatientTreatmentStatusStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class PatientTreatmentStatusStoreEffects {
  constructor(private _actions$: Actions, private _store$: Store<State>, private _patientClient: PatientClient) {}

  loadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStatusStoreActions.LoadRequest),
      switchMap((action) =>
        this._patientClient.patient_GetPatientTreatmentStatuses(action.patientId).pipe(
          map((result) => PatientTreatmentStatusStoreActions.LoadSuccess({ patientTreatmentStatuses: result })),
          catchError((err: HttpErrorResponse) => of(PatientTreatmentStatusStoreActions.LoadFailure({ error: err.message })))
        )
      )
    )
  );

  selectRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStatusStoreActions.SelectRequest),
      switchMap((action) =>
        this._store$
          .select(PatientTreatmentStatusStoreSelectors.selectPatientTreatmentStatusById(action.id))
          .pipe(map((result) => PatientTreatmentStatusStoreActions.SelectSuccess({ patientTreatmentStatus: result })))
      )
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this._actions$.pipe(
    ofType(PatientTreatmentStatusStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(PatientTreatmentStatusStoreActions.DeselectSuccess());
    })
  );

  addRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStatusStoreActions.AddRequest),
      switchMap((action) =>
        this._patientClient.patient_PostPatientTreatmentStatus(action.patientTreatmentStatus.patientId, action.patientTreatmentStatus).pipe(
          map((result) => PatientTreatmentStatusStoreActions.AddSuccess({ patientTreatmentStatus: result })),
          catchError((err: HttpErrorResponse) => of(PatientTreatmentStatusStoreActions.AddFailure({ error: err.message })))
        )
      )
    )
  );

  updateRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStatusStoreActions.UpdateRequest),
      switchMap((action) =>
        this._patientClient
          .patient_PutPatientTreatmentStatus(
            action.patientTreatmentStatus.patientId,
            action.patientTreatmentStatus.id,
            action.patientTreatmentStatus,
            null,
            action.patientTreatmentStatus.eTag
          )
          .pipe(
            switchMap(() =>
              this._patientClient.patient_GetPatientTreatmentStatus(action.patientTreatmentStatus.patientId, action.patientTreatmentStatus.id)
            ),
            map((result) => PatientTreatmentStatusStoreActions.UpdateSuccess({ patientTreatmentStatus: result })),
            catchError((err: HttpErrorResponse) => of(PatientTreatmentStatusStoreActions.UpdateFailure({ error: err.message })))
          )
      )
    )
  );

  deleteRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStatusStoreActions.DeleteRequest),
      switchMap((action) =>
        this._patientClient.patient_DeletePatientTreatmentStatus(action.patientId, action.treatmentStatusId).pipe(
          map(() => PatientTreatmentStatusStoreActions.DeleteSuccess({ id: action.treatmentStatusId })),
          catchError((err: HttpErrorResponse) => of(PatientTreatmentStatusStoreActions.DeleteFailure({ error: err.message })))
        )
      )
    )
  );

  updatePatientStatusOnChangeEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStatusStoreActions.AddSuccess, PatientTreatmentStatusStoreActions.UpdateSuccess),
      switchMapTo(this._store$.select(PatientTreatmentStatusStoreSelectors.selectAllPatientTreatmentStatuses)),
      map((statuses) => {
        if (statuses.length > 0) {
          return statuses.sort((a, b) => +b.fromDate - +a.fromDate)[0];
        }
        return null;
      }),
      tap((status) => console.log(status)),
      filter((status) => !!status),
      map((status) => PatientsStoreActions.UpdatePatientStatusId({ patientId: status.patientId, patientStatusId: status.patientStatusId }))
    )
  );
}
