import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as FormGroupStoreActions from './actions';
import * as FormGroupStoreSelectors from './selectors';
import { LocationClient, PatientClient, SettingsClient } from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material';

@Injectable({ providedIn: 'root' })
export class FormGroupStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _snackBar: MatSnackBar,
    private _settingsClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormGroupStoreActions.LoadRequest),
    switchMap((action) => {
      return this._settingsClient.settings_GetFormGroupAll().pipe(
        map((result) => FormGroupStoreActions.LoadSuccess({ formGroups: result })),
        catchError((err: HttpErrorResponse) => of(FormGroupStoreActions.LoadFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormGroupStoreActions.LoadOneRequest),
    switchMap((action) => {
      return this._settingsClient.settings_GetFormGroup(action.id).pipe(
        map((result) => FormGroupStoreActions.LoadOneSuccess({ formGroup: result })),
        catchError((err: HttpErrorResponse) => of(FormGroupStoreActions.LoadOneFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormGroupStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(FormGroupStoreSelectors.selectFormGroupById(action.id))
        .pipe(map((result) => FormGroupStoreActions.SelectSuccess({ formGroup: result })))
    )
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormGroupStoreActions.AddRequest),
    switchMap((action) =>
      this._settingsClient.settings_PostFormGroup(action.formGroup).pipe(
        map((result) => {
          this._snackBar.open("Successfully added!", "Ok", {
            duration: 3000,
          });
          return FormGroupStoreActions.AddSuccess({ formGroup: result });
        }),
        catchError((err: HttpErrorResponse) => of(FormGroupStoreActions.AddFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormGroupStoreActions.UpdateRequest),
    switchMap((action) =>
      this._settingsClient.settings_PutFormGroup(action.id, action.formGroup).pipe(
        switchMap(() => this._settingsClient.settings_GetFormGroup(action.id)),
        map((result) => {
          this._snackBar.open("Successfully updated!", "Ok", {
            duration: 3000,
          });
          return FormGroupStoreActions.UpdateSuccess({ formGroup: result });
        }),
        catchError((err: HttpErrorResponse) => of(FormGroupStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormGroupStoreActions.DeleteRequest),
    switchMap((action) =>
      this._settingsClient.settings_DeleteFormGroup(action.id).pipe(
        map(() => {
          this._snackBar.open("Successfully deleted!", "Ok", {
            duration: 3000,
          });
          return FormGroupStoreActions.DeleteSuccess({ id: action.id });
        }),
        catchError((err: HttpErrorResponse) => of(FormGroupStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );
}
