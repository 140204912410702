import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { 
  PatientsStoreSelectors, 
  PatientStoreEntity, 
  RootStoreState, 
  LocationsStoreSelectors,
  LocationStoreEntity,
} from 'src/app/root-store';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {
  LocationClient,
  TreatmentDto,
  AlignerLedgerTypeEnum,
  AlignerDto,
  AlignerInventoryDto,
  AlignerOrderDto,
  AlignerDispenseDto
} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-aligners',
  templateUrl: './aligners.component.html',
  styleUrls: ['./aligners.component.css']
})
export class AlignersComponent implements OnInit {
  patient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  patient:PatientStoreEntity;
  location:LocationStoreEntity;
  treatments:TreatmentDtoVM[] = [];
  selectedTreatment:TreatmentDtoVM;
  alignerForm:FormGroup;
  isWorking:boolean = false;
  @ViewChild('errorDialog', {static: false}) errorDialog:TemplateRef<any>;
  errorDialogRef: MatDialogRef<any>;
  dialog: DialogComponent;
  errorMsg: any = "";
  numberMask = createNumberMask({ prefix:'', thousandsSeparatorSymbol: '', allowDecimal: false, decimalSymbol: '', allowNegative :true,  allowLeadingZeroes: true });
  noTreatmentDates:boolean = false;
  constructor(
    private _store$: Store<RootStoreState.State>,
    private locationClient:LocationClient,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
  ) { 
    this.dialog = new DialogComponent(this.matDialog);
  }

  ngOnInit() {

    this.selectedLocation$.pipe(filter(location => !!location)).subscribe(location => {
      this.location = location;
    });

    this.alignerForm = this.formBuilder.group({
      aligners: new FormArray([])
    });

    this.patient$.pipe(filter(patient => !!patient)).subscribe(patient => {
      this.patient = patient;
      this.getPatientTreatment();
    });
  }
  
  get alignerFormControl(){ return this.alignerForm.controls; }
  get alignerForm_aligners(){ return this.alignerFormControl.aligners as FormArray }
  
  alignerFormInventory(indx) { return this.alignerForm_aligners.at(indx).get('inventory') as FormArray}
  alignerFormDispensed(indx) { return this.alignerForm_aligners.at(indx).get('dispensed') as FormArray}

  getAligners(treatmentId){
    this.alignerForm.reset();
    this.isWorking = true;
    this.locationClient.location_GetAligners(this.location.id, this.patient.id, treatmentId).subscribe(aligners => {
      this.alignerForm.reset();
      this.isWorking = false;

      let _aligners = aligners;

      if(_aligners.length > 0){
        const alignersForm = new FormArray([]);
        _aligners.map((aligner, indx) => {

          const inventoryForm = new FormArray([]);
          const dispensedForm = new FormArray([]);

          aligner.inventory.map(inventoryItem => {
            inventoryForm.push(this.formBuilder.group({
              eTag: inventoryItem.eTag,
              id: inventoryItem.id,
              type: inventoryItem.type,
              transactionDate: inventoryItem.transactionDate,
              fromTo: inventoryItem.fromTo,
              upperQuantity: inventoryItem.upperQuantity,
              lowerQuantity: inventoryItem.lowerQuantity,
              totalQuantity: inventoryItem.totalQuantity,
              totalOnOrder: inventoryItem.totalOnOrder
            }));
          })

          aligner.dispensed.map(dispensedItem => {
            dispensedForm.push(this.formBuilder.group({
              eTag: dispensedItem.eTag,
              id: dispensedItem.id,
              type: dispensedItem.type,
              transactionDate: dispensedItem.transactionDate,
              instructions: dispensedItem.instructions,
              upperQuantity: dispensedItem.upperQuantity,
              lowerQuantity: dispensedItem.lowerQuantity
            }))
          })

          alignersForm.push(this.formBuilder.group({
            name: [aligner.name, Validators.required],
            id : aligner.id,
            eTag: aligner.eTag,
            treatmentId: aligner.treatmentId,
            inventory: inventoryForm,
            dispensed: dispensedForm,
            totalOnOrder: aligner.totalOnOrder,
            totalOnHand: aligner.totalOnHand,
            addMode:false,
            editMode: false
          }))

        });

        this.alignerForm.setControl('aligners', alignersForm);

      } else {
        while (this.alignerForm_aligners.length) {
          this.alignerForm_aligners.removeAt(0);
       }
        this.addNewAligner();
      }
      
    },
    err => {
      console.log(err);
      this.isWorking = false;
    })
  }

  getPatientTreatment(){
    this.isWorking = true;
    this.locationClient.location_GetTreatments(this.patient.locationId, this.patient.id).subscribe(_treatments => {
      this.isWorking = false;
      this.treatments = [];
      let treatments = _.orderBy(_treatments, 'date', 'desc');
      treatments.map(treatment => {
        this.treatments.push(new TreatmentDtoVM(treatment))
      })

      if(this.treatments.length > 0){
        let openedTreatment = this.treatments[0];
        this.toggleSelectTreatmentRow(openedTreatment, 0);
      } else {
        this.noTreatmentDates = true;
      }
    },
    err => {
      console.log(err);
      this.isWorking = false;
    })
  }

  toggleSelectTreatmentRow(treatment:TreatmentDtoVM, indx){
    this.treatments.map((_treatment, i) => {
      if(i != indx){
        _treatment.selected = false;
      }
    })
    treatment.selected = treatment.selected ? false : true;
    if(treatment.selected){
      this.getAligners(treatment.id)
    }
  }

  addNewAligner(){
    this.alignerForm_aligners.push(this.formBuilder.group({
      name: ['', Validators.required],
      id : [],
      eTag:[],
      treatmentId: [],
      inventory: new FormArray([]),
      dispensed: new FormArray([]),
      totalOnOrder: [],
      totalOnHand: [],
      addMode:true,
      editMode: false
    }))
  }

  addNewInventory(type, index) {
    this.alignerFormInventory(index).controls.push(
      this.formBuilder.group({
        eTag: [''],
        id: [null],
        type: [type],
        transactionDate: moment().toDate(),
        fromTo: ['', Validators.required],
        upperQuantity: ['', Validators.required],
        lowerQuantity: ['', Validators.required],
        totalQuantity: [''],
        totalOnOrder: ['']
      })
    )
  }

  addNewDispensed(index){

    this.alignerFormDispensed(index).controls.push(this.formBuilder.group({
      eTag: [''],
      id: [null],
      type: [AlignerLedgerTypeEnum.Dispensed],
      transactionDate: moment().toDate(),
      instructions: ['', Validators.required],
      upperQuantity: ['', Validators.required],
      lowerQuantity: ['', Validators.required],
    }))
  }

  saveAligner(treatmentId, alignerIndx){
    let alignerModel:any = {}

    let alignerFormValue = this.alignerForm_aligners.controls[alignerIndx].value;

    if(alignerFormValue.id){
      this.locationClient.location_PutAligner(
          this.location.id, 
          this.patient.id, 
          treatmentId, 
          alignerFormValue.id, 
          alignerFormValue,
          null,
          alignerFormValue.eTag
        ).subscribe(resp => {
        this.isWorking = false;
        this.getAligners(treatmentId);
      },
      err => {
        console.log(err);
        this.isWorking = false;
      })

    } else {
      let inventory = [];
      this.alignerFormInventory(alignerIndx).controls.map(_inventory => {
        inventory.push(_inventory.value);
      })

      let dispensed = [];
      this.alignerFormDispensed(alignerIndx).controls.map(_dispensed => {
        dispensed.push(_dispensed.value);
      })

      inventory.map(item => {
        item.transactionDate = moment(item.transactionDate).toDate();
      })

      dispensed.map(item => {
        item.transactionDate = moment(item.transactionDate).toDate();
      })
      
      alignerModel.name = alignerFormValue.name;
      alignerModel.totalOnHand = alignerFormValue.totalOnHand;
      alignerModel.totalOnOrder = alignerFormValue.totalOnOrder;
      alignerModel.treatmentId = alignerFormValue.treatmentId;
      alignerModel.inventory = inventory;
      alignerModel.dispensed = dispensed;

      this.isWorking = true;
      this.locationClient.location_PostAligner(this.location.id, this.patient.id, treatmentId, alignerModel).subscribe(resp => {
        this.isWorking = false;
        this.getAligners(treatmentId);
      },
      err => {
        console.log(err);
        this.isWorking = false;
        
      })
    }

    
  }

  alignerEditToggle(alignerForm){
    alignerForm.patchValue({
      editMode: alignerForm.value.editMode == true ? false : true
    })
  }

  inventoryValidation(alignerIndx){
    let validInventoryForm:boolean = false;
    if(this.alignerFormInventory(alignerIndx).controls.length > 0){
      for(let i=0; i<this.alignerFormInventory(alignerIndx).controls.length; i++){
        if(this.alignerFormInventory(alignerIndx).controls[i].valid){
          validInventoryForm = true;
        } else {
          validInventoryForm = false;
          break;
        }
      }
    }
    return validInventoryForm
  }

  showHideInventorySaveBtn(alignerIndx){
    let showBtn = false;
    if(this.alignerFormInventory(alignerIndx).controls.length > 0){
      for(let i=0; i<this.alignerFormInventory(alignerIndx).controls.length; i++){
        if(this.alignerFormInventory(alignerIndx).controls[i].value.id == null){
          showBtn = true;
          break;
        } else {
          showBtn = false;
        }
      }
    }
    return showBtn;
  }

  dispensedValidation(alignerIndx){
    let validDispensedForm:boolean = false;
    if(this.alignerFormDispensed(alignerIndx).controls.length > 0){
      for(let i=0; i<this.alignerFormDispensed(alignerIndx).controls.length; i++){
        if(this.alignerFormDispensed(alignerIndx).controls[i].valid){
          validDispensedForm = true;
        } else {
          validDispensedForm = false;
          break;
        }
      }
    }
    return validDispensedForm
  }

  showHideDispensedSaveBtn(alignerIndx){
    let showBtn = false;
    if(this.alignerFormDispensed(alignerIndx).controls.length > 0){
      for(let i=0; i<this.alignerFormDispensed(alignerIndx).controls.length; i++){
        if(this.alignerFormDispensed(alignerIndx).controls[i].value.id == null){
          showBtn = true;
          break;
        } else {
          showBtn = false;
        }
      }
    }
    return showBtn;
  }

  saveInventory(treatmentId, alignerId, alignerIndx){

    if(this.alignerFormInventory(alignerIndx).controls.length > 0){
      let lastIndx = this.alignerFormInventory(alignerIndx).controls.length-1;
      let lastIndxValue = this.alignerFormInventory(alignerIndx).controls[lastIndx].value;
      
      let alignerOrder = new AlignerOrderDto()
      alignerOrder.transactionDate = moment(lastIndxValue.transactionDate).toDate();
      alignerOrder.fromToDescription = lastIndxValue.fromTo;
      alignerOrder.upperQuantity = lastIndxValue.upperQuantity;
      alignerOrder.lowerQuantity = lastIndxValue.lowerQuantity;

      this.isWorking = true;

      if(lastIndxValue.type == "OnOrder"){
        this.locationClient.location_PostAlignerOrder(this.location.id, this.patient.id, treatmentId, alignerId, alignerOrder).subscribe(resp => {
          this.isWorking = false;
          this.getAligners(treatmentId);
        },
        err => {
          this.isWorking = false;
          console.log(err)
        })
      } else {
        this.isWorking = true;
        this.locationClient.location_PostAlignerReceive(this.location.id, this.patient.id, treatmentId, alignerId, alignerOrder).subscribe(resp => {
          this.isWorking = false;
          this.getAligners(treatmentId);
        },
        err => {
          this.isWorking = false;
          console.log(err)
          this.errorMsg = err.message;
          this.showErrorDialog();
        })

      }
    }
  }

  saveDispensed(treatmentId, alignerId, alignerIndx){
    if(this.alignerFormDispensed(alignerIndx).controls.length > 0){
      let lastIndx = this.alignerFormDispensed(alignerIndx).controls.length-1;
      let lastIndxValue = this.alignerFormDispensed(alignerIndx).controls[lastIndx].value;

      let alignerDispense = new AlignerDispenseDto();
      alignerDispense.transactionDate = moment(lastIndxValue.transactionDate).toDate();
      alignerDispense.instructions = lastIndxValue.instructions;
      alignerDispense.upperQuantity = lastIndxValue.upperQuantity;
      alignerDispense.lowerQuantity = lastIndxValue.lowerQuantity

      this.locationClient.location_PostAlignerDispense(this.location.id, this.patient.id, treatmentId, alignerId, alignerDispense).subscribe(resp => {
        this.isWorking = false;
        this.getAligners(treatmentId);
      },
      err => {
        this.isWorking = false;
        console.log(err)
        this.errorMsg = err.message;
        this.showErrorDialog();
      })

    }
  }

  showErrorDialog(){
    this.errorDialogRef = this.matDialog.open(this.errorDialog);
  }
  
}

export class TreatmentDtoVM extends TreatmentDto {
  selected:boolean = false;
}
