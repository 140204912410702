import * as CommuncationDeskStoreActions from './actions';
import { CommunicationDeskStoreModule } from './communication-desk-store.module';
import { CommunicationDeskEffectsService } from './effects';
import * as CommuncationDeskStoreSelectors from './selectors';
import * as CommunicationDeskStoreState from './state';

export {
  CommuncationDeskStoreActions,
  CommunicationDeskStoreState,
  CommunicationDeskStoreModule,
  CommunicationDeskEffectsService,
  CommuncationDeskStoreSelectors,
};
