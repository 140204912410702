import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ModelStoreActions,
  ModelStoreEntity,
  ModelStoreSelectors,
  PatientsStoreSelectors,
  RootStoreState
} from 'src/app/root-store';
import { Observable, Subject } from 'rxjs';
import { map, withLatestFrom, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StorageContentTypeEnum, StorageTypeEnum } from '../../../shared/services/api.service';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-threed-models',
  templateUrl: './3d-models.component.html',
  styleUrls: ['./3d-models.component.css']
})
export class ThreeDModelsComponent implements OnInit {
  @ViewChild('formGroupDirective', { static: true }) formGroupDirective: FormGroupDirective;

  renderingModel: boolean = false;
  model: ModelStoreEntity;
  modelFormGroup: FormGroup;
  models$: Observable<ModelStoreEntity[]> = this._store$
    .select(ModelStoreSelectors.selectAllModels)
    .pipe(map(models => {
      if (models.length > 0) this.selectModel(models[0]); //Set default Model
      return models;
    }));
  selectedModel$: Observable<ModelStoreEntity> = this._store$
    .select(ModelStoreSelectors.getSelectedModel);
  isWorking$ = this._store$
    .select(ModelStoreSelectors.selectModelsIsLoading);
  error$: Observable<any> = this._store$.select(ModelStoreSelectors.selectModelsError);

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private selectedPatientId: number;
  private fetchAll$: any;

  constructor(
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private _actions$: Actions,
    private _cdr: ChangeDetectorRef
  ) {
    this.setInitialFormGroup();
  }

  ngOnInit() {
    this.fetchAll$ = this._store$
      .select(PatientsStoreSelectors.getSelectedPatientId)
      .subscribe((patientId) => {
        this.selectedPatientId = patientId;
        if (this.selectedPatientId) {
          this._store$.dispatch(ModelStoreActions.LoadRequest({ patientId: this.selectedPatientId }));
          this._cdr.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.fetchAll$.unsubscribe();
    this._destroy$.next(true);
  }

  /**
   * Dispatches a select action to the Model store
   */
  selectLatestModel() {
    this.models$
      .pipe(withLatestFrom(this._store$.select(ModelStoreSelectors.getSelectedModelId)), take(1))
      .subscribe(([result, selectedModelId]) => {
        if (selectedModelId <= 0 && result && result.length > 0) this.selectModel(result[0]);

        this.selectModel(selectedModelId <= 0 && result && result.length > 0 ? result[0] : null);
      });
  }

  /**
   * Dispatches a select action to the Model store
   * @param model Model to be set as selected
   */
  selectModel(model?: ModelStoreEntity) {
    this.model = null;
    this._store$.dispatch(ModelStoreActions.DeselectRequest());
    if (model) {
      this.renderingModel = true;
      setTimeout(() => {
        this._store$.dispatch(ModelStoreActions.SelectRequest({ id: model.id }));
      });
    }
  }

  /**
   * Create new Model
   */
  add() {
    this._store$.dispatch(ModelStoreActions.DeselectRequest())
    this.model = new ModelStoreEntity({
      contentType: StorageContentTypeEnum.Model,
      createdWhen: new Date(),
      file: null,
      id: 0,
      patientId: this.selectedPatientId,
      report: null,
      type: StorageTypeEnum.File,
      childrenCount: 0
    });
    this.formGroupDirective.resetForm(this.model);
  }

  /**
   * Build the primary FormGroup using the FormBuilder
   */
  setInitialFormGroup() {
    this.modelFormGroup = this._fb.group({
      createdWhen: [],
      file: [null, Validators.required],
      id: [],
      patientId: [null, Validators.required],
      report: [],
      type: [null, Validators.required]
    });
  }

  /**
 * Saves the Model data
 */
  save() {
    this.model = { ...this.model, ...this.modelFormGroup.getRawValue() };

    //Listen for success prior to dispatching request
    this._actions$
      .pipe(ofType(ModelStoreActions.AddSuccess), take(1), takeUntil(this._destroy$))
      .subscribe((result) => this.selectLatestModel());

    this._store$.dispatch(ModelStoreActions.AddRequest({ model: this.model }));
  }

  /**
   * Cancels the current new card
   */
  cancel() {
    this._store$.dispatch(ModelStoreActions.ResetError());
    this.selectLatestModel();
  }

  /**
   * File change event and initialize file.
   */
  fileChanged(file) {
    if (file) {
      file.data = new Blob([file], { type: file.type });
      file.fileName = file.name;
      const reader = new FileReader();

      reader.addEventListener("load", event => {
        file.dataUrl = reader.result;
      });

      reader.readAsDataURL(file);
    }

    this.modelFormGroup.patchValue({
      file: file
    });
  }
}
