import * as fromRouter from '@ngrx/router-store';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { State } from './state';
import { FEATURE_KEY } from './featurekey';
import * as RootStoreState from '../root-state';

//export const selectRouter = createFeatureSelector<RootStoreState.State, fromRouter.RouterReducerState<any>>(FEATURE_KEY);
//export const selectRouter = createFeatureSelector<State>(FEATURE_KEY);
export const selectRouter = createSelector((state: RootStoreState.State): State => state[FEATURE_KEY], value => value)

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);
