import { createAction, props } from '@ngrx/store';
import { FileParameter } from '../../shared/services/api.service';
import { LocationStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction('[Locations] Load Request', props<{ page?: number; pageSize?: number }>());
export const LoadSuccess = createAction('[Locations] Load Success', props<{ locations: LocationStoreEntity[] }>());
export const LoadFailure = createAction('[Locations] Load Failure', props<{ error: string }>());

//LOAD ONE
export const LoadOneRequest = createAction('[Locations] Load One Request', props<{ id: number }>());
export const LoadOneSuccess = createAction('[Locations] Load One Success', props<{ location: LocationStoreEntity }>());
export const LoadOneFailure = createAction('[Locations] Load One Failure', props<{ error: string }>());

//ADD
export const AddRequest = createAction('[Locations] Add Request', props<{ location: LocationStoreEntity }>());
export const AddSuccess = createAction('[Locations] Add Success', props<{ location: any }>());
export const AddFailure = createAction('[Locations] Add Failure', props<{ error: string }>());

//DELETE
export const DeleteRequest = createAction('[Locations] Delete Request', props<{ id: number }>());
export const DeleteSuccess = createAction('[Locations] Delete Success', props<{ id: number }>());
export const DeleteFailure = createAction('[Locations] Delete Failure', props<{ error: string }>());

//SELECT
export const SelectRequest = createAction('[Locations] Select Request', props<{ id: number }>());
export const SelectSuccess = createAction('[Locations] Select Success', props<{ location: LocationStoreEntity }>());
export const SelectFailure = createAction('[Locations] Select Failure', props<{ error: string }>());

//UPDATE
export const UpdateRequest = createAction('[Locations] Update Request', props<{ location: LocationStoreEntity }>());
export const UpdateSuccess = createAction('[Locations] Update Success', props<{ location: LocationStoreEntity }>());
export const UpdateFailure = createAction('[Locations] Update Failure', props<{ error: any }>());

//UTILITY
export const Unload = createAction('[Locations] Unload');

//UPDATE PROFILE
export const UpdateProfileRequest = createAction(
  '[Locations] Update Profile Request',
  props<{
    locationId: number;
    file: FileParameter;
  }>()
);
export const UpdateProfileSuccess = createAction('[Locations] Update Profile Success', props<{ location: LocationStoreEntity }>());
export const UpdateProfileFailure = createAction('[Locations] Update Profile Failure', props<{ error: string }>());

//DELETE PROFILE
export const DeleteProfileRequest = createAction(
  '[Locations] Delete Profile Request',
  props<{
    locationId: number;
  }>()
);

export const DeleteProfileSuccess = createAction('[Locations] Delete Profile Success', props<{ locationId: number }>());

export const DeleteProfileFailure = createAction('[Locations] Delete Profile Failure', props<{ error: string }>());
