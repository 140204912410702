import { createAction, props } from '@ngrx/store';
import { FileParameter, MessageDraftDto } from '../../shared/services/api.service';
import { MessageDraftStoreEntity } from './state';

export const LoadRequest = createAction('[Message Draft] Load Request', props<{ messageDraftId?: number }>());
export const LoadSuccess = createAction('[Message Draft] Load Success', props<{ entries: MessageDraftStoreEntity[] | MessageDraftStoreEntity }>());
export const LoadFailure = createAction('[Message Draft] Load Failure', props<{ error: any }>());

export const DeleteMessageDraftRequest = createAction('[Message Draft] Delete Message Draft Request', props<{ patientId: number; messageDraftId: number }>());
export const DeleteMessageDraftSuccess = createAction('[Message Draft] Delete Message Draft Success', props<{ patientId: number; messageDraftId: number }>());
export const DeleteMessageDraftFailure = createAction('[Message Draft] Delete Message Draft Failure', props<{ error: any }>());

export const Remove = createAction('[Message Draft] Remove Entry', props<{ messageDraftId: number }>());

export const AddMessageDraftRequest = createAction('[Message Draft] Add Message Draft Request', props<{ message: MessageDraftDto, files?: FileParameter[] | null }>());
export const AddMessageDraftSuccess = createAction('[Message Draft] Add Message Draft Success', props<{ message: MessageDraftDto }>());
export const AddMessageDraftFailure = createAction('[Message Draft] Add Message Draft Failure', props<{ error: any }>());

export const UpdateMessageDraftRequest = createAction('[Message Draft] Update Message Draft Request', props<{ message: MessageDraftDto, deleteAttachmentIds?: number[] | null, files?: FileParameter[] | null }>());
export const UpdateMessageDraftSuccess = createAction('[Message Draft] Update Message Draft Success', props<{ message: MessageDraftDto }>());
export const UpdateMessageDraftFailure = createAction('[Message Draft] Update Message Draft Failure', props<{ error: any }>());

export const AddMessageDraftAttachmentRequest = createAction('[Message Draft] Add Message Draft Attachment Request', props<{ message: MessageDraftDto, files: FileParameter[] }>());
export const AddMessageDraftAttachmentSuccess = createAction('[Message Draft] Add Message Draft Success', props<{ message: MessageDraftDto }>());
