import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule
} from '@angular/material';
import { NotificationSettingsComponent } from './notification-settings.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ElementsModule } from '../../elements/elements.module';
import { PatientNotificationModalModule } from '../../shared-module/searchmodule/patient-notification-modal/patient-notification-modal.module';

@NgModule({
  declarations: [
    NotificationSettingsComponent
  ],
  entryComponents: [
    NotificationSettingsComponent
  ],
  exports: [
    NotificationSettingsComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ElementsModule,
    PatientNotificationModalModule
  ]
})
export class NotificationSettingsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
