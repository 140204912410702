import { Action, createReducer, on } from "@ngrx/store";
import * as AppointmentComplianceStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD ONE
  on(AppointmentComplianceStoreActions.LoadOneRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(AppointmentComplianceStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.appointmentCompliance, {
      ...state,
      isLoading: false,
      error: null
    })
  )),
  on(AppointmentComplianceStoreActions.LoadOneFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(AppointmentComplianceStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedAppointmentComplianceId: null
  })),
  on(AppointmentComplianceStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedAppointmentComplianceId: action.appointmentCompliance && action.appointmentCompliance.id
  })),
  on(AppointmentComplianceStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(AppointmentComplianceStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(AppointmentComplianceStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedAppointmentComplianceId: null
  })),
  on(AppointmentComplianceStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UPDATE
  on(AppointmentComplianceStoreActions.UpdateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(AppointmentComplianceStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.appointmentCompliance, {
      ...state,
      isLoading: false,
      error: null
    })
  ),
  on(AppointmentComplianceStoreActions.UpdateFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({
      ...state
    });
  }),
  //UTILITY
  on(AppointmentComplianceStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
