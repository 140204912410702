import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as PhotoGroupStoreActions from './actions';
import * as PhotoGroupStoreSelectors from './selectors';
import { SettingsClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class PhotoGroupStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _settingsClient: SettingsClient) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoGroupStoreActions.LoadRequest),
    switchMap((action) =>
      this._settingsClient.settings_GetPhotoGroups().pipe(
        map((result) => PhotoGroupStoreActions.LoadSuccess({ photoGroups: result })),
        catchError((err: HttpErrorResponse) => of(PhotoGroupStoreActions.LoadFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoGroupStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(PhotoGroupStoreSelectors.selectPhotoGroupById(action.id)).pipe(map((result) => PhotoGroupStoreActions.SelectSuccess({ photoGroup: result })))
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoGroupStoreActions.UpdateRequest),
    switchMap((action) =>
      this._settingsClient.settings_PutPhotoGroup(action.id, action.photoGroup).pipe(
        switchMap(() => this._settingsClient.settings_GetPhotoGroup(action.id)),
        map((result) => PhotoGroupStoreActions.UpdateSuccess({ photoGroup: result })),
        catchError((err: HttpErrorResponse) => of(PhotoGroupStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoGroupStoreActions.AddRequest),
    switchMap((action) =>
      this._settingsClient.settings_PostPhotoGroup(action.photoGroup).pipe(
        map((result) => PhotoGroupStoreActions.AddSuccess({ photoGroup: result })),
        catchError((err: HttpErrorResponse) => of(PhotoGroupStoreActions.AddFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoGroupStoreActions.DeleteRequest),
    switchMap((action) =>
      this._settingsClient.settings_DeletePhotoGroup(action.id).pipe(
        map(() => PhotoGroupStoreActions.DeleteSuccess({ id: action.id })),
        catchError((err: HttpErrorResponse) => of(PhotoGroupStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoGroupStoreActions.LoadOneRequest),
    switchMap((action) =>
      this._settingsClient.settings_GetPhotoGroup(action.id).pipe(
        map((photoGroup) => PhotoGroupStoreActions.LoadOneSuccess({ photoGroup: photoGroup })),
        catchError((err: HttpErrorResponse) => of(PhotoGroupStoreActions.LoadOneFailure({ error: err.message })))
      )
    )
  );
}
