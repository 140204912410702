import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MessageService } from '../../../../core/message.service';

import { PatientClient } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.css']
})
export class AppNotificationComponent implements OnInit {

  @Input() patientId: any;
  @Input() selectedLocation:any;
  @Output() backToResponsibleParty = new EventEmitter();

  submitclick:boolean = false;

  appointmentNotificationsArray: any[] = [];
  _newPatientData: any;

  constructor(private _dataService: MessageService, private _patientClient:PatientClient) { }

  ngOnInit() {
	this.getPatientNotification();
  }


  getPatientNotification(){


	  this._patientClient.patient_GetNotificationsByPatient(this.patientId, null).subscribe(
		(res) => {
			this._newPatientData = JSON.parse(JSON.stringify(res));

			this._newPatientData.map(item => {

				let contactPatient = [];

				if (item.contactSourceType == 'Email') {
					let patientEmailObject = {
						type: item.contactPhoneEmail,
						selected: false,
						forAppointments: item.forAppointments,
						forBirthday: item.forBirthday,
						forFinancial: item.forFinancial,
						forMarketing: item.forMarketing,
						forTreatment: item.forTreatment
					};

					contactPatient.push(patientEmailObject);
				}

				if (item.contactSourceType == 'Phone') {
					let patientEmailObject = {
						type: item.contactPhoneEmail,
						selected: false,
						forAppointments: item.forAppointments,
						forBirthday: item.forBirthday,
						forFinancial: item.forFinancial,
						forMarketing: item.forMarketing,
						forTreatment: item.forTreatment
					};

					contactPatient.push(patientEmailObject);
				}



				let patientEmailPhoneObject = {
					id: item.id,
					type: item.contactPhoneEmail,
					selected: false,
					forAppointments: item.forAppointments,
					forBirthday: item.forBirthday,
					forFinancial: item.forFinancial,
					forMarketing: item.forMarketing,
					forTreatment: item.forTreatment
				};

				if(this.appointmentNotificationsArray.length == 0){
					let patientObject = {
					  "id": item.id,
					  "name" : item.firstName + ' ' + item.lastName,
					  "abbrv": item.relationshipTypeAbbreviation,
					  "contact": []
					}

					patientObject.contact.push(patientEmailPhoneObject)

					this.appointmentNotificationsArray.push(patientObject)
				} else {

					let contactName: any = item.firstName + ' ' + item.lastName;

					let notifIndex: any = this.appointmentNotificationsArray.findIndex(noti => noti.name === contactName);

					if(notifIndex != -1){
						this.appointmentNotificationsArray[notifIndex].contact.push(patientEmailPhoneObject)
					} else {
						let patientObject = {
						  "id": item.id,
						  "name" : item.firstName + ' ' + item.lastName,
						  "abbrv": item.relationshipTypeAbbreviation,
						  "contact": []
						}

						patientObject.contact.push(patientEmailPhoneObject)

						this.appointmentNotificationsArray.push(patientObject)
					}
				}
			})
		},
		(err) => {
			console.log(err);
		}
	  )
  }

  cancel() {
    this.backToResponsibleParty.emit({"patientid": this.patientId, "movetoslot":false});
  }


  changeNotification(section, outerIndx, innerIndx, contact){


	  console.log(this._newPatientData, contact)

	  let contactIndx:any = this._newPatientData.findIndex(elem => elem.id === contact.id);


	  if(contactIndx != -1){

		  if(section == 'forAppointments'){
			  this._newPatientData[contactIndx].forAppointments = this._newPatientData[contactIndx].forAppointments == true ? false : true;
		  } else if(section == 'forBirthday'){
			  this._newPatientData[contactIndx].forBirthday = this._newPatientData[contactIndx].forBirthday == true ? false : true;
		  } else if(section == 'forFinancial'){
			   this._newPatientData[contactIndx].forFinancial = this._newPatientData[contactIndx].forFinancial == true ? false : true;
		  } else if(section == 'forMarketing'){
			  this._newPatientData[contactIndx].forMarketing = this._newPatientData[contactIndx].forMarketing == true ? false : true;
		  } else if(section == 'forTreatment'){
			  this._newPatientData[contactIndx].forTreatment = this._newPatientData[contactIndx].forTreatment == true ? false : true;
		  }
	  }
  }

  submitPatient() {

	  /* this._patientClient.patient_PostNotifications(this.patientId, this._newPatientData, null).subscribe(
		(res) => {
			this._dataService.sendMessage('addpatientfinished', {});
			this.backToResponsibleParty.emit({"patientid": this.patientId, "movetoslot":true});
		},
		(err) => {
			console.log(err);
		}
	  ) */

	  this.submitclick = true;
  }

  aftersave(res){
    this.submitclick = false;
	this.backToResponsibleParty.emit({"patientid": this.patientId, "movetoslot":true});
	this._dataService.sendMessage('addpatientfinished', {});
  }





}
