import { Component, Input } from '@angular/core';
import { faCircle as faSolidCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faRegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faExchangeAlt } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-image-icon',
  templateUrl: './image-icon.component.html',
  styleUrls: ['./image-icon.component.scss'],
})
export class ImageIconComponent {
  @Input() type: string;
  @Input() color: string;

  faSolidCircle = faSolidCircle;
  faRegularCircle = faRegularCircle;
  faExchangeAlt = faExchangeAlt;
}
