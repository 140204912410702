import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientStatusGroupDto, IPatientStatusGroupDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientStatusGroupStoreEntity> = createEntityAdapter<PatientStatusGroupStoreEntity>({
  selectId: patientStatusGroup => patientStatusGroup.id,
  sortComparer: (a: PatientStatusGroupStoreEntity, b: PatientStatusGroupStoreEntity): number => { return b.name > a.name ? -1 : 0 }
});

export interface State extends EntityState<PatientStatusGroupStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientStatusGroupId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientStatusGroupId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientStatusGroupStoreEntity extends PatientStatusGroupDto {
  constructor(data?: IPatientStatusGroupDto) {
    super(data);
  }
}
