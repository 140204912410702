import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {  filter, map, switchMap, } from 'rxjs/operators';
import { VoiceEventStateEnum } from '../../shared/services/api.service';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as ActiveVoiceStoreActions from './actions';
import * as ActiveVoiceStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class ActiveVoiceStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>) {}
  private activeStates: number[] = [
    Object.keys(VoiceEventStateEnum).indexOf(VoiceEventStateEnum.Ringing),
    Object.keys(VoiceEventStateEnum).indexOf(VoiceEventStateEnum.Answered),
    Object.keys(VoiceEventStateEnum).indexOf(VoiceEventStateEnum.Hold),
    Object.keys(VoiceEventStateEnum).indexOf(VoiceEventStateEnum.Unhold)
  ];

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ActiveVoiceStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(ActiveVoiceStoreSelectors.selectActiveVoiceByToExtension(action.toExtension))
        .pipe(map((result) => ActiveVoiceStoreActions.SelectSuccess({ activeVoice: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(ActiveVoiceStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(ActiveVoiceStoreActions.DeselectSuccess());
    })
  );

  eventHubEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.VoiceEvent),
      map((action) => {
        const state: number = isNaN(parseFloat(action.event.state)) ?
          Object.keys(VoiceEventStateEnum).indexOf(action.event.state) :
          parseFloat(action.event.state);

        if (this.activeStates.includes(state))
          return ActiveVoiceStoreActions.LoadSuccess({ voiceHubEvent: action.event });
        else
          return ActiveVoiceStoreActions.RemoveSuccess({ toExtension: action.event.toExtension });
      })
    )
  );
}
