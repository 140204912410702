import { createSelector, MemoizedSelector, createFeatureSelector } from '@ngrx/store';
import { featureAdapter } from './state';
import { State } from '../state';
import { TREATMENT_FEATURE_KEY } from '../featurekey';

const selectFeatureState: MemoizedSelector<object, State> = createFeatureSelector<State>(TREATMENT_FEATURE_KEY)

export const selectPlanGroupState = createSelector(
  selectFeatureState,
  (state) => state.planGroups
);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectPlanGroupState);
export const selectPlanGroupIds = selectIds;
export const selectPlanGroupTotal = selectTotal;
export const selectAllPlanGroups = selectAll;
export const selectAllPlanGroupEntities = selectEntities;

export const selectIsActivePlanGroups = (isActive: boolean) => createSelector(
  selectAllPlanGroups,
  planGroups => {
    planGroups.forEach(pg => {
      if (pg.plans)
        pg.plans = pg.plans.filter(pgp => pgp.isActive == isActive);
    });
    return planGroups.filter(pg => pg.isActive == isActive);
  }
)

export const selectIsLoading = createSelector(
  selectPlanGroupState,
  (state) => state.isLoading
);

export const selectError = createSelector(
  selectPlanGroupState,
  (state) => state.error
);
