import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActiveTreatmentProgressDto, IActiveTreatmentProgressDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<ActiveTreatmentProgressStoreEntity> = createEntityAdapter<ActiveTreatmentProgressStoreEntity>({
  selectId: (activeTreatmentProgress) => activeTreatmentProgress.patientId
});

export interface State extends EntityState<ActiveTreatmentProgressStoreEntity> {
  isLoading?: boolean;
  error?: any;
  hasLoaded?: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  hasLoaded: false,
})

export class ActiveTreatmentProgressStoreEntity extends ActiveTreatmentProgressDto {
  constructor(data?: IActiveTreatmentProgressDto) {
    super(data);
  }
}
