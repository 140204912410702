import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsClient } from 'src/app/shared/services/api.service';
import * as TreatmentStoreActions from './actions';
import { DiagnosisStoreActions } from './diagnosis';
import { GoalStoreActions } from './goal';
import { PlanGroupStoreActions } from './plan-group';
import * as RootStoreState from './state';
import { StepGroupStoreActions } from './step-group';

@Injectable({ providedIn: 'root' })
export class TreatmentEffects {
  constructor(private store$: Store<RootStoreState.State>, private actions$: Actions, private settingsClient: SettingsClient) {}

  loadRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TreatmentStoreActions.LoadRequest),
      switchMap(() =>
        [
          DiagnosisStoreActions.LoadRequest({}),
          GoalStoreActions.LoadRequest(),
          PlanGroupStoreActions.LoadRequest(),
          StepGroupStoreActions.LoadRequest()
        ]
      )
    );
  });

  loadOnceRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TreatmentStoreActions.LoadOnceRequest),
      switchMap((action) =>
        [
          DiagnosisStoreActions.LoadOnceRequest(),
          GoalStoreActions.LoadOnceRequest(),
          PlanGroupStoreActions.LoadOnceRequest(),
          StepGroupStoreActions.LoadOnceRequest()
        ]
      )
    );
  });
}
