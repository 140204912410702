import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { defer, from } from 'rxjs';
import { mergeAll, take, toArray } from 'rxjs/operators';
import { ErrorDetails, FileDto, FileParameter, PatientClient, StorageContentTypeEnum } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit, OnDestroy {
  files: File[];
  isWorking: boolean = false;
  error: string;
  private uploadAll$: any;

  constructor(
    private _dialogRef: MatDialogRef<FileUploadDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: FileUploadDialogData,
    private _patientClient: PatientClient
  ) { }

  generateObservableUploads(files: File[]): Promise<any[]> {
    return new Promise(resolve => {
      let fileUploads = [];
      _.each(files, (file: File) => {
        this.isWorking = true;
        this.error = null;
        const data = new Blob([file], { type: file.type });
        const fileParameter: FileParameter = {
          data,
          fileName: file.name,
        };
        const fileUplaod = this._patientClient
          .patient_PostFile(
            this.data.patientId,
            this.data.folderId,
            this.data.parentId,
            fileParameter,
            this.data.contentType);
        fileUploads.push(fileUplaod);
      });
      resolve(fileUploads);
    });
  }

  upload() {
    if (this.files && this.files.length > 0) {
      this.generateObservableUploads(this.files).then((promises: any) => {
        const observables = promises.map(a => defer(() => a));
        this.uploadAll$ = from(observables)
          .pipe(
            mergeAll(1),
            toArray()
          )
          .subscribe((res) => {
            this.isWorking = false;
            this._dialogRef.close(res);
          });
      }, (err) => {
        this.isWorking = false;
        this.error = err.message;
      });
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.uploadAll$)
      this.uploadAll$.unsubscribe();
  }
}

export interface FileUploadDialogData {
  patientId: number;
  folderId: number;
  parentId?: number;
  contentType: StorageContentTypeEnum;
}
