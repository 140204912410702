import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as MarketingPackageStoreActions from './actions';
import * as MarketingPackageStoreSelectors from './selectors';
import { MarketingClient } from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material';

@Injectable({ providedIn: 'root' })
export class MarketingPackageStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _snackBar: MatSnackBar,
    private _marketingClient: MarketingClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(MarketingPackageStoreActions.LoadRequest),
    switchMap((action) => {
      return this._marketingClient.marketing_GetMarketingPackages().pipe(
        map((result) => MarketingPackageStoreActions.LoadSuccess({ marketingPackages: result })),
        catchError((err: HttpErrorResponse) => of(MarketingPackageStoreActions.LoadFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(MarketingPackageStoreActions.LoadOneRequest),
    switchMap((action) => {
      return this._marketingClient.marketing_GetMarketingPackage(action.id).pipe(
        map((result) => MarketingPackageStoreActions.LoadOneSuccess({ marketingPackage: result })),
        catchError((err: HttpErrorResponse) => of(MarketingPackageStoreActions.LoadOneFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(MarketingPackageStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(MarketingPackageStoreSelectors.selectMarketingPackageById(action.id))
        .pipe(map((result) => MarketingPackageStoreActions.SelectSuccess({ marketingPackage: result })))
    )
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(MarketingPackageStoreActions.AddRequest),
    switchMap((action) =>
      this._marketingClient.marketing_PostMarketingPackage(action.marketingPackage).pipe(
        map((result) => {
          this._snackBar.open("Successfully added!", "Ok", {
            duration: 3000,
          });
          return MarketingPackageStoreActions.AddSuccess({ marketingPackage: result });
        }),
        catchError((err: HttpErrorResponse) => of(MarketingPackageStoreActions.AddFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(MarketingPackageStoreActions.UpdateRequest),
    switchMap((action) =>
      this._marketingClient.marketing_PutMarketingPackage(action.id, action.marketingPackage).pipe(
        switchMap(() => this._marketingClient.marketing_GetMarketingPackage(action.id)),
        map((result) => {
          this._snackBar.open("Successfully updated!", "Ok", {
            duration: 3000,
          });
          return MarketingPackageStoreActions.UpdateSuccess({ marketingPackage: result });
        }),
        catchError((err: HttpErrorResponse) => of(MarketingPackageStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(MarketingPackageStoreActions.DeleteRequest),
    switchMap((action) =>
      this._marketingClient.marketing_DeleteMarketingPackage(action.id).pipe(
        map(() => {
          this._snackBar.open("Successfully deleted!", "Ok", {
            duration: 3000,
          });
          return MarketingPackageStoreActions.DeleteSuccess({ id: action.id });
        }),
        catchError((err: HttpErrorResponse) => of(MarketingPackageStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );
}
