import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CardsService } from '../../shared/services/cards.service';
import { AuthStoreSelectors } from '../auth-store';
import * as AuthStoreActions from '../auth-store/actions';
import * as RootStoreState from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as UserStoreSelectors from '../user-store/selectors';
import * as CardsStoreActions from './actions';
import { IOpenCard } from './IOpenCard';
import * as CardsStoreSelectors from './selectors';

@Injectable()
export class CardsStoreEffects implements OnInitEffects {
  constructor(private _cardsService: CardsService, private actions$: Actions, private store$: Store<RootStoreState.State>) {}

  ngrxOnInitEffects(): Action {
    const strOpenCards = this.getCardsSessionStorage();
    let arOpenCards: IOpenCard[] = [];
    if (strOpenCards) arOpenCards = JSON.parse(strOpenCards);
    return CardsStoreActions.CardsInitSuccess({ openCards: arOpenCards });
  }

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CardsStoreActions.AddRequestAction),
    switchMap((action) =>
      this._cardsService.addCard(action.card).pipe(
        map((card) => CardsStoreActions.AddSuccessAction({ card: card })),
        catchError((error) => of(CardsStoreActions.AddFailureAction({ error: error })))
      )
    )
  );

  @Effect()
  openCardRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CardsStoreActions.OpenCardRequest),
    withLatestFrom(this.store$.select(CardsStoreSelectors.selectCardsState)),
    map(([action, state]) => {
      this.setCardsSessionStorage(state.openCards);
      return CardsStoreActions.OpenSuccess({ cards: state.openCards });
    })
  );

  @Effect()
  closeCardRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CardsStoreActions.CloseCardRequest),
    withLatestFrom(this.store$),
    map(([action, state]) => {
      let cards = [...state.Cards.openCards];
      let cardToRemove = cards.find((c) => c.selector === action.selector);
      if (cardToRemove) {
        cards.forEach((card) => {
          if (card.order > cardToRemove.order) card.order--;
        });
        cards.splice(cards.indexOf(cardToRemove), 1);
      }
      this.setCardsSessionStorage(cards);
      return CardsStoreActions.CloseCardSuccess({ cards: cards });
    })
  );

  @Effect()
  changeCardOrderRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CardsStoreActions.ChangeCardOrderRequest),
    withLatestFrom(this.store$),
    map(([action, state]) => {
      let cards = [...state.Cards.openCards];
      let cardToMove = cards.find((c) => c.selector === action.selector);

      const prevIndex: number = cardToMove.order;
      const nextIndex: number = action.order;
      const shiftCards = cards.filter((card) => {
        //filter to cards that are moving
        if (nextIndex < prevIndex)
          //dragged left
          return card.order >= nextIndex && card.order < prevIndex;
        //dragged right
        else return card.order > prevIndex && card.order <= nextIndex;
      });

      cardToMove.order = nextIndex;

      shiftCards.forEach((card) => {
        //move cards
        if (nextIndex < prevIndex)
          //dragged left
          card.order++;
        //dragged right
        else card.order--;
      });

      this.setCardsSessionStorage(cards);
      return CardsStoreActions.ChangeCardOrderSuccess({ cards: cards });
    })
  );

  @Effect()
  loginSuccessTxCardFavoritesEffect$ = this.actions$.pipe(
    ofType(AuthStoreActions.LoginSuccess),
    //Only open cards if no session value or no cards currently open (prevent duplicates on page refresh)
    filter(() => this.cardsSessionStorageNullOrEmpty()),
    switchMap((action) =>
      this.store$.select(UserStoreSelectors.selectUserById(action.userCredentials.id)).pipe(
        filter((user) => !!user),
        take(1)
      )
    ),
    switchMap((user) => {
      if (user.txCardFavorites)
        return of(...user.txCardFavorites.map((card, ind) => CardsStoreActions.OpenCardRequest({ selector: card, order: ind })));
      else return EMPTY;
    })
  );

  @Effect()
  loginMultiSuccessTxCardFavoritesEffect$ = this.actions$.pipe(
    ofType(AuthStoreActions.LoginMultiSuccess),
    switchMap(action =>
      this.store$.select(UserStoreSelectors.selectUserById(action.userCredentials.id)).pipe(
        filter((user) => !!user),
        take(1)
      )
    ),
    withLatestFrom(this.store$.select(CardsStoreSelectors.selectOpenCards)),
    map(([user, openCards]) => {
      this.removeCardsSessionStorage();
      const newOpenCards: IOpenCard[] = (user.txCardFavorites || []).map((selector, ind) => (<IOpenCard>{selector: selector, order: ind}));
      this.setCardsSessionStorage(newOpenCards);
      return CardsStoreActions.SetOpenCardsRequest({ cards: newOpenCards});
    })
  )

  @Effect()
  openFavoriteCardsRequestEffect$ = this.actions$.pipe(
    ofType(CardsStoreActions.OpenFavoriteCardsRequest),
    withLatestFrom(this.store$.select(AuthStoreSelectors.selectCredentials)),
    withLatestFrom(this.store$.select(CardsStoreSelectors.selectCardsState)),
    switchMap(([[action, user], state]) => {
      this.setCardsSessionStorage([]);
      state.openCards = [];
      if (user.txCardFavorites) {
        return of(...user.txCardFavorites.map((card, ind) => CardsStoreActions.OpenCardRequest({ selector: card, order: ind })));
      }
      else return EMPTY;
    })
  );

  private getCardsSessionStorage(): string {
    return sessionStorage.getItem('openCards');
  }
  private setCardsSessionStorage(value: any) {
    sessionStorage.setItem('openCards', JSON.stringify(value));
  }
  private removeCardsSessionStorage() {
    sessionStorage.removeItem('openCards');
  }
  private cardsSessionStorageNullOrEmpty(): boolean {
    const value = this.getCardsSessionStorage();
    if(value){
      return (<Array<any>>JSON.parse(value)).length == 0;
    }
    return true;
  }
}
