import { Action, createReducer, on } from '@ngrx/store';
import * as PatientNotepadStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  on(PatientNotepadStoreActions.LoadPatientNotepadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientNotepadStoreActions.LoadPatientNotepadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.PatientNotepads, { ...s, isLoading: false, error: null });
  }),
  on(PatientNotepadStoreActions.LoadPatientNotepadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
