import { NgModule } from '@angular/core';
import { ImageErrorHandlerDirective } from './image-error-handler.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    ImageErrorHandlerDirective
  ],
  exports: [
    ImageErrorHandlerDirective
  ]
})


export class ImageErrorHandlerModule { }
