import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  public dialogRef: MatDialogRef<any>;
  public componentInstance: any;
  isShowSpinner: boolean = false;

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data?: any) {
    if (data != null) {
      this.isShowSpinner = data.isShowSpinner;
    }
  }

  open(template: any, data?: any, disableClose?: boolean, autoFocus?: boolean) {
    this.dialogRef = this.dialog.open(template, {
      panelClass: 'appDialog',
      backdropClass: 'whitebackdrop',
      data: data,
      disableClose: disableClose,
      autoFocus: autoFocus
    });

    return this.dialogRef;
  }

  showSpinner() {
    this.open(DialogComponent, {
      isShowSpinner: true
    }, true);
  }

  hideSpinner() {
    this.dialogRef.close();
  }

  setData(property: string, value: any) {
    if (this.dialogRef != null && this.dialogRef.componentInstance != null) {
      this.dialogRef.componentInstance[property] = value;
    }
  }
}