import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PatientsStoreSelectors, PatientStoreEntity, PatientTreatmentStoreActions, PatientTreatmentStoreSelectors, RootStoreState } from '../../root-store';

@Component({
  selector: 'patient-treatment-selector-dialog',
  templateUrl: './patient-treatment-selector-dialog.component.html',
  styleUrls: ['./patient-treatment-selector-dialog.component.scss'],
})
export class PatientTreatmentSelectorDialogComponent implements OnInit, OnDestroy {
  selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  patientTreatments$ = this._store$.select(PatientTreatmentStoreSelectors.selectAllPlans);
  selectedPatientTreatmentId: number;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  isWorking$ = this._store$.select(PatientsStoreSelectors.selectPatientsIsLoading);
  isWorking: boolean = false;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<PatientTreatmentSelectorDialogComponent>,
    private _store$: Store<RootStoreState.State>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: any,
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  initialize(): void {
    this.isWorking = true;
    this.selectedPatient$
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((patient) => {
        this.isWorking = false;
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: patient.locationId, patientId: patient.id }))
      });
  }

  done() {
    this._bottomSheetRef.dismiss(this.selectedPatientTreatmentId);
  }
}
