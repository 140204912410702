import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { SettingsClient } from 'src/app/shared/services/api.service';
import * as AuthStoreActions  from '../auth-store/actions';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as SettingsStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class SettingsStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _settingsClient: SettingsClient) { }

  @Effect()
  loginLoadSettingsEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AuthStoreActions.LoginSuccess, AuthStoreActions.ChangeTenantSuccess, SignalRHubStoreActions.HubStarted),
    withLatestFrom(this.store$.select(SignalRHubStoreSelectors.selectHubConnectionStatus)),
    filter(([_, status]) => status == HubConnectionState.Connected),
    mapTo(SettingsStoreActions.LoadRequest({}))
  );

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SettingsStoreActions.LoadRequest),
    switchMap((action) =>
      this._settingsClient.settings_GetOptions().pipe(
        map((result) => SettingsStoreActions.LoadSuccess({ settings: result })),
        catchError((err: HttpErrorResponse) => of(SettingsStoreActions.LoadFailure({ error: err.message })))
      )
    )
  );
}
