import { createAction, props } from '@ngrx/store';
import { PlanGroupDto } from 'src/app/shared/services/api.service';

export const LoadRequest = createAction(
  '[Treatment] PlanGroup Load Request',
);

export const LoadSuccess = createAction(
  '[Treatment] PlanGroup Load Success',
  props<{ planGroups: PlanGroupDto[] }>()
);

export const LoadFailure = createAction(
  '[Treatment] PlanGroup Load Failure',
  props<{ err: any}>()
);

export const LoadOnceRequest = createAction(
  '[Treatment] PlanGroup Load Once Request',
);
