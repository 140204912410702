import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { INSURANCE_COMPANY_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { InsuranceCompanyStoreEffects } from './effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(INSURANCE_COMPANY_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([InsuranceCompanyStoreEffects])
  ]
})
export class InsuranceCompanyStoreModule { }
