import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_COMPLETION_DATE_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientCompletionDateStoreEntity, State } from './state';

export const selectPatientCompletionDatesState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_COMPLETION_DATE_FEATURE_KEY);

export const getSelectedPatientCompletionDateId = createSelector(selectPatientCompletionDatesState, (state) => state.selectedPatientCompletionDateId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientCompletionDatesState);
export const selectPatientCompletionDateIds = selectIds;
export const selectAllPatientCompletionDates = selectAll;
export const selectAllPatientCompletionDateEntities = selectEntities;

export const getSelectedPatientCompletionDate = createSelector(
  selectPatientCompletionDatesState,
  selectAllPatientCompletionDates,
  (state, allPatientCompletionDates: PatientCompletionDateStoreEntity[]) =>
    allPatientCompletionDates ? allPatientCompletionDates.find((rp) => rp.id === state.selectedPatientCompletionDateId) : null
);

export const selectPatientCompletionDateById = (id: number) =>
  createSelector(selectAllPatientCompletionDates, (allPatientCompletionDates: PatientCompletionDateStoreEntity[]) =>
    allPatientCompletionDates ? allPatientCompletionDates.find((rp) => rp.id === id) : null
  );

export const selectPatientCompletionDatesError: MemoizedSelector<object, any> = createSelector(selectPatientCompletionDatesState, (state) => state.error);

export const selectPatientCompletionDatesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientCompletionDatesState, (state: State) => state.isLoading);
