import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IPostingCodeDto, PostingCodeDto } from "src/app/shared/services/api.service";

export const featureAdapter: EntityAdapter<PostingCodeStoreEntity> = createEntityAdapter<any>({
  selectId: postingCode => postingCode.id,
  sortComparer: (a: PostingCodeStoreEntity, b: PostingCodeStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<PostingCodeStoreEntity> {
  selectedPostingCodeId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPostingCodeId: null,
  isLoading: false,
  error: null
});

export class PostingCodeStoreEntity extends PostingCodeDto {
  constructor(data?: IPostingCodeDto) {
    super(data);
  }
}
