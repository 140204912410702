import { Component, OnInit, Input, ViewChild, Injector, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ComponentPortal, PortalOutlet, PortalInjector } from '@angular/cdk/portal';

import { CardsStoreState, CardsStoreSelectors, CardsStoreActions } from '../../../root-store';
import { ICardComponent, ICardData, CARD_DATA, ICard, IFlipEvent, ICardSide, ICardActionEvent, ICardAction } from '../../../shared/models';
import { ISendEvent } from '../../../shared/models/cards/ISendEvent';

@Component({
  selector: 'flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.css']
})
export class FlipCardComponent implements OnInit {

  @Input() cardSelector: string;
  @Input() width: number;
  @Input() height: number;

  selectedCard$: Observable<ICard>;

  cardFrontPortal: ComponentPortal<ICardComponent>;
  cardBackPortal: ComponentPortal<ICardComponent>;
  cardFrontToBackEvent: ReplaySubject<any> = new ReplaySubject<any>(1); //send data from a flip event to the back
  cardBackToFrontEvent: ReplaySubject<any> = new ReplaySubject<any>(1); //send data from a flip event to the front
  cardFlipEvent: ReplaySubject<IFlipEvent> = new ReplaySubject<IFlipEvent>(1); //event to listen for flip from components
  actionEvent: EventEmitter<ICardActionEvent> = new EventEmitter<ICardActionEvent>(); //event to send card level actions down to components
  cardSendEvent: ReplaySubject<ISendEvent> = new ReplaySubject<ISendEvent>(1); //event to listen for flip from components
  activeSide: ICardSide = 'front';
  widthStyle: SafeStyle;
  heightStyle: SafeStyle;
  defaultBackgroundColor: string = "rgba(255,255,255,0)";
  cardFrontData: ICardData = {
    side: 'front',
    incoming: this.cardBackToFrontEvent,
    flip: this.cardFlipEvent,
    action: this.actionEvent,
    backgroundColor: this.defaultBackgroundColor,
    send: this.cardSendEvent
  };
  cardBackData: ICardData = {
    side: 'back',
    incoming: this.cardFrontToBackEvent,
    flip: this.cardFlipEvent,
    action: this.actionEvent,
    backgroundColor: this.defaultBackgroundColor,
    send: this.cardSendEvent
  };
  dynamicHeader: string;

  constructor(private store$: Store<CardsStoreState.State>, private _injector: Injector, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.selectedCard$ = this.store$.select(CardsStoreSelectors.selectCardBySelector(this.cardSelector));

    this.selectedCard$.subscribe(result => {
      if (result) {

        if (this.width) {
          this.widthStyle = this._sanitizer.bypassSecurityTrustStyle(this.width + 'px');
        } else {
          this.widthStyle = this._sanitizer.bypassSecurityTrustStyle(result.width + 'px');
        }

        if (this.height) {
          this.heightStyle = this._sanitizer.bypassSecurityTrustStyle(this.height + 'px');
        } else {
          this.heightStyle = this._sanitizer.bypassSecurityTrustStyle(result.height + 'px');
        }

        if (result.cardFront) {
          const cardFrontInjector = this.createInjector(this.cardFrontData);
          this.cardFrontPortal = new ComponentPortal(result.cardFront, null, cardFrontInjector);
        }
        if (result.cardBack) {
          const cardBackInjector = this.createInjector(this.cardBackData);
          this.cardBackPortal = new ComponentPortal(result.cardBack, null, cardBackInjector);
        }
      }
    });

    //catch flip event and pass payload to other side, then flip card
    this.cardFlipEvent.subscribe((event: IFlipEvent) => {
      switch (event.side) {
        case 'front':
          this.cardFrontToBackEvent.next(event.payload);
          break;
        case 'back':
          this.cardBackToFrontEvent.next(event.payload);
          break;
      }
      this.dynamicHeader = event && event.payload && event.payload.header ?
        event.payload.header :
        null;
      this.flip();
    });

    this.cardSendEvent.subscribe((event: ISendEvent) => {
      switch (event.side) {
        case 'front':
          this.cardFrontToBackEvent.next(event.payload);
          break;
        case 'back':
          this.cardBackToFrontEvent.next(event.payload);
          break;
      }
    });
  }

  /**
   * Create an injector for passing into the component with the card injector token
   * @param dataToPass Whatever data is to be passed into the component
   */
  private createInjector(dataToPass:ICardData): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CARD_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  /**
   * Flip the card changing active side
   */
  flip() {
    //check opposite side has a component attached before flipping
    if (this.activeSide === 'front' && this.cardBackPortal == null)
      return;
    if (this.activeSide === 'back' && this.cardFrontPortal == null)
      return;

    this.activeSide = this.activeSide === 'front' ? 'back' : 'front';
  }

  /**
   * Send an action event down to components on both sides
   * @param action
   */
  emitAction(action: ICardAction) {
    this.actionEvent.emit({ activeSide: this.activeSide, actionName: action.actionName });
  }

  /**
   * Close card.
   */
  closeCard() {
    this.store$.dispatch(CardsStoreActions.CloseCardRequest({ selector: this.cardSelector }));
  }

  renderHeader(label: string) {
    return this._sanitizer.bypassSecurityTrustHtml(label);
  }
}
