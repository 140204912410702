import { Component, OnInit, Input, EventEmitter, Output  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { 
  LimitationDto, RiskDto 
} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-limitation-risk-display',
  templateUrl: './limitation-risk-display.component.html',
  styleUrls: ['./limitation-risk-display.component.css']
})
export class LimitationRiskDisplayComponent implements OnInit {
  @Input() isRisk:boolean = false;
  @Input() data: LimitationDto[] | RiskDto[] = [];
  @Input('enableEdits') enableEdits: boolean;
  @Output('remove') removeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output('edit') editEvent: EventEmitter<{ plan: LimitationDto | RiskDto, idx: number }> = new EventEmitter<{ plan: LimitationDto | RiskDto, idx: number }>();

  editModePlans = {};
  planEditForm:FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.planEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      clinicalTerms: [''],
      laymanTerms: ['']
    });
  }

  removeItem(evt) {
    this.removeEvent.next(evt);
  }

  toggleEditPlan(planId: number, isEditMode: boolean) {
    if (this.enableEdits){
      this.editModePlans[planId] = isEditMode;
      if(isEditMode){
        let patientPlan = this.data.find(plan => plan.id == planId);
        if(patientPlan){
          this.planEditForm.patchValue({
            name: patientPlan.name,
            clinicalTerms: patientPlan.clinicalTerms,
            laymanTerms: patientPlan.laymanTerms
          })
        }        
      }
    }    
  }

  savePlan(plan: LimitationDto | RiskDto, idx: number) {
    if (!this.enableEdits)
      return;

    let planEditFormValue = this.planEditForm.value;
    plan.name = planEditFormValue.name;
    plan.laymanTerms = planEditFormValue.laymanTerms;
    plan.clinicalTerms = planEditFormValue.clinicalTerms;
    this.editEvent.next({ plan: plan, idx: idx});
    this.toggleEditPlan(plan.id, false);
  }

  saveOnSubmit(event, plan: LimitationDto | RiskDto, idx: number){
    if(event.keyCode === 13){
      this.savePlan(plan, idx);
    }
  }

  

}
