import { createAction, props } from '@ngrx/store';
import { DiagnosisStoreEntity } from './state';

export const LoadRequest = createAction(
  '[Treatment] Diagnosis Load Request',
  props<{ id?: number }>()
);

export const LoadSuccess = createAction(
  '[Treatment] Diagnosis Load Success',
  props<{ diagnoses: DiagnosisStoreEntity[] | DiagnosisStoreEntity }>()
);

export const LoadFailure = createAction(
  '[Treatment] Diagnosis Load Failure',
  props<{ err: any}>()
);

export const LoadOnceRequest = createAction(
  '[Treatment] Diagnosis Load Once Request',
);

export const Remove = createAction(
  '[Treatment] Remove Entry',
  props<{ id: number }>()
);
