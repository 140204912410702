import { Action, createReducer, on } from '@ngrx/store';
import * as VoiceEventActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //SELECT
  on(VoiceEventActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedToExtension: null,
  })),
  on(VoiceEventActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedToExtension: action.activeVoice && action.activeVoice.toExtension,
  })),
  on(VoiceEventActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //DESELECT
  on(VoiceEventActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(VoiceEventActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedToExtension: null,
  })),
  on(VoiceEventActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(VoiceEventActions.LoadSuccess, (state, action) =>
    featureAdapter.upsertOne(action.voiceHubEvent, { ...state, isLoading: false })
  ),
  on(VoiceEventActions.RemoveSuccess, (state, action) => featureAdapter.removeOne(action.toExtension, { ...state }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
