//Lazyloading ts

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './shared/services/auth/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: './login/login.module#LoginModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
  { path: 'patient', loadChildren: './patient-portal/patient-portal.module#PatientPortalModule' },
  { path: 'Contracts', redirectTo: 'patient/contracts' },
  { path: 'error', loadChildren: './error/error.module#ErrorModule' },
  { path: '**', redirectTo: 'error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
// export const Approuting: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});
