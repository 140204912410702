import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { TenantKeyDto, ITenantKeyDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<TenantStoreEntity> = createEntityAdapter<TenantStoreEntity>({
  sortComparer: (a: TenantStoreEntity, b: TenantStoreEntity): number => a.name.localeCompare(b.name)
});

export interface State extends EntityState<TenantStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class TenantStoreEntity extends TenantKeyDto {
  constructor(data?: ITenantKeyDto) {
    super(data);
  }
}
