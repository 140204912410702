import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { DoctorTimeDto, IDoctorTimeDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<DoctorTimeStoreEntity> = createEntityAdapter<DoctorTimeStoreEntity>({
  selectId: DoctorTime => DoctorTime.id,
  sortComparer: (a: DoctorTimeStoreEntity, b: DoctorTimeStoreEntity): number => b.id - a.id
});

export interface State extends EntityState<DoctorTimeStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedDoctorTimeId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedDoctorTimeId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class DoctorTimeStoreEntity extends DoctorTimeDto {
  constructor(data?: IDoctorTimeDto) {
    super(data);
  }
}
