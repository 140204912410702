import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IImageDto, ImageDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<ModelStoreEntity> = createEntityAdapter<ModelStoreEntity>({
  selectId: model => model.id,
  sortComparer: (a: ModelStoreEntity, b: ModelStoreEntity): number => b.createdWhen.getTime() - a.createdWhen.getTime()
});

export interface State extends EntityState<ModelStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedModelId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedModelId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class ModelStoreEntity extends ImageDto {
  constructor(data?: IImageDto) {
    super(data);
  }
}
