import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ONDECK_FEATURE_KEY } from './featurekey';
import { featureAdapter, OnDeckStoreEntity, State } from './state';
import * as moment from 'moment';

export const selectOnDeckState: MemoizedSelector<object, State> = createFeatureSelector<State>(ONDECK_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectOnDeckState);
export const selectDeckIds = selectIds;
export const selectDeckTotal = selectTotal;
export const selectAllOnDeck = selectAll;
export const selectAllOnDeckEntities = selectEntities;

export const selectLocationsError: MemoizedSelector<object, any> = createSelector(selectOnDeckState, (state) => state.error);

export const selectLocationsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectOnDeckState, (state) => state.isLoading);
