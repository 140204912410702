import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, HubEventType, InsuranceCompanyDto, SettingsClient } from 'src/app/shared/services/api.service';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as InsuranceCompanyStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class InsuranceCompanyStoreEffects {
  constructor(private store$: Store<State>, private actions$: Actions, private _settingsClient: SettingsClient) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InsuranceCompanyStoreActions.LoadRequest),
    withLatestFrom(this.store$.select((state) => state.InsuranceCompanies)),
    switchMap(([action, state]) =>
      this._settingsClient
        .settings_GetInsuranceCompanies()
        .pipe(map((result) => InsuranceCompanyStoreActions.LoadSuccess({ insuranceCompanies: result })))
    )
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InsuranceCompanyStoreActions.LoadOneRequest),
    switchMap((action) =>
      this._settingsClient
        .settings_GetInsuranceCompany(action.id)
        .pipe(map((result) => InsuranceCompanyStoreActions.LoadOneSuccess({ insuranceCompany: result })))
    )
  );

  eventHubEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      filter((action) => action.event.eventArea == HubEventArea.InsuranceCompanyEvent && action.event.entityType == InsuranceCompanyDto.name),
      map((action) => {
        switch (action.event.eventType) {
          case HubEventType.Removed:
            //removed
            return InsuranceCompanyStoreActions.DeleteRequest({ id: action.event.entityId });
          case HubEventType.Modified:
          case HubEventType.Added:
            //updated and included in store
            return InsuranceCompanyStoreActions.LoadOneRequest({ id: action.event.entityId });
        }
      })
    )
  );
}
