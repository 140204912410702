import { initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import * as ContactStoreActions from './actions';
import { featureAdapter } from './state';

const reducer = createReducer(
  initialState,
  on(ContactStoreActions.SearchRequest, (state) => featureAdapter.removeAll({ ...state, isLoading: true, error: null })),
  on(ContactStoreActions.SearchSuccess, (state, action) => featureAdapter.upsertMany(action.contacts,{ ...state, isLoading: false })),
  on(ContactStoreActions.SearchFailure, (state, action) => ({ ...state, isLoading: false, error: action.err })),
  on(ContactStoreActions.LoadRequest, (state) => ({ ...state, contact: null, isLoading: true, error: null })),
  on(ContactStoreActions.LoadSuccess, (state, action) => ({ ...state, contact: action.contact, isLoading: false })),
  on(ContactStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.err })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
