import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CardsStoreSelectors } from './cards-store';
import { PatientsStoreSelectors } from './patient-store';
import { ProvidersStoreSelectors } from './provider-store';
import { LocationsStoreSelectors } from './location-store';

//All Errors
export const selectError: MemoizedSelector<object, string> = createSelector(
  CardsStoreSelectors.selectCardsError, PatientsStoreSelectors.selectPatientsError, ProvidersStoreSelectors.selectProvidersError, LocationsStoreSelectors.selectLocationsError,
  (cardsError: string, patientsError: string, providerError: any, locationError: any) => {
    return cardsError || patientsError || providerError || locationError;
  }
);

//All IsLoading
export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  CardsStoreSelectors.selectCardsIsLoading, PatientsStoreSelectors.selectPatientsIsLoading, ProvidersStoreSelectors.selectProvidersIsLoading, LocationsStoreSelectors.selectLocationsIsLoading,
  (cardsIsLoading: boolean, patientsIsLoading: boolean, providerLoading: boolean, locationLoading: boolean) => {
    return cardsIsLoading || patientsIsLoading || providerLoading || locationLoading;
  }
);
