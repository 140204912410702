import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';
import { ICard } from '../../shared/models/cards';
import { IOpenCard } from './IOpenCard';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectCardsState: MemoizedSelector<object, State> = createFeatureSelector<State>('Cards');

export const selectOpenCards: MemoizedSelector<object, IOpenCard[]> = createSelector(selectCardsState, (state) => [...state.openCards]);

export const selectAllCardsItems: (state: object) => ICard[] = featureAdapter.getSelectors(selectCardsState).selectAll;

export const selectCardBySelector = (selector: string) =>
  createSelector(selectAllCardsItems, (allCards: ICard[]) => {
    if (allCards) {
      return allCards.find((c) => c.selector === selector);
    } else {
      return null;
    }
  });

export const getCardOrderBySelector = (selector: string) =>
  createSelector(selectOpenCards, (cards) => cards.some((c) => c.selector == selector) && cards.find((c) => c.selector == selector).order);

export const selectCardsError: MemoizedSelector<object, any> = createSelector(selectCardsState, getError);

export const selectCardsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectCardsState, getIsLoading);
