import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { combineLatest, defer, from, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  PatientImagingStoreSelectors,
  PatientsStoreSelectors,
  PatientStoreEntity,
  PhotoGroupStoreActions,
  PhotoGroupStoreEntity,
  PhotoGroupStoreSelectors,
  PhotoTypeStoreActions,
  PhotoTypeStoreEntity,
  PhotoTypeStoreSelectors,
  RootStoreState,
  TwainStoreActions,
  TwainStoreSelectors
} from '../../../root-store';
import { catchError, map, mergeAll, take, takeUntil, toArray } from 'rxjs/operators';
import {
  FromTypeEnum,
  PatientClient,
  PatientTreatmentImageDto,
  PatientTreatmentImageGroupDto,
  StorageContentTypeEnum,
  StorageItemBaseDto,
  TwainClient,
  TwainDto
} from '../../../shared/services/api.service';
import { base64ToFile } from 'ngx-image-cropper';
import { DownloadService } from '../../../shared/services/download/download.service';
import { CarouselComponent } from 'angular-responsive-carousel';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AdvancedEditorComponent } from '../../../shared-module/advanced-editor/advanced-editor.component';
import { AdvancedEditorActions, AdvancedEditorData, AdvancedEditorTransform } from '../../../shared-module/advanced-editor/AdvancedEditorModels';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';

export interface PatientVisitTypeDto {
  patientTreatmentImageGroupId?: number;
  photoGroupId?: number;
  name?: string;
  isNew?: boolean;
  takenWhen?: Date;
  sortOrder?: number;
}

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UploadImageComponent implements OnInit, OnDestroy {
  @ViewChild('carouselHeader', { static: false }) carouselHeader: CarouselComponent;
  @ViewChild('carouselBody', { static: false }) carouselBody: CarouselComponent;

  patientVisitTypes: PatientVisitTypeDto[] = [];
  fromTypeEnum = FromTypeEnum;
  selectedFromType: FromTypeEnum = FromTypeEnum.LocalFile;
  twains$ = this._store$.select(TwainStoreSelectors.selectAllTwain);
  isReclassifyMode: boolean = false;
  reclassifiedPhotoTypes: PatientTreatmentImageDto[] = [];
  reclassifiedPhotoGroupid?: number;
  reclassifiedPatientTreatmentImageGroupId?: number;
  isWorking: boolean = false;
  isDownloadingTwain: boolean = false;
  isDownloadingPatientFile: boolean = false;
  hasImageSetToHeadings: boolean = false;
  uploadImageFormGroup: FormGroup;
  today: Date = new Date;
  validComboDrag: any;
  isWorking$ = this._store$.select(PatientImagingStoreSelectors.selectPatientImagingsIsLoading);
  photoTypes$: Observable<PhotoTypeStoreEntity[]> = this._store$
    .select(PhotoTypeStoreSelectors.selectAllPhotoTypes)
    .pipe(
      take(1),
      map(photoTypes => {
        let activePhotoTypes = _
          .chain(photoTypes)
          .filter('isActive')
          .orderBy(['sortOrder'], ['asc'])
          .value();

        this.imageHeadings = [];
        _.each(activePhotoTypes, activePhotoType => {
          let imageHeading: ImageHeading = {
            id: activePhotoType.id,
            defaultImage: "/assets/treatment-card/no-photo.png",
            defaultTakenImage: "/assets/treatment-card/no-photo-taken.png",
            image: null,
            originalImage: null,
            name: activePhotoType.name
          }
          if (activePhotoType.flipX || activePhotoType.flipY || activePhotoType.rotate) {
            let canvasRotation: number = Math.trunc(activePhotoType.rotate / 90);
            let rotate: number = canvasRotation ? activePhotoType.rotate % (canvasRotation * 90) : activePhotoType.rotate;
            imageHeading.originalImageTransform = {
              flipV: activePhotoType.flipX,
              flipH: activePhotoType.flipY,
              rotate: rotate,
              canvasRotation: canvasRotation,
              scale: 1
            };
            imageHeading.imageTransform = imageHeading.originalImageTransform;
          }

          this.imageHeadings.push(imageHeading);
        });
        this.reloadCarousel();
        return activePhotoTypes;
      }));
  patientAge: string;
  selectedImageIndex: number = null;
  deleteAllConfirmMessage: string = `<span style=\"color: #FFFFFF\">If you are sure, I will</span>
    <br>delete ALL images<br>
    <span style=\"color: #FFFFFF\">on the second row.</span>`;

  deleteConfirmMessage: string = `<span style=\"color: #FFFFFF\">If you are sure, I will</span>
    <br>delete this image<br>
    <span style=\"color: #FFFFFF\">on the second row.</span>`;

  images: FileUploadModel[] = [];
  dragImages: File[] = [];
  imageHeadings: ImageHeading[] = [];
  editedImageHeading: ImageHeading;
  visitTypes$: Observable<PhotoGroupStoreEntity[]> = this._store$.select(PhotoGroupStoreSelectors.selectAllPhotoGroups);

  patientTreatmentImageGroups: PatientTreatmentImageGroupDto[];
  existingPhotoTypes: { [key: number]: PatientTreatmentImageDto } = {};
  allowedType: string[] = [
    'image/tif',
    'image/tiff',
    'image/jpeg',
    'image/jpg',
    'image/png'
  ];

  private selectedPatientId: number
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  selectedPatient: PatientStoreEntity;

  @ViewChild('visitTypeDialog', { static: false }) visitTypeDialog: TemplateRef<any>;
  @ViewChild('existImageDialog', { static: false }) existImageDialog: TemplateRef<any>;
  visitTypeDialogRef: MatDialogRef<any>;
  selectedVisitType: PatientVisitTypeDto;
  hoverImageResp: any;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _dialogRef: MatDialogRef<UploadImageComponent>,
    private _formBuilder: FormBuilder,
    private _patientClient: PatientClient,
    @Inject(MAT_DIALOG_DATA) private _data: UploadImageModel,
    private _twainClient: TwainClient,
    private _downloadService: DownloadService,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    if (_data) {
      this.isReclassifyMode = _data.isReclassifyMode;
      this.reclassifiedPhotoGroupid = _data.photoGroupId;
      this.reclassifiedPatientTreatmentImageGroupId = _data.patientTreatmentImageGroupId;
    }
  }

  ngOnInit() {
    this._store$.dispatch(PhotoGroupStoreActions.LoadRequest({}));
    this.initializeData();
    this.initializeFormGroup();
    this._store$.dispatch(PhotoTypeStoreActions.LoadRequest({}));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.isReclassifyMode) {
        this.visitTypeDialogRef = this.matDialog.open(this.visitTypeDialog, {
          width: '320px',
          disableClose: true
        });

        this.visitTypeDialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result) {
            this.uploadImageFormGroup.patchValue({
              photoGroupId: this.selectedVisitType.photoGroupId,
              patientTreatmentImageGroupId: this.selectedVisitType.patientTreatmentImageGroupId
            })
            this.photoGroupChanged(this.selectedVisitType);
          } else {
            this._dialogRef.close();
          }
        })
      }

    }, 500);
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  initializeData(): void {
    this.isWorking = true;

    this._store$
      .select(PatientsStoreSelectors.getSelectedPatient)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((patient) => {
        this.selectedPatient = patient;
        this.selectedPatientId = patient.id;

        if (patient && patient.dob) {
          let duration = moment.duration(moment(this.today).diff(moment(patient.dob)));
          this.patientAge = `${duration.years()}y ${duration.months()}m`;
        }

        combineLatest([
          this._patientClient.patient_GetPatientTreatmentImageGroupsOptions(patient.id),
          this._patientClient.patient_GetPatientTreatmentImageGroups(patient.id)
        ])
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe(([options, patientTreatmentImageGroups]) => {
          this.patientTreatmentImageGroups = patientTreatmentImageGroups;

          let systemPhotoGroups: PatientVisitTypeDto[] = options.photoGroups
            .filter((x) => x.isActive)
            .map((photoGroup) => {
              return {
                photoGroupId: photoGroup.id,
                name: photoGroup.name,
                isNew: true,
                takenWhen: null,
                sortOrder: photoGroup.sortOrder
              };
          });

          let activePhotoGroupIds: number[] = systemPhotoGroups.map((x) => x.photoGroupId);

          let patientPhotoGroups: PatientVisitTypeDto[] = patientTreatmentImageGroups
            .filter((x) => activePhotoGroupIds.includes(x.photoGroupId))
            .map((patientTreatmentImageGroup) => {
              let photoGroup = options.photoGroups.find((x) => x.id == patientTreatmentImageGroup.photoGroupId);

              return {
                patientTreatmentImageGroupId: patientTreatmentImageGroup.id,
                photoGroupId: patientTreatmentImageGroup.photoGroupId,
                name: patientTreatmentImageGroup.photoGroupName,
                takenWhen: patientTreatmentImageGroup.takenWhen,
                sortOrder: photoGroup && photoGroup.sortOrder ? photoGroup.sortOrder : 0
              };
          });

          let usedPhotoGroupIds: number[] = patientPhotoGroups.map((x) => x.photoGroupId);

          this.patientVisitTypes = systemPhotoGroups
            .filter((x) => !usedPhotoGroupIds.includes(x.photoGroupId) || options.allowDuplicateImageGroups)
            .concat(patientPhotoGroups)
            .sort((a, b) => {
              if (a.sortOrder == b.sortOrder) {
                return a.takenWhen > b.takenWhen ? 1 : -1;
              }
              else {
                return a.sortOrder > b.sortOrder ? 1 : -1;
              }
            });

          if (this.isReclassifyMode) {
            this.selectedVisitType = {
              photoGroupId: this.reclassifiedPhotoGroupid,
              patientTreatmentImageGroupId: this.reclassifiedPatientTreatmentImageGroupId
            };

            this.photoGroupChanged(this.selectedVisitType);
          }
          this.isWorking = false;
        });
      });
  }

  photoGroupChanged(patientVisitType: PatientVisitTypeDto): void {
    this.existingPhotoTypes = {};
    let patientTreatmentImageGroup: PatientTreatmentImageGroupDto;
    if (!patientVisitType.isNew)
      patientTreatmentImageGroup = _.find(this.patientTreatmentImageGroups, { 'id': patientVisitType.patientTreatmentImageGroupId });

    if (patientTreatmentImageGroup) {
      this.existingPhotoTypes = _.keyBy(patientTreatmentImageGroup.patientTreatmentImages, 'photoTypeId');

      if (this.existingPhotoTypes && this.isReclassifyMode) {
        this.images = [];
        this.selectedImageIndex = 0;
        _.each(this.existingPhotoTypes, (e) => {
          this.images.push(null);
        });
      }

      this.dateValueChange(patientTreatmentImageGroup.takenWhen);
    }
    else {
      this.dateValueChange(new Date());
    }
  }

  initializeFormGroup(): void {
    this.uploadImageFormGroup = this._formBuilder.group({
      photoGroupId: [this.reclassifiedPhotoGroupid ? this.reclassifiedPhotoGroupid : '', Validators.required],
      patientTreatmentImageGroupId: [this.reclassifiedPatientTreatmentImageGroupId ? this.reclassifiedPatientTreatmentImageGroupId : ''],
      takenWhen: ['']
    });
  }

  cancelUpload(): void {
    this._dialogRef.close();
  }

  clearUploadImages(): void {
    this.images = [];
    this.selectedImageIndex = null;
  }

  doneSaving(): void {
    this.isWorking = false;
    this._dialogRef.close(true);

    this.imageHeadings.forEach((imageHeading: ImageHeading) => {
      if (imageHeading.image && imageHeading.image.data) {
        imageHeading.image = new File(
          [imageHeading.image.data],
          imageHeading.image.name,
          { type: imageHeading.image.type });
      }
      imageHeading.imageTransform = {
        flipH: false,
        flipV: false,
        rotate: 0,
        scale: 1
      };
    });
  }

  save(): void {
    if (!this.uploadImageFormGroup.valid) return;
    let imagings = _.filter(this.imageHeadings, 'image');
    if (!imagings || imagings.length < 1) return;

    this.isWorking = true;

    let uploadImageValues: any = this.uploadImageFormGroup.value;
    let patientTreatmentImageGroup = _.find(this.patientTreatmentImageGroups, (p: PatientTreatmentImageGroupDto) => {
      return p.id == uploadImageValues.patientTreatmentImageGroupId;
    });

    this.generateObservableTasks(imagings, patientTreatmentImageGroup, uploadImageValues).then((promises: any) => {
      const observables = promises.map(a => defer(() => a));
      from(observables)
        .pipe(
          mergeAll(1),
          takeUntil(this._destroy$),
          toArray()
        )
        .subscribe((res) => {
          if (patientTreatmentImageGroup &&
            patientTreatmentImageGroup.takenWhen &&
            uploadImageValues.takenWhen &&
            moment(patientTreatmentImageGroup.takenWhen).format("MM/DD/YYYY") != uploadImageValues.takenWhen
          ) {
            this._patientClient
              .patient_EditPatientTreatmentImageGroupTakenWhen(
                this.selectedPatientId,
                patientTreatmentImageGroup.id,
                new Date(uploadImageValues.takenWhen))
              .pipe(
                take(1),
                takeUntil(this._destroy$)
              )
              .subscribe((result: PatientTreatmentImageGroupDto) => {
                this.doneSaving();
              }, err => this.isWorking = false);
          }
          else {
            this.doneSaving();
          }
        });
    }, (err) => {
      console.log('upload error:', err);
      this.isWorking = false;
    });
  }

  generateObservableTasks(
    imagings: ImageHeading[],
    patientTreatmentImageGroup: PatientTreatmentImageGroupDto,
    uploadImageValues: any
  ): Promise<any[]> {
    return new Promise(resolve => {
      let taskRequests = [];
      if (!!patientTreatmentImageGroup) {
        if (this.isReclassifyMode) {
          _.each(this.reclassifiedPhotoTypes, (reclassifiedPhotoType: PatientTreatmentImageDto) => {
            let image = _.find(imagings, { 'id': reclassifiedPhotoType.photoTypeId });
            if (!image) {
              let deleteRequest = this._patientClient.patient_DeletePatientTreatmentImage(
                this.selectedPatientId,
                patientTreatmentImageGroup.id,
                reclassifiedPhotoType.id
              );
              taskRequests.push(deleteRequest);
            }
          });
        }
        _.each(imagings, (imaging: ImageHeading) => {
          let patientTreatmentImage = _.find(patientTreatmentImageGroup.patientTreatmentImages, (p: PatientTreatmentImageDto) => {
            return p.photoTypeId == imaging.id;
          });

          if (patientTreatmentImage) {
            let uploadRequest = this._patientClient.patient_PutPatientTreatmentImageUploadImage(
              this.selectedPatientId,
              patientTreatmentImageGroup.id,
              patientTreatmentImage.id,
              StorageContentTypeEnum.Imaging,
              imaging.image,
              patientTreatmentImage.photoTypeId,
              uploadImageValues.photoGroupId,
              imaging.twainId,
              imaging.fromType ? imaging.fromType : FromTypeEnum.LocalFile
            );
            taskRequests.push(uploadRequest);
          }
          else {
            let uploadRequest = this._patientClient.patient_PostPatientTreatmentImageUploadImage(
              this.selectedPatientId,
              patientTreatmentImageGroup.id,
              StorageContentTypeEnum.Imaging,
              imaging.image,
              imaging.id,
              uploadImageValues.photoGroupId,
              imaging.twainId,
              imaging.fromType ? imaging.fromType : FromTypeEnum.LocalFile
            );
            taskRequests.push(uploadRequest);
          }
        });
        resolve(taskRequests);
      }
      else {
        let patientTreatmentImageGroup: PatientTreatmentImageGroupDto = new PatientTreatmentImageGroupDto({
          id: 0,
          patientId: this.selectedPatientId,
          photoGroupId: uploadImageValues.photoGroupId,
          takenWhen: new Date(uploadImageValues.takenWhen)
        });

        this._patientClient.patient_PostPatientTreatmentImageGroup(
          this.selectedPatientId,
          patientTreatmentImageGroup
        ).subscribe((res: PatientTreatmentImageGroupDto) => {
          _.each(imagings, (imaging: any) => {
            this.patientTreatmentImageGroups.push(res);

            let uploadRequest = this._patientClient.patient_PostPatientTreatmentImageUploadImage(
              this.selectedPatientId,
              res.id,
              StorageContentTypeEnum.Imaging,
              imaging.image,
              imaging.id,
              uploadImageValues.photoGroupId,
              imaging.twainId,
              imaging.fromType ? imaging.fromType : FromTypeEnum.LocalFile
            );
            taskRequests.push(uploadRequest);
          });
          resolve(taskRequests);
        }, (err) => {
          console.log('upload error:', err);
          this.isWorking = false;
        });
      }
    });
  }

  imagesChange(): void {
    this.setImagePreview();
    this.reloadCarousel();
  }

  setImagePreview(): void {
    this.images.forEach(async (image: FileUploadModel) => {
      if (image && !image.previewImage) {
        image.previewImage = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(image)));
      }
    });
  }

  removeImage(index: number): void {
    this.images.splice(index, 1);
    this.reloadCarousel();
  }

  setImageHeading(index: number): void {
    if (this.selectedImageIndex != null && this.images[this.selectedImageIndex]) {
      this.imageHeadings[index].image = this.images[this.selectedImageIndex];
      this.imageHeadings[index].originalImage = this.images[this.selectedImageIndex];
      this.imageHeadings[index].twainId = this.images[this.selectedImageIndex].twainId;
      this.imageHeadings[index].fromType = this.images[this.selectedImageIndex].fromType;

      if (!this.imageHeadings[index].imageTransform) {
        this.imageHeadings[index].image.data = new Blob([this.images[this.selectedImageIndex]], { type: this.images[this.selectedImageIndex].type });
      }

      this.images[this.selectedImageIndex] = null;

      let nextImageIndex: number = _.findIndex(this.images, image => {
        return image != null;
      }, this.selectedImageIndex);

      if (nextImageIndex < 0)
        nextImageIndex = _.findIndex(this.images, image => {
          return image != null;
        });

      this.selectedImageIndex = nextImageIndex > -1 ?
        nextImageIndex :
        null;
      this.reloadCarousel();
    }
  }

  moveToImages(index: number): void {
    if (!this.editedImageHeading && this.imageHeadings[index].image) {
      let firstOpenSpaceIndex: number = _.findIndex(this.images, image => {
        return image == null;
      });

      let moveToImageFile = this.imageHeadings[index].image;
      moveToImageFile.twainId = this.imageHeadings[index].twainId;
      moveToImageFile.fromType = this.imageHeadings[index].fromType;

      if (firstOpenSpaceIndex === -1) {
        if (this.images == null)
          this.images = [];

        this.images.push(moveToImageFile);
        this.selectedImageIndex = this.images.length - 1;
      }
      else {
        this.images[firstOpenSpaceIndex] = moveToImageFile;
        this.selectedImageIndex = firstOpenSpaceIndex;
      }

      this.imageHeadings[index].image = null;
      this.imageHeadings[index].originalImage = null;
      this.imageHeadings[index].previewImage = null;
      this.imageHeadings[index].transformedBase64Image = null;
      this.imageHeadings[index].twainId = null;
      this.imageHeadings[index].fromType = null;
      this.imageHeadings[index].imageTransform = this.imageHeadings[index].originalImageTransform;

      this.setImagePreview();
      this.reloadCarousel();
    }
  }

  imageCropped(imageHeading: ImageHeading, base64: string): void {
    imageHeading.transformedBase64Image = base64;
    imageHeading.image.data = base64ToFile(base64);
    imageHeading.previewImage = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageHeading.image.data)));
    imageHeading.image.fileName = imageHeading.image.name;
  }

  moveExistingToImages(id: number, index: number): void {
    if (this.existingPhotoTypes[id] && !this.isWorking) {
      this.isWorking = true;
      this.urlToFile(this.existingPhotoTypes[id].name, this.existingPhotoTypes[id].locationUrl)
        .then(result => {
          this.imageHeadings[index].image = result;
          this.moveToImages(index)
          this.reclassifiedPhotoTypes.push(this.existingPhotoTypes[id]);
          this.existingPhotoTypes[id] = null;
          this.isWorking = false;
        });
    }
  }

  async urlToFile(name: string, imageUrl: string): Promise<File> {
    let response = await fetch(imageUrl);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    return new File([data], "test.jpg", metadata);
  }

  setAllImageHeading(): void {
    if (this.imageHeadings.length > 0 && this.images.length > 0) {
      _.forEach(this.images, (image, i) => {
        if (this.imageHeadings[i] && this.imageHeadings[i].image == null && image != null && !this.existingPhotoTypes[this.imageHeadings[i].id]) {
          this.imageHeadings[i].image = image;
          this.images[i] = null;
        }
      });

      let firstImageIndex: number = _.findIndex(this.images, image => {
        return image != null;
      });

      this.selectedImageIndex = firstImageIndex > 0 ?
        firstImageIndex :
        null;
      this.reloadCarousel();
    }
  }

  private reloadCarousel(): void {
    this.checkImageHeading();

    if (this.carouselHeader)
      this.carouselHeader.onDomChanges();

    if (this.carouselBody)
      this.carouselBody.onDomChanges();
  }

  private checkImageHeading(): void {
    let hasImageIndex = _.findIndex(this.imageHeadings, (imageHeading) => {
      return imageHeading.image;
    });

    this.hasImageSetToHeadings = hasImageIndex > -1;
  }

  pickTwain(twain: TwainDto): void {
    if (this.isDownloadingTwain || !twain) return;
    if (_.findIndex(this.images, { 'twainId': twain.id }) > -1) {
      this._snackBar.open("Twain already selected.", "Ok", {
        duration: 3000,
      });
      return;
    };

    this.isDownloadingTwain = true;
    this._downloadService.blob(twain.locationUrl, twain.name)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        catchError((error) => {
          this._snackBar.open("Invalid Image.", "Ok", {
            duration: 3000,
          });
          this.isDownloadingTwain = false;
          return null;
        })
      )
      .subscribe((blob: Blob) => {
        let file: FileUploadModel = new File(
          [blob],
          twain.name,
          { type: blob.type });

        file.fromType = FromTypeEnum.Twain;
        file.twainId = twain.id;

        this.images.push(file);
        this.setImagePreview();
        this.reloadCarousel();
        this.isDownloadingTwain = false;
      })
  }

  loadTwain(): void {
    this._store$.dispatch(TwainStoreActions.LoadRequest({}));
  }

  patientFileSelected(patientFile: StorageItemBaseDto): void {
    if (this.isDownloadingPatientFile) return;
    if (!patientFile || !patientFile.locationContentType) {
      this._snackBar.open("Invalid Image.", "Ok", {
        duration: 3000,
      });
      return;
    }

    if (!_.includes(this.allowedType, patientFile.locationContentType)) {
      this._snackBar.open("Invalid Image.", "Ok", {
        duration: 3000,
      });
      return;
    }

    this.isDownloadingPatientFile = true;
    this._downloadService.blob(patientFile.locationUrl, patientFile.name)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        catchError((error) => {
          this._snackBar.open("Invalid Image.", "Ok", {
            duration: 3000,
          });
          this.isDownloadingPatientFile = false;
          return null;
        })
      )
      .subscribe((blob: Blob) => {
        let file: FileUploadModel = new File(
          [blob],
          patientFile.name,
          { type: blob.type });

        file.fromType = FromTypeEnum.PatientFile;

        this.images.push(file);
        this.setImagePreview();
        this.reloadCarousel();
        this.isDownloadingPatientFile = false;
      })
  }

  async pasteFromClipboard(): Promise<void> {
    let anyNavigator: any = window.navigator;
    const clipboardContents = await anyNavigator.clipboard.read();
    for (const item of clipboardContents) {
      let imageType: string;
      item.types.some(type => {
        if (type.startsWith('image/')) {
          imageType = type
        }
      })

      if (imageType) {
        const blob = await item.getType(imageType);
        let file: FileUploadModel = new File(
          [blob],
          'clipboardImage',
          { type: blob.type });

        file.fromType = FromTypeEnum.LocalFile;

        this.images.push(file);
        this.setImagePreview();
        this.reloadCarousel();
      }
      else {
        this._snackBar.open("Latest clipboard data is not an image.", "Ok", {
          duration: 3000,
        });
      }
    }
  }

  showExistingImageWarning(index): void {
    let existingImageWarningDialog = this.matDialog.open(this.existImageDialog, { width: '470px' });
    existingImageWarningDialog.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.setImageHeading(index);
      }
    })
  }

  editImageHeading(imageHeading: ImageHeading): void {
    this.editedImageHeading = imageHeading;
    if (!this.editedImageHeading.imageTransform)
      this.editedImageHeading.imageTransform = {};

    let advancedEditorData: AdvancedEditorData = {
      image: this.editedImageHeading.image,
      originalImage: this.editedImageHeading.originalImage,
      imageTransform: this.editedImageHeading.imageTransform,
      originalImageTransform: this.editedImageHeading.originalImageTransform,
      enableReloadOriginal: true
    }

    const dialogRef = this.matDialog.open(
      AdvancedEditorComponent,
      {
        data: advancedEditorData,
        disableClose: true,
        position: {right: '0px'}
      }
    );

    dialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        if (result && result.action) {
          switch (result.action) {
            case AdvancedEditorActions.Save: {
              this.editedImageHeading.imageTransform = result.imageTransform;
              this.imageCropped(this.editedImageHeading, result.base64);
              break;
            }
            case AdvancedEditorActions.Cancel: {
              this.editedImageHeading.imageTransform = this.editedImageHeading.originalImageTransform;
              this.editedImageHeading.image = this.editedImageHeading.originalImage;
              break;
            }
          }
        }
        this.editedImageHeading = null;
      });

    dialogRef.componentInstance.editedImage
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((result: string) => {
        this.imageCropped(this.editedImageHeading, result);
      });
  }

  dateMask() {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
    return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
  }

  dateValueChange(value) {
    this.uploadImageFormGroup.patchValue({
      takenWhen: moment(value).format("MM/DD/YYYY")
    })
  }

  onPatientFileHover(resp){
    this.hoverImageResp = resp;    
  }
}

export interface ImageHeading {
  id: number,
  defaultImage: string,
  defaultTakenImage: string,
  image: any,
  originalImage: any,
  name: string,
  imageTransform?: AdvancedEditorTransform,
  originalImageTransform?: AdvancedEditorTransform,
  transformedBase64Image?: string,
  fromType?: FromTypeEnum,
  twainId?: number,
  previewImage?: SafeUrl,
  isShowEdit?: boolean
}

export interface UploadImageModel {
  isReclassifyMode: boolean,
  photoGroupId?: number,
  patientTreatmentImageGroupId?: number
}

export interface FileUploadModel extends File {
  fromType?: FromTypeEnum,
  twainId?: number,
  previewImage?: SafeUrl
}
