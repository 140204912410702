import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { ISettingsOptionsDto, SettingsOptionsDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<SettingsStoreEntity> = createEntityAdapter<SettingsStoreEntity>({
});

export interface State extends EntityState<SettingsStoreEntity> {
  settings?: SettingsStoreEntity,
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  settings: null,
  isLoading: false,
  error: null
});

export class SettingsStoreEntity extends SettingsOptionsDto {
  constructor(data?: ISettingsOptionsDto) {
    super(data);
  }
}
