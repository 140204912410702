import { Component, OnInit, Inject } from '@angular/core';
import { CARD_DATA, ICardActionEvent, ICardData, ICardSide } from 'src/app/shared/models';
import { combineLatest, Observable, Subject } from 'rxjs';
import { PatientsStoreSelectors, PatientStoreEntity, RootStoreState, LocationsStoreSelectors, PatientReferralMessageStoreSelectors, PatientReferralMessageStoreActions, PatientReferralMessageStoreEntity } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { faEnvelopeOpenText, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { MessageService, MessageServiceEventEnum } from 'src/app/core/message.service';

@Component({
  selector: 'app-refer-out-front',
  templateUrl: './refer-out-front.component.html',
  styleUrls: ['./refer-out-front.component.scss']
})
export class ReferOutFrontComponent implements OnInit {
  _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  faEnvelopeOpenText = faEnvelopeOpenText;
  faPaperclip = faPaperclip;
  referralMessages$ = this._store$.select(PatientReferralMessageStoreSelectors.selectAllPatientReferralMessages)
    .pipe(map((messages: PatientReferralMessageStoreEntity[]) => {
      return _.orderBy(messages, (m => m.delaySendDate), 'desc');
  }));;
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  isWorking$ = this._store$
    .select(PatientReferralMessageStoreSelectors.selectPatientReferralMessageIsLoading);
  isLoading:boolean = false;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  activeSide: ICardSide = 'front';

  constructor(
    @Inject(CARD_DATA) private data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private _sanitizer: DomSanitizer,
    private messageService: MessageService
  ) {
    this.data.action
      .pipe(takeUntil(this._destroy$))
      .subscribe((action) => {
        this.activeSide = action.activeSide;
      });
  }

  ngOnInit() {
    this._store$.dispatch(PatientReferralMessageStoreActions.LoadPatientReferralMessagesRequest());

    this.messageService.getMessage()
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((message) => {
        if (!message) return;
        let payload: any;
        if (message.event == 'flipcard' && message.data.event == 'flipback') {
          payload = message.data.emailData;
        }
        else if (message.event == MessageServiceEventEnum.attachImage) {
          payload = {
            event: MessageServiceEventEnum.attachImage,
            image: message.data.image
          }
        }

        if (this.activeSide === this.data.side)
          this.data.flip.next({ payload: payload, side: this.data.side });
        else
          this.data.send.next({ payload: payload, side: this.data.side });
      });

    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe(resp => {
      this.activeSide = 'front';
    })
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  addNewReferOut(){
    this._selectedPatient$
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((patient) => {
        this.data.flip.next({ payload: null, side: this.data.side });
    });
  }

  seeFull(referralMessage){
    referralMessage.seeFull = referralMessage.seeFull ? false : true;
  }

  sanitizeHtml(htmlBody: string): any {
    return this._sanitizer.bypassSecurityTrustHtml(htmlBody);
  }

  

}
