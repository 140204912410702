import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as PatientStatusGroupStoreActions from './actions';
import * as PatientStatusGroupStoreSelectors from './selectors';
import {  SettingsClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class PatientStatusGroupStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _settingsClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientStatusGroupStoreActions.LoadRequest),
    switchMap(() =>
      this._settingsClient
        .settings_GetPatientStatusGroups(true)
        .pipe(
          map((result) => PatientStatusGroupStoreActions.LoadSuccess({ patientStatusGroups: result })),
          catchError((err: HttpErrorResponse) => of(PatientStatusGroupStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientStatusGroupStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(
          PatientStatusGroupStoreSelectors
            .selectPatientStatusGroupById(action.id))
        .pipe(map((result) => PatientStatusGroupStoreActions.SelectSuccess({ patientStatusGroup: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientStatusGroupStoreActions.DeselectRequest),
    switchMap(() => {
      return of(PatientStatusGroupStoreActions.DeselectSuccess());
    })
  );
}
