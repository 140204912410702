import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payschedule',
  templateUrl: './payschedule.component.html',
  styleUrls: ['./payschedule.component.css']
})
export class PayscheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
