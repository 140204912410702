import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgImageFullscreenViewModule } from "ng-image-fullscreen-view";
import { FullScreenViewerComponent } from "./full-screen-viewer.component";

@NgModule({
  declarations: [
    FullScreenViewerComponent
  ],
  imports: [
    CommonModule,
    NgImageFullscreenViewModule
  ],
  exports: [
    FullScreenViewerComponent
  ],
})
export class FullScreenViewerModule { }
