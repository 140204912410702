import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, filter, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { 
  PatientsStoreSelectors, 
  PatientStoreEntity, 
  RootStoreState, 
  LocationsStoreSelectors,
} from 'src/app/root-store';

import {
  PatientClient,
  RelationshipDto,
  SettingsClient,
  MarketingVendorFilterEnum,
  MarketingAssetDto,
  CreateMarketingOrderFromPackageDto,
  MarketingClient,
  MarketingPackageDto,
  MarketingOrderDto,
  UpdateMarketingOrderDto,
  MarketingOrderAddressDto
} from 'src/app/shared/services/api.service';

import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { NotificationSettingsComponent } from 'src/app/schedule/notification-settings/notification-settings.component';

@Component({
  selector: 'app-gifts-back',
  templateUrl: './gifts-back.component.html',
  styleUrls: ['./gifts-back.component.css']
})
export class GiftsBackComponent implements OnInit {
  relationShips: RelationshipDto[] = [];
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  giftSelectionForm: FormGroup;
  patientId: any;
  marketingGifts: MarketingAssetDto[] = [];
  activePackages:MarketingPackageDto[]=[];
  isAddressEdit:boolean = false;
  isWorking:boolean = false;
  editableGift:MarketingOrderDto;
  statemask: any = [/[a-zA-Z]/, /[a-zA-Z]/];
  zipcodeMask: any = [/\d/, /\d/, /\d/, /\d/, /\d/];

  @ViewChild('deleteTemplate', { static: true }) deleteTemplate: TemplateRef<any>;
  @ViewChild('notificationSettingsDialog', { static: true }) notificationSettingsDialog: TemplateRef<any>;
  @ViewChild('notificationSettings', { static: false }) notificationSettings: NotificationSettingsComponent;
  notificationDialogRef:MatDialogRef<any>
  showNotification:boolean = false;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private patientClient:PatientClient,
    private formBuilder: FormBuilder,
    private settingsClient:SettingsClient,
    private marketingClient:MarketingClient,
    private _snackbar: MatSnackBar,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.giftSelectionForm = this.formBuilder.group({
			relationship: ['', Validators.required],
			package: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required]
		});

    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe(resp => {

      this.giftSelectionForm.reset();
      this.isAddressEdit = false;
      this.editableGift = null;
      this.patientId = resp.patientId;

      if(resp.gift){
        this.editableGift = resp.gift;
      }
      
      this.patientClient.patient_GetRelationships(resp.patientId).subscribe(relations => {
        this.relationShips = relations;

        if(this.editableGift){
          let relation = this.relationShips.find(_relation => _relation.contactId == this.editableGift.contact.id)
          if(relation){
            this.giftSelectionForm.patchValue({
              relationship: relation
            })

            this.giftSelectionForm.patchValue({
              addressLine1: this.editableGift.deliveryAddress.addressLine1 ? this.editableGift.deliveryAddress.addressLine1 : '',
              addressLine2: this.editableGift.deliveryAddress.addressLine2 ? this.editableGift.deliveryAddress.addressLine2 : '',
              city:  this.editableGift.deliveryAddress.city ? this.editableGift.deliveryAddress.city : '',
              state: this.editableGift.deliveryAddress.state ? this.editableGift.deliveryAddress.state : '',
              zip: this.editableGift.deliveryAddress.zip ? this.editableGift.deliveryAddress.zip : ''
            })
          }
        }

      },
      err => {
        this.openSnackBar(err.message, "OK");
      })

      this.getGifts();
      this.getMarketingPackages();
      
    })
    
  }

  flipToBack(){
    this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
  }

  getRelation() {
    let _selectedRelation = this.giftSelectionForm.value.relationship;
    if (this.relationShips && _selectedRelation) {
      let relation = this.relationShips.find(relation => relation.id == _selectedRelation.id);
      if (relation) {
        return relation;
      }
    }
    return null
  }

  getGifts(){
    this.settingsClient.settings_GetMarketingGifts(MarketingVendorFilterEnum.All).subscribe(resp => {
      this.marketingGifts = resp.filter(gift => gift.isActive);
    },
    err => {
      this.openSnackBar(err.message, "OK");
    })
  }

  getMarketingPackages(){
    this.marketingClient.marketing_GetMarketingPackages().subscribe(resp => {
      this.activePackages = resp;

      if(this.editableGift){
        let marketingPackage = this.activePackages.find(activePackage => activePackage.id == this.editableGift.marketingPackage.id);
        if(marketingPackage){
          this.giftSelectionForm.patchValue({
            package: marketingPackage
          })
        }
      }
    },
    err => {
      this.openSnackBar(err.message, "OK");
    })
  }

  getPackageByName(){
    let _selectedPackage = this.giftSelectionForm.value.package;
    if(this.activePackages && _selectedPackage){

      let activePackage = this.activePackages.find(gift => gift.id == _selectedPackage.id);
      if(activePackage){
        return activePackage
      }
    }
    return null
  }

  selectRelation(evt){
    let relation = evt.value;
    this.giftSelectionForm.patchValue({
      addressLine1: relation.primaryAddress.addressLine1 ? relation.primaryAddress.addressLine1 : '',
      addressLine2: relation.primaryAddress.addressLine2 ? relation.primaryAddress.addressLine2 : '',
      city:  relation.primaryAddress.city ? relation.primaryAddress.city : '',
      state: relation.primaryAddress.state ? relation.primaryAddress.state : '',
      zip: relation.primaryAddress.zip ? relation.primaryAddress.zip : ''
    })
  }

  toggleAddressEdit(){
    this.isAddressEdit = this.isAddressEdit ? false : true;
  }

  getGiftById(){
    let packageItem = this.giftSelectionForm.value.package;

    let marketingGift = this.marketingGifts.find(gift => gift.id == packageItem.marketingGiftId);
    if(marketingGift){
      return marketingGift;
    }

    return null;
  }

  submitGift(){
    if(this.giftSelectionForm.valid){

      let formValue = this.giftSelectionForm.value;

      this.isWorking = true;

      if(this.editableGift){

        let giftToSend = new UpdateMarketingOrderDto();
        giftToSend.patientId = this.patientId;
        
        let deliveryAddress: MarketingOrderAddressDto = new MarketingOrderAddressDto();
        deliveryAddress.addressLine1 = formValue.addressLine1;
        deliveryAddress.addressLine2 = formValue.addressLine2;
        deliveryAddress.city = formValue.city;
        deliveryAddress.state = formValue.state;
        deliveryAddress.zip = formValue.zip;
        giftToSend.deliveryAddress = deliveryAddress;
        giftToSend.packageId = formValue.package.id;
        giftToSend.eTag = this.editableGift.eTag

        this.patientClient.patient_PutGift(this.patientId, this.editableGift.id, giftToSend, null, this.editableGift.eTag).subscribe(resp => {
          this.openSnackBar("Successfully updated!", 'OK');
          this.patientClient.patient_PostGiftApprove(this.patientId, this.editableGift.id).subscribe(resp => {
            this.isWorking = false;
            this.openSnackBar("Approved!", 'OK');
            this.flipToBack();
          },
          err => {
            this.isWorking = false;
            this.openSnackBar(err.message, 'OK');
          })
        },
        err => {
          this.isWorking = false;
          this.openSnackBar(err.message, 'OK');
        })
      } else {
        let giftToSend = new CreateMarketingOrderFromPackageDto();
        giftToSend.patientId = this.patientId;
        giftToSend.contactId = formValue.relationship.contactId;
        giftToSend.packageId = formValue.package.id;

        this.patientClient.patient_PostGift(this.patientId, giftToSend).subscribe(resp => {
          this.isWorking = false;
          this.openSnackBar("Successfully added!", 'OK');
          this.flipToBack();
        },
        err => {
          this.isWorking = false;
          this.openSnackBar(err.message, 'OK');
        })
      }

      
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  cancelGift() {
		this.matDialog.open(this.deleteTemplate).afterClosed().subscribe((result) => {
			if (result) {
				this.confirmDelete();
			}
		});
	}

  confirmDelete(){
    this.isWorking = true;
    this.patientClient.patient_PostGiftCancel(this.patientId, this.editableGift.id).subscribe(resp => {
      this.isWorking = false;
      this.openSnackBar("Successfully canceled!", 'OK');
      this.flipToBack();
    },
    err => {
      this.isWorking = false;
      this.openSnackBar(err.message, 'OK');
    })
  }

  openNotificationModal(){
    this.notificationDialogRef = this.matDialog.open(this.notificationSettingsDialog, {width: '700px', height: '440px'});
    this.notificationDialogRef.afterOpened().subscribe(resp => {
      this.showNotification = true;
    });

    this.notificationDialogRef.afterClosed().subscribe(resp => {
      this.showNotification = false;
    })
  }
  
}
