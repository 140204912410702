import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter } from "./state";
import * as ProvidersStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(
    ProvidersStoreActions.LoadRequest,
    (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize }),
  ),
  on(ProvidersStoreActions.LoadSuccess, (state, action) => {
    featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.providers, { ...state, isLoading: false, error: null })
  }),
  on(ProvidersStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //LOAD ONE
  on(ProvidersStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(ProvidersStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.provider, { ...state, isLoading: false, error: null })
  )),
  on(ProvidersStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //ADD
  on(ProvidersStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(ProvidersStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.provider, { ...state, isLoading: false, error: null })
  )),
  on(ProvidersStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //DELETE
  on(ProvidersStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(ProvidersStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  )),
  on(ProvidersStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //UTILITY
  on(ProvidersStoreActions.Unload, (state,action) => featureAdapter.removeAll({...state}))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
