export enum InsuranceClaimTypeEnum {
    None = 0,
    AssignBenefits = 1 << 0,
    AutoAccident = 1 << 1,
    IllnesssOrInjury = 1 << 2,
    JobInjury = 1 << 3,
    PreEstimate = 1 << 4,
    Primary = 1 << 5,
    ReleaseInfo = 1 << 6,
    TreatmentForOrtho = 1 << 7,
    SignatureOnFile = 1 << 8
}
