import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, HubEventType, TaskDto, UserClient } from 'src/app/shared/services/api.service';
import { UserClientService } from '../../shared/services/client/user-client.service';
import * as AuthStoreSelectors from '../auth-store/selectors';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as UserTaskStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class UserTaskStoreEffects {
  private _selectedUserId = this.store$.select(AuthStoreSelectors.selectUserId);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _userClient: UserClient,
    private _userClientService: UserClientService) { }

  @Effect()
  userTaskSignalRActionsEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedUserId),
    filter(
      ([action, userId]) =>
        userId &&
        action.event.eventArea == HubEventArea.UserTaskEvent &&
        action.event.userId.toLowerCase() == userId.toLowerCase()
    ),
    map(([action, userId]) =>
      UserTaskStoreActions.LoadUserTaskRequest()
    ),
  );

  @Effect()
  loadUserTaskRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(UserTaskStoreActions.LoadUserTaskRequest),
    withLatestFrom(this._selectedUserId),
    switchMap(([action, userId]) =>
      this._userClientService.fetchAll(
        this._userClient.user_GetTasks,
        [
          { value: userId },
          { value: undefined },
          { value: true },
          { value: false },
          { value: 20 },
          { isPage: true },
        ],
        TaskDto
      ).pipe(
        map((result) => UserTaskStoreActions.LoadUserTaskSuccess({ tasks: result })),
        catchError((error) => of(UserTaskStoreActions.LoadUserTaskFailure({ error })))
      )
    )
  );
}
