import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ProfessionalAddComponent } from 'src/app/dialogs/professional-add/professional-add.component';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-patient-overview-back',
  templateUrl: './patient-overview-back.component.html',
  styleUrls: ['./patient-overview-back.component.css'],
})
export class PatientOverviewBackComponent implements OnInit, OnDestroy {
  /** Current editor which determines which backside component is displayed */
  editor: string;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  editorPayload: (editor: string) => Observable<any> = (editor) => this.data.incoming
    .pipe(
      takeUntil(this._destroy$),
      filter((payload) => payload.editor === editor)
    );

  professionalDiaglogRef:MatDialogRef<ProfessionalAddComponent>;

  faUndo = faUndo;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.data.backgroundColor = '#eaf6fa';
    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe((payload: { editor: string }) => (this.editor = payload.editor));
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  /** Flips card, returning to the front and sets editor to null (after a delay to prevent hiding while visible) */
  back() {
    setTimeout(() => (this.editor = null), 500);
    this.data.flip.next(<IFlipEvent>{ side: this.data.side });
  }

  addProfessional(){
    this.professionalDiaglogRef = this.matDialog.open(ProfessionalAddComponent, {'height': '100%', 'maxHeight': '750px', 'width': '500px'})
  }
}
