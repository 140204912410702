import { Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, ActionsSubject } from '@ngrx/store';
import * as moment from 'moment';
import { merge, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  PatientTreatmentPlanStoreEntity,
  PatientTreatmentStoreActions,
  PatientTreatmentStoreEffectsService,
  PatientTreatmentStoreSelectors,
  RootStoreState,
  StepGroupStoreSelectors,
  PatientsStoreSelectors
  
} from 'src/app/root-store';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { 
  StepDto, 
  StepGroupDto, 
  StepWithCountDto, 
  LocationClient, 
  StepStartDto,
  TreatmentDto,
  SettingsClient
} from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material';
import { ofType } from '@ngrx/effects';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as _ from 'lodash';

@Component({
  selector: 'app-treatment-steps-editor',
  templateUrl: './treatment-steps-editor.component.html',
  styleUrls: ['./treatment-steps-editor.component.scss']
})
export class TreatmentStepsEditorComponent implements OnInit {
  @Input('treatment') patientTreatment: TreatmentDto;
  private _destroy$: Subject<boolean> = new Subject();
  stepGroups$ = this._store$.select(StepGroupStoreSelectors.selectIsActiveStepGroups(true));
  /* steps$: Observable<StepWithCountDto[]> = this.stepGroups$.pipe(
    map((groups) => groups.reduce((arr, grp) => arr.push(...grp.steps) && arr, <StepWithCountDto[]>[]))
  ) */

  steps$: Observable<StepWithCountDto[]> = combineLatest(
    this.settingClient.settings_GetSteps()
  ).pipe(
    map((activeSteps) => {
      let activeStepsCount: StepWithCountDto[] = [];
      activeSteps[0].map(steps => {
        let stepcountDto:StepWithCountDto = new StepWithCountDto();
        stepcountDto.stepId = steps.id;
        stepcountDto.isActive = steps.isActive;
        stepcountDto.name = steps.name;
        stepcountDto.sortOrder = steps.sortOrder;
        stepcountDto.weekCount = steps.defaultWeekCount;
        stepcountDto.eTag = steps.eTag;
        activeStepsCount.push(stepcountDto);
      })
      activeStepsCount = _.orderBy(activeStepsCount, 'name', 'asc');
      return activeStepsCount
    })
  )

  stepGroupSelector: StepGroupDto;
  isLoading$ = this._store$.select(PatientTreatmentStoreSelectors.selectIsLoading);
  addStepFormControl: FormControl = new FormControl();
  stepSearch$: Observable<StepWithCountDto[]> = this.addStepFormControl.valueChanges.pipe(
    startWith(''),
    filter((value: string | Object) => typeof value === 'string'),
    map((value: string) => value && value.trim()),
    withLatestFrom(this.steps$),
    map(([value, steps]) => {
      if (value) return steps.filter((step) => step.name.toLowerCase().includes(value.toLowerCase()));
      return steps;
    })
  );
  get totalWeekCount() {
    return (this.patientTreatment && this.patientTreatment.steps.reduce((total, step) => (total += step.weekCount), 0)) || 0;
  }
  updateWeekCount$: Subject<StepDto> = new Subject<StepDto>();
  // updateWeekCountEffect$ =

  stepSearchOptn$: Observable<StepWithCountDto[]> = this.steps$.pipe(
    withLatestFrom(this.steps$),
    map(([value, steps]) => {
      return steps;
    })
  )

  selectedStep:StepDto;
  restartForm: FormGroup;
  selectedPatient: any;
  selectedPatient$ = this._store$.select(PatientsStoreSelectors.getSelectedPatient).subscribe((patient) => {
    this.selectedPatient = patient;
  });

  isTreatmentStarted:boolean = false;
  stepsInProgress:boolean = false;
  numberMask = createNumberMask({ prefix:'', thousandsSeparatorSymbol: ',', allowDecimal: true, decimalSymbol: '.',  allowLeadingZeroes: true });
  updateSortOrder$: Subject<StepDto> = new Subject<StepDto>();

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientTSEF: PatientTreatmentStoreEffectsService,
    private formBuilder: FormBuilder,
    private _locationClient:LocationClient,
    private _snackbar: MatSnackBar,
    private _actions$: ActionsSubject,
    private settingClient:SettingsClient
  ) { }

  ngOnInit() {
    this.restartForm = this.formBuilder.group({
      startdate: ['', Validators.required],
    });

    this.restartForm.patchValue({
      "startdate": this.patientTreatment.steps.length > 0 ? moment(this.patientTreatment.steps[0].startDate).toISOString() : moment().toISOString()
    });

    this.isTreatmentStarted = false;

    if(this.patientTreatment.steps.length > 0){
      this.patientTreatment.steps.map(step => {
        if(step.actualFinishDate != null) {
          this.isTreatmentStarted = true;
        }
      })
    }

    //Listen for week count change and save after an amount of time without further changes
    this.updateWeekCount$.pipe(debounceTime(1200), takeUntil(this._destroy$)).subscribe((step) => {
      
      this._patientTSEF.updateStepRequestEffect$.pipe(take(1)).subscribe((result) => {
        if (result.type == PatientTreatmentStoreActions.UpdateStepsSuccess.type) this.patientTreatment = result.plan;
      });

      this._locationClient.location_PutStep(this.selectedPatient.locationId, this.selectedPatient.id, this.patientTreatment.id, step.id, step).subscribe(result => {
        this.stepsInProgress = false;
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this.selectedPatient.locationId, patientId: this.selectedPatient.id }))
        this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(treatments => {
          this.patientTreatment.steps = _.orderBy(treatments.plans.find(item => item.id == this.patientTreatment.id).steps, 'sortOrder', 'asc');
        })
      },
      err => {
        this.stepsInProgress = false;
      })
    });

    //Listen for successful actions and update our local copy of plan
    merge(this._patientTSEF.deleteStepRequestEffect$, this._patientTSEF.updateStepRequestEffect$, this._patientTSEF.addStepRequestEffect$)
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        if (
          result.type == PatientTreatmentStoreActions.DeleteStepSuccess.type ||
          result.type == PatientTreatmentStoreActions.UpdateStepsSuccess.type ||
          result.type == PatientTreatmentStoreActions.AddStepsSuccess.type
        )
          if (this.patientTreatment && this.patientTreatment.id == result.plan.id) this.patientTreatment = result.plan;
      });


    this.updateSortOrder$.pipe(debounceTime(1200), takeUntil(this._destroy$)).subscribe((step) => {
      
      this._patientTSEF.updateStepRequestEffect$.pipe(take(1)).subscribe((result) => {
        if (result.type == PatientTreatmentStoreActions.UpdateStepsSuccess.type) this.patientTreatment = result.plan;
      });

      this._locationClient.location_PutStep(this.selectedPatient.locationId, this.selectedPatient.id, this.patientTreatment.id, step.id, step).subscribe(result => {
        this.stepsInProgress = false;
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this.selectedPatient.locationId, patientId: this.selectedPatient.id }))
        this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(treatments => {
          this.patientTreatment.steps = _.orderBy(treatments.plans.find(item => item.id == this.patientTreatment.id).steps, 'sortOrder', 'asc');
        })
      },
      err => {
        this.stepsInProgress = false;
      })
    });

  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  restartTreatmentDate() {
    let stepStart: StepStartDto = new StepStartDto({
      treatmentId: this.patientTreatment.id,
      startDate: moment(this.restartForm.value.startdate).toDate()
    });

    this._locationClient.location_PostStepsRestart(
      this.selectedPatient.locationId,
      this.selectedPatient.id,
      this.patientTreatment.id,
      stepStart,
      null).subscribe((res) => {
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({
          locationId: this.selectedPatient.locationId,
          patientId: this.selectedPatient.id
        }))
        this._snackbar.open('Succesfully updated new start date', 'OK', { duration: 2500 });
      },
      err => {
        console.log(err)
        this._snackbar.open(err.message, 'OK');
      }
    )
  }

  addStepGroup(group: StepGroupDto) {
    let stepDate = moment().startOf('day');
    const steps = group.steps
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((step, ind) => {
        return new StepDto({
          ...step,
          id: null,
          treatmentId: this.patientTreatment.id,
          startDate: stepDate.toDate(),
          estimateFinishDate: stepDate.add(step.weekCount, 'weeks').toDate(),
          sortOrder: ind,
        });
      });
    this.addSteps(steps);
  }

  addSteps(steps: StepDto[]) {
    let startInd = this.patientTreatment.steps.length;
    steps.forEach((step) => {
      step.sortOrder = startInd;
      startInd++;
    });
    
    if(this.patientTreatment.id == 0){
      this.stepsInProgress = true;
      this._locationClient.location_PostTreatment(
        this.selectedPatient.locationId, 
        this.selectedPatient.id,
        this.patientTreatment).pipe(take(1)).subscribe(resp => {
          steps[0].treatmentId = resp.id;
          this._locationClient.location_PostSteps(this.selectedPatient.locationId, this.selectedPatient.id, resp.id, steps).subscribe(result => {
            this.stepsInProgress = false;
            this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this.selectedPatient.locationId, patientId: this.selectedPatient.id }))
            this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(treatments => {
              this.patientTreatment = treatments.plans.find(item => item.id == resp.id);
            })
          },
          err => {
            this.stepsInProgress = false;
          })
      }, err => {
        this.stepsInProgress = false;
      })
    } else {

      this.stepsInProgress = true;

      this._locationClient.location_PostSteps(this.selectedPatient.locationId, this.selectedPatient.id, this.patientTreatment.id, steps).subscribe(result => {
        this.stepsInProgress = false;
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this.selectedPatient.locationId, patientId: this.selectedPatient.id }))
        this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(treatments => {
          this.patientTreatment.steps = _.orderBy(treatments.plans.find(item => item.id == this.patientTreatment.id).steps, 'sortOrder', 'asc');
        })
      },
      err => {
        this.stepsInProgress = false;
      })
    }
  }

  addNewStepFromTemplate(step: StepWithCountDto | string) {
    if(!step) return;
    let stepDate =
      this.patientTreatment.steps.length > 0
        ? moment(this.patientTreatment.steps[this.patientTreatment.steps.length - 1].estimateFinishDate)
        : moment().startOf('day');
    if (step instanceof StepWithCountDto) {
      const newStep = new StepDto({
        ...step,
        id: null,
        treatmentId: this.patientTreatment.id,
        startDate: stepDate.toDate(),
        estimateFinishDate: stepDate.add(step.weekCount, 'weeks').toDate(),
      });
      this.addSteps([newStep]);
    } else if(typeof step === 'string') {
      const newStep = new StepDto({
        id: null,
        treatmentId: this.patientTreatment.id,
        sortOrder: this.patientTreatment.steps.length,
        weekCount:  0,
        actualFinishDate: null,
        name: step,
        startDate: stepDate.toDate(),
        estimateFinishDate: stepDate.toDate(),
      });
      this.addSteps([newStep]);
    }
    this.addStepFormControl.setValue(null);
  }

  deleteStep(stepToDelete: StepDto) {
    //we need up date the estimated dates and sort order for steps following deleted step
    /* let stepsToUpdate = this.patientTreatment.steps.filter((s) => s.id != stepToDelete.id && s.sortOrder > stepToDelete.sortOrder);
    if (stepsToUpdate.length > 0) {
      let startDate = moment(stepToDelete.startDate);
      stepsToUpdate
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .forEach((s) => {
          s.startDate = startDate.toDate();
          s.estimateFinishDate = startDate.add(s.weekCount, 'weeks').toDate();
          s.sortOrder -= 1;
        });
      this._store$.dispatch(PatientTreatmentStoreActions.UpdateStepsRequest({ steps: stepsToUpdate, treatmentId: this.patientTreatment.id }));
    }
    this._store$.dispatch(PatientTreatmentStoreActions.DeleteStepRequest({ stepId: stepToDelete.id, treatmentId: this.patientTreatment.id })); */


    this.stepsInProgress = true;

    this._locationClient.location_DeleteStep(this.selectedPatient.locationId, this.selectedPatient.id, this.patientTreatment.id, stepToDelete.id).subscribe(resp => {
      this.stepsInProgress = false;
      this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this.selectedPatient.locationId, patientId: this.selectedPatient.id }))
      this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(treatments => {
        this.patientTreatment.steps = _.orderBy(treatments.plans.find(item => item.id == this.patientTreatment.id).steps, 'sortOrder', 'asc');
      })
    }, 
    err => {
      this.stepsInProgress = false;
      console.log(err)
    })

    
  }

  updateStepWeekCount(step) {
    this.updateWeekCount$.next(step);
  }

  stepDisplayFn(step: StepWithCountDto) {
    return step && step.name;
  }

  stepTrackByFn(item, ind) {
    return item.id;
  }

  editStep(stepToEdit: StepDto){

    let stepsToUpdate = this.patientTreatment.steps;
    if (stepsToUpdate.length > 0) {
      /* let startDate = moment(stepToEdit.startDate);
      stepsToUpdate
        .forEach((s) => {
          s.startDate = startDate.toDate();
          s.estimateFinishDate = startDate.add(s.weekCount, 'weeks').toDate();
        }); */
        
      this.stepsInProgress = true;

      this._locationClient.location_PutStep(this.selectedPatient.locationId, this.selectedPatient.id, this.patientTreatment.id, stepToEdit.id, stepToEdit).subscribe(result => {
        this.stepsInProgress = false;
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this.selectedPatient.locationId, patientId: this.selectedPatient.id }))
        this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(treatments => {
          this.patientTreatment.steps = _.orderBy(treatments.plans.find(item => item.id == this.patientTreatment.id).steps, 'sortOrder', 'asc');
        })
      },
      err => {
        this.stepsInProgress = false;
      })
    }
  }

  enableAutoComplete(editstepElem: StepDto){
    this.selectedStep = editstepElem;
    let searchkeyword: any = editstepElem.name;
    this.stepSearchOptn$ = this.steps$.pipe(
      withLatestFrom(this.steps$),
      map(([value, steps]) => {
        if (value) return steps.filter((step) => step.name.toLowerCase().includes(searchkeyword.toLowerCase()));
        return steps;
      })
    )
  }

  selectedEditOption(editOption: StepDto){
    this.selectedStep.name = editOption.name;
    this.selectedStep.weekCount = editOption.weekCount;
  }

  activeStepInput(activeStep:StepDto){
    this.stepSearchOptn$ = this.steps$.pipe(
      withLatestFrom(this.steps$),
      map(([value, steps]) => steps)
    )
  }

  updateSortOrder(step){
    this.updateSortOrder$.next(step);
  }
}
