import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, delay, filter, mapTo } from 'rxjs/operators';
import { HubEventArea, HubEventType, SettingsClient } from 'src/app/shared/services/api.service';
import * as DiagnosisStoreSelectors from './selectors';
import * as RootStoreState from '../../root-state';
import * as DiagnosisStoreActions from './actions';
import { SignalRHubStoreActions } from '../../signalr-hub-store';

@Injectable({ providedIn: 'root' })
export class TreatmentDiagnosisEffects {
  constructor(private store$: Store<RootStoreState.State>, private actions$: Actions, private settingsClient: SettingsClient) {}

  loadRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiagnosisStoreActions.LoadRequest),
      switchMap((action) => {
        if (action.id) {
          return this.settingsClient.settings_GetDiagnosis(action.id).pipe(
            map((result) => DiagnosisStoreActions.LoadSuccess({ diagnoses: result })),
            catchError((error) => of(DiagnosisStoreActions.LoadFailure({ err: error })))
          )
        }
        else {
          return this.settingsClient.settings_GetDiagnoses().pipe(
            map((result) => DiagnosisStoreActions.LoadSuccess({ diagnoses: result })),
            catchError((error) => of(DiagnosisStoreActions.LoadFailure({ err: error })))
          )
        }
      })
    );
  });

  loadOnceRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiagnosisStoreActions.LoadOnceRequest),
      withLatestFrom(this.store$.select(DiagnosisStoreSelectors.selectDiagnosisState)),
      filter(([_, state]) => !state.hasLoaded),
      mapTo(DiagnosisStoreActions.LoadRequest({}))
    );
  });

  // Listen for updates from hub
  messageDraftUpdateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      filter(
        (action) =>
          action.event.eventArea == HubEventArea.DiagnosesEvent &&
          action.event.entityType == SettingsClient.DiagnosisDto
      ),
      map((action) =>
        action.event.eventType == HubEventType.Added ||
        action.event.eventType == HubEventType.Modified
          ? DiagnosisStoreActions.LoadRequest({ id: action.event.entityId })
          : DiagnosisStoreActions.Remove({ id: action.event.entityId })
      ),
    )
  );
}
