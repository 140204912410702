import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OutreachmainComponent, MedicalHistory } from './outreachmain/outreachmain.component';
import { Outreachrouting } from './outreach.routing';
import { TreatmentComponent, ImageModal, TodoListDialog } from './treatment/treatment.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { ChairComponent, PatientBox } from './chair/chair.component';
import { AcordionddnComponent } from './acordionddn/acordionddn.component';
import { MultileveldrpdnComponent } from './multileveldrpdn/multileveldrpdn.component';
import { TreatmentmeterComponent } from './treatmentmeter/treatmentmeter.component';
import { CardaccordionComponent } from './cardaccordion/cardaccordion.component';
import { StepsdrdnComponent } from './stepsdrdn/stepsdrdn.component';
import { RelationshipcardComponent } from './relationshipcard/relationshipcard.component';
import { TextMaskModule } from 'angular2-text-mask';
import { FinanceComponent } from './finance/finance.component';
import { CommunicationComponent } from './communication/communication.component';
import { VerticalMeterComponent } from './vertical-meter/vertical-meter.component';
import { SelectedDDOptionsComponent } from './selected-ddoptions/selected-ddoptions.component';
import { TxplanBackComponent } from './txplan-back/txplan-back.component';
import { NontxplanBackComponent } from './nontxplan-back/nontxplan-back.component';
import { TreatmentNotificationComponent } from './treatment-notification/treatment-notification.component';

import { XrayScanComponent } from './xray-scan/xray-scan.component';
import { PayscheduleComponent } from './payschedule/payschedule.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatFormFieldModule, MatInputModule, MatProgressBarModule, MatMenuModule, MatSelectModule } from '@angular/material';
import { PatientProfileHomeComponent } from './patient-profile-home/patient-profile-home.component';

import { UploadComponent } from './treatment/upload/upload.component';
import { DropdownComponent } from './treatment/dropdown/dropdown.component';
import { GalleryComponent } from './treatment/gallery/gallery.component';
import { CropImageComponent } from './treatment/crop-image/crop-image.component';
import { GalleryModalComponentComponent } from './treatment/gallery-modal-component/gallery-modal-component.component';
import { ElasticTreatmentComponent } from './elastic-treatment/elastic-treatment.component';
import { ElasticDropdownComponent } from './elastic-treatment/elastic-dropdown/elastic-dropdown.component';
import { MedicalHistoryComponent } from './outreachmain/medical-history/medical-history.component';

import { SearchmoduleModule } from '../shared-module/searchmodule/searchmodule.module';
import { PatientscheduleProfileComponent } from './modal/patientschedule-profile/patientschedule-profile.component';
import { PatientmodalddProfileComponent } from './modal/patientmodaldd-profile/patientmodaldd-profile.component';
import { TxCardDdrpdnComponent } from './treatment/tx-card-ddrpdn/tx-card-ddrpdn.component';
import { GoalsAcordionddnComponent } from './goals-acordionddn/goals-acordionddn.component';

import { NgxFileDropModule } from 'ngx-file-drop';

import { MedicalAlertcardComponent } from './medical-alertcard/medical-alertcard.component';
import { CommentCardComponent } from './comment-card/comment-card.component';
import { PatientPreferencescardComponent } from './patient-preferencescard/patient-preferencescard.component';
import { PatientPreferencesDropdownComponent } from './patient-preferencescard/patient-preferences-dropdown/patient-preferences-dropdown.component';
import { PatientReferralCardComponent } from './patient-referral-card/patient-referral-card.component';
import { ProfessionalReferralCardComponent, DoctorReferralModule } from './professional-referral-card/professional-referral-card.component';
import { DoctorReferralInComponent } from './professional-referral-card/doctor-referral-in/doctor-referral-in.component';
import { DoctorReferralOutComponent } from './professional-referral-card/doctor-referral-out/doctor-referral-out.component';

import { DentistCardComponent, DentistReferralModule } from './dentist-card/dentist-card.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { BracketCardComponent } from './bracket-card/bracket-card.component';
import { DropdownRelationshipCardComponent } from './relationshipcard/dropdown-relationship-card/dropdown-relationship-card.component';
import { DoctorLocationComponent } from './doctor-location/doctor-location.component';
import { ClickConfirmModule } from '../shared-module/click-confirm/click-confirm.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CompareComponent } from './treatment/gallery/compare/compare.component';
import { PhotoEditorModule } from '../shared-module/photo-editor/photo-editor.module';
import { PhotoEditorComponent } from '../shared-module/photo-editor/photo-editor.component';
import { EditTakenWhenDialogComponent } from './treatment/gallery/edit-taken-when-dialog/edit-taken-when-dialog.component';
import { MovePhotoGroupDialogComponent } from './treatment/gallery/move-photo-group-dialog/move-photo-group-dialog.component';
import { MomentDateModule } from '../pipe/moment-date/moment-date.module';
import { ImageErrorHandlerModule } from '../shared-module/image-error-handler/image-error-handler.module';
import { MovePhotoDialogComponent } from './treatment/gallery/move-photo-dialog/move-photo-dialog.component';
import { FileViewerComponent } from '../shared-module/file-viewer/file-viewer.component';
import { FileViewerModule } from '../shared-module/file-viewer/file-viewer.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AdvancedEditorModule } from '../shared-module/advanced-editor/advanced-editor.module';
import { AdvancedEditorComponent } from '../shared-module/advanced-editor/advanced-editor.component';
import { RenameFileModule } from '../shared-module/card-loader/patient-files/rename-file-dialog/rename-file-dialog.module';
import { RenameFileDialogComponent } from '../shared-module/card-loader/patient-files/rename-file-dialog/rename-file-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  imports: [
    ClickConfirmModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ImageCropperModule,
    IvyCarouselModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    NgxFileDropModule,
    Outreachrouting,
    PerfectScrollbarModule,
    SearchmoduleModule,
    TextMaskModule,
    PhotoEditorModule,
    FileViewerModule,
    MomentDateModule,
    ImageErrorHandlerModule,
    FontAwesomeModule,
    AdvancedEditorModule,
    RenameFileModule
  ],

  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],

  declarations: [
    AcordionddnComponent,
    BracketCardComponent,
    CardaccordionComponent,
    ChairComponent,
    CommentCardComponent,
    CommunicationComponent,
    CompareComponent,
    CropImageComponent,
    DentistCardComponent,
    DentistReferralModule,
    DoctorLocationComponent,
    DoctorReferralInComponent,
    DoctorReferralModule,
    DoctorReferralOutComponent,
    DropdownComponent,
    DropdownRelationshipCardComponent,
    EditTakenWhenDialogComponent,
    ElasticDropdownComponent,
    ElasticTreatmentComponent,
    FinanceComponent,
    GalleryComponent,
    GalleryModalComponentComponent,
    GoalsAcordionddnComponent,
    ImageModal,
    MedicalAlertcardComponent,
    MedicalHistory,
    MedicalHistoryComponent,
    MovePhotoGroupDialogComponent,
    MovePhotoDialogComponent,
    MultileveldrpdnComponent,
    NontxplanBackComponent,
    OutreachmainComponent,
    PatientBox,
    PatientmodalddProfileComponent,
    PatientPreferencescardComponent,
    PatientPreferencesDropdownComponent,
    PatientProfileHomeComponent,
    PatientReferralCardComponent,
    PatientscheduleProfileComponent,
    PayscheduleComponent,
    ProfessionalReferralCardComponent,
    RelationshipcardComponent,
    SelectedDDOptionsComponent,
    StepsdrdnComponent,
    TodoListDialog,
    TreatmentComponent,
    TreatmentmeterComponent,
    TreatmentNotificationComponent,
    TxCardDdrpdnComponent,
    TxplanBackComponent,
    UploadComponent,
    VerticalMeterComponent,
    XrayScanComponent
  ],

  entryComponents: [
    DentistReferralModule,
    DoctorReferralModule,
    EditTakenWhenDialogComponent,
    ImageModal,
    MedicalHistory,
    MedicalHistoryComponent,
    MovePhotoGroupDialogComponent,
    MovePhotoDialogComponent,
    PatientBox,
    PhotoEditorComponent,
    FileViewerComponent,
    AdvancedEditorComponent,
    RenameFileDialogComponent
  ]
})
export class OutreachModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
