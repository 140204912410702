import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChairEffectsService } from './effects';
import { CHAIR_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(CHAIR_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([ChairEffectsService])
  ],
})
export class ChairStoreModule { }
