import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientFormDto, IPatientFormDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientFormStoreEntity> = createEntityAdapter<PatientFormStoreEntity>({
  sortComparer: (a: PatientFormStoreEntity, b: PatientFormStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<PatientFormStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientFormId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientFormId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientFormStoreEntity extends PatientFormDto {
  constructor(data?: IPatientFormDto) {
    super(data);
  }
}
