import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CEPHALOMETRIC_TYPE_FEATURE_KEY } from './featurekey';
import { featureAdapter, CephalometricTypeStoreEntity, State } from './state';

export const selectCephalometricTypesState: MemoizedSelector<object, State> = createFeatureSelector<State>(CEPHALOMETRIC_TYPE_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectCephalometricTypesState);
export const selectCephalometricTypeIds = selectIds;
export const selectAllCephalometricTypes = selectAll;
export const selectAllCephalometricTypeEntities = selectEntities;

export const selectCephalometricTypesError: MemoizedSelector<object, any> = createSelector(selectCephalometricTypesState, (state) => state.error);

export const selectCephalometricTypesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectCephalometricTypesState, (state: State) => state.isLoading);
