import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ActiveTreatmentProgressEffects } from './active-treatment-progress';
import { TreatmentDiagnosisEffects } from './diagnosis';
import { TreatmentEffects } from './effects';
import { TREATMENT_FEATURE_KEY } from './featurekey';
import { TreatmentGoalEffects } from './goal';
import { TreatmentPlanGroupEffects } from './plan-group';
import { featureReducer } from './reducer';
import { TreatmentStepGroupEffects } from './step-group';

@NgModule({
  imports: [
    StoreModule.forFeature(TREATMENT_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([
      TreatmentEffects,
      TreatmentDiagnosisEffects,
      TreatmentGoalEffects,
      TreatmentPlanGroupEffects,
      TreatmentPlanGroupEffects,
      TreatmentStepGroupEffects,
      ActiveTreatmentProgressEffects])
  ]
})
export class TreatmentStoreModule {}
