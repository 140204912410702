import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<{ event: MessageServiceEventType; data: any }>();
  dayType: any;

  sendMessage(event: MessageServiceEventType, message: any) {
    this.subject.next({ event: event, data: message });
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage() {
    return this.subject.asObservable();
  }
}

export type MessageServiceEventType = MessageServiceEventEnum | keyof typeof MessageServiceEventEnum;

export enum MessageServiceEventEnum {
  chairIsLoading = "chairIsLoading",
  refreshPatientSchedule = 'refreshPatientSchedule',
  addedToChair = 'addedToChair',
  scroll = 'scroll',
  isToday = 'isToday',
  timelineview = 'timelineview',
  zoomview = 'zoomview',
  addDayEventMsg = 'addDayEventMsg',
  remove_day_event = 'remove_day_event',
  showFiveMinGrid = 'showFiveMinGrid',
  remove_event = 'remove_event',
  openOption = 'openOption',
  backtoparent = 'backtoparent',
  openedDaySelected = 'openedDaySelected',
  dateHasAppointment = 'dateHasAppointment',
  viewChangeSchedule = 'viewChangeSchedule',
  dropdownstate = 'dropdownstate',
  closemodal = 'closemodal',
  backToFront = 'backToFront',
  chairChange = 'chairChange',
  timeChange = 'timeChange',
  templateSubmitted = 'templateSubmitted',
  cloneTemplate = 'cloneTemplate',
  createblock = 'createblock',
  report = 'report',
  userprofile = 'userprofile',
  addtobucket = 'addtobucket',
  addDentist = 'addDentist',
  openpatienttab = 'openpatienttab',
  posttreatment = 'posttreatment',
  savedoctorFilterforCls = 'savedoctorFilterforCls',
  savedoctorforCls = 'savedoctorforCls',
  savedoctorFilterforoutCls = 'savedoctorFilterforoutCls',
  savedoctorforOut = 'savedoctorforOut',
  savedoctor = 'savedoctor',
  savedoctorOut = 'savedoctorOut',
  addDoctor = 'addDoctor',
  updatedata = 'updatedata',
  refreshScheduledChairs = 'refreshScheduledChairs',
  refreshCheckoutList = 'refreshCheckoutList',
  showappnttype = 'showappnttype',
  addpatientfinished = 'addpatientfinished',
  newtabopen = 'newtabopen',
  'elastic selected' = 'elastic selected',
  dactivewarning = 'deactivewarning',
  flipcard = 'flipcard',
  attachImage = 'attachImage'
}
