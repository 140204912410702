import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IScheduleGridOnWaitListDto, ScheduleGridOnWaitListDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<WaitingAppointmentStoreEntity> = createEntityAdapter<WaitingAppointmentStoreEntity>({
  sortComparer: (a: WaitingAppointmentStoreEntity, b: WaitingAppointmentStoreEntity): number => a.appointmentId - b.appointmentId
});

export interface State extends EntityState<WaitingAppointmentStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class WaitingAppointmentStoreEntity extends ScheduleGridOnWaitListDto {
  constructor(data?: IScheduleGridOnWaitListDto) {
    super(data);
  }
}
