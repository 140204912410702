import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { MODEL_FEATURE_KEY } from './featurekey';
import { featureAdapter, ModelStoreEntity, State } from './state';

export const selectModelsState: MemoizedSelector<object, State> = createFeatureSelector<State>(MODEL_FEATURE_KEY);

export const getSelectedModelId = createSelector(selectModelsState, (state) => state.selectedModelId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectModelsState);
export const selectModelIds = selectIds;
export const selectAllModels = selectAll;
export const selectAllModelEntities = selectEntities;

export const getSelectedModel = createSelector(
  selectModelsState,
  selectAllModels,
  (state, allModels: ModelStoreEntity[]) =>
    allModels ? allModels.find((rp) => rp.id === state.selectedModelId) : null
);

export const selectModelById = (id: number) =>
  createSelector(selectAllModels, (allModels: ModelStoreEntity[]) =>
    allModels ? allModels.find((rp) => rp.id === id) : null
  );

export const selectModelsError: MemoizedSelector<object, any> = createSelector(selectModelsState, (state) => state.error);

export const selectModelsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectModelsState, (state: State) => state.isLoading);
