import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EvoPermission, PERMISSION_AREA } from './jwt.model';
import { UserPermissionsService } from './user-permissions.service';

/**
 * Sets an html elements `display` style to `none` when user does not have specified permission.
 * @example
 * ``` html
 * <div [hideByPermission]="[Permissions.Dashboard.Analytics.Consults, 1]"></div>
 * ```
 */
@Directive({ selector: '[hideByPermission]' })
export class HideByPermissionDirective implements OnDestroy {
  private _subscription: Subscription;

  @Input('hideByPermission') set hideByPermission(value: [EvoPermission, PERMISSION_AREA]) {
    if (value) {
      if (this._subscription) this._subscription.unsubscribe();

      this._subscription = this._userPermissionsService.hasPermission(value[0], value[1]).subscribe((hasPermission) => {
        if (!hasPermission) this.eleRef.nativeElement.style.display = 'none';
        else this.eleRef.nativeElement.style.display = null;
      });
    }
  }

  constructor(private eleRef: ElementRef<HTMLElement>, private _userPermissionsService: UserPermissionsService) {}

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
