import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { StationaryDto, IStationaryDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<StationaryStoreEntity> = createEntityAdapter<StationaryStoreEntity>({
  sortComparer: (a: StationaryStoreEntity, b: StationaryStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<StationaryStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class StationaryStoreEntity extends StationaryDto {
  constructor(data?: IStationaryDto) {
    super(data);
  }
}
