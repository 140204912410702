import { createAction, props } from '@ngrx/store';
import { DiagnosisDto2, GoalDto, PlanDto, StepDto, TreatmentDto } from 'src/app/shared/services/api.service';
import { PatientTreatmentPlanStoreEntity } from './state';

export const LoadTreatmentsOnceRequest = createAction(
  '[Patient Plans] LoadTreatmentsOnce Request',
  props<{ locationId: number; patientId: number }>()
);
export const LoadTreatmentsRequest = createAction('[Patient Plans] LoadTreatments Request', props<{ locationId: number; patientId: number }>());
export const LoadTreatmentsSuccess = createAction('[Patient Plans] LoadTreatments Success', props<{ plans: PatientTreatmentPlanStoreEntity[] }>());
export const LoadTreatmentsFailure = createAction('[Patient Plans] LoadTreatments Failure', props<{ error: any }>());

export const UpdateTreatmentCommentRequest = createAction(
  '[Patient Plans] UpdateTreatmentComment Request',
  props<{ treatmentId: number; comment: string }>()
);
export const UpdateTreatmentCommentSuccess = createAction(
  '[Patient Plans] UpdateTreatmentComment Success',
  props<{ plan: PatientTreatmentPlanStoreEntity }>()
);
export const UpdateTreatmentCommentFailure = createAction('[Patient Plans] UpdateTreatmentComment Failure', props<{ error: any }>());

//#region NYI ->
export const AddTreatmentRequest = createAction(
  '[Patient Plans] AddTreatment Request',
  props<{ locationId: number; patientId: number; treatment: PatientTreatmentPlanStoreEntity }>()
);
export const AddTreatmentSuccess = createAction(
  '[Patient Plans] AddTreatment Success',
  props<{ locationId: number; patientId: number; treatment: PatientTreatmentPlanStoreEntity }>()
);
export const AddTreatmentFailure = createAction('[Patient Plans] AddTreatment Failure', props<{ error: any }>());

export const AddDiagnosisRequest = createAction('[Patient Plans] AddDiagnosis Request', props<{ diagnosis: DiagnosisDto2 }>());
export const AddDiagnosisSuccess = createAction('[Patient Plans] AddDiagnosis Success', props<{ diagnosis: DiagnosisDto2 }>());
export const AddDiagnosisFailure = createAction('[Patient Plans] AddDiagnosis Failure', props<{ error: any }>());

export const AddPlanRequest = createAction('[Patient Plans] AddPlan Request', props<{ plan: PlanDto }>());
export const AddPlanSuccess = createAction('[Patient Plans] AddPlan Success', props<{ plan: PlanDto }>());
export const AddPlanFailure = createAction('[Patient Plans] AddPlan Failure', props<{ error: any }>());

export const AddGoalRequest = createAction('[Patient Plans] AddGoal Request', props<{ goal: GoalDto }>());
export const AddGoalSuccess = createAction('[Patient Plans] AddGoal Success', props<{ goal: GoalDto }>());
export const AddGoalFailure = createAction('[Patient Plans] AddGoal Failure', props<{ error: any }>());
//#endregion <- NYI

export const AddStepsRequest = createAction('[Patient Plans] AddStep Request', props<{ steps: StepDto[]; treatmentId: number }>());
export const AddStepsSuccess = createAction('[Patient Plans] AddStep Success', props<{ plan: PatientTreatmentPlanStoreEntity }>());
export const AddStepsFailure = createAction('[Patient Plans] AddStep Failure', props<{ error: any }>());

export const UpdateStepsRequest = createAction('[Patient Plans] UpdateStep Request', props<{ steps: StepDto[]; treatmentId: number }>());
export const UpdateStepsSuccess = createAction('[Patient Plans] UpdateStep Success', props<{ plan: PatientTreatmentPlanStoreEntity }>());
export const UpdateStepsFailure = createAction('[Patient Plans] UpdateStep Failure', props<{ error: any }>());

export const DeleteStepRequest = createAction('[Patient Plans] DeleteStep Request', props<{ treatmentId: number; stepId: number }>());
export const DeleteStepSuccess = createAction('[Patient Plans] DeleteStep Success', props<{ plan: PatientTreatmentPlanStoreEntity }>());
export const DeleteStepFailure = createAction('[Patient Plans] DeleteStep Failure', props<{ error: any }>());

export const SelectTreatmentPlan = createAction('[Patient Plans] SelectTreatmentPlan', props<{ id: number }>());
export const LocalUpdatePlan = createAction('[Patient Plans] LocalUpdateSteps', props<{ plan: PatientTreatmentPlanStoreEntity }>());
