import { Component, Input, Output, OnDestroy, OnInit, AfterViewInit, forwardRef, HostBinding } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true
    }
  ]
})
export class ImageUploaderComponent implements ControlValueAccessor {
  //#region forms stuff
  @HostBinding('attr.id') externalId = '';

  private _ID = '';
  @Input() set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }
  get id() {
    return this._ID;
  }

  @Input('value') _value = '';
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  onChange: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  onTouched: any = () => { };
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  disabled: boolean = false;
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  @Input() required: boolean = false;
  //#endregion


  @Input('maxSize') maxSize: number = 100;
  @Input('forceResize') forceResize: boolean = false;
  @Input('thumbnail') showThumbnail: boolean = true;
  @Input('thumbnailMaxSize') thumbnailMaxSize = '100px';

  @Output('image') imageOutput = new Subject<string>(); //outputs image as base64 string

  constructor() {
  }

  //on input change, get the file, read it, resize, and store base64 image in form
  saveImage(eve: Event) {
    let p = new Promise((resolve, reject) => {
      let input = eve.target as HTMLInputElement;
      if (input.files && input.files.length > 0) { //ensure input has files
        //we will read the file client side
        let reader: FileReader = new FileReader();
        reader.readAsDataURL(input.files[0]); //read as base64, may change later
        reader.onloadend = (ev) => {
          this.resizeImage(reader.result as string, this.maxSize)
            .subscribe(resizedImg => {
              this.value = resizedImg;
              this.imageOutput.next(this.value);
              resolve(null);
            });
        };
      }
    });

    p.finally(() => {
      eve.target.dispatchEvent(new Event('saved')); //trigger saved event to hide spinner
    });
  }

  //takes a base64 encoded image string and resizes it by drawing onto a different sized canvas
  resizeImage(imgData: string, maxWidth: number) {
    const img = new Image();
    img.src = imgData;
    return fromEvent(img, 'load') //wait for image to load data before drawing to canvas from it
      .pipe(map(eve => { //remap observable from onload event to our processed data url
        const canvas = document.createElement('canvas');
        let scaleFactor = maxWidth / img.width;

        const longSide = img.width > img.height ? img.width : img.height;
        if (longSide < maxWidth && !this.forceResize)
          scaleFactor = 1; //don't scale if beneath maxWidth and forced rescale is off

        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        //draw image onto downsized canvas and get new image data from it
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        return ctx.canvas.toDataURL();
      }));
  }

}
