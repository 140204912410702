import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardsStoreActions, RootStoreState } from '../../root-store';
import { CARD_DESCRIMINATOR, ICard, ICardModule } from '../../shared/models';
import { ThreeDModelsModule } from './3d-models/3d-models.module';
import { AlignersModule } from './aligners/aligners.module';
import { CephalometricsModule } from './cephalometrics/cephalometrics.module';
import { KeyDatesModule } from './key-dates/key-dates.module';
import { PatientBasicDetailsModule } from './patient-basic-details/patient-basic-details.module';
import { PatientFilesModule } from './patient-files/patient-files.module';
import { PatientImagingModule } from './patient-imaging/patient-imaging.module';
import { PatientOverviewModule } from './patient-overview/patient-overview.module';
import { PatientPlanModule } from './patient-plan/patient-plan.module';
import { PatientRelationshipsModule } from './patient-relationships/patient-relationships.module';
import { ToothgridModule } from './toothgrid/toothgrid.module';
import { NotepadModule } from './notepad/notepad.module';
import { RxRequestsModule } from './rx-requests/rx-requests.module';
import { PatientFormsModule } from './patient-forms/patient-forms.module';
import { ReferOutModule } from './refer-out/refer-out.module';
import { AlertsRemindersModule } from './alerts-reminders/alerts-reminders.module';
import { GiftsModule } from './gifts/gifts.module';
import { PanoramicModule } from './panoramic-viewer/panoramic-viewer.module';

const cards = [
  PatientBasicDetailsModule,
  PatientRelationshipsModule,
  PatientOverviewModule,
  PatientPlanModule,
  ThreeDModelsModule,
  AlignersModule,
  CephalometricsModule,
  PatientFormsModule,
  KeyDatesModule,
  PatientFilesModule,
  PatientImagingModule,
  ToothgridModule,
  NotepadModule,
  RxRequestsModule,
  ReferOutModule,
  AlertsRemindersModule,
  GiftsModule,
  PanoramicModule
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...cards],
})
export class CardLoaderModule {
  constructor(private store$: Store<RootStoreState.State>, @Optional() @SkipSelf() parentModule?: CardLoaderModule) {
    //Ensure only load once
    if (parentModule) {
      throw new Error('CardLoaderModule is already loaded. Import it in the AppModule only');
    }

    if (cards) {
      //get this modules NgModule decorator
      cards.forEach((moduleType: any) => {
        //Check if import is a card module
        if (moduleType.prototype.descriminator == CARD_DESCRIMINATOR) {
          var cardModule: ICardModule = moduleType.prototype;
          const card: ICard = {
            selector: cardModule.cardSelector,
            cardFront: cardModule.cardFront,
            cardBack: cardModule.cardBack,
            header: cardModule.cardHeader,
            width: cardModule.cardWidth,
            height: cardModule.cardHeight,
            actions: cardModule.cardActions,
            order: cardModule.cardOrder,
            color: cardModule.cardColor,
          };
          const request = CardsStoreActions.AddRequestAction({ card: card });
          this.store$.dispatch(request);
        }
      });
    }
  }
}
