import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { PatientClient, PatientTreatmentImageGroupDto, PhotoGroupDto } from 'src/app/shared/services/api.service';
import { PhotoGroupStoreEntity } from '../../../../root-store';

@Component({
  selector: 'move-photo-group-dialog',
  templateUrl: './move-photo-group-dialog.component.html',
  styleUrls: ['./move-photo-group-dialog.component.scss'],
})
export class MovePhotoGroupDialogComponent implements OnInit {
  patientTreatmentImageGroupIdTo: number;
  formControlPhotoGroupIdTo: FormControl;
  isWorking: boolean = false;
  photoGroups: PatientTreatmentImageGroupDto[];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: MovePhotoGroupDialogData,
    private _bottomSheetRef: MatBottomSheetRef<MovePhotoGroupDialogComponent>,
    private _patientClient: PatientClient,
    private _snackBar: MatSnackBar
  ) {
    this.formControlPhotoGroupIdTo = new FormControl();
    let fromPhotoGroupId = this._data.fromPhotoGroupId;
    this.photoGroups = _.filter(this._data.photoGroups, (p: PatientTreatmentImageGroupDto) => {
      return p.id != fromPhotoGroupId;
    });
  }

  ngOnInit() {}

  save() {
    this.isWorking = true;
    this._patientClient
      .patient_MovePatientTreatmentImageGroupPhotoGroup(
        this._data.patientId,
        this._data.patientTreatmentImageGroupIdFrom,
        this.patientTreatmentImageGroupIdTo)
      .pipe(take(1))
      .subscribe(() => {
        this.isWorking = false;
        this.done();
      }, err => this.isWorking = false);
  }

  done() {
    this._snackBar.open("Successfully moved.", "Ok", {
      duration: 3000,
    });
    this._bottomSheetRef.dismiss(true);
  }
}

export interface MovePhotoGroupDialogData {
  patientId: number;
  patientTreatmentImageGroupIdFrom: number;
  fromPhotoGroupId: number
  photoGroups: PatientTreatmentImageGroupDto[];
}
