import { createAction, props } from '@ngrx/store';
import { EntityHubEvent, VoiceHubEvent } from 'src/app/shared/services/api.service';

export const HubCreated = createAction('[SignalR Hub] Created');
export const HubStarted = createAction('[SignalR Hub] Started');
export const HubStopped = createAction('[SignalR Hub] Stopped');
export const HubReconnecting = createAction('[SignalR Hub] Reconnecting');
export const HubReconnected = createAction('[SignalR Hub] Reconnected');

export const EntityEvent = createAction('[SignalR Hub] EntityEvent', props<{ event: EntityHubEvent }>());
export const HubStopRequest = createAction('[SignalR Hub] HubStop Request');

export const VoiceEvent = createAction('[SignalR Hub] VoiceEvent', props<{ event: VoiceHubEvent }>());
