import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppointmentComplianceStoreModule } from './appointment-compliance-store';
import { AppointmentStoreModule } from './appointment-store';
import { AuthStoreModule } from './auth-store';
import { CardsStoreModule } from './cards-store';
import { CephalometricStoreModule } from './cephalometric-store';
import { CommunicationDeskStoreModule } from './communication-desk-store';
import { ContactStoreModule } from './contact-store';
import { DentistStoreModule } from './dentist-store';
import { DoctorTimeStoreModule } from './doctor-time-store';
import { FormTypeStoreModule } from './form-type-store';
import { HeaderStoreModule } from './header-store';
import { InsuranceCompanyStoreModule } from './insurancecompany-store';
import { LocationStoreModule } from './location-store';
import { ModelStoreModule } from './model-store';
import { OnDeckStoreModule } from './on-deck-store';
import { PatientCompletionDateStoreModule } from './patient-completion-date-store';
import { PatientDentistVisitStoreModule } from './patient-dentist-visit-store/patient-dentist-visit-store.module';
import { PatientImagingStoreModule } from './patient-imaging-store';
import { PatientMessageStoreModule } from './patient-message-store';
import { PatientModelBoxStoreModule } from './patient-model-box-store';
import { PatientNotepadStoreModule } from './patient-notepad-store';
import { PatientRecallStoreModule } from './patient-recall-store';
import { PatientStatusGroupStoreModule } from './patient-status-group-store';
import { PatientStoreModule } from './patient-store';
import { PatientTreatmentStatusStoreModule } from './patient-treatment-status-store';
import { PatientTreamentStoreModule } from './patient-treatment-store';
import { PatientLedgerStoreModule } from './patientledger-store';
import { PhotoGroupStoreModule } from './photogroup-store';
import { PhotoTypeStoreModule } from './phototype-store';
import { PostingCodeStoreModule } from './posting-code-store';
import { ProcedureGroupStoreModule } from './procedure-group-store';
import { ProviderStoreModule } from './provider-store';
import { ResponsiblePartyStoreModule } from './responsibleparty-store';
import { RouterStoreModule } from './router-store';
import { ScheduleStoreModule } from './schedule-store';
import { SignalRHubStoreModule } from './signalr-hub-store';
import { TreatmentStoreModule } from './treatment-store/treatment-store.module';
import { UserStoreModule } from './user-store';
import { UserTaskStoreModule } from './user-task-store';
import { TwainStoreModule } from './twain-store';
import { MessageDraftStoreModule } from './message-draft-store';
import { ActiveVoiceStoreModule } from './active-voice-store';
import { TemplateStoreModule } from './template-store';
import { StationaryStoreModule } from './stationary-store';
import { CephalometricTypeStoreModule } from './cephalometric-type-store';
import { PatientCephalometricStoreModule } from './patient-cephalometric-store';
import { ChairStoreModule } from './chair-store';
import { PatientReferralMessageStoreModule } from './patient-referral-message-store';
import { FormGroupStoreModule } from './form-group-store';
import { PatientFormStoreModule } from './patient-form-store';
import { PatientMiniCardsStoreModule } from './patient-mini-cards-store';
import { WaitingAppointmentStoreModule } from './waiting-appointment-store';
import { MarketingPackageStoreModule } from './marketing-package-store';
import { InternalUserStoreModule } from './internal-user-store';
import { TenantStoreModule } from './tenant-store';
import { TxCardTypeStoreModule } from './tx-card-type-store';
import { SettingsStoreModule } from './settings-store';

@NgModule({
  declarations: [],
  imports: [
    // The load order of these do matter (for now), please do not sort the module imports
    // For now, if your store depends on the intialization action of another then place it above said dependency.
    // If you don't know where to put it then place it anywhere above AuthStoreModule
    CommonModule,
    RouterStoreModule,
    AppointmentStoreModule,
    AppointmentComplianceStoreModule,
    CardsStoreModule,
    CephalometricStoreModule,
    CephalometricTypeStoreModule,
    DentistStoreModule,
    DoctorTimeStoreModule,
    FormGroupStoreModule,
    FormTypeStoreModule,
    HeaderStoreModule,
    LocationStoreModule,
    ModelStoreModule,
    CommunicationDeskStoreModule,
    InsuranceCompanyStoreModule,
    InternalUserStoreModule,
    ContactStoreModule,
    MarketingPackageStoreModule,
    PatientCephalometricStoreModule,
    PatientCompletionDateStoreModule,
    PatientDentistVisitStoreModule,
    PatientFormStoreModule,
    PatientImagingStoreModule,
    PatientLedgerStoreModule,
    PatientModelBoxStoreModule,
    PatientMessageStoreModule,
    PatientNotepadStoreModule,
    PatientRecallStoreModule,
    PatientReferralMessageStoreModule,
    PatientStoreModule,
    PatientStatusGroupStoreModule,
    PatientTreatmentStatusStoreModule,
    PhotoGroupStoreModule,
    PhotoTypeStoreModule,
    PostingCodeStoreModule,
    ProcedureGroupStoreModule,
    ProviderStoreModule,
    ResponsiblePartyStoreModule,
    SettingsStoreModule,
    TenantStoreModule,
    TreatmentStoreModule,
    TxCardTypeStoreModule,
    UserStoreModule,
    UserTaskStoreModule,
    PatientTreamentStoreModule,
    ScheduleStoreModule,
    OnDeckStoreModule,
    TwainStoreModule,
    MessageDraftStoreModule,
    SignalRHubStoreModule,
    ActiveVoiceStoreModule,
    TemplateStoreModule,
    StationaryStoreModule,
    ChairStoreModule,
    PatientMiniCardsStoreModule,
    WaitingAppointmentStoreModule,
    AuthStoreModule, // For now this needs to be listed last. See note above
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  ]
})
export class RootStoreModule { }
