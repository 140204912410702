import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, HubEventType, MessageDto, PatientClient } from 'src/app/shared/services/api.service';
import { PatientClientService } from '../../shared/services/client/patient-client.service';
import { PatientsStoreSelectors } from '../patient-store';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as PatientReferralMessageStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class PatientReferralMessageStoreEffects {
  private _selectedPatientId = this.store$.select(PatientsStoreSelectors.getSelectedPatientId);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _patientClient: PatientClient,
    private _patientClientService: PatientClientService) { }

  @Effect()
  patientReferralMessageEventEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedPatientId),
    filter(
      ([action, selectedPatientId], selectedLocationId) =>
        action.event.eventArea == HubEventArea.CommunicationEvent &&
        action.event.entityType == MessageDto.name &&
        action.event.patientId == selectedPatientId
    ),
    map(([action, selectedPatientId]) => {
      switch (action.event.eventType) {
        case HubEventType.Removed:
          return PatientReferralMessageStoreActions.ReferralMessagedRemoved({ messageId: action.event.entityId });
        case HubEventType.Modified:
          return PatientReferralMessageStoreActions.LoadPatientReferralMessageRequest({ messageId: action.event.entityId });
        case HubEventType.Added:
          return PatientReferralMessageStoreActions.LoadPatientReferralMessageRequest({ messageId: action.event.entityId });
      }
    })
  );

  @Effect()
  loadPatientReferralMessageRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientReferralMessageStoreActions.LoadPatientReferralMessagesRequest),
    withLatestFrom(this._selectedPatientId),
    switchMap(([action, selectedPatientId]) => 
      this._patientClientService.fetchAll(
        this._patientClient.patient_GetMessages,
        [
          { value: selectedPatientId },
          { value: 20 },
          { isPage: true },
          { value: true }
        ],
        MessageDto
      ).pipe(
        map((result) => PatientReferralMessageStoreActions.LoadPatientReferralMessagesSuccess({ patientReferralMessages: result })),
        catchError((error) => of(PatientReferralMessageStoreActions.LoadPatientReferralMessagesFailure({ error })))
      )
    )
  );

  @Effect()
  loadMessageRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientReferralMessageStoreActions.LoadPatientReferralMessageRequest),
    withLatestFrom(this._selectedPatientId),
    switchMap(([action, selectedPatientId]) =>
      this._patientClient.patient_GetMessage(
        selectedPatientId,
        action.messageId
      ).pipe(
        map((result) => PatientReferralMessageStoreActions.LoadPatientReferralMessageSuccess({ patientReferralMessage: result })),
        catchError((error) => of(PatientReferralMessageStoreActions.LoadPatientReferralMessageFailure({ error })))
      )
    )
  );
}
