import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AuthStoreActions,
  AuthStoreSelectors,
  LocationsStoreSelectors,
  PatientsStoreActions,
  PatientsStoreSelectors,
  RootStoreState,
} from 'src/app/root-store';
import * as _ from 'lodash';

import {
  LocationClient,
  PatientSearchTypeEnum,
  ScheduleGridCalendarMonthDto,
  SearchClient,
  SearchContactDto,
  SearchRelationshipBaseDto,

} from 'src/app/shared/services/api.service';


@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.css']
})
export class PatientSearchComponent implements OnInit {
  @ViewChild('patientsearchinput', { static: false }) patientsearchinput: ElementRef;
  @ViewChild('patientListTrigger', { static: false }) patientListTrigger: MatMenuTrigger;
  @Output() getSerarchValue = new EventEmitter();

  patientSearchType: any="last";
  patientlistArr: any[] = [];
  locationsArr:any[] = [];
  noPatient: boolean = false;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _locationClient: LocationClient,
    private _searchClient: SearchClient

  ) { }

  ngOnInit() {
    this.getAllLocations();
  }

  ngAfterViewInit() {
    const keyup = fromEvent(this.patientsearchinput.nativeElement, 'keyup');
    keyup.pipe(
			map((i: any) => i.currentTarget.value),
			debounceTime(500)).subscribe(value => {
        this.noPatient = false;
				if (value.trim().length > 0) {
					this.getPatientList(value)
				} else {
          this.patientlistArr = [];
        }
			});
  }


  getPatientList(searchString) {
    let patientSearchTypeEnum: PatientSearchTypeEnum;
    switch (this.patientSearchType) {
      case ('id'):
        patientSearchTypeEnum = PatientSearchTypeEnum.PatientId;
        break;
      case ('first'):
        patientSearchTypeEnum = PatientSearchTypeEnum.First;
        break;
      default:
        patientSearchTypeEnum = PatientSearchTypeEnum.Last;
        break;
    }

    this._searchClient
      .search_GetInformationalSearchPatients(searchString, patientSearchTypeEnum)
      .pipe(take(1))
      .subscribe(
        (res) => {
          let sortBy = 'lastName';
          if (this.patientSearchType == 'first')
            sortBy = 'firstName';
          else if (this.patientSearchType == 'id')
            sortBy = 'id';

          this.patientlistArr = _.sortBy(res, [sortBy]);

          this.patientlistArr.map(patient => {
            patient.locationName = this.locationsArr.find(loc => loc.id === patient.locationId).shortName;
          })

          if(this.patientlistArr.length == 0){
            this.noPatient = true;
          }

        },
        (err) => console.log('searchPatient error:', err)
      );

  }

  getAllLocations() {
		this._locationClient.location_GetLocations(100, undefined, null).subscribe(
			(res) => {
				this.locationsArr = res.filter(location => location.isActive == true);
			},
			(err) => {
				console.log(err)
			}
		)
  }

  onSelectionPatient(id: number) {

    let patientModel = this.patientlistArr.find((patient) => patient.patientId == id);
    this.getSerarchValue.emit({"patientModel": patientModel});
    this.patientListTrigger.closeMenu();    
  }

}
