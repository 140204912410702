import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, filter, mapTo } from 'rxjs/operators';
import { SettingsClient } from 'src/app/shared/services/api.service';
import { RootStoreState } from '../..';
import * as StepGroupStoreActions from './actions';
import * as StepGroupStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class TreatmentStepGroupEffects {
  constructor(private store$: Store<RootStoreState.State>, private actions$: Actions, private settingsClient: SettingsClient) {}

  loadRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StepGroupStoreActions.LoadRequest),
      switchMap(() =>
        this.settingsClient.settings_GetStepGroups().pipe(
          map((result) => StepGroupStoreActions.LoadSuccess({ stepGroups: result })),
          catchError((error) => of(StepGroupStoreActions.LoadFailure({ err: error })))
        )
      )
    );
  });

  loadOnceRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StepGroupStoreActions.LoadOnceRequest),
      withLatestFrom(this.store$.select(StepGroupStoreSelectors.selectStepGroupState)),
      filter(([_, state]) => !state.hasLoaded),
      mapTo(StepGroupStoreActions.LoadRequest())
    );
  });
}
