import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, map, mergeMap, share, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { LocationClient } from 'src/app/shared/services/api.service';
import { RootStoreState } from '..';
import * as PatientTreatmentStoreActions from './actions';
import * as PatientTreatmentStoreSelectors from './selectors';
import { PatientTreatmentPlanStoreEntity, State } from './state';

@Injectable({ providedIn: 'root' })
export class PatientTreatmentStoreEffectsService {
  constructor(private _actions$: Actions, private _store$: Store<RootStoreState.State>, private _locationClient: LocationClient) {}

  loadPlansOnceRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStoreActions.LoadTreatmentsOnceRequest),
      withLatestFrom(this._store$.select(PatientTreatmentStoreSelectors.selectPatientTreatmentsState)),
      filter(([action, state]) => !state.isLoaded || (state.isLoaded && action.patientId != state.patientId)),
      map(([action, _]) => PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: action.locationId, patientId: action.patientId }))
    )
  );

  loadPlansRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStoreActions.LoadTreatmentsRequest),
      switchMap((action) =>
        this._locationClient.location_GetTreatments(action.locationId, action.patientId).pipe(
          take(1),
          map((result) => PatientTreatmentStoreActions.LoadTreatmentsSuccess({ plans: result })),
          catchError((err) => of(PatientTreatmentStoreActions.LoadTreatmentsFailure({ error: err })))
        )
      ),
      share()
    )
  );

  addStepRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStoreActions.AddStepsRequest),
      withLatestFrom(this._store$.select(PatientTreatmentStoreSelectors.selectPatientTreatmentsState)),
      switchMap(([action, state]) =>
        this._locationClient.location_PostSteps(state.locationId, state.patientId, action.treatmentId, action.steps).pipe(
          take(1),
          switchMap((_) => this._locationClient.location_GetTreatment(state.locationId, state.patientId, action.treatmentId).pipe(take(1))),
          map((result) => PatientTreatmentStoreActions.AddStepsSuccess({ plan: result })),
          catchError((error) => of(PatientTreatmentStoreActions.AddStepsFailure({ error: error })))
        )
      ),
      share()
    )
  );

  updateStepRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStoreActions.UpdateStepsRequest),
      withLatestFrom(this._store$.select(PatientTreatmentStoreSelectors.selectPatientTreatmentsState)),
      switchMap(([action, state]) =>
        forkJoin(
          action.steps.map((step) =>
            this._locationClient.location_PutStep(state.locationId, state.patientId, action.treatmentId, step.id, step).pipe(take(1))
          )
        ).pipe(
          take(1),
          switchMap((_) => this._locationClient.location_GetTreatment(state.locationId, state.patientId, action.treatmentId).pipe(take(1))),
          map((result) => PatientTreatmentStoreActions.UpdateStepsSuccess({ plan: result })),
          catchError((error) => of(PatientTreatmentStoreActions.UpdateStepsFailure({ error: error })))
        )
      ),
      share()
    )
  );

  deleteStepRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStoreActions.DeleteStepRequest),
      withLatestFrom(this._store$.select(PatientTreatmentStoreSelectors.selectPatientTreatmentsState)),
      switchMap(([action, state]) =>
        this._locationClient.location_DeleteStep(state.locationId, state.patientId, action.treatmentId, action.stepId).pipe(
          take(1),
          switchMap((_) => this._locationClient.location_GetTreatment(state.locationId, state.patientId, action.treatmentId).pipe(take(1))),
          map((result) => PatientTreatmentStoreActions.DeleteStepSuccess({ plan: result })),
          catchError((error) => of(PatientTreatmentStoreActions.DeleteStepFailure({ error: error })))
        )
      ),
      share()
    )
  );

  updateTreatmentCommentRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientTreatmentStoreActions.UpdateTreatmentCommentRequest),
      withLatestFrom(this._store$.select(PatientTreatmentStoreSelectors.selectPatientTreatmentsState)),
      //fetch treatment from store
      switchMap(([action, state]) =>
        this._store$.select(PatientTreatmentStoreSelectors.selectTreatmentPlan(action.treatmentId)).pipe(
          take(1),
          //update comment on treatment
          map((treatment) => {
            treatment.comments = action.comment;
            return treatment;
          }),
          //pass on state and treatment
          map((treatment) => <[State, PatientTreatmentPlanStoreEntity]>[state, treatment]),
        )
      ),
      //PUT treatment and GET new updated entity
      switchMap(([state, treatment]) =>
        this._locationClient.location_PutTreatment(state.locationId, state.patientId, treatment.id, treatment).pipe(
          take(1),
          switchMap((_) => this._locationClient.location_GetTreatment(state.locationId, state.patientId, treatment.id).pipe(take(1))),
          map((result) => PatientTreatmentStoreActions.UpdateTreatmentCommentSuccess({ plan: result })),
          catchError((error) => of(PatientTreatmentStoreActions.UpdateTreatmentCommentFailure({ error: error })))
        )
      ),
      share()
    )
  );
}
