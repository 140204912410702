import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FEATURE_KEY } from './featurekey';
import { State, featureAdapter } from './state';

export const STATE: MemoizedSelector<object, State> = createFeatureSelector<State>(FEATURE_KEY);
const {selectAll,selectEntities,selectIds,selectTotal} = featureAdapter.getSelectors(STATE);

export const allPatientLedgers = selectAll;
export const selectedPatientLedger = createSelector(STATE, state => state.selectedLedger);
export const selectedLedgerTransactions = createSelector(STATE, state => state.ledgerTransactions);
export const selectedLedgerId = createSelector(STATE, state => state.selectedLedgerId);
export const ledgerOptions = createSelector(STATE, state => state.ledgerOptions);
export const togglePatientInfo = createSelector(STATE, state => state.showPatientInfo);
export const loadError: MemoizedSelector<object, any> = createSelector(STATE, (state) => state.error);
export const selectPatientLedgersIsLoading: MemoizedSelector<object, boolean> = createSelector(STATE, (state: State) => state.isLoading);
