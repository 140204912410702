import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { PhotoEditorActions } from './PhotoEditorModels';
import { PhotoEditorData } from './PhotoEditorModels';

@Component({
  selector: 'photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PhotoEditorComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  photoImageTransform: ImageTransform = {};
  photoData: PhotoEditorData;
  isWorking: boolean = false;
  imageTransform_rotate: any = 0;
  canvasRotation: number = 0;
  scale: number = 1;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  @Output() editedImage = new EventEmitter<string>();

  constructor(
    private _dialogRef: MatDialogRef<PhotoEditorComponent>,
    private _snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: PhotoEditorData,
    private _cdr: ChangeDetectorRef
  ) {
    this.photoData = data;
  }

  ngOnInit() {
    this.isWorking = true;
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  close(): void {
    this._dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.editedImage.emit(event.base64);
    this._cdr.detectChanges();
  }

  imageLoaded() {
    this.photoImageTransform = {
      flipV: false,
      flipH: false,
      rotate: 0
    };
    this.openSnackBar("Image loaded", 'Ok');
    this.isWorking = false;
    this._cdr.detectChanges();
  }

  cropperReady() {
    // cropper ready
  }

  flipHorizontal(): void {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      flipH: !this.photoImageTransform.flipH
    };
    this._cdr.detectChanges();
  }

  flipVertical(): void {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      flipV: !this.photoImageTransform.flipV
    };
    this._cdr.detectChanges();
  }

  zoomOut() {
    this.scale -= .1;
    this.photoImageTransform = {
      ...this.photoImageTransform,
      scale: this.scale
    };
    this._cdr.detectChanges();
  }

  zoomIn() {
    this.scale += .1;
    this.photoImageTransform = {
      ...this.photoImageTransform,
      scale: this.scale
    };
    this._cdr.detectChanges();
  }

  loadImageFailed(): void {
    this.openSnackBar("Failed to load image", 'Ok');
    this.isWorking = false;
    this._cdr.detectChanges();
  }

  removePhoto(): void {
    this._dialogRef.close({ action: PhotoEditorActions.Remove })
  }

  savePhoto(): void {
    this._dialogRef.close({
      action: PhotoEditorActions.Save,
      file: base64ToFile(this.croppedImage),
      imageInfo: this.photoData.imageInfo
    })
  }

  reloadOriginal(): void {
    this.isWorking = true;
    this.photoData.locationUrl = this.photoData.locationOriginalUrl;
    this._cdr.detectChanges();
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
    this._cdr.detectChanges();
  }

  imageRotation(){
    this.photoImageTransform = {
      ...this.photoImageTransform,
      rotate: this.imageTransform_rotate
    };
    this._cdr.detectChanges();
  }

  rotateLeft(): void {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      rotate: this.photoImageTransform.rotate - 90
    };
    this.canvasRotation--;
  }

  rotateRight(): void {
    this.photoImageTransform = {
      ...this.photoImageTransform,
      rotate: this.photoImageTransform.rotate + 90
    };
    this.canvasRotation++;
  }


}
