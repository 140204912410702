import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatInputModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatDialogModule,
  MatListModule,
  MatIconModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatDatepickerModule
} from '@angular/material';
import { TabDirective } from '../directives/tab-selection.directive';
import { SimpleMeridianPipe } from '../pipe/simple-meridian.pipe';
import { TreatmentCardsComponent } from './treatment-cards/treatment-cards.component';
import { NotesModalComponent } from './notes-modal/notes-modal.component';
import { ReplaceNewLinePipe } from '../pipe/replace-new-line.pipe';
import { TreatmentCardMultipleAddModal } from './treatment-cards-appointment-modal/treatment-cards-appointment-modal';
import { ClickConfirmModule } from './click-confirm/click-confirm.module';
import { ElementsModule } from '../elements/elements.module';
import { MomentDateModule } from '../pipe/moment-date/moment-date.module';
import { SafeResourcePipe } from './card-loader/safe-resource.pipe';
import { SignatureDialogComponent } from './signature-dialog/signature-dialog.component';
import { DigitalSignatureModule } from '../elements/digital-signature/digital-signature.module';
import { DelayedHoverDirective } from '../directives/delayed-hover.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TextMaskModule } from 'angular2-text-mask';
import { PatientTreatmentSelectorDialogComponent } from './patient-treatment-selector/patient-treatment-selector-dialog.component';
import { TenantComponent } from './tenant/tenant.component';
import { LocationComponent } from './location/location.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { OrderPipe } from '../pipe/order.pipe';

@NgModule({
  declarations: [
    TreatmentCardsComponent,
    SimpleMeridianPipe,
    TabDirective,
    NotesModalComponent,
    ReplaceNewLinePipe,
    TreatmentCardMultipleAddModal,
    SafeResourcePipe,
    SignatureDialogComponent,
    DelayedHoverDirective,
    PatientTreatmentSelectorDialogComponent,
    TenantComponent,
    LocationComponent,
    OrderPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatListModule,
    ClickConfirmModule,
    ElementsModule,
    MomentDateModule,
    MatIconModule,
    MatProgressBarModule,
    MatTooltipModule,
    DragDropModule,
    FontAwesomeModule,
    TextMaskModule,
    ClickOutsideModule,
    MatDatepickerModule
  ],
  exports: [
    FormsModule,
    TreatmentCardsComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatTabsModule,
    DigitalSignatureModule,
    SafeResourcePipe,
    MatListModule,
    SimpleMeridianPipe,
    TabDirective,
    ReplaceNewLinePipe,
    DelayedHoverDirective,
    DragDropModule,
    FontAwesomeModule,
    TextMaskModule,
    PatientTreatmentSelectorDialogComponent,
    TenantComponent,
    LocationComponent,
    MatDatepickerModule,
    OrderPipe
  ],
  entryComponents: [
    NotesModalComponent,
    TreatmentCardMultipleAddModal,
    PatientTreatmentSelectorDialogComponent,
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
