import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { FormGroupDto, IFormGroupDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<FormGroupStoreEntity> = createEntityAdapter<FormGroupStoreEntity>({
  sortComparer: (a: FormGroupStoreEntity, b: FormGroupStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<FormGroupStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedFormGroupId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedFormGroupId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class FormGroupStoreEntity extends FormGroupDto {
  constructor(data?: IFormGroupDto) {
    super(data);
  }
}
