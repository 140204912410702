import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DENTIST_FEATURE_KEY } from './featurekey';
import { featureAdapter, DentistStoreEntity, State } from './state';

export const selectDentistsState: MemoizedSelector<object, State> = createFeatureSelector<State>(DENTIST_FEATURE_KEY);

export const getSelectedDentistId = createSelector(selectDentistsState, (state) => state.selectedDentistId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectDentistsState);
export const selectDentistIds = selectIds;
export const selectAllDentists = selectAll;
export const selectAllDentistEntities = selectEntities;

export const getSelectedDentist = createSelector(
  selectDentistsState,
  selectAllDentists,
  (state, allDentists: DentistStoreEntity[]) =>
    allDentists ? allDentists.find((rp) => rp.id === state.selectedDentistId) : null
);

export const selectDentistById = (id: number) =>
  createSelector(selectAllDentists, (allDentists: DentistStoreEntity[]) =>
    allDentists ? allDentists.find((rp) => rp.id === id) : null
  );

export const selectDentistsError: MemoizedSelector<object, any> = createSelector(selectDentistsState, (state) => state.error);

export const selectDentistsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectDentistsState, (state: State) => state.isLoading);
