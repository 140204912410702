import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';
import { PatientsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { ContactAddressDto2, ContactEmailDto2, ContactPhoneDto2, NotificationAddressDto, NotificationDto, NotificationEmailDto, NotificationPhoneDto, PatientClient, PatientNotifyTypeEnum, RelationshipDto, SecureAccessDto } from 'src/app/shared/services/api.service';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MessageDialog } from 'src/app/dialogs/message/message.dialog';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { FutureMessagesDialogComponent } from 'src/app/dialogs/future-messages-dialog/future-messages-dialog.component';

@Component({
  selector: 'app-patient-notification-modal',
  templateUrl: './patient-notification-modal.component.html',
  styleUrls: ['./patient-notification-modal.component.scss']
})
export class PatientNotificationModalComponent implements OnInit, OnDestroy {
  @Input() isNewPatient;
  @Input() submitclick;
  @Input() selectedPatientId;
  @Output() aftersave = new EventEmitter();
  @Output() hasDuplicateEmail = new EventEmitter();

  notifications: NotificationDto[] = [];
  relationships: RelationshipDto[];
  modified: boolean = false;
  dialog: DialogComponent;
  isWorking: boolean = false;

  PatientNotifyTypeEnum = PatientNotifyTypeEnum;

  @ViewChild('secureAccessDialog', {static: false}) secureAccessDialog:TemplateRef<any>;
  secureAccessDialogRef: MatDialogRef<any>;
  secureAccesses: SecureAccessDto[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  patientId: number;
  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientsClient: PatientClient,
    private matDialog: MatDialog
  ) {
    this.dialog = new DialogComponent(matDialog);
  }

  ngOnInit() {
    if (!this.selectedPatientId) {
      this.selectedPatientId$
        .pipe(
          takeUntil(this.destroy$),
          filter(patientId => !!patientId)
        ).subscribe((patientId: number) => {
          this.patientId = patientId;
          this.initializeNotifications();
        });
    }
    else {
      this.patientId = this.selectedPatientId;
      this.initializeNotifications();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  initializeNotifications(): void {
    this.notifications = null;
    this.modified = false;
    this.isWorking = true;
    combineLatest([
      this._patientsClient.patient_GetNotificationsByPatient(this.patientId),
      this._patientsClient.patient_GetRelationships(this.patientId)
    ])
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([notifications, relationships]) => {
        this.relationships = relationships;
        this.notifications = notifications;
        let emails: string[] = [];

        _.each(this.relationships, (r: RelationshipDto) => {
          let notification = this.notifications.find((x) => { return x.contactId == r.contactId });
          if (!notification) {
            notification = this.generateNotification(r);

            if (r.mobilePhone && r.mobilePhone.id > 0) {
              let phoneNotification: NotificationPhoneDto = this.generatePhoneNotification(r, r.mobilePhone);
              if (notification)
                notification.phones.push(phoneNotification);
            }

            if (r.alternatePhone1 && r.alternatePhone1.id > 0) {
              let phoneNotification: NotificationPhoneDto = this.generatePhoneNotification(r, r.alternatePhone1);
              if (notification)
                notification.phones.push(phoneNotification);
            }

            if (r.alternatePhone2 && r.alternatePhone2.id > 0) {
              let phoneNotification: NotificationPhoneDto = this.generatePhoneNotification(r, r.alternatePhone2);
              if (notification)
                notification.phones.push(phoneNotification);
            }

            if (r.primaryEmail && r.primaryEmail.id > 0) {
              let emailNotification: NotificationEmailDto = this.generateEmailNotification(r, r.primaryEmail);
              if (emailNotification)
                notification.emails.push(emailNotification);
            }

            if (r.primaryAddress && r.primaryAddress.id > 0) {
              let addressNotification: NotificationAddressDto = this.generateAddressNotification(r, r.primaryAddress);
              if (addressNotification)
                notification.addresses.push(addressNotification);
            }

            this.notifications.push(notification);
          }
        });

        this.notifications = _.sortBy(this.notifications, ['lastName', 'firstName']);

        emails = _.chain(this.notifications)
          .reduce((accumulator, value) => accumulator.concat(value.emails), [])
          .map('emailAddress')
          .value();

        let uniqueEmails = [] = _.uniq(emails);
        this.hasDuplicateEmail.emit(uniqueEmails.length != emails.length);
        this.isWorking = false;
      });
  }

  generateNotification(relationship: RelationshipDto): NotificationDto {
    let notification: NotificationDto = new NotificationDto();

    notification.patientId = relationship.patientId;
    notification.contactId = relationship.contactId;
    notification.title = relationship.title;
    notification.firstName = relationship.firstName;
    notification.lastName = relationship.lastName;
    notification.relationshipType = relationship.relationshipType;
    notification.relationshipTypeLabel = relationship.relationshipTypeLabel;
    notification.relationshipTypeAbbreviation = relationship.relationshipTypeCode;
    notification.phones = [];
    notification.emails = [];
    notification.addresses = [];

    return notification;
  }

  generateEmailNotification(relationship: RelationshipDto, contactEmail: ContactEmailDto2): NotificationEmailDto {
    let email = new NotificationEmailDto();

    email.contactEmailId = contactEmail.id;
    email.emailType = contactEmail.emailType;
    email.emailAddress = contactEmail.emailAddress;
    email.isSecureAccess = relationship.isSecureAccess;

    if(relationship.relationshipType == 'Patient') {
      email.forPatientForm = true;
    }

    return email;
  }

  generatePhoneNotification(relationship: RelationshipDto, contactPhone: ContactPhoneDto2): NotificationPhoneDto {
    let phone = new NotificationPhoneDto();

    phone.contactPhoneId = contactPhone.id;
    phone.phoneType = contactPhone.phoneType;
    phone.phoneNumber = contactPhone.number;

    return phone;
  }

  generateAddressNotification(relationship: RelationshipDto, contactAddress: ContactAddressDto2): NotificationAddressDto {
    let address = new NotificationAddressDto();

    address.contactAddressId = contactAddress.id;
    address.addressType = contactAddress.addressType;
    address.addressLine1 = contactAddress.addressLine1;
    address.addressLine2 = contactAddress.addressLine2;
    address.city = contactAddress.city;
    address.state = contactAddress.state;
    address.zip = contactAddress.zip;

    return address;
  }

  ngOnChanges(){
    if(this.submitclick){
      this.save();
    }
  }

  save() {
    this.modified = false;
    this.submitclick = false;
    this.isWorking = true;

    this.notifications.forEach((n: any) => { n.retriggerPatientStatus = !!this.isNewPatient; })

    this.secureAccesses = [];
    let secureAccesses_nonModal = [];

    let hasSecureAccesses = _.chain(this.notifications)
      .reduce((accumulator, value) => accumulator.concat(value.emails), [])
      .filter((item: NotificationEmailDto) => (item.isSecureAccess))
      .value();

    hasSecureAccesses.map((notificationEmail: any) => {
      let _secureAccessDto = new SecureAccessDto();

      _secureAccessDto.contactId = notificationEmail.contactId;
      _secureAccessDto.isSecureAccess = notificationEmail.isSecureAccess;
      _secureAccessDto.emailAddress = notificationEmail.emailAddress;

      if (notificationEmail.willShowSecureDialog) {
        _secureAccessDto.sendConfirmation = true;
        this.secureAccesses.push(_secureAccessDto);
      } else {
        secureAccesses_nonModal.push(_secureAccessDto);
      }
    });

    if (this.secureAccesses.length > 0) {
      this.secureAccessDialogRef = this.dialog.open(this.secureAccessDialog);
      this.secureAccessDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
        let willSaveSecureAccesses: boolean = !!resp;
        this.saveNotifications(this.secureAccesses, willSaveSecureAccesses);
      })
    } else {
      this.saveNotifications(secureAccesses_nonModal, true);
    }


  }

  selectAllEmailPhoneChanged(notification: NotificationEmailDto | NotificationPhoneDto): void {
    let isChecked: boolean = notification.forAppointments &&
      notification.forTreatment &&
      notification.forFinancial &&
      notification.forMarketing &&
      notification.forBirthday &&
      notification.forPatientForm;

    notification.forAppointments = !isChecked;
    notification.forTreatment = !isChecked;
    notification.forFinancial = !isChecked;
    notification.forMarketing = !isChecked;
    notification.forBirthday = !isChecked;
    notification.forPatientForm = !isChecked;

    this.modified = true;

    this.checkFutureMessages();
  }

  selectAllAddressChanged(notification: NotificationAddressDto): void {
    let isChecked: boolean = notification.forMarketing &&
      notification.forGifting;

    notification.forMarketing = !isChecked;
    notification.forGifting = !isChecked;

    this.modified = true;
  }

  selectCheckbox(notification, checkboxFor?:string){
    this.modified = true;

    setTimeout(() => {
      notification.selectAll = notification.forAppointments &&
        notification.forTreatment &&
        notification.forFinancial &&
        notification.forMarketing &&
        notification.forBirthday &&
        notification.forPatientForm;

      if(checkboxFor == 'Appointments'){
        this.checkFutureMessages();
      }
    });
  }

  checkFutureMessages(){
    this.isWorking = true;
    this._patientsClient.patient_GetMessages(this.patientId, undefined, -1).subscribe(resp => {
      let _messages = resp.filter(message => message.messageType != 'Notification');
      if (_messages.length > 0) {
        const dialogRef = this.matDialog.open(FutureMessagesDialogComponent, { data: { patientId: this.patientId } })
      }
      this.isWorking = false;
    }, err => {
      this.isWorking = false;
    });
  }

  saveNotifications(secureAccesses, willSaveSecureAccesses?: boolean) {
    combineLatest([
      this._patientsClient.patient_PostNotifications(this.patientId, this.notifications),
      willSaveSecureAccesses ? this._patientsClient.patient_PostContactSecureAccess(this.patientId, secureAccesses) : of(null)
    ])
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError((err, caught) => {
          this.modified = true;
          this.isWorking = false;
          let data = {
            message: err.message
          };

          if (err.error == 'Duplicate email.') {
            data.message = `You have selected to make this email secure. Another person already is using this
                email as a secure email. Please use another email.`;
          }

          this.dialog.open(MessageDialog, data);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.isWorking = false;
        this.aftersave.emit(result);
      });
  }

  onChangeSecureAccess(evt, notification){
    notification.willShowSecureDialog = evt.checked
  }

}
