import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { APPOINTMENT_COMPLIANCE_FEATURE_KEY } from './featurekey';
import { featureAdapter, AppointmentComplianceStoreEntity, State } from './state';

export const selectAppointmentCompliancesState: MemoizedSelector<object, State> = createFeatureSelector<State>(APPOINTMENT_COMPLIANCE_FEATURE_KEY);

export const getSelectedAppointmentComplianceId = createSelector(selectAppointmentCompliancesState, (state) => state.selectedAppointmentComplianceId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectAppointmentCompliancesState);
export const selectAppointmentComplianceIds = selectIds;
export const selectAllAppointmentCompliances = selectAll;
export const selectAllAppointmentCompliancesEntities = selectEntities;

export const getSelectedAppointmentCompliance = createSelector(
  selectAppointmentCompliancesState,
  selectAllAppointmentCompliances,
  (state, allAppointmentCompliances: AppointmentComplianceStoreEntity[]) =>
    allAppointmentCompliances ? allAppointmentCompliances.find((rp) => rp.id === state.selectedAppointmentComplianceId) : null
);

export const selectAppointmentComplianceById = (id: number) =>
  createSelector(selectAllAppointmentCompliances, (allAppointmentCompliances: AppointmentComplianceStoreEntity[]) =>
    allAppointmentCompliances ? allAppointmentCompliances.find((rp) => rp.id === id) : null
  );

export const selectAppointmentCompliancesError: MemoizedSelector<object, any> = createSelector(selectAppointmentCompliancesState, (state) => state.error);

export const selectAppointmentCompliancesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectAppointmentCompliancesState, (state: State) => state.isLoading);
