import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import emailMask from 'text-mask-addons/dist/emailMask';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';
/* import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component'; */
import { ConfirmDeleteDialogComponent } from 'src/app/admin-settings/operation-pages/referrals/confirm-delete-dialog/confirm-delete-dialog.component';
import { AddDialogComponent } from 'src/app/admin-settings/operation-pages/referrals/add-dialog/add-dialog.component';

import {
  ProfessionalGroupDto,
  ClinicDto,
  DentistClient,
  DentistDto2,
  ClinicDto2,
  ClinicClient
} from 'src/app/shared/services/api.service';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-professional-add',
  templateUrl: './professional-add.component.html',
  styleUrls: ['./professional-add.component.css']
})
export class ProfessionalAddComponent implements OnInit {

  doctorform: FormGroup;
  phonemask: any = ['(',/[0-9]/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,];
  emailMask = emailMask;
  groupData: any[] = [];

  selectedCheckboxarray: any[] = [];
  profGroupCheckboxError = false;
  selectedProfGroupName: string;
  professionalGroupDto: ProfessionalGroupDto[] = [];
  selectedLocArr: any[] = [];
  clinicsDto: ClinicDto[] = [];

  clinicSection:boolean = false;
  locationform: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ProfessionalAddComponent>,
    private formBuilder: FormBuilder,
    private _dentistClient: DentistClient,
    private _clinicClient: ClinicClient,
    private _snackbar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initDoctorFormGroup();
    this.initLocationFormGroup();
    this.initializeOptions();
    
  }

  initializeOptions() {
    this._dentistClient.dentist_GetOptions().subscribe(optionsResp => {
      this.groupData = [];
      this.professionalGroupDto = optionsResp['professionalGroups'];
      this.professionalGroupDto.forEach((x) => {
        this.groupData.push({ label: x.name, selected: false });
      });
      this.clinicsDto = optionsResp['clinics'];
      this.clinicSection = false;
    },
      err => {
        console.log(err)
      })
  }

  private initDoctorFormGroup() {
    this.doctorform = this.formBuilder.group({
      doctorname: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      designation: [''],
      birthday: ['', Validators.pattern('\\d{2}\\/\\d{2}\\/\\d{4}')],
      phone: ['', Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')],
      email: ['', Validators.email],
      notes: [''],
      nickName: [''],
      docTorTitle: ['Dr.'],
      doctorOfficeRole: [''],
      middleName: [''],
      isFavorite: ['']
    });
  }

  initLocationFormGroup() {
    this.locationform = this.formBuilder.group({
      locationName: ['', Validators.required],
      locationAddress1: [''],
      locationAddress2: [''],
      locationCity: [''],
      locationState: [''],
      locationZip: [''],
      locationPhone: ['', Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')],
      locationExt: [''],
      locationFax: ['', Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')],
      locationEmail: ['', Validators.email],
      locationNotes: [''],
      isFavorite: ['']
    });
  }


  dateMask() {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
    return {
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      keepCharPositions: true,
      pipe: autoCorrectedDatePipe,
    };
  }

  checkCheckBoxFn(index) {
    this.groupData[index].selected =
      this.groupData[index].selected === false ? true : false;
    this.selectedProfGroupName = this.groupData[index].label;
    if (this.groupData[index].selected === true) {
      this.selectedCheckboxarray.push(this.groupData[index].label);
    } else {
      this.selectedCheckboxarray.splice(
        this.selectedCheckboxarray.indexOf(this.groupData[index].label),
        1
      );
    }
    this.checkprofessionalGP();
  }

  checkprofessionalGP() {
    if (this.selectedCheckboxarray.length !== 0) {
      this.profGroupCheckboxError = false;
    } else {
      this.profGroupCheckboxError = true;
    }
  }

  toggleDoctorIsFav(){
    let isFavorite = this.doctorform.value.isFavorite == true ? false : true;

    this.doctorform.patchValue({
      isFavorite: isFavorite
    })
  }

  toggleLocationIsFav(){
    let isFavorite = this.locationform.value.isFavorite == true ? false : true;

    this.locationform.patchValue({
      isFavorite: isFavorite
    })
  }

  saveProfessional() {
    
    if (this.doctorform.invalid) {
      return;
    } else {
      let dentist:DentistDto2 = new DentistDto2();

      let doctorFormValue = this.doctorform.value;

      dentist.displayName = doctorFormValue.doctorname;
      dentist.firstName = doctorFormValue.firstname;
      dentist.middleName = doctorFormValue.middleName;
      dentist.lastName = doctorFormValue.lastname;
      dentist.degree = doctorFormValue.designation;
      dentist.nickname = doctorFormValue.nickName;
      dentist.title = doctorFormValue.docTorTitle;
      dentist.officeRole = doctorFormValue.doctorOfficeRole;
      dentist.dob = doctorFormValue.birthday ? moment(doctorFormValue.birthday).toDate() : null;
      dentist.mobileNumber = doctorFormValue.phone;
      dentist.email = doctorFormValue.email;
      dentist.memo = doctorFormValue.notes;
      dentist.isFavorite = doctorFormValue.isFavorite;
      dentist.professionGroups = this.getProfgroup(this.selectedCheckboxarray);
      dentist.isActive = true;
      dentist.clinics = this.selectedLocArr;
      
      this._dentistClient.dentist_PostDentist(dentist).subscribe(resp => {
        this._snackbar.open('Professional added sucessfully', 'Ok', {
          duration: 3000,
        })
      },
      err => {
        console.log(err)
      })

      this.dialogRef.close();
      
    }
  }

  private getProfgroup(profGroupArray: any): ProfessionalGroupDto[] {
    const profGroups = [];
    profGroupArray.forEach((element) => {
      profGroups.push(
        this.professionalGroupDto.find(
          (x) => x.name.toLowerCase() === element.toLowerCase()
        )
      );
    });
    return profGroups;
  }

  removefromList(indx) {
    this.openDialog(indx);
  }

  openDialog(indx): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.selectedLocArr.splice(indx, 1);
      }
    });
  }

  addExitLocationInProfForm() {
    this.dialog
      .open(AddDialogComponent, {
        data: { dataList: this.clinicsDto, title: 'Locations' },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.selectAnotherLocation(data);
        }
      });
  }

  selectAnotherLocation(locationItem) {
   this.selectedLocArr.push(locationItem);
  }

  createNewClinic(){
    this.clinicSection = true;
  }

  saveClinic(){

    if(this.locationform.valid){
      let locationFormValue = this.locationform.value;
      let clinic = new ClinicDto();
      clinic.name = locationFormValue.locationName
      clinic.addressLine1 = locationFormValue.locationAddress1;
      clinic.addressLine2 = locationFormValue.locationAddress2;
      clinic.city = locationFormValue.locationCity;
      clinic.state = locationFormValue.locationState;
      clinic.zip = locationFormValue.locationCity;
      clinic.phoneNumber = locationFormValue.locationPhone;
      clinic.faxNumber = locationFormValue.locationFax;
      clinic.email = locationFormValue.locationEmail;
      clinic.note = locationFormValue.locationNotes;
      clinic.isFavorite = locationFormValue.isFavorite;
      clinic.isActive = true;
      
      this._clinicClient.clinic_PostClinic(clinic).subscribe(resp => {
        this.selectedLocArr.push(resp);
        this.initializeOptions();
      })
    }

    
  }

}
