import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { IMessageDraftDto, MessageDraftDto } from "@shared/services/api.service";

export const featureAdapter: EntityAdapter<MessageDraftStoreEntity> = createEntityAdapter<MessageDraftStoreEntity>({
  selectId: (messageDraft) => messageDraft.id
});

export interface State extends EntityState<MessageDraftStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});

export class MessageDraftStoreEntity extends MessageDraftDto {
  constructor(data?: IMessageDraftDto) {
    super(data);
  }
}
