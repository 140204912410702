import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { PatientsStoreSelectors, PatientStoreEntity, ResponsiblePartyStoreSelectors, RootStoreState } from 'src/app/root-store';
import {
  CreatePatientDto,
  DentistReferralDto,
  DocumentTemplateDto,
  FormTypeDto,
  LocationClient,
  PatientClient,
  PatientDentistDto,
  SettingsClient,
  StationaryDto,
  TemplateTypeEnumDto,
  UserClient,
  UserDto2,
  UserTypeFilterEnum
} from 'src/app/shared/services/api.service';
import { IMessageBuilderRecipient } from './models';

@Injectable()
export class MessageBuilderService {
  private _load$ = new ReplaySubject();
  private _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  private _selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  readonly templates$: Observable<DocumentTemplateDto[]> = this._settingsClient.settings_GetTemplates(
      [TemplateTypeEnumDto.Email, TemplateTypeEnumDto.SMS]);
  readonly formTypes$: Observable<FormTypeDto[]> = this._settingsClient.settings_GetFormTypeAll(50).pipe(shareReplay(1));
  readonly stationary$: Observable<StationaryDto[]> = this._settingsClient.settings_GetStationaries().pipe(shareReplay(1));
  readonly clinics$: Observable<UserDto2[]> = this._userClient.user_GetUsers(UserTypeFilterEnum.Clinic, true, 100).pipe(shareReplay(1));

  //RESPONSIBLE PARTIES
  readonly responsiblePartiesContacts$: Observable<IMessageBuilderRecipient[]> = this._store$
    .select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties)
    .pipe(
      map((responsibleParties) =>
        responsibleParties.map(
          (rp) => {
            let recipient: IMessageBuilderRecipient = {
              id: rp.contactId,
              prefix: rp.relationshipTypeCode,
              name: `${rp.firstName} ${rp.lastName}`,
              phone: rp.mobilePhone && rp.mobilePhone.number,
              email: rp.primaryEmail && rp.primaryEmail.emailAddress,
              addressLine1: rp.primaryAddress && rp.primaryAddress.addressLine1,
              addressLine2: rp.primaryAddress && rp.primaryAddress.addressLine2,
              city: rp.primaryAddress && rp.primaryAddress.city,
              state: rp.primaryAddress && rp.primaryAddress.state,
              zip: rp.primaryAddress && rp.primaryAddress.zip,
              type: 'contact',
              phones: [],
              preferredContactMethod: rp.preferredContactMethod,
              contactPhoneEmailId: rp.primaryEmail && rp.primaryEmail.id,
              isSecureAccess: rp.isSecureAccess
            }

            if (rp.mobilePhone)
              recipient.phones.push(rp.mobilePhone);

            if (rp.alternatePhone1)
              recipient.phones.push(rp.alternatePhone1);

            if (rp.alternatePhone2)
              recipient.phones.push(rp.alternatePhone2);

            if (recipient.phones[0])
              recipient.phone = recipient.phones[0].number;

            return recipient;
          }
        )
      )
    );

  //PATIENT'S DENTIST
  private _patientDentist$: Observable<PatientDentistDto> = combineLatest([this._selectedPatientId$, this._load$]).pipe(
    map(([patientId]) => patientId),
    filter((patientId) => !!patientId),
    switchMap((patientId) =>
      this._patientClient.patient_GetPatientDentists(patientId).pipe(
        map((dentists) => (dentists.length > 0 ? dentists.sort((a, b) => b.createdWhen.getTime() - a.createdWhen.getTime())[0] : null)),
        take(1)
      )
    ),
    shareReplay(1)
  );
  readonly patientDentistContact$: Observable<IMessageBuilderRecipient> = this._patientDentist$.pipe(
    map((d) =>
      !d
        ? null
        : <IMessageBuilderRecipient>{
            id: d.dentist.id,
            prefix: 'D',
            name: `${d.dentist.firstName} ${d.dentist.lastName}`,
            suffix: `/ ${d.clinic.name}`,
            email: d.dentist.email ? d.dentist.email : d.clinic.email,
            phone: d.dentist.mobileNumber ? d.dentist.mobileNumber : d.clinic.phoneNumber,
            type: 'dentist',
          }
    )
  );

  //PATIENT PROFESSIONAL REFERRAL
  private _patientReferredBy$: Observable<CreatePatientDto> = combineLatest([this._selectedPatient$, this._load$]).pipe(
    map(([patientId]) => patientId),
    filter((patient) => !!patient),
    switchMap((patient) => {
      if (!patient.referringPatientId) return of(null);
      else
        return this._patientClient
          .patient_GetPatient(patient.referringPatientId)
          .pipe(
            switchMap((referringPatient) => this._locationClient.location_GetPatient(referringPatient.locationId, referringPatient.id).pipe(take(1)))
          );
    }),
    shareReplay(1)
  );
  readonly patientReferredByContact$ = this._patientReferredBy$.pipe(
    map((referral) =>
      !!referral
        ? <IMessageBuilderRecipient>{
            id: referral.contactId,
            prefix: 'R',
            name: `${referral.firstName} ${referral.lastName}`,
            phone: referral.mobilePhone.number,
            email: referral.primaryEmail.emailAddress,
            type: 'contact',
          }
        : null
    )
  );

  //PATIENT PROFESSIONAL OUT REFERRAL
  private _patientProfessionalReferal$: Observable<DentistReferralDto[]> = combineLatest([this._selectedPatientId$, this._load$]).pipe(
    map(([patientId]) => patientId),
    filter((patientId) => !!patientId),
    switchMap((patientId) => this._patientClient.patient_GetDentistReferrals(patientId).pipe(take(1))),
    shareReplay(1)
  );
  readonly patientProfessionalReferralContact$ = this._patientProfessionalReferal$.pipe(
    map((referrals) => {
      return referrals.map(
        (r) =>
          <IMessageBuilderRecipient>{
            id: r.dentistId,
            prefix: 'R-D',
            name: r.dentistName,
            suffix: r.clinicName,
            email: r.dentistEmail,
            phone: r.dentistPhone,
            addressLine1: r.clinicAddressLine1,
            addressLine2: r.clinicAddressLine2,
            city: r.clinicCity,
            state: r.clinicState,
            zip: r.clinicZip,
            type: 'dentist',
          }
      );
    })
  );

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _settingsClient: SettingsClient,
    private _locationClient: LocationClient,
    private _patientClient: PatientClient,
    private _userClient: UserClient,
  ) {}

  load() {
    this._load$.next();
  }
}
