import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { PATIENT_STATUS_GROUP_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientStatusGroupStoreEntity, State } from './state';

export const selectPatientStatusGroupsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_STATUS_GROUP_FEATURE_KEY);

export const getSelectedPatientStatusGroupId = createSelector(selectPatientStatusGroupsState, (state) => state.selectedPatientStatusGroupId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientStatusGroupsState);
export const selectPatientStatusGroupIds = selectIds;
export const selectAllPatientStatusGroups = selectAll;
export const selectAllPatientStatusGroupEntities = selectEntities;

export const getSelectedPatientStatusGroup = createSelector(
  selectPatientStatusGroupsState,
  selectAllPatientStatusGroups,
  (state, allPatientStatusGroups: PatientStatusGroupStoreEntity[]) =>
    allPatientStatusGroups ? allPatientStatusGroups.find((rp) => rp.id === state.selectedPatientStatusGroupId) : null
);

export const selectPatientStatusGroupById = (id: number) =>
  createSelector(selectAllPatientStatusGroups, (allPatientStatusGroups: PatientStatusGroupStoreEntity[]) =>
    allPatientStatusGroups ? allPatientStatusGroups.find((rp) => rp.id === id) : null
  );

export const selectPatientStatusGroupsError: MemoizedSelector<object, any> = createSelector(selectPatientStatusGroupsState, (state) => state.error);

export const selectPatientStatusGroupsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientStatusGroupsState, (state: State) => state.isLoading);

export const selectFlattenedTreatmentStatuses = createSelector(selectAllPatientStatusGroups, (groups) => _.chain(groups).map((g) => g.patientStatuses).flatten().value())
