import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { take } from 'rxjs/operators';
import { FileDto, FolderDto, PatientClient } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss'],
})
export class NewFolderDialogComponent implements OnInit {
  folderName: string;
  isWorking: boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: NewFolderDialogData,
    private _bottomSheetRef: MatBottomSheetRef<NewFolderDialogComponent>,
    private _patientClient: PatientClient
  ) {}

  ngOnInit() {}

  addFolder() {
    const folder = new FolderDto({
      id: 0,
      name: this.folderName,
      parentId: this._data.parentId,
      childrenCount: 0,
    });
    this.isWorking = true;
    this._patientClient
      .patient_PostFolder(this._data.patientId, folder)
      .pipe(take(1))
      .subscribe((result) => {
        this.isWorking = false;
        this.done(this._data.parentId, result);
      }, err => this.isWorking = false);
  }

  done(parentId: number, folder: FolderDto) {
    this._bottomSheetRef.dismiss([parentId, folder]);
  }
}

export interface NewFolderDialogData {
  parentId: number;
  patientId: number;
}
