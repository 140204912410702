import { Component, OnInit, Inject, ViewChild,  TemplateRef} from '@angular/core';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { take, takeUntil, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { 
  PatientsStoreSelectors, 
  RootStoreState, 
} from 'src/app/root-store';

import {
  PatientClient,
  MarketingOrderDto,
  MarketingClient,
  MarketingPackageDto
} from 'src/app/shared/services/api.service';

import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-gifts-front',
  templateUrl: './gifts-front.component.html',
  styleUrls: ['./gifts-front.component.css']
})
export class GiftsFrontComponent implements OnInit {
  patient$ = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  patient;
  isWorking:boolean = false;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  giftsArr: MarketingOrderDto[] = [];
  marketingPackages:MarketingPackageDto[] = [];
  @ViewChild('deleteTemplate', { static: true }) deleteTemplate: TemplateRef<any>;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private patientClient:PatientClient,
    private marketingClient:MarketingClient,
    private matDialog: MatDialog,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit() {

    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe(resp => {
      this.getGifts();
    });


    this.patient$.pipe(filter(patient => !!patient)).subscribe(patient => {
      this.patient = patient;
    })

    this.getMarketingPackages();
  }

  fliptoBack(){
    this.data.flip.next(<IFlipEvent>{ payload: {
      patientId: this.patient.id
    }, side: this.data.side });
  }

  getGifts(){
    this.isWorking = true;
    this.patient$.pipe(filter(patient => !!patient)).subscribe(patient => {
      this.patientClient.patient_GetGifts(patient.id).subscribe(resp => {
        this.giftsArr = _.orderBy(resp, 'orderDate', 'desc')
        this.isWorking = false;
      },
      err => {
        this.isWorking = false;
        this.openSnackBar(err.message, "OK");
      })
    })
    
  }

  getMarketingPackages(){
    this.marketingClient.marketing_GetMarketingPackages().subscribe(resp => {
      this.marketingPackages = resp;
      this.getGifts();
    },
    err => {
      this.openSnackBar(err.message, "OK");
    })
  }

  getPackageById(marketingGiftId){
    let marketingPackage = this.marketingPackages.find(gift => gift.id == marketingGiftId);
    if(marketingPackage){
      return marketingPackage;
    }

    return null;
  }

  editGift(gift){
    this.data.flip.next(<IFlipEvent>{ payload: {
      patientId: this.patient.id,
      gift: gift
    }, side: this.data.side });
  }

  onCancelGift(giftItem:MarketingOrderDto){

    const cancelDeleteRef = this.matDialog.open(this.deleteTemplate)
    cancelDeleteRef.afterClosed().pipe(take(1)).subscribe(resp => {
      this.isWorking = true;
      if(resp){
        this.patientClient.patient_PostGiftCancel(giftItem.patient.id, giftItem.id).pipe(take(1)).subscribe(resp => {
          this.isWorking = false;
          this.openSnackBar("Cancelled sucessfully!", "");
          this.getGifts();
        },
        err => {
          this.isWorking = false;
          this.openSnackBar(err.message, "OK");
        })
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

}
