import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

import { environment } from 'src/environments/environment';

const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
const postHttpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'}), observe: 'response' as 'body'};

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
	
	private BASE_URL = environment.apiUrl;
	
	constructor(private http: HttpClient) { }
	
	
  async postApiData(apiurl, data) {

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(this.BASE_URL + apiurl, data, postHttpOptions)
        .subscribe(
          async res => {
            resolve(res);
            
          },
          async err => {
            reject(err);
            
          }
        );
    });
  }
  
  async getApiData(apiUrl: string) {
	  
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.BASE_URL + apiUrl, httpOptions ).subscribe(
        async res => {
          resolve(res);
        },
        async err => {
          reject(err);
        }
      );
    });
  }
  
  async putApiData(apiurl, data, putHttpOptions) {

    return new Promise((resolve, reject) => {
      this.http
        .put<any>(this.BASE_URL + apiurl, data, putHttpOptions)
        .subscribe(
          async res => {
            resolve(res);
            
          },
          async err => {
            reject(err);
            
          }
        );
    });
  }
  
  private formatData(data) {
    let returnData = '';
    let count = 0;
    for (const i in data) {
      if (count === 0) {
        returnData += i + '=' + data[i];
      } else {
        returnData += '&' + i + '=' + data[i];
      }
      count = count + 1;
    }
    return returnData;
  }
}
