import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { TwainClient, StorageContentTypeEnum, HubEventArea, HubEventType } from 'src/app/shared/services/api.service';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import { State } from '../root-state';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import * as TwainStoreActions from './actions';
import * as TwainStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class TwainStoreEffects {
  constructor(private store$: Store<State>, private actions$: Actions, private _twainClient: TwainClient) {}

  // Load OnDeck when user logs in
  loadOnLoginRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStoreActions.LoginSuccess, SignalRHubStoreActions.HubStarted),
      withLatestFrom(this.store$.select(TwainStoreSelectors.selectAllTwain), this.store$.select(SignalRHubStoreSelectors.selectHubConnectionStatus)),
      filter(([_, twains, status]) => status == HubConnectionState.Connected),
      mapTo(TwainStoreActions.LoadRequest({}))
    )
  );

  loadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwainStoreActions.LoadRequest),
      switchMap((action) => {
        if (action.twainId == null) {
          return this._twainClient.twain_GetTwains().pipe(
            map((twains) => TwainStoreActions.LoadSuccess({ entries: twains })),
            catchError((err) => of(TwainStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        } else {
          return this._twainClient.twain_GetTwain(action.twainId).pipe(
            map((twain) => TwainStoreActions.LoadSuccess({ entries: twain })),
            catchError((err) => of(TwainStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        }
      })
    )
  );

  // Listen for updates from hub
  twainUpdateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      filter(
        (action) =>
          action.event.eventArea == HubEventArea.TwainEvent &&
          action.event.entityType == TwainClient.TwainDto
      ),
      map((action) =>
        action.event.eventType != HubEventType.Removed
          ? TwainStoreActions.LoadRequest({ twainId: action.event.entityId })
          : TwainStoreActions.Remove({ twainId: action.event.entityId })
      ),
    )
  );
}
