import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { concatMap, expand, map, tap, toArray } from 'rxjs/operators';
import { API_BASE_URL, ClinicClient } from '../api.service';
import { FetchAllParameter } from './client.model';

@Injectable({ providedIn: 'root' })
export class ClinicClientService extends ClinicClient {
  private endpoint: (...args: any[]) => Observable<any[]>;
  private params: FetchAllParameter[];

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super(http, baseUrl);
  }

  private fetchPage(page = 0) {
    let args: any[] = [];
    _.each(this.params, (p: FetchAllParameter) => {
      if (p.isPage)
        args.push(page);
      else
        args.push(p.value);
    });

    return this.endpoint(...args).pipe(
      tap(() => console.log(`-> fetched page ${page}`)),
      map(ret => {
        return {
          items: ret,
          nextPage: ret.length > 0 ? ++page : undefined,
        }
      })
  );
}

  public fetchAll(
    endpoint: (...args: any[]) => Observable<any[]>,
    params: FetchAllParameter[],
    t: any
  ): Observable<typeof t[]> {
    this.endpoint = endpoint;
    this.params = params;

    const list = this.fetchPage().pipe(
      expand(({ nextPage }) => nextPage ? this.fetchPage(nextPage) : EMPTY),
      concatMap(({ items }) => items),
      toArray(),
    );

    return list;
  }
}
