import { Action, createReducer, on } from "@ngrx/store";
import * as PatientFormStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PatientFormStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPatientFormId: null })),
  on(PatientFormStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patientForms, { ...s, isLoading: false, error: null })
  }),
  on(PatientFormStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(PatientFormStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientFormStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.patientForm, { ...state, isLoading: false, error: null })
  )),
  on(PatientFormStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(PatientFormStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPatientFormId: null })),
  on(PatientFormStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedPatientFormId: action.patientForm && action.patientForm.id })),
  on(PatientFormStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(PatientFormStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(PatientFormStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedPatientFormId: null })),
  on(PatientFormStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(PatientFormStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientFormStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.patientForm, { ...state, isLoading: false, error: null })
  )),
  on(PatientFormStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD MULTIPLE
  on(PatientFormStoreActions.AddMultipleRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientFormStoreActions.AddMultipleSuccess, (state, action) => (
    featureAdapter.upsertMany(action.patientForms, { ...state, isLoading: false, error: null })
  )),
  on(PatientFormStoreActions.AddMultipleFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(PatientFormStoreActions.DeleteRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPatientFormId: state.selectedPatientFormId === action.id ? null : state.selectedPatientFormId })),
  on(PatientFormStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  ),
  on(PatientFormStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(PatientFormStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PatientFormStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.patientForm, { ...state, isLoading: false, error: null })
  ),
  on(PatientFormStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
