import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { EvolutionColors } from 'src/app/shared/enums';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { MatSelectModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, MatTooltipModule } from '@angular/material';
import { RxRequestsComponent } from './rx-requests.component';
import { DentistClinicSearchModule } from '../../dentist-clinic-search/dentist-clinic-search.module';
import { RxRequestBackComponent } from './rx-request-back/rx-request-back.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@CardModule({
  selector: 'rx-requests',
  front: RxRequestsComponent,
  back: RxRequestBackComponent,
  header: 'Rx Request',
  width: 800,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faPrescription', actionName: '', isFaIcon: true}],
  order: 80,
  color: EvolutionColors.Orange,
})


@NgModule({
  declarations: [RxRequestsComponent, RxRequestBackComponent],
  entryComponents: [RxRequestsComponent, RxRequestBackComponent],
  imports: [
    CommonModule,
    MatSelectModule, 
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    FormsModule, 
    ReactiveFormsModule,
    DentistClinicSearchModule,
    AngularEditorModule,
    FontAwesomeModule,
    MatAutocompleteModule
  ]
})
export class RxRequestsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
