import { InjectionToken, EventEmitter } from "@angular/core";
import { IFlipEvent } from "./IFlipEvent";
import { ICardSide } from "./ICardSide";
import { ICardActionEvent } from "./ICardActionEvent";
import { Observable, ReplaySubject } from 'rxjs';
import { ISendEvent } from "./ISendEvent";

export interface ICardData<T = any, T2 = any> {
  side: ICardSide;
  incoming: Observable<T>;
  flip: ReplaySubject<IFlipEvent<T2>>;
  action: EventEmitter<ICardActionEvent>;
  backgroundColor: string;
  send: ReplaySubject<ISendEvent<T2>>;
}

export const CARD_INJECTION_TOKEN = 'CARD_DATA';
export const CARD_DATA = new InjectionToken<ICardData>(CARD_INJECTION_TOKEN);
