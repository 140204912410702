import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatMenuModule
} from '@angular/material';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { PatientNotificationModalComponent } from './patient-notification-modal.component';

@NgModule({
  declarations: [
    PatientNotificationModalComponent
  ],
  entryComponents: [
    PatientNotificationModalComponent
  ],
  exports: [
    PatientNotificationModalComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    MatMenuModule
  ]
})
export class PatientNotificationModalModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
