import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TWAIN_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const selectTwainState: MemoizedSelector<object, State> = createFeatureSelector<State>(TWAIN_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectTwainState);
export const selectTwainIds = selectIds;
export const selectAllTwain = selectAll;
export const selectAllTwainEntities = selectEntities;

export const selectTwainsError: MemoizedSelector<object, any> = createSelector(selectTwainState, (state) => state.error);

export const selectTwainsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectTwainState, (state) => state.isLoading);
