import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {
    LocationsStoreSelectors,
    PatientsStoreSelectors,
  RootStoreState
} from 'src/app/root-store';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ImageDto, PatientClient } from '../../../shared/services/api.service';

@Component({
  selector: 'app-panoramic-viewer',
  templateUrl: './panoramic-viewer.component.html',
  styleUrls: ['./panoramic-viewer.component.css']
})
export class PanoramicViewerComponent implements OnInit {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  panoramicImages: ImageDto[];
  selectedImageTop: ImageDto;
  selectedImageBottom: ImageDto;
  isWorking: boolean;
  defaultImageId: number;
  @ViewChild('fullScreen', { static: false }) fullScreen: ElementRef;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    private _actions$: Actions,
    private _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.defaultImageId = this.getDefaultPanoramicImageId();
    sessionStorage.removeItem('defaultPanoramicImage');
    this.selectedPatientId$
      .pipe(
        takeUntil(this._destroy$),
        filter((id) => !!id)
      )
      .subscribe((patientId) => {
        this.isWorking = true;
        this.selectedImageTop = null;
        this.selectedImageBottom = null;
        this._patientClient.patient_GetPanoramicImaging(patientId)
          .pipe(
            take(1),
            takeUntil(this._destroy$)
          )
          .subscribe((x) => {
            this.panoramicImages = x;
            if (this.panoramicImages) {
              let defaultIndex: number = this.defaultImageId ? this.panoramicImages.findIndex((x) => x.id == this.defaultImageId) : null;
              if (defaultIndex && this.panoramicImages[defaultIndex]) {
                this.selectedImageTop = this.panoramicImages[defaultIndex];
                this.selectedImageBottom = this.panoramicImages[defaultIndex];
              }
              else {
                this.selectedImageTop = this.panoramicImages[0];
                if (this.panoramicImages[1])
                  this.selectedImageBottom = this.panoramicImages[1];
              }
            }
            this.isWorking = false;
          });
      });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  getDefaultPanoramicImageId(): number {
    try {
      return JSON.parse(sessionStorage.getItem('defaultPanoramicImage'));
    } catch (e) {
      return null;
    }
  }

  showFullScreen(): void {
    const elem = this.fullScreen.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  imageSelector(id: number, isTop: boolean, isNext: boolean): void {
    let currentIndex = this.panoramicImages.findIndex((x) => x.id == id);
    if (isNext)
      currentIndex++;
    else
      currentIndex--;

    if (currentIndex < 0 || currentIndex >= this.panoramicImages.length) return;

    if (isTop)
      this.selectedImageTop = this.panoramicImages[currentIndex];
    else
      this.selectedImageBottom = this.panoramicImages[currentIndex];
  }
}
