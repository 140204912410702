import { SafeHtml } from '@angular/platform-browser';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IMessageDto, MessageDto } from 'src/app/shared/services/api.service';

export class PatientMessageStoreEntity extends MessageDto {
  safeHtml?: SafeHtml;
  canEdit?: boolean;
  canDelete?: boolean;
  constructor(data?: IMessageDto) {
    super(data);
  }
}

export const featureAdapter = createEntityAdapter<PatientMessageStoreEntity>({
  selectId: (model) => model.id,
  sortComparer: (a,b) => (a.delaySendDate && a.delaySendDate.valueOf()) - (b.delaySendDate && b.delaySendDate.valueOf())
});

export interface State extends EntityState<PatientMessageStoreEntity> {
  previousEOF?: boolean;
  previousPageNumber?: number;
  futureEOF?: boolean;
  futurePageNumber?: number;
  pageSize?: number;
  patientId?: number;
  isLoading?: boolean;
  copiedMessage?: MessageDto;
}

export const initialState: State = featureAdapter.getInitialState({
  previousEOF: false,
  previousPageNumber: 0,
  futureEOF: false,
  futurePageNumber: 0,
  pageSize: 6,
  patientId: null,
  isLoading: false,
  copiedMessage: null
});
