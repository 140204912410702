import { createReducer, on, Action } from '@ngrx/store';
import { initialState, State, featureAdapter } from './state';
import * as MessageDraftStoreActions from './actions';

const reducer = createReducer(
  initialState,
  on(MessageDraftStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true })),
  on(MessageDraftStoreActions.LoadSuccess, (state, action) =>
    Array.isArray(action.entries)
      ? featureAdapter.upsertMany(action.entries, featureAdapter.removeAll({ ...state, isLoading: false }))
      : featureAdapter.upsertOne(action.entries, { ...state, isLoading: false })
  ),
  on(MessageDraftStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),

  on(MessageDraftStoreActions.DeleteMessageDraftRequest, (state, action) => ({ ...state, isLoading: true })),
  on(MessageDraftStoreActions.DeleteMessageDraftSuccess, (state, action) => ({ ...state, isLoading: false })),
  on(MessageDraftStoreActions.DeleteMessageDraftFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),

  on(MessageDraftStoreActions.Remove, (state, action) => featureAdapter.removeOne(action.messageDraftId, { ...state }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
