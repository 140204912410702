import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss'],
})
export class IconSelectorComponent {
  @Input() icons: IconSelectorModel[];
  @Input() label: string = 'choose icon';
  @Input() model: string;
  @Input() colorCode: string = '#3f91cd';
  @Input() disabled = false;
  @Output() selectionChange = new EventEmitter();

  onSelectionChange(iconCode: string) {
    if (!this.disabled) {
      this.model = iconCode;
      this.selectionChange.emit(this.model);
    }
  }
}

export interface IconSelectorModel {
  code: string;
  sortOrder: number;
}
