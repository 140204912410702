import { createAction, props } from "@ngrx/store";
import { CephalometricStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Cephalometric] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[Cephalometric] Load Success',
  props<{ cephalometrics: CephalometricStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Cephalometric] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Cephalometric] Add Request',
  props<{ cephalometric: CephalometricStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Cephalometric] Add Success',
  props<{ cephalometric: CephalometricStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Cephalometric] Add Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Cephalometric] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Cephalometric] Select Success',
  props<{ cephalometric: CephalometricStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Cephalometric] Select Failure',
  props<{ error: any; }>()
);


//DESELECT
export const DeselectRequest = createAction(
  '[Cephalometric] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Cephalometric] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Cephalometric] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Cephalometric] Reset Error'
);
