import { createAction, props } from '@ngrx/store';
import { PatientMessageStoreEntity } from './state';
import { CreateReminderDto, MessageDto, CreateMessageDto, CreateFollowUpDto, ProblemDetails, ReplyMessageDto } from 'src/app/shared/services/api.service';

export const ResetRequest = createAction('[Patient Messages] Reset Request', props<{ patientId?: number}>());

export const SetPatientRequest = createAction('[Patient Messages] SetPatient Request', props<{ patientId: number }>());
export const SetPatientSuccess = createAction('[Patient Messages] SetPatient Success', props<{ patientId: number }>());

export const LoadNextPageRequest = createAction('[Patient Messages] LoadNextPage Request');
export const LoadNextFuturePageRequest = createAction('[Patient Messages] LoadNextFuturePage Request');

export const LoadPageRequest = createAction('[Patient Messages] LoadPage Request', props<{ page: number; pageSize?: number; }>());
export const LoadPageSuccess = createAction('[Patient Messages] LoadPage Success', props<{ messages: PatientMessageStoreEntity[]; previousEof: boolean; futureEof: boolean }>());
export const LoadPageFailure = createAction('[Patient Messages] LoadPage Failure', props<{ error: any }>());

export const AddReminderRequest = createAction('[Patient Messages] AddReminder Request', props<{ reminder: CreateReminderDto }>());
export const AddReminderSuccess = createAction('[Patient Messages] AddReminder Success', props<{ messages: MessageDto[] }>());
export const AddReminderFailure = createAction('[Patient Messages] AddReminder Failure', props<{ error: any }>());

export const AddMessageRequest = createAction('[Patient Messages] AddMessage Request', props<{ message: CreateMessageDto, messageDraftId?: number }>());
export const AddMessageSuccess = createAction('[Patient Messages] AddMessage Success', props<{ message: MessageDto, messageDraftId?: number }>());
export const AddMessageFailure = createAction('[Patient Messages] AddMessage Failure', props<{ error: any }>());

export const AddFollowUpRequest = createAction('[Patient Messages] AddFollowUp Request', props<{ followUp: CreateFollowUpDto }>());
export const AddFollowUpSuccess = createAction('[Patient Messages] AddFollowUp Success', props<{ message: MessageDto }>());
export const AddFollowUpFailure = createAction('[Patient Messages] AddFollowUp Failure', props<{ error: any }>());

export const DeleteMessageRequest = createAction('[Patient Messages] DeleteMessage Request', props<{ patientId: number; messageId: number; deleteThread?: boolean }>());
export const DeleteMessageSuccess = createAction('[Patient Messages] DeleteMessage Success', props<{ patientId: number; messageId: number; deleteThread?: boolean }>());
export const DeleteMessageFailure = createAction('[Patient Messages] DeleteMessage Failure', props<{ error: ProblemDetails | any }>());

export const MarkAsReadRequest = createAction('[Patient Messages] MarkAsRead Request', props<{ id: number; patientId: number; }>());
export const MarkAsReadSuccess = createAction('[Patient Messages] MarkAsRead Success', props<{ id: number; patientId: number; }>());
export const MarkAsReadFailure = createAction('[Patient Messages] MarkAsRead Failure', props<{ error: any }>());

export const MessagedRemoved = createAction('[Patient Messages] RemoveMessage', props<{ messageId: number; }>());

export const LoadMessageRequest = createAction('[Patient Messages] LoadMessage Request', props<{ patientId: number; messageId: number;}>());
export const LoadMessageSuccess = createAction('[Patient Messages] LoadMessage Success', props<{ patientId: number; message: MessageDto;}>());
export const LoadMessageFailure = createAction('[Patient Messages] LoadMessage Failure', props<{ error: ProblemDetails | any }>());

export const AddReplyMessageRequest = createAction('[Patient Messages] AddReplyMessage Request', props<{ replyMessage: ReplyMessageDto }>());
export const AddReplyMessageSuccess = createAction('[Patient Messages] AddReplyMessage Success', props<{ message: MessageDto }>());
export const AddReplyMessageFailure = createAction('[Patient Messages] AddReplyMessage Failure', props<{ error: any }>());

export const CopyMessage = createAction(
  '[Patient Messages] Message Copied',
  props<MessageDto>()
)

export const UpdateCopiedMessage = createAction(
  '[Patient Messages] Update Copied Message',
  props<{ copiedMessage: MessageDto }>()
)
