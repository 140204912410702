import { Action, createReducer, on } from "@ngrx/store";
import * as FormGroupStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(FormGroupStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedFormGroupId: null })),
  on(FormGroupStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.formGroups, { ...s, isLoading: false, error: null })
  }),
  on(FormGroupStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(FormGroupStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FormGroupStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.formGroup, { ...state, isLoading: false, error: null })
  )),
  on(FormGroupStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(FormGroupStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedFormGroupId: null  })),
  on(FormGroupStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedFormGroupId: action.formGroup && action.formGroup.id })),
  on(FormGroupStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(FormGroupStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(FormGroupStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedFormGroupId: null })),
  on(FormGroupStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(FormGroupStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FormGroupStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.formGroup, { ...state, isLoading: false, error: null })
  )),
  on(FormGroupStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(FormGroupStoreActions.DeleteRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedFormGroupId: state.selectedFormGroupId === action.id ? null : state.selectedFormGroupId })),
  on(FormGroupStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  ),
  on(FormGroupStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(FormGroupStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FormGroupStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.formGroup, { ...state, isLoading: false, error: null })
  ),
  on(FormGroupStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
