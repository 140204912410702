import { Action, createReducer, on } from "@ngrx/store";
import * as ModelStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(ModelStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedModelId: null
  })),
  on(ModelStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.models, { ...s, isLoading: false, error: null })
  }),
  on(ModelStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //ADD
  on(ModelStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(ModelStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.model,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(ModelStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(ModelStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedModelId: null
  })),
  on(ModelStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedModelId: action.model && action.model.id
  })),
  on(ModelStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(ModelStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(ModelStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedModelId: null
  })),
  on(ModelStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(ModelStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
