import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsRemindersFrontComponent } from './alerts-reminders-front/alerts-reminders-front.component';

import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { CardModule } from 'src/app/shared/models';
import { MatIconModule, MatButtonModule,  MatTooltipModule, MatMenuModule, MatInputModule, MatDatepickerModule, MatProgressBarModule, MatSelectModule, MatDialogModule, MatSnackBarModule, MatCheckboxModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsRemindersBackComponent } from './alerts-reminders-back/alerts-reminders-back.component';
import { ElementsModule } from 'src/app/elements/elements.module';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

@CardModule({
  selector: 'alerts-reminders',
  front: AlertsRemindersFrontComponent,
  back: AlertsRemindersBackComponent,
  header: 'Alerts / Reminders',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faBell', actionName: '', isFaIcon: true}],
  order: 10,
})

@NgModule({
  declarations: [AlertsRemindersFrontComponent, AlertsRemindersBackComponent],
  entryComponents: [AlertsRemindersFrontComponent, AlertsRemindersBackComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,
    FontAwesomeModule,
    ElementsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule
  ]
})
export class AlertsRemindersModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
