import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatMenuItem } from '@angular/material';
import { Subscription } from 'rxjs';
import { EvoPermission, PERMISSION_AREA } from './jwt.model';
import { UserPermissionsService } from './user-permissions.service';

/**
 * Sets an html elements `disabled` attribute when user does not have specified permission.
 * @example
 * ``` html
 * <div [disableByPermission]="[Permissions.Dashboard.Analytics.Consults, 1]"></div>
 * ```
 */
@Directive({ selector: '[disableByPermission]' })
export class DisableByPermissionDirective implements OnDestroy {
  private _subscription: Subscription;

  @Input('disableByPermission') set disableByPermission(value: [EvoPermission, PERMISSION_AREA, MatMenuItem]) {
    if (value) {
      if (this._subscription) this._subscription.unsubscribe();

      this._subscription = this._userPermissionsService.hasPermission(value[0], value[1]).subscribe((hasPermission) => {
        if (!hasPermission) {
          this.eleRef.nativeElement.setAttribute('disabled', 'disabled');
          if (value[2])
            value[2].disabled = true;
        }
        else {
          this.eleRef.nativeElement.removeAttribute('disabled');
          if (value[2])
            value[2].disabled = false;
        }
      });
    }
  }

  constructor(private eleRef: ElementRef<HTMLElement>, private _userPermissionsService: UserPermissionsService) {}

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
