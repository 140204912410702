import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {
  PatientsStoreSelectors,
  RootStoreState, PatientStoreEntity,
  PhotoGroupStoreActions,
  PhotoTypeStoreActions,
  CardsStoreActions,
} from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { map, take, takeUntil, } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { UploadImageComponent } from '../../../outreach/treatment/upload-image/upload-image.component';
import { GalleryComponent } from '../../../outreach/treatment/gallery/gallery.component';
import { FullScreenImage } from '../../full-screen-viewer/full-screen-image';
import { FullScreenViewerComponent } from '../../full-screen-viewer/full-screen-viewer.component';
import { PatientClient, PatientTreatmentImageGroupDto, StorageItemBaseDto } from '../../../shared/services/api.service';
import { MontageGeneratorComponent } from '../../../outreach/treatment/montage-generator/montage-generator.component';
import { Actions, ofType } from '@ngrx/effects';
import { MessageService, MessageServiceEventEnum } from '../../../core/message.service';

@Component({
  selector: 'app-patient-imaging',
  templateUrl: './patient-imaging.component.html',
  styleUrls: ['./patient-imaging.component.css']
})
export class PatientImagingComponent implements OnInit, OnDestroy, AfterViewInit {
  patient: PatientStoreEntity;
  isWorking: number = 0;

  patient$: Observable<PatientStoreEntity> = this._store$
    .select(PatientsStoreSelectors.getSelectedPatient)
    .pipe(map(patient => {
      this.patient = patient;
      if (patient) {
        this._store$.dispatch(PhotoGroupStoreActions.LoadRequest({}));
        this._store$.dispatch(PhotoTypeStoreActions.LoadRequest({}));
        this.initializeData();
      }
      return patient;
    }));

  selectedPatientTreatmentImageGroup: PatientTreatmentImageGroupDto;
  selectedPatientTreatmentImage;
  selectedImageIndex: number = -1;
  patientTreatmentImageGroups: PatientTreatmentImageGroupDto[];
  private fetchAll$: any;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _dialog: MatDialog,
    private _patientClient: PatientClient,
    private _actions$: Actions,
    private messageService: MessageService
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  initializeData(): void {
    this.isWorking++;

    this._patientClient.patient_GetPatientTreatmentImageGroups(
      this.patient.id
    ).subscribe((res: PatientTreatmentImageGroupDto[]) => {
      this.patientTreatmentImageGroups = _.sortBy(res, ['photoGroupSortOrder']);
      _.each(this.patientTreatmentImageGroups, (patientTreatmentImageGroup: any) => {
        patientTreatmentImageGroup.patientTreatmentImages = _.sortBy(patientTreatmentImageGroup.patientTreatmentImages, ['photoTypeSortOrder']);
        patientTreatmentImageGroup.dobExamDiff = this.calculateDateDiff(patientTreatmentImageGroup.takenWhen, this.patient.dob);
      });

      if (this.patientTreatmentImageGroups && this.patientTreatmentImageGroups.length > 0)
        this.selectPatientTreatmentImageGroup(this.patientTreatmentImageGroups[0]);
      else
        this.selectPatientTreatmentImageGroup(null);

      this.isWorking--;
    }, (err) => {
        console.log('imaging error:', err);
        this.isWorking--;
    });
  }

  ngOnDestroy() {
    if (this.fetchAll$)
      this.fetchAll$.unsubscribe();
    this._destroy$.next(true);
  }

  private calculateDateDiff(examDate, dateOfBirth) {
    var duration = moment.duration(moment(examDate).diff(moment(dateOfBirth)));
    return `${duration.years()}y ${duration.months()}m`;
  }

  openUpload(): void {
    const dialogRef = this._dialog.open(
      UploadImageComponent,
      { disableClose: true }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) 
        this.initializeData();
    });
  }

  openGallery(): void {
    const dialogRef = this._dialog.open(
      GalleryComponent,
      { disableClose: true }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.initializeData();
    });
  }

  openMontage(): void {
    const dialogRef = this._dialog.open(
      MontageGeneratorComponent
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.initializeData();
    });
  }

  showLightbox(): void {
    if (!this.selectedPatientTreatmentImageGroup || !this.selectedPatientTreatmentImageGroup.patientTreatmentImages) return;
    let fullScreenImages: FullScreenImage[] = _.map(this.selectedPatientTreatmentImageGroup.patientTreatmentImages, (image: any) => {
      let fullScreenImage: FullScreenImage = {
        image: image.locationUrl,
        thumbImage: image.locationUrl
      };
      return fullScreenImage;
    });

    const dialogRef = this._dialog.open(
      FullScreenViewerComponent,
      {
        data: {
          images: fullScreenImages,
          selectedImageIndex: this.selectedImageIndex
        }
      }
    );

    dialogRef.afterClosed().subscribe(() => {});
  }

  selectPatientTreatmentImageGroup(patientTreatmentImageGroup: PatientTreatmentImageGroupDto): void {
    this.selectedPatientTreatmentImageGroup = null;
    this.selectedPatientTreatmentImage = null;
    this.selectedImageIndex = -1;

    if (!patientTreatmentImageGroup) return;
    this.selectedPatientTreatmentImageGroup = patientTreatmentImageGroup;

    if (!patientTreatmentImageGroup.patientTreatmentImages || !patientTreatmentImageGroup.patientTreatmentImages[0]) return;
    this.selectedPatientTreatmentImage = patientTreatmentImageGroup.patientTreatmentImages[0];
    this.selectedImageIndex = 0;
  }

  attachToReferOut() {
    this._actions$
      .pipe(
        ofType(CardsStoreActions.OpenSuccess),
        take(1),
        takeUntil(this._destroy$))
      .subscribe(result => {
        setTimeout(() => {
          let attachImage: StorageItemBaseDto = this.selectedPatientTreatmentImage;
          this.messageService.sendMessage(
            MessageServiceEventEnum.attachImage,
            {
              image: attachImage
            }
          );
        });
      })

    this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: 'referout' }));

  }

  openPano(): void {
    sessionStorage.setItem('defaultPanoramicImage', JSON.stringify(this.selectedPatientTreatmentImage.storageItemId));
    this._store$.dispatch(CardsStoreActions.CloseCardRequest({ selector: 'panoramic-viewer' }));
    setTimeout(() => {
      this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: 'panoramic-viewer' }));
    });
  }
}
