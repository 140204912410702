import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FORM_GROUP_FEATURE_KEY } from './featurekey';
import { featureAdapter, FormGroupStoreEntity, State } from './state';

export const selectFormGroupsState: MemoizedSelector<object, State> = createFeatureSelector<State>(FORM_GROUP_FEATURE_KEY);

export const getSelectedFormGroupId = createSelector(selectFormGroupsState, (state) => state.selectedFormGroupId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectFormGroupsState);
export const selectFormGroupIds = selectIds;
export const selectAllFormGroups = selectAll;
export const selectAllFormGroupsEntities = selectEntities;

export const getSelectedFormGroup = createSelector(
  selectFormGroupsState,
  selectAllFormGroups,
  (state, allFormGroups: FormGroupStoreEntity[]) =>
    allFormGroups ? allFormGroups.find((rp) => rp.id === state.selectedFormGroupId) : null
);

export const selectFormGroupById = (id: number) =>
  createSelector(selectAllFormGroups, (allFormGroups: FormGroupStoreEntity[]) =>
    allFormGroups ? allFormGroups.find((rp) => rp.id === id) : null
  );

export const selectFormGroupsError: MemoizedSelector<object, any> = createSelector(selectFormGroupsState, (state) => state.error);

export const selectFormGroupsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectFormGroupsState, (state: State) => state.isLoading);
