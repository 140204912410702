import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  PatientsStoreSelectors,
  PatientsStoreState
} from 'src/app/root-store';
import { PatientAlertDto, PatientAlertTypeEnum, PatientClient } from '@shared/services/api.service';
import { takeUntil, take, filter } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faExclamation, faHeart, faPlusSquare, faCheckCircle, faTint, faTrashAlt, faBell, faDollarSign, faUndo, faAngleDoubleRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faTriangle, } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog, MatSelect, MatOption } from '@angular/material';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';

@Component({
  selector: 'app-alerts-reminders-front',
  templateUrl: './alerts-reminders-front.component.html',
  styleUrls: ['./alerts-reminders-front.component.scss']
})
export class AlertsRemindersFrontComponent implements OnInit, OnDestroy {
  faTriangle = faTriangle;
  faHeart = faHeart;
  faExclamation = faExclamation;
  faPlusSquare = faPlusSquare;
  faCheckCircle = faCheckCircle
  faTint = faTint;
  faTrashAlt = faTrashAlt;
  faBell = faBell;
  faDollarSign = faDollarSign;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  faUndo = faUndo;
  faAngleDoubleRight = faAngleDoubleRight;
  faCalendar = faCalendar;

  isWorking: boolean = false;
  patientId$ = this.store$.select(PatientsStoreSelectors.getSelectedPatientId);
  selectedPatientId: any;
  historyCount: number = 0;
  activeMedicals: PatientAlertDto[] = [];
  activeSchedules: PatientAlertDto[] = [];
  activeReminders: PatientAlertDto[] = [];
  historyMedicals: PatientAlertDto[] = [];
  historySchedules: PatientAlertDto[] = [];
  historyReminders: PatientAlertDto[] = [];
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  dialog: DialogComponent;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private store$: Store<PatientsStoreState.State>,
    private patientClient: PatientClient,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
  ) {
    this.dialog = new DialogComponent(this._dialog);
  }

  ngOnInit() {
    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe(resp => {
      this.getAlerts();
    });

    this.patientId$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(patientId => {
        this.selectedPatientId = patientId;
        this.getAlerts();
        //this.checkIfOpenAlertModal();
      })

  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  flipToBack(section, selectedAlert?: PatientAlertDto) {
    this.data.flip.next(<IFlipEvent>{
      payload: {
        section: section,
        selectedAlert: selectedAlert
      },
      side: this.data.side
    })
  }

  getAlerts() {
    if (this.selectedPatientId < 1) return;

    this.activeMedicals = [];
    this.activeReminders = [];
    this.activeSchedules = [];
    this.isWorking = true;
    this.patientClient.patient_GetAlerts(this.selectedPatientId)
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe(resp => {

        let medicals = [...resp.medical, ...resp.tlc];

        if (medicals && medicals.length > 0) {
          this.activeMedicals = medicals.filter((x: PatientAlertDto) => !x.isComplete);
          this.historyMedicals = medicals.filter((x: PatientAlertDto) => x.isComplete);
        }

        if (resp.schedule && resp.schedule.length > 0) {
          this.activeSchedules = resp.schedule.filter((x: PatientAlertDto) => !x.isComplete);
          this.historySchedules = resp.schedule.filter((x: PatientAlertDto) => x.isComplete);
        }

        if (resp.other && resp.other.length > 0) {
          this.activeReminders = resp.other.filter((x: PatientAlertDto) => !x.isComplete);
          this.historyReminders = resp.other.filter((x: PatientAlertDto) => x.isComplete);
        }

        this.isWorking = false;
      },
        (error) => {
          this.activeMedicals = [];
          this.activeSchedules = [];
          this.activeReminders = [];
          this.isWorking = false;
          this.openSnackBar("Error", "Ok");
        });
  }

  setAlertToComplete(alert: PatientAlertDto): void {
    if (this.selectedPatientId < 1) return;
    this.isWorking = true;
    this.patientClient.patient_PostAlertComplete(this.selectedPatientId, alert.id)
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((resp) => {
        this.isWorking = false;
        this.openSnackBar("Updated Successfully!", "");
        this.getAlerts();
      },
        (error) => {
          this.isWorking = false;
          this.openSnackBar("Error", "Ok");
        });
  }

  editAlert(alert: PatientAlertDto): void {
    this.flipToBack(alert.type, alert);
  }

  showHistory(): void {
    this.data.flip.next(<IFlipEvent>{
      payload: {
        isHistory: true,
        historyMedicals: this.historyMedicals,
        historySchedules: this.historySchedules,
        historyReminders: this.historyReminders
      },
      side: this.data.side
    })
  }

  openSnackBar(message: string, action: string): void {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }
}
