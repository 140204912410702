import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { WAITING_APPOINTMENT_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const selectWaitingAppointmentsState: MemoizedSelector<object, State> = createFeatureSelector<State>(WAITING_APPOINTMENT_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectWaitingAppointmentsState);
export const selectAllWaitingAppointments = selectAll;
export const selectAllWaitingAppointmentsEntities = selectEntities;

export const selectWaitingAppointmentsError: MemoizedSelector<object, any> = createSelector(selectWaitingAppointmentsState, (state) => state.error);
export const selectWaitingAppointmentsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectWaitingAppointmentsState, (state: State) => state.isLoading);
