import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'src/app/shared/models';
import { GiftsFrontComponent } from './gifts-front/gifts-front.component';
import { GiftsBackComponent } from './gifts-back/gifts-back.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatProgressBarModule,
  MatListModule,
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatTooltipModule
} from '@angular/material';
import { TxCardToolsEnum } from '@shared/services/api.service';

import { TextMaskModule } from 'angular2-text-mask';
import { NotificationSettingsModule } from 'src/app/schedule/notification-settings/notification-settings.module';

@CardModule({
  back: GiftsBackComponent,
  front: GiftsFrontComponent,
  height: DEFAULT_CARD_HEIGHT,
  selector: 'gifts',
  width: 460,
  header: 'Gifts',
  order: 150,
  actions: [{iconName: 'faGift', actionName: '', isFaIcon: true}],
  
})


@NgModule({
  declarations: [GiftsFrontComponent, GiftsBackComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatProgressBarModule,
    MatListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTooltipModule,
    FormsModule, 
    ReactiveFormsModule,
    TextMaskModule,
    NotificationSettingsModule
  ],
  entryComponents: [GiftsFrontComponent, GiftsBackComponent]
})
export class GiftsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
