import { createReducer, on } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';
import * as CardsStoreActions from './actions';
import { IOpenCard } from './IOpenCard';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //INIT
  on(CardsStoreActions.CardsInitSuccess, (state, action) => ({ ...state, openCards: action.openCards })),
  //LOAD
  on(CardsStoreActions.LoadRequestAction, (state) => ({ ...state, isLoading: true, error: null })),
  on(CardsStoreActions.LoadSuccessAction, (state, action) => featureAdapter.upsertMany(action.items, { ...state, isLoading: false, error: null })),
  on(CardsStoreActions.LoadFailureAction, (state, action) => ({ ...state, isLoading: true, error: action.payload.error })),
  //ADD
  on(CardsStoreActions.AddRequestAction, (state) => ({ ...state, isLoading: true, error: null })),
  on(CardsStoreActions.AddSuccessAction, (state, action) => featureAdapter.upsertOne(action.card, { ...state, isLoading: false, error: null })),
  on(CardsStoreActions.AddFailureAction, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //DELETE
  on(CardsStoreActions.DeleteRequestAction, (state) => ({ ...state, isLoading: true, error: null })),
  on(CardsStoreActions.DeleteSuccessAction, (state, action) =>
    featureAdapter.removeOne(action.selector, { ...state, isLoading: false, error: null })
  ),
  on(CardsStoreActions.DeleteFailureAction, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //DISPLAY
  on(CardsStoreActions.OpenCardRequest, (state, action) => {
    //ensure card isn't already open
    if (!state.openCards.some((c) => c.selector == action.selector))
      return {
        ...state,
        isLoading: true,
        openCards: [
          //                                if specific order & order > action order        remap card and increase order              otherwise return same card
          ...state.openCards.map((card) => (action.order && card.order >= action.order ? <IOpenCard>{ ...card, order: card.order + 1 } : card)),
          //add in newly opened card either at specified order or at the end if not specified
          <IOpenCard>{ selector: action.selector, order: action.order || state.openCards.length + 1 },
        ],
      };
    else
      return state;
  }),
  on(CardsStoreActions.SetOpenCardsRequest, (state) => ({ ...state, isLoading: true })),
  on(CardsStoreActions.OpenSuccess, (state, action) => ({ ...state, isLoading: false })),
  on(CardsStoreActions.CloseCardRequest, (state, action) => ({ ...state, isLoading: true })),
  on(CardsStoreActions.CloseCardSuccess, (state, action) => ({ ...state, isLoading: false, openCards: action.cards }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
