import { Store } from '@ngrx/store';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { RootStoreState, LocationsStoreSelectors, LocationsStoreActions } from '../../root-store';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { LocationDto } from 'src/app/shared/services/api.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit, OnDestroy {
  activeLocation: any = 2;
  showdrpdn: boolean = false;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  activeLocations$ = this._rootStore$.select(LocationsStoreSelectors.selectActiveLocations);
  selectedLocation$ = this._rootStore$.select(LocationsStoreSelectors.getSelectedLocation);

  constructor(
    private location: Location,
    public scheduleService: ScheduleService,
    private _rootStore$: Store<RootStoreState.State>) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  selectLocation(location: LocationDto) {
    this._rootStore$.dispatch(LocationsStoreActions.SelectRequest({ id: location.id }));
    this.showdrpdn = false;
  }

  onShowDialog(): void {
    this.showdrpdn = this.showdrpdn == true ? false : true;
  }

  onClickedOutside() {
    this.showdrpdn = false;
  }
}
