import { Action, createReducer, on } from "@ngrx/store";
import * as PhotoGroupStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PhotoGroupStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize, selectedPhotoGroupId: null })),
  on(PhotoGroupStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.photoGroups, { ...s, isLoading: false, error: null })
  }),
  on(PhotoGroupStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(PhotoGroupStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PhotoGroupStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.photoGroup, { ...state, isLoading: false, error: null })
  )),
  on(PhotoGroupStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(PhotoGroupStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PhotoGroupStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.photoGroup, { ...state, isLoading: false, error: null })
  )),
  on(PhotoGroupStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(PhotoGroupStoreActions.DeleteRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPhotoGroupId: state.selectedPhotoGroupId === action.id ? null : state.selectedPhotoGroupId })),
  on(PhotoGroupStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null})
  ),
  on(PhotoGroupStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(PhotoGroupStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedPhotoGroupId: null  })),
  on(PhotoGroupStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedPhotoGroupId: action.photoGroup && action.photoGroup.id })),
  on(PhotoGroupStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(PhotoGroupStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(PhotoGroupStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedPhotoGroupId: null })),
  on(PhotoGroupStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(PhotoGroupStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(PhotoGroupStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.photoGroup, {...state, isLoading: false, error: null})
  ),
  on(PhotoGroupStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({...state});
  }),
  //UTILITY
  on(PhotoGroupStoreActions.ResetError, (state) => ({...state, error: null})),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
