import { Action, createReducer, on } from '@ngrx/store';
import * as PatientMessageStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';
import { state } from '@angular/animations';

const reducer = createReducer(
  initialState,
  on(PatientMessageStoreActions.ResetRequest, (state, action) => featureAdapter.removeAll({ ...initialState, patientId: action.patientId })),
  on(PatientMessageStoreActions.SetPatientRequest, (state, action) => ({ ...state, patientId: action.patientId })),
  on(PatientMessageStoreActions.LoadNextPageRequest, (state, action) => ({ ...state, previousPageNumber: state.previousPageNumber + 1 })),
  on(PatientMessageStoreActions.LoadNextFuturePageRequest, (state, action) => ({ ...state, futurePageNumber: state.futurePageNumber - 1 })),
  on(PatientMessageStoreActions.LoadPageRequest, (state, action) => ({ ...state, isLoading: true, pageSize: action.pageSize || state.pageSize })),
  on(PatientMessageStoreActions.LoadPageSuccess, (state, action) =>
    featureAdapter.upsertMany(action.messages, { ...state, isLoading: false, previousEOF: action.previousEof, futureEOF: action.futureEof })
  ),
  on(PatientMessageStoreActions.LoadPageFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.AddReminderRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.AddReminderSuccess, (state, action) =>
    action.messages.some((m) => m.patientId == state.patientId)
      ? featureAdapter.upsertMany(action.messages, { ...state, isLoading: false })
      : { ...state, isLoading: false }
  ),
  on(PatientMessageStoreActions.AddReminderFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.AddMessageRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.AddMessageSuccess, (state, action) =>
    action.message.patientId == state.patientId && !action.message.isHidden
      ? featureAdapter.upsertOne(action.message, { ...state, isLoading: false })
      : { ...state, isLoading: false }
  ),
  on(PatientMessageStoreActions.AddMessageFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.AddFollowUpRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.AddFollowUpSuccess, (state, action) =>
    action.message.patientId == state.patientId
      ? featureAdapter.upsertOne(action.message, { ...state, isLoading: false })
      : { ...state, isLoading: false }
  ),
  on(PatientMessageStoreActions.AddFollowUpFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.DeleteMessageRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.DeleteMessageSuccess, (state, action) => featureAdapter.removeOne(action.messageId, { ...state, isLoading: false })),
  on(PatientMessageStoreActions.DeleteMessageFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.MarkAsReadRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.MarkAsReadSuccess, (state, action) =>
    featureAdapter.updateOne({ id: action.id, changes: { readWhen: new Date() } }, { ...state, isLoading: false })
  ),
  on(PatientMessageStoreActions.MarkAsReadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.MessagedRemoved, (state, action) => featureAdapter.removeOne(action.messageId, ({...state}))),
  on(PatientMessageStoreActions.LoadMessageRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.LoadMessageSuccess, (state, action) => featureAdapter.upsertOne(action.message, {...state, isLoading: false})),
  on(PatientMessageStoreActions.LoadMessageFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientMessageStoreActions.CopyMessage, (state, action) => ({ ...state, copiedMessage: action })),

  on(PatientMessageStoreActions.AddReplyMessageRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientMessageStoreActions.AddReplyMessageSuccess, (state, action) =>
    action.message.patientId == state.patientId && !action.message.isHidden
      ? featureAdapter.upsertOne(action.message, { ...state, isLoading: false })
      : { ...state, isLoading: false }
  ),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
