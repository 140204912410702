import { Injectable, APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';

let GLOBAL_SETTINGS;

@Injectable()
export class AppPropertyService {
  constructor(private httpClient: HttpClient) {}

  public load() {
    return new Promise((resolve, reject) => {
      this.httpClient.get('assets/global-property.json').subscribe(
        globalSettings => {
          GLOBAL_SETTINGS = globalSettings;
          resolve(true);
        },
        error => reject(error)
      );
    });
  }

  public get(fieldKey: string) {
    let value = GLOBAL_SETTINGS;

    fieldKey.split('.').forEach(field => {
      value = value ? value[field] : undefined;
    });

    return value;
  }
}

export const AppPropertyProvider = {
  provide: APP_INITIALIZER,
  useFactory: (appPropertyService: AppPropertyService) => {
    return () => appPropertyService.load();
  },
  deps: [AppPropertyService],
  multi: true
};