import { initialState, State } from './state';
import * as ScheduleStoreActions from './actions';
import { createReducer, on } from '@ngrx/store';

const reducer = createReducer(
  initialState,
  on(ScheduleStoreActions.DateChange, (state, action) => ({ ...state, viewDate: action.date })),
);

export function featureReducer(state: State | undefined, action) {
  return reducer(state, action);
}
