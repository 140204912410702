import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GoalDto } from 'src/app/shared/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-patient-goals-display',
  templateUrl: './patient-goals-display.component.html',
  styleUrls: ['./patient-goals-display.component.css']
})
export class PatientGoalsDisplayComponent implements OnInit {

  @Input('goals') patientGoals: GoalDto[];
  @Output('remove') removeEvent: EventEmitter<GoalDto> = new EventEmitter<GoalDto>();

  constructor() { }

  ngOnInit() {
    this.patientGoals = _.orderBy(this.patientGoals, 'sortOrder', 'asc');
  }

  removeGoal(goal: GoalDto) {
    this.removeEvent.next(goal);
  }
}
