import { Component, Inject, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  FormGroupStoreActions,
  FormGroupStoreSelectors,
  FormTypeStoreActions,
  FormTypeStoreSelectors,
  LocationsStoreSelectors,
  PatientFormStoreActions,
  PatientsStoreSelectors,
  ResponsiblePartyStoreActions,
  ResponsiblePartyStoreSelectors,
  RootStoreState
} from '../../../../root-store';
import { NotificationSettingsComponent, NotificationSettingsData } from '../../../../schedule/notification-settings/notification-settings.component';
import { CARD_DATA, ICardData } from '../../../../shared/models';
import { FormGroupDto, FormTypeAudienceEnum, FormTypeDto, NotificationDto, NotificationEmailDto, NotificationPhoneDto, PatientClient, PatientFormDto } from '../../../../shared/services/api.service';

@Component({
  selector: 'patient-forms-back',
  templateUrl: './patient-forms-back.component.html',
  styleUrls: ['./patient-forms-back.component.css']
})
export class PatientFormsBackComponent implements OnInit, OnDestroy {
  @ViewChild('notificationSettings', { static: false }) notificationSettings: NotificationSettingsComponent;
  @ViewChild('warningDialog', {static: false}) warningDialog:TemplateRef<any>;

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  notificationList: any = [];
  alreadyOpenedNotificationSettings: boolean = false;

  isFormTypeWorking$: Observable<boolean> = this._store$.select(FormTypeStoreSelectors.selectFormTypesIsLoading);
  isFormGroupWorking$: Observable<boolean> = this._store$.select(FormGroupStoreSelectors.selectFormGroupsIsLoading);
  isResponsiblePartyWorking$: Observable<boolean> = this._store$.select(ResponsiblePartyStoreSelectors.selectResponsiblePartysIsLoading);

  formTypes$ = this._store$.select(FormTypeStoreSelectors.selectAllFormTypes).pipe(
    map((forms) => forms.filter((f) => (f.audience == this.audienceType && f.isActive)))
  );
  formGroups$ = this._store$.select(FormGroupStoreSelectors.selectAllFormGroups).pipe(map((forms) => forms.filter((f) => f.isActive)));
  responsibles$ = this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties);

  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  private _selectedLocationId$ = this._store$.select(LocationsStoreSelectors.getSelectedLocationId);

  selectedFormGroup: FormGroupDto;
  selectedFormType: FormTypeDto;
  selectedPatientId: number;

  notificationCount: number = 0;

  patientForms: PatientFormDto[] = [];
  audienceType: FormTypeAudienceEnum;

  constructor(
    private _store$: Store<RootStoreState.State>,
    @Inject(CARD_DATA) private _cardData: ICardData<any, FormTypeAudienceEnum>,
    private _sanitizer: DomSanitizer,
    private patientClient: PatientClient,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
  ) {
    this._cardData.backgroundColor = '#eaf6fa';
    this._cardData.incoming.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      this.audienceType = data;
      this.alreadyOpenedNotificationSettings = false;
      this.initialize();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  initialize(): void {
    this._store$.dispatch(FormTypeStoreActions.LoadRequest());
    this._store$.dispatch(FormGroupStoreActions.LoadRequest());

    combineLatest(
      this._selectedPatientId$,
      this._selectedLocationId$
    )
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(([patientId, locationId]) => {
        if (patientId && locationId) {
          this.selectedPatientId = patientId;
          this._store$.dispatch(ResponsiblePartyStoreActions.LoadRequest({
            patientId: patientId,
            locationId: locationId
          }));
          this.getNotifications();
        }
      });
  }

  getNotifications() {
    if (!this.selectedPatientId) return;
    this.patientClient.patient_GetNotificationsByPatient(this.selectedPatientId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => {
        this.notificationList = res;
        const notificationEmailCount = this.notificationList
          .reduce((accumulator, value) => accumulator.concat(value.emails), [])
          .filter((x: NotificationEmailDto) => {
            return x.forPatientForm;
          }).length;

        const notificationPhoneCount = this.notificationList
          .reduce((accumulator, value) => accumulator.concat(value.phones), [])
          .filter((x: NotificationPhoneDto) => {
            return x.forPatientForm;
          }).length;

        this.notificationCount = notificationEmailCount + notificationPhoneCount;

        if (notificationEmailCount < 1
          && notificationPhoneCount < 1) {
          this.openSnackBar('Please mark someone to notify forms.', 'OK');

          if (!this.alreadyOpenedNotificationSettings) {
            this.openPatientNotificationSettings();
          }
        }
    });
  }

  afterSubmitNotification() {
    this.initialize();
  }

  goToFront() {
    this.patientForms = [];
    this.selectedFormGroup = null;
    this.selectedFormType = null;
    this._cardData.flip.next({ payload: null, side: this._cardData.side });
  }

  deleteFormType(index: number): void {
    this.patientForms.splice(index, 1);
  }

  addFormGroup(formGroup: FormGroupDto): void {
    if (!formGroup || !formGroup.formTypes) return;

    formGroup.formTypes.forEach((x) => {
      if (x.audience == this.audienceType)
        this.addFormType(x);
    });
  }

  addFormType(formType: FormTypeDto): void {
    if (!formType || formType.id < 1) return;

    this.patientForms.push(new PatientFormDto({
      id: 0,
      patientId: this.selectedPatientId,
      formTypeId: formType.id,
      formTypeAudience: formType.audience,
      isActive: true
    }));
  }

  save(): void {
    this._store$.dispatch(PatientFormStoreActions.AddMultipleRequest({
      patientForms: this.patientForms
    }));
    this.goToFront();
  }

  selectCompareObject(option, value): boolean {
    if (!option || !value) return false;
    return option.id === value.id;
  }

  openPatientNotificationSettings(): void {
    this.alreadyOpenedNotificationSettings = true;
    let notificationSettingsData: NotificationSettingsData = {
      patientId: this.selectedPatientId,
      notificationList: this.notificationList,
      fetchNotification: false,
      isDialog: true
    };
    const dialogRef = this._dialog.open(
      NotificationSettingsComponent,
      {
        data: notificationSettingsData,
        disableClose: true
      }
    );

    dialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        this.afterSubmitNotification();
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  confirmSave() {
    if (this.audienceType == FormTypeAudienceEnum.Office) {
      this.save();
    }
    else {
      this._dialog.open(this.warningDialog).afterClosed().subscribe(dialogResponse => {
        if (dialogResponse != null) {
          if (dialogResponse == true) {
            this.save();
          } else {
            this.goToFront();
          }
        }
      });
    }
  }
}
