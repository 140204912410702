import { createAction, props } from "@ngrx/store";
import { PhotoTypeStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Photo Type] Load Request',
  props<{page?: number; pageSize?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Photo Type] Load Success',
  props<{ photoTypes: PhotoTypeStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Photo Type] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[Photo Type] Load One Request',
  props<{ id: number; }>()
);

export const LoadOneSuccess = createAction(
  '[Photo Type] Load One Success',
  props<{ photoType: PhotoTypeStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[Photo Type] Load One Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Photo Type] Add Request',
  props<{ photoType: PhotoTypeStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Photo Type] Add Success',
  props<{ photoType: PhotoTypeStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Photo Type] Delete Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[Photo Type] Delete Request',
  props<{ id: number; }>()
);

export const DeleteSuccess = createAction(
  '[Photo Type] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Photo Type] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Photo Type] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Photo Type] Select Success',
  props<{ photoType: PhotoTypeStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Photo Type] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[Photo Type] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Photo Type] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Photo Type] Deselect Failure',
  props<{ error: any; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[Photo Type] Update Request',
  props<{ id: number; photoType: PhotoTypeStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[Photo Type] Update Success',
  props<{ photoType: PhotoTypeStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[Photo Type] Update Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Photo Type] Reset Error'
);
