import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import {
  CardsStoreActions,
  PatientsStoreSelectors,
  PatientTreatmentPlanStoreEntity,
  PatientTreatmentStoreActions,
  PatientTreatmentStoreSelectors,
  RootStoreState,
  StepGroupStoreActions,
} from 'src/app/root-store';

import { Actions, ofType } from '@ngrx/effects';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { 
  TreatmentDto,
  StepDto
} from 'src/app/shared/services/api.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-treatment-steps-display',
  templateUrl: './treatment-steps-display.component.html',
  styleUrls: ['./treatment-steps-display.component.css']
})
export class TreatmentStepsDisplayComponent implements OnInit {

  @Input() treatment: TreatmentDto;
  @Output() inProgress = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  totalWeekCount: number = 0;
  totalWeeksRemaining: number = 0;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _actions$: Actions,
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges(){
    this.countTotal();
  }

  markStepFinished(step: StepDto, isFinished: boolean = true) {
   const updateStep = new StepDto({ ...step, actualFinishDate: isFinished ? new Date() : null, treatmentId: this.treatment.id });
    //this.inProgress.emit(true);
    this._actions$
				.pipe(ofType(PatientTreatmentStoreActions.UpdateStepsSuccess), take(1), takeUntil(this._destroy$))
				.subscribe((result) => {
          this.treatment = result.plan;
          this.countTotal();
				});
    this._store$.dispatch(PatientTreatmentStoreActions.UpdateStepsRequest({ steps: [updateStep], treatmentId: this.treatment.id }));
  }

  countTotal(){
    this.totalWeekCount = _.sumBy(this.treatment.steps, (item) => {
      return +item.weekCount;
    });

    let hasRemaining = this.treatment.steps.filter((step) => !step.actualFinishDate);
    if(hasRemaining.length > 0){
      this.totalWeeksRemaining = _.sumBy(hasRemaining, (item) => {
        return +item.weekCount;
      });
    }

    
  }

}
