import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PhotoGroupDto, IPhotoGroupDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PhotoGroupStoreEntity> = createEntityAdapter<PhotoGroupStoreEntity>({
  sortComparer: (a: PhotoGroupStoreEntity, b: PhotoGroupStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<PhotoGroupStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPhotoGroupId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPhotoGroupId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PhotoGroupStoreEntity extends PhotoGroupDto {
  constructor(data?: IPhotoGroupDto) {
    super(data);
  }
}
