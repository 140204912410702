import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, defer, from } from 'rxjs';
import { catchError, map, switchMap, toArray, mergeAll } from 'rxjs/operators';
import { State } from '../root-state';
import * as PatientImagingStoreActions from './actions';
import * as PatientImagingStoreSelectors from './selectors';
import { PatientClient, StorageContentTypeEnum } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class PatientImagingStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientImagingStoreActions.LoadRequest),
    switchMap((action) =>
      this._patientClient
        .patient_GetImaging(
          action.patientId,
          StorageContentTypeEnum.Imaging)
        .pipe(
          map((result) => PatientImagingStoreActions.LoadSuccess({ patientImagings: result })),
          catchError((err: HttpErrorResponse) => of(PatientImagingStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientImagingStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(
          PatientImagingStoreSelectors
            .selectPatientImagingById(action.id))
        .pipe(map((result) => PatientImagingStoreActions.SelectSuccess({ patientImaging: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientImagingStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(PatientImagingStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientImagingStoreActions.AddRequest),
    switchMap((action) =>
      this._patientClient.patient_PostImage(
        action.patientImaging.patientId,
        action.patientImaging.file,
        StorageContentTypeEnum.Imaging,
        action.patientImaging.report)
        .pipe(
          map((result) => PatientImagingStoreActions.AddSuccess({ patientImaging: result })),
          catchError((err: HttpErrorResponse) => of(PatientImagingStoreActions.AddFailure({ error: err.message })))
        ))
  );

  @Effect()
  addMultipleRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientImagingStoreActions.AddMultipleRequest),
    switchMap((action) => {
      const observables = action.patientImagings.map(a => defer(() => this._patientClient.patient_PostImage(
        a.patientId,
        a.file,
        StorageContentTypeEnum.Imaging,
        a.report,
        null,
        a.photoGroupId,
        a.photoTypeId)))
      return from(observables)
        .pipe(
          mergeAll(1),
          toArray()
        )
        .pipe(
          map((result) => PatientImagingStoreActions.AddMultipleSuccess({ patientImagings: result })),
          catchError((err: HttpErrorResponse) => of(PatientImagingStoreActions.AddMultipleFailure({ error: err.message })))
        );
    })
  );
}
