import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { DocumentTemplateDto, IDocumentTemplateDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<TemplateStoreEntity> = createEntityAdapter<TemplateStoreEntity>({
  sortComparer: (a: TemplateStoreEntity, b: TemplateStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<TemplateStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class TemplateStoreEntity extends DocumentTemplateDto {
  constructor(data?: IDocumentTemplateDto) {
    super(data);
  }
}
