import { Component, OnInit, Inject, ViewChild, TemplateRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faExclamation, faHeart, faPlusSquare, faCheckCircle, faTint, faTrashAlt, faBell, faDollarSign, faUndo, faAngleDoubleRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil, take, filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  PatientsStoreSelectors,
  PatientsStoreState,
  RootStoreState,
  SettingsStoreSelectors
} from 'src/app/root-store';
import { PatientAlertDto, PatientAlertTypeEnum, PatientClient, PatientAlertNotifyLocationEnum } from '@shared/services/api.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { MatSnackBar, MatDialog } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-alerts-reminders-back',
  templateUrl: './alerts-reminders-back.component.html',
  styleUrls: ['./alerts-reminders-back.component.scss']
})
export class AlertsRemindersBackComponent implements OnInit, OnDestroy {

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  faTriangle = faTriangle;
  faHeart = faHeart;
  faExclamation = faExclamation;
  faPlusSquare = faPlusSquare;
  faCheckCircle = faCheckCircle
  faTint = faTint;
  faTrashAlt = faTrashAlt;
  faBell = faBell;
  faDollarSign = faDollarSign;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  faUndo = faUndo;
  faAngleDoubleRight = faAngleDoubleRight;
  faCalendar = faCalendar;

  patient$ = this.store$.select(PatientsStoreSelectors.getSelectedPatient);

  otherAlerts: any[] = [];

  selectedAlert: any;
  editedAlert: PatientAlertDto;
  selectedColor: any = "#FFFFFF";
  intervalList = [...Object.entries(financialIntervalEnum).map(([key, value]) => ({ id: key, name: value }))];

  alertForm: FormGroup;
  isWorking: boolean = false;
  historyCount: number = 0;
  historyMedicals: PatientAlertDto[] = [];
  historyReminders: PatientAlertDto[] = [];
  isHistory: boolean;

  alertSection: any;
  patientAlertIntervals: PatientAlertDto[];

  /*
  None = "None",
    Scheduling = "Scheduling",
    Treatment = "Treatment",
    Financial = "Financial",
    Communication = "Communication",
    All = "All",
  */

    notifiLocation_Scheduling:boolean = false;
    notifiLocation_Treatment:boolean = false;
    notifiLocation_Financial:boolean = false;
    notifiLocation_Communication:boolean = false;

    isTriggerEnabled:boolean = true;
    procedureGroupList: any[] = [];
    procedures: any[]=[];

  settings$ = this._rootStore$.select(SettingsStoreSelectors.selectedSettings);

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private patientClient: PatientClient,
    private store$: Store<PatientsStoreState.State>,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    public matDialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _rootStore$: Store<RootStoreState.State>,
  ) {
    this.data.backgroundColor = '#eaf6fa';
  }

  ngOnInit() {
    this.alertForm = this.formBuilder.group({
      id: [''],
      etag: [''],
      alertType: ['other'],
      remindWhen: [''],
      color: ['#ffffff'],
      note: ['', Validators.required],
      interval: [''],
      amount: [''],
      procedureId: ['']
    })
    this.getProcedureGroups();

    this.data.incoming
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
        this.clearIntervals();
        this.historyMedicals = [];
        this.historyReminders = [];
        this.isHistory = resp.isHistory;
        this.alertSection = resp.section;
        this.selectedAlert = resp.selectedAlert;
        if (this.isHistory) {
          this.historyMedicals = resp.historyMedicals;
          this.historyReminders = resp.historyReminders;
        }
        else {
          if (resp.selectedAlert) {
            let alert = new PatientAlertDto(resp.selectedAlert);

            this.alertForm.patchValue({
              id: alert.id,
              procedureId: alert.procedureId,
              etag: alert.eTag,
              alertType: alert.type,
              remindWhen: alert.remindWhen,
              color: alert.color,
              note: alert.note
            });

            if(alert.notifyLocations.indexOf('All') != -1){
              this.notifiLocation_Scheduling = true;
              this.notifiLocation_Treatment = true;
              this.notifiLocation_Financial = true;
              this.notifiLocation_Communication = true;
            }

            if(alert.notifyLocations.indexOf('Scheduling') != -1){
              this.notifiLocation_Scheduling = true;
            }

            if(alert.notifyLocations.indexOf('Treatment') != -1){
              this.notifiLocation_Treatment = true;
            }

            if(alert.notifyLocations.indexOf('Financial') != -1){
              this.notifiLocation_Financial = true;
            }

            if(alert.notifyLocations.indexOf('Communication') != -1){
              this.notifiLocation_Communication = true;
            }

            this.isTriggerEnabled = alert.isTriggerEnabled;

          } else {
            this.alertForm.patchValue({
              alertType: resp.section
            });
            if(resp.section == 'Financial'){
              this.notifiLocation_Scheduling = true;
              this.notifiLocation_Treatment = true;
              this.notifiLocation_Financial = true;
              this.notifiLocation_Communication = true;
              this.isTriggerEnabled = true;
            } else if(resp.section == 'Other'){
              this.notifiLocation_Scheduling = true;
              this.notifiLocation_Treatment = true;
              this.notifiLocation_Financial = true;
              this.notifiLocation_Communication = true;
              this.isTriggerEnabled = true;
            } else {
              this.notifiLocation_Scheduling = false;
              this.notifiLocation_Treatment = false;
              this.notifiLocation_Financial = false;
              this.notifiLocation_Communication = false;
              this.isTriggerEnabled = true;
            }
          }
        }
        this._cdr.detectChanges();
      });

    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  getProcedureGroups(){
    this.patient$
        .pipe(filter(patient => !!patient), take(1))
        .subscribe(patient => {
          this.patientClient.patient_GetOptions(patient.id).subscribe(resp => {
            if(resp.alertTypeProcedureGroups && resp.alertTypeProcedureGroups.length > 0){
              let scheduleProcedureGroup = resp.alertTypeProcedureGroups.find(item => item.type == 'Schedule');
              this.procedureGroupList = _.orderBy(scheduleProcedureGroup.procedureGroups, 'name', 'asc');
              this.procedureGroupList.map(procedureGroup => {
                procedureGroup.procedures = _.orderBy(procedureGroup.procedures, 'sortOrder', 'asc');
              })
              this.procedures = _.chain(this.procedureGroupList).map('procedures').flatten().value();
            }
          },
          err => {
            console.log(err.message)
          })
        })
  }

  displayForAlertType(alertType) {
    return alertType != 'Medical' && alertType != 'Tlc';
  }

  selectAlertType(alerttype) {
    this.clearIntervals();
    this.alertSection = alerttype;
    this.alertForm.patchValue({
      alertType: alerttype
    });
  }

  selectColor(color) {
    this.alertForm.patchValue({
      color: color
    });
  }

  selectInterval(interval) {
    this.alertForm.patchValue({
      interval: interval.name
    });
  }

  saveAlert(): void {
    this.isWorking = true;

    let form = this.alertForm.value;

    let alert: any = new PatientAlertDto();

    alert.id = form.id;
    alert.eTag = form.etag;
    alert.type = form.alertType;
    alert.note = form.note;
    alert.color = form.color;
    alert.remindWhen = form.remindWhen;
    alert.procedureId = form.procedureId;
    alert.isTriggerEnabled = this.isTriggerEnabled;

    if(this.notifiLocation_Scheduling == true &&
      this.notifiLocation_Treatment == true &&
      this.notifiLocation_Financial == true &&
      this.notifiLocation_Communication == true){

        alert.notifyLocations = PatientAlertNotifyLocationEnum.All;

    } else if(this.notifiLocation_Scheduling == false &&
      this.notifiLocation_Treatment == false &&
      this.notifiLocation_Financial == false &&
      this.notifiLocation_Communication == false){
        alert.notifyLocations = PatientAlertNotifyLocationEnum.None;
    }

    else {
      let _notifyLocations = [];
      if(this.notifiLocation_Scheduling == true) {
        _notifyLocations.push(PatientAlertNotifyLocationEnum.Scheduling);
      }
      if(this.notifiLocation_Treatment == true) {
        _notifyLocations.push(PatientAlertNotifyLocationEnum.Treatment);
      }
      if(this.notifiLocation_Financial == true) {
        _notifyLocations.push(PatientAlertNotifyLocationEnum.Financial);
      }
      if(this.notifiLocation_Communication == true) {
        _notifyLocations.push(PatientAlertNotifyLocationEnum.Communication);
      }

      alert.notifyLocations = _notifyLocations.join(", ");
    }

    if (alert.id && alert.id > 0) {
      this.patient$
        .pipe(filter(patient => !!patient), take(1))
        .subscribe(patient => {
          this.patientClient
            .patient_PutAlert(patient.id, alert.id, alert)
            .pipe(
              takeUntil(this._destroy$),
              take(1)
            )
            .subscribe(resp => {
              this.isWorking = false;
              this.openSnackBar("Updated Successfully!", "");
              this.backToFront();
            },
            err => {
              this.isWorking = false;
              console.log(err)
            });
        });
    }
    if (this.patientAlertIntervals && this.patientAlertIntervals.length > 0) {
      this.patient$
        .pipe(filter(patient => !!patient), take(1))
        .subscribe(patient => {
          this.patientAlertIntervals.forEach((patientAlertInterval: any) => {
            patientAlertInterval.patientId = patient.id;

            if (this.notifiLocation_Scheduling &&
              this.notifiLocation_Treatment &&
              this.notifiLocation_Financial &&
              this.notifiLocation_Communication ) {
              patientAlertInterval.notifyLocations = PatientAlertNotifyLocationEnum.All;
            }
            else if (!this.notifiLocation_Scheduling &&
              !this.notifiLocation_Treatment &&
              !this.notifiLocation_Financial &&
              !this.notifiLocation_Communication ) {
              patientAlertInterval.notifyLocations = PatientAlertNotifyLocationEnum.None;
            }
            else {
              let _notifyLocations = [];
              if (this.notifiLocation_Scheduling == true) {
                _notifyLocations.push(PatientAlertNotifyLocationEnum.Scheduling);
              }
              if (this.notifiLocation_Treatment == true) {
                _notifyLocations.push(PatientAlertNotifyLocationEnum.Treatment);
              }
              if (this.notifiLocation_Financial == true) {
                _notifyLocations.push(PatientAlertNotifyLocationEnum.Financial);
              }
              if (this.notifiLocation_Communication == true) {
                _notifyLocations.push(PatientAlertNotifyLocationEnum.Communication);
              }

              patientAlertInterval.notifyLocations = _notifyLocations.join(", ");
            }
          });

          this.patientClient
            .patient_PostAlerts(patient.id, this.patientAlertIntervals)
            .pipe(
              takeUntil(this._destroy$),
              take(1)
            )
            .subscribe(resp => {
              this.isWorking = false;
              this.openSnackBar("Created Successfully!", "");
              this.backToFront();
            },
              err => {
                this.isWorking = false;
                console.log(err)
              });
        });
    }
    else {
      this.patient$
        .pipe(filter(patient => !!patient), take(1))
        .subscribe(patient => {
          alert.patientId = patient.id;
          alert.isTriggerEnabled = this.isTriggerEnabled;

          this.patientClient
            .patient_PostAlert(patient.id, alert)
            .pipe(
              takeUntil(this._destroy$),
              take(1)
            )
            .subscribe(resp => {
              this.isWorking = false;
              this.openSnackBar("Created Successfully!", "");
              this.backToFront();
            },
            err => {
              this.isWorking = false;
              console.log(err)
            });
        });
    }
  }

  deleteAlert() {
    if (!this.selectedAlert) {
      this.openSnackBar("Alert not found!", "");
      return;
    }

    this.patient$
      .pipe(filter(patient => !!patient), take(1))
      .subscribe(patient => {
        this.isWorking = true;
        this.patientClient
          .patient_DeleteAlert(patient.id, this.selectedAlert.id)
          .pipe(
            take(1),
            takeUntil(this._destroy$)
          )
          .subscribe(resp => {
            this.isWorking = false;
            this.selectedAlert = null;
            this.openSnackBar("Deleted Successfully!", "");
            this.backToFront();
          },
          err => {
            this.isWorking = false;
          });
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  toggleComplete(elem) {
    elem.isComplete = elem.isComplete ? false : true;
  }

  backToFront() {
    this.editedAlert = null;
    this.selectedAlert = null;
    this.notifiLocation_Scheduling = false;
    this.notifiLocation_Treatment = false;
    this.notifiLocation_Financial = false;
    this.notifiLocation_Communication = false;
    this.alertForm.reset();
    this.selectedColor = this.alertForm.value.color;
    this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
    this._cdr.detectChanges();
  }

  addEditFinancialReminder(isEditMode?: boolean) {
    this.alertForm.reset();
    if (!isEditMode) {
      this.selectedColor = '#FFFFFF';
      this.alertForm.patchValue({
        date: moment().toDate()
      })
    } else {
      this.selectedColor = this.selectedAlert.color ? this.selectedAlert.color : '#FFFFFF';
      this.alertForm.patchValue({
        date: moment(this.selectedAlert.date).toDate(),
        note: this.selectedAlert.note
      })
    }

  }

  updateIsComplete() {
    this.patient$.pipe(filter(patient => !!patient), take(1)).subscribe(patient => {
      this.isWorking = true;
      this.patientClient.patient_PostAlertComplete(patient.id, this.selectedAlert.id).subscribe(resp => {
        this.isWorking = false;
        this.selectedAlert = null;
        this.openSnackBar("Updated Successfully!", "");
      },
        err => {

        })
    })
  }

  clearIntervals(): void {
    this.patientAlertIntervals = [];
  }

  generateIntervals(): void {
    this.clearIntervals();
    let form = this.alertForm.value;

    for (let i = 0; i < form.amount; i++) {
      let alert: PatientAlertDto = new PatientAlertDto();

      alert.id = 0
      alert.type = form.alertType;
      alert.note = form.note;
      alert.color = form.color;
      alert.remindWhen = this.getIntervalDate(form.remindWhen, i, form.interval);
      this.patientAlertIntervals.push(alert);
    }
  }

  getIntervalDate(startDate: Date, times: number, type: financialIntervalEnum): Date {
    switch (type) {
      case financialIntervalEnum.Monthly:
        return moment(startDate).add(times * 1, 'M').toDate();
      case financialIntervalEnum.BiMonthly:
          return moment(startDate).add(times * 2, 'M').toDate();
      case financialIntervalEnum.Weekly:
        return moment(startDate).add(times * 7, 'd').toDate();
      case financialIntervalEnum.BiWeekly:
          return moment(startDate).add(times * 15, 'd').toDate();
      case financialIntervalEnum.Quarterly:
        return moment(startDate).add(times * 3, 'M').toDate();
      case financialIntervalEnum.Yearly:
        return moment(startDate).add(times * 1, 'y').toDate();
      case financialIntervalEnum.SemiAnnually:
        return moment(startDate).add(times * 6, 'M').toDate();
      default:
        return null;
    }
  }

  deleteAlertInterval(index: number): void {
    this.patientAlertIntervals.splice(index, 1);
  }

  getProcedure(){
    let procedureId = this.alertForm.value.procedureId;

    if(procedureId) {
      let procedure = this.procedures.find(item => item.id == procedureId);

      if(procedure) {
        return `${procedure.code} - ${procedure.description}`;
      }
    }

    return null;
  }
}

export enum financialIntervalEnum {
  Monthly = "Monthly",
  BiMonthly = "BiMonthly",
  Weekly = "Weekly",
  BiWeekly = "BiWeekly ",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  SemiAnnually = "SemiAnnually",
}
