import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterPipe } from './search-filter.pipe';
import { SequenceItemSortPipe } from './sequence-item-sort.pipe';
import { AgePipe } from './age.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [SequenceItemSortPipe],
  declarations: [SearchFilterPipe, SequenceItemSortPipe, AgePipe],
  exports: [CommonModule, SearchFilterPipe, SequenceItemSortPipe, AgePipe]
})


export class UtilityModule { }
