import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClickConfirm } from '../click-confirm.interface';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  templateUrl: './dialog-confirm-template.component.html',
  styleUrls: ['./dialog-confirm-template.component.css']
})
export class DialogConfirmTemplateComponent implements ClickConfirm, OnInit {

  _confirmText: any;

  @Output('confirm') confirm: EventEmitter<any> = new EventEmitter<any>();
  @Input() set confirmText(value: string) {
    this._confirmText = this.sanitizer.bypassSecurityTrustHtml(value);
  };
  @Input('showImage') showImage: boolean;
  @Input('positiveButtonText') positiveButtonText: string = "Yes";
  @Input('negativeButtonText') negativeButtonText: string = "No";
  @Input('boxpanelPositionClass') boxpanelPositionClass: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  confirmClick() {
    this.confirm.next(true);
  }

  cancelClick() {
    this.confirm.next(false);
  }

}
