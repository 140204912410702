import { createAction, props } from '@ngrx/store';
import { GoalDto2 } from 'src/app/shared/services/api.service';

export const LoadRequest = createAction(
  '[Treatment] Goal Load Request',
);

export const LoadSuccess = createAction(
  '[Treatment] Goal Load Success',
  props<{ goals: GoalDto2[] }>()
);

export const LoadFailure = createAction(
  '[Treatment] Goal Load Failure',
  props<{ err: any}>()
);

export const LoadOnceRequest = createAction(
  '[Treatment] Goal Load Once Request',
);
