import { createAction, props } from "@ngrx/store";
import { PatientRecallStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientRecall] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[PatientRecall] Load Success',
  props<{ patientRecalls: PatientRecallStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientRecall] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[PatientRecall] Add Request',
  props<{ patientRecall: PatientRecallStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[PatientRecall] Add Success',
  props<{ patientRecall: PatientRecallStoreEntity; }>()
);

export const AddFailure = createAction(
  '[PatientRecall] Add Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[PatientRecall] Update Request',
  props<{ patientRecall: PatientRecallStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[PatientRecall] Update Success',
  props<{ patientRecall: PatientRecallStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[PatientRecall] Update Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[PatientRecall] Delete Request',
  props<{ patientId: number, patientRecallId: number }>()
);

export const DeleteSuccess = createAction(
  '[PatientRecall] Delete Success',
  props<{ id: number }>()
);

export const DeleteFailure = createAction(
  '[PatientRecall] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientRecall] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientRecall] Select Success',
  props<{ patientRecall: PatientRecallStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientRecall] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientRecall] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientRecall] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientRecall] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[PatientRecall] Reset Error'
);
