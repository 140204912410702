import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiagnosisDto } from 'src/app/shared/services/api.service';
import { PatientDiagnosisDisplay } from './PatientDiagnosisDisplay';
import * as _ from 'lodash';

@Component({
  selector: 'app-patient-diagnosis-display',
  templateUrl: './patient-diagnosis-display.component.html',
  styleUrls: ['./patient-diagnosis-display.component.css']
})
export class PatientDiagnosisDisplayComponent implements OnInit {

  @Input('diagnoses') diagnoses: DiagnosisDto[];
  @Input('display') display: 'normal' | 'display' = 'normal';
  @Output('remove') removeEvent: EventEmitter<DiagnosisDto> = new EventEmitter<DiagnosisDto>();
  @Input('enableEdits') enableEdits: boolean;
  @Output('edit') editEvent: EventEmitter<{ diagnosis: DiagnosisDto, idx: number }> = new EventEmitter<{ diagnosis: DiagnosisDto, idx: number }>();

  editModePlans = {};

  constructor() { }

  ngOnInit() {
    this.diagnoses = _.orderBy(this.diagnoses, 'sortOrder', 'asc');
  }

  getLabelForPatientdiagnosis(patientDiagnosis: DiagnosisDto): PatientDiagnosisDisplay {
    let display: PatientDiagnosisDisplay = {
      label: '',
      sublabel: '',
      miscInfo: '',
      clinicalTerms: '',
      laymanTerms: ''
    };

    if (patientDiagnosis.children && patientDiagnosis.children.length > 0) {
      display.label += `${patientDiagnosis.name} &gt;`;
      const { label, sublabel, clinicalTerms, laymanTerms, miscInfo } = this.getLabelForPatientdiagnosis(patientDiagnosis.children[0]);

      if (label && label.length > 0)
        display.label += ` ${label}`;

      display.sublabel = sublabel;
      display.clinicalTerms = clinicalTerms;
      display.laymanTerms = laymanTerms;
      display.miscInfo = miscInfo;
    }
    else {
      display.sublabel = `${patientDiagnosis.name}`;
      if (patientDiagnosis.miscInfo && patientDiagnosis.miscInfo.length > 0)
        display.sublabel += ` ${patientDiagnosis.miscInfo}`;

      display.clinicalTerms = patientDiagnosis.clinicalTerms;
      display.laymanTerms = patientDiagnosis.laymanTerms;
      display.miscInfo = patientDiagnosis.miscInfo;
    }
    return display;
  }

  remove(diagnosis: DiagnosisDto) {
    this.removeEvent.next(diagnosis);
  }

  toggleEditPlan(diagnosisId: number, isEditMode: boolean) {
    if(this.enableEdits){
      this.editModePlans[diagnosisId] = isEditMode;
    }
  }

  saveDiagnosis(diagnosis:DiagnosisDto, idx: number, description:string, clinicalTerms: string, laymanTerms: string) {
    if(diagnosis.children[0].children && diagnosis.children[0].children.length > 0){
      diagnosis.children[0].children[0].miscInfo = description;
      diagnosis.children[0].children[0].laymanTerms = laymanTerms;
      diagnosis.children[0].children[0].clinicalTerms = clinicalTerms;
      this.editEvent.next({ diagnosis: diagnosis.children[0].children[0], idx: idx});
    } else {
      diagnosis.children[0].miscInfo = description;
      diagnosis.children[0].laymanTerms = laymanTerms;
      diagnosis.children[0].clinicalTerms = clinicalTerms;
      this.editEvent.next({ diagnosis: diagnosis.children[0], idx: idx});
    }
    this.toggleEditPlan(diagnosis.id, false);
  }

}
