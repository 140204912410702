import { Action, createReducer, on } from "@ngrx/store";
import * as PatientRecallStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PatientRecallStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedPatientRecallId: null
  })),
  on(PatientRecallStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patientRecalls, { ...s, isLoading: false, error: null })
  }),
  on(PatientRecallStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //ADD
  on(PatientRecallStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientRecallStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.patientRecall,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientRecallStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UPDATE
  on(PatientRecallStoreActions.UpdateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientRecallStoreActions.UpdateSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.patientRecall,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientRecallStoreActions.UpdateFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DELETE
  on(PatientRecallStoreActions.DeleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientRecallStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(
      action.id,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientRecallStoreActions.DeleteFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(PatientRecallStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedPatientRecallId: null
  })),
  on(PatientRecallStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientRecallId: action.patientRecall && action.patientRecall.id
  })),
  on(PatientRecallStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(PatientRecallStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientRecallStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientRecallId: null
  })),
  on(PatientRecallStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(PatientRecallStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
