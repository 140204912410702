import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as PhotoTypeStoreActions from './actions';
import * as PhotoTypeStoreSelectors from './selectors';
import { SettingsClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class PhotoTypeStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _settingsClient: SettingsClient) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoTypeStoreActions.LoadRequest),
    switchMap((action) =>
      this._settingsClient.settings_GetPhotoTypes().pipe(
        map((result) => PhotoTypeStoreActions.LoadSuccess({ photoTypes: result })),
        catchError((err: HttpErrorResponse) => of(PhotoTypeStoreActions.LoadFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoTypeStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(PhotoTypeStoreSelectors.selectPhotoTypeById(action.id)).pipe(map((result) => PhotoTypeStoreActions.SelectSuccess({ photoType: result })))
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoTypeStoreActions.UpdateRequest),
    switchMap((action) =>
      this._settingsClient.settings_PutPhotoType(action.id, action.photoType).pipe(
        switchMap(() => this._settingsClient.settings_GetPhotoType(action.id)),
        map((result) => PhotoTypeStoreActions.UpdateSuccess({ photoType: result })),
        catchError((err: HttpErrorResponse) => of(PhotoTypeStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoTypeStoreActions.AddRequest),
    switchMap((action) =>
      this._settingsClient.settings_PostPhotoType(action.photoType).pipe(
        map((result) => PhotoTypeStoreActions.AddSuccess({ photoType: result })),
        catchError((err: HttpErrorResponse) => of(PhotoTypeStoreActions.AddFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoTypeStoreActions.DeleteRequest),
    switchMap((action) =>
      this._settingsClient.settings_DeletePhotoType(action.id).pipe(
        map(() => PhotoTypeStoreActions.DeleteSuccess({ id: action.id })),
        catchError((err: HttpErrorResponse) => of(PhotoTypeStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PhotoTypeStoreActions.LoadOneRequest),
    switchMap((action) =>
      this._settingsClient.settings_GetPhotoType(action.id).pipe(
        map((photoType) => PhotoTypeStoreActions.LoadOneSuccess({ photoType: photoType })),
        catchError((err: HttpErrorResponse) => of(PhotoTypeStoreActions.LoadOneFailure({ error: err.message })))
      )
    )
  );
}
