import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatSelectModule, MatTooltipModule, MatDatepickerModule, MatProgressBarModule, MatCardModule, MatRippleModule } from '@angular/material';
import { CardModule } from 'src/app/shared/models';
import { UtilityModule } from '../../utility/utility.module';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { BasicDetailsBackComponent } from './basic-details-back/basic-details-back.component';
import { BasicDetailsFrontComponent } from './basic-details-front/basic-details-front.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { EvolutionColors } from 'src/app/shared/enums';
import { TextMaskModule } from 'angular2-text-mask';
import { WarningDialogComponent } from './basic-details-back/warning-dialog/warning-dialog.component';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { UserPermissionsModule } from '@shared/user-permissions/user-permissions.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';


@CardModule({
  selector: 'patient-basic-details',
  front: BasicDetailsFrontComponent,
  back: BasicDetailsBackComponent,
  header: 'Patient',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faUser', actionName: '', isFaIcon: true}],
  order: 80,
  color: EvolutionColors.Blue,
})
@NgModule({
  declarations: [BasicDetailsFrontComponent, BasicDetailsBackComponent, WarningDialogComponent],
  entryComponents: [BasicDetailsFrontComponent, BasicDetailsBackComponent, WarningDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    UtilityModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatCardModule,
    MatAutocompleteModule,
    TextMaskModule,
    MomentDateModule,
    UserPermissionsModule,
    MatRippleModule,
    FontAwesomeModule
  ],
})
export class PatientBasicDetailsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
