import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanoramicViewerComponent } from './panoramic-viewer.component';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { MatButtonModule, MatCardModule, MatIconModule, MatListModule, MatProgressBarModule, MatSidenavModule } from '@angular/material';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';


@CardModule({
  selector: 'panoramic-viewer',
  front: PanoramicViewerComponent,
  back: null,
  header: '',
  width: 710,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faPanorama', actionName: '', isFaIcon: true}],
  order: 160
})
@NgModule({
  declarations: [PanoramicViewerComponent],
  entryComponents: [PanoramicViewerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatSidenavModule,
    MomentDateModule
  ],
})
export class PanoramicModule { }
