export enum AnalyticGroupPermissionKeys {
  Consults = '__Consults__',
  Debands = '__Debands__',
  Exams = '__Exam__',
  OBSRecalls = '__OBS-Recall__',
  Records = '__Records__',
  Starts = '__Starts__',
  MTDRecaps = '__MTD-Recap__',
  NoShows = '__No-Show__',
  StatusTotals = '__Status-Totals__',
  NewProspects = '__New-Prospects__'
}
