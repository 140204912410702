import { Action, createReducer, on } from "@ngrx/store";
import * as PatientImagingStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PatientImagingStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedPatientImagingId: null
  })),
  on(PatientImagingStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patientImagings, { ...s, isLoading: false, error: null })
  }),
  on(PatientImagingStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //ADD
  on(PatientImagingStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientImagingStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.patientImaging,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientImagingStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //ADD MULTIPLE
  on(PatientImagingStoreActions.AddMultipleRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientImagingStoreActions.AddMultipleSuccess, (state, action) => (
    featureAdapter.upsertMany(
      action.patientImagings,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientImagingStoreActions.AddMultipleFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(PatientImagingStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedPatientImagingId: null
  })),
  on(PatientImagingStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientImagingId: action.patientImaging && action.patientImaging.id
  })),
  on(PatientImagingStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(PatientImagingStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientImagingStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientImagingId: null
  })),
  on(PatientImagingStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(PatientImagingStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
