import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { MarketingPackageStoreEffects } from './effects';
import { MARKETING_PACKAGE_FEATURE_KEY } from './featurekey';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(MARKETING_PACKAGE_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([MarketingPackageStoreEffects])
  ]
})
export class MarketingPackageStoreModule { }
