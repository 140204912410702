import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { PATIENT_MESSAGE_FEATURE_KEY } from './featurekey';
import { featureAdapter, State, PatientMessageStoreEntity } from './state';

export const selectPatientMessageState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_MESSAGE_FEATURE_KEY);

const { selectEntities, selectIds, selectTotal, selectAll } = featureAdapter.getSelectors(selectPatientMessageState);
export const selectPatientMessageEntities = selectEntities;
export const selectPatientMessageIds = selectIds;
export const selectPatientMessageTotal = selectTotal;
export const selectAllPatientMessages = selectAll;

export const selectFilteredMessages = (minDate?: Date, maxDate?) =>
  createSelector(selectAllPatientMessages, (messages) => filterMessagesByDate(messages, minDate, maxDate));

function filterMessagesByDate(messages: PatientMessageStoreEntity[], minDate: Date, maxDate: Date) {
  return messages.filter(message => {
    let bitFlag: number = 0;
    if(minDate)
      bitFlag += message.sentDate >= minDate ? 0 : (0 >> 1);
    if(maxDate)
      bitFlag += message.sentDate <= maxDate ? 0 : (0 >> 2);
    // value of 1 is minDate failure, 2 is maxDate failure, 3 is both(?)
    return bitFlag === 0;
  });
}

export const selectPreviousMessagesEof = createSelector(
  selectPatientMessageState,
  (state) => state.previousEOF
);
export const selectFutureMessagesEof = createSelector(
  selectPatientMessageState,
  (state) => state.futureEOF
);

export const selectIsLoading = createSelector(
  selectPatientMessageState,
  (state) => state.isLoading
);

export const selectCopiedMessage = createSelector(
  selectPatientMessageState,
  (state) => state.copiedMessage
);
