import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientModelBoxDto, IPatientModelBoxDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientModelBoxStoreEntity> = createEntityAdapter<PatientModelBoxStoreEntity>({
  selectId: patientModelBox => patientModelBox.id,
  sortComparer: (a: PatientModelBoxStoreEntity, b: PatientModelBoxStoreEntity): number => b.date.getTime() - a.date.getTime()
});

export interface State extends EntityState<PatientModelBoxStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientModelBoxId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientModelBoxId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientModelBoxStoreEntity extends PatientModelBoxDto {
  constructor(data?: IPatientModelBoxDto) {
    super(data);
  }
}
