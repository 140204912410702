import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { STATIONARY_FEATURE_KEY } from './featurekey';
import { featureAdapter, StationaryStoreEntity, State } from './state';

export const selectStationariesState: MemoizedSelector<object, State> = createFeatureSelector<State>(STATIONARY_FEATURE_KEY);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectStationariesState);
export const selectStationaryIds = selectIds;
export const selectAllStationaries = selectAll;
export const selectAllStationariesEntities = selectEntities;

export const getSelectedStationary = createSelector(
  selectStationariesState,
  selectAllStationaries,
);

export const selectStationaryById = (id: number) =>
  createSelector(selectAllStationaries, (allStationaries: StationaryStoreEntity[]) =>
    allStationaries ? allStationaries.find((rp) => rp.id === id) : null
  );

export const selectStationariesError: MemoizedSelector<object, any> = createSelector(selectStationariesState, (state) => state.error);

export const selectStationariesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectStationariesState, (state: State) => state.isLoading);
