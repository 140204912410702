import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ITwainDto, TwainDto } from "@shared/services/api.service";

export const featureAdapter: EntityAdapter<TwainStoreEntity> = createEntityAdapter<TwainStoreEntity>({
  selectId: twain => twain.id,
});

export interface State extends EntityState<TwainStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});

export class TwainStoreEntity extends TwainDto {
  constructor(data?: ITwainDto) {
    super(data);
  }
}
