import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { State } from '../root-state';
import * as TemplateStoreActions from './actions';
import { HubEventArea, HubEventType, SettingsClient } from 'src/app/shared/services/api.service';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from '../signalr-hub-store';
import { AuthStoreActions } from '../auth-store';
import * as TemplateStoreSelectors from './selectors';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class TemplateStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _settingsClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(TemplateStoreActions.LoadRequest),
    switchMap((action) => {
      if (action.id == null) {
        return this._settingsClient.settings_GetTemplates().pipe(
          map((result) => TemplateStoreActions.LoadSuccess({ templates: result })),
          catchError((err: HttpErrorResponse) => of(TemplateStoreActions.LoadFailure({ error: err.message }))),
          take(1)
        )
      } else {
        return this._settingsClient.settings_GetTemplate(action.id).pipe(
          map((result) => TemplateStoreActions.LoadSuccess({ templates: result })),
          catchError((err: HttpErrorResponse) => of(TemplateStoreActions.LoadFailure({ error: err.message }))),
          take(1)
        )
      }

    })
  );

  @Effect()
  templateUpdateEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    filter(
      (action) =>
        action.event.eventArea == HubEventArea.TemplateEvent &&
        action.event.entityType == SettingsClient.DocumentTemplateDto
    ),
    map((action) =>
      action.event.eventType != HubEventType.Removed
        ? TemplateStoreActions.LoadRequest({ id: action.event.entityId })
        : TemplateStoreActions.DeleteSuccess({ id: action.event.entityId })
    ),
  );
}
