import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LimitationDto, RiskDto, TreatmentDto } from 'src/app/shared/services/api.service';
import { Store, ActionsSubject } from '@ngrx/store';
import { Observable, Subject, of, iif } from 'rxjs';
import { take, switchMap, share, tap, takeUntil, filter } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import {
  PatientsStoreSelectors,
  PatientStoreEntity,
  RootStoreState,
  PatientTreatmentStoreActions
} from 'src/app/root-store';
import {
  SettingsClient,
  LocationClient
} from 'src/app/shared/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-limitations-risk-editor',
  templateUrl: './limitations-risk-editor.component.html',
  styleUrls: ['./limitations-risk-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LimitationsRiskEditorComponent implements OnInit {
  @Input() isRisk:boolean = false;
  @Input('treatment') patientTreatment: TreatmentDto;
  private _selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  dataArr = [];
  isWorking:boolean = false;
  selectedItems: LimitationDto[] = [];
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _cdr: ChangeDetectorRef,
    private _settingsClient:SettingsClient,
    private _locationClient:LocationClient,
    private _actions$: ActionsSubject,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData(){
    this.isWorking = true;
    if(this.isRisk){
      this._settingsClient.settings_GetRisks().subscribe(resp => {
        this.dataArr = _.orderBy(resp, ['sortOrder', 'name'], ['asc', 'asc']);
        this.isWorking = false;
        this.setSelectedDataItem();
        this._cdr.detectChanges();
      },
      err => {
        console.log(err);
        this.isWorking = false;
      })
    } else {
      this._settingsClient.settings_GetLimitations().subscribe(resp => {
        this.dataArr = _.orderBy(resp, ['sortOrder', 'name'], ['asc', 'asc']);
        this.isWorking = false;
        this.setSelectedDataItem();
        this._cdr.detectChanges();
      },
      err => {
        console.log(err);
        this.isWorking = false;
      })
    }
  }

  setSelectedDataItem(): void {
    if(this.isRisk){
      this.selectedItems = [...this.patientTreatment.risks];
    } else {
      this.selectedItems = [...this.patientTreatment.limitations];
    }
    this._cdr.detectChanges();
  }

  addDataItem(dataItem){
    if(this.isRisk){
      let risks: RiskDto[] = [];
      let riskDto = new RiskDto({...dataItem, id: 0, treatmentId: this.patientTreatment.id});
      risks.push(riskDto);

      this._selectedPatient$
        .pipe(
          tap((_) => this.isWorking = true),
          switchMap((patient) =>
            iif(
              () => this.patientTreatment.id == 0,
              of(risks),
              this._locationClient.location_PostRisks(patient.locationId, patient.id, this.patientTreatment.id, risks).pipe(share())
            )
          ),
          take(1),
          tap((_) => this.isWorking = false),
        )
        .subscribe((result) => {
          this._selectedPatient$.subscribe(patient => {
            this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: patient.locationId, patientId: patient.id }))
            this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(result => {
              let _treatment = result.plans.find(treament => treament.id == this.patientTreatment.id);
              this.patientTreatment.risks = _treatment.risks;
              this.setSelectedDataItem();
              this._cdr.detectChanges();
            })
          })
        },
        err => {
          this.isWorking = false
        });
    } else {
      let limitations: LimitationDto[] = [];
      let limitationDto = new LimitationDto({...dataItem, id: 0, treatmentId: this.patientTreatment.id})
      limitations.push(limitationDto);

      this._selectedPatient$
        .pipe(
          tap((_) => this.isWorking = true),
          switchMap((patient) =>
            iif(
              () => this.patientTreatment.id == 0,
              of(limitations),
              this._locationClient.location_PostLimitations(patient.locationId, patient.id, this.patientTreatment.id, limitations).pipe(share())
            )
          ),
          take(1),
          tap((_) => this.isWorking = false),
        )
        .subscribe((result) => {
          this._selectedPatient$.subscribe(patient => {
            this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: patient.locationId, patientId: patient.id }))
            this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(result => {
              let _treatment = result.plans.find(treament => treament.id == this.patientTreatment.id);
              this.patientTreatment.limitations = _treatment.limitations;
              this.setSelectedDataItem();
              this._cdr.detectChanges();
            })
          })
        },
        err => {
          this.isWorking = false
        });
    }
  }

  removeItem(deleteItem: LimitationDto | RiskDto) {
    if(this.isRisk){
      this._selectedPatient$
      .pipe(
        tap((_) => this.isWorking = true),
        switchMap((patient) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(null),
            this._locationClient.location_DeleteRisk(patient.locationId, patient.id, this.patientTreatment.id, deleteItem.id).pipe(share())
          )
        ),
        tap((_) => this.isWorking = false),
        take(1)
      )
      .subscribe((_) => {
        const index = this.patientTreatment.risks.findIndex(g => g.id == deleteItem.id);
        this.patientTreatment.risks.splice(index, 1);
        this.setSelectedDataItem();
      });

    } else {
      this._selectedPatient$
      .pipe(
        tap((_) => this.isWorking = true),
        switchMap((patient) =>
          iif(
            () => this.patientTreatment.id == 0,
            of(null),
            this._locationClient.location_DeleteLimitation(patient.locationId, patient.id, this.patientTreatment.id, deleteItem.id).pipe(share())
          )
        ),
        tap((_) => this.isWorking = false),
        take(1)
      )
      .subscribe((_) => {
        const index = this.patientTreatment.limitations.findIndex(g => g.id == deleteItem.id);
        this.patientTreatment.limitations.splice(index, 1);
        this.setSelectedDataItem();
      });
    }
  }

  editPlan(editObject) {
    let patientPlan: LimitationDto | RiskDto = editObject.plan;

    this._selectedPatient$
      .pipe(
        tap((_) => this.isWorking = true),
        switchMap((patient) => {
          if (this.patientTreatment.id == 0){
            return of(patientPlan);
          } else {
            if(this.isRisk){
              return this._locationClient.location_PutRisk(patient.locationId, patient.id, this.patientTreatment.id, patientPlan.id, patientPlan).pipe(share());
            } else {
              return this._locationClient.location_PutLimitation(patient.locationId, patient.id, this.patientTreatment.id, patientPlan.id, patientPlan).pipe(share());
            }
          }
        }),
        tap((_) => this.isWorking = false),
        take(1)
      )
      .subscribe((result) => {
        this._selectedPatient$.pipe(filter(patient => !!patient)).subscribe(patient => {
          this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: patient.locationId, patientId: patient.id }))
          this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(result => {
            let _treatment = result.plans.find(treament => treament.id == this.patientTreatment.id);
            if(this.isRisk){
              this.patientTreatment.risks = _treatment.risks
            } else {
              this.patientTreatment.limitations = _treatment.limitations;
            }
            this.setSelectedDataItem();
            this._cdr.detectChanges();
          })
        })
      });
  }
}
