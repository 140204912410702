import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, ActionsSubject } from '@ngrx/store';
import { filter, shareReplay, take, map, switchMapTo, takeUntil } from 'rxjs/operators';
import { PatientMessageStoreActions, PatientMessageStoreSelectors, PatientsStoreSelectors, RootStoreState, CommuncationDeskStoreSelectors } from 'src/app/root-store';
import { PatientMessageStoreEntity } from 'src/app/root-store/patient-message-store/state';
import { FilterMessageDialogActions, FilterMessageDialogComponent, IFilterMessageCriteria, ISequenceGroupFilter } from 'src/app/patient-details/communications/patient-conversation/filter-message-dialog/filter-message-dialog.component';
import * as _ from 'lodash';
import { 
  MessageTypeEnum, 
  ProviderStatusEnum, 
  ProviderStatusDto, 
  MessageDto, 
  MessageChannelTypeEnum,
  CommunicationDeskDto,
  RecipientDto,
  PatientClient
 }  from 'src/app/shared/services/api.service';
import * as moment from 'moment';
import { faBell, faComment, faEnvelope, faFolderOpen, faEnvelopeOpen, faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-future-messages-dialog',
  templateUrl: './future-messages-dialog.component.html',
  styleUrls: ['./future-messages-dialog.component.scss']
})
export class FutureMessagesDialogComponent implements OnInit, OnDestroy {
  MessageTypeEnum = MessageTypeEnum;
  MessageChannelTypeEnum = MessageChannelTypeEnum;
  Date = Date;
  patientId = this.data.patientId;
  filterMessageCriteria: IFilterMessageCriteria;
  patientMessagesIsLoading$: Observable<boolean> = this._store$.select(PatientMessageStoreSelectors.selectIsLoading);
  private _communicationDesks$: Observable<CommunicationDeskDto[]> = this._store$.select(CommuncationDeskStoreSelectors.selectAllDesks);
  private _filterPatientMessages$ = new BehaviorSubject<void>(undefined);
  patientMessages$: Observable<PatientMessageStoreEntity[]> = this._filterPatientMessages$.pipe(
    switchMapTo(
      this.patientClient.patient_GetMessages(this.patientId, undefined, -1)
      .pipe(
        map((_messages) => {
          
          let messages:PatientMessageStoreEntity[] = _messages.map(item => new PatientMessageStoreEntity(item));

          messages = messages.filter(message => message.messageType != MessageTypeEnum.Notification)

          if (!this.filterMessageCriteria) return messages;

          if ((this.filterMessageCriteria.sequenceIds && this.filterMessageCriteria.sequenceIds.length > 0) ||
            this.filterMessageCriteria.isNotSequence) {
            messages = _.filter(messages, (message) => {
              let isIncluded: boolean = false;
              if (this.filterMessageCriteria.sequenceIds && this.filterMessageCriteria.sequenceIds.length > 0)
                isIncluded = _.includes(this.filterMessageCriteria.sequenceIds, message.sequenceGroupId);

              if (!isIncluded && this.filterMessageCriteria.isNotSequence)
                isIncluded = !message.sequenceGroupId;

              return isIncluded;
            });
          }

          if (this.filterMessageCriteria.isInboundMessage) {
            messages = _.filter(messages, (message) => {
              return message.messageType == MessageTypeEnum.Inbound;
            });
          }

          if (this.filterMessageCriteria.isOutboundMessage) {
            messages = _.filter(messages, (message) => {
              return message.messageType == MessageTypeEnum.Outbound;
            });
          }

          if (this.filterMessageCriteria.dateRange && this.filterMessageCriteria.dateRange.isSelected) {
            if (this.filterMessageCriteria.dateRange.from) {
              messages = _.filter(messages, (message) => {
                return message.delaySendDate >= this.filterMessageCriteria.dateRange.from;
              });
            }

            if (this.filterMessageCriteria.dateRange.to) {
              messages = _.filter(messages, (message) => {
                return message.delaySendDate <= this.filterMessageCriteria.dateRange.to;
              });
            }
          }

          messages = _.map(messages, (message) => {
            message.safeHtml = message.isHTML ? this.formatMessageHtml(message.htmlBody) : this.formatMessageText(message.textBody);
            message.canDelete = this.showDeleteAction(message);
            message.canEdit = false;
            return message;
          });

          return messages;
        })
      )
    ),
    shareReplay(1)
  );

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public _matDialogRef: MatDialogRef<FutureMessagesDialogComponent>,
    private _store$: Store<RootStoreState.State>,
    private _domSanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private patientClient:PatientClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _actions$: ActionsSubject,
  ) { }

  ngOnInit() {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  closeDialog(){
    this._matDialogRef.close(null);
  }

  showDeleteAction(message: PatientMessageStoreEntity) {
    return message.delaySendDate.valueOf() > Date.now();
  }

  formatMessageHtml(message: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(message);
  }

  formatMessageText(message: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(message && message.replace('\n', '<br>'));
  }

  loadNextFuturePage() {
    this._store$.dispatch(PatientMessageStoreActions.LoadNextFuturePageRequest());
  }

  getMsgDatetime(dateTime){
    return moment(dateTime).format('MM/DD/YYYY, hh:mm a');
  }

  getMsgStatus(status:ProviderStatusDto){
    if(status.status == ProviderStatusEnum.Success){
      if(status.isRead){
        return 'sent and opened'
      } else {
        return 'successfully sent'
      }
    } else if(status.status == ProviderStatusEnum.Unknown){
      return 'unknown'
    } else if(status.status == ProviderStatusEnum.Error){
      return `Error: ${status.errorMessage}`
    }

    return ''
  }

  getIconColor(message:MessageDto){
    if(message.messageType == MessageTypeEnum.Outbound){
      if(message.channelType == MessageChannelTypeEnum.SMS || message.channelType == MessageChannelTypeEnum.Email){
        if(message.providerStatus.status == ProviderStatusEnum.Unknown){
          return '#646464';
        } else if(message.providerStatus.status == ProviderStatusEnum.Processing){
          return '#646464';
        } else if(message.providerStatus.status == ProviderStatusEnum.Success){
          return '#FFFFFF';
        } else if(message.providerStatus.status == ProviderStatusEnum.Error){
          return '#F11212';
        }
      }
    }

    return '#646464';
  }

  getFaIcon(messageChannel: MessageChannelTypeEnum, messageType: MessageTypeEnum, status: ProviderStatusDto) {
    switch (messageType) {
      case 'Inbound':
      case 'Outbound':
        switch (messageChannel) {
          case 'SMS':
            return faComment;
          case 'Email':
            if (status.status == ProviderStatusEnum.Success && status.isRead) {
              return faEnvelopeOpen;
            } else {
              return faEnvelope;
            }
        }
      case 'Notification':
        return faBell;
      default:
        return faFolderOpen;
    }
  }

  getDesk(communicationDeskId: number) {
    return this._communicationDesks$.pipe(map((desks) => desks.find((d) => d.id == communicationDeskId)));
  }

  removeMessage(patientId: number, messageId: number) {
    this._store$.dispatch(PatientMessageStoreActions.DeleteMessageRequest({ patientId: patientId, messageId: messageId }));

    this._actions$
        .pipe(ofType(PatientMessageStoreActions.DeleteMessageSuccess), take(1), takeUntil(this._destroy$)) //listen for success then flip
        .subscribe((result) => {
          this._filterPatientMessages$.next();
        });


  }

  willShowExpandButton(msgbox){
    let childNodes = msgbox.parentNode.children;
    for (let i = 0; i < childNodes.length; i++) {
      let childNode = childNodes[i];
      if(childNode.classList.contains('boxcontent')){
        if(childNode.clientHeight < 110){
          return false
        }
      }
    }
    return true;
  }

  reduceToRecipientNames(recipients: RecipientDto[]) {
    return recipients.map((recipient) => recipient.fullName).toString();
  }

  reduceToRecipientPhoneEmails(recipients: RecipientDto[]) {
    return recipients.map((recipient) => recipient.phoneOrEmail).toString();
  }

  toggleExpanButtonLabel(msgbox){
    let childNodes = msgbox.parentNode.children;
    for (let i = 0; i < childNodes.length; i++) {
      let childNode = childNodes[i];
      if(childNode.classList.contains('boxcontent') && childNode.classList.contains('boxcontent-expand')){
        return true;
      }
    }
    return false
  }

  toggleExpand(evt){
    evt.stopPropagation();
    let childNodes = evt.target.parentNode.parentNode.children;
    for (let i = 0; i < childNodes.length; i++) {
      let childNode = childNodes[i];
      if(childNode.classList.contains('boxcontent')){
        if(childNode.classList.contains('boxcontent-expand')){
          childNode.classList.remove('boxcontent-expand');
        } else {
          childNode.classList.add('boxcontent-expand');
        }
      }
    }
  }

  

}
