import { createAction, props } from '@ngrx/store';
import { ICard } from '../../shared/models/cards';
import { IOpenCard } from './IOpenCard';

//INIT
export const CardsInitSuccess = createAction(
  '[Cards] Init',
  props<{ openCards: IOpenCard[]; }>()
)

//LOAD (Not implemented)
export const LoadRequestAction = createAction(
  '[Cards] Load Success'
);
export const LoadSuccessAction = createAction(
  '[Cards] Load Success',
  props<{ items: ICard[] }>()
);
export const LoadFailureAction = createAction(
  '[Cards] Load Failure',
  props<{ payload: { error: string } }>()
);

//ADD
export const AddRequestAction = createAction(
  '[Cards] Add Request',
  props<{ card: ICard }>()
);
export const AddSuccessAction = createAction(
  '[Cards] Add Success',
  props<{ card: ICard }>()
);
export const AddFailureAction = createAction(
  '[Cards] Add Failure',
  props<{ error: string }>()
);

//DELETE
export const DeleteRequestAction = createAction(
  '[Cards] Delete Request',
  props<{ selector: string }>()
);
export const DeleteSuccessAction = createAction(
  '[Cards] Delete Success',
  props<{ selector: string }>()
);
export const DeleteFailureAction = createAction(
  '[Cards] Delete Failure',
  props<{ error: string }>()
);

//DISPLAY
export const OpenCardRequest = createAction(
  '[Cards] Open Request',
  props<{ selector: string; order?: number; }>()
);
export const SetOpenCardsRequest = createAction(
  '[Cards] Set Open Cards Request',
  props<{ cards: IOpenCard[] }>()
);
export const OpenSuccess = createAction(
  '[Cards] Open Success',
  props<{ cards: IOpenCard[]  }>()
);

export const CloseCardRequest = createAction(
  '[Cards] Close Request',
  props<{ selector: string }>()
);
export const CloseCardSuccess = createAction(
  '[Cards] Close Success',
  props<{ cards: IOpenCard[] }>()
);

export const ChangeCardOrderRequest = createAction(
  '[Cards] Change Order Request',
  props<{ selector: string, order: number }>()
);
export const ChangeCardOrderSuccess = createAction(
  '[Cards] Change Order Success',
  props<{ cards: IOpenCard[] }>()
);

export const OpenFavoriteCardsRequest = createAction(
  '[Cards] Open Favorite Cards Request'
);
