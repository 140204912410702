import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNewLinePipe',
})
export class ReplaceNewLinePipe implements PipeTransform {
  transform(value: string) {
    return value.replace(/\n/g, '<br>');
  }
}
