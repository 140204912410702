import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_RECALL_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientRecallStoreEntity, State } from './state';

export const selectPatientRecallState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_RECALL_FEATURE_KEY);

export const getSelectedPatientRecallId = createSelector(selectPatientRecallState, (state) => state.selectedPatientRecallId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientRecallState);
export const selectPatientRecallIds = selectIds;
export const selectAllPatientRecalls = selectAll;
export const selectAllPatientRecallEntities = selectEntities;

export const getSelectedPatientRecall = createSelector(
  selectPatientRecallState,
  selectAllPatientRecalls,
  (state, allPatientRecalls: PatientRecallStoreEntity[]) =>
    allPatientRecalls ? allPatientRecalls.find((rp) => rp.id === state.selectedPatientRecallId) : null
);

export const selectPatientRecallById = (id: number) =>
  createSelector(selectAllPatientRecalls, (allPatientRecalls: PatientRecallStoreEntity[]) =>
    allPatientRecalls ? allPatientRecalls.find((rp) => rp.id === id) : null
  );

export const selectPatientRecallsError: MemoizedSelector<object, any> = createSelector(selectPatientRecallState, (state) => state.error);

export const selectPatientRecallsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientRecallState, (state: State) => state.isLoading);
