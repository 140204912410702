import { featureAdapter, State } from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMMUNICATION_DESK_FEATURE_KEY } from './featurekey';

export const selectCommunicationDeskState = createFeatureSelector<State>(COMMUNICATION_DESK_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = featureAdapter.getSelectors(selectCommunicationDeskState);

export const selectAllDesks = selectAll;
export const selectDeskEntities = selectEntities;
export const selectDeskIds = selectIds;
export const selectTotalDesks = selectTotal;

export const selectDesk = (id: number) => createSelector(selectAllDesks, (desks) => desks.find((d) => d.id == id));
