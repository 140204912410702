import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatOption } from '@angular/material';
import { InsuranceCompanyVM } from 'src/app/shared/services/models.dto';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SelectComponent {
  @Input() list = [];
  @Input() model: NgModel;
  @Input() errmsg: string = 'Select an option.';
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() value: string;
  @Input() disabled: boolean = false;
  @Input() linear: false;
  @Input() isProcedureGroup: false;
  @Input() isInsuranceCompany: false;
  @Input() unselect: false;
  @Input() unselectLabel: string = '-- None --';
  @Input() backgroundColor: string;
  @Input() keyValue: string = 'id';
  @Input() keyLabel: string = 'name';
  @Input() groupKeyLabel: string = 'name';
  @Input() subGroup = '';
  @Input() onCreateOptionGroup: Function;
  @Input() onCreateOption: Function;
  @Input() matTooltip: string;
  @Input() optionsClass: string;
  @Input() hasError: boolean = false;
  @Input() classList: string = 'native';
  @Input() default: string;
  @Output() valueChange = new EventEmitter();
  @Input() isTable: false;
  @Input() triggeredSelectdOptn:string = "";
  @Input() hasBackground:boolean;
  @Input() isHTML:boolean = false;

  onChange(e) {
    if (this.required) {
      this.hasError = e.value == null || e.value <= 0 || e.value.length <= 0;
    }
    this.hideErrorMessage();
    this.valueChange.emit(e.value);
    if(this.isTable){
      this.triggeredSelectdOptn = this.getElmentById(e.value);
    }
  }

  validate() {
    this.hasError = false;

    if (this.required) {
      this.hasError = this.model == null;
      return !this.hasError;
    }

    return !this.hasError;
  }

  showErrorMessage(message?: string) {
    if (message) { this.errmsg = message; }
    this.hasError = true;
  }

  hideErrorMessage() {
    this.hasError = false;
  }

  getElmentById(id){
    if(this.list && this.model){
      let element = this.list.find(listitem => listitem.id == id);
      if(element != -1){
        return element.name
      }
      return null;
    }
    return null;
  }
}
