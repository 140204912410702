import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { EvolutionColors } from 'src/app/shared/enums';
import { CardModule } from 'src/app/shared/models';
import { ElasticPermanentPrimaryComponent } from '../../elastic-sequence-module/elastic-permanent-primary/elastic-permanent-primary.component';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { ToothgridComponent } from './toothgrid.component';
import { MatSelectModule, MatProgressBarModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';


@CardModule({
  selector: 'toothgrid',
  front: ToothgridComponent,
  back: null,
  header: 'Toothgrid',
  width: 710,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faTooth', actionName: '', isFaIcon: true}],
  order: 60,
  color: EvolutionColors.Orange,
})
@NgModule({
  declarations: [ToothgridComponent, ElasticPermanentPrimaryComponent],
  entryComponents: [ToothgridComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ClickOutsideModule, PerfectScrollbarModule, MatSelectModule, MatProgressBarModule, MatIconModule],
})
export class ToothgridModule {}
