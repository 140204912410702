import { createAction, props } from '@ngrx/store';
import { ProviderStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction('[Providers] Load Request', props<{ page?: number; pageSize?: number; locationId?: number }>());
export const LoadSuccess = createAction('[Providers] Load Success', props<{ providers: ProviderStoreEntity[] }>());
export const LoadFailure = createAction('[Providers] Load Failure', props<{ error: string }>());

//LOAD ONE
export const LoadOneRequest = createAction('[Providers] Load One Request', props<{ id: number; }>());
export const LoadOneSuccess = createAction('[Providers] Load One Success', props<{ provider: ProviderStoreEntity }>());
export const LoadOneFailure = createAction('[Providers] Load One Failure', props<{ error: string }>());

//ADD
export const AddRequest = createAction('[Providers] Add Request', props<{ provider: ProviderStoreEntity }>());
export const AddSuccess = createAction('[Providers] Add Success', props<{ provider: any }>());
export const AddFailure = createAction('[Providers] Delete Failure', props<{ error: string }>());

//DELETE
export const DeleteRequest = createAction('[Providers] Delete Request', props<{ id: number }>());
export const DeleteSuccess = createAction('[Providers] Delete Success', props<{ id: number }>());
export const DeleteFailure = createAction('[Providers] Delete Failure', props<{ error: string }>());

//UTILITY
export const Unload = createAction('[Providers] Unload');
