import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../../core/message.service';


export enum KEY_CODE {
  SPACEBAR = 32,
  ENTER = 13,
  ARROW_DN = 40,
  ARROW_UP = 38
}

@Component({
	selector: 'app-dropdown-shared',
	templateUrl: './dropdown-shared.component.html',
	styleUrls: ['./dropdown-shared.component.css']
})
export class DropdownSharedComponent implements OnInit {
	@Input() options: any;
	@Input() open: any;
	@Input() label: any;
	@Input() position: any;
	@Input() address: boolean = false;

	@Output() value = new EventEmitter();

	showdd: boolean = false;
	dd_id: any;
	subscription: Subscription;

	_ddoptionsArr: any[] = [];
	ddplaceholder: any;
	
	focused:boolean = false;
	ddIndxByKeybrd: any = 0;
	
	selectedIndx: any = 0;
	selectedByKeybrd:boolean = false;
	
	@Input() ddreadOnly:boolean;
	
	@HostListener('focus') focusHandler() {
		if(this.ddreadOnly != true){
			this.focused = true;
		}
	};
	
	@HostListener('blur') blurHandler(){
		if(this.ddreadOnly != true){
			this.blurHandlerAction();
		}
	};
	
	@HostListener('window:keyup', ['$event'])
	  keyEvent(event: KeyboardEvent) {
		  if(this.ddreadOnly != true){
			if (event.keyCode === KEY_CODE.SPACEBAR) {
			 if(this.focused){
				 this.showdd = true;
				 this.ddIndxByKeybrd = this.selectedIndx;
				 this.selectbyKeyboard(this.ddIndxByKeybrd)
			 }
			}

			if (event.keyCode === KEY_CODE.ARROW_DN) {
				if(this.focused){
					this.ddIndxByKeybrd++;
					if(this.ddIndxByKeybrd > this._ddoptionsArr.length-1){
						this.ddIndxByKeybrd = this._ddoptionsArr.length-1;
					}
					this.selectbyKeyboard(this.ddIndxByKeybrd)
				}
			}
			
			
			if (event.keyCode === KEY_CODE.ARROW_UP) {
				if(this.focused){
				  this.ddIndxByKeybrd--;
					if(this.ddIndxByKeybrd < 0){
						this.ddIndxByKeybrd = 0;
					}
					this.selectbyKeyboard(this.ddIndxByKeybrd)
				}
			}
			
			if (event.keyCode === KEY_CODE.ENTER) {
			  if(this.focused){
				  this.selectedByKeybrd = true;
				  this.selectDdOption(this.ddIndxByKeybrd);
			  }
			}
		  }
	  }
	

	constructor(private dataService: MessageService) { }

	ngOnInit() {
		if (this.open == 'yes' && this.ddreadOnly != true) {
			this.showddoptions();
		}
		this.initializeDropdown();
    this.subscription = this.dataService.getMessage().subscribe(message => {
      if (!message) return;
			if (message.event == 'dropdownstate') {
				if (this.dd_id != message.data.dd_id) {
					this.showdd = false;
					this.focused = false;
				}
			}
		});
	}
	
	ngOnChanges(){
		this.initializeDropdown();
	}

	showddoptions() {
		if(this.ddreadOnly != true){
			this.showdd = this.showdd == true ? false : true;
			this.dataService.sendMessage('dropdownstate', { 'dd_id': this.dd_id, 'state': this.showdd });
		}
	}

	initializeDropdown() {
		this.dd_id = (+ new Date()) + (Math.floor(Math.random() * 1000) + 1);
		this._ddoptionsArr = JSON.parse(JSON.stringify(this.options));

		this._ddoptionsArr.map(item => {
			item.selected = false;
		})
		if (this.label) {
			this.ddplaceholder = this.label;
		} else {
			this.ddplaceholder = this._ddoptionsArr[0].option;
		}
		
		if(this.position != 'top'){
			this.position = 'bottom';
		}
		
	}

	selectDdOption(indx) {
		if(this.ddreadOnly != true){
			this._ddoptionsArr.map(item => {
				item.selected = false;
			});
			
			this.ddplaceholder = this._ddoptionsArr[indx].option;
			
			this._ddoptionsArr[indx].selected = true;
			this.selectedIndx = indx;
			this.value.emit(this._ddoptionsArr[indx]);
			
			this.showdd = false;
		}
	}

	onClickedOutside() {
		this.showdd = false;
	}
	
	
	
	blurHandlerAction(){
		if(this.ddreadOnly != true){
			if(this.focused == true && this.selectedByKeybrd == false){
				this.selectDdOption(this.selectedIndx)
			}
			this.showdd = false;
			this.focused = false;
			this.selectedByKeybrd = false;
		}
	}
	
	selectbyKeyboard(indx){
		if(this.ddreadOnly != true){
			this._ddoptionsArr.map(item => {
				item.selected = false;
			});
			
			this.ddplaceholder = this._ddoptionsArr[indx].option;
			
			this._ddoptionsArr[indx].selected = true;
		}
	}
	
	
}
