import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as DentistStoreActions from './actions';
import * as DentistStoreSelectors from './selectors';
import { DentistClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class DentistStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _dentistClient: DentistClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DentistStoreActions.LoadRequest),
    switchMap(() =>
      this._dentistClient
        .dentist_GetDentists()
        .pipe(
          map((result) => DentistStoreActions.LoadSuccess({ dentists: result })),
          catchError((err: HttpErrorResponse) => of(DentistStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DentistStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(
          DentistStoreSelectors
            .selectDentistById(action.id))
        .pipe(map((result) => DentistStoreActions.SelectSuccess({ dentist: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(DentistStoreActions.DeselectRequest),
    switchMap(() => {
      return of(DentistStoreActions.DeselectSuccess());
    })
  );
}
