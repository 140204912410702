import { createAction, props } from '@ngrx/store';
import { UserStoreEntity } from './state';
import { FileParameter, PhotoDto } from '../../shared/services/api.service';

//LOAD PAGE
export const LoadRequest = createAction('[User] Load Request', props<{ page?: number; pageSize?: number }>());

export const LoadSuccess = createAction('[User] Load Success', props<{ users: UserStoreEntity[] }>());

export const LoadFailure = createAction('[User] Load Failure', props<{ error: any }>());

//LOAD task count
export const LoadTaskCountRequest = createAction('[User Task Count] Load Request');

export const LoadTaskCountSuccess = createAction('[User Task Count] Load Success', props<{ taskCount: number }>());

export const LoadTaskCountFailure = createAction('[User Task Count] Load Failure', props<{ error: any }>());

//Fetch
export const FetchRequest = createAction('[User] Fetch Request', props<{ id: string }>());

export const FetchSuccess = createAction('[User] Fetch Success', props<{ user: UserStoreEntity }>());

export const FetchFailure = createAction('[User] Fetch Failure', props<{ error: any }>());

//ADD PHOTO
export const AddPhotoRequest = createAction(
  '[User] Add Photo Request',
  props<{
    userId: string;
    file: FileParameter;
  }>()
);

export const AddPhotoSuccess = createAction('[User] Add Photo Success', props<{ photo: PhotoDto }>());

export const AddPhotoFailure = createAction('[User] Add Photo Failure', props<{ error: string }>());

//UPDATE PHOTO
export const UpdatePhotoRequest = createAction(
  '[User] Update Photo Request',
  props<{
    userId: string;
    photoId: number;
    file: FileParameter;
  }>()
);

export const UpdatePhotoSuccess = createAction('[User] Update Photo Success', props<{ photo: PhotoDto }>());

export const UpdatePhotoFailure = createAction('[User] Update Photo Failure', props<{ error: string }>());

//ADD
export const AddRequest = createAction('[User] Add Request', props<{ user: UserStoreEntity }>());

export const AddSuccess = createAction('[User] Add Success', props<{ user: UserStoreEntity }>());

export const AddFailure = createAction('[User] Add Failure', props<{ error: string }>());

//SELECT
export const SelectRequest = createAction('[User] Select Request', props<{ id: string }>());

export const SelectSuccess = createAction('[User] Select Success', props<{ user: UserStoreEntity }>());

export const SelectFailure = createAction('[User] Select Failure', props<{ error: any }>());

//DESELECT
export const DeselectRequest = createAction('[User] Deselect Request');

export const DeselectSuccess = createAction('[User] Deselect Success');

export const DeselectFailure = createAction('[User] Deselect Failure', props<{ error: any }>());

//UTILITY
export const ResetError = createAction('[User] Reset Error');

//Tx Card Favorites
export const UpdateTxCardFavorite = createAction('[User] Update TxCardFavorite', props<{ cardSelector: string; isFavorite: boolean }>());
export const UpdateTxCardFavoriteSuccess = createAction('[User] Update TxCardFavorite Success', props<{ userId: string; favorites: string[]; }>());
export const UpdateTxCardFavoriteFailure = createAction('[User] Update TxCardFavorite Failure', props<{ error: any }>());
