import { createAction, props } from "@ngrx/store";
import { PatientTreatmentStatusStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientTreatmentStatus] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[PatientTreatmentStatus] Load Success',
  props<{ patientTreatmentStatuses: PatientTreatmentStatusStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientTreatmentStatus] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[PatientTreatmentStatus] Add Request',
  props<{ patientTreatmentStatus: PatientTreatmentStatusStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[PatientTreatmentStatus] Add Success',
  props<{ patientTreatmentStatus: PatientTreatmentStatusStoreEntity; }>()
);

export const AddFailure = createAction(
  '[PatientTreatmentStatus] Add Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[PatientTreatmentStatus] Update Request',
  props<{ patientTreatmentStatus: PatientTreatmentStatusStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[PatientTreatmentStatus] Update Success',
  props<{ patientTreatmentStatus: PatientTreatmentStatusStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[PatientTreatmentStatus] Update Failure',
  props<{ error: string; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[PatientTreatmentStatus] Delete Request',
  props<{ patientId: number, treatmentStatusId: number }>()
);

export const DeleteSuccess = createAction(
  '[PatientTreatmentStatus] Delete Success',
  props<{ id: number }>()
);

export const DeleteFailure = createAction(
  '[PatientTreatmentStatus] Delete Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientTreatmentStatus] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientTreatmentStatus] Select Success',
  props<{ patientTreatmentStatus: PatientTreatmentStatusStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientTreatmentStatus] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientTreatmentStatus] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientTreatmentStatus] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientTreatmentStatus] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[PatientTreatmentStatus] Reset Error'
);
