import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RootStoreState, AuthStoreActions, TenantStoreSelectors } from '../../root-store';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { TenantKeyDto } from 'src/app/shared/services/api.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.css']
})
export class TenantComponent implements OnInit, OnDestroy {
  tenants$ = this._store$.select(TenantStoreSelectors.selectAllTenants);
  showdrpdn: boolean = false;
  faBuilding = faBuilding;
  tenantKey: string;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public scheduleService: ScheduleService,
    private _actions$: Actions,
    private _store$: Store<RootStoreState.State>) { }

  ngOnInit() {
    this._actions$
      .pipe(ofType(AuthStoreActions.ChangeTenantSuccess), takeUntil(this._destroy$))
      .subscribe((result) => {
        this.initializeTenant();
      });

    this.initializeTenant();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  initializeTenant(): void {
    if (sessionStorage.getItem('tenant')) {
      let _tenantName = JSON.parse(sessionStorage.getItem('tenant'));
      this.tenantKey = _tenantName.key;
    }
  }

  selectTenant(tenant: TenantKeyDto) {
    if (tenant) {
      this._store$.dispatch(AuthStoreActions.ChangeTenantRequest({ tenantName: tenant.name, tenantKey: tenant.tenantKey, saveSession: true }));
    }
    this.showdrpdn = false;
  }

  onClickedOutside() {
    this.showdrpdn = false;
  }
}
