import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as CephalometricStoreActions from './actions';
import * as CephalometricStoreSelectors from './selectors';
import { PatientClient, StorageContentTypeEnum } from 'src/app/shared/services/api.service';
import { PatientsStoreActions } from '../patient-store';

@Injectable({ providedIn: 'root' })
export class CephalometricStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CephalometricStoreActions.LoadRequest),
    switchMap((action) =>
      this._patientClient
        .patient_GetImaging(
          action.patientId,
          StorageContentTypeEnum.Ceph)
        .pipe(
          map((result) => CephalometricStoreActions.LoadSuccess({ cephalometrics: result })),
          catchError((err: HttpErrorResponse) => of(CephalometricStoreActions.LoadFailure({ error: err.message })))
        ))
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CephalometricStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$
        .select(
          CephalometricStoreSelectors
            .selectCephalometricById(action.id))
        .pipe(map((result) => CephalometricStoreActions.SelectSuccess({ cephalometric: result })))
    )
  );

  @Effect()
  deselectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CephalometricStoreActions.DeselectRequest),
    switchMap((action) => {
      return of(CephalometricStoreActions.DeselectSuccess());
    })
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CephalometricStoreActions.AddRequest),
    switchMap((action) =>
      this._patientClient.patient_PostImage(
        action.cephalometric.patientId,
        action.cephalometric.file,
        StorageContentTypeEnum.Ceph,
        action.cephalometric.report)
        .pipe(
          map((result) => CephalometricStoreActions.AddSuccess({ cephalometric: result })),
          catchError((err: HttpErrorResponse) => of(CephalometricStoreActions.AddFailure({ error: err.message })))
        ))
  );
}
