import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { defer, from, Observable } from 'rxjs';
import { mergeAll, toArray } from 'rxjs/operators';
import { download, Download } from './download';
import { SAVER, Saver } from './saver.provider';
import * as JSZip from 'jszip';

// Credits to: https://nils-mehlhorn.de/posts/angular-file-download-progress
@Injectable({ providedIn: 'root' })
export class DownloadService {
  constructor(private http: HttpClient, @Inject(SAVER) private save: Saver) { }

  download(url: string, filename?: string): Observable<Download> {
    return this.http
      .get(url, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(download((blob) => this.save(blob, filename)));
  }

  downloadAsBlob(url: string, filename?: string): Observable<Download> {
    return this.http
      .get(url, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(download((blob) => blob));
  }

  downloadFilesAsZip(downloadFiles: IDownloadFile[], zipName: string): Promise<true> {
    return new Promise(resolve => {
      this.generateZip(downloadFiles).then((zipBlob: Blob) => {
        this.save(zipBlob, zipName);
        resolve(true);
      });
    });
  }

  private generateZip(downloadFiles: IDownloadFile[]): Promise<Blob> {
    return new Promise(resolve => {
      this.generateObservableTasks(downloadFiles).then((responses: IDownloadResponse[]) => {
        const observables = responses.map(a => defer(() => a.blob));
        const filenames: string[] = responses.map(a => a.filename.replace(new RegExp('/', 'g'), '_'));
        from(observables)
          .pipe(
            mergeAll(1),
            toArray()
          )
          .subscribe((res) => {
            var zip = new JSZip();
            _.each(res, (f: Blob, i: number) => {
              zip.file(filenames[i], f, { base64: true });
            });
            resolve(zip.generateAsync({ type: "blob" }))
          });
      });
    });
  }

  private generateObservableTasks(downloadFiles: IDownloadFile[]): Promise<IDownloadResponse[]> {
    return new Promise(resolve => {
      let tasks: IDownloadResponse[] = [];
      _.each(downloadFiles, (d: IDownloadFile) => {
        let task: IDownloadResponse = {
          blob: this.http
            .get(d.url, {
              responseType: 'blob',
            }),
          filename: d.filename
        }
        tasks.push(task);
      });
      resolve(tasks);
    });
  }

  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }
}

export interface IDownloadFile {
  url: string;
  filename: string;
}

interface IDownloadResponse {
  blob: Observable<Blob>;
  filename: string;
}
