import { Action, createReducer, on } from '@ngrx/store';
import * as UserTaskStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(UserTaskStoreActions.LoadUserTaskRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(UserTaskStoreActions.LoadUserTaskSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.tasks, { ...s, isLoading: false, error: null });
  }),
  on(UserTaskStoreActions.LoadUserTaskFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
