import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as CephalometricTypeStoreActions from './actions';
import { SettingsClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class CephalometricTypeStoreEffects {
  constructor(private actions$: Actions, private store$: Store<State>, private _settingsClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(CephalometricTypeStoreActions.LoadRequest),
    switchMap(() =>
      this._settingsClient
        .settings_GetCephalometricTypes()
        .pipe(
          map((result) => CephalometricTypeStoreActions.LoadSuccess({ cephalometricTypes: result })),
          catchError((err: HttpErrorResponse) => of(CephalometricTypeStoreActions.LoadFailure({ error: err.message })))
        ))
  );
}
