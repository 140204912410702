import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CEPHALOMETRIC_FEATURE_KEY } from './featurekey';
import { featureAdapter, CephalometricStoreEntity, State } from './state';

export const selectCephalometricsState: MemoizedSelector<object, State> = createFeatureSelector<State>(CEPHALOMETRIC_FEATURE_KEY);

export const getSelectedCephalometricId = createSelector(selectCephalometricsState, (state) => state.selectedCephalometricId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectCephalometricsState);
export const selectCephalometricIds = selectIds;
export const selectAllCephalometrics = selectAll;
export const selectAllCephalometricEntities = selectEntities;

export const getSelectedCephalometric = createSelector(
  selectCephalometricsState,
  selectAllCephalometrics,
  (state, allCephalometrics: CephalometricStoreEntity[]) =>
    allCephalometrics ? allCephalometrics.find((rp) => rp.id === state.selectedCephalometricId) : null
);

export const selectCephalometricById = (id: number) =>
  createSelector(selectAllCephalometrics, (allCephalometrics: CephalometricStoreEntity[]) =>
    allCephalometrics ? allCephalometrics.find((rp) => rp.id === id) : null
  );

export const selectCephalometricsError: MemoizedSelector<object, any> = createSelector(selectCephalometricsState, (state) => state.error);

export const selectCephalometricsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectCephalometricsState, (state: State) => state.isLoading);
