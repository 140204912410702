import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { HEADER_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(HEADER_FEATURE_KEY, featureReducer),
  ]
})
export class HeaderStoreModule { }
