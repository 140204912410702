import { ContactPhoneDto2, FormTypeDto } from "../../../shared/services/api.service";

export type ChannelType = 'Default' | 'SMS' | 'Email' | 'SnailMail' | 'Notification' | 'Signature';
export enum MessageBuilderChannelTypeEnum {
  Default = 'Default',
  Email = 'Email',
  SMS = 'SMS',
  Notification = 'Notification',
  SnailMail = 'SnailMail',
  Signature = 'Signature'
}
export type MessageSectionType =
  | 'channelType'
  | 'recipient'
  | 'template'
  | 'templateByType'
  | 'formType'
  | 'ccSelector'
  | 'repeatDays'
  | 'subject'
  | 'messageBody'
  | 'desk'
  | 'followUpDate'
  | 'fileAttachment'
  | 'stationary'
  | 'deskSignOff'
  | 'imageSelector'
export enum MessageBuilderSectionTypeEnum {
  ChannelType = 'channelType',
  Recipient = 'recipient',
  Template = 'template',
  TemplateByType = 'templateByType',
  FormType = 'formType',
  CcSelector = 'ccSelector',
  RepeatDays = 'repeatDays',
  Subject = 'subject',
  MessageBody = 'messageBody',
  Desk = 'desk',
  FollowupDate = 'followUpDate',
  FileAttachment = 'fileAttachment',
  Stationary = 'stationary',
  DeskSignOff = 'deskSignOff',
  ImageSelector = 'imageSelector'
}

export class PatientCommunicationMessage implements IPatientCommunicationMessage {
  channelType!: ChannelType;
  recipient: IMessageBuilderRecipient;
  cc?: { contactId?: number | null; phoneEmail?: string | null; name?: string | null } = { contactId: null, phoneEmail: null, name: null };
  templateId?: number | null = null;
  formTypeId?: number | null = null;
  formTypeToken?: string | null = null;
  communicationDeskId?: number | null = null;
  repeat?: { startDate?: Date | null; repeatCount?: number | null; repeatDayDelays?: number | null } = {
    startDate: null,
    repeatCount: null,
    repeatDayDelays: null,
  };
  subject?: string | null = null;
  messageBody?: string | null = null;
  followUpDate?: Date | null = null;
  fileAttachments?: any[] | null = null;
  isSecure: boolean;

  constructor(data?: IPatientCommunicationMessage) {
    //borrowed from swagger service
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IPatientCommunicationMessage {
  messageDraftId?: number;
  messageDraftETag?: string;
  channelType?: ChannelType;
  recipient?: IMessageBuilderRecipient;
  cc?: {
    id?: number | null;
    contactId?: number | null;
    phoneEmail?: string | null;
    name?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    type?: string | null;
    isSecureAccess?: boolean | null;
  };
  templateId?: number;
  stationaryId?: number;
  formTypeId?: number;
  formTypeToken?: string;
  communicationDeskId?: number;
  repeat?: { startDate?: Date; repeatCount?: number; repeatDayDelays?: number };
  subject?: string;
  messageBody?: string;
  followUpDate?: Date;
  fileAttachments?: any[];
  localFileAttachments?: any[];
  snailMailAttachment?: any;
  isSecure: boolean;
  formTypes?: FormTypeDto[];
}

export interface IMessageBuilderRecipient {
  prefix?: string;
  suffix?: string;
  id?: number;
  name?: string;
  phoneEmail?: string;
  phone?: string;
  phones?: ContactPhoneDto2[];
  email?: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  type: 'dentist' | 'contact' | 'other' | 'clinic';
  preferredContactMethod?:string;
  isSecureAccess?:boolean;
  contactPhoneEmailId?: number
  notificationId?: number;
}
