import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_FORM_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientFormStoreEntity, State } from './state';

export const selectPatientFormsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_FORM_FEATURE_KEY);

export const getSelectedPatientFormId = createSelector(selectPatientFormsState, (state) => state.selectedPatientFormId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientFormsState);
export const selectPatientFormIds = selectIds;
export const selectAllPatientForms = selectAll;
export const selectAllPatientFormsEntities = selectEntities;

export const getSelectedPatientForm = createSelector(
  selectPatientFormsState,
  selectAllPatientForms,
  (state, allPatientForms: PatientFormStoreEntity[]) =>
    allPatientForms ? allPatientForms.find((rp) => rp.id === state.selectedPatientFormId) : null
);

export const selectPatientFormById = (id: number) =>
  createSelector(selectAllPatientForms, (allPatientForms: PatientFormStoreEntity[]) =>
    allPatientForms ? allPatientForms.find((rp) => rp.id === id) : null
  );

export const selectPatientFormsError: MemoizedSelector<object, any> = createSelector(selectPatientFormsState, (state) => state.error);

export const selectPatientFormsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientFormsState, (state: State) => state.isLoading);
