import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PATIENT_TREATMENT_STORE_FEATURE_KEY } from './featurekey';
import { featureAdapter, initialState, State } from './state';

export const selectPatientTreatmentsState = createFeatureSelector<State>(PATIENT_TREATMENT_STORE_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = featureAdapter.getSelectors(selectPatientTreatmentsState);
export const selectAllPlans = selectAll;
export const selectPlanEntitities = selectEntities;
export const selectPlanIds = selectIds;
export const selectTotalPlans = selectTotal;

export const selectTreatmentPlan = (treatmentId: number) => createSelector(selectAll, (treatments) => treatments.find((treatment) => treatment.id === treatmentId));

export const selectIsLoaded = createSelector(selectPatientTreatmentsState, (state) => state.isLoaded);
export const selectIsLoading = createSelector(selectPatientTreatmentsState, (state) => state.isLoading);

export const getSelectedTreatmentId = createSelector(selectPatientTreatmentsState, (state) => state.selectedTreatmentPlanId);
export const getSelectedTreatment = createSelector(getSelectedTreatmentId, selectAll, (id, treatments) => treatments.find((p) => p.id === id));
