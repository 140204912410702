import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { MARKETING_PACKAGE_FEATURE_KEY } from './featurekey';
import { featureAdapter, MarketingPackageStoreEntity, State } from './state';

export const selectMarketingPackagesState: MemoizedSelector<object, State> = createFeatureSelector<State>(MARKETING_PACKAGE_FEATURE_KEY);

export const getSelectedMarketingPackageId = createSelector(selectMarketingPackagesState, (state) => state.selectedMarketingPackageId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectMarketingPackagesState);
export const selectMarketingPackageIds = selectIds;
export const selectAllMarketingPackages = selectAll;
export const selectAllMarketingPackagesEntities = selectEntities;

export const getSelectedMarketingPackage = createSelector(
  selectMarketingPackagesState,
  selectAllMarketingPackages,
  (state, allMarketingPackages: MarketingPackageStoreEntity[]) =>
    allMarketingPackages ? allMarketingPackages.find((rp) => rp.id === state.selectedMarketingPackageId) : null
);

export const selectMarketingPackageById = (id: number) =>
  createSelector(selectAllMarketingPackages, (allMarketingPackages: MarketingPackageStoreEntity[]) =>
    allMarketingPackages ? allMarketingPackages.find((rp) => rp.id === id) : null
  );

export const selectMarketingPackagesError: MemoizedSelector<object, any> = createSelector(selectMarketingPackagesState, (state) => state.error);

export const selectMarketingPackagesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectMarketingPackagesState, (state: State) => state.isLoading);
