import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighlightSearch } from '../../pipe/highlight.pipe';
import { SearchpanelComponent, SearchPatientBox } from './searchpanel/searchpanel.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { DateSuffix } from '../../pipe/datesuffix.pipe';
import { PatientmodalddComponent } from './patientmodaldd/patientmodaldd.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TextMaskModule } from 'angular2-text-mask';


import { ResponsiblePartyComponent } from './modal-component/responsible-party/responsible-party.component';
import { CreateNewEventComponent } from './modal-component/create-new-event/create-new-event.component';
import { NewPatientComponent } from './modal-component/new-patient/new-patient.component';
import { ModalComponentComponent } from './modal-component/modal-component/modal-component.component';

import { DropdownSharedComponent } from './modal-component/dropdown-shared/dropdown-shared.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AppNotificationComponent } from './modal-component/patient-notification/app-notification.component';
import { NotificationSettingsComponent } from 'src/app/schedule/notification-settings/notification-settings.component';
import { ElementsModule } from 'src/app/elements/elements.module';

import { SearchAllNamesComponent } from './modal-component/search-all-names/search-all-names.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion'; 
import {MatSelectModule} from '@angular/material/select';

import { DentistClinicSearchModule } from '../dentist-clinic-search/dentist-clinic-search.module';
import { PatientSearchModule } from '../patient-search/patient-search.module';
import { PatientNotificationModalComponent } from './patient-notification-modal/patient-notification-modal.component';

import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatProgressBarModule} from '@angular/material/progress-bar'; 
import {MatIconModule} from '@angular/material/icon'; 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NotificationSettingsModule } from '../../schedule/notification-settings/notification-settings.module';
import { PatientNotificationModalModule } from './patient-notification-modal/patient-notification-modal.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    FlexLayoutModule,
    TextMaskModule,
    ElementsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    DentistClinicSearchModule,
    PatientSearchModule,
    MatProgressBarModule,
    MatIconModule,
    MatDatepickerModule,
    NotificationSettingsModule,
    PatientNotificationModalModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  declarations: [
    SearchpanelComponent,
    SearchPatientBox,
    DateSuffix,
    PatientmodalddComponent,
    ResponsiblePartyComponent,
    CreateNewEventComponent,
    NewPatientComponent,
    ModalComponentComponent,
    DropdownSharedComponent,
    AppNotificationComponent,
    SearchAllNamesComponent
  ],

  entryComponents: [SearchPatientBox],

  exports: [
    CommonModule, 
    SearchpanelComponent, 
    SearchPatientBox, 
    FormsModule, 
    ReactiveFormsModule, 
    PerfectScrollbarModule, 
    DateSuffix, 
    PatientmodalddComponent, 
    ClickOutsideModule, 
    FlexLayoutModule, 
    ResponsiblePartyComponent, 
    CreateNewEventComponent, 
    NewPatientComponent, 
    ModalComponentComponent, 
    DropdownSharedComponent, 
    TextMaskModule
  ]
})
export class SearchmoduleModule { }
