import { Pipe, PipeTransform } from '@angular/core';
import { DentistReferralDto } from 'src/app/shared/services/api.service';

@Pipe({
  name: 'dentistReferralSort',
  pure: true,
})
export class DentistReferralSortPipe implements PipeTransform {
  transform(value: DentistReferralDto[]): any {
    return value.sort((a, b) => (a.referralDate && a.referralDate.getTime()) - (b.referralDate && b.referralDate.getTime()));
  }
}
