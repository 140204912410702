import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as AppointmentStoreActions from './actions';
import * as AppointmentStoreSelectors from './selectors';
import { LocationClient, PatientClient } from 'src/app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class AppointmentStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _locationClient: LocationClient,
    private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentStoreActions.LoadRequest),
    switchMap((action) => {
      if (action.locationId)
        return this._locationClient.location_GetPatientAppointments(action.locationId, action.patientId).pipe(
          map((result) => AppointmentStoreActions.LoadSuccess({ appointments: result })),
          catchError((err: HttpErrorResponse) => of(AppointmentStoreActions.LoadFailure({ error: err.message })))
        )
      else
        return this._patientClient.patient_GetPatientAppointments(action.patientId).pipe(
          map((result) => AppointmentStoreActions.LoadSuccess({ appointments: result })),
          catchError((err: HttpErrorResponse) => of(AppointmentStoreActions.LoadFailure({ error: err.message })))
        )
    })
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(AppointmentStoreSelectors.selectAppointmentById(action.id)).pipe(map((result) => AppointmentStoreActions.SelectSuccess({ appointment: result })))
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentStoreActions.DeleteRequest),
    switchMap((action) =>
      this._locationClient.location_DeleteAppointment(action.locationId, action.patientId, action.id).pipe(
        map(() => AppointmentStoreActions.DeleteSuccess({ id: action.id })),
        catchError((err: HttpErrorResponse) => of(AppointmentStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateNoShowRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentStoreActions.UpdateNoShowRequest),
    switchMap((action) =>
      this._patientClient.patient_PutAppointmentNoShow(action.patientId, action.appointmentId, action.noShow).pipe(
        map((result) => AppointmentStoreActions.UpdateNoShowSuccess({ appointment: result })),
        catchError((err: HttpErrorResponse) => of(AppointmentStoreActions.UpdateNoShowFailure({ error: err.message })))
      )
    )
  );
}
