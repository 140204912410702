import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { CONTACTS_FEATURE_KEY } from './featurekey';
import { State, featureAdapter } from './state';

export const selectContactsState: MemoizedSelector<object, State> = createFeatureSelector(CONTACTS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectContactsState);
export const selectContactIds = selectIds;
export const selectContactTotal = selectTotal;
export const selectAllContacts = selectAll;
export const selectAllContactEntities = selectEntities;

export const getSelectedContact = createSelector(
  selectContactsState,
  (state) => state.contact
);

export const selectIsLoading = createSelector(
  selectContactsState,
  (state) => state.isLoading
);
