import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatTabsModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatDialogModule,
  MatSelectModule,
  MatAutocompleteModule
} from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';
import { CardModule } from 'src/app/shared/models';
import { ClickConfirmModule } from '../../click-confirm/click-confirm.module';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { DiagnosisEditorComponent } from './diagnosis-editor/diagnosis-editor.component';
import { DiagnosisSelectorComponent } from './diagnosis-selector/diagnosis-selector.component';
import { GoalsEditorComponent } from './goals-editor/goals-editor.component';
import { PatientDiagnosisDisplayComponent } from './patient-diagnosis-display/patient-diagnosis-display.component';
import { PatientGoalsDisplayComponent } from './patient-goals-display/patient-goals-display.component';
import { PatientPlanBackComponent } from './patient-plan-back/patient-plan-back.component';
import { PatientPlanFrontComponent } from './patient-plan-front/patient-plan-front.component';
import { PatientPlanDisplayComponent } from './treatment-plan-display/treatment-plan-display.component';
import { TreatmentPlanEditorComponent } from './treatment-plan-editor/treatment-plan-editor.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { AngularEditorModule } from '@kolkov/angular-editor';
import { LimitationsRiskEditorComponent } from './limitations-risk-editor/limitations-risk-editor.component';
import { LimitationRiskDisplayComponent } from './limitation-risk-display/limitation-risk-display.component';
import { TreatmentStepsDisplayComponent } from './treatment-steps-display/treatment-steps-display.component';
import { TreatmentStepsEditorComponent } from './treatment-steps-editor/treatment-steps-editor.component';
import { TextMaskModule } from 'angular2-text-mask';

@CardModule({
  back: PatientPlanBackComponent,
  front: PatientPlanFrontComponent,
  height: DEFAULT_CARD_HEIGHT,
  selector: 'diagnosis',
  width: 660,
  header: 'Diagnosis / Treatment',
  order: 20,
  actions: [{iconName: '<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5625 7.625H8.3125C8.06641 7.625 7.875 7.84375 7.875 8.0625V8.9375C7.875 9.18359 8.06641 9.375 8.3125 9.375H13.5625C13.7812 9.375 14 9.18359 14 8.9375V8.0625C14 7.84375 13.7812 7.625 13.5625 7.625ZM13.5625 11.125H8.3125C8.06641 11.125 7.875 11.3438 7.875 11.5625V12.4375C7.875 12.6836 8.06641 12.875 8.3125 12.875H13.5625C13.7812 12.875 14 12.6836 14 12.4375V11.5625C14 11.3438 13.7812 11.125 13.5625 11.125ZM13.5625 4.125H8.3125C8.06641 4.125 7.875 4.34375 7.875 4.5625V5.4375C7.875 5.68359 8.06641 5.875 8.3125 5.875H13.5625C13.7812 5.875 14 5.68359 14 5.4375V4.5625C14 4.34375 13.7812 4.125 13.5625 4.125ZM13.5625 0.625H8.3125C8.06641 0.625 7.875 0.84375 7.875 1.0625V1.9375C7.875 2.18359 8.06641 2.375 8.3125 2.375H13.5625C13.7812 2.375 14 2.18359 14 1.9375V1.0625C14 0.84375 13.7812 0.625 13.5625 0.625ZM5.6875 7.625H0.4375C0.191406 7.625 0 7.84375 0 8.0625V8.9375C0 9.18359 0.191406 9.375 0.4375 9.375H5.6875C5.90625 9.375 6.125 9.18359 6.125 8.9375V8.0625C6.125 7.84375 5.90625 7.625 5.6875 7.625ZM5.6875 11.125H0.4375C0.191406 11.125 0 11.3438 0 11.5625V12.4375C0 12.6836 0.191406 12.875 0.4375 12.875H5.6875C5.90625 12.875 6.125 12.6836 6.125 12.4375V11.5625C6.125 11.3438 5.90625 11.125 5.6875 11.125ZM5.6875 0.625H0.4375C0.191406 0.625 0 0.84375 0 1.0625V1.9375C0 2.18359 0.191406 2.375 0.4375 2.375H5.6875C5.90625 2.375 6.125 2.18359 6.125 1.9375V1.0625C6.125 0.84375 5.90625 0.625 5.6875 0.625ZM5.6875 4.125H0.4375C0.191406 4.125 0 4.34375 0 4.5625V5.4375C0 5.68359 0.191406 5.875 0.4375 5.875H5.6875C5.90625 5.875 6.125 5.68359 6.125 5.4375V4.5625C6.125 4.34375 5.90625 4.125 5.6875 4.125Z" fill="currentColor"/></svg>', actionName: ''}]
})
@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDividerModule,
    MatSnackBarModule,
    ClickConfirmModule,
    MatExpansionModule,
    MatDatepickerModule,
    FontAwesomeModule,
    MatMenuModule,
    MatDialogModule,
    AngularEditorModule,
    MatSelectModule,
    MatAutocompleteModule,
    TextMaskModule
  ],
  exports: [],
  declarations: [
    DiagnosisEditorComponent,
    PatientPlanBackComponent,
    DiagnosisSelectorComponent,
    PatientPlanFrontComponent,
    PatientDiagnosisDisplayComponent,
    GoalsEditorComponent,
    TreatmentPlanEditorComponent,
    PatientPlanDisplayComponent,
    PatientGoalsDisplayComponent,
    LimitationsRiskEditorComponent,
    LimitationRiskDisplayComponent,
    TreatmentStepsDisplayComponent,
    TreatmentStepsEditorComponent,
  ],
  providers: [],
  entryComponents: [DiagnosisEditorComponent, PatientPlanBackComponent, DiagnosisEditorComponent, PatientPlanFrontComponent],
})
export class PatientPlanModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
