import { createAction, props } from "@ngrx/store";
import { FormGroupStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[FormGroup] Load Request'
);

export const LoadSuccess = createAction(
  '[FormGroup] Load Success',
  props<{ formGroups: FormGroupStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[FormGroup] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[FormGroup] Load One Request',
  props<{ id: number; }>()
);

export const LoadOneSuccess = createAction(
  '[FormGroup] Load One Success',
  props<{ formGroup: FormGroupStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[FormGroup] Load One Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[FormGroup] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[FormGroup] Select Success',
  props<{ formGroup: FormGroupStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[FormGroup] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[FormGroup] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[FormGroup] Deselect Success',
);

export const DeselectFailure = createAction(
  '[FormGroup] Deselect Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[FormGroup] Add Request',
  props<{ formGroup: FormGroupStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[FormGroup] Add Success',
  props<{ formGroup: FormGroupStoreEntity; }>()
);

export const AddFailure = createAction(
  '[FormGroup] Delete Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[FormGroup] Update Request',
  props<{ id: number; formGroup: FormGroupStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[FormGroup] Update Success',
  props<{ formGroup: FormGroupStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[FormGroup] Update Failure',
  props<{ error: any; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[FormGroup] Delete Request',
  props<{ id: number; }>()
);

export const DeleteSuccess = createAction(
  '[FormGroup] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[FormGroup] Delete Failure',
  props<{ error: string; }>()
);
