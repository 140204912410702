import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { ITxCardTypeDto, TxCardTypeDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<TxCardTypeStoreEntity> = createEntityAdapter<TxCardTypeStoreEntity>({
  selectId: txCardType => txCardType.id,
  sortComparer: (a: TxCardTypeStoreEntity, b: TxCardTypeStoreEntity): number => { return b.name > a.name ? -1 : 0 }
});

export interface State extends EntityState<TxCardTypeStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class TxCardTypeStoreEntity extends TxCardTypeDto {
  constructor(data?: ITxCardTypeDto) {
    super(data);
  }
}
