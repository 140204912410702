import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { ProviderDto3, IProviderDto3 } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<ProviderStoreEntity> = createEntityAdapter<ProviderStoreEntity>({
  selectId: patient => patient.id,
  sortComparer: (a: ProviderStoreEntity, b: ProviderStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<ProviderStoreEntity> {
  page?: number;
  pageSize?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class ProviderStoreEntity extends ProviderDto3 {
  constructor(data?: IProviderDto3) {
    super(data);
  }
}
