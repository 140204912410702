import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: Date, format: string): string {
    if (value) {
      const diff = moment(moment.now()).diff(value);
      const duration = moment.duration(diff);

      if (format)
        return format
          .replace('Y', duration.years().toString())
          .replace('M', duration.months().toString())
          .replace('W', duration.weeks().toString())
          .replace('D', duration.days().toString())
          .replace('S', duration.seconds().toString());

      return `${duration.years()}`;
    }
    return null;
  }

}
