import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GoalDto2, IGoalDto2 } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<GoalDto2> = createEntityAdapter<GoalDto2>({
  selectId: (goal) => goal.id,
  sortComparer: (a, b) => a.id - b.id,
});

export interface State extends EntityState<GoalDto2> {
  isLoading?: boolean;
  error?: any;
  hasLoaded?: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  hasLoaded: false,
})

export class GoalStoreEntity extends GoalDto2 {
  constructor(data?: IGoalDto2) {
    super(data);
  }
}
