import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import * as PostingCodeStoreActions from './actions';
import { HubEventArea, HubEventType, PostingCodeDto, SettingsClient } from 'src/app/shared/services/api.service';
import { SignalRHubStoreActions } from '../signalr-hub-store';

@Injectable({ providedIn: 'root' })
export class PostingCodeStoreEffects {
  constructor(private actions$: Actions, private settingsClient: SettingsClient) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PostingCodeStoreActions.LoadRequest),
    switchMap((action) =>
      this.settingsClient.settings_GetPostingCodes(action.activeOnly).pipe(
        map((result: any) => PostingCodeStoreActions.LoadSuccess({ postingCodeList: result })),
        catchError((err: HttpErrorResponse) => of(PostingCodeStoreActions.LoadFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PostingCodeStoreActions.LoadOneRequest),
    switchMap((action) =>
      this.settingsClient
        .settings_GetPostingCode(action.id)
        .pipe(map((result) => PostingCodeStoreActions.LoadOneSuccess({ postingCode: result })))
    )
  );

  eventHubEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      filter((action) => action.event.eventArea == HubEventArea.PostingCodeEvent && action.event.entityType == PostingCodeDto.name),
      map((action) => {
        switch (action.event.eventType) {
          case HubEventType.Removed:
            //removed
            return PostingCodeStoreActions.DeleteRequest({ id: action.event.entityId });
          case HubEventType.Modified:
          case HubEventType.Added:
            //updated and included in store
            return PostingCodeStoreActions.LoadOneRequest({ id: action.event.entityId });
        }
      })
    )
  );
}
