import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { PatientCompletionDateDto, IPatientCompletionDateDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientCompletionDateStoreEntity> = createEntityAdapter<PatientCompletionDateStoreEntity>({
  selectId: patientCompletionDate => patientCompletionDate.id,
  sortComparer: (a: PatientCompletionDateStoreEntity, b: PatientCompletionDateStoreEntity): number => b.createdWhen.getTime() - a.createdWhen.getTime()
});

export interface State extends EntityState<PatientCompletionDateStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientCompletionDateId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientCompletionDateId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class PatientCompletionDateStoreEntity extends PatientCompletionDateDto {
  constructor(data?: IPatientCompletionDateDto) {
    super(data);
  }
}
