import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';

import {
  InsuranceCompanyDto,
  SettingsClient,
} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-add-edit-insurance',
  templateUrl: './add-edit-insurance.component.html',
  styleUrls: ['./add-edit-insurance.component.scss']
})
export class AddEditInsuranceComponent implements OnInit {
  public carrierFormGroup: FormGroup;
  public phonemask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  urlRegex = /^https:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;

  constructor(
    public dialogRef: MatDialogRef<AddEditInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackbar: MatSnackBar,
    private _settingsClient:SettingsClient
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  private initFormGroup() {
    this.carrierFormGroup = new FormGroup({
      id: new FormControl(''),
      payorNumber: new FormControl(''),
      name: new FormControl('', Validators.required),
      shortName: new FormControl('', Validators.required),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      phoneNumber: new FormControl(''),
      phoneNumberExtension: new FormControl(''),
      faxNumber: new FormControl(''),
      email: new FormControl(''),
      providerNumber: new FormControl(''),
      websiteUrl: new FormControl('', Validators.pattern(this.urlRegex)),
      userName: new FormControl(''),
      password: new FormControl(''),
      notes: new FormControl(''),
      isActive: new FormControl(false),
      eTag: new FormControl(''),
      hasClaims: new FormControl(false),
      hasEFT: new FormControl(false),
      hasERA: new FormControl(false),
      hasEligibility: new FormControl(false),
      useOnline: new FormControl(false)
      
    });

    this.carrierFormGroup.patchValue({
      payorNumber: this.data.formdata['payorNumber'],
      name: this.data.formdata['name'],
      shortName: this.data.formdata['shortName'],
      addressLine1: this.data.formdata['addressLine1'],
      addressLine2: this.data.formdata['addressLine2'],
      city: this.data.formdata['city'],
      state: this.data.formdata['state'],
      zip: this.data.formdata['zip'],
      phoneNumber: this.data.formdata['phoneNumber'],
      phoneNumberExtension: this.data.formdata['phoneNumberExtension'],
      faxNumber: this.data.formdata['faxNumber'],
      email: this.data.formdata['email'],
      providerNumber: this.data.formdata['providerNumber'],
      websiteUrl: this.data.formdata['websiteUrl'],
      userName: this.data.formdata['userName'],
      password: this.data.formdata['password'],
      notes: this.data.formdata['notes'],
      isActive: this.data.formdata['isActive'],
      hasClaims: this.data.formdata['hasClaims'],
      hasEFT: this.data.formdata['hasEFT'],
      hasERA: this.data.formdata['hasERA'],
      hasEligibility: this.data.formdata['hasEligibility'],
      useOnline: this.data.formdata['useOnline']
      
    })
    
  }

  public setActive(): void {
    const temp = this.carrierFormGroup.controls['isActive']
    temp.patchValue(temp.value ? false : true);
  }

  public save(): void {
    let action: Observable<any>;
    if (
      this.carrierFormGroup.controls['shortName'].valid &&
      this.carrierFormGroup.controls['name'].valid
    ){

      console.log(this.data.mode) 
      
      let carriersDto = this.prepareInsuranceCompanyDtoRequest();

      if (this.data.mode === 'add'){
        action = this._settingsClient.settings_PostInsuranceCompany(carriersDto, '');
      } else {
        let rowId = this.data.formdata.id;
        let eTag = this.data.formdata.eTag;

        action = this._settingsClient.settings_PutInsuranceCompany(rowId, carriersDto, null, eTag);
      }

      action.subscribe(resp => {
        this.dialogRef.close("saved")
      },
      err => {
        this.dialogRef.close("error");
      })

    } else {
      this._snackbar.open('short name and name are required.', 'Close', {
        duration: 3000,
      });
    }
  }

  private prepareInsuranceCompanyDtoRequest() {
    const temp: InsuranceCompanyDto = new InsuranceCompanyDto();

    temp.payorNumber = this.carrierFormGroup.controls['payorNumber'].value;
    temp.name = this.carrierFormGroup.controls['name'].value;
    temp.shortName = this.carrierFormGroup.controls['shortName'].value;
    temp.addressLine1 = this.carrierFormGroup.controls['addressLine1'].value;
    temp.addressLine2 = this.carrierFormGroup.controls['addressLine2'].value;
    temp.city = this.carrierFormGroup.controls['city'].value;
    temp.state = this.carrierFormGroup.controls['state'].value;
    temp.zip = this.carrierFormGroup.controls['zip'].value;
    temp.phoneNumber = this.carrierFormGroup.controls['phoneNumber'].value;
    temp.phoneNumberExtension = this.carrierFormGroup.controls[
      'phoneNumberExtension'
    ].value;
    temp.faxNumber = this.carrierFormGroup.controls['faxNumber'].value;
    temp.email = this.carrierFormGroup.controls['email'].value;
    temp.providerNumber = this.carrierFormGroup.controls[
      'providerNumber'
    ].value;
    temp.websiteUrl = this.carrierFormGroup.controls['websiteUrl'].value;
    temp.userName = this.carrierFormGroup.controls['userName'].value;
    temp.password = this.carrierFormGroup.controls['password'].value;
    temp.notes = this.carrierFormGroup.controls['notes'].value;
    temp.isActive = this.carrierFormGroup.controls['isActive'].value;
    temp.hasClaims = this.carrierFormGroup.controls['hasClaims'].value;
    temp.hasEFT = this.carrierFormGroup.controls['hasEFT'].value;
    temp.hasERA = this.carrierFormGroup.controls['hasERA'].value;
    temp.hasEligibility = this.carrierFormGroup.controls['hasEligibility'].value;
    temp.useOnline = this.carrierFormGroup.controls['useOnline'].value;

    return temp;
  }

}
