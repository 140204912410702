import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { ICard } from "../models/cards";

@Injectable({ providedIn: 'root' })
export class CardsService {
  private cardsMap: Map<string, ICard> = new Map<string, ICard>();

  addCard(card: ICard): Observable<ICard> {
    this.cardsMap.set(card.selector, card);
    return of(card);
  }

  removeCard(cardSelector): Observable<any> {
    this.cardsMap.delete(cardSelector);
    return of(null);
  }

  getCardMap(): Observable<Map<string, ICard>> {
    return of(this.cardsMap);
  }

  getCardList(): Observable<ICard[]> {
    return of(Array.from(this.cardsMap.values()));
  }
}