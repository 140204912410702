import { EvoPermissions } from './jwt.model';

export function EvoTokenReviver(key: string, value: any) {
  //the only appropriate use of 'any'
  //Locations
  if (key === 'Locations') {
    if (Array.isArray(value)) return value.map((item: string) => Number(item.split('.')[1]));
    else return [Number(value.split(',')[1])];
  }

  //Permissions
  if (key === 'Permissions') {
    if (Array.isArray(value)) return parsePermissions(value);
    else if (typeof value === 'string') return parsePermissions(value.split('|'));
  }

  //Roles
  if (key.endsWith('/role')) {
    if (Array.isArray(value)) {
      //simply ensure all values are lowercased for consistency
      return (<string[]>value).map((role) => role.toLocaleLowerCase());
    } else {
      return [value.toLocaleLowerCase()];
    }
  }

  //User Name
  if (key.endsWith('/name')) {
    if (Array.isArray(value)) {
      //technically I think that username claims could be an array, checking to be safe but taking only first item
      return value[0];
    } else {
      return value;
    }
  }

  return value;
}

export function parsePermissions(value: string[]): EvoPermissions {
  let locationPermissions = value
    .map((perm) => perm.split(':'))
    //string[] => { permissionArea: number | string; permission: string; }[] (Unreduced)
    .map(([strLocation, permission]) => {
      let [location, id] = strLocation.split('.');
      const permissionArea = location.toUpperCase().startsWith('GLOBAL') ? 'Global' : Number(id);
      return { permissionArea: permissionArea, permission: permission };
    })
    //{ permissionArea: number | string; permission: string; }[] => EvoPermissions (Reduced)
    .reduce((acc, i) => {
      if (acc[i.permissionArea]) {
        acc[i.permissionArea].push(i.permission);
      } else {
        acc[i.permissionArea] = [i.permission];
      }
      return acc;
    }, <EvoPermissions>{}); //initial accumulator to define structure

  locationPermissions.locationIds = Object.keys(locationPermissions)
    .map((k) => Number(k))
    .filter((k) => !Object.is(k, NaN));
  return locationPermissions;
}
