import { Directive, HostListener, ElementRef, Input, Output, EventEmitter, OnDestroy, Component, OnInit } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { DialogConfirmTemplateComponent } from './dialog-confirm-template/dialog-confirm-template.component';
import { DialogInfoTemplateComponent } from './dialog-info-template/dialog-info-template.component';
import { ClickConfirmTemplateComponent } from './click-confirm-template/click-confirm-template.component';
import { ClickConfirm } from './click-confirm.interface';

@Directive({
  selector: '[evoClickConfirm]',
})
export class ClickConfirmDirective implements OnDestroy, OnInit {
  @Output('evoClickConfirm') evoClickConfirm: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input('evoClickConfirmText') evoClickConfirmText: string = 'Confirm?';
  @Input('evoClickConfirmTemplate') evoClickConfirmTemplate: string;
  @Input('evoClickPositiveButtonText') evoClickPositiveButtonText: string;
  @Input('evoClickNegativeButtonText') evoClickNegativeButtonText: string;
  @Input('evoShowImage') evoShowImage: boolean;

  private _lastEvent: MouseEvent;
  private _overlayRef: OverlayRef;
  private _componentPortal: ComponentPortal<ClickConfirm>;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _elref: ElementRef, private _overlay: Overlay) {
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elref)
        .withPositions([{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]),
      minWidth: 'fit-content',
      hasBackdrop: false,
    });
  }

  ngOnInit(): void {
    this._componentPortal = new ComponentPortal(this.portalTemplate);
  }

  ngOnDestroy(): void {
    if (this._overlayRef.hasAttached) this._overlayRef.detach();
  }

  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    this._lastEvent = $event;
    $event.preventDefault();
    $event.stopPropagation();
    this.openConfirm();
  }

  get portalTemplate(): any {
    switch (this.evoClickConfirmTemplate) {
      case 'closeConfirm':
        return DialogConfirmTemplateComponent;
      case 'infoConfirm':
        return DialogInfoTemplateComponent;
      default:
        return ClickConfirmTemplateComponent;
    }
  }

  openConfirm() {
    if (!this._overlayRef.hasAttached()) {
      const componentRef = this._overlayRef.attach(this._componentPortal);
      componentRef.instance.confirmText = this.evoClickConfirmText;
      componentRef.instance.positiveButtonText = this.evoClickPositiveButtonText;
      componentRef.instance.negativeButtonText = this.evoClickNegativeButtonText;
      componentRef.instance.showImage = this.evoShowImage;
      const sub = componentRef.instance.confirm
        .pipe(takeUntil(this._destroy$))
        .subscribe((result) => (result ? this.confirm() : this.cancel()));
      componentRef.onDestroy(() => sub.unsubscribe());
    }
  }

  confirm() {
    this.evoClickConfirm.next(this._lastEvent);
    this._overlayRef.detach();
  }

  cancel() {
    this._overlayRef.detach();
  }
}
