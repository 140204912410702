import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlipCardComponent } from './flip-card/flip-card.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';

export const DEFAULT_CARD_HEIGHT = 600;

const components = [FlipCardComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    PortalModule,
    MatIconModule,
    DragDropModule
  ],
  exports: components
})
export class CardsModule { }
