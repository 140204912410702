import { Action, createReducer, on } from "@ngrx/store";
import * as PatientCephalometricStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  on(PatientCephalometricStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true })),
  on(PatientCephalometricStoreActions.LoadSuccess, (state, action) =>
    Array.isArray(action.entries)
      ? featureAdapter.upsertMany(action.entries, featureAdapter.removeAll({ ...state, isLoading: false }))
      : featureAdapter.upsertOne(action.entries, { ...state, isLoading: false })
  ),
  on(PatientCephalometricStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(PatientCephalometricStoreActions.Remove, (state, action) => featureAdapter.removeOne(action.patientCephalometricId, { ...state }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
