import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { State } from '../root-state';
import * as FormTypeStoreActions from './actions';
import * as FormTypeStoreSelectors from './selectors';
import { LocationClient, PatientClient, SettingsClient } from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material';

@Injectable({ providedIn: 'root' })
export class FormTypeStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _snackBar: MatSnackBar,
    private _settingsClient: SettingsClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormTypeStoreActions.LoadRequest),
    switchMap((action) => {
      return this._settingsClient.settings_GetFormTypeAll(50).pipe(
        map((result) => FormTypeStoreActions.LoadSuccess({ formTypes: result })),
        catchError((err: HttpErrorResponse) => of(FormTypeStoreActions.LoadFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  loadOneRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormTypeStoreActions.LoadOneRequest),
    switchMap((action) => {
      return this._settingsClient.settings_GetFormType(action.id).pipe(
        map((result) => FormTypeStoreActions.LoadOneSuccess({ formType: result })),
        catchError((err: HttpErrorResponse) => of(FormTypeStoreActions.LoadOneFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormTypeStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(FormTypeStoreSelectors.selectFormTypeById(action.id))
        .pipe(map((result) => FormTypeStoreActions.SelectSuccess({ formType: result })))
    )
  );

  @Effect()
  addRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormTypeStoreActions.AddRequest),
    switchMap((action) =>
      this._settingsClient.settings_PostFormType(action.formType).pipe(
        map((result) => {
          this._snackBar.open("Successfully added!", "Ok", {
            duration: 3000,
          });
          return FormTypeStoreActions.AddSuccess({ formType: result });
        }),
        catchError((err: HttpErrorResponse) => of(FormTypeStoreActions.AddFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  updateRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormTypeStoreActions.UpdateRequest),
    switchMap((action) =>
      this._settingsClient.settings_PutFormType(action.id, action.formType).pipe(
        switchMap(() => this._settingsClient.settings_GetFormType(action.id)),
        map((result) => {
          this._snackBar.open("Successfully updated!", "Ok", {
            duration: 3000,
          });
          return FormTypeStoreActions.UpdateSuccess({ formType: result });
        }),
        catchError((err: HttpErrorResponse) => of(FormTypeStoreActions.UpdateFailure({ error: err.message })))
      )
    )
  );

  @Effect()
  deleteRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(FormTypeStoreActions.DeleteRequest),
    switchMap((action) =>
      this._settingsClient.settings_DeleteFormType(action.id).pipe(
        map(() => {
          this._snackBar.open("Successfully deleted!", "Ok", {
            duration: 3000,
          });
          return FormTypeStoreActions.DeleteSuccess({ id: action.id });
        }),
        catchError((err: HttpErrorResponse) => of(FormTypeStoreActions.DeleteFailure({ error: err.message })))
      )
    )
  );
}
