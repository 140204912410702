import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatTreeModule,
  MatButtonModule,
  MatIconModule,
  MatRippleModule,
  MatDividerModule,
  MatInputModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatMenuModule,
  MatDatepickerModule,
  MatSelectModule,
  MatExpansionModule,
  MatProgressBarModule
} from '@angular/material';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { PatientOverviewBackComponent } from './patient-overview-back/patient-overview-back.component';
import { PatientOverviewFrontComponent } from './patient-overview-front/patient-overview-front.component';
import { PatientDentistComponent } from './patient-dentist/patient-dentist.component';
import { PatientReferralComponent } from './patient-referral/patient-referral.component';
import { PatientReferredPracticeComponent } from './patient-referred-practice/patient-referred-practice.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DentistReferralSortPipe } from './dentist-referral-sort.pipe';
/* import { DentistClinicSearchComponent } from '../../dentist-clinic-search/dentist-clinic-search/dentist-clinic-search.component'; */
import { DentistClinicSearchModule } from "../../dentist-clinic-search/dentist-clinic-search.module";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ClickConfirmModule } from '../../click-confirm/click-confirm.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@CardModule({
  selector: 'patient-overview',
  front: PatientOverviewFrontComponent,
  back: PatientOverviewBackComponent,
  header: '',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faHome', actionName: '', isFaIcon: true}],
  order: 0,
})
@NgModule({
  declarations: [
    PatientOverviewFrontComponent,
    PatientOverviewBackComponent,
    PatientDentistComponent,
    PatientReferralComponent,
    PatientReferredPracticeComponent,
    DentistReferralSortPipe,
    /* DentistClinicSearchComponent */
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatMenuModule,
    MatDatepickerModule,
    MatSelectModule,
    MatExpansionModule,
    MatProgressBarModule,
    ClickConfirmModule,
    PerfectScrollbarModule,
    DentistClinicSearchModule,
    FontAwesomeModule
  ],
  entryComponents: [PatientOverviewFrontComponent, PatientOverviewBackComponent],
})
export class PatientOverviewModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIconPacks(fas);
  }
}
