import { Component, OnInit, ViewChild, ElementRef, Inject, TemplateRef, OnDestroy } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, forkJoin, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PatientsStoreSelectors, PatientStoreEntity, RootStoreState, DentistStoreActions, DentistStoreSelectors, DentistStoreEntity } from 'src/app/root-store';
import { DomSanitizer } from '@angular/platform-browser';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';

import {
  DocumentTemplateDto,
  PatientClient,
  SettingsClient,
  StationaryDto,
  ClinicClient,
  ClinicDto,
  FileParameter,
  StorageContentTypeEnum,
  TemplateTypeEnumDto
} from 'src/app/shared/services/api.service';

import * as moment from 'moment';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-rx-requests',
  templateUrl: './rx-requests.component.html',
  styleUrls: ['./rx-requests.component.css']
})
export class RxRequestsComponent implements OnInit, OnDestroy {

  @ViewChild("dentistclinic", { static: false }) dentistclinic: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('footercontent', { static: false }) footercontent: ElementRef;
  @ViewChild('messagebox', {static: false}) messagebox:ElementRef;

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  formgrp:FormGroup;
  isDental:boolean = false;
  textTemplates: DocumentTemplateDto[] = [];
  allTextTemplates: DocumentTemplateDto[] = [];
  templates$: Observable<DocumentTemplateDto[]> = this._settingsClient.settings_GetTemplates(
    [TemplateTypeEnumDto.Email, TemplateTypeEnumDto.SMS]
  ).pipe(shareReplay(1));
  stationary$: Observable<StationaryDto[]> = this._settingsClient.settings_GetStationaries().pipe(shareReplay(1));
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  dentists:DentistStoreEntity[] = [];
  initSearch:boolean = false;
  toothArr: any[] = [
    {
      section: 'upper', toothArrnagment: [
        { id: 'UR8' }, { id: 'UR7' }, { id: 'UR6' }, { id: 'UR5' }, { id: 'UR4' }, { id: 'UR3' }, { id: 'UR2' }, { id: 'UR1' },
        { id: 'UL1' }, { id: 'UL2' }, { id: 'UL3' }, { id: 'UL4' }, { id: 'UL5' }, { id: 'UL6' }, { id: 'UL7' }, { id: 'UL8' }]
    },
    {
      section: 'lower', toothArrnagment: [
        { id: 'LR8' }, { id: 'LR7' }, { id: 'LR6' }, { id: 'LR5' }, { id: 'LR4' }, { id: 'LR3' }, { id: 'LR2' }, { id: 'LR1' },
        { id: 'LL1' }, { id: 'LL2' }, { id: 'LL3' }, { id: 'LL4' }, { id: 'LL5' }, { id: 'LL6' }, { id: 'LL7' }, { id: 'LL8' }]
    }
  ];

  permaPrimaryToothArr_Upper: any = [
    { "permanent": "8", "primary": "", "dentalPermanent": "1", "dentalPrimary": "" },
    { "permanent": "7", "primary": "", "dentalPermanent": "2", "dentalPrimary": ""  },
    { "permanent": "6", "primary": "", "dentalPermanent": "3", "dentalPrimary": "" },
    { "permanent": "5", "primary": "e", "dentalPermanent": "4", "dentalPrimary": "A" },
    { "permanent": "4", "primary": "d", "dentalPermanent": "5", "dentalPrimary": "B" },
    { "permanent": "3", "primary": "c", "dentalPermanent": "6", "dentalPrimary": "C" },
    { "permanent": "2", "primary": "b", "dentalPermanent": "7", "dentalPrimary": "D" },
    { "permanent": "1", "primary": "a", "dentalPermanent": "8", "dentalPrimary": "E" },
    { "permanent": "1", "primary": "a", "dentalPermanent": "9", "dentalPrimary": "F" },
    { "permanent": "2", "primary": "b", "dentalPermanent": "10", "dentalPrimary": "G"},
    { "permanent": "3", "primary": "c", "dentalPermanent": "11", "dentalPrimary": "H"},
    { "permanent": "4", "primary": "d", "dentalPermanent": "12", "dentalPrimary": "I"},
    { "permanent": "5", "primary": "e", "dentalPermanent": "13", "dentalPrimary": "J"},
    { "permanent": "6", "primary": "", "dentalPermanent": "14", "dentalPrimary": ""},
    { "permanent": "7", "primary": "", "dentalPermanent": "15", "dentalPrimary": ""},
    { "permanent": "8", "primary": "", "dentalPermanent": "16", "dentalPrimary": ""}
  ];

  permaPrimaryToothArr_Lower: any = [
    { "permanent": "8", "primary": "", "dentalPermanent": "32", "dentalPrimary": ""},
    { "permanent": "7", "primary": "", "dentalPermanent": "31", "dentalPrimary": ""},
    { "permanent": "6", "primary": "", "dentalPermanent": "30", "dentalPrimary": ""},
    { "permanent": "5", "primary": "e", "dentalPermanent": "29", "dentalPrimary": "T"},
    { "permanent": "4", "primary": "d", "dentalPermanent": "28", "dentalPrimary": "S"},
    { "permanent": "3", "primary": "c", "dentalPermanent": "27", "dentalPrimary": "R"},
    { "permanent": "2", "primary": "b", "dentalPermanent": "26", "dentalPrimary": "Q"},
    { "permanent": "1", "primary": "a", "dentalPermanent": "25", "dentalPrimary": "P"},
    { "permanent": "1", "primary": "a", "dentalPermanent": "24", "dentalPrimary": "O"},
    { "permanent": "2", "primary": "b", "dentalPermanent": "23", "dentalPrimary": "N"},
    { "permanent": "3", "primary": "c", "dentalPermanent": "22", "dentalPrimary": "M"},
    { "permanent": "4", "primary": "d", "dentalPermanent": "21", "dentalPrimary": "L"},
    { "permanent": "5", "primary": "e", "dentalPermanent": "20", "dentalPrimary": "K"},
    { "permanent": "6", "primary": "", "dentalPermanent": "19", "dentalPrimary": ""},
    { "permanent": "7", "primary": "", "dentalPermanent": "18", "dentalPrimary": ""},
    { "permanent": "8", "primary": "", "dentalPermanent": "17", "dentalPrimary": ""}
  ];

  permanentPrimaryArr_upper: any[] = [
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    }
  ];

  permanentPrimaryArr_lower: any[] = [
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    },
    {
      "permanent": true,
      "primaryavailable": false,
      "primary": true,
      "parmaLabel": true,
      "missing": false,
      "unerupted": false,
      "extract": false,
      "chain": false,
      "expose": false,
      "caries": false,
      "misc": false,
      "roots": false,
      "primary_missing": false,
      "primary_unerupted": false,
      "primary_extract": false,
      "primary_chain": false,
      "primary_expose": false,
      "primary_caries": false,
      "primary_misc": false,
      "primary_roots": false,
    }
  ];

  selectedOptionBtn: any = "permanent";

  optionBtnSelected: any[] = [
    {"name": "primary", "selected": false},
    {"name": "permanent", "selected" : true},
    {"name": "missing", "selected": false},
    {"name": "unerupted", "selected": false},
    {"name": "extract", "selected": false},
    {"name": "chain", "selected": false},
    {"name": "expose", "selected": false},
    {"name": "caries", "selected": false},
    {"name": "misc", "selected": false},
    {"name": "roots", "selected": false}
  ];

  patientDetails:any = {};
  patient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  clinics: ClinicDto[] = [];
  templateContent: any = "";
  messageBody: any = "";
  genrtPDF:boolean = false;
  rxRequestionReplaced:boolean = false;
  splitedContent1: any = "";
  splitedContent2: any = "";
  stationaryHeader: any = "";
  stationaryFooter: any = "";

  caretPos: any = 0;
  teethDescriptions: any[] = [];
  @ViewChild('toothgridimage', {static: false}) toothgridimage:ElementRef;
  @ViewChild('templateInput', {static: false}) templateInput: ElementRef;
  stationaries: StationaryDto[] = [];

  faCheck = faCheck;
  isLaymanDescription:boolean = false;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private _settingsClient: SettingsClient,
    private _clinicClient: ClinicClient,
    private patientClient: PatientClient,
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.formgrp = this._fb.group({
      template: [''],
      clinicId: [],
      dentistId: [],
      messageBody: [],
      stationaryId: []
    });

    this.templates$
      .pipe(
        filter(template => !!template),
        takeUntil(this._destroy$)
      )
      .subscribe(templates => {
      this.allTextTemplates = templates.filter(template => template.templateType == TemplateTypeEnumDto.Email)
      this.allTextTemplates = _.orderBy(this.allTextTemplates, ((obj)=> obj.name.toLowerCase()), 'asc');
      this.textTemplates = this.allTextTemplates;
    })

    this.stationary$
      .pipe(
        takeUntil(this._destroy$),
        filter(stationary => !!stationary)
    )
      .subscribe(stationaries => {
      this.stationaries = stationaries.filter(stationary => stationary.isActive);
    })

    this.getTeethDescription();
    this.getPatientDetails();
    this.getClinics();
    this._store$.dispatch(DentistStoreActions.LoadRequest({}));
    this._store$.select(DentistStoreSelectors.selectAllDentists)
      .pipe(takeUntil(this._destroy$))
      .subscribe(dentist => {
      if(dentist){
        this.dentists = dentist;
      }

    })
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  getPatientDetails(){
    this.patient$
      .pipe(takeUntil(this._destroy$), takeUntil(this._destroy$))
      .subscribe(res => {
      if(res != null){
        this.patientDetails = res
        this.getTeethElastic();
      }

    })

  }

  getClinics(){
    this._clinicClient.clinic_GetClinics()
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => {
      this.clinics = res.filter(clinic => clinic.isActive)
    });
  }

  getTeethDescription(){
    this._settingsClient.settings_GetTeethSettings(null)
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
      this.teethDescriptions = _.orderBy(resp, (obj)=> parseInt(obj.dentalCode));
    })
  }



  toggleRadio(){
    this.isDental = this.isDental == true ? false : true;
  }

  getTeethElastic() {
    this.patientClient.patient_GetTeethElastics(this.patientDetails.id, 100, undefined, null)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
      let lasteditedItem: any = res[res.length - 1];
      if (lasteditedItem && lasteditedItem.teethSequence != '') {
        let teethSequenceArr: any[] = lasteditedItem.teethSequence.split(':');
        teethSequenceArr.map(_teethelem => {
          let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
          let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);

          if (upperindx != -1) {
            this.permanentPrimaryArr_upper[upperindx].primary = true;
            this.permanentPrimaryArr_upper[upperindx].permanent = false;
            this.permanentPrimaryArr_upper[upperindx].parmaLabel = false;

          }

          if (lowerindx != -1) {
            this.permanentPrimaryArr_lower[lowerindx].primary = true;
            this.permanentPrimaryArr_lower[lowerindx].permanent = false;
            this.permanentPrimaryArr_lower[lowerindx].parmaLabel = false;
          }
        })
      }

      if (lasteditedItem) {
        let respArr: any[] = lasteditedItem.optionSequence.split('-');
        respArr.map(teethelem => {
          let _teethelem: any = teethelem.split(':')[0];

          let upperindx: any = this.toothArr[0].toothArrnagment.findIndex(elem => elem.id === _teethelem);
          let lowerindx: any = this.toothArr[1].toothArrnagment.findIndex(elem => elem.id === _teethelem);

          if (upperindx != -1) {

            let toothOptionsArr: any[] = teethelem.split(':')[1].split(',');

            toothOptionsArr.map(optionItem => {

              if (optionItem == 'M') {
                this.permanentPrimaryArr_upper[upperindx].missing = true;
              }

              if (optionItem == 'U') {
                this.permanentPrimaryArr_upper[upperindx].unerupted = true;
              }

            })
          }

          if (lowerindx != -1) {

            let toothOptionsArr: any[] = teethelem.split(':')[1].split(',');

            toothOptionsArr.map(optionItem => {

              if (optionItem == 'M') {
                this.permanentPrimaryArr_lower[lowerindx].missing = true;
              }

              if (optionItem == 'U') {
                this.permanentPrimaryArr_lower[lowerindx].unerupted = true;
              }

            })
          }

        })
      }
    },
    err => {
      console.log(err)
    })
  }

  getDentistSerarchValue(searchobj){

    if(searchobj.isDentist == true){
      this.dentistclinic.nativeElement.value = searchobj.dentist.displayName;
    } else {
      this.dentistclinic.nativeElement.value = searchobj.clinic.name
    }


		this.formgrp.patchValue({
			dentistId: searchobj.dentist.id,
			clinicId: searchobj.clinic.id
		})
	}

  togglePrimaryTooth(section, indx, isPermanent) {

      if (section == 'upper') {
        if(this.selectedOptionBtn == 'primary' && !isPermanent){
          this.permanentPrimaryArr_upper[indx].primary = this.permanentPrimaryArr_upper[indx].primary ? false : true;
        } else if(this.selectedOptionBtn == 'permanent' && isPermanent){
          this.permanentPrimaryArr_upper[indx].permanent = this.permanentPrimaryArr_upper[indx].permanent ? false : true;
        }else if(this.selectedOptionBtn == 'missing'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].missing = this.permanentPrimaryArr_upper[indx].missing == true ? false : true;
          } else {
            this.permanentPrimaryArr_upper[indx].primary_missing = this.permanentPrimaryArr_upper[indx].primary_missing == true ? false : true;
          }          
        } else if(this.selectedOptionBtn == 'unerupted'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].unerupted = this.permanentPrimaryArr_upper[indx].unerupted == true ? false : true;
          } else {
            this.permanentPrimaryArr_upper[indx].primary_unerupted = this.permanentPrimaryArr_upper[indx].primary_unerupted == true ? false : true;
          }
        } else if(this.selectedOptionBtn == 'extract'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].extract = this.permanentPrimaryArr_upper[indx].extract == true ? false : true;

            if(this.permanentPrimaryArr_upper[indx].extract){
              let _dentalCode = this.permaPrimaryToothArr_Upper[indx].dentalPermanent;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode);
              this.selectedTeethDescription(teethDescriptionItem);
            }
            
          } else {
            this.permanentPrimaryArr_upper[indx].primary_extract = this.permanentPrimaryArr_upper[indx].primary_extract == true ? false : true;

            if(this.permanentPrimaryArr_upper[indx].primary_extract){
              let _dentalCode = this.permaPrimaryToothArr_Upper[indx].dentalPrimary;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode.toLowerCase());
              this.selectedTeethDescription(teethDescriptionItem);
            }

          }
        } else if(this.selectedOptionBtn == 'chain'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].chain = this.permanentPrimaryArr_upper[indx].chain == true ? false : true;
            this.permanentPrimaryArr_upper[indx].permanent = !this.permanentPrimaryArr_upper[indx].chain;
          } else {
            this.permanentPrimaryArr_upper[indx].primary_chain = this.permanentPrimaryArr_upper[indx].primary_chain == true ? false : true;
            this.permanentPrimaryArr_upper[indx].primary = !this.permanentPrimaryArr_upper[indx].primary_chain;
          }
          
        } else if(this.selectedOptionBtn == 'expose'){
          
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].expose = this.permanentPrimaryArr_upper[indx].expose == true ? false : true;
            this.permanentPrimaryArr_upper[indx].permanent = !this.permanentPrimaryArr_upper[indx].expose;

            if(this.permanentPrimaryArr_upper[indx].expose){
              let _dentalCode = this.permaPrimaryToothArr_Upper[indx].dentalPermanent;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode);
              this.selectedTeethDescription(teethDescriptionItem);
            }
          } else {
            this.permanentPrimaryArr_upper[indx].primary_expose = this.permanentPrimaryArr_upper[indx].primary_expose == true ? false : true;
            this.permanentPrimaryArr_upper[indx].primary = !this.permanentPrimaryArr_upper[indx].primary_expose;

            if(this.permanentPrimaryArr_upper[indx].primary_expose){
              let _dentalCode = this.permaPrimaryToothArr_Upper[indx].dentalPrimary;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode.toLowerCase());
              this.selectedTeethDescription(teethDescriptionItem);
            }
          }          

        } else if(this.selectedOptionBtn == 'caries'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].caries = this.permanentPrimaryArr_upper[indx].caries == true ? false : true;
          } else {
            this.permanentPrimaryArr_upper[indx].primary_caries = this.permanentPrimaryArr_upper[indx].primary_caries == true ? false : true;
          }
        } else if(this.selectedOptionBtn == 'misc'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].misc = this.permanentPrimaryArr_upper[indx].misc == true ? false : true;
          } else {
            this.permanentPrimaryArr_upper[indx].primary_misc = this.permanentPrimaryArr_upper[indx].primary_misc == true ? false : true;
          }
        } else if(this.selectedOptionBtn == 'root'){
          if(isPermanent){
            this.permanentPrimaryArr_upper[indx].root = this.permanentPrimaryArr_upper[indx].root == true ? false : true;
          } else {
            this.permanentPrimaryArr_upper[indx].primary_roots = this.permanentPrimaryArr_upper[indx].primary_roots == true ? false : true;
          }
        }
      } else if (section == 'lower') {
        if(this.selectedOptionBtn == 'primary' && !isPermanent){
          this.permanentPrimaryArr_lower[indx].primary = this.permanentPrimaryArr_lower[indx].primary ? false : true;
        } else if(this.selectedOptionBtn == 'permanent' && isPermanent){
          this.permanentPrimaryArr_lower[indx].permanent = this.permanentPrimaryArr_lower[indx].permanent ? false : true;
        } else if(this.selectedOptionBtn == 'missing'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].missing = this.permanentPrimaryArr_lower[indx].missing == true ? false : true;
          } else {
            this.permanentPrimaryArr_lower[indx].primary_missing = this.permanentPrimaryArr_lower[indx].primary_missing == true ? false : true;
          }          
        } else if(this.selectedOptionBtn == 'unerupted'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].unerupted = this.permanentPrimaryArr_lower[indx].unerupted == true ? false : true;
          } else {
            this.permanentPrimaryArr_lower[indx].primary_unerupted = this.permanentPrimaryArr_lower[indx].primary_unerupted == true ? false : true;
          }
        } else if(this.selectedOptionBtn == 'extract'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].extract = this.permanentPrimaryArr_lower[indx].extract == true ? false : true;

            if(this.permanentPrimaryArr_lower[indx].extract){
              let _dentalCode = this.permaPrimaryToothArr_Lower[indx].dentalPermanent;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode);
              this.selectedTeethDescription(teethDescriptionItem);
            }

          } else {
            this.permanentPrimaryArr_lower[indx].primary_extract = this.permanentPrimaryArr_lower[indx].primary_extract == true ? false : true;

            if(this.permanentPrimaryArr_lower[indx].primary_extract){
              let _dentalCode = this.permaPrimaryToothArr_Lower[indx].dentalPrimary;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode.toLowerCase());
              this.selectedTeethDescription(teethDescriptionItem);
            }
          }
        } else if(this.selectedOptionBtn == 'chain'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].chain = this.permanentPrimaryArr_lower[indx].chain == true ? false : true;
            this.permanentPrimaryArr_lower[indx].permanent = !this.permanentPrimaryArr_lower[indx].chain;
          } else {
            this.permanentPrimaryArr_lower[indx].primary_chain = this.permanentPrimaryArr_lower[indx].primary_chain == true ? false : true;
            this.permanentPrimaryArr_lower[indx].primary = !this.permanentPrimaryArr_lower[indx].primary_chain;
          }

        } else if(this.selectedOptionBtn == 'expose'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].expose = this.permanentPrimaryArr_lower[indx].expose == true ? false : true;
            this.permanentPrimaryArr_lower[indx].permanent = !this.permanentPrimaryArr_lower[indx].expose;

            if(this.permanentPrimaryArr_lower[indx].expose){
              let _dentalCode = this.permaPrimaryToothArr_Lower[indx].dentalPermanent;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode);
              this.selectedTeethDescription(teethDescriptionItem);
            }

          } else {
            this.permanentPrimaryArr_lower[indx].primary_expose = this.permanentPrimaryArr_lower[indx].primary_expose == true ? false : true;
            this.permanentPrimaryArr_lower[indx].primary = !this.permanentPrimaryArr_lower[indx].primary_expose;

            if(this.permanentPrimaryArr_lower[indx].primary_expose){
              let _dentalCode = this.permaPrimaryToothArr_Lower[indx].dentalPrimary;
              let teethDescriptionItem = this.teethDescriptions.find(teethDescription => teethDescription.dentalCode ==  _dentalCode.toLowerCase());
              this.selectedTeethDescription(teethDescriptionItem);
            }
          }
        } else if(this.selectedOptionBtn == 'caries'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].caries = this.permanentPrimaryArr_lower[indx].caries == true ? false : true;
          } else {
            this.permanentPrimaryArr_lower[indx].primary_caries = this.permanentPrimaryArr_lower[indx].primary_caries == true ? false : true;
          }
        } else if(this.selectedOptionBtn == 'misc'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].misc = this.permanentPrimaryArr_lower[indx].misc == true ? false : true;
          } else {
            this.permanentPrimaryArr_lower[indx].primary_misc = this.permanentPrimaryArr_lower[indx].primary_misc == true ? false : true;
          }
        } else if(this.selectedOptionBtn == 'root'){
          if(isPermanent){
            this.permanentPrimaryArr_lower[indx].root = this.permanentPrimaryArr_lower[indx].root == true ? false : true;
          } else {
            this.permanentPrimaryArr_lower[indx].primary_roots = this.permanentPrimaryArr_lower[indx].primary_roots == true ? false : true;
          }
        }
      }
  }

  getOption(type){
    this.selectedOptionBtn = type;

    if(type == 'primary'){
      this.optionBtnSelected[0].selected = true;
      this.optionBtnSelected[1].selected = false;
    } else if(type == 'permanent') {
      this.optionBtnSelected[0].selected = false;
      this.optionBtnSelected[1].selected = true;
    } else {
      let btnIndx: any = this.optionBtnSelected.findIndex(item => item.name === type);

      if(btnIndx != -1){
        this.optionBtnSelected.map((item, index) => {
          if(index > 1){
            item.selected = false;
          }
        })

        this.optionBtnSelected[btnIndx].selected = true;
      }
    }
  }

  generatePdf(){

    this.genrtPDF = true;
    let formvalue: any = this.formgrp.value;
    let dentistId: any = formvalue.dentistId;

    if(dentistId == null){
      dentistId = 0;
    }

    if(formvalue.messageBody){

      this.messageBody = this.sanitizer.bypassSecurityTrustHtml(formvalue.messageBody.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));
    }

    if (formvalue.stationaryId && formvalue.template){

      forkJoin(
        this.patientClient.patient_GetPatientStationary(this.patientDetails.id, formvalue.stationaryId),
        this.patientClient.patient_GetPatientTemplate(
          this.patientDetails.id,
          formvalue.template.id,
          null,
          null,
          null,
          dentistId,
          null,
          null,
          null,
          null,
          null,
          true
        )
      )
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {

        let stationaryRes: StationaryDto = res[0];
        let tempateRes: DocumentTemplateDto = res[1];

        this.stationaryHeader = this.sanitizer.bypassSecurityTrustHtml(stationaryRes.header);
        this.stationaryFooter = this.sanitizer.bypassSecurityTrustHtml(stationaryRes.footer);

        let rxRequestIndx: any = tempateRes.content.indexOf('{RxRequest}');

          if(rxRequestIndx != -1 ){
            this.rxRequestionReplaced = true;
            let _splitedContent1: any = tempateRes.content.substring(0, rxRequestIndx);
            let _splitedContent2: any = tempateRes.content.substring(rxRequestIndx+11, tempateRes.content.length);
            this.splitedContent1 = this.sanitizer.bypassSecurityTrustHtml(_splitedContent1.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));
            this.splitedContent2 = this.sanitizer.bypassSecurityTrustHtml(_splitedContent2.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));
          } else {
            this.rxRequestionReplaced = false;
            this.templateContent = this.sanitizer.bypassSecurityTrustHtml(tempateRes.content.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));
          }

          this.createDataForPDF();

      })

    } else {
      if(formvalue.stationaryId){
        this.patientClient.patient_GetPatientStationary(
          this.patientDetails.id,
          formvalue.stationaryId

        )
          .pipe(takeUntil(this._destroy$))
          .subscribe((res: StationaryDto) => {
          this.stationaryHeader = this.sanitizer.bypassSecurityTrustHtml(res.header);
          this.stationaryFooter = this.sanitizer.bypassSecurityTrustHtml(res.footer);
          this.createDataForPDF();

        }, (err) => {
          console.log('stationary error:', err);

        });
      } else if (formvalue.template){

        this.patientClient.patient_GetPatientTemplate(
          this.patientDetails.id,
          formvalue.template.id,
          null,
          null,
          null,
          dentistId,
          null,
          null,
          null,
          null,
          null,
          true
        )
          .pipe(takeUntil(this._destroy$))
          .subscribe((res: DocumentTemplateDto) => {

          let rxRequestIndx: any = res.content.indexOf('{RxRequest}');

          if(rxRequestIndx != -1 ){
            this.rxRequestionReplaced = true;
            let _splitedContent1: any = res.content.substring(0, rxRequestIndx);
            let _splitedContent2: any = res.content.substring(rxRequestIndx+11, res.content.length);
            this.splitedContent1 = this.sanitizer.bypassSecurityTrustHtml(_splitedContent1.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));
            this.splitedContent2 = this.sanitizer.bypassSecurityTrustHtml(_splitedContent2.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));

          } else {
            this.rxRequestionReplaced = false;
            this.templateContent = this.sanitizer.bypassSecurityTrustHtml(res.content.replace(/(?:\r\n|\r|\n)/g, "<br class='br-class' />"));
          }

          this.createDataForPDF();

        }, (err) => {
            console.log('template error:', err);
        });

      } else {
        this.createDataForPDF();
      }
    }
  }

  createDataForPDF(){
    this.createToothGridImage();
    
  }

  getClinicById(type){
    if (this.formgrp.value.clinicId) {
      let clinicId: any = this.formgrp.value.clinicId;
      let clinic: ClinicDto = this.clinics.find(clinic => clinic.id == clinicId);
      if(clinic){
        if (type == 'name') {
          return clinic.name;
        } else if (type == 'address') {
          let address: any = "";
          if(clinic.addressLine2 == null){
            address = `${clinic.addressLine1} \n ${clinic.city}, ${clinic.state} ${clinic.zip}`
          } else {
            address = `${clinic.addressLine1} \n ${clinic.addressLine2} \n ${clinic.city}, ${clinic.state} ${clinic.zip}`
          }
          return address;
        }
      }
    }
    return;
  }

  getDentistById(){
    if (this.formgrp.value.dentistId) {
      let dentistId:any = this.formgrp.value.dentistId;
      let dentist:DentistStoreEntity = this.dentists.find(dentist => dentist.id == dentistId)
      return dentist ? dentist.displayName : ''
    } else {
      return
    }
  }

  selectedTeethDescription(toothItem){

    let description: any = this.formgrp.value.messageBody ? this.formgrp.value.messageBody : '';

    let cursorBeforeData: any = description.substring(0, this.caretPos);
		let cursorAfterData: any = description.substring(this.caretPos, description.length);
    let toothItemDesc: any = this.isLaymanDescription ? toothItem.laymanDescription : toothItem.description;
    if(toothItemDesc == null || toothItemDesc == undefined){
      toothItemDesc = "";
    }
    
    this.formgrp.patchValue({
      messageBody: (cursorBeforeData == '' ? `${cursorBeforeData} ${toothItemDesc} ${cursorAfterData}` : `${cursorBeforeData} \n${toothItemDesc} ${cursorAfterData}`)
    });

    setTimeout(() => {
      this.messagebox.nativeElement.focus();
      this.messagebox.nativeElement.selectionEnd = this.caretPos + toothItemDesc.length + 2;
    })

  }

  getCaretPos(oField) {
		if (oField.selectionStart || oField.selectionStart == '0') {
		   this.caretPos = oField.selectionStart;
		}
	}

  selectTemplate(evt){
    let template:DocumentTemplateDto = evt.option.value;
    this.formgrp.patchValue({
      stationaryId: template.defaultStationaryId,
      template: template
    })
    this.templateInput.nativeElement.value = template.name;
  }

  allPrimaryPermanent(toothtype){
    if(toothtype == 'primary'){
      this.permanentPrimaryArr_upper.map(item => {
        item.primary = true;
        item.permanent = false;
        item.parmaLabel = false;
      });

      this.permanentPrimaryArr_lower.map(item => {
        item.primary = true;
        item.permanent = false;
        item.parmaLabel = false;
      });

    } else {
      this.permanentPrimaryArr_upper.map(item => {
        item.primary = false;
        item.permanent = true;
        item.parmaLabel = true;
      });

      this.permanentPrimaryArr_lower.map(item => {
        item.primary = false;
        item.permanent = true;
        item.parmaLabel = true;
      });
    }
  }

  createToothGridImage(){
    html2canvas(this.toothgridimage.nativeElement).then((canvas) => {
      let img = canvas.toDataURL("image/jpeg");
      this.toothgridimage.nativeElement.innerHTML = `<img src='${img}' width=100% />`;

      setTimeout(() => {
        let content = this.content.nativeElement;
        this.data.flip.next(<IFlipEvent>{
          payload: {
            content: content,
            footer: this.stationaryFooter,
            templateId: this.formgrp.value.template.id,
            template: this.formgrp.value.template,
            stationaryId: this.formgrp.value.stationaryId,
            dentistId: this.formgrp.value.dentistId,
            rxRequestElement: `<div id="rxrequestelm"> <img src='${img}' width=100% /> </div>`
          },
          side: this.data.side
        })
        this.rxRequestionReplaced = false;
        this.genrtPDF = false;
      }, 1000);
    })
  }

  getTemplate(template){
    if(template){
      return template;
    }
    return null;
  }

  getStationary(stationaryId){
    if(stationaryId){
      let stationary = this.stationaries.find(stationary => stationary.id == stationaryId);
      if(stationary){
        return stationary;
      } else {
        return null;
      }
    }
    return null;
  }

  toggleCheckbox(){
    this.isLaymanDescription = this.isLaymanDescription ? false : true;
  }

  searchTemplate(evt){
    let searchkeyword: any = evt.target.value;
    if (searchkeyword.length > 0) {
      this.textTemplates = this.allTextTemplates.filter(option => option.name.toLowerCase().includes(searchkeyword.toLowerCase()));
      if(this.textTemplates.length == 0){
        this.textTemplates = this.allTextTemplates;
      }
    } else {
      this.textTemplates = this.allTextTemplates;
    }
  }

  whoToPatientDentist(){
    if(this.patientDetails){
      this.dentistclinic.nativeElement.value = this.patientDetails.dentistClinic.dentist.displayName;

      this.formgrp.patchValue({
        dentistId: this.patientDetails.dentistClinic.dentistId,
        clinicId: this.patientDetails.dentistClinic.clinicId
      })
    }
  }
}
