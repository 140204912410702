import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IPatientCephalometricDto, PatientCephalometricDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientCephalometricStoreEntity> = createEntityAdapter<PatientCephalometricStoreEntity>({
  selectId: cephalometric => cephalometric.id,
  sortComparer: (a: PatientCephalometricStoreEntity, b: PatientCephalometricStoreEntity): number => b.createdWhen.getTime() - a.createdWhen.getTime()
});

export interface State extends EntityState<PatientCephalometricStoreEntity> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null
});

export class PatientCephalometricStoreEntity extends PatientCephalometricDto {
  constructor(data?: IPatientCephalometricDto) {
    super(data);
  }
}
