import { Component, Inject, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppPropertyService } from 'src/app/shared/services/property-service/property-service.service';
import { TreatmentPlanService } from 'src/app/shared/services/settings/treatment-plan.service';
import { fromEvent } from 'rxjs';
import { debounceTime, map, filter, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { faCheckCircle, faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.css'],
})
export class NotesModalComponent implements OnInit {
  @ViewChild("searchinput", { static: false }) searchinput: ElementRef;
  @ViewChild("textarea", { static: false }) textarea: ElementRef;
  
  public notesArray: any;
  public note = {
    noteValue: '',
    colorCode: '',
  };
  public showColorPalette = false;
  public colorPaletteArray = [];
  filterNotes: any[] = [];

  faCheckCircle = faCheckCircle;
  faClone = faClone;
  faPaintBrush = faPaintBrush;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appPropSvc: AppPropertyService,
    private _treatmentPlanSvc: TreatmentPlanService,
    public dialogRef: MatDialogRef<NotesModalComponent>
  ) {
    this.colorPaletteArray = this.appPropSvc.get(
      'treatment_plan.colorPaletteArray'
    );
  }

  ngOnInit() {
    this.data['notesArray'] = _.orderBy(this.data['notesArray'], 'shortName', 'asc');
    this.filterNotes = this.data['notesArray'];
    this.note.noteValue = this.data['notesObj'].noteValue === undefined ? '' : this.data['notesObj'].noteValue === null ? '' : this.data['notesObj'].noteValue;
    this.note.colorCode = this.data['notesObj'].colorCode === undefined ? '' : this.data['notesObj'].colorCode;
  }

  ngAfterViewInit() {
		setTimeout(() => {
			this.searchNotes();
      this.textarea.nativeElement.focus();
		}, 500);
	}

  public pickNote(note: string): void {
    this.note.noteValue += `${note}\n`;
  }

  public save() {
    this.dialogRef.close(this.note);
  }

  public openPalette(): void {
    this.showColorPalette = this.showColorPalette ? false : true;

    console.log("showColorPalette: ", this.showColorPalette)
  }

  public selectColor(clr: string): void {
    this._treatmentPlanSvc.removeClassOnElementWithClassnames(
      'circle',
      'selected-circle'
    );
    this.note.colorCode = '';
    const el = document.getElementById(`circle_${clr}`);

    el.classList.add('selected-circle');
    this.note.colorCode = clr;
    //this.showColorPalette = false;
  }

  searchNotes() {

    fromEvent(this.searchinput.nativeElement, "keyup").pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {
        let searchkeyword: any = res.target.value;
        if (searchkeyword.length > 0) {
          if(this.data.isDynamic)
            this.filterNotes = this.data['notesArray'].filter(option => option.name.toLowerCase().includes(searchkeyword.toLowerCase()));
          else
            this.filterNotes = this.data['notesArray'].filter(option => option.shortName.toLowerCase().includes(searchkeyword.toLowerCase()));
        } else {
          this.filterNotes = this.data['notesArray'];
        }
      })
  }

  copyNoteFromLastCard(){
    if(this.data.lastcard){
      if(this.data.noteType === 'today'){
        this.note.noteValue = this.data.lastcard.nextNotes
      } else {
        this.note.noteValue = this.data.newcard.todayNotes
      }
      
    }
    
  }
}
