export declare type USSTATE_KEY =
'AK' | 'AL' | 'AR' | 'AS' | 'AZ' | 'CA' | 'CO' | 'CT' | 'DC' | 'DE' | 'FL' | 'GA' | 'GU' | 'HI' | 'IA' | 'ID' | 'IL' |
'IN' | 'KS' | 'KY' | 'LA' | 'MA' | 'MD' | 'ME' | 'MI' | 'MN' | 'MO' | 'MS' | 'MT' | 'NC' | 'ND' | 'NE' | 'NH' | 'NJ' |
'NM' | 'NV' | 'NY' | 'OH' | 'OK' | 'OR' | 'PA' | 'PR' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VA' | 'VI' | 'VT' |
'WA' | 'WI' | 'WV' | 'WY';

export declare type USSTATE = { name: string; };
export const USSTATE_LIST: { [key in USSTATE_KEY]: USSTATE } = {
  'AK' : { name: 'Alaska' },
  'AL' : { name: 'Alabama' },
  'AR' : { name: 'Arkansas' },
  'AS' : { name: 'American Samoa' },
  'AZ' : { name: 'Arizona' },
  'CA' : { name: 'California' },
  'CO' : { name: 'Colorado' },
  'CT' : { name: 'Connecticut' },
  'DC' : { name: 'District of Columbia' },
  'DE' : { name: 'Delaware' },
  'FL' : { name: 'Florida' },
  'GA' : { name: 'Georgia' },
  'GU' : { name: 'Guam' },
  'HI' : { name: 'Hawaii' },
  'IA' : { name: 'Iowa' },
  'ID' : { name: 'Idaho' },
  'IL' : { name: 'Illinois' },
  'IN' : { name: 'Indiana' },
  'KS' : { name: 'Kansas' },
  'KY' : { name: 'Kentucky' },
  'LA' : { name: 'Louisiana' },
  'MA' : { name: 'Massachusetts' },
  'MD' : { name: 'Maryland' },
  'ME' : { name: 'Maine' },
  'MI' : { name: 'Michigan' },
  'MN' : { name: 'Minnesota' },
  'MO' : { name: 'Missouri' },
  'MS' : { name: 'Mississippi' },
  'MT' : { name: 'Montana' },
  'NC' : { name: 'North Carolina' },
  'ND' : { name: ' North Dakota' },
  'NE' : { name: 'Nebraska' },
  'NH' : { name: 'New Hampshire' },
  'NJ' : { name: 'New Jersey' },
  'NM' : { name: 'New Mexico' },
  'NV' : { name: 'Nevada' },
  'NY' : { name: 'New York' },
  'OH' : { name: 'Ohio' },
  'OK' : { name: 'Oklahoma' },
  'OR' : { name: 'Oregon' },
  'PA' : { name: 'Pennsylvania' },
  'PR' : { name: 'Puerto Rico' },
  'RI' : { name: 'Rhode Island' },
  'SC' : { name: 'South Carolina' },
  'SD' : { name: 'South Dakota' },
  'TN' : { name: 'Tennessee' },
  'TX' : { name: 'Texas' },
  'UT' : { name: 'Utah' },
  'VA' : { name: 'Virginia' },
  'VI' : { name: 'Virgin Islands' },
  'VT' : { name: 'Vermont' },
  'WA' : { name: 'Washington' },
  'WI' : { name: 'Wisconsin' },
  'WV' : { name: 'West Virginia' },
  'WY' : { name: 'Wyoming'}
};
