import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchMap, switchMapTo, take, withLatestFrom } from 'rxjs/operators';
import { EntityHubEvent, HubEventArea, HubEventType, MessageDto, PatientClient, StorageContentTypeEnum } from 'src/app/shared/services/api.service';
import { PatientsStoreActions } from '../patient-store';
import * as RootStoreState from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as PatientMessageStoreActions from './actions';
import * as PatientMessageStoreSelectors from './selectors';
import { State } from './state';

@Injectable({ providedIn: 'root' })
export class PatientMessageStoreEffectsService {
  private _state$ = this._store$.select(PatientMessageStoreSelectors.selectPatientMessageState);

  constructor(private _store$: Store<RootStoreState.State>, private _actions$: Actions, private _patientClient: PatientClient) { }

  resetPageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.ResetRequest),
      filter((action) => action.patientId !== null || action.patientId !== undefined),
      mapTo(PatientMessageStoreActions.LoadPageRequest({ page: 0 })),
      share()
    )
  );

  loadPageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.LoadPageRequest),
      withLatestFrom(this._state$),
      filter(([_, state]) => state.patientId !== null || state.patientId !== undefined),
      switchMap(([action, state]) =>
        this._patientClient.patient_GetMessages(state.patientId, state.pageSize, action.page).pipe(
          map((result) => ({
            messages: result,
            prevEof: action.page >= 0 ? result.length < state.pageSize : state.previousEOF,
            futureEof: action.page < 0 ? result.length < state.pageSize : state.futureEOF,
          })),
          map((result) =>
            PatientMessageStoreActions.LoadPageSuccess({ messages: result.messages, previousEof: result.prevEof, futureEof: result.futureEof })
          ),
          catchError((error) => of(PatientMessageStoreActions.LoadPageFailure({ error })))
        )
      ),
      share()
    )
  );

  loadNextPageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.LoadNextPageRequest),
      switchMapTo(this._store$.select(PatientMessageStoreSelectors.selectPatientMessageState).pipe(take(1))),
      map((state) => PatientMessageStoreActions.LoadPageRequest({ page: state.previousPageNumber })),
      share()
    )
  );

  loadNextFuturePageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.LoadNextFuturePageRequest),
      switchMapTo(this._store$.select(PatientMessageStoreSelectors.selectPatientMessageState).pipe(take(1))),
      map((state) => PatientMessageStoreActions.LoadPageRequest({ page: state.futurePageNumber })),
      share()
    )
  );

  addReminderRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.AddReminderRequest),
      switchMap((action) =>
        this._patientClient.patient_PostReminder(action.reminder.patientId, action.reminder).pipe(
          take(1),
          map((result) => PatientMessageStoreActions.AddReminderSuccess({ messages: result })),
          catchError((error) => of(PatientMessageStoreActions.AddReminderFailure({ error: error })))
        )
      ),
      share()
    )
  );

  addMessageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.AddMessageRequest),
      switchMap((action) =>
        this._patientClient.patient_PostMessage(
          action.message.patientId,
          action.message
        ).pipe(
          take(1),
          map((result) => PatientMessageStoreActions.AddMessageSuccess({ message: result, messageDraftId: action.messageDraftId })),
          catchError((error) => of(PatientMessageStoreActions.AddMessageFailure({ error: error })))
        )
      ),
      share()
    )
  );

  addReplyMessageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.AddReplyMessageRequest),
      withLatestFrom(this._state$),
      filter(([_, state]) => state.patientId !== null || state.patientId !== undefined),
      switchMap(([action, state]) =>
        this._patientClient.patient_PostReplyMessage(
          state.patientId,
          action.replyMessage.messageId,
          action.replyMessage
        ).pipe(
          take(1),
          map((result) => PatientMessageStoreActions.AddReplyMessageSuccess({ message: result })),
          catchError((error) => of(PatientMessageStoreActions.AddReplyMessageFailure({ error: error })))
        )
      ),
      share()
    )
  );

  addNotificationRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.AddFollowUpRequest),
      switchMap((action) =>
        this._patientClient.patient_PostFollowUps(action.followUp.patientId, action.followUp).pipe(
          take(1),
          map((result) => PatientMessageStoreActions.AddFollowUpSuccess({ message: result })),
          catchError((error) => of(PatientMessageStoreActions.AddFollowUpFailure({ error: error })))
        )
      ),
      share()
    )
  );

  removeMessageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.DeleteMessageRequest),
      switchMap((action) =>
        this._patientClient.patient_DeleteMessage(action.patientId, action.messageId, action.deleteThread).pipe(
          take(1),
          mapTo(PatientMessageStoreActions.DeleteMessageSuccess({ patientId: action.patientId, messageId: action.messageId })),
          catchError((error) => of(PatientMessageStoreActions.DeleteMessageFailure({ error: error })))
        )
      ),
      share()
    )
  );

  selectPatientSuccessEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientsStoreActions.SelectSuccess),
      map((action) => PatientMessageStoreActions.ResetRequest({ patientId: action.patient.id })),
      share()
    )
  );

  markAsReadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.MarkAsReadRequest),
      switchMap((action) =>
        this._patientClient.patient_MarkReadCommunicationDeskMessage(action.patientId, action.id).pipe(
          take(1),
          mapTo(PatientMessageStoreActions.MarkAsReadSuccess({ id: action.id, patientId: action.patientId })),
          catchError((error) => of(PatientMessageStoreActions.DeleteMessageFailure({ error: error })))
        )
      ),
      share()
    )
  );

  loadMessageRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.LoadMessageRequest),
      withLatestFrom(this._state$),
      switchMap(([action, state]) =>
        this._patientClient.patient_GetMessage(state.patientId, action.messageId).pipe(
          take(1),
          //TODO: maybe discern some way to determine if message is within range of loaded pages
          map((result) => PatientMessageStoreActions.LoadMessageSuccess({ patientId: action.patientId, message: result })),
          catchError((error) => of(PatientMessageStoreActions.LoadPageFailure({ error })))
        )
      ),
      share()
    )
  );

  eventHubEffects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      filter((action) => action.event.eventArea == HubEventArea.CommunicationEvent && action.event.entityType == MessageDto.name),
      withLatestFrom(this._state$),
      filter(([action, state]) => this.filterMessageEvent(action.event, state)),
      map(([action, state]) => {
        const patientId = Number(action.event.properties.find((p) => p.key == 'PatientId').value);
        switch (action.event.eventType) {
          case HubEventType.Removed:
            //removed
            return PatientMessageStoreActions.MessagedRemoved({ messageId: action.event.entityId });
          case HubEventType.Modified:
            //updated and included in store
            return PatientMessageStoreActions.LoadMessageRequest({ messageId: action.event.entityId, patientId: patientId });
          case HubEventType.Added:
            //added but not included in store
            return PatientMessageStoreActions.LoadMessageRequest({ messageId: action.event.entityId, patientId: patientId });
        }
      })
    )
  );

  patientCopyMessageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMessageStoreActions.CopyMessage),
      map((action) => {
        return PatientMessageStoreActions.UpdateCopiedMessage({ copiedMessage: action });
      })
    )
  );

  private filterMessageEvent(event: EntityHubEvent, state: State): boolean {
    //check if messages are loaded for a patient
    if (state.patientId == null) {
      return false;
    }

    //check if patient matches the patient loaded into messages
    const prop = event.properties && event.properties.find((p) => p.key == 'PatientId');
    if (state.patientId != (prop && Number(prop.value))) {
      return false;
    }

    //check if message NOT loaded for modified event
    if (event.eventType == HubEventType.Modified && !(<number[]>state.ids).includes(event.entityId)) return false;

    //check if message loaded for added event
    if (event.eventType == HubEventType.Modified && (<number[]>state.ids).includes(event.entityId)) return false;

    //All good
    return true;
  }
}
