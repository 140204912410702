import { Action, createReducer, on } from "@ngrx/store";
import * as PatientTreatmentStatusStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(PatientTreatmentStatusStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedPatientTreatmentStatusId: null
  })),
  on(PatientTreatmentStatusStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patientTreatmentStatuses, { ...s, isLoading: false, error: null })
  }),
  on(PatientTreatmentStatusStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //ADD
  on(PatientTreatmentStatusStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientTreatmentStatusStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.patientTreatmentStatus,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientTreatmentStatusStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UPDATE
  on(PatientTreatmentStatusStoreActions.UpdateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientTreatmentStatusStoreActions.UpdateSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.patientTreatmentStatus,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientTreatmentStatusStoreActions.UpdateFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DELETE
  on(PatientTreatmentStatusStoreActions.DeleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientTreatmentStatusStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(
      action.id,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(PatientTreatmentStatusStoreActions.DeleteFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(PatientTreatmentStatusStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedPatientTreatmentStatusId: null
  })),
  on(PatientTreatmentStatusStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientTreatmentStatusId: action.patientTreatmentStatus && action.patientTreatmentStatus.id
  })),
  on(PatientTreatmentStatusStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(PatientTreatmentStatusStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientTreatmentStatusStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedPatientTreatmentStatusId: null
  })),
  on(PatientTreatmentStatusStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(PatientTreatmentStatusStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
