import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar, MatTreeNestedDataSource } from '@angular/material';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { combineLatest, isObservable, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CardsStoreActions, CardsStoreSelectors, LocationsStoreSelectors, PatientsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { FileDto, FolderDto, PatientClient, StorageContentTypeEnum, StorageItemBaseDto, StorageTypeEnum } from 'src/app/shared/services/api.service';
import { Download } from 'src/app/shared/services/download/download';
import { DownloadService } from 'src/app/shared/services/download/download.service';
import { FileViewerComponent } from '../../../file-viewer/file-viewer.component';
import { FileUploadDialogComponent, FileUploadDialogData } from '../file-upload-dialog/file-upload-dialog.component';
import { NewFolderDialogComponent, NewFolderDialogData } from '../new-folder-dialog/new-folder-dialog.component';
import { RenameFileDialogComponent, RenameFileDialogData } from '../rename-file-dialog/rename-file-dialog.component';
import { TwainUploadDialogComponent, TwainUploadDialogData } from '../twain-upload-dialog/twain-upload-dialog.component';
import {faFolderPlus } from '@fortawesome/free-solid-svg-icons';

interface TreeNode {
  fileDtoId?: number;
  name: string;
  cardSelector?: string;
  storageItem?: StorageItemBaseDto;
  children?: TreeNode[] | Observable<TreeNode[]>;
  isLoaded?: boolean;
  isWorking?: boolean;
  type: StorageTypeEnum;
  childrenCount?: number;
  parentId?: number;
  contentType?: StorageContentTypeEnum;
  download$?: Observable<Download>;
  restrictedFolder?: boolean;
  createdWhen?: Date;
}

class CustomNestedTreeControl<T> extends NestedTreeControl<T> {
  canCollapse: (dataNode: T) => boolean = (_) => true;
  constructor(getChildren: (dataNode: T) => Observable<T[]> | T[] | undefined | null, canCollapse?: (dataNode: T) => boolean) {
    super(getChildren);
    if (canCollapse) this.canCollapse = canCollapse;
  }
}

@UntilDestroy()
@Component({
  selector: 'app-patient-files-front',
  templateUrl: './patient-files-front.component.html',
  styleUrls: ['./patient-files-front.component.scss'],
})
export class PatientFilesFrontComponent implements OnInit, AfterViewInit {
  thumbnailRef: MatDialogRef<FileViewerComponent, any>;
  selectedLocation$ = this._rootStore$.select(LocationsStoreSelectors.getSelectedLocation);
  selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId)
    .pipe(
      untilDestroyed(this),
      filter((id) => !!id),
      distinctUntilChanged()
    )
    .pipe(map(patientId => {
      this.patientId = patientId;
      if (!!this.patientId) {
        this.initialize();
      }
      return patientId;
    }));
  patientId: number;
  treeControl = new CustomNestedTreeControl<TreeNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  private _loadFolder$: Subject<[number, TreeNode]> = new Subject<[number, TreeNode]>();
  private _rootFolders$: Observable<TreeNode[]>;
  moveFiles: StorageItemBaseDto[] = [];
  restrictedFolders: string[] = ['Forms', 'Attachments'];

  defaultFoldersWorking:boolean = false;
  faFolderPlus = faFolderPlus;

  //ROOT TREE NODES
  treeData: TreeNode[];

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    @Inject(CARD_DATA) private _data: ICardData<any, StorageItemBaseDto>,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _downloadService: DownloadService,
    private _rootStore$: Store<RootStoreState.State>,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {}

  initialize(): void {
    this._rootFolders$ = this.getFolder(null);
    this.treeData = [
      {
        name: 'Files',
        children: this._rootFolders$,
        fileDtoId: null,
        type: StorageTypeEnum.Folder,
        parentId: null,
      },
    ];
    this.dataSource.data = this.treeData;
    this.treeControl.expand(this.dataSource.data[0]);
    setTimeout(() => {
      this.loadFolder(this.dataSource.data[0]);
    });
  }

  hasChild = (_: number, node: TreeNode) => !!node.children || node.type == StorageTypeEnum.Folder;

  /**
   * Opens a card adjacent to the current one
   */
  openCard(cardSelector: string) {
    this._store$
      .select(CardsStoreSelectors.getCardOrderBySelector('patient-files'))
      .pipe(take(1))
      .subscribe((order) => this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: cardSelector, order: order + 1 })));
  }

  /**
   * Indicate a folder to be loaded from api if not already loaded
   */
  loadFolder(node: TreeNode) {
    if (!node.isLoaded) {
      this._loadFolder$.next([node.fileDtoId, node]);
    }
  }

  /**
   * Converts a list of FileDto into treenodes representing either a file or a folder
   */
  mapFoldersToTreeNode(contents: FileDto[]): TreeNode[] {
    if (contents && contents.length > 0) {
      return _.chain(contents)
        .orderBy(['type', 'createdWhen'], ['desc', 'asc'])
        .map((c: FileDto) => { return this.mapContentToTreeNode(c); })
        .value();
    }
    return [];
  }

  /**
   * Converts a FileDto item into a tree node representing either a folder or a file depending upon item type
   */
  mapContentToTreeNode(content: FileDto): TreeNode {
    if (content.type == StorageTypeEnum.File) {
      //Item is a file
      return <TreeNode>{
        name: this.getNodeName(content),
        storageItem: content,
        cardSelector: this.contentTypeToCardSelector(content.contentType),
        type: StorageTypeEnum.File,
        parentId: content.parentId,
        contentType: content.contentType,
        createdWhen: content.createdWhen
      };
    } else if (content.type == StorageTypeEnum.Folder) {
      //Item is a folder
      return <TreeNode>{
        fileDtoId: content.id,
        name: content.name,
        children: this.getFolder(content.id),
        type: StorageTypeEnum.Folder,
        childrenCount: content.childrenCount,
        isLoaded: content.childrenCount > 0 ? false : true,
        parentId: content.parentId,
        restrictedFolder: _.indexOf(this.restrictedFolders, content.name) > -1
      };
    } else {
      //Something wrong with item
      return <TreeNode>{
        name: 'File Missing or Broken',
      };
    }
  }

  /**
   * Get the text to display for the file name
   * @param file
   */
  getNodeName(file: FileDto): string {
    if (file.contentType == StorageContentTypeEnum.Other) {
      return file.name;
    }
    else {
      return file.name.replace(/\.[^/.]+$/, "");
    }
  }

  /**
   * Returns an observable that when subscribed to returns the list of contents for a single folder
   */
  getFolder(fileDtoId: number): Observable<TreeNode[]> {
    //Defers loading the folders until the folderId has its number called
    return this._loadFolder$.pipe(
      //filters to only load when id matches the requested folder
      filter(([folderId, node]) => {
        return folderId == fileDtoId &&
               !!this.patientId;
      }),
      //set loading to false to show progress bar
      tap(([folderId, node]) => (node.isLoaded = false)),
      switchMap(([folderId, node]) =>
        combineLatest([
          of(node),
          //fetches root folders when folderId is null, otherwise fetches a specific folders contents
          folderId == null ? this._patientClient.patient_GetFolders(this.patientId) : this._patientClient.patient_GetFolder(this.patientId, folderId),
        ])
      ),
      map(([node, contents]) => {
        //set node to loaded and return a list of its contents mapped as TreeNodes
        node.isLoaded = true;
        node.childrenCount = contents.length;
        return this.mapFoldersToTreeNode(contents);
      }),
      //shareReplay critical to prevent reload on every view change
      shareReplay()
    );
  }

  /**
   * Maps a StorageContentType to the card where those items are managed
   */
  contentTypeToCardSelector(contentType: StorageContentTypeEnum): string {
    switch (contentType) {
      case StorageContentTypeEnum.Ceph:
        return 'cephalometrics';
      case StorageContentTypeEnum.Imaging:
      case StorageContentTypeEnum.IntraOral:
        return 'patient-imaging';
      case StorageContentTypeEnum.Model:
        return '3d-models';
      case StorageContentTypeEnum.Contract:
      case StorageContentTypeEnum.Attachment:
      case StorageContentTypeEnum.Other:
      default:
        return null;
    }
  }

  /**
   * Open a dialog to upload a file
   */
  openAddFolderDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data = <NewFolderDialogData>{
      parentId: node.fileDtoId,
      patientId: this.patientId
    };

    this._bottomSheet
      .open<NewFolderDialogComponent, NewFolderDialogData, [number, FolderDto]>(NewFolderDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const [parentId, folder] = result;
          this._loadFolder$.next([parentId, node]);
        }
      });
  }

  /**
   * Open a dialog to rename a file
   */
  openRenameFileDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data = <RenameFileDialogData>{
      parentId: node.parentId,
      patientId: this.patientId,
      fileId: node.storageItem.id,
      currentFileName: node.storageItem.name
    };

    this._bottomSheet
      .open<RenameFileDialogComponent, RenameFileDialogData, [number, FolderDto]>(RenameFileDialogComponent, { data: data })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const [parentId, updatedFile] = result;
          this._loadFolder$.next([parentId, node]);
        }
      });
  }

  /**
   * Reloads the subtree contents of a folder
   */
  refreshFolder(node: TreeNode) {
    this._loadFolder$.next([node.fileDtoId, node]);
  }

  private getParentNode(node: TreeNode) {
    if (node.parentId) {
      //folder is not root so find parent and refresh
      //note: unfortunately getDescendants does not return a true reference to the node that the display is using so changes to isLoaded and childrenCount are not updated in the template
      return this.treeControl.getDescendants(this.dataSource.data[0]).find((n) => n.fileDtoId == node.parentId);
    } else {
      //folder is a root folder so just refresh root
      return this.dataSource.data[0];
    }
  }

  /**
   * Refresh the list of contents for the parent of the provided node
   * @param node child node
   */
  private refreshParentFolder(node: TreeNode) {
    this.refreshFolder(this.getParentNode(node));
  }

  /**
   * Removes a folder (if empty)
   */
  deleteFolder(node: TreeNode) {
    if (!this.patientId) return;

    if (node.childrenCount == 0) {
      this._patientClient.patient_DeleteFolder(this.patientId, node.fileDtoId)
        .subscribe((_) => {
          this.refreshParentFolder(node);
          this._snackbar.open('Folder successfully deleted', 'OK', { duration: 2500 });
        });
    }
  }

  /**
   * Opens a dialog to upload a file. Refreshes the folder upon successful upload.
   */
  openFileUploadDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data: FileUploadDialogData = {
      folderId: node.fileDtoId,
      contentType: StorageContentTypeEnum.Other,
      patientId: this.patientId,
    };

    this._dialog
      .open<FileUploadDialogComponent, FileUploadDialogData, any>(FileUploadDialogComponent, { data, maxWidth: 800 })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this._snackbar.open('File successfully uploaded', 'OK', { duration: 2000 });
          this.treeControl.expand(node);
          this.refreshFolder(node);
        }
      });
  }

  openTwainUploadDialog(node: TreeNode) {
    if (!this.patientId) return;

    let data: TwainUploadDialogData = {
      folderId: node.fileDtoId,
      contentType: StorageContentTypeEnum.Other,
      patientId: this.patientId,
    };

    this._dialog
      .open<TwainUploadDialogComponent, TwainUploadDialogData, any>(TwainUploadDialogComponent, { data, width: '500px' })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this._snackbar.open('Twain successfully uploaded', 'OK', { duration: 2000 });
          this.treeControl.expand(node);
          this.refreshFolder(node);
        }
      });
  }

  /**
   * Checks if a file is allowed to be deleted
   * Currently limited to only file type items with storage type of Other
   */
  canDelete(node: TreeNode) {
    return node.type == StorageTypeEnum.File && node.contentType == StorageContentTypeEnum.Other;
  }

  /**
   * Checks if a file is allowed to be renamed
   * Currently limited to only file type items with storage type of Other
   */
  canRename(node: TreeNode) {
    return node.type == StorageTypeEnum.File && node.contentType == StorageContentTypeEnum.Other;
  }

  /**
   * Deletes a file and refreshes containing folder
   */
  deleteFile(node: TreeNode) {
    if (!this.patientId) return;

    node.isWorking = true;
    this._patientClient.patient_DeleteFile(this.patientId, node.storageItem.parentId, node.storageItem.id)
      .pipe(take(1))
      .subscribe((_) => {
        node.isWorking = false;
        this._snackbar.open('File successfully deleted', 'OK', { duration: 2000 });
        this.refreshParentFolder(this.getParentNode(node));
      });
  }

  downloadFile(node: TreeNode) {
    node.download$ = this._downloadService.download(
      node.storageItem.locationUrl,
      node.storageItem.name);
  }

  addMoveFile(storageItem: StorageItemBaseDto) {
    if (this.moveFiles.length == 0 && storageItem) {
      this.moveFiles.push(storageItem);
      this._snackbar.open('Please select tree to move file', 'OK', { duration: 2000 });
    }
  }

  moveFile(node: TreeNode) {
    if (!this.patientId) return;

    if (this.moveFiles.length > 0) {
      node.isWorking = true;
      let moveFile = this.moveFiles[0];
      this._patientClient.patient_MoveFile(
        this.patientId,
        moveFile.parentId,
        moveFile.id,
        node.fileDtoId,
        null,
        moveFile.eTag)
      .pipe(take(1))
      .subscribe((_) => {
        this.moveFiles = [];
        node.isWorking = false;
        this._snackbar.open('File successfully moved', 'OK', { duration: 2000 });
        this.refreshParentFolder(this.getParentNode(node));
      });
    }
  }

  cancelMoveFile(): void {
    this.moveFiles = [];
  }

  /**
   * Gets the file extension from file name
   */
  private getFileExtension(fileName: string) {
    return fileName.split('.').reverse()[0].toUpperCase();
  }

  openAddDefaultFolderDialog(node?: TreeNode) {
    if (!this.patientId) return;

    this.defaultFoldersWorking = true;
    this._patientClient.patient_PostDefaultFolders(this.patientId)
      .subscribe(resp => {
        this.defaultFoldersWorking = false;
        if(node){
          this.refreshFolder(node)
        } else {
          this.refreshFolder(this.dataSource.data[0])
        }
        
      },
      err => {
        console.log(err);
        this.defaultFoldersWorking = false;
      });
  }

  showFile(file: StorageItemBaseDto) {
    this._dialog.open(
      FileViewerComponent,
      {
        data: {
          thumbnailUrl: file.locationThumbnailUrl,
          url: file.locationUrl,
          contentType: file.locationContentType,
          isThumbnail: false
        },
        hasBackdrop: true,
        height: '100%',
        width: '100%',
        restoreFocus: false
      }
    );
  }

  showFileThumbnail(file: StorageItemBaseDto, elementRef: ElementRef): void {
    this.closeFileThumbnail();
    this.thumbnailRef = this._dialog.open(
      FileViewerComponent,
      {
        data: {
          thumbnailUrl: file.locationThumbnailUrl,
          url: file.locationUrl,
          contentType: file.locationContentType,
          isThumbnail: true,
          trigger: elementRef
        },
        hasBackdrop: false,
        restoreFocus: false
      }
    );
  }

  closeFileThumbnail(): void {
    if (this.thumbnailRef)
      this.thumbnailRef.close();
  }
}
