import { createAction, props } from "@ngrx/store";
import { StationaryStoreEntity } from './state';
import { IStationaryDto, StationaryDto } from "../../shared/services/api.service";

//LOAD PAGE
export const LoadRequest = createAction(
  '[Stationary] Load Request',
  props<{ id?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Stationary] Load Success',
  props<{ stationaries: StationaryStoreEntity | StationaryStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Stationary] Load Failure',
  props<{ error: any; }>()
);

export const DeleteSuccess = createAction(
  '[Stationary] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[Stationary] Delete Failure',
  props<{ error: string; }>()
);
