import { Action, createReducer, on } from "@ngrx/store";
import * as CephalometricTypeStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(CephalometricTypeStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(CephalometricTypeStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.cephalometricTypes, { ...s, isLoading: false, error: null })
  }),
  on(CephalometricTypeStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
