import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TextFieldComponent {
  @Input() model: any;
  @Input() errmsg: string = 'Input required.';
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() disabled: false;
  @Input() maxLength: number = 10000;
  @Input() placeholder: string = '';
  @Input() backgroundColor: string;
  @Input() minRows: number = 1;
  @Input() maxRows: number = 10;
  @Input() matTooltip: string;
  @Input() classList: string = 'native';
  @Output() valueChange = new EventEmitter();
  @Output() click = new EventEmitter();

  hasError: boolean = false;

  validate() {
    this.hasError = false;

    if (this.required) {
      this.hasError = this.model == null || this.model.trim().length <= 0;
      return !this.hasError;
    }

    return !this.hasError;
  }

  setErrorMessage(msg: string) {
    this.errmsg = msg;
    return this;
  }

  showErrorMessage() {
    this.hasError = true;
  }

  hideErrorMessage() {
    this.hasError = false;
  }

  onChange(newValue) {
    if (this.required) {
      this.hasError = newValue.trim().length <= 0;
    }

    this.valueChange.emit(this.model);
  }
  onClick(event) {
    let self = this;

    setTimeout(function () {
      self.click.emit(event);
    });
  }

  clear() {
    this.hideErrorMessage();
    this.model = null;
    this.valueChange.emit(this.model);
  }
}