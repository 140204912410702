import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CreatePatientDto, ICreatePatientDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientStoreEntity> = createEntityAdapter<PatientStoreEntity>({
  selectId: (patient) => patient.id,
  sortComparer: (a: PatientStoreEntity, b: PatientStoreEntity): number => a.id - b.id,
});

export interface State extends EntityState<PatientStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedPatientId?: number;
  selectedPatient: PatientStoreEntity;
  isLoading?: boolean;
  error?: any;
  history?: PatientSearchHistory[];
  txCardAlerts?: boolean
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientId: null,
  selectedPatient: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null,
  history: [],
  txCardAlerts: true
});

export class PatientStoreEntity extends CreatePatientDto {
  constructor(data?: ICreatePatientDto) {
    super(data);
  }
}

export interface IPatientSearchHistory{
  patientId?: number;
  locationId?: number;
  name?: string;
}

export class PatientSearchHistory implements IPatientSearchHistory {
  constructor(data?: IPatientSearchHistory) {
    if(data) {
      this.patientId = data.patientId || 0;
      this.locationId = data.locationId || 0;
      this.name = data.name || ''
    }
  }
  patientId: number;
  locationId: number;
  name: string;
}
