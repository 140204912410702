import { createAction, props } from "@ngrx/store";
import { PatientStatusGroupStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientStatusGroup] Load Request',
  props<{page?: number; pageSize?: number; }>(),
);

export const LoadSuccess = createAction(
  '[PatientStatusGroup] Load Success',
  props<{ patientStatusGroups: PatientStatusGroupStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientStatusGroup] Load Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientStatusGroup] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientStatusGroup] Select Success',
  props<{ patientStatusGroup: PatientStatusGroupStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientStatusGroup] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientStatusGroup] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientStatusGroup] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientStatusGroup] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[PatientStatusGroup] Reset Error'
);
