import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FORM_TYPE_FEATURE_KEY } from './featurekey';
import { featureAdapter, FormTypeStoreEntity, State } from './state';

export const selectFormTypesState: MemoizedSelector<object, State> = createFeatureSelector<State>(FORM_TYPE_FEATURE_KEY);

export const getSelectedFormTypeId = createSelector(selectFormTypesState, (state) => state.selectedFormTypeId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectFormTypesState);
export const selectFormTypeIds = selectIds;
export const selectAllFormTypes = selectAll;
export const selectAllFormTypesEntities = selectEntities;

export const getSelectedFormType = createSelector(
  selectFormTypesState,
  selectAllFormTypes,
  (state, allFormTypes: FormTypeStoreEntity[]) =>
    allFormTypes ? allFormTypes.find((rp) => rp.id === state.selectedFormTypeId) : null
);

export const selectFormTypeById = (id: number) =>
  createSelector(selectAllFormTypes, (allFormTypes: FormTypeStoreEntity[]) =>
    allFormTypes ? allFormTypes.find((rp) => rp.id === id) : null
  );

export const selectFormTypesError: MemoizedSelector<object, any> = createSelector(selectFormTypesState, (state) => state.error);

export const selectFormTypesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectFormTypesState, (state: State) => state.isLoading);
