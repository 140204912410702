import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PATIENT_DENTIST_VISIT_FEATURE_KEY } from './featurekey';
import { featureAdapter, PatientDentistVisitStoreEntity, State } from './state';

export const selectPatientDentistVisitsState: MemoizedSelector<object, State> = createFeatureSelector<State>(PATIENT_DENTIST_VISIT_FEATURE_KEY);

export const getSelectedPatientDentistVisitId = createSelector(selectPatientDentistVisitsState, (state) => state.selectedPatientDentistVisitId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPatientDentistVisitsState);
export const selectPatientDentistVisitIds = selectIds;
export const selectAllPatientDentistVisits = selectAll;
export const selectAllPatientDentistVisitEntities = selectEntities;

export const getSelectedPatientDentistVisit = createSelector(
  selectPatientDentistVisitsState,
  selectAllPatientDentistVisits,
  (state, allPatientDentistVisits: PatientDentistVisitStoreEntity[]) =>
    allPatientDentistVisits ? allPatientDentistVisits.find((rp) => rp.id === state.selectedPatientDentistVisitId) : null
);

export const selectPatientDentistVisitById = (id: number) =>
  createSelector(selectAllPatientDentistVisits, (allPatientDentistVisits: PatientDentistVisitStoreEntity[]) =>
    allPatientDentistVisits ? allPatientDentistVisits.find((rp) => rp.id === id) : null
  );

export const selectPatientDentistVisitsError: MemoizedSelector<object, any> = createSelector(selectPatientDentistVisitsState, (state) => state.error);

export const selectPatientDentistVisitsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPatientDentistVisitsState, (state: State) => state.isLoading);
