import { UpdateStr } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { JwtBody } from '@shared/user-permissions';
import { AuthenticateTokenModel, TenantKeyDto, TokenDto } from 'src/app/shared/services/api.service';

export const LoginInit = createAction('[Auth] Login Initialize');

//LOGIN
export const LoginRequest = createAction('[Auth] Login Request', props<{ authModel: AuthenticateTokenModel; }>());
export const LoginSuccess = createAction('[Auth] Login Success', props<{ userCredentials: TokenDto; }>());
export const LoginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

//LOGIN MULTI
export const LoginMultiRequest = createAction('[Auth] LoginMulti Request', props<{ authModel: AuthenticateTokenModel }>());
export const LoginMultiSuccess = createAction('[Auth] LoginMulti Success', props<{ userCredentials: TokenDto }>());
export const LoginMultiFailure = createAction('[Auth] LoginMulti Failure', props<{ error: any }>());

//LOGIN SWITCH
export const LoginSelect = createAction('[Auth] LoginSelect Request', props<{ id: string }>());
export const LoginSelectSuccess = createAction('[Auth] LoginSelect Sucess', props<{ token: TokenDto }>());

//TOKEN REFRESH
export const RefreshRequest = createAction('[Auth] Refresh Request', props<{ id: string; accessToken: string; refreshToken: string }>());
export const RefreshSuccess = createAction('[Auth] Refresh Success', props<{ id: string; userCredentials: Partial<TokenDto> }>());
export const RefreshFailure = createAction('[Auth] Refresh Failure', props<{ id: string; error: any }>());

//LOGOUT
export const LogoutRequest = createAction('[Auth] Logout Request', props<{ id?: string; returnUrl?: string; }>()); //optional username prop for multi-login in case its not the active user logging out
export const LogoutSuccess = createAction('[Auth] Logout Success', props<{ id: string; ids?: string[]; returnUrl?: string; }>());
export const LogoutFailure = createAction('[Auth] Logout Failure', props<{ error: any }>());

export const AddTokens = createAction('[Auth] Add Tokens', props<{ tokens: TokenDto[] }>());
export const UpdateToken = createAction('[Auth] Update Token', props<UpdateStr<TokenDto>>());

export const SetTenant = createAction('[Auth] Set Tenant', props<{ tenantName: string; tenantKey: string; isPatient?: boolean; saveSession?: boolean; }>());

export const ChangeTenantRequest = createAction('[Auth] Change Tenant Request', props<{ tenantName: string; tenantKey: string; isPatient?: boolean; saveSession?: boolean; }>());
export const ChangeTenantSuccess = createAction('[Auth] Change Tenant Success', props<{ id: string; userCredentials: TokenDto }>());
export const ChangeTenantFailure = createAction('[Auth] Change Tenant Failure', props<{ id: string; error: any }>());

export const SetParsedToken = createAction('[Auth] Set Permissions State', props<{ parsedToken: JwtBody }>());
