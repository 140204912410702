import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ContactClient } from 'src/app/shared/services/api.service';
import * as ContactStoreActions  from './actions';

@Injectable({ providedIn: 'root' })
export class ContactsEffectService {
  constructor(private actions$: Actions, private contactsClient: ContactClient) {}

  searchRequestEffect2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStoreActions.SearchRequest),
      switchMap((action) =>
        this.contactsClient.contact_GetContacts(action.search, action.pageSize, action.page, '').pipe(
          map((data) => ContactStoreActions.SearchSuccess({ contacts: data })),
          catchError((error) => of(ContactStoreActions.SearchFailure({ err: error })))
        )
      )
    ));

  loadRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(ContactStoreActions.LoadRequest),
        switchMap((action) =>
          this.contactsClient.contact_GetContact(action.contactId, '').pipe(
            map(data => ContactStoreActions.LoadSuccess({ contact: data })),
            catchError(error => of(ContactStoreActions.LoadFailure({ err: error }))))
          ),
    );
  });
}
