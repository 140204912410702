import { createAction, props } from "@ngrx/store";
import { ModelStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[Model] Load Request',
  props<{page?: number; pageSize?: number; patientId: number; }>(),
);

export const LoadSuccess = createAction(
  '[Model] Load Success',
  props<{ models: ModelStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Model] Load Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[Model] Add Request',
  props<{ model: ModelStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[Model] Add Success',
  props<{ model: ModelStoreEntity; }>()
);

export const AddFailure = createAction(
  '[Model] Add Failure',
  props<{ error: string; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Model] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Model] Select Success',
  props<{ model: ModelStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Model] Select Failure',
  props<{ error: any; }>()
);


//DESELECT
export const DeselectRequest = createAction(
  '[Model] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[Model] Deselect Success',
);

export const DeselectFailure = createAction(
  '[Model] Deselect Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[Model] Reset Error'
);
