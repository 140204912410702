import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter } from "./state";
import * as LocationsStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(
    LocationsStoreActions.LoadRequest,
    (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize }),
  ),
  on(LocationsStoreActions.LoadSuccess, (state, action) => {
    featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.locations, { ...state, isLoading: false, error: null })
  }),
  on(LocationsStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(LocationsStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(LocationsStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.location, { ...state, isLoading: false, error: null })
  )),
  on(LocationsStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(LocationsStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(LocationsStoreActions.AddSuccess, (state, action) => (
    featureAdapter.addOne(action.location, { ...state, isLoading: false, error: null })
  )),
  on(LocationsStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(LocationsStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(LocationsStoreActions.DeleteSuccess, (state, action) => (
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null })
  )),
  on(LocationsStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(LocationsStoreActions.SelectRequest, (state, action) => ({...state, isLoading: true, error: null, selectedLocationId: null})),
  on(LocationsStoreActions.SelectSuccess, (state, action) => ({...state, isLoading: false, error: null, selectedLocationId: action.location.id})),
  on(LocationsStoreActions.SelectFailure, (state, action) => ({...state, isLoading: false, error: action.error})),
  //UPDATE
  on(LocationsStoreActions.UpdateRequest, (state) => ({...state, isLoading: true, error: null })),
  on(LocationsStoreActions.UpdateSuccess, (state, action) => featureAdapter.upsertOne(action.location, {...state, isLoading: false, error: null })),
  on(LocationsStoreActions.UpdateFailure, (state, action) => ({...state, isLoading: false, error: action.error })),
  //UTILITY
  on(LocationsStoreActions.Unload, (state, action) => featureAdapter.removeAll({ ...state })),
  //UPDATE PROFILE
  on(LocationsStoreActions.UpdateProfileRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(LocationsStoreActions.UpdateProfileSuccess, (state, action) => (
    featureAdapter.upsertOne(action.location, { ...state, isLoading: false, error: null })
  )),
  on(LocationsStoreActions.UpdateProfileFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  //DELETE PROFILE
  on(LocationsStoreActions.DeleteProfileRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(LocationsStoreActions.DeleteProfileSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(LocationsStoreActions.DeleteProfileFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  }))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
