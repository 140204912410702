import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CommonApiService } from 'src/app/shared/services/new-appnt-service/common-api.service';
import { fromEvent } from 'rxjs';
import { switchMap,debounceTime, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-all-names',
  templateUrl: './search-all-names.component.html',
  styleUrls: ['./search-all-names.component.css']
})
export class SearchAllNamesComponent implements OnInit {
	
	@Input() selectedLocation: any;
	@Input() searchFor: any;
	@ViewChild('searchresult',{static:false}) searchresult: ElementRef;
	@ViewChild('errornotification',{static:false}) errornotification: ElementRef;
	@Output() selectedSearchElem = new EventEmitter();
	
	@ViewChild('SearchInput', {static:false}) SearchInput:ElementRef;
	
	patientAllData: any;
	
	patient_data: any = [];
	searchResult: any[] = [];
	searchCriteria: any = '';
	searchContHt:any = 0;
	showSearchOptions: boolean = false;
	searchtype: any;

	showlastList: boolean = false;
	searchinput: any;
	hover: any = -1;
	respHover: any = -1;
	selectedPatient: any;
	allSearch: boolean = false;

	lastlistArr: any[] =[];
	last10listArr:any[]=[];
	lastlistArrlength: any= 0;
	
	searchPatient: any = '';
	
	filteredOptions: Observable<any>;
	
  constructor(private apiService:CommonApiService) { }

  ngOnInit() {}
  
  ngAfterViewInit() {
	  setTimeout(() => {
		  this.SearchInput.nativeElement.focus();
		  this.callSearchAPI();
	  }, 500)
  }
  
  getPatientList(){
	  
		let apiurl = `/locations/${this.selectedLocation.id}/patients?pageSize=100`;
		
		this.apiService.getApiData(apiurl).then(res =>{
			
			this.patientAllData = res;
			
			this.patientAllData.map(item => {
				
				let relation: any [] = [];
				
				console.log("search: ", item.id, item.firstName)
				
				let dob: any = '';
				let emailAddress = '';
				
				if(item.dob){
					dob = item.dob.split('T')[0]
				}
				
				if(item.primaryEmail && item.primaryEmail.emailAddress){
					emailAddress = item.primaryEmail.emailAddress
				}
				
				let patient = {
					'id': item.id,
					'locationId': item.locationId,
					'firstname': item.firstName,
					'lastname': item.lastName,
					'dob': dob,
					'is_patient': true,
					'address': '',
					'phone': '',
					'email': emailAddress,
					'relation': relation
				}
				
				this.patient_data.push(patient)
			})
			
		})
	  
  }
  
  
  callSearchAPI(){
	  
	fromEvent(this.SearchInput.nativeElement,"keyup").pipe(
		debounceTime(500),
		map((userInput : any) =>userInput.target.value )).subscribe(res =>{
					
			let searchkeyword: any = res;
			let apiurl:any="";
			
			if(this.searchFor == 'contacts'){
				apiurl = `/contacts?search=${searchkeyword}`;
			} else {
				apiurl = `/patients?search=${searchkeyword}`;
			}
			
			if(searchkeyword.length > 1) {
				this.apiService.getApiData(apiurl).then(res =>{
				
				this.patientAllData = res;
				this.patient_data = [];
				
				this.patientAllData.map(item => {
					
					let relation: any [] = [];
					
					let dob: any = '';
					let emailAddress = '';
					let phone: any = '';
					let address: any = '';
					let city: any = "";
					let state: any = "";
					let zip: any = "";
					
					if(item.dob){
						dob = item.dob.split('T')[0]
					}
					
					if(item.primaryEmailAddress){
						emailAddress = item.primaryEmailAddress
					}
					
					if(item.primaryPhoneNumber){
						phone = item.primaryPhoneNumber
					}
					
					if(item.primaryAddressLine1){
						address = item.primaryAddressLine1;
					}
					
					if(item.primaryAddressCity){
						if(item.primaryAddressLine1){
							city = ", " + item.primaryAddressCity;
						} else {
							city = item.primaryAddressCity;
						}
					}
					
					if(item.primaryAddressState){
						if(item.primaryAddressLine1 || item.primaryAddressCity){
							state = ", " + item.primaryAddressState;
						} else {
							state = item.primaryAddressState;
						}
					}
					
					if(item.primaryAddressZip){
						zip = item.primaryAddressZip;
					}
					
					let addr: any = `${address} ${city} ${state} ${zip}`
					let locId: any = '';
					
					if(item.locationId){
						locId = item.locationId;
					}
					
					let patient = {
						'id': item.id,
						'locationId': locId,
						'firstname': item.firstName,
						'lastname': item.lastName,
						'dob': dob,
						'is_patient': true,
						'address': addr,
						'phone': phone,
						'email': emailAddress,
						'relation': relation,
						'fullName': `${item.firstName} ${item.lastName}` 
					}
					
					this.patient_data.push(patient)
					
					//this.filteredOptions.push(patient);
				})
				this.createSearchList(searchkeyword)
			})
			} else {
				this.searchResult = [];
			}
			
			
			
		});
		
	fromEvent(this.SearchInput.nativeElement,"blur").pipe(
		debounceTime(500),
		map((userInput : any) =>userInput.target.value )).subscribe(res =>{
			this.showSearchOptions = false;
		})
  }
  
  getSearchedElem(elem){
	  let optionIndx: any = this.searchResult.findIndex(srch => srch.fullName === elem.option.value);
	  let searched_elem: any = this.searchResult[optionIndx];
	  this.selectedSearchElem.emit(searched_elem);
  }
  
  
  select_patient(elem){
	  this.selectedSearchElem.emit(elem);
	  this.showSearchOptions = false;
	  
	  this.SearchInput.nativeElement.value = `${elem.firstname} ${elem.lastname}`;
  }
  
  createSearchList(_searchstr){
	
	//console.log('searchstr: ', this.patient_data)

	this.showlastList = false;
	let searchstr = _searchstr.toLowerCase();
	
	let hiddenSrch:boolean = true;
	

	let formatedSchStr: any;

	if(hiddenSrch == true){
		formatedSchStr = searchstr;
		this.searchinput = _searchstr;
		this.allSearch = true;
	} else {
		formatedSchStr = searchstr;
		this.searchtype = '';
		this.searchinput = _searchstr;
		this.allSearch = false;
	}

	this.searchCriteria = formatedSchStr;

	let searchStrSplit = formatedSchStr.split(' ');
	let filterSearchArr = searchStrSplit.filter(item => {return item});

	this.searchResult = [];

	if(formatedSchStr.length >= 2){

		if(filterSearchArr.length==1){
			let not_a_Number: any = isNaN(filterSearchArr[0]);

			if(hiddenSrch == true){
				if(not_a_Number == true){
					this.searchtype = 'name';
				} else {
					this.searchtype = 'phone';
				}
			}

			if(hiddenSrch == true){
				this.searchResult = this.patient_data.filter(item => {
					return filterSearchArr.some(srch => {
						return (item.firstname.toLowerCase().includes(srch) || item.lastname.toLowerCase().includes(srch) || item.phone.includes(srch) || item.address.toLowerCase().includes(srch) || item.email.toLowerCase().includes(srch))
					})
				});
			} else {
				this.searchResult = this.patient_data.filter(item => {
					return filterSearchArr.some(srch => {
						return (item.firstname.toLowerCase().includes(srch) || item.lastname.toLowerCase().includes(srch) || item.phone.includes(srch) || item.address.toLowerCase().includes(srch) || item.email.toLowerCase().includes(srch)) && item.is_patient
					})
				});
			}

		} else {

			let _search_name: boolean = false;
			let _search_phone: boolean = false;

			for(let i=0; i<filterSearchArr.length; i++){
				let not_a_Number_multi: any = isNaN(filterSearchArr[i]);
				if(hiddenSrch == true){
					if(not_a_Number_multi == true){
						_search_name = true;
					} else {
						_search_phone = true;
					}
				}
			}

			if(hiddenSrch == true){

				if(_search_name == true && _search_phone == false){
					this.searchtype = 'name';

					let nameArr: any = {fname: filterSearchArr[0], lname: filterSearchArr[1]};
					this.searchResult = this.patient_data.filter(item => {
						return (item.firstname.toLowerCase().includes(nameArr.fname) && item.lastname.toLowerCase().includes(nameArr.lname));
					});


				} else if(_search_name == false && _search_phone == true){
					this.searchtype = 'phone';

					this.searchResult = this.patient_data.filter(item => {
						return filterSearchArr.some(srch => {
							return (item.phone.includes(srch));
						})
					});

				} else if(_search_name == true && _search_phone == true){
					this.searchtype = 'address';
					let addrsStr: any = filterSearchArr.join(" ");
					this.searchResult = this.patient_data.filter(item => {
						return (item.address.toLowerCase().includes(addrsStr))
					});

				} else {
				}

			} else {

				let nameArr: any = {fname: filterSearchArr[0], lname: filterSearchArr[1]};

				this.searchResult = this.patient_data.filter(item => {
					return item.firstname.toLowerCase().includes(nameArr.fname) && item.lastname.toLowerCase().includes(nameArr.lname) && item.is_patient;
				});
			}

		}

		this.showSearchOptions = true;

		if(this.searchResult.length > 0){
			if(this.searchresult){
				setTimeout(() => {
					this.searchContHt = this.searchresult.nativeElement.offsetHeight + 40;
				});
			}
		} else {
			if(this.errornotification){
				setTimeout(() => {
					this.searchContHt = this.errornotification.nativeElement.offsetHeight + 40;
				});
			}
		}
	} else {
		this.searchtype = '';
		this.showSearchOptions = false;
	}
  }

}
