import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { HttpMethod } from '../enums';

@Injectable()
export class ETagInterceptorService implements HttpInterceptor {
  private EtagDictionary: { [resourceLocation: string]: string } = {};
  private readonly etagProp = 'eTag';

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //check Etag dictionary

    return this.processOutgoing(req).pipe(
      mergeMap((newRequest) => next.handle(newRequest)), //process request
      tap((event) => this.processIncoming(event)) //check incoming event for ETags to store
    );
  }

  /**
   * Take outgoing request and looking for ETag in dictionary or body and add If-Match header on PUT
   * @param request Outgoing HTTP request
   */
  private processOutgoing(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return (request.body instanceof Blob ? blobToText(request.body) : of(request.body)).pipe(
      map((requestBody) => (typeof requestBody === 'object' ? requestBody : JSON.parse(requestBody))),
      map((data) => {
        let etag: string;
        //search in dictiony for etag, if not try and pull from request data
        if (this.EtagDictionary[request.url]) {
          etag = this.EtagDictionary[request.url];
          if (data && this.etagProp in data) data[this.etagProp] = etag;
        } else {
          etag = data && this.etagProp in data && data[this.etagProp];
        }

        let newRequest = request.clone();
        const headers = request.headers;
        //only on update, if we have cached etag and If-Match header is not already set
        const ifMatchSet: boolean = !!headers && headers.has('If-Match') && headers.get('If-Match') != null && headers.get('If-Match') != '';
        if (request.method == HttpMethod.Put && etag && !ifMatchSet) {
          //check if we have a cached etag of this resource
          newRequest = request.clone({ headers: headers.set('If-Match', etag) });
        }
        return newRequest;
      })
    );
  }

  /**
   * On incoming event, process the body and headers storing any ETags found with header taking priority before body
   * @param event Incoming event from HTTP request
   */
  private processIncoming(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse) {
      //check for Etag and store
      if (event.headers.has('etag')) this.EtagDictionary[event.url] = event.headers.get('etag');

      if (event.body instanceof Blob) {
        //NSwag client makes all requests with Blob type instead of auto typing
        blobToText(event.body) //convert from blob to text
          .pipe(
            take(1),
            map((textBody) => IsJsonString(textBody) ? textBody && JSON.parse(textBody) : null) //parse into json object
          )
          .subscribe((body) => {
            if (body && typeof body === 'object' && this.etagProp in body) {
              //secondary check for eTag in content entity
              this.EtagDictionary[event.url] = body[this.etagProp];
            }
          });
      }
    }
  }
}

/** Converts a blob object into its string value */
// Borrowed from NSwag client `api.service.ts`
function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
