import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss'],
})
export class ColorSelectorComponent {
  @Input() colorList: any[];
  @Input() label: string = 'choose color';
  @Input() model: string;
  @Input() disabled = false;
  @Output() selectionChange = new EventEmitter();

  onSelectionChange(colorCode: string) {
    if (!this.disabled) {
      this.model = colorCode;
      this.selectionChange.emit(this.model);
    }
  }
}