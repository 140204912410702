import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyDatesComponent } from './key-dates.component';
import { CardModule } from 'src/app/shared/models';
import { DEFAULT_CARD_HEIGHT } from '../cards.module';
import { MatIconModule, MatButtonModule, MatTooltipModule, MatExpansionModule, MatInputModule, MatDatepickerModule, MatProgressBarModule, MatSelectModule, MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EvolutionColors } from 'src/app/shared/enums';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { UserPermissionsModule } from '@shared/user-permissions/user-permissions.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@CardModule({
  selector: 'key-dates',
  front: KeyDatesComponent,
  back: null,
  header: 'Key Dates',
  width: 460,
  height: DEFAULT_CARD_HEIGHT,
  actions: [{iconName: 'faCalendarDay', actionName: '', isFaIcon: true}],
  order: 90,
  color: EvolutionColors.Blue,
})
@NgModule({
  declarations: [KeyDatesComponent],
  entryComponents: [KeyDatesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MomentDateModule,
    UserPermissionsModule,
    MatDialogModule,
    NgxMaterialTimepickerModule
  ]
})
export class KeyDatesModule { }
