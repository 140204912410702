import { createAction, props } from '@ngrx/store';
import { InsuranceCompanyStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction('[InsuranceCompany] Load Request', props<{ page?: number; pageSize?: number }>());

export const LoadSuccess = createAction('[InsuranceCompany] Load Success', props<{ insuranceCompanies: InsuranceCompanyStoreEntity[] }>());

export const LoadFailure = createAction('[InsuranceCompany] Load Failure', props<{ error: string }>());

//LOAD ONE
export const LoadOneRequest = createAction('[InsuranceCompany] Load One Request', props<{ id: number }>());

export const LoadOneSuccess = createAction('[InsuranceCompany] Load One Success', props<{ insuranceCompany: InsuranceCompanyStoreEntity }>());

export const LoadOneFailure = createAction('[InsuranceCompany] Load One Failure', props<{ error: string }>());

//ADD
export const AddRequest = createAction('[InsuranceCompany] Add Request', props<{ insuranceCompany: InsuranceCompanyStoreEntity }>());

export const AddSuccess = createAction('[InsuranceCompany] Add Success', props<{ insuranceCompany: InsuranceCompanyStoreEntity }>());

export const AddFailure = createAction('[InsuranceCompany] Delete Failure', props<{ error: string }>());

//DELETE
export const DeleteRequest = createAction('[InsuranceCompany] Delete Request', props<{ id: number }>());

export const DeleteSuccess = createAction('[InsuranceCompany] Delete Success', props<{ id: number }>());

export const DeleteFailure = createAction('[InsuranceCompany] Delete Failure', props<{ error: string }>());
