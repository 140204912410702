import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TX_CARD_TYPE_FEATURE_KEY } from './featurekey';
import { featureAdapter, TxCardTypeStoreEntity, State } from './state';

export const selectTxCardTypeState: MemoizedSelector<object, State> = createFeatureSelector<State>(TX_CARD_TYPE_FEATURE_KEY);

export const getSelectedId = createSelector(selectTxCardTypeState, (state) => state.selectedId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectTxCardTypeState);
export const selectTxCardTypeIds = selectIds;
export const selectAllTxCardTypes = selectAll;
export const selectAllTxCardTypeEntities = selectEntities;

export const getSelectedTxCardType = createSelector(
  selectTxCardTypeState,
  selectAllTxCardTypes,
  (state, selectTxCardTypes: TxCardTypeStoreEntity[]) =>
    selectTxCardTypes ? selectTxCardTypes.find((rp) => rp.id === state.selectedId) : null
);

export const selectTxCardTypeById = (id: number) =>
  createSelector(selectAllTxCardTypes, (allTxCardTypes: TxCardTypeStoreEntity[]) =>
    allTxCardTypes ? allTxCardTypes.find((rp) => rp.id === id) : null
  );

export const selectError: MemoizedSelector<object, any> = createSelector(selectTxCardTypeState, (state) => state.error);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(selectTxCardTypeState, (state: State) => state.isLoading);
