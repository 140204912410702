import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { ITaskDto, TaskDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<UserTaskStoreEntity> = createEntityAdapter<UserTaskStoreEntity>({
  selectId: user => user.id
});

export interface State extends EntityState<UserTaskStoreEntity> {
  selectedUserTaskId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedUserTaskId: null,
  isLoading: false,
  error: null
});

export class UserTaskStoreEntity extends TaskDto {
  constructor(data?: ITaskDto) {
    super(data);
  }
}
