import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import {
  MatAutocompleteTrigger,
  MatDialog,
  MatDialogRef,
  MatSelect,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, shareReplay, switchMapTo } from 'rxjs/operators';
import { MessageService, MessageServiceEventEnum } from 'src/app/core/message.service';
import { PatientsStoreSelectors, RootStoreState, AuthStoreSelectors, PatientsStoreActions, TxCardTypeStoreSelectors } from 'src/app/root-store';
import { ProcedureGroupDTO } from 'src/app/shared/services/settings/settings.service';
import { TreatmentPlanService } from 'src/app/shared/services/settings/treatment-plan.service';
import { LocationsStoreSelectors, InternalUserStoreSelectors, InternalUserStoreEntity } from '../../root-store';
import {
  AppointmentHistoryDto,
  AppointmentTxCardDto,
  Custom1Dto,
  Custom2Dto,
  ElasticDto,
  LocationClient,
  PatientClient,
  TxCardDto,
  TxCardOptionsDto,
  WireDto,
  UserTypeFilterEnum,
  UserTypeEnum
} from '../../shared/services/api.service';
import { NotesModalComponent } from '../notes-modal/notes-modal.component';
import { TreatmentCardMultipleAddModal } from '../treatment-cards-appointment-modal/treatment-cards-appointment-modal';

import { Subscription } from 'rxjs';
import { PERMISSIONS } from '@shared/constants/permissions';
import { UserPermissionsService } from '@shared/user-permissions/user-permissions.service';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MessageDialog } from 'src/app/dialogs/message/message.dialog';
import * as moment from 'moment-timezone';
import { ACTIVE_PATIENT_LOCATION } from '../user-permissions/jwt.model';
import * as _ from 'lodash';
import html2pdf from 'html2pdf.js';
import {faGrinBeam, faGrin, faMeh, faFrown, faCalendarPlus, faSave, faUndo, faPencilAlt, faTrash, faLock, faCalendar} from '@fortawesome/free-solid-svg-icons';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-treatment-cards',
  templateUrl: './treatment-cards.component.html',
  styleUrls: ['./treatment-cards.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentCardsComponent implements OnInit, OnDestroy {
  // View Children
  @ViewChild('oralSelect', { static: false }) oralSelect: MatSelect;
  @ViewChild('uwTrigger', { static: false }) uwTrigger: MatAutocompleteTrigger;
  @ViewChild('uwInput', { static: false }) uwInput: ElementRef<HTMLInputElement>;
  @ViewChild('lwTrigger', { static: false }) lwTrigger: MatAutocompleteTrigger;
  @ViewChild('lwInput', { static: false }) lwInput: ElementRef<HTMLInputElement>;
  @ViewChild('elasticTrigger', { static: false }) elasticTrigger: MatAutocompleteTrigger;
  @ViewChild('elasticInput', { static: false }) elasticInput: ElementRef<HTMLInputElement>;
  @ViewChild('applianceTrigger', { static: false }) applianceTrigger: MatAutocompleteTrigger;
  @ViewChild('applianceInput', { static: false }) applianceInput: ElementRef<HTMLInputElement>;
  @ViewChild('custom1Trigger', { static: false }) custom1Trigger: MatAutocompleteTrigger;
  @ViewChild('custom1Input', { static: false }) custom1Input: ElementRef<HTMLInputElement>;
  @ViewChild('custom2Trigger', { static: false }) custom2Trigger: MatAutocompleteTrigger;
  @ViewChild('custom2Input', { static: false }) custom2Input: ElementRef<HTMLInputElement>;
  @ViewChild('staffSelect', { static: false }) staffSelect: MatSelect;
  @ViewChild('appointmentDialog', { static: false }) appointmentDialog: TemplateRef<any>;
  @ViewChild('TreatmentNotesDialog', { static: true }) TreatmentNotesDialog: TemplateRef<any>;
  @ViewChild('undoConfirmationTemplate', { static: false }) undoConfirmationTemplate: TemplateRef<any>;
  @ViewChild('feenotedialog', { static: false }) feenotedialog: TemplateRef<any>;
  @ViewChild('txcarddaterange', { static: false }) txcarddaterange: TemplateRef<any>;

  newCard: TxCardDtoVM;
  editIndex: number;
  cards: TxCardDtoVM[] = [];
  options: TxCardOptionsDto;
  refreshOptions$ = new BehaviorSubject<TxCardOptionsDto>(undefined);
  isLoading: boolean;
  options$: Observable<TxCardOptionsDto> = this.refreshOptions$.pipe(
    switchMapTo(this._locationClient.location_GetOptions('0', '0').pipe(take(1))),
    shareReplay(1)
  )

  dataSource: MatTableDataSource<TxCardDtoVM>;
  columns: string[] = [
    'postDatetime',
    'oralHygiene',
    'upperWire',
    'lowerWire',
    'elastics',
    'appliance',
    'custom1',
    'custom2',
    'staff',
    'dr',
    'todayNotes',
    'nextNotes',
    'procedure',
    'wks',
    'actions',
  ]; // proc, u, wks

  public ev: Event;
  public oralHygieneSelection: string;
  pastAppointments: AppointmentHistoryDto[];
  appointmentDialogRef: MatDialogRef<any>;
  currentAppointmentList: any[];

  selectedPatient$ = this._rootStore$.select(PatientsStoreSelectors.getSelectedPatient);
  selectedPatientId$ = this._rootStore$.select(PatientsStoreSelectors.getSelectedPatientId);
  selectedLocationId$ = this._rootStore$.select(LocationsStoreSelectors.getSelectedLocationId);
  selectedLocation$ = this._rootStore$.select(LocationsStoreSelectors.getSelectedLocation);
  user_Info$: Observable<any> = this._rootStore$.select(AuthStoreSelectors.selectCredentials).pipe(user => user);
  destroy$: Subject<boolean> = new Subject<boolean>();
  procedureGroupList: ProcedureGroupDTO[];

  // filtered options for auto-complete
  filteredUpperWires: WireDto[];
  filteredLowerWires: WireDto[];
  filteredElastics: ElasticDto[];
  filteredAppliances: string[];
  filteredCustom1: Custom1Dto[];
  filteredCustom2: Custom2Dto[];

  staffUsers: InternalUserStoreEntity[]=[];
  filteredStaff: InternalUserStoreEntity[]=[];
  newCardStaffName:any;

  private userPermissionSubscription: Subscription;
  permissionDialog: DialogComponent;

  userName: any = "";
  staffUser: any;
  changeUserInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() txPDFCompleted = new EventEmitter();
  @ViewChild('txpdfcontent', {static: false}) txpdfcontent: ElementRef;
  genTxPdf: boolean = false;
  isDependencyLoaded: boolean = false;

  users$: Observable<InternalUserStoreEntity[]> = this._rootStore$.select(InternalUserStoreSelectors.selectAllInternalUsers)
  UserTypeFilterEnum = UserTypeFilterEnum;

  faCalendarPlus = faCalendarPlus;
  faSave = faSave;
  faUndo = faUndo;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faLock = faLock;
  faCalendar = faCalendar;
  faTriangle = faTriangle;

  numberMask = createNumberMask({ prefix:'', thousandsSeparatorSymbol: ',', allowDecimal: true, decimalSymbol: '.',  allowLeadingZeroes: true });
  procedures = [];

  allTxRow:boolean = true;
  dateRange:boolean = false;
  searchform: FormGroup;

  minStartDate: any;
  maxStartDate: any;
  minEndDate: any;
  maxEndDate: any;
  submitted:boolean = false;
  pdfCards: TxCardDtoVM[] = [];

  txCardTypes$ = this._rootStore$.select(TxCardTypeStoreSelectors.selectAllTxCardTypes);

  constructor(
    private _locationClient: LocationClient,
    private _cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _treatmentSvc: TreatmentPlanService,
    private _rootStore$: Store<RootStoreState.State>,
    private messageService: MessageService,
    private _patientClient: PatientClient,
    private _userPermissionsService: UserPermissionsService,
    private formBuilder: FormBuilder,
  ) {
    this.permissionDialog = new DialogComponent(this.dialog);
  }

  ngOnInit() {

    this.loadOptions();

    this._treatmentSvc
      .get(`${this._treatmentSvc._txCardsEndpoint}/oralHygienes`)
      .pipe(map((resp) => resp['body']))
      .subscribe((res: any) => {
        this.oralHygieneSelection = res['selection'];
      });

    this.loadCards();

    this.user_Info$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if(user){
          this.userName = user.nickName || user.firstName;
          this.staffUser = user;
        }
    })

    this.changeUserInfo.pipe(takeUntil(this.destroy$)).subscribe(changeInfo => {
      this.messageService.sendMessage('dactivewarning', changeInfo);
    })

    this.messageService.getMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe(eve => {
        if (!eve) return;
        if (!this.editIndex
          && this.editIndex !== 0
          && eve.event == MessageServiceEventEnum.addedToChair
          && eve.data
          && eve.data.patientId) {

          this.selectedLocationId$
            .pipe(
              take(1),
              takeUntil(this.destroy$)
            )
            .subscribe(locationId => {
              this._rootStore$.dispatch(PatientsStoreActions.SelectRequest({ id: eve.data.patientId, locationId: locationId }));
            })
        }
    });

    this.users$.pipe(filter(users => !!users)).subscribe(users => {
      this.staffUsers = users
        .sort((a, b) => {
          if (a.shortName) {
            return a.shortName.localeCompare(b.shortName);
          } else if (a.firstName) {
            return a.firstName.localeCompare(b.firstName);
          } else if (a.lastName) {
            return a.lastName.localeCompare(b.lastName);
          }
        })
        .filter(user => user.isActive && (user.userType == UserTypeEnum.Internal));
      this.filteredStaff = users.filter(user => user.isActive);
    })

    this.intializeSearchForm();
  }

  loadOptions(){
    this.options$.pipe(take(1)).subscribe(x => {
      if (x) {
        this.options = x;

        this.procedureGroupList = this.options.procedureGroups.map((group) => new ProcedureGroupDTO(group));
        this.procedureGroupList = _.orderBy(this.procedureGroupList, 'name', 'asc');
        this.procedureGroupList.map(procedureGroup => {
          procedureGroup.procedures = _.orderBy(procedureGroup.procedures, 'sortOrder', 'asc');
        });

        this.procedures = _.chain(this.procedureGroupList).map('procedures').flatten().value();

        this.options.nextPeriods = _.orderBy(this.options.nextPeriods, 'sortOrder', 'asc');

        // default filtered values
        this.filteredUpperWires = this.options.upperWires;
        this.filteredLowerWires = this.options.lowerWires;
        this.filteredElastics = this.options.elastics;
        this.filteredAppliances = this.options.appliances;
        this.filteredCustom1 = this.options.custom1;
        this.filteredCustom2 = this.options.custom2;

        // remove columns if not enabled in settings
        if (this.options.txCard.hiddenColumns) {
          this.options.txCard.hiddenColumns.forEach((x) => (this.columns = this.columns.filter((y) => y != x)));
        }

        this.cards.map(txcardRow => {
          txcardRow.isCollapsed = this.options.txCard.defaultClosed;
        })

      }
      this.isDependencyLoaded = true;
      this._cdr.detectChanges();
    })
  }

  openAutoComplete(trigger: MatAutocompleteTrigger, input: ElementRef<HTMLInputElement>) {
    setTimeout((x) => {
      if (trigger && input) {
        trigger.openPanel();
        input.nativeElement.focus();
      }
    }, 100);
  }

  focusAutoComplete() {
    console.log('focusAutoComplete');
  }

  onChangeOption(val: string, options: any, property?: string) {
    if (!val) return;
    val = val.toLowerCase();
    return options.filter((option) => (property ? option[property].toLowerCase().includes(val) : option.toLowerCase().includes(val)));
  }

  getProviderDisplay() {
    if (!this.newCard.providerId) return;
    return this.options.providers.find((x) => x.id == this.newCard.providerId).firstName;
  }

  private loadAppointments() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.selectedPatientId$
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        filter((id) => !!id),
        distinctUntilChanged(),
        switchMap((patientId) => this._patientClient.patient_GetPatientAppointments(patientId, true, 100)),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        this.pastAppointments = res.filter((data) => data.forTxCards);
        if (this.pastAppointments.length > 0) {
          this.appointmentDialogRef = this.dialog.open(this.appointmentDialog);
        } else {
          this.addCardRow();
        }
        this.isLoading = false;
      });
  }

  public createMultipleAppointments(event: Event) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(TreatmentCardMultipleAddModal, {
      data: {
        options: this.options,
        procedureGroupList: this.procedureGroupList,
        appointments: this.newCard.appointmentTxCards,
      },
      width: '550px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((x: AppointmentTxCardDto[]) => {
      if (x) {
        this.newCard.appointmentTxCards = x;
        this._cdr.detectChanges();
        this.changeUserInfo.next(true);
      }
    });
  }

  loadCards() {
    this.newCard = null;

    this.selectedPatient$
      .pipe(
        filter((patient) => {
          if (!patient) {
            this.editIndex = null;
            this.newCard = null;
            this.changeUserInfo.next(false);
            this._cdr.detectChanges();
          }
          return !!patient;
        }),
        switchMap((patient) => this._locationClient.location_GetTxCards(patient.locationId, patient.id, null)),
        takeUntil(this.destroy$)
      )
      .subscribe((x) => {
        this.editIndex = null;
        this.cards = [];
        this.pdfCards = [];

        x.map(item => {
          this.cards.push(new TxCardDtoVM(item))
        })

        if (this.cards && this.cards.length > 0) {
          this.minStartDate = moment(this.cards[this.cards.length - 1].postDatetime).tz(this.cards[this.cards.length - 1].locationTimeZone).toDate();
          this.maxEndDate = moment(this.cards[0].postDatetime).tz(this.cards[0].locationTimeZone).toDate();
          this.maxStartDate = this.maxEndDate;
          this.minEndDate = this.minStartDate;

          this.searchform.patchValue({
            startdate: moment(this.cards[this.cards.length - 1].postDatetime).tz(this.cards[this.cards.length - 1].locationTimeZone).toDate(),
            enddate: moment(this.cards[0].postDatetime).tz(this.cards[0].locationTimeZone).toDate(),
          })
        }

        this.pdfCards = this.cards;
        this._cdr.detectChanges();

        if (this.newCard) {
          this.cards.unshift(this.newCard);
          this.editIndex = 0;
        }

        this.setDataSource();
        this._cdr.detectChanges();
        this.loadOptions();
      });
  }

  get rowAppointmentEditDisplay(): AppointmentTxCardDto {
    if (!this.newCard.appointmentTxCards || this.newCard.appointmentTxCards.length == 0) {
      this.newCard.appointmentTxCards = [new AppointmentTxCardDto()];
    }

    return this.newCard.appointmentTxCards[0];
  }

  addCard() {
    if (this.newCard) {
      return;
    }

    this.loadAppointments();
  }

  addCardRow(appointment = null) {
    this.newCard = new TxCardDtoVM();
    this.newCard.postDatetime = new Date();

    if (appointment) {
      this.newCard.locationTimeZone = appointment.locationTimeZone;
      this.newCard.locationId = appointment.locationId;
      this.newCard.staff = null;
      this.newCard.staffId = null;
      if (moment().diff(moment(appointment.startTime), 'days') > 0 && moment().diff(moment(appointment.startTime), 'days') <= 7) {
        this.newCard.postDatetime = appointment.startTime
      }
    }

    this.user_Info$.subscribe(user => {
      if (this.newCard)
        this.newCard.staffId = user.id;

        let staffUser = this.staffUsers.find(staff => staff.id == user.id);

        if(staffUser){
          this.newCardStaffName = staffUser.shortName ? staffUser.shortName : `${staffUser.firstName} ${staffUser.lastName}`;
        }

    })

    // add row to table, set datasource and editIndex
    this.cards.unshift(this.newCard);
    this.setDataSource();
    this.editIndex = 0;
    this._cdr.detectChanges();
    this.changeUserInfo.next(true);

    // open first select
    //this.openSelect(this.oralSelect);

  }

  appointmentSelected(appointment: AppointmentHistoryDto) {
    this.appointmentDialogRef.close();
    this.addCardRow(appointment);

    if (appointment) {
      this.newCard.appointmentId = appointment.id;
    }
  }

  editCard(index: number) {

    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }

    this.userPermissionSubscription = this._userPermissionsService.hasPermission(PERMISSIONS.Location.TreatmentCards.AddNewEntry, ACTIVE_PATIENT_LOCATION).subscribe(hasPermission => {
      if (hasPermission) {
        this.editIndex = index;

        // clone card for editing
        this.cards[index] = JSON.parse(JSON.stringify(this.cards[index]));
        this.setDataSource();
        this.newCard = this.cards[index];

        // open first select
        this.openSelect(this.oralSelect);
        this.changeUserInfo.next(true);

      } else {
        let data = {
          title: 'Permission Error',
          message: "Sorry, you don't have rights to edit entry. Please see the administrator."
        };

        this.permissionDialog.open(MessageDialog, data);
      }

    })
  }

  canEditNext(card: TxCardDto) {
    if (this.editIndex == 0) return true;
    return card.appointmentTxCards.every((x) => x.appointmentId == null);
  }

  async saveCard() {
    if (!(this.newCard.staffId || this.newCard.providerId)) {
      this._snackBar.open('Staff or Doctor entry is required', 'Close', {
        duration: 7000,
        panelClass: ['error-snackbar'],
      });
      return;
    }

    this.isLoading = true;

    this.selectedPatient$
      .pipe(
        take(1),
        switchMap((patient) => {
          delete this.newCard.staff;
          if (this.newCard.id) {
            localStorage.setItem('etag', this.newCard.eTag);
            return this._locationClient.location_PutTxCard(
              this.newCard.locationId ? this.newCard.locationId : patient.locationId,
              patient.id,
              this.newCard.id,
              this.newCard,
              null,
              null
            );
          } else {
            return this._locationClient.location_PostTxCard(
              this.newCard.locationId ? this.newCard.locationId : patient.locationId,
              patient.id,
              this.newCard,
              null
            );
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (x) => {
          this.newCard = null;
          this.loadCards();
          this._snackBar.open('Treatment Saved', 'Close', {
            duration: 3000,
          });
          this.editIndex = null;
          this.changeUserInfo.next(false);

          this.messageService.sendMessage('refreshScheduledChairs', null);
          this.isLoading = false;
        },
        (err) => {
          this._snackBar.open('Failed to save treatment', 'Close', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
          this.changeUserInfo.next(false);
          this.isLoading = false;
        }
      );
  }

  deleteCard(index: number) {
    const cardId = this.cards[index].id;
    this.isLoading = true;
    this.selectedPatient$
      .pipe(
        take(1),
        switchMap((patient) => this._locationClient.location_DeleteTxCard(patient.locationId, patient.id, cardId, null)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.isLoading = false;
        this.loadCards();
      },
      err => {
        this.isLoading = false;
      });
  }

  openSelect(select: MatSelect) {
    if (select) {
      select.open();
    }
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource(this.cards);
  }

  cancelAddEdit() {
    this.loadCards();
    this.changeUserInfo.next(false);
  }

  public getColorOrClass(clazzname: any, flag: string): any {
    switch (clazzname) {
      case 'Excellent':
        return flag === 'clr' ? '#0ed251' : faGrinBeam;
      case 'Good':
        return flag === 'clr' ? '#3f91cd' : faGrin;
      case 'Needs Improvement':
        return flag === 'clr' ? '#646464' : faMeh;
      case 'Poor':
        return flag === 'clr' ? '#ff8d4d' : faFrown;
      case 'Unacceptable':
        return flag === 'clr' ? '#fe0000' : faFrown;
    }
  }

  public enterNotes(type: string) {

    const notesObj = {
      noteValue: type === 'today' ? this.newCard.todayNotes : this.newCard.nextNotes,
      colorCode: type === 'today' ? this.newCard.todayColorCode : this.newCard.nextColorCode,
    };
    const dialogRef = this.dialog.open(NotesModalComponent, {
      height: '475px',
      width: '100vw',
      data: {
        notesArray: this.options.notes,
        noteType: type,
        notesObj: notesObj,
        lastcard: this.cards.length >= 2 ? this.cards[1] : null,
        newcard: this.cards.length > 0 ? this.cards[0] : null
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {

      console.log("result ", result);

      if (type === 'today' && result !== undefined && result !== null) {
        this.newCard.todayNotes = result['noteValue'] !== undefined ? result['noteValue'] : this.newCard.todayNotes;
        this.newCard.todayColorCode = result['colorCode'] !== undefined ? result['colorCode'] : this.newCard.todayColorCode;
      }
      if (type === 'next' && result !== undefined  && result !== null) {
        this.newCard.nextNotes = result['noteValue'] !== undefined ? result['noteValue'] : this.newCard.nextNotes;
        this.newCard.nextColorCode = result['colorCode'] !== undefined ? result['colorCode'] : this.newCard.nextColorCode;
      }

      this._cdr.detectChanges();
      this.changeUserInfo.next(true);
    });
  }

  public getNoteColor(clr: string, idx: number): string {
    if (clr) {
      return clr;
    } else {
      if (this.editIndex === idx) {
        return 'rgba(63, 145, 205, 0.5)';
      } else {
        return 'none';
      }
    }
  }

  public getIcon(val: string): string {
    switch (val) {
      case 'Excellent':
        return this.oralHygieneSelection === 'Number' ? '5' : 'A';
      case 'Good':
        return this.oralHygieneSelection === 'Number' ? '4' : 'B';
      case 'Needs Improvement':
        return this.oralHygieneSelection === 'Number' ? '3' : 'C';
      case 'Poor':
        return this.oralHygieneSelection === 'Number' ? '2' : 'D';
      case 'Unacceptable':
        return this.oralHygieneSelection === 'Number' ? '1' : 'E';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);

    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }
  }

  lowerWireChanged(element: TxCardDto): void {
    let option = _.find(this.options.lowerWires, { 'name': this.newCard.lowerWire });
    element.lowerWireColorCode = this.newCard.lowerWireColorCode = option && option.colorCode ?
      option.colorCode :
      null;
  }

  upperWireChanged(element: TxCardDto): void {
    let option = _.find(this.options.upperWires, { 'name': this.newCard.upperWire });
    element.upperWireColorCode = this.newCard.upperWireColorCode = option && option.colorCode ?
      option.colorCode :
      null;
  }

  elasticChanged(element: TxCardDto): void {
    let option = _.find(this.options.elastics, { 'name': this.newCard.elastic });
    element.elasticColorCode = this.newCard.elasticColorCode = option && option.colorCode ?
      option.colorCode :
      null;
  }

  custom1Changed(element: TxCardDto): void {
    let option = _.find(this.options.custom1, { 'name': this.newCard.custom1 });
    element.custom1ColorCode = this.newCard.custom1ColorCode = option && option.colorCode ?
      option.colorCode :
      null;
  }

  custom2Changed(element: TxCardDto): void {
    let option = _.find(this.options.custom2, { 'name': this.newCard.custom2 });
    element.custom2ColorCode = this.newCard.custom2ColorCode = option && option.colorCode ?
      option.colorCode :
      null;
  }

  generateTxCardPDF(){
    this.genTxPdf = true;

    setTimeout(() => {
      let content = this.txpdfcontent.nativeElement;
      var opt = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'TX-CARD.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

      html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var pgHt = pdf.internal.pageSize.getHeight();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);

            const today = moment();
            let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
            let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`;
            pdf.text(footerText, 0.25, pgHt - 0.25);
          }
          this.genTxPdf = false;
          this.allTxRow = true;
          this.dateRange = false;
          this.searchform.reset();
          this.searchform.patchValue({
            allTxRow: this.allTxRow,
            daterange: this.dateRange,
            startdate: moment(this.cards[this.cards.length-1].postDatetime).tz(this.cards[this.cards.length-1].locationTimeZone).toDate(),
            enddate: moment(this.cards[0].postDatetime).tz(this.cards[0].locationTimeZone).toDate(),
          })
          this.minStartDate = moment(this.cards[this.cards.length - 1].postDatetime).tz(this.cards[this.cards.length - 1].locationTimeZone).toDate();
          this.maxEndDate = moment(this.cards[0].postDatetime).tz(this.cards[0].locationTimeZone).toDate();
          this.maxStartDate = this.maxEndDate;
          this.minEndDate = this.minStartDate;

          this.txPDFCompleted.emit();
        })
        .save();
    }, 1000)
  }

  notesPopup(element){
    this.dialog.open(this.TreatmentNotesDialog, {data: element, maxWidth: '500px', minWidth: '500px', maxHeight: '80%'})
  }

  undoConfirmation(){
    const undoConfirmation = this.dialog.open(this.undoConfirmationTemplate);
    undoConfirmation.afterClosed().subscribe(resp => {
      if(resp){
        this.cancelAddEdit();
      }
    })
  }

  addFeeNote(){

    const feenotedialog = this.dialog.open(this.feenotedialog, {'width': '250px', 'maxHeight': '400px'});
    feenotedialog.afterClosed().subscribe(resp => {
      if(resp){
        //this.newCard.feeAmount
      }
    })
  }

  getFeeNoteTooltip(element) {
    let amount = element.feeAmount ? `Amount: ${element.feeAmount} \n` : '';
    let feeNote = element.feeNotes ? `Notes: ${element.feeNotes}` : '';
    return `${amount} ${feeNote}`;
  }

  onChangeProcedure(){
    this.rowAppointmentEditDisplay.nextPeriodId = this.procedures.find(procedure => procedure.id == this.rowAppointmentEditDisplay.procedureId).defaultNextPeriodId;
  }

  toggleCollapse(rowElement){
    rowElement.isCollapsed = !rowElement.isCollapsed
  }

  getprocedureById(id){
		return this.procedures.find(procedure => procedure.id == id);
	}

  getNextPeriodById(id){
    return this.options.nextPeriods.find(nextPeriod => nextPeriod.id == id)
  }

  toggleAllTxRow(){
    this.allTxRow = this.allTxRow == true ? false : true;
    this.dateRange = !this.allTxRow;
    this.searchform.patchValue({
      allTxRow: this.allTxRow,
      daterange: this.dateRange,
    })

    if(this.allTxRow){
      this.pdfCards = this.cards;
      this._cdr.detectChanges();
    }

  }

  toggleDateRange(){
    this.dateRange = this.dateRange == true ? false : true;
    this.allTxRow = !this.allTxRow;
    this.searchform.patchValue({
      allTxRow: this.allTxRow,
      daterange: this.dateRange
    })

  }

  openTxCardDateRangeDialog(){
    const txCardDateRangeDialog = this.dialog.open(this.txcarddaterange);
    this.submitted = true;
    txCardDateRangeDialog.afterClosed().subscribe(resp => {
      if (resp) {
        this.generateTxCardPDF();
      } else {
        this.genTxPdf = false;
        this.allTxRow = true;
        this.dateRange = false;
        this.searchform.reset();
        this.searchform.patchValue({
          allTxRow: this.allTxRow,
          daterange: this.dateRange,
          startdate: moment(this.cards[this.cards.length - 1].postDatetime).tz(this.cards[this.cards.length - 1].locationTimeZone).toDate(),
          enddate: moment(this.cards[0].postDatetime).tz(this.cards[0].locationTimeZone).toDate(),
        })

        this.minStartDate = moment(this.cards[this.cards.length-1].postDatetime).tz(this.cards[this.cards.length-1].locationTimeZone).toDate();
        this.maxEndDate = moment(this.cards[0].postDatetime).tz(this.cards[0].locationTimeZone).toDate();
        this.maxStartDate = this.maxEndDate;
        this.minEndDate = this.minStartDate;

        this.txPDFCompleted.emit();
      }
    })
  }

  intializeSearchForm() {
    this.searchform = this.formBuilder.group({
      allTxRow: [''],
      daterange: [''],
      startdate: [''],
      enddate: [''],
    });

    this.searchform.patchValue({
      allTxRow: this.allTxRow,
      daterange: this.dateRange,
    })
  }

  startDateChange(evt) {
    this.minEndDate = new Date(evt.value).toISOString();

    this.pdfCards = this.cards.filter(cardItem =>
      moment(cardItem.postDatetime).tz(cardItem.locationTimeZone).isSameOrAfter(moment(this.searchform.value.startdate).tz(cardItem.locationTimeZone)) &&
      moment(cardItem.postDatetime).tz(cardItem.locationTimeZone).isSameOrBefore(moment(this.searchform.value.enddate).tz(cardItem.locationTimeZone)));

    this._cdr.detectChanges();
  }

  enDateChange(evt) {
    this.maxStartDate = new Date(evt.value).toISOString();;

    this.pdfCards = this.cards.filter(cardItem =>
      moment(cardItem.postDatetime).tz(cardItem.locationTimeZone).isSameOrAfter(moment(this.searchform.value.startdate).tz(cardItem.locationTimeZone)) &&
      moment(cardItem.postDatetime).tz(cardItem.locationTimeZone).isSameOrBefore(moment(this.searchform.value.enddate).tz(cardItem.locationTimeZone)));

    this._cdr.detectChanges();
  }

  staffChanged(evt){
    let _searchkeyword: any = evt.target.value;

    if (_searchkeyword.length > 0) {
      let searchkeyword = _searchkeyword.toLowerCase();
      this.filteredStaff = this.staffUsers.filter(option => option.firstName.toLowerCase().includes(searchkeyword) || option.lastName.toLowerCase().includes(searchkeyword) || (option.shortName && option.shortName.toLowerCase().includes(searchkeyword)));

      if(this.filteredStaff.length == 0){
        this.filteredStaff = this.staffUsers;
      }
    } else {
      this.filteredStaff = this.staffUsers;
    }
  }

  onStaffOptionChanged(evt){
    let _staffId = evt.option.value;
    let staffUser = this.staffUsers.find(staff => staff.id == _staffId);

    if(staffUser){
      this.newCard.staffId = _staffId;
      if(staffUser.shortName){}
      this.newCardStaffName = staffUser.shortName ? staffUser.shortName : `${staffUser.firstName} ${staffUser.lastName}`;
    }

  }


}

export class TxCardDtoVM extends TxCardDto {
  isCollapsed:boolean = true;
}
