import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { INTERNAL_USER_FEATURE_KEY } from './featurekey';
import { featureAdapter, InternalUserStoreEntity, State } from './state';

export const selectInternalUsersState: MemoizedSelector<object, State> = createFeatureSelector<State>(INTERNAL_USER_FEATURE_KEY);

export const getSelectedInternalUserId = createSelector(selectInternalUsersState, (state) => state.selectedInternalUserId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectInternalUsersState);
export const selectInternalUserIds = selectIds;
export const selectAllInternalUsers = selectAll;
export const selectAllInternalUserEntities = selectEntities;

export const getSelectedInternalUser = createSelector(selectInternalUsersState, selectAllInternalUsers, (state, allInternalUsers: InternalUserStoreEntity[]) =>
  allInternalUsers ? allInternalUsers.find((rp) => rp.id === state.selectedInternalUserId) : null
);

export const selectInternalUserById = (id: string) =>
  createSelector(selectAllInternalUsers, (allInternalUsers: InternalUserStoreEntity[]) => (allInternalUsers ? allInternalUsers.find((rp) => rp.id === id) : null));

export const selectInternalUsersError: MemoizedSelector<object, any> = createSelector(selectInternalUsersState, (state) => state.error);

export const selectInternalUsersIsLoading: MemoizedSelector<object, boolean> = createSelector(selectInternalUsersState, (state: State) => state.isLoading);

export const selectTxCardIsFavorite = (userId: string, cardSelector: string) =>
  createSelector(selectInternalUserById(userId), (user: InternalUserStoreEntity) => user && user.txCardFavorites && user.txCardFavorites.some((c) => c == cardSelector));
