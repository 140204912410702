import { Pipe, PipeTransform } from "@angular/core";
import * as Moment from 'moment';
import { SequenceItemDto, SequenceTimingTriggerEnum } from 'src/app/shared/services/api.service';

@Pipe({
  name: 'sequenceItemSort',
  pure: true
})
export class SequenceItemSortPipe implements PipeTransform {
  transform(items: SequenceItemDto[], direction: 'asc' | 'dsc' = 'asc') {
    if (items) {
      let refDate = Moment('1988-10-17T02:47:00+07:00');

      return items.sort((a, b) => {
        let aDelta = this.calculateDelta(a, refDate);
        let bDelta = this.calculateDelta(b, refDate);

        if (direction == 'asc')
          return aDelta.diff(bDelta);
        else
          return bDelta.diff(aDelta);
      });
    }
    return items;
  }

  private calculateDelta(item: SequenceItemDto, refDate: Moment.Moment) {

    let increment: Moment.unitOfTime.DurationConstructor;
    switch (item.timingTrigger) {
      case SequenceTimingTriggerEnum.HoursAfterTrigger:
      case SequenceTimingTriggerEnum.HoursBeforeAppointment:
      case SequenceTimingTriggerEnum.HoursAfterAppointment:
        increment = 'hours';
        break;
      case SequenceTimingTriggerEnum.DaysAfterTigger:
      case SequenceTimingTriggerEnum.DaysBeforeAppointment:
      case SequenceTimingTriggerEnum.DaysAfterAppointment:
        increment = 'days';
        break;
      case SequenceTimingTriggerEnum.Immediately:
        increment = 'seconds';
        break;
    }

    let delta: 'before' | 'after';
    switch (item.timingTrigger) {
      case SequenceTimingTriggerEnum.HoursAfterTrigger:
      case SequenceTimingTriggerEnum.DaysAfterTigger:
      case SequenceTimingTriggerEnum.DaysAfterAppointment:
      case SequenceTimingTriggerEnum.HoursAfterAppointment:
        delta = 'after';
        break;
      case SequenceTimingTriggerEnum.HoursBeforeAppointment:
      case SequenceTimingTriggerEnum.DaysBeforeAppointment:
      case SequenceTimingTriggerEnum.Immediately:
        delta = 'before';
        break;
    }



    let deltaDate = refDate.clone()
    deltaDate.add(Moment(item.triggerStartTime, Moment.HTML5_FMT.TIME_SECONDS).hours(), 'hours');
    if (delta == 'before')
      deltaDate.subtract(item.triggerIncrement, increment);
    else if(delta == 'after')
      deltaDate.add(item.triggerIncrement, increment);

    return deltaDate;
  }
}
