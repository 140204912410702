import { Action, createReducer, on } from "@ngrx/store";
import * as ProcedureGroupStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(ProcedureGroupStoreActions.LoadRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    page: action.page || state.page,
    pageSize: action.pageSize || state.pageSize,
    selectedProcedureGroupId: null
  })),
  on(ProcedureGroupStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.procedureGroups, { ...s, isLoading: false, error: null })
  }),
  on(ProcedureGroupStoreActions.LoadFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //ADD
  on(ProcedureGroupStoreActions.AddRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(ProcedureGroupStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(
      action.procedureGroup,
      { ...state, isLoading: false, error: null }
    )
  )),
  on(ProcedureGroupStoreActions.AddFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //SELECT
  on(ProcedureGroupStoreActions.SelectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedProcedureGroupId: null
  })),
  on(ProcedureGroupStoreActions.SelectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedProcedureGroupId: action.procedureGroup && action.procedureGroup.id
  })),
  on(ProcedureGroupStoreActions.SelectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //DESELECT
  on(ProcedureGroupStoreActions.DeselectRequest, (state, action) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(ProcedureGroupStoreActions.DeselectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    error: null,
    selectedProcedureGroupId: null
  })),
  on(ProcedureGroupStoreActions.DeselectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({ ...state });
  }),
  //UTILITY
  on(ProcedureGroupStoreActions.ResetError, (state) => ({
    ...state,
    error: null
  })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
