export enum PhotoEditorActions {
  Remove = 'Remove',
  Save = 'Save'
}

export interface PhotoEditorData {
  locationUrl?: string,
  locationOriginalUrl?: string,
  enableRemovePhoto?: boolean,
  enableReloadOriginal?: boolean,
  imageInfo?: any,
  firstName?: string,
  lastName?: string,
  nickname?: string,
  dob?: Date,
  takenWhen?: Date
}
