import { Injectable } from '@angular/core';
import { OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as RouterStoreActions from './actions';

@Injectable({providedIn: 'root'})
export class RouterStoreEffectsService implements OnInitEffects {
  constructor() { }
  ngrxOnInitEffects(): Action {
    return RouterStoreActions.Init();
  }

}
