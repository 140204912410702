import { createAction, props } from "@ngrx/store";
import { PatientFormStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction(
  '[PatientForm] Load Request'
);

export const LoadSuccess = createAction(
  '[PatientForm] Load Success',
  props<{ patientForms: PatientFormStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[PatientForm] Load Failure',
  props<{ error: any; }>()
);

//LOAD ONE
export const LoadOneRequest = createAction(
  '[PatientForm] Load One Request',
  props<{ id: number; }>()
);

export const LoadOneSuccess = createAction(
  '[PatientForm] Load One Success',
  props<{ patientForm: PatientFormStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[PatientForm] Load One Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[PatientForm] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[PatientForm] Select Success',
  props<{ patientForm: PatientFormStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[PatientForm] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[PatientForm] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[PatientForm] Deselect Success',
);

export const DeselectFailure = createAction(
  '[PatientForm] Deselect Failure',
  props<{ error: any; }>()
);

//ADD
export const AddRequest = createAction(
  '[PatientForm] Add Request',
  props<{ patientForm: PatientFormStoreEntity; }>()
);

export const AddSuccess = createAction(
  '[PatientForm] Add Success',
  props<{ patientForm: PatientFormStoreEntity; }>()
);

export const AddFailure = createAction(
  '[PatientForm] Add Failure',
  props<{ error: string; }>()
);

//ADD MULTIPLE
export const AddMultipleRequest = createAction(
  '[PatientForm] Add Multiple Request',
  props<{ patientForms: PatientFormStoreEntity[]; }>()
);

export const AddMultipleSuccess = createAction(
  '[PatientForm] Add Multiple Success',
  props<{ patientForms: PatientFormStoreEntity[]; }>()
);

export const AddMultipleFailure = createAction(
  '[PatientForm] Add Multiple Failure',
  props<{ error: string; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[PatientForm] Update Request',
  props<{ id: number; patientForm: PatientFormStoreEntity; }>()
);

export const UpdateSuccess = createAction(
  '[PatientForm] Update Success',
  props<{ patientForm: PatientFormStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[PatientForm] Update Failure',
  props<{ error: any; }>()
);

//DELETE
export const DeleteRequest = createAction(
  '[PatientForm] Delete Request',
  props<{ id: number; }>()
);

export const DeleteSuccess = createAction(
  '[PatientForm] Delete Success',
  props<{ id: number; }>()
);

export const DeleteFailure = createAction(
  '[PatientForm] Delete Failure',
  props<{ error: string; }>()
);
