import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule, MatIconModule, MatInputModule, MatProgressBarModule, MatSelectModule, MatTooltipModule } from "@angular/material";
import { ImageCropperModule } from "ngx-image-cropper";
import { ClickConfirmModule } from "../click-confirm/click-confirm.module";
import { AdvancedEditorComponent } from "./advanced-editor.component";
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    AdvancedEditorComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ClickConfirmModule,
    ImageCropperModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSliderModule,
    MatMenuModule,
    FormsModule,
    DragDropModule,
    MatInputModule,
    MatSelectModule
  ],
  exports: [
    AdvancedEditorComponent
  ],
})
export class AdvancedEditorModule { }
