import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { IMessageDto, MessageDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<PatientReferralMessageStoreEntity> = createEntityAdapter<PatientReferralMessageStoreEntity>({
  selectId: patientReferralMessage => patientReferralMessage.id
});

export interface State extends EntityState<PatientReferralMessageStoreEntity> {
  selectedPatientReferralMessageId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedPatientReferralMessageId: null,
  isLoading: false,
  error: null
});

export class PatientReferralMessageStoreEntity extends MessageDto {
  constructor(data?: IMessageDto) {
    super(data);
  }
}
