import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { PatientCephalometricStoreEffects } from './effects';
import { PATIENT_CEPHALOMETRIC_FEATURE_KEY } from './featurekey';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(PATIENT_CEPHALOMETRIC_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([PatientCephalometricStoreEffects])
  ]
})
export class PatientCephalometricStoreModule { }
