import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(){ }
    // displays error messages
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // let errorMessage = "Anunknownerroroccured";
                // if(error.error.message) {
                //     errorMessage = error.error.message;
                // }
                return throwError(error);
            })
        );
    }
}
