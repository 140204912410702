import { createAction, props } from "@ngrx/store";
import { AppointmentComplianceStoreEntity } from './state';
import { AppointmentComplianceDto, IAppointmentComplianceDto } from "../../shared/services/api.service";

//LOAD ONE
export const LoadOneRequest = createAction(
  '[AppointmentCompliance] Load One Request',
  props<{ patientId: number; appointmentId: number; }>()
);

export const LoadOneSuccess = createAction(
  '[AppointmentCompliance] Load One Success',
  props<{ appointmentCompliance: AppointmentComplianceStoreEntity; }>()
);

export const LoadOneFailure = createAction(
  '[AppointmentCompliance] Load One Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[AppointmentCompliance] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[AppointmentCompliance] Select Success',
  props<{ appointmentCompliance: AppointmentComplianceStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[AppointmentCompliance] Select Failure',
  props<{ error: any; }>()
);

//DESELECT
export const DeselectRequest = createAction(
  '[AppointmentCompliance] Deselect Request',
);

export const DeselectSuccess = createAction(
  '[AppointmentCompliance] Deselect Success',
);

export const DeselectFailure = createAction(
  '[AppointmentCompliance] Deselect Failure',
  props<{ error: any; }>()
);

//UPDATE
export const UpdateRequest = createAction(
  '[AppointmentCompliance] Update Request',
  props<{ patientId: number; appoointmentId: number; appointmentCompliance: AppointmentComplianceDto; }>()
);

export const UpdateSuccess = createAction(
  '[AppointmentCompliance] Update Success',
  props<{ appointmentCompliance: AppointmentComplianceStoreEntity; }>()
);

export const UpdateFailure = createAction(
  '[AppointmentCompliance] Update Failure',
  props<{ error: any; }>()
);

//UTILITY
export const ResetError = createAction(
  '[AppointmentCompliance] Reset Error'
);
