import { createAction, props } from '@ngrx/store';
import { StepGroupDto } from 'src/app/shared/services/api.service';

export const LoadRequest = createAction(
  '[Treatment] StepGroup Load Request',
);

export const LoadSuccess = createAction(
  '[Treatment] StepGroup Load Success',
  props<{ stepGroups: StepGroupDto[] }>()
);

export const LoadFailure = createAction(
  '[Treatment] StepGroup Load Failure',
  props<{ err: any}>()
);

export const LoadOnceRequest = createAction(
  '[Treatment] StepGroup Load Once Request',
);
