import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { State } from '../root-state';
import * as PatientCephalometricStoreActions from './actions';
import { HubEventArea, HubEventType, PatientClient } from 'src/app/shared/services/api.service';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import { PatientsStoreSelectors } from '../patient-store';

@Injectable({ providedIn: 'root' })
export class PatientCephalometricStoreEffects {
  private _selectedPatientId$ = this.store$.select(PatientsStoreSelectors.getSelectedPatientId);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _patientClient: PatientClient) { }

  loadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientCephalometricStoreActions.LoadRequest),
      withLatestFrom(this._selectedPatientId$),
      switchMap(([action, selectedPatientId]) => {
        if (action.patientCephalometricId == null) {
          return this._patientClient.patient_GetPatientCephalometrics(selectedPatientId).pipe(
            map((patientCephalometrics) => PatientCephalometricStoreActions.LoadSuccess({ entries: patientCephalometrics })),
            catchError((err) => of(PatientCephalometricStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        } else {
          return this._patientClient.patient_GetPatientCephalometric(selectedPatientId, action.patientCephalometricId).pipe(
            map((patientCephalometric) => PatientCephalometricStoreActions.LoadSuccess({ entries: patientCephalometric })),
            catchError((err) => of(PatientCephalometricStoreActions.LoadFailure({ error: err }))),
            take(1)
          );
        }
      })
    )
  );

  twainUpdateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      withLatestFrom(this._selectedPatientId$),
      filter(
        ([action, selectedPatientId]) =>
          action.event.eventArea == HubEventArea.PatientCephalometricEvent &&
          action.event.entityType == PatientClient.PatientCephalometricOrderDto &&
          action.event.patientId == selectedPatientId
      ),
      map(([action, selectedPatientId]) =>
        action.event.eventType != HubEventType.Removed
          ? PatientCephalometricStoreActions.LoadRequest({
            patientCephalometricId: action.event.entityId
          })
          : PatientCephalometricStoreActions.Remove({ patientCephalometricId: action.event.entityId })
      ),
    )
  );
}
