import { Action, createReducer, on } from '@ngrx/store';
import * as PatientReferralMessageStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  on(PatientReferralMessageStoreActions.LoadPatientReferralMessagesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(PatientReferralMessageStoreActions.LoadPatientReferralMessagesSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.patientReferralMessages, { ...s, isLoading: false, error: null });
  }),
  on(PatientReferralMessageStoreActions.LoadPatientReferralMessagesFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),

  on(PatientReferralMessageStoreActions.LoadPatientReferralMessageRequest, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(PatientReferralMessageStoreActions.LoadPatientReferralMessageSuccess, (state, action) =>
    featureAdapter.upsertOne(action.patientReferralMessage, { ...state, isLoading: false })),
  on(PatientReferralMessageStoreActions.LoadPatientReferralMessageFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),

  on(PatientReferralMessageStoreActions.ReferralMessagedRemoved, (state, action) =>
    featureAdapter.removeOne(action.messageId, ({ ...state }))),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
