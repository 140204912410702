import { NgModule } from '@angular/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextFieldModule } from '@angular/cdk/text-field';

import {
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatRadioModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatListModule,
  MatSidenavModule,
  MatRippleModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTabsModule,
  MatSnackBarModule,
  MatChipsModule,
  MatTableModule,
  MatDialogModule,
  MatButtonToggleModule
} from '@angular/material';

const modules = [
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatRadioModule,
  MatTooltipModule,
  MatAutocompleteModule,
  NgxMaterialTimepickerModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatListModule,
  MatSidenavModule,
  MatRippleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTabsModule,
  MatSnackBarModule,
  MatChipsModule,
  TextFieldModule,
  MatTableModule,
  MatDialogModule,
  MatButtonToggleModule
];

@NgModule({
  imports: [modules],
  exports: [modules]
})
export class MaterialModule { }
