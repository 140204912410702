import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PHOTOTYPE_FEATURE_KEY } from './featurekey';
import { featureAdapter, PhotoTypeStoreEntity, State } from './state';

export const selectPhotoTypesState: MemoizedSelector<object, State> = createFeatureSelector<State>(PHOTOTYPE_FEATURE_KEY);

export const getSelectedPhotoTypeId = createSelector(selectPhotoTypesState, (state) => state.selectedPhotoTypeId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectPhotoTypesState);
export const selectPhotoTypeIds = selectIds;
export const selectAllPhotoTypes = selectAll;
export const selectAllPhotoTypesEntities = selectEntities;

export const getSelectedPhotoType = createSelector(
  selectPhotoTypesState,
  selectAllPhotoTypes,
  (state, allPhotoTypes: PhotoTypeStoreEntity[]) =>
    allPhotoTypes ? allPhotoTypes.find((rp) => rp.id === state.selectedPhotoTypeId) : null
);

export const selectPhotoTypeById = (id: number) =>
  createSelector(selectAllPhotoTypes, (allPhotoTypes: PhotoTypeStoreEntity[]) =>
    allPhotoTypes ? allPhotoTypes.find((rp) => rp.id === id) : null
  );

export const selectPhotoTypesError: MemoizedSelector<object, any> = createSelector(selectPhotoTypesState, (state) => state.error);

export const selectPhotoTypesIsLoading: MemoizedSelector<object, boolean> = createSelector(selectPhotoTypesState, (state: State) => state.isLoading);
