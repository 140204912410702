import { Component, Inject, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
    FormGroupStoreActions,
    FormGroupStoreSelectors,
    FormTypeStoreActions,
    FormTypeStoreSelectors,
    LocationsStoreSelectors,
    PatientFormStoreActions,
    PatientFormStoreSelectors,
    PatientsStoreSelectors,
    ResponsiblePartyStoreSelectors,
    RootStoreState
} from '../../../../root-store';
import { CARD_DATA, ICardData } from '../../../../shared/models';
import { FormTypeAudienceEnum, PatientFormDto } from '../../../../shared/services/api.service';
import { MatDialog } from '@angular/material';
import { PatientFormsViewerComponent } from '../patient-form-viewer/patient-forms-viewer.component';

@UntilDestroy()
@Component({
  selector: 'patient-forms-front',
  templateUrl: './patient-forms-front.component.html',
  styleUrls: ['./patient-forms-front.component.css']
})
export class PatientFormsFrontComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  isPatientFormWorking$: Observable<boolean> = this._store$.select(PatientFormStoreSelectors.selectPatientFormsIsLoading);
  isFormTypeWorking$: Observable<boolean> = this._store$.select(FormTypeStoreSelectors.selectFormTypesIsLoading);
  isFormGroupWorking$: Observable<boolean> = this._store$.select(FormGroupStoreSelectors.selectFormGroupsIsLoading);

  patientForms$ = this._store$.select(PatientFormStoreSelectors.selectAllPatientForms).pipe(
    map((forms) => forms.filter((f) => f.formTypeAudience == FormTypeAudienceEnum.Patient))
  );
  officeForms$ = this._store$.select(PatientFormStoreSelectors.selectAllPatientForms).pipe(
    map((forms) => forms.filter((f) => f.formTypeAudience == FormTypeAudienceEnum.Office))
  );
  formTypes$ = this._store$.select(FormTypeStoreSelectors.selectAllFormTypes);
  formGroups$ = this._store$.select(FormGroupStoreSelectors.selectAllFormGroups);
  responsibles$ = this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties);

  private _selectedPatientId$ = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  private _selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);

  selectedTimeZone: string;
  @ViewChild('patientforminfo', { static: true }) patientforminfo: TemplateRef<any>;

  constructor(
    private _store$: Store<RootStoreState.State>,
    @Inject(CARD_DATA) private _data: ICardData<any, FormTypeAudienceEnum>,
    private _dialog: MatDialog,
  ) {

  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this._store$.dispatch(PatientFormStoreActions.LoadRequest());
    this._store$.dispatch(FormTypeStoreActions.LoadRequest());
    this._store$.dispatch(FormGroupStoreActions.LoadRequest());

    combineLatest(
      this._selectedPatientId$,
      this._selectedLocation$
    )
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(([patientId, location]) => {
        if (patientId && location) {
          this.selectedTimeZone = location.ianaTimeZone;
          this._store$.dispatch(PatientFormStoreActions.LoadRequest());
        }
      });
  }

  addPatientForm() {
    this._data.flip.next({ payload: FormTypeAudienceEnum.Patient, side: this._data.side });
  }

  addOfficeForm() {
    this._data.flip.next({ payload: FormTypeAudienceEnum.Office, side: this._data.side });
  }

  openPatientForm(patientForm: PatientFormDto): void {
    if (patientForm && patientForm.jsonData) {
      this._dialog.open(
        PatientFormsViewerComponent,
        {
          data: patientForm,
          width: '1000px',
          minHeight: '700px'
        }
      );
    }
  }

  getForm(patientForm: PatientFormDto) {
    if (!patientForm.isExpire && !!patientForm.completedWhen && patientForm.jsonData) {
      this.openPatientForm(patientForm);
    }
    else if (patientForm.isExpire) {
      this._dialog
        .open(this.patientforminfo, { data: "Form has expired!" });
    }
    else if (!patientForm.completedWhen) {
      window.open(patientForm.entryUrl, "_blank");
    }
  }
}
