import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSliderModule,
  MatTooltipModule
} from "@angular/material";
import { ImageCropperModule } from "ngx-image-cropper";
import { MomentDateModule } from "../../../pipe/moment-date/moment-date.module";
import { ClickConfirmModule } from "../../../shared-module/click-confirm/click-confirm.module";
import { MontageGeneratorComponent } from "./montage-generator.component";

@NgModule({
  declarations: [
    MontageGeneratorComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ClickConfirmModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MomentDateModule,
    ImageCropperModule,
    MatSliderModule,
    MatMenuModule,
    MatCheckboxModule,
  ],
  exports: [
    MontageGeneratorComponent
  ],
})
export class MontageGeneratorModule { }
