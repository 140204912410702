import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationClient, PlanDto, TreatmentDto } from 'src/app/shared/services/api.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PatientsStoreSelectors, PatientsStoreState } from '../../../../root-store';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-patient-plan-display',
  templateUrl: './treatment-plan-display.component.html',
  styleUrls: ['./treatment-plan-display.component.css']
})
export class PatientPlanDisplayComponent implements OnInit, OnDestroy {
  @Input('plans') patientPlans: PlanDto[];
  @Input('enableEdits') enableEdits: boolean;
  @Input('treatment') treatment: TreatmentDto;
  @Input('showComments') showComments: boolean;
  @Output('remove') removeEvent: EventEmitter<PlanDto> = new EventEmitter<PlanDto>();
  @Output('edit') editEvent: EventEmitter<{ plan: PlanDto, idx: number }> = new EventEmitter<{ plan: PlanDto, idx: number }>();

  editModePlans = {};
  planEditForm: FormGroup;

  commentsModelChanged: Subject<string> = new Subject<string>();
  patient$ = this.store$.select(PatientsStoreSelectors.getSelectedPatient);
  patientId: number;
  locationId: number;
  isWorking: boolean;
  isCommentsEdited: boolean = false;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store$: Store<PatientsStoreState.State>,
    private formBuilder: FormBuilder,
    private locationClient: LocationClient,
    private _snackbar: MatSnackBar,
    private _cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.planEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      clinicalTerms: [''],
      laymanTerms: ['']
    });

    this.patient$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(patient => {
        if (patient) {
          this.patientId = patient.id;
          this.locationId = patient.locationId;
        }
      })

    this.commentsModelChanged
      .pipe(
        takeUntil(this._destroy$),
        filter(x => !this.isCommentsEdited && this.treatment && this.treatment.id > 0),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((comments: string) => {
        this.isCommentsEdited = true;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  saveComments(): void {
    if (!this.treatment ||
      this.treatment.id <= 0 ||
      this.isWorking)
      return;

    this.isWorking = true;
    this.locationClient.location_PutTreatmentComments(
      this.locationId,
      this.patientId,
      this.treatment.id,
      this.treatment
    )
      .pipe(
        takeUntil(this._destroy$),
        take(1)
    )
      .subscribe(resp => {
        this.isWorking = false;
        this.isCommentsEdited = false;
        this.openSnackBar("Comments saved", "Ok");
        this._cdr.detectChanges();
      },
        (error) => {
          this.isWorking = false;
          this.openSnackBar("Error while saving comments", "Ok");
          this._cdr.detectChanges();
        });
  }

  removePlan(plan: PlanDto) {
    this.removeEvent.next(plan);
  }

  toggleEditPlan(planId: number, isEditMode: boolean) {
    if (this.enableEdits){
      this.editModePlans[planId] = isEditMode;
      if(isEditMode){
        let patientPlan = this.patientPlans.find(plan => plan.id == planId);
        if(patientPlan){
          this.planEditForm.patchValue({
            name: patientPlan.name,
            clinicalTerms: patientPlan.clinicalTerms,
            laymanTerms: patientPlan.laymanTerms
          })
        }        
      }
    }    
  }

  savePlan(plan: PlanDto, idx: number) {
    if (!this.enableEdits)
      return;

    let planEditFormValue = this.planEditForm.value;
    plan.name = planEditFormValue.name;
    plan.laymanTerms = planEditFormValue.laymanTerms;
    plan.clinicalTerms = planEditFormValue.clinicalTerms;
    this.editEvent.next({ plan: plan, idx: idx});
    this.toggleEditPlan(plan.id, false);
  }

  saveOnSubmit(event, plan: PlanDto, idx: number){
    if(event.keyCode === 13){
      this.savePlan(plan, idx);
    }
  }

  commentsChanged(text: string) {
    this.commentsModelChanged.next(text);
  }

  openSnackBar(message: string, action: string): void {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }
}
