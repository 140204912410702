import { JwtBody } from './jwt.model';
import { EvoTokenReviver, parsePermissions } from './reviver';
import { inflate, ungzip } from 'pako';

export function EvoTokenParser(token: string, permissions?: string): JwtBody {
  const [tokenHead, tokenBody, tokenSignature] = token.split('.');

  if(tokenBody == null) {
    throw new Error("Error retrieving token body");
  }

  const s = JSON.parse(atob(tokenBody));

  // Inject Permissions
  if (permissions) {
    s.Permissions = parsePermissionsBase64(permissions);
  }

  const json = JSON.parse(JSON.stringify(s), EvoTokenReviver);

  return new JwtBody(json);;
}

export function parsePermissionsBase64(gzipBase64Permissions: string): string {
  try {
      // Decode
      const strData = atob(gzipBase64Permissions);

      // Unzip
      // Convert binary string to character-number array
      const charData = strData.split('').map(function(x){return x.charCodeAt(0);});
      const binData = Uint8Array.from(charData);

      const unzipped = ungzip(binData);

      return String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(unzipped)));
  } catch (error) {
      console.error(`Error during decompression: ${error}`);
      throw error;
  }
}
