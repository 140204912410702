import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  CardsStoreActions,
  CardsStoreSelectors,
  LocationsStoreSelectors,
  PatientsStoreSelectors,
  PatientStoreEntity,
  ProvidersStoreSelectors,
  PatientsStoreActions,
  ResponsiblePartyStoreActions,
  ResponsiblePartyStoreEntity,
  ResponsiblePartyStoreSelectors,
  RootStoreState,
} from 'src/app/root-store';
import { LANGUAGES_LIST } from 'src/app/shared/enums';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import {
  ContactClient,
  ContactDto,
  DentistReferralDirectionEnum,
  DentistReferralDto,
  PatientClient,
  PatientReferralDto,
  PatientDentistDto,
  ReferringContactDto,
  PatientContactReferralDto
} from 'src/app/shared/services/api.service';
import { PatientReferralPayload } from '../patient-referral/patient-referral.component';
import { IPatientReferredPracticePayload } from '../patient-referred-practice/patient-referred-practice.component';
import { PatientDentistPayload } from '../patient-dentist/patient-dentist.component';
import { ofType } from '@ngrx/effects';
import * as _ from 'lodash';
import { findLastKey } from 'lodash';

@Component({
  selector: 'app-patient-overview-front',
  templateUrl: './patient-overview-front.component.html',
  styleUrls: ['./patient-overview-front.component.scss'],
})
export class PatientOverviewFrontComponent implements OnInit {
  @ViewChild('referralCountTemplate', { static: true }) referralCountTemplate: TemplateRef<any>;
  @ViewChild('notesTemplate', { static: true }) notesTemplate: TemplateRef<any>;
  @ViewChild('hobbiesTemplate', { static: true }) hobbiesTemplate: TemplateRef<any>;
  @ViewChild('accountNumberTemplate', { static: true }) accountNumberTemplate: TemplateRef<any>;
  @ViewChild('emergencyContactInfo', { static: true }) emergencyContactInfo: TemplateRef<any>;
  @ViewChild('tlcTemplate', { static: true }) tlcTemplate: TemplateRef<any>;
  @ViewChild('medicalAlertTemplate', { static: true }) medicalAlertTemplate: TemplateRef<any>;

  referralCountTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  notesTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  hobbiesTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  accountNumberTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  emergencyContactInfo$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  tlcTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  medicalAlertTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();

  /** Tree control for displaying data in Material Tree */
  treeControl = new CustomNestedTreeControl<TreeNode>(
    (node) => node.children,
    (node) => node.canCollapse != false,
    (node) => node.canCollapseView != false
  );
  /** Datasource to be inserted into tree control */
  treeData = new MatTreeNestedDataSource<TreeNode>();
  responsibleParties$: Observable<TreeNode[]> = this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties).pipe(
    map((responsibleParties) =>
      responsibleParties.map((responsibleParty) => ({
        label: `${responsibleParty.firstName} ${responsibleParty.lastName} (${responsibleParty.relationshipTypeCode})`,
        onclick: (node: TreeNode) => {
          this.openRelationship(responsibleParty);
        },
        nodeHeight: '24px'
      }))
    )
  );
  selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  //Dentist Referrals
  private _dentistReferrals$: ReplaySubject<DentistReferralDto[]> = new ReplaySubject<DentistReferralDto[]>(1);
  private _inboundDentistReferrals$ = this._dentistReferrals$.pipe(
    map((referrals) => referrals.filter((r) => r.direction == DentistReferralDirectionEnum.Inbound))
  );
  private _outboundDentistReferrals$ = this._dentistReferrals$.pipe(
    map((referrals) => referrals.filter((r) => r.direction == DentistReferralDirectionEnum.Outbound))
  );

  //Patient Referrals
  patientContactReferrals$: ReplaySubject<PatientContactReferralDto[]> = new ReplaySubject<PatientContactReferralDto[]>(1);
  private _referringContacts$: Observable<ReferringContactDto[]> = this.selectedPatient$.pipe(
    filter((patient) => !!patient),
    switchMap((patient) =>
      this._patientClient
        .patient_GetPatientReferringContacts(patient.id)
    ),
    takeUntil(this._destroy$),
    shareReplay(1)
  );

  patientDentists$: ReplaySubject<PatientDentistDto[]> = new ReplaySubject<PatientDentistDto[]>(1);

  /** Array of TreeNodes to describe actual tree structure */
  nodes: TreeNode[] = [

    {
      label: 'Notes',
      children: [
        {
          label: '',
          children: [],
          canCollapseView: false,
          templateContent: this.notesTemplate$,
        }
      ],
    },

    {
      label: 'Hobby/Interest',
      children: [
        {
          label: '',
          children: [],
          canCollapseView: false,
          templateContent: this.hobbiesTemplate$,
        }
      ],
    },

    {
      label: 'Relationships',
      children: this.responsibleParties$,
    },

    {
      label: 'Emergency contact information',
      children: [
        {
          label: '',
          children: [],
          canCollapseView: false,
          templateContent: this.emergencyContactInfo$,
        }
      ],
    },

    /* {
      label: 'Patient Dentist',
      children: [
        {
          label: `Dr Scott Cardell, DDS`,
          onclick: (_) => this.showPatientDentist(),
        },
      ],
    }, */

    {
      label: 'Patient Dentist',
      onclick: (_) => this.showPatientDentist(),
      children: this.selectedPatient$.pipe(
        filter((patient) => !!patient),
        map((patient): TreeNode[] => {
          let patientDentistLabel: string = '';
          let clinicLabel: string = '';

          if (patient.dentistClinic) {
            if (patient.dentistClinic.clinic) {
              const clinic = patient.dentistClinic.clinic;

              clinicLabel = `<div>${clinic.name}</div><div>${this.formatPhoneNumber(clinic.phoneNumber)}</div> <div>${clinic.faxNumber ? 'fax: ' : ''} ${this.formatPhoneNumber(clinic.faxNumber)}</div>${this.getAddress(clinic)}<div>${clinic.email ? clinic.email : ""}</div>`;
            }

            if (patient.dentistClinic.dentist) {
              const dentist = patient.dentistClinic.dentist;

              patientDentistLabel = `<div class="row"><div class="col-7"><div>${dentist.firstName} ${dentist.lastName}</div>${clinicLabel}</div><div class="col-5"><div>${dentist.nickname ? dentist.nickname : ""}</div><div>${dentist.memo ? dentist.memo : ""}</div></div></div>`;
            } else {
              patientDentistLabel = clinicLabel;
            }
          }

          return <TreeNode[]> [<TreeNode>{ label: patientDentistLabel }];
        })
      )
    },

    {
      label: 'Referral',
      children: [
        {
          label: 'Professional referral',
          onclick: (_) => this.showPatientReferral(),
          canCollapse: false,
          children: this._inboundDentistReferrals$.pipe(map((referrals) => referrals.map((r) => <TreeNode>{ label: `${r.dentistName}`}))),
        },
        {
          label: 'Patient referral',
          onclick: (_) => this.showPatientReferral(),
          canCollapse: false,
          children: this._referringContacts$.pipe(
            map((referringContacts) => (!!referringContacts && referringContacts.length > 0 ?
              referringContacts.map((x) =>  <TreeNode>{label: `${x.firstName} ${x.lastName}`})
              : [<TreeNode>{ label: 'No Referral' }]))
          ),
        },
        {
          label: 'Referred to doctor (out)',
          onclick: (_) => this.showPatientReferral(),
          canCollapse: false,
          children: this._outboundDentistReferrals$.pipe(map((referrals) => referrals.map((r) => <TreeNode>{ label: `${r.dentistName}` }))),
        },
        {
          label: 'Patients Referred To Your Practice',
          onclick: (_) => this.showReferredToPractice(),
          canCollapse: false,
          children: [], //this.patientReferrals$.pipe(map((referrals) => referrals.map((r) => <TreeNode>{ label: `${r.firstName} ${r.lastName}` }))),
          templateContent: this.referralCountTemplate$,
        },
      ],
    },
    {
      label: 'Preferences',
      children: this.selectedPatient$.pipe(
        filter((patient) => !!patient),
        switchMap((patient) =>
          zip(
            of(patient),
            this._store$.select(ProvidersStoreSelectors.selectProviderById(patient.preferredProviderId)),
            this._store$.select(LocationsStoreSelectors.selectLocationById(patient.preferredLocationId))
          )
        ),
        map(([patient, provider, location]): TreeNode[] => [
          { label: `<strong>Provider</strong>: ${provider && provider.nickName}` },
          { label: `<strong>Office</strong>: ${location && location.shortName}` },
          { label: `<strong>Language</strong>: ${patient.languageKey && LANGUAGES_LIST[patient.languageKey.toLowerCase()].name}` },
        ])
      ),
    },

    {
      label: 'Account',
      children: [
        {
          label: '',
          children: [],
          canCollapseView: false,
          templateContent: this.accountNumberTemplate$,
        }
      ],
    }
  ];

  /** Conveniece function for tree control */
  hasChild = (_: number, node: TreeNode) => !!node.children;

  noteEditMode:boolean = false;
  hobbiesInterestEditMode:boolean = false;
  accountNumberEditMode:boolean = false;
  emergencyContactInfoEditMode:boolean = false;
  selectedPatient:any;
  tlcEditMode:boolean = false;
  medicalAlertEditMode:boolean = false;

  constructor(
    private _store$: Store<RootStoreState.State>,
    @Inject(CARD_DATA) public _data: ICardData,
    private _patientClient: PatientClient,
    private _contactClient: ContactClient,
    public domSanitizer: DomSanitizer,
    private _actions$: ActionsSubject
  ) {
    //Update referrals list after selected patient changes
    this.selectedPatient$
      .pipe(
        filter((patient) => !!patient),
        distinctUntilKeyChanged('id'),
        switchMap((patient) => this._patientClient.patient_GetDentistReferrals(patient.id).pipe(take(1))),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => this._dentistReferrals$.next(result));
    this.selectedPatient$
      .pipe(
        filter((patient) => !!patient),
        distinctUntilKeyChanged('id'),
        switchMap((patient) => this._patientClient.patient_GetPatientContactReferrals(patient.id).pipe(take(1))),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => this.patientContactReferrals$.next(result));

      this.selectedPatient$
      .pipe(
        filter((patient) => !!patient),
        switchMap((patient) => this._patientClient.patient_GetPatientDentists(patient.id).pipe(take(1))),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        const _result: any[] = [];
        if(result.length > 0){
          _result.push(result[result.length-1]);
        }
        return this.patientDentists$.next(_result)
      });

  }

  ngOnInit() {
    this.selectedPatient$.pipe(filter(patient => !!patient)).subscribe(res => {
      this.selectedPatient = res;
      console.log("selectedPatient ", this.selectedPatient)
    })
    this.treeData.data = this.nodes;
    this.treeControl.dataNodes = this.nodes;
    this.treeControl.expandAll();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngAfterViewInit() {
    this.referralCountTemplate$.next(this.referralCountTemplate);
    this.notesTemplate$.next(this.notesTemplate);
    this.hobbiesTemplate$.next(this.hobbiesTemplate);
    this.accountNumberTemplate$.next(this.accountNumberTemplate);
    this.emergencyContactInfo$.next(this.emergencyContactInfo);
    this.tlcTemplate$.next(this.tlcTemplate);
    this.medicalAlertTemplate$.next(this.medicalAlertTemplate);
  }

  /** Handler function for processing click events on a node within the tree */
  handleOnClick(node: TreeNode) {
    if (node.onclick) node.onclick(node);
  }

  /** Opens the Relationships card and sets the provided responsible party as selected */
  // Acts as the onclick event under the relationships tree branch
  openRelationship(responsibleParty: ResponsiblePartyStoreEntity) {
    this._store$.dispatch(ResponsiblePartyStoreActions.SelectRequest({ id: responsibleParty.id }));

    this._store$
      .select(CardsStoreSelectors.selectOpenCards)
      .pipe(take(1))
      .subscribe((openCards) => {
        if (!openCards.some((card) => card.selector === 'patient-relationships')) {
          const index = openCards.find((card) => card.selector === 'patient-overview').order + 1;
          this._store$.dispatch(CardsStoreActions.OpenCardRequest({ selector: 'patient-relationships', order: index }));
        }
      });
  }

  /** Flips the card to the back specifying the dentist component as the desired editor */
  showPatientDentist() {
    this._data.flip.next(<IFlipEvent>{
      payload: <PatientDentistPayload>{
        editor: 'dentist',
        header: 'Patient Dentist',
        patientDentists$: this.patientDentists$
      },
      side: this._data.side
    });
  }

  /** Flips the card to the back specifying the patient referral as the desired editor */
  showPatientReferral() {
    this._data.flip.next(<IFlipEvent>{
      payload: <PatientReferralPayload>{
        editor: 'patient-referral',
        header: 'Referrals',
        dentistReferrals$: this._dentistReferrals$,
        referringContacts$: this._referringContacts$,
      },
      side: this._data.side,
    });
  }

  showReferredToPractice() {
    this._data.flip.next(<IFlipEvent>{
      payload: <IPatientReferredPracticePayload>{
        editor: 'patient-referred-practice',
        header: 'Referrals',
        patientContactReferrals$: this.patientContactReferrals$,
      },
      side: this._data.side,
    });
  }

  toggleNoteEdit(){
    this.noteEditMode = this.noteEditMode == true ? false : true;
  }

  toggleHobbiesInterestEdit(){
    this.hobbiesInterestEditMode = this.hobbiesInterestEditMode == true ? false : true;
  }

  toggleAccountNumberEdit(){
    this.accountNumberEditMode = this.accountNumberEditMode == true ? false : true;
  }

  toggleEmergencyContactInfo(){
    this.emergencyContactInfoEditMode = this.emergencyContactInfoEditMode == true ? false : true;
  }

  toggleTLCEdit(){
    this.tlcEditMode = this.tlcEditMode == true ? false : true;
  }

  toggleMedicalAlertEdit(){
    this.medicalAlertEditMode = this.medicalAlertEditMode == true ? false : true;
  }

  autoGrowTextArea(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 10)+"px";
  }

  saveNotes(){
    this._store$.dispatch(PatientsStoreActions.UpdateRequest({ patient: this.selectedPatient }));

    this._actions$
      .pipe(ofType(PatientsStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$))
      .subscribe((result) => {
        this.tlcEditMode = false;
        this.noteEditMode = false;
        this.hobbiesInterestEditMode = false;
        this.accountNumberEditMode = false;
        this.emergencyContactInfoEditMode = false;
        this.medicalAlertEditMode = false;
      });
  }


  formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber) {
      let cleaned = ('' + phoneNumber).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }

      return phoneNumber;
    }

    return '';
  }

  getClinicAddress(data: any) {
    return data ? `${data.clinicAddressLine1 ? '<div>' + data.clinicAddressLine1 + '</div>' : ''}
    ${data.clinicAddressLine2 ? '<div>' + data.clinicAddressLine2 + '</div>' : ''}
    ${data.clinicCity ? '<div>' + data.clinicCity + ',' : ''}
    ${data.clinicState ? data.clinicState : ''}
    ${data.clinicZip ? data.clinicZip + '</div>' : '</div>'}` : '';
  }

  getAddress(data: any) {
    return data ? `${data.addressLine1 ? '<div>' + data.addressLine1 + '</div>' : ''}
    ${data.addressLine2 ? '<div>' + data.addressLine2 + '</div>' : ''}
    ${data.city ? '<div>' + data.city + ',' : ''}
    ${data.state ? data.state : ''}
    ${data.zip ? data.zip + '</div>' : '</div>'}` : '';
  }

  saveTLC(){
    this._store$.dispatch(PatientsStoreActions.UpdateRequest({ patient: this.selectedPatient }));

    this._actions$
      .pipe(ofType(PatientsStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$))
      .subscribe((result) => {
        this.tlcEditMode = false;
        this.noteEditMode = false;
        this.hobbiesInterestEditMode = false;
        this.accountNumberEditMode = false;
        this.emergencyContactInfoEditMode = false;
        this.medicalAlertEditMode = false;
      });
  }

  saveMedicalAlert(){
    this._store$.dispatch(PatientsStoreActions.UpdateRequest({ patient: this.selectedPatient }));

    this._actions$
      .pipe(ofType(PatientsStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$))
      .subscribe((result) => {
        this.tlcEditMode = false;
        this.noteEditMode = false;
        this.hobbiesInterestEditMode = false;
        this.accountNumberEditMode = false;
        this.emergencyContactInfoEditMode = false;
        this.medicalAlertEditMode = false;
      });
  }
}

interface TreeNode {
  label: string | SafeHtml;
  children?: TreeNode[] | Observable<TreeNode[]>;
  referrals?: string[];
  onclick?: (node: TreeNode) => void;
  canCollapse?: boolean;
  canCollapseView?: boolean;
  templateContent?: Observable<TemplateRef<any>>;
  nodeHeight?: string | null;
}

class CustomNestedTreeControl<T> extends NestedTreeControl<T> {
  canCollapse: (dataNode: T) => boolean = (_) => true;
  canCollapseView: (dataNode: T) => boolean = (_) => true;
  constructor(getChildren: (dataNode: T) => Observable<T[]> | T[] | undefined | null, canCollapse?: (dataNode: T) => boolean, canCollapseView?: (dataNode: T) => boolean) {
    super(getChildren);
    if (canCollapse) this.canCollapse = canCollapse;
    if (canCollapseView) this.canCollapseView = canCollapseView;
  }
}
