import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { HubEventArea, PatientClient, PatientNotepadDto } from 'src/app/shared/services/api.service';
import { PatientClientService } from '../../shared/services/client/patient-client.service';
import { PatientsStoreActions, PatientsStoreSelectors } from '../patient-store';
import { State } from '../root-state';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import * as PatientNotepadStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class PatientNotepadStoreEffects {
  private _selectedPatientId = this.store$.select(PatientsStoreSelectors.getSelectedPatientId);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _patientClient: PatientClient,
    private _patientClientService: PatientClientService) { }

  @Effect()
  patientNotepadUpsertEffect$: Observable<Action> = this.actions$.pipe(
    ofType(SignalRHubStoreActions.EntityEvent),
    withLatestFrom(this._selectedPatientId),
    filter(
      ([action, selectedPatientId], selectedLocationId) =>
        action.event.eventArea == HubEventArea.NotepadEvent &&
        action.event.entityId == selectedPatientId
    ),
    mergeMap((action) =>
      of(
        PatientNotepadStoreActions.LoadPatientNotepadRequest(),
        PatientsStoreActions.LoadOneRequest({ id: action[0].event.entityId, locationId: 0 })
      )
    )
  );

  @Effect()
  loadPatientNotepadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(PatientNotepadStoreActions.LoadPatientNotepadRequest),
    withLatestFrom(this._selectedPatientId),
    switchMap(([action, selectedPatientId]) =>
      this._patientClientService.fetchAll(
        this._patientClient.patient_GetNotepads,
        [
          { value: selectedPatientId },
          { value: 20 },
          { isPage: true }
        ],
        PatientNotepadDto
      ).pipe(
        map((result) => PatientNotepadStoreActions.LoadPatientNotepadSuccess({ PatientNotepads: result })),
        catchError((error) => of(PatientNotepadStoreActions.LoadPatientNotepadFailure({ error })))
      )
    )
  );
}
